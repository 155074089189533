/* eslint-disable jsx-a11y/label-has-associated-control */

import { ButtonClose } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import { usePoints } from '../UsersPointsProvider';

function ModalHeader({ handleCloseClick }) {
    return (
        <div
            className="modal-box-header"
            style={{ borderBottom: '1px solid rgba(19, 15, 64, 0.1)' }}
        >
            <div>
                <div className="modal-box-title">Point to Cash Calculator</div>
                <div className="modal-box-close">
                    <ButtonClose evOnClick={handleCloseClick} />
                </div>
            </div>
        </div>
    );
}

function ModalBody() {
    const { getPointsValue, handlePointsTransfer, pointsValue, taka } = usePoints();

    return (
        <div className="points-modal-area modal-body-area">
            <div className="line line-sm-none align-end line-row-center line-no-wrap line-sm-wrap">
                <div className="cell-fill">
                    <div>
                        <label className="d-sm-block label" htmlFor="points">
                            Available Points:
                        </label>
                        <input
                            onChange={getPointsValue}
                            type="text"
                            className="form-input w-sm-100"
                            value={pointsValue.val}
                        />
                    </div>
                </div>
                <div className="cell-1 cell-sm-12">
                    <div className="point-convert-img">
                        <img className="w-100" src={icons.iconConverterP} alt="icon" />
                    </div>
                </div>
                <div className="cell-fill">
                    <div>
                        <label className="d-sm-block label" htmlFor="points">
                            Ammount:
                        </label>
                        <input type="text" className="form-input w-sm-100" value={taka || 0} />
                    </div>
                </div>
            </div>
            <div className="w-100 mt-5">
                <button
                    onClick={handlePointsTransfer}
                    type="button"
                    className="btn-gr btn-gr-modal default-none w-100"
                >
                    Transfer Now
                </button>
            </div>
        </div>
    );
}

function PointsConverterModal({ setOpenModal, points }) {
    const { pointsValue, setPointsValue } = usePoints();

    const handleCloseClick = () => setOpenModal(null);
    return (
        <div className="modal-box backdrop ">
            <div className="modal-box-content" /* style={{ maxWidth: '448px' }} */>
                <ModalHeader handleCloseClick={handleCloseClick} />

                <ModalBody
                    pointsValue={pointsValue}
                    setPointsValue={setPointsValue}
                    points={points}
                />
            </div>
        </div>
    );
}

export default PointsConverterModal;
