import ListActionButton from './ListActionButton';

function RowCommonView({ title, value }) {
    return (
        <div className="line-row-between mb-1">
            <div className="cell">
                <span className="value primary-70">{title || '--'}:</span>
            </div>
            <div className="cell">
                <span className="value primary">{value || '--'}</span>
            </div>
        </div>
    );
}

function ProfileListMobile({ profileList, setShowOptions, showOptions }) {
    if (!profileList?.length) {
        return <div className="list-item-sm">No Profile Added !</div>;
    }
    return profileList?.map((data) => (
        <div key={data?.id} className={`list-item-sm ${data?.demo ? 'placeholder-mode' : ''}`}>
            <div className="line-row-between mb-2">
                <div className="title primary">
                    <span>{data?.domain}</span>
                </div>
                <ListActionButton
                    setShowOptions={setShowOptions}
                    showOptions={showOptions}
                    data={data}
                />
            </div>
            <RowCommonView title="Email" value={data?.email} />
            <RowCommonView title="Phone" value={data?.phone} />
        </div>
    ));
}

export default ProfileListMobile;
