function hostnameFromValidDomain(q) {
    const regex = /([a-z0-9\-\\.])+\.([a-z]){2,8}/is;
    const m = regex.exec(q);

    if (m && m[0] === q) {
        return q;
    }

    return null;
}

// https://dmitripavlutin.com/parse-url-javascript/
function hostnameFromValidUrl(q) {
    try {
        const url = new URL(q);
        return hostnameFromValidDomain(url.hostname);
    } catch (error) {
        return null;
    }
}

function pickTld(hostname, TLDs) {
    for (let i = 0; i < TLDs.length; i += 1) {
        const output = hostname.split(`.${TLDs[i].tld}`);
        if (output.length === 2 && output[output.length - 1] === '') {
            const domainAr = output[0].split('.');
            const domainTxt = domainAr.splice(-1);

            return {
                subdomainTxt: domainAr.join('.'),
                domainTxt: domainTxt.join('.'),
                tld: TLDs[i].tld,
                isListedTld: true
            };
        }
    }

    // If TLD Not Found
    const output = hostname.split('.');
    const tld = output.splice(-1);
    const domainTxt = output.splice(-1);

    return {
        subdomainTxt: output.join('.'),
        domainTxt: domainTxt.join('.'),
        tld: tld.join(''),
        isListedTld: false
    };
}

function splitHostname(hostname, TLDs) {
    const nTLDs = TLDs.map((tld) => ({ tld, length: tld.length })).sort((a, b) => {
        if (a.length === b.length) return 0;
        return a.length < b.length ? 1 : -1;
    });

    return pickTld(hostname, nTLDs);
}

export function getDomainInfo(q, TLDs) {
    // https://www.bikiran.com/
    // https://bikiran.com/
    // app.bikiran.com
    // bikiran.com
    //

    // --Convert to Lower
    const q1 = q.toLowerCase();

    const hostname =
        [hostnameFromValidUrl(q1), hostnameFromValidDomain(q1)].filter((item) => !!item)[0] || '';

    if (!hostname) {
        return {
            error: 1,
            message: 'Not a valid domain',
            data: {
                isDomain: false,
                isListedTld: false,
                tld: '',
                domainTxt: '',
                subdomainTxt: '',
                hostname: '',
                domain: ''
            }
        };
    }

    const { tld, isListedTld, domainTxt, subdomainTxt } = splitHostname(hostname, TLDs);

    return {
        error: 0,
        message: 'Domain is valid',
        data: {
            isDomain: true,
            isListedTld,
            tld,
            domainTxt,
            subdomainTxt,
            hostname,
            domain: `${domainTxt}.${tld}`
        }
    };
}

export default null;
