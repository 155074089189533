import { useEffect } from 'react';
import { ButtonGr } from '../../../../../configs/Buttons';
import PageProperties from '../../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../../headers/DashboardHeaderSection';
import CartItemListSection from './cart_item_list/CartItemListSection';

function HeaderContent() {
    return (
        <div className="line justify-end">
            <ButtonGr evOnClick={null} title="Add cart" />
        </div>
    );
}

function SupportCartPage() {
    useEffect(() => {
        PageProperties(`Cart \u2022 Support`);
    }, []);

    return (
        <div className="cell cell-content h-100 w-100 domain-section">
            <div className="content-section">
                <div className="content-area user-contract-page-area">
                    <DashboardDefaultHeader
                        title="Cart"
                        path="/smanager/ticket/"
                        content={<HeaderContent />}
                        mobileContent={<HeaderContent />}
                    />

                    <div className="content-body-scroll" style={{ paddingBottom: '180px' }}>
                        <div className="w-100">
                            <CartItemListSection />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SupportCartPage;
