import '../assets/css/style.css';
import FloatingMessage from '../layouts/xl/FloatingMessage';
import AuthProvider from './contexts/AuthProvider';
import CartProvider from './contexts/CartProvider';
import InitProvider from './contexts/InitProvider';
import LayoutProvider from './contexts/LayoutProvider';
import SearchProvider from './contexts/SearchProvider';
import TemplateProvider from './contexts/TemplateProvider';
import RouteHandler from './RouteHandler';
import ComposeProviders from './utils/ComposeProviders';

function App() {
    return (
        <ComposeProviders
            components={[
                TemplateProvider,
                InitProvider,
                AuthProvider,
                CartProvider,
                SearchProvider,
                LayoutProvider
            ]}
        >
            <RouteHandler />
            <FloatingMessage />
        </ComposeProviders>
    );
}

export default App;
