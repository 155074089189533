function Divider({ title, action }) {
    return (
        <div className="line align-end line-no-wrap line-g1 mb-4">
            <div className="cell">
                <div className="divider-title">{title}</div>
            </div>
            <div className="cell cell-fill">
                <hr className={`hr mb-1 ${action ? ' divider-line' : ''}`} />
            </div>
            {action && (
                <div className="cell">
                    <span className="divider-action">{action}</span>
                </div>
            )}
        </div>
    );
}

export default Divider;
