import { useEffect, useState } from 'react';

import { ButtonClose, ButtonGr } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../contexts/AuthProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';
import CopyText from '../../../websites/models/CopyText';

const ModalHeader = ({ handleCloseClick, modal }) => {
    const { domain } = modal?.data || {};

    return (
        <div className="modal-box-header border-bottom">
            <div>
                <div className="modal-box-title">
                    <span>cPanel Login</span>
                    <span>
                        <small>
                            <CopyText copyValue={domain} value={domain?.toUpperCase()} />
                        </small>
                    </span>
                </div>
                <div className="modal-box-close">
                    <ButtonClose evOnClick={handleCloseClick} />
                </div>
            </div>
        </div>
    );
};

function ModalBody({ setModal, cpData, setCpData, id }) {
    const { currentUser } = useAuth();

    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .setApiUrl('CONTRACT_HOSTING', `/${id}/cp-login/`)
            .post({ contractId: id })
            .then(({ data }) => {
                setCpData(data);
            })
            .then((err) => {
                console.log(err);
            });
    }, [currentUser, id, setCpData]);

    const handleLoginBtn = (e) => {
        if (cpData?.error === 0 && e) {
            setModal({ type: null });
            window?.open(cpData?.url);
        }
    };

    return (
        <div className="modal-body-area h-100 mt-2">
            <div className="line-col-center">
                <span className="font-20 purple d-block mb-4">{cpData?.message}</span>
                <ButtonGr
                    evOnClick={(e) => handleLoginBtn(e)}
                    disabled={cpData === null || cpData?.error !== 0}
                    title="Login"
                    modal
                />
            </div>
        </div>
    );
}

function CpLoginLinkPopup({ setModal, modal, show, id }) {
    const [cpData, setCpData] = useState(null);

    const handleCloseClick = () => setModal({ type: null });

    if (!show) {
        return null;
    }

    return (
        <div className="modal-box backdrop">
            <div
                className={`modal-box-content cPanel-login-popup ${!cpData ? 'loading-block' : ''}`}
            >
                <ModalHeader handleCloseClick={handleCloseClick} modal={modal} />
                <ModalBody
                    handleCloseClick={handleCloseClick}
                    id={id}
                    setModal={setModal}
                    setCpData={setCpData}
                    cpData={cpData}
                />
            </div>
        </div>
    );
}

export default CpLoginLinkPopup;
