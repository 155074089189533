/* eslint-disable jsx-a11y/label-has-associated-control */
import { useRef } from 'react';
import { ButtonClose, ButtonGr } from '../../../../../configs/Buttons';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import InputCopy from '../../../../utils/inputs/InputCopy';
import { mkStrongPassword, mkToken } from '../../../../utils/StringOperation';
import CpCreateWizardProvider, { useCreateCpWizard } from './CpCreateWizardProvider';

const ModalHeader = ({ handleCloseClick }) => (
    <div className="modal-box-header border-bottom">
        <div>
            <div className="modal-box-title">
                <span>Create cPanel Wizard</span>
            </div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={handleCloseClick} />
            </div>
        </div>
    </div>
);

function FinalStepView({ formData, setFormData, handleDomainValue, handleUsernameValue }) {
    const domainRef = useRef();

    const handelDomainInput = (ev) => {
        setFormData((cuData) => ({
            ...cuData,
            domain: ev.target.value,
            isDomainVerified: false,
            isDomainError: false,
            username: '',
            isUsernameVerified: false,
            isUsernameError: false,
            password: ''
        }));
    };

    const handelUsernameInput = (ev) => {
        setFormData((cuData) => ({
            ...cuData,
            username: ev.target.value,
            isUsernameVerified: false,
            isUsernameError: false
        }));
    };

    const handelPasswordInput = (ev) => {
        setFormData((cuData) => ({
            ...cuData,
            password: ev.target.value
        }));
    };

    const handelRegeneratePassword = () => {
        setFormData((cuData) => ({
            ...cuData,
            password: mkStrongPassword(16)
        }));
    };

    const cName = (isVerified, isError) => {
        const c = ['form-input w-100'];
        if (isVerified) {
            c.push('verified');
        }
        if (isError) {
            c.push('error');
        }
        return c.join(' ');
    };

    return (
        <div>
            <div className="mb-2">
                <label htmlFor="domain_name" className="label">
                    Domain/Sub domain
                </label>
                <input
                    ref={domainRef}
                    onBlur={handleDomainValue}
                    onChange={handelDomainInput}
                    value={formData.domain}
                    placeholder="domain-name.com"
                    type="text"
                    className={cName(formData.isDomainVerified, formData.isDomainError)}
                    autoComplete="off"
                />
            </div>

            <div className="mb-2">
                <label htmlFor="username" className="label">
                    cPanel User Name
                </label>
                <input
                    onBlur={handleUsernameValue}
                    onChange={handelUsernameInput}
                    value={formData.username}
                    placeholder="domain1234"
                    type="text"
                    className={cName(formData.isUsernameVerified, formData.isUsernameError)}
                    autoComplete="off"
                />
            </div>

            <div className="mb-4">
                <div className="line align-end line-g1 line-no-wrap">
                    <div className="cell cell-fill">
                        <InputCopy
                            title="Set Password"
                            placeholder="Type Password"
                            setValue={handelPasswordInput}
                            value={formData.password}
                        />
                    </div>
                    <div className="cell">
                        <div
                            className="icon-generate-pass"
                            role="button"
                            tabIndex={-1}
                            onClick={handelRegeneratePassword}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function ModalBody({ handleCloseClick, setUniqueKey }) {
    const { formData, setFormData, handelDomainVerify, handelUsernameVerify, handelCreateCp } =
        useCreateCpWizard();
    const { setMessage } = useTemplate();

    const handleDomainValue = () => {
        if (formData.domain.length && !formData.isDomainVerified && !formData.isDomainError) {
            setMessage('Validating domain...');
            handelDomainVerify()
                .then(({ message }) => {
                    setMessage(message);
                })
                .catch((err) => {
                    setMessage(err);
                });
        }
    };

    const handleUsernameValue = () => {
        if (formData.username.length && !formData.isUsernameVerified && !formData.isUsernameError) {
            setMessage('Validating username...');
            handelUsernameVerify()
                .then(({ message }) => {
                    setMessage(message);
                })
                .catch((err) => {
                    setMessage(err);
                });
        }
    };

    const handleCreateCpBtn = () => {
        setMessage('Creating cPanel...');
        handelCreateCp()
            .then(({ error, message }) => {
                if (error === 0) {
                    handleCloseClick();
                    setUniqueKey(mkToken(32));
                }
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    return (
        <div className="modal-body-area">
            <FinalStepView
                formData={formData}
                setFormData={setFormData}
                handleDomainValue={handleDomainValue}
                handleUsernameValue={handleUsernameValue}
            />

            <div className="line justify-end">
                {/* {!formData.isDomainVerified ? (
                    <ButtonGr title="Verify Domain" modal evOnClick={handleDomainValue} />
                ) : null}

                {formData.isDomainVerified && !formData.isUsernameVerified ? (
                    <ButtonGr title="Verify Username" modal evOnClick={handleUsernameValue} />
                ) : null} */}

                <ButtonGr
                    title="Create cPanel"
                    modal
                    evOnClick={handleCreateCpBtn}
                    disabled={!formData?.isDomainVerified && !formData?.isUsernameVerified}
                />
            </div>
        </div>
    );
}

function CpCreateWizardPopup({ setModal, show, item, setUniqueKey }) {
    const handleCloseClick = () => setModal(null);
    if (!show) {
        return null;
    }

    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content cp-wizard-area">
                <ModalHeader handleCloseClick={handleCloseClick} />
                <CpCreateWizardProvider contractId={item?.id || item?.contractId}>
                    <ModalBody handleCloseClick={handleCloseClick} setUniqueKey={setUniqueKey} />
                </CpCreateWizardProvider>
            </div>
        </div>
    );
}

export default CpCreateWizardPopup;
