import { useNavigate } from 'react-router-dom';
import { arrowIcons, hostingIcons, icons, servicesPageIcons } from '../../../../configs/Icons';

const serviceOptions = [
    {
        id: 11,
        key: 'web-hosting',
        image: hostingIcons.iconWebHV2,
        image5: hostingIcons.iconWebHV3,
        title: 'Web Hosting',
        description:
            'If you runs a small website or application with low tariff, then we are ready to provide quality',
        url: '/services/hosting/web/'
    },
    {
        id: 12,
        key: 'app-hosting',
        image: hostingIcons.iconAppHostingV1,
        image5: hostingIcons.iconAppHostingV2,
        title: 'App Hosting',
        description:
            'If you runs a small or medium application, then we are ready to provide quality-full &...',
        url: '/services/hosting/app/'
    },
    {
        id: 13,
        key: 'email-hosting',
        image: hostingIcons.iconEmailHostingV1,
        image5: hostingIcons.iconEmailHostingV2,
        title: 'Email Hosting',
        description:
            'If you need some business emails and website for your organization. We provides...',
        url: '/services/hosting/email/'
    },

    {
        id: 21,
        key: 'cloud-vm',
        image: icons.iconCloudVm,
        image5: icons.iconCloudVm5,
        title: 'Cloud VM',
        description:
            'If you runs a medium or high tariff website then we provide best managed/unmanaged..',
        url: '/services/server/cloud-vm/'
    },
    {
        id: 22,
        key: 'vps',
        image: icons.iconVpsV1,
        image5: icons.iconVps5,
        title: 'VPS',
        description:
            'If you runs a medium or high tariff website then we provide best managed/unmanaged VPS.',
        url: '/services/server/vps/'
    },
    {
        id: 23,
        key: 'dedicated-server',
        image: icons.iconDedicatedServerV1,
        image5: icons.iconDedicatedServer5,
        title: 'Dedicated Server',
        description:
            'If you required email server or file server, audio/video strumming server, data ...',
        url: '/services/server/dedicated/'
    },

    {
        id: 31,
        key: 'e-commerce-software',
        image: servicesPageIcons.iconEcomSoft,
        image5: servicesPageIcons.iconEcomSoft5,
        title: 'E-Commerce Software',
        description:
            'If you runs website with low tariff, then we provide quality-full Hosting Service.',
        url: '/services/software/e-commerce/'
    },
    {
        id: 32,
        key: 'education-software',
        image: servicesPageIcons.iconEduSoft,
        image5: servicesPageIcons.iconEduSoft5,
        title: 'Education Software',
        description:
            'If you runs website with low tariff, then we provide quality-full Hosting Service.',
        url: 'https://www.edusoft.com.bd/',
        redirect: true
    },
    {
        id: 33,
        key: 'news-portal',
        image: servicesPageIcons.iconNewsPortal,
        image5: servicesPageIcons.iconNewsPortal5,
        title: 'News Portal Software',
        description:
            'If you runs website with low tariff, then we provide quality-full Hosting Service.',
        url: '/services/software/news-portal/'
    },

    {
        id: 41,
        key: 'sms-service',
        image: servicesPageIcons.iconSmsService,
        image5: servicesPageIcons.iconSmsService5,
        title: 'SMS Service',
        description:
            'If you runs website with low tariff, then we provide quality-full Hosting Service.',
        url: '/services/send/sms-service/'
    },
    {
        id: 42,
        key: 'email-service',
        image: servicesPageIcons.iconEmailService,
        image5: servicesPageIcons.iconEmailService5,
        title: 'Email Service',
        description:
            'If you runs website with low tariff, then we provide quality-full Hosting Service.',
        url: '/services/send/email/'
    },
    {
        id: 43,
        key: 'push-notification',
        image: servicesPageIcons.iconPushNotification,
        image5: servicesPageIcons.iconPushNotification5,
        title: 'Push Notification',
        description:
            'If you runs website with low tariff, then we provide quality-full Hosting Service.',
        url: '/services/send/push/'
    }
];

const ServiceOption = ({ item, navigate }) => (
    <div
        className="cell cell-4"
        role="button"
        tabIndex="-1"
        onClick={(ev) => {
            ev.preventDefault();
            if (item?.redirect) {
                window.open(item.url);
            } else {
                navigate(item.url);
            }
        }}
    >
        <div className="service-option">
            <div className="img" style={{ backgroundImage: `url(${item.image})` }}>
                <div className="animate" style={{ backgroundImage: `url(${item.image5})` }} />
            </div>
            <h3 className={[item.key, 'animate'].join(' ')}>{item.title}</h3>
            <p>{item.description}</p>
            <button className="button button-transparent" type="button">
                Start Conversation
                <img className="animate" src={arrowIcons.iconArrowRightV2} alt="Arrow Right" />
            </button>
        </div>
    </div>
);

function ServicesSection() {
    const navigate = useNavigate();
    return (
        <div className="services-area mt-120">
            <div className="container">
                <div className="services-area-header">
                    <h3 className="sub-title" style={{ lineHeight: '0px' }}>
                        Our Service
                    </h3>
                    <h1 className="title">Choose Your need</h1>
                    <p>
                        We runes few services for your business growth. You may find out your best
                        choice. Our service is scalable, secure and smooth. And we are ready to
                        support you.
                    </p>
                </div>

                <div className="service-description-area">
                    <div className="line">
                        {serviceOptions.map((item) => (
                            <ServiceOption item={item} key={item.id} navigate={navigate} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesSection;
