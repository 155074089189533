import { useState } from 'react';
import ResultBodyContent from './ResultBodyContent';

function ContentItem({ resItem, apiData }) {
    // body content
    if (resItem === 0) {
        return <ResultBodyContent apiData={apiData} />;
    }

    // Cookies content
    if (resItem === 1) {
        return <h2 className="req-title ">Cookies</h2>;
    }

    // Headers content
    if (resItem === 2) {
        return <h2 className="req-title ">Headers</h2>;
    }

    // Test result content
    if (resItem === 3) {
        return <h2 className="req-title ">Test result</h2>;
    }
}

function ResultTabsContentArea({ resItem, apiData }) {
    const [editMode, setEditMode] = useState(null);
    return (
        <div className="dt-tab-content border-bottom">
            <ContentItem
                apiData={apiData}
                resItem={resItem}
                setEditMode={setEditMode}
                editMode={editMode}
            />
        </div>
    );
}

export default ResultTabsContentArea;
