import React, { useEffect, useRef } from 'react';

function LeftSideModal({ children, show, setShow }) {
    const ref = useRef();
    useEffect(() => {
        function handelClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShow(false);
            }
        }
        document.addEventListener('mousedown', handelClickOutside);
        return () => {
            document.removeEventListener('mousedown', handelClickOutside);
        };
    }, [setShow]);

    return (
        <div className={`left-modal ${show ? 'active' : ''}`}>
            <div className="left-options overflow-auto" ref={ref}>
                {children}
            </div>
        </div>
    );
}

export default LeftSideModal;
