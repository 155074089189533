import React from 'react';

import { icons } from '../../../../../configs/Icons';

function ResultStep({ isVisible }) {
    return (
        <div className={`line line-no-wrap line-g2 mb-6 ${!isVisible ? 'disabled-view' : ''}`}>
            <div className="cell">
                <div className="logo">
                    <img className="w-100 d-block" src={icons.iconDoneBg} alt="" />
                </div>
            </div>
            <div className="cell mt-2">
                <span className="font-18 primary">Result</span>
                <span className="d-block font-14 primary-70">
                    Your Domain Transferred Successfully on 12:53pm, 24 Dec 2024
                </span>
            </div>
        </div>
    );
}

export default ResultStep;
