import LayoutSelector from '../../../../utils/LayoutSelector';
import Divider from '../../hostings/_models/SectionDivider';
import { useHostingDatabase } from '../HostingInfoDatabasesProvider';
import DatabaseListMobile from './DatabaseListMobile';
import DatabaseListWeb from './DatabaseListWeb';

const demoArr = [
    {
        id: '-----',
        database: '------------------',
        disk_usage: '-------',
        users: ['-----------'],
        demo: true
    }
];

function DatabaseListSection() {
    const { databaseInfo } = useHostingDatabase();

    return (
        <div className="product-info email-list-section mb-4">
            <Divider title="Databases" />
            <div className="">
                <LayoutSelector
                    sm
                    md
                    webComp={<DatabaseListWeb arr={databaseInfo?.data || demoArr} />}
                    mobileComp={<DatabaseListMobile arr={databaseInfo?.data || demoArr} />}
                />
            </div>
        </div>
    );
}

export default DatabaseListSection;
