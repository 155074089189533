import { Link } from 'react-router-dom';

function SectionTitleArea() {
    return (
        <div>
            <h3 className="section-title mb-1 mt-0">Privacy Policy</h3>
            <div className="sub-title mt-1 mb-1">Updated On: 6 July 2022</div>
            <div className="sub-title mt-1 mb-4">
                To view our archived Privacy Policy,{' '}
                <span className="purple">
                    <Link to="/" className="purple">
                        click here.
                    </Link>
                </span>
            </div>
        </div>
    );
}

function CommonSectionText({ title }) {
    return <h4 className="value-title mb-3">{title}</h4>;
}

function CommonListArea({ info }) {
    return (
        <ul>
            {info.map((data) => (
                <li className="list-disc" key={data}>
                    <span>{data}</span>
                </li>
            ))}
        </ul>
    );
}

function InfoText() {
    const info = [
        'Name',
        'Telephone number',
        'Address',
        'Date of birth',
        'Email address',
        'Billing and payment information',
        'Candidate information (for job applicants)',
        'Other data collected that could directly or indirectly identify you.'
    ];
    return (
        <div>
            <p>
                BIKIRAN cares about your privacy. For this reason, we collect and use personal
                information only as needed to deliver our products, services, websites and mobile
                applications, and to communicate with you about the same, or as you have requested
                (collectively, our “Services”). Your personal information includes information such
                as:
            </p>
            <CommonListArea info={info} />
            <p className=" mb-3">
                Our Privacy Policy not only explains how and why we use your personal information
                that we collect, but also how you can access, update or otherwise take control of
                your personal information. We’ve also created a Trust Center that offers answers to
                your most common questions, quick links to access your Account Settings,
                instructions on how to exercise certain rights that might be available to you, and
                definitions to key terms and concepts noted in this Privacy Policy.
            </p>
            <p className=" mt-3 mb-4">
                If at any time you have questions about our practices or any of your rights
                described below, you may reach our DPO(“Data Protection Officer”) and our dedicated
                team that supports this office by contacting us at{' '}
                <a href="mailto:privacy@bikiran.com">
                    <span className="purple">privacy@bikiran.com</span>
                </a>
                . This inbox is actively monitored and managed so that we can deliver an experience
                that you can confidently trust.
            </p>
        </div>
    );
}

function InfoWeCollect() {
    const info = [
        'Create an account or purchase any of our Services (ex: billing information, including name, address, credit card number, government identification)',
        'Request assistance from our award-winning customer support team (ex: phone number, voice recordings)',
        'Complete contact forms or request newsletters or other information from us (ex: email)',
        'Participate in contests and surveys, apply for a job, or otherwise participate in activities we promote that might require information about you.'
    ];
    return (
        <div>
            <CommonSectionText title="What information we collect, how we collect it, and why" />
            <p>
                Much of what you likely consider personal information is collected directly from you
                when you:
            </p>
            <CommonListArea info={info} />
            <p>
                However, we also collect additional information when delivering our Services to you
                to ensure necessary and optimal performance. These methods of collection may not be
                as obvious to you, so we thought we’d highlight and explain a bit more about what
                these might be.
            </p>
        </div>
    );
}

function CoockiesAndTech() {
    return (
        <div>
            <CommonSectionText title="Cookies and similar technologies:" />
            <p className="mb-4 mt-2">
                On our websites and mobile applications allow us to track your browsing behavior,
                such as links clicked, pages viewed, and items purchased. Specifically, the data
                collected relates to your interactions with our site features or third-party
                features, such as social media plugins, contained within the Services; IP (Internet
                Protocol) address. These technologies also automatically collect data to measure
                website performance and improve our systems, including metadata, log files, page
                load time, server response time to optimize DNS resolution, network routing and
                server configurations. For additional information, and to learn how to manage the
                technologies we utilize, please visit our{' '}
                <Link to="/">
                    <span className="purple">Cookie Policy</span>
                </Link>
            </p>
        </div>
    );
}

function SupplementedData() {
    return (
        <div>
            <CommonSectionText title="Supplemented Data:" />
            <p className="mb-4 mt-2">
                It may be received about you from other sources, for example, from publicly
                available databases, social media platforms, or third parties from whom we have
                purchased data, in which case we may combine this data with information we already
                have about you so that we can update, expand and analyze the accuracy of our
                records, assess the qualifications of a candidate for employment, identify new
                customers, and provide products and services that may be of interest to you. If you
                provide us personal information about others, or if others give us your information,
                we will only use that information for the specific reason for which it was provided
                to us.
            </p>
        </div>
    );
}
function HowWeUtilizeInfo() {
    const info = [
        'Improve and optimize the operation and performance of our Services.',
        'Diagnose problems with and identify any security and compliance risks, errors, or needed enhancements to the Services',
        'Detect and prevent fraud and abuse of our Services and systems'
    ];
    return (
        <div>
            <CommonSectionText title="How we utilize information:" />
            <p className="mb-3 mt-2">
                BIKIRAN strongly believe in both minimizing the data we collect and limiting its use
                and purpose to only that (1) for which we have been given permission, (2) as
                necessary to deliver the Services you purchase or interact with, or (3) as we might
                be required or permitted for legal compliance or other lawful purposes:
            </p>
            <p className="mt-3">
                <span className="value-title" style={{ fontSize: '16px' }}>
                    Delivering, improving, updating and enhancing our Services.
                </span>{' '}
                We collect various information relating to your purchase, use and/or interactions
                with our Services. We utilize this information to:
            </p>
            <CommonListArea info={info} />
            <p className="mb-4">
                Much of the data collected is aggregated or statistical data about how individuals
                use our Services, and is not linked to any personal information.
            </p>
        </div>
    );
}

function SharingWithThirdParties() {
    const info1 = [
        'Processing credit card payments',
        'Serving advertisements (more on this topic below)',
        'Conducting contests or surveys',
        'Performing analysis of our Services, customer demographics, and sentiment',
        'Communicating with you, such as by way of email or survey delivery',
        'Customer relationship management',
        'Security, risk management and compliance',
        'Recruiting support and related services.'
    ];
    const info2 = [
        'In the event that we sell or buy any business or assets (whether a result of liquidation, bankruptcy or otherwise), in which case we will disclose your data to the prospective seller or buyer of such business or assets; or',
        'If we sell, buy, merge, are acquired by, or partner with other companies or businesses, or sell some or all of our assets. In such transactions, your information may be among the transferred assets.'
    ];
    return (
        <div>
            <CommonSectionText title="Sharing with trusted third parties:" />
            <p className="mt-0">
                BIKIRAN may share your personal information with affiliated companies within our
                corporate family, with third parties with which we have partnered to allow you to
                integrate their services into our own Services, and with trusted third party service
                providers as necessary for them to perform services on our behalf, such as:
            </p>
            <CommonListArea info={info1} />
            <p>
                These third parties (and any subcontractors they may be permitted to use) have
                agreed not to share, use or retain your personal information for any purpose other
                than as necessary for the provision of Services.
            </p>
            <p>We will also disclose your information to third parties:</p>
            <CommonListArea info={info2} />
        </div>
    );
}

function Advertisement() {
    return (
        <div>
            <CommonSectionText title="Advertisement" />
            <p className="mt-0">
                BIKIRAN want to serve you ads that are most relevant and useful to you, so we may
                use the personal information you provided us in Account Settings or those collected
                through cookie technologies to personalize ads and improve your overall experience
                with us on our site and other sites. We call this ad personalization (formerly,
                interest-based advertising) because the ads you see are based on previously
                collected or historical data to determine which ad will be most relevant to you,
                including data such as previous search queries, activity, visits to sites or apps,
                demographic information, or location. To illustrate ad personalization that is
                audience-based, let’s say you registered a domain with us and created a website
                using our website builder product. The next time you navigate to our advertising
                partner’s platform or website, they may recognize that you are a BIKIRAN customer
                via a matched email and may use that data to help us display an ad about our latest
                promotion for website security.
            </p>
            <p className="mb-3">
                Alternatively, if you already purchased our website security product, then we may
                exclude you from future ad campaigns for this product because it wouldn’t be
                relevant to you. Other ad personalization could be activity-based. For example,
                using data about your page views on our site to determine whether an ad would be of
                interest to you. The information we share with our advertising channel partners to
                deliver you personalized ads is done securely and cannot be used by them for any
                other purpose. We do not sell your data to any third parties. In addition, the data
                we share is hashed and encrypted, which means that it does not directly identify
                you. We do not sell your data in any way to our partners or other third parties.
            </p>
        </div>
    );
}

function Communicating() {
    const info = [
        'Email',
        'Text (SMS) messages',
        'Telephone calls',
        'Messenger applications (e.g. WhatsApp, Skype, etc.)',
        'Automated phone calls or text messages'
    ];
    return (
        <div>
            <CommonSectionText title="Communicating with you:" />
            <p className="mt-0">
                BIKIRAN may contact you directly or through a third party service provider regarding
                products or services you have signed up or purchased from us, such as necessary to
                deliver transactional or service related communications. We may also contact you
                with offers for additional services we think you’ll find valuable if you give us
                consent, or where allowed to contact you based on legitimate interests. You don’t
                need to provide consent as a condition to purchase our goods or services. These
                contacts may include:
            </p>
            <CommonListArea info={info} />
            <p>
                If BIKIRAN collect information from you in connection with a co-branded offer, it
                will be clear at the point of collection who is collecting the information and whose
                privacy policy applies. In addition, it will describe any options you have regarding
                the use and/or sharing of your personal information with a co-branded partner, as
                well as how to exercise those options. We are not responsible for the privacy
                practices or the content of third-party sites. Please read the privacy policy of any
                website you visit.
            </p>
            <p>
                If you make use of a service that allows you to import contacts (ex. using email
                marketing services to send emails on your behalf), we will only use the contacts and
                any other personal information for the requested service. If you believe that anyone
                has provided us with your personal information and you would like to request that it
                be removed from our database, please contact us at{' '}
                <a href="mailto:privacy@bikiran.com">
                    <span className="purple">privacy@bikiran.com</span>
                </a>
                .
            </p>
        </div>
    );
}

function TransferInfoToAbroad() {
    return (
        <div>
            <CommonSectionText title="Transfer of personal information abroad:" />
            <p className="mt-0 mb-3">
                If you utilize our Services from a country other than the country where our servers
                are located, your personal information may be transferred across international
                borders, which will only be done when necessary for the performance of our contract
                with you, when we have your consent to do so, or subject to the appropriate standard
                contractual clauses. Also, when you call us or initiate a chat, we may provide you
                with support from one of our global locations outside your country of origin.
            </p>
        </div>
    );
}

function LegalRequest() {
    return (
        <div>
            <CommonSectionText title="Compliance with legal, regulatory and law enforcement requests:" />
            <p className="mt-0">
                BIKIRAN cooperate with government and law enforcement officials and private parties
                to enforce and comply with the law. We may disclose any information about you to
                government or law enforcement officials or private parties as we, in our sole
                discretion, believe necessary or appropriate to respond to claims and legal process
                (such as subpoena requests), to protect our property and rights or the property and
                rights of a third party, to protect the safety of the public or any person, or to
                prevent or stop activity we consider to be illegal or unethical.
            </p>
            <p className="mb-3">
                To the extent we are legally permitted to do so, we may take reasonable steps to
                notify you if we are required to provide your personal information to third parties
                as part of legal process. We will also share your information to the extent
                necessary to comply with any ICANN, registry or ccTLD rules, regulations and
                policies when you register a domain name with us. For reasons critical to
                maintaining the security, stability and resiliency of the Internet, this includes
                the transfer of domain name registration information to the underlying domain
                registry operator and escrow provider, and publication of that information as
                required by ICANN in the public WHOIS database or with other third parties that
                demonstrate a legitimate legal interest to such information.
            </p>
        </div>
    );
}

function SecureData() {
    const info = [
        'The length of time we have an ongoing relationship with you and provide Services to you (for example, for as long as you have an account with us or keep using our Services)',
        'Whether account owners modify or their users delete information through their accounts;',
        'Whether we have a legal obligation to keep the data (for example, certain laws require us to maintain records of your transactions for a certain period of time before we can delete them); or',
        'Whether retention is advisable in light of our legal position (such as in regard to the enforcement of our agreements, the resolution of disputes, and applicable statutes of limitations, litigation, or regulatory investigation).'
    ];
    return (
        <div>
            <CommonSectionText title="How we secure, store and retain your data:" />
            <p className="mt-0">
                We follow generally accepted standards to store and protect the personal information
                we collect, both during transmission and once received and stored, including
                utilization of encryption where appropriate. We retain personal information for as
                long as required to engage in the uses described in this Privacy Policy, unless a
                longer retention period is required by applicable law.
            </p>
            <p>The criteria used to determine our retention periods include the following:</p>
            <CommonListArea info={info} />
            <p className="mb-3">
                If you have any questions about the security or retention of your personal
                information, you can contact us at:{' '}
                <a href="mailto:privacy@bikiran.com">
                    <span className="purple">privacy@bikiran.com</span>
                </a>
            </p>
        </div>
    );
}

function AccessData() {
    return (
        <div>
            <CommonSectionText title="How you can access, update or delete your data:" />
            <p className="mt-0">
                To easily access, view, update, delete or port your personal information, or to
                update your subscription preferences, please sign into your Account and visit
                “Account Settings”.
            </p>
            <p>
                If you make a request to delete your personal information and that data is necessary
                for the products or services you have purchased, the request will be honored only to
                the extent it is no longer necessary for any Services purchased or required for our
                legitimate business purposes or legal or contractual record keeping requirements.
            </p>
            <p className="mb-3">
                If you are unable for any reason to access your Account Settings or our Trust
                Center, you may also contact us by one of the methods described in the “Contact Us”
                section below.
            </p>
        </div>
    );
}

function PrivacyShieldFramework() {
    return (
        <div>
            <CommonSectionText title="The E.U-U.S and Swiss-U.S. Privacy Shield Frameworks:" />
            <p className="mt-0">
                On July 16, 2020, the Court of Justice of the European Union issued a judgment
                declaring as “invalid” the European Commission’s Decision (EU) 2016/1250 of 12 July
                2016 on the adequacy of the protection provided by the EU-U.S. Privacy Shield. As a
                result of that decision, the EU-U.S. Privacy Shield Framework is no longer a valid
                mechanism to comply with EU data protection requirements when transferring personal
                data from the European Union to the United States.
            </p>
            <p>
                Nonetheless, the U.S. Department of Commerce continues to administer the Privacy
                Shield program, and our parent company, BIKIRAN, complies with the EU-U.S. Privacy
                Shield Framework as set forth by the U.S. Department of Commerce regarding the
                collection, use, and retention of personal information transferred from the European
                Union to the United States. BIKIRAN has certified to the U.S. Department of Commerce
                that it adheres to the Privacy Shield Principles. If there is any conflict between
                the terms in this privacy policy and the Privacy Shield Principles, the Privacy
                Shield Principles shall govern. To learn more about the Privacy Shield program, and
                to view our certification, please visit{' '}
                <Link to="/https://www.privacyshield.gov/">
                    <span className="purple">https://www.privacyshield.gov/</span>
                </Link>
                .
            </p>
            <p>
                BIKIRAN is responsible for the processing of personal information it receives, under
                each Privacy Shield Framework, and subsequently transfers to a third party acting as
                an agent on its behalf. BIKIRAN complies with the Privacy Shield Principles for all
                onward transfers of personal information from the EU and Switzerland, including the
                onward transfer liability provisions.
            </p>
            <p>
                With respect to personal information received or transferred pursuant to each
                Privacy Shield Framework, BIKIRAN.COM is subject to the regulatory enforcement
                powers of the U.S. Federal Trade Commission. In certain situations, BIKIRAN.COM may
                be required to disclose personal information in response to lawful requests by
                public authorities, including to meet national security or law enforcement
                requirements.
            </p>
            <p>
                If you have an unresolved privacy or data use concern that we have not addressed
                satisfactorily, please contact our U.S.-based third party dispute resolution
                provider (free of charge) at https://feedback-form.truste.com/watchdog/request.
                Under certain conditions, more fully described on the{' '}
                <a target="_blank" href="https://www.privacyshield.gov/" rel="noreferrer">
                    <span className="purple">Privacy Shield website</span>
                </a>
                , you may invoke binding arbitration when other dispute resolution procedures have
                been exhausted.
            </p>
            <p>
                In compliance with the Privacy Shield Principles, BIKIRAN.COM commits to resolve
                complaints about our collection or use of your personal information. EU and Swiss
                individuals with inquiries or complaints regarding our Privacy Shield policy should
                first contact us in any manner provided in the `CONTACT US` section below in this
                Privacy Policy.
            </p>
            <p className="mb-3">
                BIKIRAN.COM has further committed to cooperate with the panel established by the EU
                data protection authorities (DPAs) and the Swiss Federal Data Protection and
                Information Commissioner (FDPIC) with regard to unresolved Privacy Shield complaints
                concerning human resources data transferred from the EU and Switzerland in the
                context of the employment relationship.
            </p>
            <h4 className="value-title mb-3" style={{ fontStyle: 'italic' }}>
                ‘Do Not Track’ notifications:
            </h4>
            <p>
                Some browsers allow you to automatically notify websites you visit not to track you
                using a “Do Not Track” signal. There is no consensus among industry participants as
                to what “Do Not Track” means in this context. Like many websites and online
                services, we currently do not alter our practices when we receive a “Do Not Track”
                signal from a visitor’s browser. To find out more about “Do Not Track,” you may
                visit{' '}
                <a target="_blank" href="https://www.allaboutdnt.com" rel="noreferrer">
                    <span className="purple">www.allaboutdnt.com</span>
                </a>
                .
            </p>
        </div>
    );
}

function AgeRestrictions() {
    return (
        <div>
            <CommonSectionText title="Age restrictions:" />
            <p className="mt-0 mb-3">
                Our Services are available for purchase only for those over the age of 18. Our
                Services are not targeted to, intended to be consumed by or designed to entice
                individuals under the age of 18. If you know of or have reason to believe anyone
                under the age of 18 has provided us with any personal information, please contact us
                per the instructions below.
            </p>
        </div>
    );
}

function NonDiscrimination() {
    const info = [
        'Deny you goods or services.',
        'Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.',
        'Provide you a different level or quality of goods or services.',
        'Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.'
    ];
    return (
        <div>
            <CommonSectionText title="Non-Discrimination:" />
            <p className="mt-0">
                We will not discriminate against you for exercising any of your privacy rights.
                Unless permitted under applicable laws, we will not:
            </p>
            <CommonListArea info={info} />
        </div>
    );
}

function ChangesPolicy() {
    return (
        <div>
            <CommonSectionText title="Changes to this policy:" />
            <p className="mt-0 mb-3">
                BIKIRAN reserve the right to modify this Privacy Policy at any time. If we decide to
                change our Privacy Policy, we will post those changes to this Privacy Policy and any
                other places we deem appropriate, so that you are aware of what information we
                collect, how we use it, and under what circumstances, if any, we disclose it. If we
                make material changes to this Privacy Policy, we will notify you here, by email, or
                by means of a notice on our home page, at least thirty (30) days prior to the
                implementation of the changes.
            </p>
        </div>
    );
}
function ContactUs() {
    // const font = { fontSize: '16px' };
    return (
        <div>
            <CommonSectionText title="Contact us:" />
            <p className="mt-0 mb-3">
                If you have any questions, concerns, or complaints about our Privacy Policy, or how
                we handle your personal data, you may contact our Office of the Data Protection
                Officer by email at{' '}
                <a href="mailto:privacy@bikiran.com">
                    <span className="purple">privacy@bikiran.com</span>
                </a>
                . In the alternative, you may contact us by either of the following means:
            </p>
            <ul className="address-area">
                <li className="list-disc value-title fz-16">
                    <p className="title">Office Address</p>
                    <p className="value">
                        <span className="title">Khulna</span>: Room 401, Sheikh Kamal IT Incubation
                        & Training Center KUET, KUET Road, Khulna 9203, Bangladesh
                    </p>
                    <p className="value">
                        <span className="title">Dhaka</span>: House-02, Block-F, Shangbadik Abashik
                        Elaka, Kalshi Road, Mirpur-11, Dhaka-1216, Bangladesh
                    </p>
                </li>
                <li className="list-disc value-title fz-16">
                    <p className="title">Phone Number</p>
                    <p className="value">
                        <span className="title">
                            <a href="tel:+880 1925 363333">+880 1925 363333</a>
                        </span>{' '}
                        <span className="title ml-2">
                            <a href="tel:+880 1616 000951">+880 1616 000951</a>
                        </span>
                    </p>
                </li>
            </ul>
        </div>
    );
}

function AreaFooter() {
    return (
        <div>
            <CommonSectionText title="We will respond to all requests, inquiries or concerns within thirty (30) days." />
            <p className="mt-0">
                If you are not satisfied with our response, you may direct privacy complaints to
                your local data protection authority. BIKIRAN is the data controller for
                www.bikiran.com.
            </p>
        </div>
    );
}

function PrivacyPolicySection() {
    return (
        <div className="policy-area-container container">
            <SectionTitleArea />
            <hr style={{ opacity: '40%' }} />
            <InfoText />
            <InfoWeCollect />
            <CoockiesAndTech />
            <SupplementedData />
            <HowWeUtilizeInfo />
            <SharingWithThirdParties />
            <Advertisement />
            <Communicating />
            <TransferInfoToAbroad />
            <LegalRequest />
            <SecureData />
            <AccessData />
            <PrivacyShieldFramework />
            <AgeRestrictions />
            <NonDiscrimination />
            <ChangesPolicy />
            <ContactUs />
            <AreaFooter />
        </div>
    );
}

export default PrivacyPolicySection;
