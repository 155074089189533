/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import CommonModal from '../modals/CommonModal';

function HostingBundleSection() {
    const [openModal, setOpenModal] = useState(null);
    const bundles = [
        {
            id: 1,
            type: 'VPS',
            discount: '25% Off',
            specification: '2 Core CPU & 4GB RAM Multi Location',
            price: '$49.99',
            duration: 'Month',
            description:
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum molestiae a laudantium quos ducimus commodi ipsum inventore omnis ab magni?'
        },
        {
            id: 2,
            type: 'Shared',
            discount: '30% Off',
            specification: '2 Core CPU & 4GB RAM Multi Location',
            price: '$64.99',
            duration: 'Year',
            description:
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum molestiae a laudantium quos ducimus commodi ipsum inventore omnis ab magni?'
        },
        {
            id: 3,
            type: 'Dedicated',
            discount: '20% Off',
            specification: '8 Core CPU & 32GB RAM Multi Location',
            price: '$129.99',
            duration: 'Month',
            description:
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum molestiae a laudantium quos ducimus commodi ipsum inventore omnis ab magni?'
        }
    ];
    return (
        <div className="hosting-bundle-container">
            {bundles.map((bundle) => (
                <div key={bundle?.id} className="bundle-content">
                    <p>{bundle?.discount}</p>
                    <p>{bundle?.type}</p>
                    <p>{bundle?.specification}</p>
                    <p>
                        {bundle?.price}/<small>{bundle?.duration}</small>
                    </p>
                    <button onClick={() => setOpenModal(true)} type="button">
                        <p>Add to cart</p>
                    </button>
                </div>
            ))}
            {openModal && <CommonModal openModal={openModal} setOpenModal={setOpenModal} />}
        </div>
    );
}
export default HostingBundleSection;
