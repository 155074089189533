import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppLoginPage from './app/applogin/AppLoginPage';
import ILoginPage from './app/applogin/ILoginPage';
import LoginPage from './app/auth/login_page/LoginPage';
import CartPage from './app/cart/CartPage';
import AccessWizardPage from './app/manage/_models/AccessWizardPage';
import ActivityLogPage from './app/manage/activity-log/ActivityLogPage';
import ProfileAddressPage from './app/manage/addresses/ProfileAddressPage';
import UsersBackupPage from './app/manage/backup/UsersBackupPage';
import UsersPointPage from './app/manage/bikiran-points/UsersPointPage';
import BillingsInvoiceInfoPage from './app/manage/billings/invoice_info/BillingsInvoiceInfoPage';
import BillingsInvoiceListPage from './app/manage/billings/invoice_list/BillingsInvoiceListPage';
import UsersPaymentMethodPage from './app/manage/billings/payment_methods/UsersPaymentMethodPage';
import UsersTransactionPage from './app/manage/billings/transaction/UsersTransactionPage';
import UsersContactPage from './app/manage/contact/UsersContactPage';
import UserDnsPage from './app/manage/dns/UserDnsPage';
import DomainProfilePage from './app/manage/domain-profile/DomainProfilePage';
import DomainInfoConversationPage from './app/manage/domains_Info_conversation/DomainInfoConversationPage';
import DomainActivityLogPage from './app/manage/domains_info_activity/DomainActivityLogPage';
import DomainInfoBasicPage from './app/manage/domains_info_basic/DomainInfoBasicPage';
import DomainInfoCnsPage from './app/manage/domains_info_cns/DomainInfoCnsPage';
import DomainInfoContractsPage from './app/manage/domains_info_contacts/DomainInfoContactsPage';
import DomainInfoDnsPage from './app/manage/domains_info_dns/DomainInfoDnsPage';
import DomainInfoFaqPage from './app/manage/domains_info_faq/DomainInfoFaqPage';
import DomainInfoNsPage from './app/manage/domains_info_ns/DomainInfoNsPage';
import DomainInfoTransferPage from './app/manage/domains_info_transfer/DomainInfoTransferPage';
import DomainListPage from './app/manage/domains_list/DomainListPage';
import DomainTransferPage from './app/manage/domains_transfer/DomainTransferPage';
import HostingInfoBasicPage from './app/manage/hostings_Info_basic/HostingInfoBasicPage';
import HostingInfoConversationListPage from './app/manage/hostings_Info_conversation/HostingInfoConversationListPage';
import HostingInfoConversationPage from './app/manage/hostings_Info_conversation/HostingInfoConversationPage';
import HostingInfoDatabasesPage from './app/manage/hostings_Info_databases/HostingInfoDatabasesPage';
import HostingInfoEmailPage from './app/manage/hostings_Info_email/HostingInfoEmailPage';
import HostingInfoStoragePage from './app/manage/hostings_Info_storage/HostingInfoStoragePage';
import HostingActivityLogPage from './app/manage/hostings_activitylog/HostingActivityLogPage';
import HostingInfoBackupPage from './app/manage/hostings_info_backup/HostingInfoBackupPage';
import HostingInfoDnsPage from './app/manage/hostings_info_dns/HostingInfoDnsPage';
import HostingInfoFaqPage from './app/manage/hostings_info_faq/HostingInfoFaqPage';
import UsersHostingListPage from './app/manage/hostings_list/UsersHostingListPage';
import PrivacyPolicySection from './app/manage/legal/components/PrivacyPolicySection';
import RefundPolicySection from './app/manage/legal/components/RefundPolicySection';
import TabsArea from './app/manage/legal/components/TabsArea';
import TermsAgreementSection from './app/manage/legal/components/TermsAgreementSection';
import TermsConditionPage from './app/manage/legal/pages/TermsConditionPage';
import UsersLicensePage from './app/manage/license/UsersLicensePage';
import UserNotificationPage from './app/manage/notification/UserNotificationPage';
import UserOrgRolePage from './app/manage/organizational-role/UserOrgRolePage';
import ProfileOverviewPage from './app/manage/profile-overview/ProfileOverviewPage';
import UsersRenewPage from './app/manage/renew/UsersRenewPage';
import RoleInvitationPage from './app/manage/role/RoleInvitationPage';
import SecurityPage from './app/manage/security/SecurityPage';
import TwoFactorAuthPage from './app/manage/security/TwoFactorAuthPage';
import UsersServerPage from './app/manage/servers/pages/UsersServerPage';
import UserSocialMediaPage from './app/manage/social-media/UserSocialMediaPage';
import UserBasicInfoPage from './app/manage/user-basic-info/UserBasicInfoPage';
import InvoicePrintPage from './app/payment/InvoicePrintPage';
import PaymentPage from './app/payment/PaymentPage';
import PostmanPage from './app/postman/PostmanPage';
import PowerAuthHandler from './app/power/models/PowerAuthHandler';
import PowerCartPage from './app/power/pages/PowerCartPage';
import PowerDashboardPage from './app/power/pages/PowerDashboardPage';
import SearchPage from './app/search/SearchPage';
import EcomSoftwarePage from './app/services/EcomSoftwarePage';
import EmailServicePage from './app/services/EmailServicePage';
import NewsSoftwarePage from './app/services/NewsSoftwarePage';
import PushNotificationPage from './app/services/PushNotificationPage';
import ServicesPage from './app/services/ServicesPage';
import SmsServicePage from './app/services/SmsServicePage';
import CloudVmPage from './app/services/server-service/pages/CloudVmPage';
import DedicatedServerPage from './app/services/server-service/pages/DedicatedServerPage';
import ServerServicePage from './app/services/server-service/pages/ServerServicePage';
import VpsPage from './app/services/server-service/pages/VpsPage';
import SoftwareServicePage from './app/services/software-service/SoftwareServicePage';
import SupportActivityLogPage from './app/smanager/manage/activity-log/SupportActivityLogPage';
import UsersBillingsPage from './app/smanager/manage/billings/UsersBillingsPage';
import SupportCartPage from './app/smanager/manage/cart/SupportCartPage';
import ContractsDomainPage from './app/smanager/manage/contracts_domain/ContractsDomainPage';
import ContractsEmailPage from './app/smanager/manage/contracts_email/ContractsEmailPage';
import ContractsHostingPage from './app/smanager/manage/contracts_hosting/ContractsHostingPage';
import ContractsServerPage from './app/smanager/manage/contracts_server/ContractsServerPage';
import ContractsSmsPage from './app/smanager/manage/contracts_sms/ContractsSmsPage';
import ContractsSoftwarePage from './app/smanager/manage/contracts_software/ContractsSoftwarePage';
import SupportTicketPage from './app/smanager/manage/support-ticket/SupportTicketPage';
import SupportUserDetailsPage from './app/smanager/manage/users/SupportUserDetailsPage';
import SupportUserPage from './app/smanager/manage/users/SupportUserPage';
import SupportManagerHandler from './app/smanager/models/SupportManagerHandler';
import AppHostingSection from './app/system/components/AppHostingSection';
import EmailHostingSection from './app/system/components/EmailHostingSection';
import WebHostingSection from './app/system/components/WebHostingSection';
import DomainPage from './app/system/pages/DomainPage';
import HostingService from './app/system/pages/HostingService';
import UserAuthHandler from './app/users/models/UserAuthHandler';
import UsersBalance from './app/users/pages/UsersBalance';
import UsersDashboardPage from './app/users/pages/UsersDashboardPage';
import UsersEmailPage from './app/users/pages/UsersEmailPage';
import UsersSmsPage from './app/users/pages/UsersSmsPage';
import UsersSoftwarePage from './app/users/pages/UsersSoftwarePage';
import IconGrid from './app/websites/components/IconGrid';
import AboutPage from './app/websites/pages/AboutPage';
import AffiliatePage from './app/websites/pages/AffiliatePage';
import ClientsPage from './app/websites/pages/ClientsPage';
import ContactUsPage from './app/websites/pages/ContactUsPage';
import DevelopersPage from './app/websites/pages/DevelopersPage';
import IndexPage from './app/websites/pages/IndexPage';
import IndexPageBackup from './app/websites/pages/IndexPageBackup';
import KnowledgeBasePage from './app/websites/pages/KnowledgeBasePage';
import NotFoundPage from './app/websites/pages/NotFoundPage';
import OurTeamPage from './app/websites/pages/OurTeamPage';
import PricingPage from './app/websites/pages/PricingPage';
import QuestionDetailsPage from './app/websites/pages/QuestionDetailsPage';
import SupportPage from './app/websites/pages/SupportPage';
import AcceptCookiePopup from './utils/AcceptCookiePopup';
import DebugInfo from './utils/DebugInfo';

function RouteHandler() {
    return (
        <BrowserRouter>
            <Routes>
                {/* Home & Search Page */}
                <Route path="/*" element={<NotFoundPage />} />
                <Route path="/" element={<IndexPage />} />
                <Route path="/search/" element={<SearchPage />} />
                <Route path="/role/invitation/" element={<RoleInvitationPage />} />
                <Route path="/login/" element={<LoginPage />} />

                {/* All Product & Service Related Page */}
                <Route path="/domain/" element={<DomainPage page="domain" />} />
                <Route path="/services/" element={<ServicesPage page="services" />} />
                <Route path="/access/" element={<AccessWizardPage />} />
                <Route path="/services/*">
                    <Route path="/services/*" element={<HostingService page="hosting-service" />}>
                        <Route path="hosting/web/" element={<WebHostingSection />} />
                        <Route path="hosting/app/" element={<AppHostingSection />} />
                        <Route path="hosting/email/" element={<EmailHostingSection />} />
                    </Route>
                    <Route path="server/" element={<ServerServicePage />} />
                    <Route path="server/cloud-vm/" element={<CloudVmPage />} />
                    <Route path="server/vps/" element={<VpsPage />} />
                    <Route path="server/dedicated/" element={<DedicatedServerPage />} />

                    <Route path="software/" element={<SoftwareServicePage />} />
                    <Route path="software/e-commerce/" element={<EcomSoftwarePage />} />
                    <Route path="software/news-portal/" element={<NewsSoftwarePage />} />

                    <Route path="send/sms-service/" element={<SmsServicePage />} />
                    <Route path="send/email/" element={<EmailServicePage />} />
                    <Route path="send/push/" element={<PushNotificationPage />} />
                </Route>
                <Route path="/pricing/" element={<PricingPage />} />

                {/*  Cart & Checkout Related Pages */}
                <Route path="/cart/" element={<CartPage page="cart" />} />
                <Route path="/payment/:invoiceId/" element={<PaymentPage />} />
                <Route path="/payment/:invoiceId/print" element={<InvoicePrintPage />} />

                {/* Information Page */}
                <Route path="/clients/" element={<ClientsPage page="clients" />} />
                <Route path="/about-us/" element={<AboutPage page="about" />} />
                <Route path="/our-team/" element={<OurTeamPage />} />
                <Route path="/support/" element={<SupportPage />} />
                <Route path="/contact-us/" element={<ContactUsPage />} />
                <Route path="/knowledge-base/" element={<KnowledgeBasePage />} />
                <Route path="/knowledge-base/:id" element={<QuestionDetailsPage />} />

                {/* All Legal Related Pages */}
                <Route path="/legal/" element={<TermsConditionPage />} />
                <Route path="/legal/*" element={<TabsArea />}>
                    <Route path="terms-of-service/" element={<TermsAgreementSection />} />
                    <Route path="privacy-policy/" element={<PrivacyPolicySection />} />
                    <Route path="refund-policy/" element={<RefundPolicySection />} />
                </Route>

                <Route path="/auth/*" element={<UserAuthHandler />}>
                    <Route path="app-login/" element={<AppLoginPage />} />
                </Route>
                <Route path="auth/i-login/" element={<ILoginPage />} />

                {/* User */}
                <Route path="/user/*" element={<UserAuthHandler />}>
                    <Route path="profile-overview/" element={<ProfileOverviewPage />} />
                    <Route path="dashboard/" element={<UsersDashboardPage />} />
                    <Route path="basic-info/" element={<UserBasicInfoPage />} />
                    <Route path="notifications/" element={<UserNotificationPage />} />
                    <Route path="organizational-role/" element={<UserOrgRolePage />} />
                    <Route path="domain-profile/" element={<DomainProfilePage />} />
                    <Route path="security-and-login/" element={<SecurityPage />} />
                    <Route path="security-and-login/2facAuth/" element={<TwoFactorAuthPage />} />
                    <Route path="activity-log/" element={<ActivityLogPage />} />
                    <Route path="contacts/" element={<UsersContactPage />} />
                    <Route path="addresses/" element={<ProfileAddressPage />} />
                    <Route path="social-url/" element={<UserSocialMediaPage />} />
                    {/* <Route path="social-url/" element={<UserSocialMediaPageBackup />} /> */}
                    <Route path="balance/" element={<UsersBalance />} />
                    <Route path="points/" element={<UsersPointPage />} />

                    <Route path="services/domain/" element={<DomainListPage />} />
                    <Route path="services/dns/" element={<UserDnsPage />} />
                    <Route path="services/backup/" element={<UsersBackupPage />} />
                    <Route path="services/license/" element={<UsersLicensePage />} />
                    <Route path="services/domain/:contractId/" element={<DomainInfoBasicPage />} />
                    <Route
                        path="services/domain/:contractId/activity/"
                        element={<DomainActivityLogPage />}
                    />
                    <Route
                        path="services/domain/:contractId/nameservers/"
                        element={<DomainInfoNsPage />}
                    />
                    <Route
                        path="services/domain/:contractId/child-nameserver/"
                        element={<DomainInfoCnsPage />}
                    />
                    <Route
                        path="services/domain/:contractId/contacts/"
                        element={<DomainInfoContractsPage />}
                    />
                    <Route
                        path="services/domain/:contractId/transfer-out/"
                        element={<DomainInfoTransferPage />}
                    />
                    <Route
                        path="services/domain/:contractId/conversation/"
                        element={<DomainInfoConversationPage />}
                    />
                    <Route path="services/:type/renew/:contractId" element={<UsersRenewPage />} />
                    <Route
                        path="services/domain/:contractId/transfer-out"
                        element={<DomainTransferPage />}
                    />
                    {/* hosting */}
                    <Route path="services/domain/:contractId/faq" element={<DomainInfoFaqPage />} />
                    <Route path="services/domain/:contractId/dns" element={<DomainInfoDnsPage />} />

                    <Route path="services/hosting/" element={<UsersHostingListPage />} />
                    <Route
                        path="services/hosting/:contractId/"
                        element={<HostingInfoBasicPage />}
                    />
                    <Route
                        path="services/hosting/:contractId/emails/"
                        element={<HostingInfoEmailPage />}
                    />
                    <Route
                        path="services/hosting/:contractId/databases/"
                        element={<HostingInfoDatabasesPage />}
                    />
                    <Route
                        path="services/hosting/:contractId/storage/"
                        element={<HostingInfoStoragePage />}
                    />
                    <Route
                        path="services/hosting/:contractId/conversation/"
                        element={<HostingInfoConversationListPage />}
                    />
                    <Route
                        path="services/hosting/:contractId/conversation/team/"
                        element={<HostingInfoConversationPage />}
                    />
                    <Route
                        path="services/hosting/:contractId/activity/"
                        element={<HostingActivityLogPage />}
                    />
                    <Route
                        path="services/hosting/:contractId/faq"
                        element={<HostingInfoFaqPage />}
                    />
                    <Route
                        path="services/hosting/:contractId/dns"
                        element={<HostingInfoDnsPage />}
                    />
                    <Route
                        path="services/hosting/:contractId/backup"
                        element={<HostingInfoBackupPage />}
                    />

                    {/* hosting */}
                    <Route path="services/server/:serverType/" element={<UsersServerPage />} />
                    <Route path="services/sms-package/" element={<UsersSmsPage />} />
                    <Route path="services/email/" element={<UsersEmailPage />} />
                    <Route path="services/software/" element={<UsersSoftwarePage />} />

                    <Route path="billing/invoice/" element={<BillingsInvoiceListPage />} />
                    <Route
                        path="billing/invoice/:invoiceId/"
                        element={<BillingsInvoiceInfoPage />}
                    />
                    <Route path="billing/payment-methods/" element={<UsersPaymentMethodPage />} />
                    <Route path="billing/transactions/" element={<UsersTransactionPage />} />
                </Route>

                <Route path="/icons" element={<IconGrid />} />
                <Route path="/postman/" element={<PostmanPage />} />
                <Route path="/developers/" element={<DevelopersPage />} />

                <Route path="/old-home" element={<IndexPageBackup />} />
                <Route path="/affiliate/" element={<AffiliatePage />} />

                {/* <Route path="/images/" element={<ImageFiles />} /> */}
                {/* <Route path="/privacy-policy/" element={<PrivacyPolicyPage />} /> */}
                {/* <Route path="/servicePage/" element={<ServicePage />} /> */}
                {/* <Route path="/services/hosting/web/" element={<WebHostingPage />} /> */}

                {/* Support Manager */}
                <Route path="/smanager/*" element={<SupportManagerHandler />}>
                    <Route path="ticket/" element={<SupportTicketPage />} />
                    <Route path="cart/" element={<SupportCartPage />} />
                    <Route path="users/" element={<SupportUserPage />} />
                    <Route path="users/:id" element={<SupportUserDetailsPage />} />
                    <Route path="billings/" element={<UsersBillingsPage />} />
                    <Route path="contracts/*">
                        <Route path="hosting/" element={<ContractsHostingPage />} />
                        <Route path="domain/" element={<ContractsDomainPage />} />
                        <Route path="server/" element={<ContractsServerPage />} />
                        <Route path="software/" element={<ContractsSoftwarePage />} />
                        <Route path="sms/" element={<ContractsSmsPage />} />
                        <Route path="email/" element={<ContractsEmailPage />} />
                    </Route>
                    <Route path="activity-log/" element={<SupportActivityLogPage />} />
                </Route>

                {/* Admin Dashboard */}
                <Route path="/power/*" element={<PowerAuthHandler />}>
                    <Route path="dashboard/" element={<PowerDashboardPage />} />
                    <Route path="cart-manage/" element={<PowerCartPage />} />

                    <Route path="invoice/" element={null} />
                    <Route path="invoice/:invoiceId/" element={null} />
                    <Route path="invoice-items/" element={null} />

                    <Route path="contract/domains/" element={null} />
                    <Route path="contract/hosting/" element={null} />
                    <Route path="contract/servers/" element={null} />
                    <Route path="contract/softwares/" element={null} />
                    <Route path="contract/send/" element={null} />

                    <Route path="finance/accounts/" element={null} />
                    <Route path="finance/gateway-transactions/" element={null} />
                    <Route path="finance/transactions/" element={null} />

                    <Route path="log/history/" element={null} />
                    <Route path="log/query-errors/" element={null} />

                    <Route path="user/" element={null} />
                    <Route path="user/:userId/" element={null} />
                    <Route path="user/login-logs/" element={null} />
                    <Route path="user/addresses/" element={<ProfileAddressPage />} />
                    <Route path="user/providers/" element={null} />

                    <Route path="services/" element={null} />
                    <Route path="services/:serviceId/" element={null} />
                </Route>
            </Routes>

            <AcceptCookiePopup />
            <DebugInfo show={false} />
        </BrowserRouter>
    );
}
export default RouteHandler;
