import { useEffect, useRef, useState } from 'react';

import { icons } from '../../../configs/Icons';

function InputCopy({
    title,
    name,
    onBlur,
    setValue,
    value,
    placeholder,
    disabled = false,
    height = 45
}) {
    const ref = useRef();

    const inputVal = value?.length;

    const inputText = inputVal || '';

    const [mode, setMode] = useState(null);

    useEffect(() => {
        setTimeout(() => {
            setMode(false);
        }, 3000);
    });

    return (
        <div>
            <div className="label">{title}</div>
            <div className="input-copy">
                <input
                    ref={ref}
                    onBlur={onBlur}
                    onChange={setValue}
                    value={value || ''}
                    placeholder={placeholder || 'Type....'}
                    type="text"
                    name={name}
                    className="form-input"
                    disabled={disabled}
                    height={`${height}px`}
                />
                <span
                    hidden={!inputVal}
                    role="button"
                    tabIndex={-1}
                    onClick={() => {
                        if (navigator.clipboard.writeText(inputText)) {
                            setMode(true);
                        }
                    }}
                    style={{ top: height === 45 ? '12px' : '10px' }}
                >
                    <img
                        className="d-block w-100"
                        src={mode ? icons.iconCheckD : icons.iconCopyV2}
                        alt="copy"
                    />
                </span>
            </div>
        </div>
    );
}

export default InputCopy;
