import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonGr } from '../../../../configs/Buttons';
import { editIcons, icons } from '../../../../configs/Icons';
import { useTemplate } from '../../../contexts/TemplateProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import { GetDate } from '../../../utils/Show';
import PaymentInvoiceProducts from './invoice/PaymentInvoiceProducts';

const InvoiceInfo = ({ invoiceInfo, isDue }) => {
    const navigate = useNavigate();
    const { invoiceId } = useParams();
    // if (!invoiceInfo) {
    //     return null;
    // }
    return (
        <div className="invoice-info">
            {/* <span className="font-18 clr">Details</span> */}
            <div className="line">
                <div className="cell cell-fill font-18 clr">Details</div>
                {!isDue && (
                    <div className="cell print-none d-sm-none">
                        <ButtonGr
                            evOnClick={() => navigate(`/payment/${invoiceId}/print`)}
                            title="Print"
                        />
                    </div>
                )}
            </div>
            <div className="line invoice-info-line">
                <div className="cell cell-fill">Invoice Number:</div>
                <div className="cell">
                    <span>#{invoiceInfo?.id || '------------'}</span>
                </div>
            </div>
            <div className="line invoice-info-line">
                <div className="cell cell-fill">Date of Issue:</div>
                <div className="cell">
                    <span>{GetDate(invoiceInfo?.issueDate) || '-----------'}</span>
                </div>
            </div>
            <div className="line invoice-info-line">
                <div className="cell cell-fill">Payment Due Date:</div>
                <div className="cell">
                    <span>{GetDate(invoiceInfo?.dueToDate) || '-----------'}</span>
                </div>
            </div>
        </div>
    );
};
const AddressFrom = ({ invoiceData }) => (
    <div className="address-view">
        <span className="font-18 clr">From</span>
        <div className="name">
            <span>BIKIRAN.COM</span>
        </div>
        <div className="address">
            <span>
                {' '}
                {invoiceData
                    ? 'House-02, Block-F, Shangbadik Abashik Elaka, Kalshi Road, Mirpur-11, Dhaka-1216,Bangladesh'
                    : '---------------------'}
            </span>
        </div>
        <div className="contact">
            Contact:{' '}
            <span>
                <b>880 1925 363333</b>
            </span>
        </div>
    </div>
);

function EditIcon({ openAddressEditor, addressBilling }) {
    return (
        <span
            className="cell"
            onClick={() => {
                openAddressEditor(addressBilling);
            }}
            role="button"
            tabIndex={-1}
        >
            <img src={editIcons.iconEditV3} alt="" style={{ width: '20px', display: 'block' }} />
        </span>
    );
}

const AddressTo = ({ addressBilling, openAddressEditor, isDue }) => {
    const { name, org, email, mobile, line1, line2, line3, country, state, city, zip } =
        addressBilling || {};

    // if (!addressBilling) {
    //     return null;
    // }

    return (
        <div className="address-view">
            <h4 className="line-align line-g1">
                <div className="cell">To</div>
                {isDue && (
                    <EditIcon
                        openAddressEditor={openAddressEditor}
                        addressBilling={addressBilling}
                    />
                )}
            </h4>
            <div className="name">
                <span className="gap">
                    {name} {org ? `(${org})` : '----------------'}
                </span>
            </div>
            <div className="address">
                <span>
                    {[line1, line2, line3, city, state, zip, country]
                        .filter((x) => !!x)
                        .join(', ') || '------------------'}
                </span>
            </div>
            <div className="contact break-all">
                Contact:{' '}
                <span>
                    <b>{mobile || '---------'}</b>, <b>{email || '---------'}</b>
                </span>
            </div>
        </div>
    );
};

function PaymentInvoiceView({ invoiceData, openAddressEditor, isDue }) {
    const { setMessage } = useTemplate();
    const addressBilling = invoiceData?.addressBilling;

    const handelExecute = () => {
        setMessage('Loading...');
        AxiosAuth.setUrl(`https://demo.io.bikiran.com/invoice/create-contract/${invoiceData?.id}/`)
            .get()
            .then(({ data }) => {
                setMessage(data.message);
            });
    };

    return (
        <div className="invoice-area print-area">
            <div className="invoice-header">
                <div className="d-none print mb-4">
                    <img className="bik-logo " src={icons.iconBikLogoV2} alt="" />
                </div>
                <div className="line mb-6">
                    <div className="cell cell-12">
                        <div className="line line-no-wrap line-sm-wrap mb-6">
                            <div className="cell cell-5 cell-sm-12 mb-sm-6">
                                <AddressFrom invoiceData={invoiceData} />
                            </div>
                            <div className="cell cell-2 d-sm-none">&nbsp;</div>
                            <div className="cell cell-5 cell-sm-12">
                                <InvoiceInfo
                                    isDue={isDue}
                                    invoiceInfo={invoiceData || {}}
                                    items={invoiceData?.invoiceItems}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="cell-cell-12">
                        <AddressTo
                            isDue={isDue}
                            addressBilling={addressBilling}
                            openAddressEditor={openAddressEditor}
                        />
                    </div>
                </div>
            </div>

            <div className="invoice-summery">
                <h4 className="order-summery-text">Order Summery</h4>
                <PaymentInvoiceProducts invoiceData={invoiceData || {}} />
            </div>

            {invoiceData?.itemBeExecute > 0 ? (
                <div>
                    <button type="button" onClick={handelExecute}>
                        Execute 1 Item
                    </button>
                </div>
            ) : null}
        </div>
    );
}

export default PaymentInvoiceView;
