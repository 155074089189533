import { ButtonClose } from '../../../../configs/Buttons';

const ModalHeader = ({ setShowDt }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title" />
            <div className="modal-box-close">
                <ButtonClose evOnClick={() => setShowDt({ type: null })} />
            </div>
        </div>
    </div>
);

function ModalBody({ showDt }) {
    return (
        <div className="container h-100 text-center">
            <div style={{ padding: '16px 20px' }}>
                <h2>{showDt?.status}</h2>
            </div>
        </div>
    );
}

function TicketDetailsModal({ setShowDt, show, showDt }) {
    if (!show || showDt?.type === null) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <ModalHeader setShowDt={setShowDt} />

                <ModalBody showDt={showDt} />
            </div>
        </div>
    );
}

export default TicketDetailsModal;
