import { icons } from '../../../../../configs/Icons';

function InfoWarningSection({ info, openModal }) {
    const isRed = info?.color === 'red';

    if (!info?.error) {
        return null;
    }
    return (
        <div className={`info-warning-area ${isRed ? 'red' : 'yellow'}`}>
            <div className="line-align">
                <div className="mr-2 mb-sm-2">
                    <div className="line-align line-no-wrap">
                        <span className="warning-text text-dotted break-all mr-1">
                            {info?.message || '--'}
                        </span>
                        <span className="tooltip">
                            <img
                                className="d-block icon"
                                src={isRed ? icons?.iconInfoRed : icons?.iconInfoYellow}
                                alt=""
                            />
                            <span className="tooltip-text after-none">info</span>
                        </span>
                    </div>
                </div>
                <div className="mb-sm-1">
                    <button
                        onClick={openModal ? () => openModal(info?.action) : null}
                        type="button"
                        className="default-none info-warning-button"
                        disabled={null}
                    >
                        {info?.action || '--'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default InfoWarningSection;
