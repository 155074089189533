/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { ButtonActionReload, ButtonGr } from '../../../../configs/Buttons';
import { useAuth } from '../../../contexts/AuthProvider';
import PageProperties from '../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../headers/DashboardHeaderSection';
import TableActionButton from '../_models/TableActionButton';
import ProfileAddressProvider, { useAddress } from './ProfileAddressProvider';
import AddressListTable from './address_list_table/AddressListTable';
import CreateAddressModal from './address_list_table_modals/CreateAddressModal';
import UpdateAddressModal from './address_list_table_modals/UpdateAddressModal';

function AddAddressButton({ handelAddButton }) {
    const { addressList } = useAddress();
    return (
        <TableActionButton show={addressList}>
            <div className="line-row-center">
                <ButtonGr evOnClick={handelAddButton} title="Add Address" />
            </div>
        </TableActionButton>
    );
}

function HeaderContent({ handelAddButton }) {
    const { reloadList } = useAddress();

    return (
        <div className="line justify-end line-no-wrap">
            <div className="header-action-btn">
                <ButtonActionReload evOnClick={reloadList} />
            </div>
            <div className="d-md-none">
                <ButtonGr evOnClick={handelAddButton} title="Add Address" />
            </div>
        </div>
    );
}

function AddressListView({ addressList, setModalData }) {
    return <AddressListTable addressList={addressList} setModalData={setModalData} />;
}

function AddressListArea({ setModalData }) {
    const { addressList } = useAddress();

    if (addressList?.length === 0) {
        return (
            <div className="list-container">
                <table className="table-list cell-sm-12 " cellPadding={0} cellSpacing={0}>
                    <thead>
                        <tr>
                            <th colSpan={2} width="21%">
                                Name
                            </th>
                            <th colSpan={3} width="38%">
                                Address
                            </th>
                            <th colSpan={3}>Email</th>
                            <th colSpan={1} width="10.5%">
                                Action
                            </th>
                        </tr>
                    </thead>
                </table>
                <div className="mt-3 mb-3 fs-16 fw-500 opacity-medium ml-4">No Address found !</div>
            </div>
        );
    }

    return (
        <div className="">
            <AddressListView addressList={addressList} setModalData={setModalData} />
        </div>
    );
}

function ProfileAddressPage() {
    const { currentUser } = useAuth();

    const [modalData, setModalData] = useState({
        type: null,
        data: {}
    });

    const handelAddButton = () => {
        setModalData(() => ({
            type: 'create',
            data: {}
        }));
    };

    useEffect(() => {
        PageProperties(`Address \u2022 Profile`);
    }, []);

    return (
        <ProfileAddressProvider currentUser={currentUser}>
            <div className="cell cell-content h-100 w-100">
                <div className="content-section">
                    <div className="content-area">
                        <DashboardDefaultHeader
                            title="Address"
                            path="/user/basic-info/"
                            content={<HeaderContent handelAddButton={handelAddButton} />}
                            mobileContent={<HeaderContent handelAddButton={handelAddButton} />}
                        />
                        <div
                            className="content-body-scroll user-address-info-area"
                            style={{ paddingBottom: '180px' }}
                        >
                            <AddressListArea setModalData={setModalData} modalData={modalData} />

                            <AddAddressButton handelAddButton={handelAddButton} />

                            <CreateAddressModal
                                modalData={modalData}
                                setModalData={setModalData}
                                show={modalData?.type === 'create'}
                            />

                            <UpdateAddressModal
                                setModalData={setModalData}
                                modalData={modalData}
                                show={modalData.type === 'update'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ProfileAddressProvider>
    );
}

export default ProfileAddressPage;
