import React, { useState } from 'react';
import LayoutSelector from '../../../../utils/LayoutSelector';
import ProfileListMobile from './ProfileListMobile';
import ProfileListWeb from './ProfileListWeb';

const profileList = [
    {
        id: 1,
        domain: 'Bikiran.com',
        fullName: 'Yeasin Arafat',
        email: '123@gmail.com',
        phone: '8801795617763',
        org: 'Bikiran.com',
        address: 'Khulna',
        city: 'Khulna',
        state: 'Khulna',
        post: 9000,
        country: 'Bangladesh'
    },
    {
        id: 2,
        domain: 'Prothomalo.com',
        fullName: 'Christopher Nolan',
        email: 'prothom@gmail.com',
        phone: '8801735022421',
        org: 'Prothom',
        address: 'Dhaka',
        city: 'Dhaka',
        state: 'Dhaka',
        post: 1250,
        country: 'Noakhali'
    }
];

function ProfileListTable() {
    const [showOptions, setShowOptions] = useState(false);
    return (
        <LayoutSelector
            sm
            md
            webComp={
                <ProfileListWeb
                    profileList={profileList}
                    showOptions={showOptions}
                    setShowOptions={setShowOptions}
                />
            }
            mobileComp={
                <ProfileListMobile
                    profileList={profileList}
                    showOptions={showOptions}
                    setShowOptions={setShowOptions}
                />
            }
        />
    );
}

export default ProfileListTable;
