import React from 'react';
import InfoStatusHandler from '../../../../users/models/InfoStatusHandler';

function RowCommonView({ title, value }) {
    return (
        <div className="line-row-between mb-1">
            <div className="cell">
                <span className="value primary-70">{title || '--'}:</span>
            </div>
            <div className="cell">
                <span className="value primary">{value || '--'}</span>
            </div>
        </div>
    );
}

function TransactionListMobile({ arr, setOpenModal }) {
    if (arr?.length === 0) {
        return (
            <div>
                <div className={`list-item-sm ${!arr ? 'placeholder-mode' : ''}`}>
                    <span className="title">No Transactions Found !</span>
                </div>
            </div>
        );
    }
    return (
        <div>
            {arr?.map((data) => (
                <div
                    role="button"
                    tabIndex={-1}
                    onClick={() => setOpenModal(true)}
                    key={data?.id}
                    className={`list-item-sm ${data?.isDemo ? 'placeholder-mode' : ''}`}
                >
                    <div className="mb-2">
                        <span className="title primary">{data?.trxId || '----'}</span>
                        <span
                            className="sub-title d-block"
                            style={{ color: InfoStatusHandler(data) }}
                        >
                            {data?.status || '----'}
                        </span>
                    </div>

                    <RowCommonView title="Date" value={data?.date} />
                    <RowCommonView title="Type" value={data?.type} />
                    <RowCommonView title="Total" value={data?.total} />
                </div>
            ))}
        </div>
    );
}

export default TransactionListMobile;
