import { usePoints } from '../UsersPointsProvider';

/* eslint-disable react/no-array-index-key */
function PointsHistorySection() {
    const histories = [
        {
            id: 1,
            earningFrom: 'Successfully Purchase (#16748521)',
            date: '08-02-2020',
            cash: +70,
            ammount: 7
        },
        {
            id: 2,
            earningFrom: 'Successfully Purchase (#15748521)',
            cash: +80,
            date: '19-01-2020',
            ammount: 8
        },
        {
            id: 3,
            earningFrom: 'Point cashed into wallet',
            cash: -300,
            date: '12-01-2020',
            ammount: 30
        }
    ];

    const { activity } = usePoints();

    return (
        <div className="">
            <table className="table-list web-device" cellPadding={0} cellSpacing={0}>
                <thead>
                    <tr>
                        <th colSpan={2}>Earned From</th>
                        <th width={130}>Date</th>
                        <th colSpan={2} width={100}>
                            Earning/Cash
                        </th>
                        <th width={100}>In Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {activity?.map((history, index) => (
                        <tr key={index}>
                            <td
                                colSpan={2}
                                // style={{ color: history.ammount < 0 ? '#FB7247' : '#17DC69' }}
                            >
                                Point cashed into wallet
                            </td>
                            <td>{history.date}</td>
                            <td
                                style={{ color: history.cash < 0 ? '#FB7247' : '#17DC69' }}
                                colSpan={2}
                            >
                                {history?.val} Bikiran Points
                            </td>
                            <td>{(history?.tk * 100) / 10}TK</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {histories.map((history) => (
                <table
                    key={history?.id}
                    className="table-list table-list-mobile mobile-device"
                    cellPadding={0}
                    cellSpacing={0}
                >
                    <tbody className="body-list-mobile">
                        <tr>
                            <td>Earned From</td>
                            <td style={{ color: history.ammount < 0 ? '#FB7247' : '#17DC69' }}>
                                {history.earningFrom}
                            </td>
                        </tr>
                        <tr>
                            <td>Date</td>
                            <td>{history.date}</td>
                        </tr>
                        <tr>
                            <td>Earning/Cash</td>
                            <td style={{ color: history.cash < 0 ? '#FB7247' : '#17DC69' }}>
                                {history.cash}
                            </td>
                        </tr>
                        <tr>
                            <td>In Amount</td>
                            <td>{history.ammount}</td>
                        </tr>
                    </tbody>
                </table>
            ))}
            {/* ))} */}
        </div>
    );
}

export default PointsHistorySection;
