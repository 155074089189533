import { ButtonClose } from '../../../../../configs/Buttons';

const ModalHeader = ({ setMode, data }) => (
    <div className="modal-box-header mb-2">
        <div>
            <div className="modal-box-title">{data?.domain}</div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={() => setMode(false)} />
            </div>
        </div>
    </div>
);

function ModalBody({ data }) {
    return (
        <div className="modal-body-area pb-0">
            <div>
                <div className="line line-no-wrap mb-2">
                    <div className="cell-5">
                        <span className="fs-16 opacity-low">Full Name</span>
                    </div>
                    <div className="cell-7">
                        <span className="fs-16">{data?.fullName || ''}</span>
                    </div>
                </div>
                <div className="line line-no-wrap mb-2">
                    <div className="cell-5">
                        <span className="fs-16 opacity-low">Email Address:</span>
                    </div>
                    <div className="cell-7">
                        <span className="fs-16">{data?.email || ''}</span>
                    </div>
                </div>
                <div className="line line-no-wrap mb-2">
                    <div className="cell-5">
                        <span className="fs-16 opacity-low">Phone Number:</span>
                    </div>
                    <div className="cell-7">
                        <span className="fs-16">{data?.phone || ''}</span>
                    </div>
                </div>
                <div className="line line-no-wrap mb-2">
                    <div className="cell-5">
                        <span className="fs-16 opacity-low">Organization:</span>
                    </div>
                    <div className="cell-7">
                        <span className="fs-16">{data?.org || ''}</span>
                    </div>
                </div>
                <div className="line line-no-wrap mb-2">
                    <div className="cell-5">
                        <span className="fs-16 opacity-low">Address:</span>
                    </div>
                    <div className="cell-7">
                        <span className="fs-16 break-all">
                            {/* House-02, Block-F, Sangbadik Abashik Elaka, Kalshi Road */}
                            {data?.address || ''}
                        </span>
                    </div>
                </div>
                <div className="line line-no-wrap mb-2">
                    <div className="cell-5">
                        <span className="fs-16 opacity-low">City:</span>
                    </div>
                    <div className="cell-7">
                        <span className="fs-16">{data?.city || ''}</span>
                    </div>
                </div>
                <div className="line line-no-wrap mb-2">
                    <div className="cell-5">
                        <span className="fs-16 opacity-low">State:</span>
                    </div>
                    <div className="cell-7">
                        <span className="fs-16">{data?.state || ''}</span>
                    </div>
                </div>
                <div className="line line-no-wrap mb-2">
                    <div className="cell-5">
                        <span className="fs-16 opacity-low">Post Code:</span>
                    </div>
                    <div className="cell-7">
                        <span className="fs-16">{data?.post || ''}</span>
                    </div>
                </div>
                <div className="line line-no-wrap">
                    <div className="cell-5">
                        <span className="fs-16 opacity-low">Country:</span>
                    </div>
                    <div className="cell-7">
                        <span className="fs-16">{data?.country || ''}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ProfileDetailsModal({ setMode, show, data }) {
    if (!show) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <ModalHeader setMode={setMode} data={data} />

                <ModalBody data={data} />
            </div>
        </div>
    );
}

export default ProfileDetailsModal;
