import React from 'react';
import { ButtonOutlinePurple } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';

function VerifyView() {
    return (
        <div>
            <span className="d-block font-14 primary-70 mb-2">
                Domain name transfer code has been sent
            </span>

            <span className="mb-3 d-block">
                <input placeholder="Code" type="text" className="form-input" />
            </span>
            <ButtonOutlinePurple title="Verify Now" />
        </div>
    );
}

function TransferingStep({ isVisible }) {
    const isVerified = true;

    if (isVerified) {
        return (
            <div className={`line line-no-wrap line-g2 mb-6 ${!isVisible ? 'disabled-view' : ''}`}>
                <div className="cell">
                    <div className="logo">
                        <img className="w-100 d-block" src={icons.iconTransferBg} alt="" />
                    </div>
                </div>
                <div className="cell mt-2">
                    <span className="font-18 primary">Transferring</span>
                    <span className="d-block font-14 primary-70">
                        Verified at 12:53pm, 24 Dec 1996
                    </span>
                </div>
            </div>
        );
    }
    return (
        <div className="line line-g2 mb-6">
            <div className="cell">
                <div className="logo">
                    <img className="w-100 d-block" src={icons.iconTransferBg} alt="" />
                </div>
            </div>
            <div className="cell mt-2">
                <span className="font-18 primary">Transferring</span>
                <VerifyView />
            </div>
        </div>
    );
}

export default TransferingStep;
