import { useState } from 'react';

export function InpPasswordOld({ value, setValue, title, name, disabled = false }) {
    return (
        <>
            <div className="label">{title}</div>
            <input
                type="password"
                name={name}
                className="form-input d-block w-100 bulllll"
                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                value={value}
                onChange={setValue}
                disabled={disabled}
            />
        </>
    );
}

export function InpPassword({ value, setValue, title, name, disabled = false, top = '17px' }) {
    const [inputType, setInputType] = useState({ type: '', mode: false });

    const handleInputType = () => {
        setInputType({
            type: inputType.type,
            mode: !inputType.mode
        });

        if (inputType.mode) {
            setInputType({ type: 'password', mode: !inputType.mode });
        } else {
            setInputType({ type: 'text', mode: !inputType.mode });
        }
    };

    return (
        <>
            {title && <div className="label">{title}</div>}
            <div className="position-relative">
                <input
                    type={inputType.type !== '' ? inputType.type : 'password'}
                    name={name}
                    style={{ paddingRight: '30px' }}
                    className={`form-input d-block w-100 ${
                        value?.length > 0 && inputType.type === 'password' ? 'font-20' : 'font-16'
                    }`}
                    placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                    value={value}
                    onChange={setValue}
                    disabled={disabled}
                />
                <div
                    onClick={() => handleInputType()}
                    role="button"
                    tabIndex={-1}
                    className={`input-pass ${
                        inputType.mode && inputType.type !== '' ? 'show' : 'hidden'
                    }`}
                    style={{ top }}
                />
            </div>
        </>
    );
}

export function InpPasswordSBS({ children }) {
    return (
        <div>
            <div className="line line-g2">
                <div className="cell cell-6">{children[0]}</div>
                <div className="cell cell-6">{children[1]}</div>
            </div>
        </div>
    );
}

function PasswordInputs() {
    return null;
}

export default PasswordInputs;
