import React, { useState } from 'react';
import { BtnProfileEdit, ButtonCancel } from '../../../../../configs/Buttons';
import VerifyPasswordPopup from '../modals/VerifyPasswordPopup';
import { useBasicInfo } from '../UserBasicInfoProvider';

const compId = 'editUsername';

const EditView = ({
    currentUser,
    formData,
    setFormData,
    handelExitEdit,
    handelSubmitForm,
    mode
}) => {
    const handleUpdate = (ev) => {
        setFormData((cuData) => ({ ...cuData, username: ev.target.value }));
    };

    return (
        <div className={`edit-view-area ${mode === compId ? 'show' : ''}`}>
            <div>
                <div>
                    <div className="mb-2">
                        <span className="profile-subject">User Name:</span>
                    </div>
                    <div className="mb-2">
                        <input
                            type="text"
                            className="form-input w-100"
                            onChange={handleUpdate}
                            value={formData?.username || currentUser?.username || ''}
                        />
                    </div>
                    <div className="line line-sm-no-wrap text-start note-text">
                        <span className="value fw-500">Note:</span>
                        &nbsp;
                        <span className="value fw-400">
                            <span className="d-block mb-1">
                                &bull; If you change your username, you can’t edit again within 9
                                Months.
                            </span>
                            <span>
                                &bull;To change your username, you must verify the password
                                associated with your logged-in account.
                            </span>
                        </span>
                    </div>
                </div>
                <div className="line justify-end ">
                    <ButtonCancel evOnClick={handelExitEdit} title="Cancel" />

                    <button
                        onClick={handelSubmitForm}
                        type="button"
                        className="default-none btn-gr btn-gr-web ml-2"
                        disabled={currentUser?.username === formData?.username?.trim()}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

function ProfileUserName({ currentUser }) {
    const [modal, setModal] = useState(false);
    const { mode, setMode, formData, setFormData } = useBasicInfo();
    // const [formData, setFormData] = useState({
    //     username: currentUser?.username || ''
    // });

    const handelClickEdit = () => {
        setMode(compId);
    };

    const handelExitEdit = () => {
        setMode(null);
    };

    const handelSubmitForm = () => {
        setModal(!modal);
    };

    return (
        <>
            <div className={`line default-view-area ${mode === compId ? 'border-none' : ''}`}>
                <div className="cell cell-3 cell-md-4  od-opacity">
                    <span className="profile-subject">User Name:</span>
                </div>

                <div className="cell-fill  od-opacity">
                    <span className="value">{currentUser?.username || '--'}</span>
                </div>

                <div className="cell  od-opacity">
                    <BtnProfileEdit evOnClick={handelClickEdit} isDisabled={!!mode} />
                </div>
            </div>
            <EditView
                currentUser={currentUser}
                formData={formData}
                setFormData={setFormData}
                handelExitEdit={handelExitEdit}
                handelSubmitForm={handelSubmitForm}
                mode={mode}
            />
            <VerifyPasswordPopup show={modal} setModal={setModal} />
        </>
    );
}

export default ProfileUserName;
