import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonActionReload, ButtonGr } from '../../../../../configs/Buttons';
import { DashboardDefaultHeader } from '../../../headers/DashboardHeaderSection';

function DomainListHeaderButton({ handelReload }) {
    const navigate = useNavigate();

    return (
        <div className="line justify-end">
            <span style={{ width: '30px' }} className="mr-2">
                <ButtonActionReload evOnClick={() => handelReload()} />
            </span>
            <ButtonGr evOnClick={() => navigate('/domain')} title="Buy Domain" />
        </div>
    );
}

function DomainListHeader({ handelReload, listDetails }) {
    return (
        <DashboardDefaultHeader
            title="Domain"
            subTitle={listDetails}
            path="/user/profile-overview/"
            content={<DomainListHeaderButton handelReload={handelReload} />}
            mobileContent={
                <span className="header-action-btn">
                    <ButtonActionReload evOnClick={() => handelReload()} />
                </span>
            }
        />
    );
}

export default DomainListHeader;
