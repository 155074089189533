import { createContext, useContext, useState } from 'react';

const UsersRenewContext = createContext();

export function useRenew() {
    return useContext(UsersRenewContext);
}

function UsersRenewProvider({ children, contractId }) {
    const [duration, setDuration] = useState(12);
    // const [renewData, setRenewData] = useState([]);

    const resetState = () => {
        setDuration(12);
    };

    const handelMinusClick = (inc = 1) => {
        setDuration((currentState) => {
            if (currentState > 1) {
                return currentState - inc;
            }
            return currentState;
        });
    };

    const handelPlusClick = (inc = 1) => {
        setDuration((currentState) => {
            if (currentState < 10) {
                return currentState + inc;
            }
            return currentState;
        });
    };

    const handleAddToCart = () => {
        console.log('add cart item');
    };

    const handleUpdateCartItem = () => {
        console.log('update cart item');
    };

    const handleDeleteFromCart = () => {
        console.log('delete cart item');
    };

    const handleEmptyCart = () => {
        console.log('empty cart item');
    };

    const handelCreateInvoice = () => {
        console.log('create invoice');
    };

    // useEffect(() => {

    // }, []);

    const value = {
        contractId,
        resetState,
        duration,
        setDuration,
        handelMinusClick,
        handelPlusClick,
        handleAddToCart,
        handleUpdateCartItem,
        handleDeleteFromCart,
        handleEmptyCart,
        handelCreateInvoice
    };

    return <UsersRenewContext.Provider value={value}>{children}</UsersRenewContext.Provider>;
}

export default UsersRenewProvider;
