import { ButtonActionEdit } from '../../../../../configs/Buttons';

function TableBody({ listArr, searchValue }) {
    if (!listArr?.length) {
        return (
            <tbody>
                <tr>
                    <td colSpan={100}>
                        <span className="font-14">No Data Found!</span>
                    </td>
                </tr>
            </tbody>
        );
    }
    return (
        <tbody>
            {listArr
                ?.filter((el) => el.type.toLowerCase()?.includes(searchValue?.toLowerCase()))
                ?.map((data) => (
                    <tr key={data?.id} className={data?.demo ? 'placeholder-mode' : ''}>
                        <td>
                            <span className="opacity-medium">{data?.type || '---'}</span>
                        </td>
                        <td>
                            <span className="break-all">{data?.name || '---'}</span>
                        </td>
                        <td>
                            <span>{data?.content || '---'}</span>
                        </td>
                        <td>
                            <span>{data?.proxy || '---'}</span>
                        </td>
                        <td>
                            <span>{data?.ttl || '---'}</span>
                        </td>
                        <td>
                            <div className="line justify-end">
                                <span className="btn-action-web">
                                    <ButtonActionEdit />
                                </span>
                            </div>
                        </td>
                    </tr>
                ))}
        </tbody>
    );
}

function DnsListWeb({ searchValue, listArr }) {
    return (
        <table className="table-list table-v2" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th width="10%">Type</th>
                    <th width="40%">Name</th>
                    <th width="17%">Content</th>
                    <th width="17%">Proxy status</th>
                    <th width="8%">TTL</th>
                    <th width="8%">Action</th>
                </tr>
            </thead>
            <TableBody searchValue={searchValue} listArr={listArr} />
        </table>
    );
}

export default DnsListWeb;
