import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { icons, servicesIcons } from '../../configs/Icons';

const menuItems = [
    {
        id: 1,
        title: 'Hosting Service',
        menu: [
            { title: 'App', path: '/services/hosting/app/' },
            { title: 'Web', path: '/services/hosting/web/' },
            { title: 'Email', path: '/services/hosting/email/' }
        ],
        img: servicesIcons.iconServiceHosting,
        path: '/services/hosting/web/'
    },
    {
        id: 2,
        title: 'Server Service',
        menu: [
            { title: 'Dedicated', path: '/services/server/dedicated/' },
            { title: 'Cloud', path: '/services/server/cloud-vm/' },
            { title: 'Vps', path: '/services/server/vps/' }
        ],
        img: servicesIcons.iconServiceServer,
        path: '/services/server/'
    },
    {
        id: 3,
        title: 'Software Service',
        menu: [
            { title: 'eCom', path: '/services/software/e-commerce/' },
            { title: 'Edu', path: 'https://www.edusoft.com.bd/', redirect: true },
            { title: 'News', path: '/services/software/news-portal/' }
        ],
        img: servicesIcons.iconServiceSoftware,
        path: '/services/software'
    },
    {
        id: 4,
        title: 'Send',
        menu: [
            { title: 'Sms', path: '/services/send/sms-service/' },
            { title: 'Email', path: '/services/server/email/' },
            { title: 'Push', path: '/services/send/push/' }
        ],
        img: servicesIcons.iconServiceSend,
        path: '/services/'
    },
    {
        id: 5,
        title: 'Web Security',
        menu: [
            { title: 'SSL', path: '/services/' },
            { title: 'Imunify360', path: '/services/' },
            { title: 'SiteLock', path: '/services/' }
        ],
        img: servicesIcons.iconWebSecurity,
        path: '/services/'
    },
    {
        id: 6,
        title: 'Web Backup & Storage',
        menu: [
            { title: 'Bikiran', path: '/services/' },
            { title: 'WordPress', path: '/services/' },
            { title: 'More', path: '/services/' }
        ],
        img: servicesIcons.iconServiceBackup,
        path: '/services/'
    },
    {
        id: 7,
        title: 'Premium Email',
        menu: [
            { title: 'Bikiran', path: '/services/' },
            { title: 'Google', path: '/services/' },
            { title: 'More', path: '/services/' }
        ],
        img: servicesIcons.iconServiceEmail,
        path: '/services/'
    },
    {
        id: 8,
        title: 'All Services',
        menu: [{ title: 'Check Our Services', path: '/services/' }],
        img: servicesIcons.iconServiceAll,
        path: '/services/'
    }
];

function ServiceMenu({ show, setShow, myRef }) {
    const ref = myRef;

    useEffect(() => {
        function handelClickOutside(event) {
            if (ref?.current && !ref?.current.contains(event?.target)) {
                // alert("You clicked outside of me!");
                setShow(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handelClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handelClickOutside);
        };
    }, [ref, setShow]);

    if (!show) {
        return false;
    }

    return (
        <div className="service-menu-pop" ref={ref}>
            <img src={icons.iconShape} alt="shape" />
            <div className="">
                {menuItems.map((item) => (
                    <div key={item?.id} className="service-area">
                        <div className="line-align line-no-wrap">
                            <div>
                                <img className="d-block" src={item?.img} alt="" />
                            </div>
                            <div className="ml-2">
                                <Link to={item?.path} className="service-title">
                                    {item?.title}
                                </Link>
                                <div className="line-align line-no-wrap">
                                    {item?.menu?.map((el) => {
                                        if (el?.redirect) {
                                            return (
                                                <span
                                                    key={el?.title}
                                                    role="button"
                                                    tabIndex={-1}
                                                    onClick={() => window.open(el?.path)}
                                                    className="menu-item"
                                                >
                                                    {el?.title}
                                                </span>
                                            );
                                        }
                                        return (
                                            <Link
                                                key={el?.title}
                                                className="menu-item"
                                                to={el?.path}
                                                onClick={() => setShow(false)}
                                            >
                                                {' '}
                                                {el.title}
                                            </Link>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ServiceMenu;
