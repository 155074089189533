import { useRef } from 'react';
import { ButtonActionDotMenu } from '../../../../../configs/Buttons';
import ContactInfoIconHandler from '../_models/ContactInfoIconHandler';
import ContactInfoStatusHandler from '../_models/ContactInfoStatusHandler';
import ContactInfoEmailTableMenu from './ContactInfoEmailTableMenu';

function ButtonActionWeb({ setShowOptions, showOptions, myRef, data }) {
    return (
        <div className="position-relative">
            <div className="line-align justify-end ">
                <span className="btn-action-web">
                    <ButtonActionDotMenu
                        evOnClick={() => {
                            setShowOptions(data?.id);
                        }}
                    />
                </span>
            </div>
            <div className="option-popup">
                <ContactInfoEmailTableMenu
                    myRef={myRef}
                    showOptions={data?.id === showOptions}
                    setShowOptions={setShowOptions}
                    data={data}
                    type="email"
                />
            </div>
        </div>
    );
}

function CuUserText({ cUser }) {
    if (!cUser) {
        return null;
    }
    return <span className="logged-mail-text">You are logged in with this email</span>;
}

function TableBody({ currentUser, arr, contactList, showOptions, setShowOptions }) {
    const myRef = useRef();

    const type = 'email';

    return (
        <tbody className={!contactList ? 'placeholder-mode' : ''}>
            {arr?.map((data) => {
                const cUser = data.userUid === currentUser.userUid;
                return (
                    <tr key={data?.id}>
                        <td colSpan={6}>
                            <div className="line-align line-g1 line-no-wrap">
                                <span className="cell">
                                    <ContactInfoIconHandler value={data.providerId} />
                                </span>
                                <div className="cell cell-fill">
                                    <div className="line-align">
                                        <span className="cell-fill">
                                            <h4 className="title mt-0 mb-0">{data.email}</h4>
                                        </span>
                                        <span className="cell">
                                            <CuUserText cUser={cUser} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td colSpan={3}>
                            <ContactInfoStatusHandler data={data} type={type} />
                        </td>
                        <td colSpan={1}>
                            <ButtonActionWeb
                                setShowOptions={setShowOptions}
                                showOptions={showOptions}
                                myRef={myRef}
                                data={data}
                            />
                        </td>
                    </tr>
                );
            })}
        </tbody>
    );
}

function ContactInfoEmailTableWeb({ arr, contactList, currentUser, showOptions, setShowOptions }) {
    return (
        <table className="table-list cell-sm-12" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th colSpan={6}>Email Address</th>
                    <th width="23%" colSpan={3}>
                        Status
                    </th>
                    <th className="text-right" width="10%" colSpan={1}>
                        Action
                    </th>
                </tr>
            </thead>
            <TableBody
                arr={arr}
                contactList={contactList}
                currentUser={currentUser}
                showOptions={showOptions}
                setShowOptions={setShowOptions}
            />
        </table>
    );
}

export default ContactInfoEmailTableWeb;
