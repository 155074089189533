import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import { useLayout } from '../../../contexts/LayoutProvider';
import HeaderSection from '../../headers/HeaderSection';

import MobileFooter from '../../users/components/MobileFooter';
import FixedHeaderControl from '../../websites/components/FixedHeaderControl';
import SupportSidebarMenuSection from '../components/SupportSidebarMenuSection';

function SupportManagerHandler() {
    const { currentUser } = useAuth();
    const { windowSizeName: sizeName } = useLayout();

    if (!currentUser) {
        return <div />;
    }

    if (currentUser?.loginStatus !== true) {
        return (
            <div>
                <HeaderSection showLoginPopup />
            </div>
        );
    }

    return (
        <div className={['h-100 header-b', 'browser', `browser-${sizeName}`].join(' ')}>
            <div className="d-lg-none">
                <FixedHeaderControl>
                    <HeaderSection />
                </FixedHeaderControl>
            </div>
            <div className="body-section">
                <div className="container h-100">
                    <div className="line line-no-wrap h-100">
                        <div className="cell cell-profile h-100 sidebar-menu-area w-100">
                            <SupportSidebarMenuSection currentUser={currentUser} />
                        </div>
                        <div className="cell cell-content h-100 w-100">
                            <div className="content-section">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <MobileFooter support />
        </div>
    );
}

export default SupportManagerHandler;
