import { useState } from 'react';
import SocialListButtonArea from './SocialListButtonArea';

function UrlViewMobile({ viewType, data }) {
    if (viewType === data?.id) {
        return (
            <span className="w-100 h-100">
                <input
                    className="form-input w-100 w-100"
                    type="text"
                    defaultValue={data?.url || ''}
                />
            </span>
        );
    }
    return (
        <span className="line-align h-100">
            <a
                style={{ marginLeft: '11px', marginTop: '0.5px' }}
                href={data?.url}
                className="font-14 clr overflow-dotted"
                target="_blank"
                rel="noreferrer"
            >
                {data?.url || ''}
            </a>
        </span>
    );
}

function ListDisableView({ data, viewType, setViewType }) {
    return (
        <div className="" style={{ filter: 'grayscale(100%)', pointerEvents: 'none' }}>
            <div className="line-row-between mb-3">
                <div className="line-align line-no-wrap">
                    <div className="logo-img ">
                        <img className="d-block w-100" src={data?.img} alt={data?.name} />
                    </div>
                    <div className="ml-3 title">{data?.name}</div>
                </div>
                <div>
                    <div className="btn-action">
                        <SocialListButtonArea
                            setViewType={setViewType}
                            viewType={viewType}
                            data={data}
                        />
                    </div>
                </div>
            </div>
            <div className="input-section-area">
                <UrlViewMobile viewType={viewType} data={data} />
            </div>
        </div>
    );
}

function Body({ data, setViewType, viewType }) {
    if (viewType !== null && viewType !== data?.id) {
        return <ListDisableView data={data} viewType={viewType} setViewType={setViewType} />;
    }
    return (
        <div>
            <div className="line-row-between mb-3">
                <div className="line-align line-no-wrap">
                    <div className="logo-img ">
                        <img className="d-block w-100" src={data?.img} alt={data?.name} />
                    </div>
                    <div className="ml-3 title">{data?.name}</div>
                </div>
                <div>
                    <div className="btn-action">
                        <SocialListButtonArea
                            setViewType={setViewType}
                            viewType={viewType}
                            data={data}
                        />
                    </div>
                </div>
            </div>
            <div className="input-section-area">
                <UrlViewMobile viewType={viewType} data={data} />
            </div>
        </div>
    );
}

function SocialListMobile({ info }) {
    const [viewType, setViewType] = useState(null);

    return info?.map((data) => (
        <div key={data?.id} className="list-item-sm">
            <Body data={data} setViewType={setViewType} viewType={viewType} />
        </div>
    ));
}

export default SocialListMobile;
