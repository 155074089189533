import { Link } from 'react-router-dom';

function SectionTitleArea() {
    return (
        <div>
            <h3 className="section-title mb-1 mt-0">Refund Policy</h3>
            <div className="sub-title mt-1 mb-1">Updated On: 6 July 2022</div>
            <div className="sub-title mt-1 mb-4">
                To view our archived Privacy Policy,{' '}
                <span className="purple">
                    <Link to="/" className="purple">
                        click here.
                    </Link>
                </span>
            </div>
        </div>
    );
}

function CommonSectionText({ title }) {
    return <h4 className="value-title mb-2">{title}</h4>;
}

function CommonListAreaUl({ info }) {
    return (
        <ul>
            {info.map((data) => (
                <li className="list-disc" key={Math.random()}>
                    <span>{data}</span>
                </li>
            ))}
        </ul>
    );
}
function CommonListAreaOl({ info }) {
    return (
        <ul>
            {info.map((data) => (
                <li className="list-disc" key={Math?.random()}>
                    <span className="title-sm">{data.title}</span>
                    <span>{data.value}</span>
                </li>
            ))}
        </ul>
    );
}

function CommonTldView({ arr }) {
    return (
        <ul>
            {arr.map((data) => (
                <li key={data?.val1} className="line-align">
                    <span className="cell-3 ml-6 mb-0 mt-0">{data.val1}</span>
                    <span className="cell-5 ml-6 mt-0 mb-0">{data.val2}</span>
                    <span className="cell-4" />
                </li>
            ))}
        </ul>
    );
}

function InfoText() {
    return (
        <div className="mb-4">
            <p className="mb-3">
                Products purchased from BIKIRAN.COM may be refunded only if canceled within the
                refund period specified below in this policy. Some products have different policies
                or requirements for a refund associated with them, including some products that are
                not eligible for a refund under any circumstance. Please see below for refund terms
                applicable to such products.
            </p>
            <p className="mb-3 mt-0">
                <span className="title-sm">Date of the transaction</span>, for the purpose of this
                Refund Policy, means the date of purchase of any product or service, which includes
                the date any renewal is processed by BIKIRAN.COM, LLC in accordance with the terms
                and conditions of the applicable product or service agreement (see here).
            </p>
            <p className="mb-3 mt-0">
                <span className="title-sm">``Refund Period``</span>, you may cancel a product at any
                any time, but a refund will only be issued if you request a refund with BIKIRAN`s
                customer service within the refund time frame specified for the applicable product,
                if available at all.
            </p>
            <p className="mb-3 mt-0">
                <span className="title-sm">No Refund After Account Closure</span>. If eligible for a
                refund, it is necessary for you to request a refund prior to account closure. You
                may elect to close your account with us at any time, but upon account closure you
                will no longer be eligible for a refund as otherwise permitted under this Refund
                Policy.
            </p>
            <p className="title-sm">
                EU & UK CONSUMERS: YOU HAVE A STATUTORY RIGHT TO CANCEL PRODUCTS AND SERVICES WITHIN
                14 DAYS FROM THE DATE OF THE CONTRACT. HOWEVER, WE ARE ENTITLED TO CHARGE A
                REASONABLE AMOUNT FOR THE SERVICES THAT WE HAVE ALREADY PROVIDED TO YOU AS AT THE
                DATE OF CANCELLATION. THEREFORE, A FULL, OR ANY, REFUND MAY NOT BE DUE. PLEASE NOTE
                THIS DOES NOT AFFECT THE ``PRODUCTS WITH SPECIAL REFUND TERMS``, WHERE FULL REFUNDS
                MAY BE GIVEN, AS APPLICABLE. THIS REFUND POLICY DOES NOT AFFECT YOUR STATUTORY
                RIGHTS
            </p>
        </div>
    );
}

function DomainBackorders() {
    return (
        <div className="mb-4">
            <CommonSectionText title="Domain Backorders:" />
            <p className="mt-0">
                If a Domain Backorder credit has been applied to an auction, then it is
                non-refundable (if not yet applied to an auction, eligible for a refund within 30
                days of the date of the transaction).
            </p>
        </div>
    );
}

function RefundTerms() {
    return (
        <div>
            <CommonSectionText title="Standard Refund Terms:" />
            <p className="mt-2 mb-1">
                <span className="title-sm">Annual Plans:</span> Within 30 days of the date of the
                transaction.
            </p>
            <p className="mt-0 mb-2">
                <span className="title-sm">Monthly Plans:</span>Within 48 hours of the date of the
                transaction.
            </p>
            <p className="mt-0 mb-1 italic-text font-14">
                **Monthly Plans include all plans with less than a 1-year term (e.g., 6 months., 9
                months., etc.)
            </p>
            <p className="mb-4 mt-0 italic-text font-14">
                +Security products with remediation service cannot be refunded once a cleanup
                request has been submitted._
            </p>
        </div>
    );
}

function NameRegAndRenew() {
    const info1 = [
        { title: 'New Registration:', value: ' 5 Days (120 Hours)' },
        { title: 'Auto-Renewal (one-year):', value: ' 45 Days (1080 hours)' },
        { title: 'Auto-Renewal (multiple-year):', value: ' 5 Days (120 Hours)' },
        { title: 'Manual Renewal:', value: ' 5 days (120 Hours)' }
    ];
    const info2 = [
        {
            title: '.AU:',
            value: ' New Registration / Auto-Renewal / Manual Renewal - 3 Days (72 Hours)'
        },
        {
            title: '.COM.AU::',
            value: ' New Registration / Auto-Renewal / Manual Renewal - 3 Days (72 Hours)'
        },
        {
            title: '.NET.AU:',
            value: ' New Registration / Auto-Renewal / Manual Renewal - 3 Days (72 Hours)'
        },
        {
            title: '.ORG.AU:',
            value: ' New Registration / Auto-Renewal / Manual Renewal - 3 Days (72 Hours)'
        },
        { title: '.CZ:', value: ' Manual Renewal - 59 Days (1416 Hours)' },
        { title: '.IT:', value: ' Auto-Renewal / Manual Renewal - 14 Days (336 Hours)' },
        { title: '.MX:', value: ' New Registration - 45 Days (1080 Hours)' },
        { title: '.COM.MX:', value: ' New Registration - 45 Days (1080 Hours)' },
        { title: '.PE:', value: ' Manual Renewal - 29 Days (696 Hours)' },
        { title: '.COM.PE:', value: ' Manual Renewal - 29 Days (696 Hours)' },
        { title: '.NET.PE:', value: ' Manual Renewal - 29 Days (696 Hours)' },
        { title: '.NOM.PE:', value: ' Manual Renewal - 29 Days (696 Hours)' },
        { title: '.ORG.PE:', value: ' Manual Renewal - 29 Days (696 Hours)' },
        { title: '.PH:', value: ' Manual Renewal - 59 Days (1416 Hours)' },
        { title: 'COM.PH:', value: ' Manual Renewal - 59 Days (1416 Hours)' },
        { title: '.ORG.PE:', value: ' Manual Renewal - 29 Days (696 Hours)' },
        { title: '.NET.PH:', value: ' Manual Renewal - 59 Days (1416 Hours)' },
        { title: '.ORG.PH:', value: ' Manual Renewal - 59 Days (1416 Hours)' },
        { title: '.SE:', value: ' Manual Renewal - 69 Days (1656 Hours)' },
        { title: '.SG:', value: ' Manual Renewal - 29 Days (696 Hours)' },
        { title: '.SO:', value: ' Auto-Renewal / Manual Renewal - 4 Days (96 Hours)' },
        { title: '.COM.SO:', value: ' Auto-Renewal / Manual Renewal - 4 Days (96 Hours)' },
        { title: '.NET.SO:', value: ' Auto-Renewal / Manual Renewal - 4 Days (96 Hours)' },
        { title: '.ORG.SO:', value: ' Auto-Renewal / Manual Renewal - 4 Days (96 Hours)' },
        {
            title: '.UK:',
            value: ' New Registration / Auto-Renewal / Manual Renewal - 5 Days (120 Hours)'
        },
        {
            title: '.CO.UK:',
            value: ' New Registration / Auto-Renewal / Manual Renewal - 5 Days (120 Hours)'
        },
        {
            title: '.ME.UK:',
            value: ' New Registration / Auto-Renewal / Manual Renewal - 5 Days (120 Hours)'
        },
        {
            title: '.ORG.UK:',
            value: ' New Registration / Auto-Renewal / Manual Renewal - 5 Days (120 Hours)'
        }
    ];
    return (
        <div className="mb-4">
            <CommonSectionText title="Domain Name Registrations/Renewals:" />
            <ol>
                <li className="list-num">
                    <span className="title-sm">Standard Terms:</span>
                </li>
                <CommonListAreaOl info={info1} />
                <li className="list-num">
                    <span className="title-sm">Exceptions:</span>
                </li>
                <CommonListAreaOl info={info2} />
                <li className="list-num">
                    <span className="title-sm">Pre-Registrations:</span>
                    <ul>
                        <li className="list-disc mt-0">
                            <span>
                                For gTLD pre-registrations, application fees are non-refundable*.
                            </span>
                        </li>
                    </ul>
                </li>
            </ol>
            <p className="italic-text">
                ***For more information on pre-registration refunds with Donuts Inc. family of{' '}
                <Link style={{ textDecoration: 'underline' }} to="/" className="purple">
                    TLDs
                </Link>
                , see{' '}
                <Link style={{ textDecoration: 'underline' }} to="/" className="purple">
                    Donuts’ Pre-Registration Refunds and Auctions Policy
                </Link>
            </p>
        </div>
    );
}

function ItService() {
    return (
        <div className="mb-4">
            <CommonSectionText title="BIKIRAN IT Services:" />
            <p className="mt-0 mb-3">
                If IT Service has already been performed, then it is non-refundable (if not yet
                performed, eligible for a refund within 30 days of the date of the transaction).
            </p>
            <ul>
                <li className="list-disc">
                    <span>Annual Subscriptions: Non-refundable</span>
                </li>
                <li className="list-disc">
                    <span>
                        Monthly Subscriptions: If one or more request(s) have been completed during
                        the billing period, your monthly subscription is non-refundable.
                    </span>
                </li>
            </ul>
        </div>
    );
}

function HostingService() {
    return (
        <div className="mb-4">
            <CommonSectionText title="Hosting Services:" />
            <p className="mt-0 mb-0">
                If a Hosting Service has already been performed, then it is non-refundable (if not
                yet performed, eligible for a refund within 30 days of the date of the transaction).
            </p>
        </div>
    );
}
function PurchaseProduct() {
    return (
        <div className="mb-4">
            <CommonSectionText title="Purchased Product w/ Free Domain:" />
            <p className="mt-0 mb-0">
                In the event any purchased product includes a free domain name, if you cancel the
                purchased product, the list price for the domain name will be deducted from the
                refund amount (the list price is the price of the domain name listed on BIKIRAN’s
                website and is not subject to any promotion, discount, or other reduction in price).
            </p>
        </div>
    );
}
function WebService() {
    return (
        <div className="mb-4">
            <CommonSectionText title="Website Services: " />
            <p className="mt-0 mb-3">
                <span className="title-sm">Build Fee:</span> 30% cancellation fee when in progress,
                70% cancellation fee when site design is delivered for review. Non-refundable once
                first revision is completed or after 30 days of the date of the transaction. Once
                performance has begun, web service design is considered completed 180 days from the
                date of the transaction and will not be eligible for a refund thereafter.
            </p>
            <p className="mt-0 mb-0">
                Hosting: Eligible for a refund as defined in the ``Standard Refund Terms`` stated
                above.
            </p>
        </div>
    );
}
function WebSecurity() {
    return (
        <div className="mb-4">
            <CommonSectionText title="Website Security:" />
            <p className="mt-0 mb-0">
                Refunds are only available within 30 days of purchase and will only be issued in
                cases where a manual malware removal was not completed.
            </p>
        </div>
    );
}
function Design() {
    return (
        <div className="mb-4">
            <CommonSectionText title="Logo or Print Design:" />
            <p className="mt-0 mb-0">
                40% cancellation fee when in progress, non-refundable once artwork delivered or
                within 30 days of the date of the transaction.
            </p>
        </div>
    );
}
function SocilaMediaManagement() {
    return (
        <div className="mb-4">
            <CommonSectionText title="Social Media Management:" />
            <p className="mt-0 mb-0">
                You can cancel Social Media Management at any time and we will not bill you for
                future months. However, you will not be refunded the cost of the present or past
                months since the service has already been performed. If you cancel Social Media
                Management and request a refund within 48 hours of initially purchasing or renewing,
                you can receive a full refund for that month of service. After 48 hours, however,
                you are responsible for paying for that month, but will not be billed for future
                months.
            </p>
        </div>
    );
}

function NoRefund() {
    const info = [
        'Appraisals (Express and Certified Appraisals are non-refundable if the customer already applied the credit).',
        'Auctions Memberships',
        'Cloud Servers',
        'Configuration Add-Ons',
        'Domain: Auctions, Buy Service (you may request a refund ONLY IF you have not submitted a bid on the domain the service was purchased for), Consolidation or Monitoring',
        'Merchant Accounts',
        'Discount Domain Club (if used for purchase)',
        'Hosting Connection paid Apps (not refundable once set up)',
        'Office Max Bundles',
        'Premium Domain Names',
        'Google Workspace Plan',
        'Redemption Fees',
        'All Registrations or Renewals in the following TLD’s are fully non-refundable:'
    ];
    const info1 = [
        'Registrations or Renewals in the following TLD’s are refundable, except for the condition in parentheses (in which case, they are non-refundable):**'
    ];

    const arr = [
        { val1: '.AI', val2: '.NU' },
        { val1: '.AM', val2: '.NZ' },
        { val1: '.AT', val2: '.CO.NZ' },
        { val1: '.BE', val2: '.NET.NZ' },
        { val1: '.CH', val2: '.ORG.NZ' },
        { val1: '.CL', val2: '.PL' },
        { val1: '.DE', val2: '.BIZ.PL' },
        { val1: '.DK', val2: '.COM.PL' },
        { val1: '.ES', val2: '.INFO.PL' },
        { val1: '.COM.ES', val2: '.NET.PL' },
        { val1: '.NOM.ES', val2: '.ORG.PL' },
        { val1: '.ORG.ES', val2: '.RU' },
        { val1: '.EU', val2: '.COM.RU' },
        { val1: '.FM', val2: '.NET.RU' },
        { val1: '.FR', val2: '.ORG.RU' },
        { val1: '.GG	', val2: '.TC' },
        { val1: '.GS', val2: '.TK' },
        { val1: '.CO.JP', val2: '.VE' },
        { val1: '.JP', val2: '.CO.VE' },
        { val1: '.KR', val2: '.COM.VE' },
        { val1: '.CO.KR', val2: '.INFO.VE' },
        { val1: '.NE.KR', val2: '.NET.VE' },
        { val1: '.RE.KR', val2: '.ORG.VE' },
        { val1: '.MS', val2: '.WEB.VE' },
        { val1: '.NL', val2: '.VG' },
        { val1: '.NO', val2: '.CO.ZA' }
    ];

    const arr1 = [
        { val1: '.AMSTERDAM', val2: '(New Registration and Manual Renewal Only)' },
        { val1: '.ARCHI', val2: '(Manual Renewal Only)' },
        { val1: '.BIO', val2: '(Manual Renewal Only)' },
        { val1: '.SKI', val2: '(Manual Renewal Only)' },
        { val1: '.CA', val2: '(Manual Renewal Only)' },
        { val1: '.CZ', val2: '(New Registration and Manual Renewal Only)' },
        { val1: '.IT	', val2: '(Manual Renewal Only)' },
        { val1: '.MX', val2: '(Manual Renewal Only)' },
        { val1: '.COM.MX', val2: '(Manual Renewal Only)' },
        { val1: '.PE', val2: '(New Registration and Manual Renewal Only)' },
        { val1: '.COM.PE', val2: '(New Registration and Manual Renewal Only)' },
        { val1: '.NET.PE', val2: '(New Registration and Manual Renewal Only)' },
        { val1: '.NOM.PE', val2: '(New Registration and Manual Renewal Only)' },
        { val1: '.ORG.PE', val2: '(New Registration and Manual Renewal Only)' },
        { val1: '.PH', val2: '(New Registration and Manual Renewal Only)' },
        { val1: '.COM.PH', val2: '(New Registration and Manual Renewal Only)' },
        { val1: '.NET.PH', val2: '(New Registration and Manual Renewal Only)' },
        { val1: '.ORG.PH', val2: '(New Registration and Manual Renewal Only)' },
        { val1: '.SE', val2: '(New Registration and Manual Renewal Only)' },
        { val1: '.SG', val2: '(New Registration and Manual Renewal Only)' },
        { val1: '.SO', val2: '(New Registrations Only)' },
        { val1: '.COM.SO', val2: '(New Registrations Only)' },
        { val1: '.NET.SO', val2: '(New Registrations Only)' },
        { val1: '.ORG.SO', val2: '(New Registrations Only)' },
        { val1: '.TW', val2: '(Auto-Renewal Only)' },
        { val1: '.COM.TW', val2: '(Auto-Renewal Only)' },
        { val1: '.IDV.TW', val2: '(Auto-Renewal Only)' },
        { val1: '.ORG.TW', val2: '(Auto-Renewal Only)' }
    ];

    return (
        <div className="mb-4">
            <CommonSectionText title="Products Not Eligible for Refunds:" />
            <CommonListAreaUl info={info} />
            <CommonTldView arr={arr} />
            <CommonListAreaUl info={info1} />
            {/* <CommonTldView arr={arr1} /> */}
            <ul>
                {arr1.map((data) => (
                    <li key={data?.val1} className="line-align line-sm-no-wrapr">
                        <span className="cell-3 cell-sm-4 ml-sm-2 ml-6 mb-0 mt-0">{data.val1}</span>
                        <span className="cell-5 cell-sm-8 ml-sm-2 ml-6 mt-0 mb-0">{data.val2}</span>
                        <span className="cell-4" />
                    </li>
                ))}
            </ul>
        </div>
    );
}

function RefundPolicySection() {
    return (
        <div className="policy-area-container container">
            <SectionTitleArea />
            <hr style={{ opacity: '40%' }} />
            <InfoText />
            <RefundTerms />
            <DomainBackorders />
            <NameRegAndRenew />
            <ItService />
            <HostingService />
            <PurchaseProduct />
            <WebService />
            <WebSecurity />
            <Design />
            <SocilaMediaManagement />
            <NoRefund />
        </div>
    );
}

export default RefundPolicySection;
