import React, { useState } from 'react';

import { ButtonOutlinePurple } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';

function VerifyView({ handleVerifyClick }) {
    return (
        <div>
            <span className="d-block font-14 primary-70 mb-2">
                Please check that your email address is correct, as we will send the transfer code
                there
            </span>
            <span className="line mb-2">
                <span className="d-block font-14 fw-500 primary-70">Registant Email:</span>&nbsp;
                <span className="d-block font-14 primary"> yourname@gmail.com</span>
            </span>
            <ButtonOutlinePurple evOnClick={() => handleVerifyClick()} title="Verify Now" />
        </div>
    );
}

function VerifyEmailStep({ onClick }) {
    const [isVerified, setIsVerified] = useState(false);

    const handleVerifyClick = () => {
        setIsVerified(true);
        onClick();
    };

    if (isVerified) {
        return (
            <div className="line line-g2 mb-6 active-line">
                <div className="cell">
                    <div className="logo">
                        <img className="w-100 d-block" src={icons.iconSecurityPurple} alt="" />
                    </div>
                </div>
                <div className="cell mt-2">
                    <span className="font-18 primary">Verify Email</span>
                    <span className="d-block font-14 primary-70">
                        Verified at 12:53pm, 24 Dec 1996
                    </span>
                </div>
            </div>
        );
    }
    return (
        <div className="line line-no-wrap line-g2 mb-6">
            <div className="cell">
                <div className="logo">
                    <img className="w-100 d-block" src={icons.iconSecurityPurple} alt="" />
                </div>
            </div>
            <div className="cell mt-2">
                <span className="font-18 primary">Verify Email</span>
                <VerifyView handleVerifyClick={handleVerifyClick} />
            </div>
        </div>
    );
}

export default VerifyEmailStep;
