import React from 'react';
import '../../../../assets/css/bikiran/style.power.css';
import { useAuth } from '../../../contexts/AuthProvider';
import LeftSidebarSection from '../components/LeftSidebarSection';
import MegaBodySection from '../components/MegaBodySection';

function PowerAuthHandler() {
    const { currentUser } = useAuth();

    if (!currentUser) {
        return <div>Loading...</div>;
    }

    if (currentUser?.loginStatus !== true) {
        return <div>Please Login</div>;
    }

    return (
        <div className="mega mega-panel line">
            <div className="mega-sidebar cell">
                <LeftSidebarSection />
            </div>

            <div className="mega-body cell-fill">
                <MegaBodySection />
            </div>
        </div>
    );
}

export default PowerAuthHandler;
