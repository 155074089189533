import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageProperties from '../../../utils/PageProperties';
import FaqSection from '../faq/FaqSection';
import HostingInfoHeader from '../hostings_info_header/HostingInfoHeader';
import TabsSection from '../hostings_info_tabs/TabSection';

function ContentHead() {
    return (
        <HostingInfoHeader
            evReloadClick={null}
            info={{ title: 'Email Hosting - 3 GB SSD', domain: 'Domain.com' }}
            path="faq/"
        />
    );
}

function ContentBody({ contractId }) {
    return (
        <div className="content-body-scroll" style={{ paddingBottom: '180px' }}>
            <TabsSection faq info />
            <FaqSection contractId={contractId} />
        </div>
    );
}

function HostingInfoFaqPage() {
    const { contractId } = useParams();

    useEffect(() => {
        PageProperties(`FAQ \u2022 Hosting`);
    }, []);

    return (
        <div className="cell cell-content h-100">
            <div className="content-section">
                <div className="content-area">
                    <ContentHead />
                    <div className="content-body">
                        <ContentBody contractId={contractId} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HostingInfoFaqPage;
