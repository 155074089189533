import { useSearchParams } from 'react-router-dom';
import HeaderSection from '../../headers/HeaderSection';
import FixedHeaderControl from '../../websites/components/FixedHeaderControl';
import RoleInvitationFooter from './role_invitation/RoleInvitationFooter';
import RoleInvitationSection from './role_invitation/RoleInvitationSection';

function RoleInvitationPage() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    // console.log(token);

    return (
        <>
            <div className="header-b">
                <FixedHeaderControl>
                    <HeaderSection />
                </FixedHeaderControl>
            </div>
            <RoleInvitationSection token={token} />
            <RoleInvitationFooter />
        </>
    );
}

export default RoleInvitationPage;
