import { ButtonClose, ButtonGr } from '../../../../../configs/Buttons';
import CopyText from '../../../websites/models/CopyText';

const ModalHeader = ({ setMode, domain }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title sub-title">
                <span>Import & Export</span>
                <span>
                    <small>
                        <CopyText copyValue={domain} value={domain?.toUpperCase()} />
                    </small>
                </span>
            </div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={() => setMode({ type: null })} />
            </div>
        </div>
    </div>
);

function FooterArea() {
    return (
        <div className="line-row-between">
            <div>
                <span className="font-18">Export DNS records</span>
            </div>
            <div className="button-download-gr">
                <ButtonGr title="Export" modal />
            </div>
        </div>
    );
}

function ModalBody() {
    return (
        <div className="modal-body-area">
            <div className="drag-area purple-v mb-4">
                <span className="font-14">
                    <span className="purple">Select a file</span> or drag it here
                </span>
            </div>
            <FooterArea />
        </div>
    );
}

function DnsImportPopup({ show, setMode, domain }) {
    if (!show) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <ModalHeader setMode={setMode} domain={domain} />

                <ModalBody />
            </div>
        </div>
    );
}

export default DnsImportPopup;
