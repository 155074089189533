export function DateInput({ handleChange, val, height = '40px', width = '' }) {
    return (
        <div
            className="line w-100 date-input"
            style={{ height, maxWidth: width || '150px', width: width || '150px' }}
        >
            <input
                style={{
                    height: '100%'
                }}
                onChange={handleChange}
                value={val}
                type="date"
                className="form-input w-100 h-100 unstyled calender-icon"
            />
        </div>
    );
}

function DateInputs() {
    return null;
}

export default DateInputs;
