import React, { useRef } from 'react';
import { icons } from '../../../../../configs/Icons';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import OtpInputs from '../../../../utils/inputs/OtpInputs';
import { InpPhone } from '../../../../utils/inputs/TextInputs';
import { useContact } from '../ContactManageProvider';

const BtnSendOtp = ({ formData, setFormData }) => {
    const btnRef = useRef();
    const { setMessage } = useTemplate();
    const { sendPhoneOtp } = useContact();

    const onCLickOtpSend = () => {
        setMessage('Sending OTP to email...');
        btnRef.current.disabled = true;

        sendPhoneOtp(formData)
            .then(({ error, message, sentId }) => {
                setMessage(message);
                btnRef.current.disabled = false;

                if (error === 0) {
                    setFormData((cuData) => ({ ...cuData, sentId, otpKey: Math.random() }));
                }
            })
            .catch((err) => {
                btnRef.current.disabled = false;
                setMessage(err);
            });
    };

    return (
        <button
            className="button button-gr-pink w-100 login-btn"
            type="button"
            onClick={onCLickOtpSend}
            ref={btnRef}
        >
            Send OTP
        </button>
    );
};

const BtnVerifyAndLink = ({ formData, setFormData, setModalData }) => {
    const btnRef = useRef();
    const { setMessage } = useTemplate();
    const { addPhoneContact } = useContact();

    const onCLickLinkAcc = () => {
        setMessage('Adding phone...');
        btnRef.current.disabled = true;

        addPhoneContact(formData)
            .then(({ error, message }) => {
                setMessage(message);
                btnRef.current.disabled = false;

                if (error === 0) {
                    setFormData({
                        phone: '',
                        sentId: '',
                        otp: '',
                        otpKey: ''
                    });
                    setModalData((cuData) => ({ ...cuData, type: null }));
                }
            })
            .catch((err) => {
                btnRef.current.disabled = false;
                setMessage(err);
            });
    };

    return (
        <button
            className="button button-gr-pink w-100 login-btn"
            type="button"
            onClick={onCLickLinkAcc}
            ref={btnRef}
        >
            Send OTP
        </button>
    );
};

const FormButtons = ({ formData, setFormData, setModalData }) => {
    if (formData.sentId) {
        return (
            <BtnVerifyAndLink
                formData={formData}
                setFormData={setFormData}
                setModalData={setModalData}
            />
        );
    }

    return <BtnSendOtp formData={formData} setFormData={setFormData} />;
};

const LinkAccountForm = ({ formData, setFormData, handelPhoneInput, setModalData }) => (
    <>
        <div className="line line-g2">
            <div className="cell cell-12 input-grp mb-2">
                <InpPhone
                    value={formData.phone}
                    setValue={handelPhoneInput}
                    title="Phone Number"
                    placeholder="ex: 8801795617763"
                    vendorIcon={icons.iconPhonePrimary}
                    isChecked={formData.otpIsVerified}
                    disabled={formData.sentId || formData.providerId}
                />
            </div>

            {!formData.otpIsVerified ? (
                <div className="cell cell-12 mb-2">
                    <OtpInputs formData={formData} setFormData={setFormData} />
                </div>
            ) : null}
        </div>

        <div className="mb-3">
            <FormButtons
                formData={formData}
                setFormData={setFormData}
                setModalData={setModalData}
            />
        </div>
    </>
);

function ContactInfoPhoneAddModalBody({ formData, setFormData, handelPhoneInput, setModalData }) {
    return (
        <div className="login-modal-area">
            <LinkAccountForm
                formData={formData}
                setFormData={setFormData}
                handelPhoneInput={handelPhoneInput}
                setModalData={setModalData}
            />

            <div className="text-center text-g mb-4 line">
                <div className="cell-5 login-divider">
                    <hr />
                </div>
                <div className="cell-2">or</div>
                <div className="cell-5 login-divider">
                    <hr />
                </div>
            </div>
        </div>
    );
}

export default ContactInfoPhoneAddModalBody;
