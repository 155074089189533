import { useState } from 'react';
import { loginAreaIcons } from '../../../../../configs/Icons';
import CommonModal from '../../../system/modals/CommonModal';

function NotificationIcon() {
    const [openModal, setOpenModal] = useState(null);
    return (
        <div className="icon-notification">
            <div
                role="button"
                tabIndex={-1}
                onClick={() => setOpenModal(true)}
                className="line-align line-md-none"
            >
                <img src={loginAreaIcons.iconNotificationV1} alt="icon notification" />
            </div>
            {openModal && <CommonModal openModal={openModal} setOpenModal={setOpenModal} />}
        </div>
    );
}
export default NotificationIcon;
