import { ButtonGr, ButtonOutlinePink } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import { useRole } from '../RoleProvider';

function Checkbox() {
    const { setFormData, formData } = useRole();
    const handleAgreement1 = () => {
        setFormData((cuData) => ({
            ...cuData,
            checkBox1: !formData?.checkBox1
        }));
    };
    const handleAgreement2 = () => {
        setFormData((cuData) => ({
            ...cuData,
            checkBox2: !formData?.checkBox2
        }));
    };

    return (
        <div className="text-left red mb-4">
            <div
                className="line-inline line-no-wrap mb-1"
                role="button"
                tabIndex={-1}
                onClick={handleAgreement1}
            >
                <span>
                    <input
                        checked={formData?.checkBox1 || false}
                        readOnly
                        type="checkbox"
                        name="backup_data"
                        id=""
                    />
                </span>{' '}
                <span className="font-14 ml-1">
                    The new owner will have full control over the hosting account!
                </span>
            </div>
            <div
                className="line-inline line-no-wrap"
                role="button"
                tabIndex={-1}
                onClick={handleAgreement2}
            >
                <span>
                    <input
                        checked={formData?.checkBox2 || false}
                        readOnly
                        type="checkbox"
                        name="cp_data"
                        id=""
                    />
                </span>{' '}
                <span className="font-14 ml-1">
                    Once the transfer is complete, the previous owner will no longer have access!
                </span>
            </div>
        </div>
    );
}

function WarningText() {
    return (
        <>
            <div className="line-row-center align-unset mb-2">
                <img className="d-block" src={icons.iconWarningLine} alt="" />
            </div>
            <div className="mb-2">
                <span className="font-16 red fw-500">
                    Are you sure you want to transfer the ownership of the contract?
                </span>
            </div>
        </>
    );
}

function ModalBody({ setModal, data }) {
    const { handelChangeOwner, handelReload, formData, setFormData } = useRole();
    const { setMessage } = useTemplate();

    const handelRoleOwner = () => {
        setMessage('Changing Ownership...');
        handelChangeOwner(data?.roleId).then(({ error, message }) => {
            if (error === 0) {
                setMessage(message);
                setModal({ type: null });
                handelReload();
            } else {
                setModal({ type: 'error' });
                setMessage(null);
            }
            setMessage(message);
        });
    };

    return (
        <div className="modal-body-area text-center mb-2 mt-2">
            <WarningText />
            <Checkbox />
            <div className="">
                <div className="line-row-center">
                    <span>
                        <ButtonOutlinePink
                            evOnClick={() => setModal({ type: null }) + setFormData({})}
                            title="No"
                            modal
                        />
                    </span>
                    <span className="ml-2">
                        <ButtonGr
                            disabled={!formData?.checkBox1 || !formData?.checkBox2}
                            evOnClick={handelRoleOwner}
                            title="Yes"
                            modal
                        />
                    </span>
                </div>
            </div>
        </div>
    );
}

function OwnershipWarningPopup({ show, setModal, modal }) {
    if (!show) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content" style={{ width: '450px' }}>
                <ModalBody setModal={setModal} data={modal?.data} />
            </div>
        </div>
    );
}

export default OwnershipWarningPopup;
