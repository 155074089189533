import { useState } from 'react';
import { icons } from '../../../../configs/Icons';
import DomainRegAvailableLg from '../../../../layouts/lg/DomainRegAvailableLg';
import CurrencyDropdownMenu from '../../../utils/CurrencyDropdownMenu';
import LayoutSelector from '../../../utils/LayoutSelector';
import PurchaseDomainModal from '../../websites/modals/PurchaseDomainModal';
import { ShowFormattedPrice } from '../../websites/models/Pricing';

function DomainFoundIcon() {
    return (
        <div className="cell d-lg-none">
            <div className="line-align">
                <span className="line">
                    <img src={icons.iconCheckD} alt="icon check green" />
                </span>
            </div>
        </div>
    );
}

function DomainNameArea({ productInfo }) {
    return (
        <div className="cell cell-self-middle">
            <div className="line-align mr-5">
                <div className="domain-text-area">
                    <p className="congrats-text">
                        <span className="break-all">
                            Congratulation, Your domain is Available for Registration!
                        </span>
                    </p>
                    <h4 className="domain-text">
                        <span className="break-all">
                            {productInfo?.property?.domain?.toUpperCase() ||
                                '-----------------------'}
                        </span>
                    </h4>
                </div>
            </div>
        </div>
    );
}

function DomainPricingArea({ productInfo, setPurchaseData }) {
    return (
        <div className="cell-fill cell-self-middle">
            <div className="domain-pricing-area">
                <div className="line-align line-no-wrap justify-end ">
                    <div className="cell">
                        <div className="text-end pricing-text">
                            <div className="no-wrap price">
                                <span className="line-align line-no-wrap justify-end pricing-area gap">
                                    {productInfo ? (
                                        <CurrencyDropdownMenu>
                                            <span className="w-100 h-100">
                                                <img
                                                    className="w-100 h-100 d-block"
                                                    src={icons.iconCurrencyExchange}
                                                    alt="currency change icon"
                                                />
                                            </span>
                                        </CurrencyDropdownMenu>
                                    ) : null}

                                    <span className="ml-2">
                                        {ShowFormattedPrice(
                                            (productInfo?.price?.registration || 0) * 12
                                        ) || '0.00'}
                                        /yr
                                    </span>
                                </span>
                            </div>
                            <div className="no-wrap renew-price">
                                <span>
                                    Renew{' '}
                                    {ShowFormattedPrice((productInfo?.price?.renew || 0) * 12) ||
                                        '0.00'}
                                    /yr
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="cell">
                        <span>
                            <div
                                role="button"
                                tabIndex={-1}
                                onClick={() => setPurchaseData(productInfo)}
                                className="domain-cart-button line-align justify-end "
                            >
                                <span className="">Add to Cart</span>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function DomainAvailableXl({ productInfo, setPurchaseData, purchaseData }) {
    // console.log(productInfo);
    return (
        <div className={`container ${!productInfo ? 'placeholder-mode' : ''}`}>
            <div className="domain-reg-av-area ">
                <div className="line line-no-wrap">
                    <DomainFoundIcon />
                    <DomainNameArea productInfo={productInfo} />
                    <DomainPricingArea
                        productInfo={productInfo}
                        setPurchaseData={setPurchaseData}
                    />
                </div>
            </div>

            {purchaseData ? (
                <PurchaseDomainModal
                    productInfo={purchaseData || null}
                    setPurchaseData={setPurchaseData}
                />
            ) : null}
        </div>
    );
}

function SearchDomainRegAvailable({ domainInfo }) {
    const [purchaseData, setPurchaseData] = useState(null);
    const productInfo = domainInfo?.productInfo || null;

    return (
        <LayoutSelector
            sm
            md
            lg
            webComp={
                <DomainAvailableXl
                    productInfo={productInfo}
                    purchaseData={purchaseData}
                    setPurchaseData={setPurchaseData}
                />
            }
            mobileComp={
                <DomainRegAvailableLg
                    productInfo={productInfo}
                    purchaseData={purchaseData}
                    setPurchaseData={setPurchaseData}
                />
            }
        />
    );
}

export default SearchDomainRegAvailable;
