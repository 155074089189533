import React, { useRef, useState } from 'react';
import { basic } from '../../../../../configs/Images';

const serviceList = [
    {
        id: 1,
        title: 'Domain Service',
        img: basic.domainImg,
        status: true
    },
    {
        id: 2,
        title: 'Hosting Service',
        img: basic.hostingImg,
        status: true
    },
    {
        id: 3,
        title: 'Email Service',
        img: basic.emailImg,
        status: false
    },
    {
        id: 4,
        title: 'Sms Service',
        img: basic.smsImg,
        status: true
    }
];

function Divider({ title }) {
    return (
        <div className="line-align line-no-wrap mb-4">
            <div className="cell title fw-400">{title}</div>
            <hr className="cell-fill cell-self-end ml-1" style={{ opacity: '0.5' }} />
        </div>
    );
}

function ServiceItem({ imgSrc, title, status }) {
    return (
        <div className="cell cell-4 cell-xl-6 cell-md-12 mb-2">
            <div className="list-item line-align">
                <div className="line cell-self-middle" style={{ maxWidth: '68px' }}>
                    <img className="w-100" src={imgSrc} alt="" />
                </div>
                <div className="ml-3 line-col">
                    <span
                        className={`status line-row-center align-unset ${
                            status ? 'running' : 'disable'
                        }`}
                    >
                        {status ? 'Running' : 'Disable'}
                    </span>
                    <span>{title}</span>
                </div>
            </div>
        </div>
    );
}

function ServiceTabButton({ setServiceType, btnRef1, btnRef2 }) {
    const handleBtnClick = (item) => {
        const buttonLeft = btnRef1?.current?.classList;
        const buttonRight = btnRef2?.current?.classList;

        const active = (name) => name.add('active');
        const inactive = (name) => name.remove('active');

        if (item === 1) {
            setServiceType(null);
            active(buttonLeft);
            inactive(buttonRight);
        }
        if (item === 2) {
            setServiceType(true);
            active(buttonRight);
            inactive(buttonLeft);
        }
    };
    return (
        <div className="service-tab-button">
            <div className="line-align line-no-wrap justify-start">
                <button
                    ref={btnRef1}
                    onClick={() => handleBtnClick(1)}
                    type="button"
                    className="active"
                >
                    All Service
                </button>
                <button
                    ref={btnRef2}
                    onClick={() => handleBtnClick(2)}
                    type="button"
                    className="ml-2"
                >
                    Running Services
                </button>
                <div />
            </div>
        </div>
    );
}

function ProfileServicesSection() {
    const [serviceType, setServiceType] = useState();

    const btnRef1 = useRef();
    const btnRef2 = useRef();
    const services = serviceType
        ? serviceList.filter((el) => el.status === serviceType)
        : serviceList;
    return (
        <div className="product-info mb-4 service-list-area">
            <Divider title="Services List" />
            <ServiceTabButton
                btnRef1={btnRef1}
                btnRef2={btnRef2}
                setServiceType={setServiceType}
                serviceType={serviceType}
            />
            <div className="line-align line-g1">
                {services.map((item) => (
                    <ServiceItem
                        show={serviceType === 'running'}
                        key={item?.id}
                        imgSrc={item?.img}
                        title={item?.title}
                        status={item?.status}
                    />
                ))}
            </div>
        </div>
    );
}

export default ProfileServicesSection;
