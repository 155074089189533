import { createContext, useContext, useState } from 'react';

const DomainCnsContext = createContext();

export function useScroll() {
    return useContext(DomainCnsContext);
}

function ScrollProvider({ children }) {
    const [isScroll, setIsScroll] = useState(null);
    const [element, setElement] = useState(null);
    const handleScroll = (e) => {
        const activity = e.target.scrollTop > 0;
        if (activity) {
            setIsScroll(true);
        } else {
            setIsScroll(false);
        }
    };
    const value = {
        handleScroll,
        setIsScroll,
        isScroll,
        setElement,
        element
    };

    return <DomainCnsContext.Provider value={value}>{children}</DomainCnsContext.Provider>;
}

export default ScrollProvider;
