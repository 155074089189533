import React from 'react';
import HeaderSection from '../../../headers/HeaderSection';
import FixedHeaderControl from '../../../websites/components/FixedHeaderControl';

function TermsConditionPage() {
    return (
        <>
            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>
        </>
    );
}

export default TermsConditionPage;
