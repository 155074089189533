import { useEffect } from 'react';
import { ButtonGr } from '../../../../../configs/Buttons';
import PageProperties from '../../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../../headers/DashboardHeaderSection';
import BillingsListSection from './billings_list/BillingsListSection';

function HeaderContent() {
    return (
        <div className="line justify-end">
            <ButtonGr evOnClick={null} title="Create Invoice" />
        </div>
    );
}

function ContentBodyArea() {
    return (
        <div className="list-container w-100">
            <BillingsListSection />
        </div>
    );
}

function UsersBillingsPage() {
    useEffect(() => {
        PageProperties(`Billings \u2022 Support`);
    }, []);

    return (
        <div className="cell cell-content h-100 w-100 domain-section">
            <div className="content-section">
                <div className="content-area user-contract-page-area">
                    <DashboardDefaultHeader
                        title="Billings"
                        path="/smanager/ticket/"
                        content={<HeaderContent />}
                        mobileContent={<HeaderContent />}
                    />

                    <div className="content-body-scroll" style={{ paddingBottom: '180px' }}>
                        <ContentBodyArea />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default UsersBillingsPage;
