import React, { useState } from 'react';
import { ButtonPurple, ButtonSolidSky } from '../../../../../configs/Buttons';
import LayoutSelector from '../../../../utils/LayoutSelector';
import SearchInput from '../../../../utils/inputs/SearchInput';
import DnsRecordArea from '../dns_record/DnsRecordArea';
import DnsImportPopup from './DnsImportPopup';
import DnsListMobile from './DnsListMobile';
import DnsListWeb from './DnsListWeb1';

const listArr = [
    // {
    //     id: 1,
    //     type: 'A',
    //     name: 'alokitomunshiganj.com',
    //     proxy: 'DNS only',
    //     content: '51.79.229.154',
    //     ttl: 'Auto'
    // },
    // {
    //     id: 2,
    //     type: 'AAAA',
    //     name: 'alokitomunshiganj.com',
    //     proxy: 'Proxied',
    //     content: '51.79.229.154',
    //     ttl: 'Auto'
    // },
    // {
    //     id: 3,
    //     type: 'CNAME',
    //     name: 'alokitomunshiganj.com',
    //     proxy: 'DNS only',
    //     content: '51.79.229.154',
    //     ttl: 'Auto'
    // },
    // {
    //     id: 4,
    //     type: 'MX',
    //     name: 'alokitomunshiganj.com',
    //     proxy: 'Proxied',
    //     content: '51.79.229.154',
    //     ttl: 'Auto'
    // },
    // {
    //     id: 5,
    //     type: 'NS',
    //     name: 'alokitomunshiganj.com',
    //     proxy: 'DNS only',
    //     content: '51.79.229.154',
    //     ttl: 'Auto'
    // },
    // {
    //     id: 6,
    //     type: 'PTR',
    //     name: 'alokitomunshiganj.com',
    //     proxy: 'Proxied',
    //     content: '51.79.229.154',
    //     ttl: 'Auto'
    // },
    // {
    //     id: 7,
    //     type: 'SPF',
    //     name: 'alokitomunshiganj.com',
    //     proxy: 'DNS only',
    //     content: '51.79.229.154',
    //     ttl: 'Auto'
    // },
    // {
    //     id: 8,
    //     type: 'SRV',
    //     name: 'alokitomunshiganj.com',
    //     proxy: 'Proxied',
    //     content: '51.79.229.154',
    //     ttl: 'Auto'
    // },
    // {
    //     id: 9,
    //     type: 'TXT',
    //     name: 'alokitomunshiganj.com',
    //     proxy: 'DNS only',
    //     content: '51.79.229.154',
    //     ttl: 'Auto'
    // },
    // {
    //     id: 10,
    //     type: 'SOA',
    //     name: 'alokitomunshiganj.com',
    //     proxy: 'Proxied',
    //     content: '51.79.229.154',
    //     ttl: 'Auto'
    // },
    // {
    //     id: 11,
    //     type: 'CAA',
    //     name: 'alokitomunshiganj.com',
    //     proxy: 'DNS only',
    //     content: '51.79.229.154',
    //     ttl: 'Auto'
    // }
];

const types = ['A', 'AAAA', 'CNAME', 'MX', 'NS', 'PTR', 'SPF', 'SRV', 'TXT', 'SOA', 'CAA'];

function ListHeaderAction({ setSearchValue, searchValue, setMode }) {
    return (
        <div className="line line-g1 line-no-wrap line-lg-wrap">
            <div className="cell cell-fill cell-lg-12  mb-3">
                <div className="mb-1">
                    <SearchInput
                        value={searchValue}
                        setValue={(e) => setSearchValue(e.target.value)}
                        height={35}
                    />
                </div>
                <div>
                    <div className="line line-no-wrap text-dotted font-14">
                        <span className="clr mr-2">Filter:</span>
                        {types?.map((data) => (
                            <span
                                role="button"
                                tabIndex={-1}
                                onClick={() => setSearchValue(data)}
                                key={data}
                                className="purple cursor-pointer"
                            >
                                {`${data},`} &nbsp;
                            </span>
                        ))}
                    </div>
                </div>
            </div>
            <div className="cell cell-lg-6  mb-3">
                <span className="import-btn">
                    <ButtonSolidSky
                        evOnClick={() => setMode({ type: 'import' })}
                        title="Import & Export"
                    />
                </span>
            </div>
            <div className="cell cell-lg-6  mb-3">
                <span className="import-btn">
                    <ButtonPurple
                        evOnClick={() => setMode({ type: 'add-record' })}
                        title="Add Record"
                    />
                </span>
            </div>
        </div>
    );
}

function DnsListSection({ domain }) {
    const [mode, setMode] = useState({ type: '', data: null });
    const [searchValue, setSearchValue] = useState('');

    return (
        <div>
            <ListHeaderAction
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                setMode={setMode}
            />
            <DnsRecordArea
                types={types}
                isActive={mode.type === 'add-record'}
                evCloseClick={() => setMode({ type: null })}
            />
            <DnsImportPopup show={mode.type === 'import'} setMode={setMode} domain={domain} />
            <LayoutSelector
                sm
                md
                webComp={<DnsListWeb searchValue={searchValue} listArr={listArr} />}
                mobileComp={<DnsListMobile searchValue={searchValue} arr={listArr} />}
            />
        </div>
    );
}

export default DnsListSection;
