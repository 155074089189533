import React, { useEffect } from 'react';
import HeaderSection from '../../headers/HeaderSection';
import FixedHeaderControl from '../components/FixedHeaderControl';
import FooterSectionHome from '../components/FooterSectionHome';
import HomeSearchSection from '../components/HomeSearchSection';

function IndexPage(props) {
    const { page } = props;

    useEffect(() => {
        if (page === 'services') {
            document.getElementById('services').scrollIntoView();
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [page]);

    // useEffect(() => {
    //     PageProperties('BIKIRAN');
    // }, []);

    return (
        <>
            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>

            <HomeSearchSection />

            <FooterSectionHome />
        </>
    );
}

export default IndexPage;
