import { createContext, useContext, useEffect, useState } from 'react';

import AxiosAuth from '../../../utils/AxiosAuth';
import HostingInfoErrorPopup from '../hostings_Info/hostinginfo_popup/HostingInfoErrorPopup';

const HostingInfoConversationContext = createContext();

export function useHostingConversation() {
    return useContext(HostingInfoConversationContext);
}

/* 
{
        contractId: 10000496,
        domain: 'DATABASES.COM',
        title: 'APP Hosting - 4 GB SSD'
    }
 */

function HostingInfoConversationProvider({ children, currentUser, contractId }) {
    const [listKey, setListKey] = useState(null);
    const [conversationInfo, setConversationInfo] = useState({
        contractId: 10000496,
        domain: 'CONVEERSATION.COM',
        title: 'Web Hosting - 4 GB SSD'
    });
    const [error, setError] = useState(null);
    const reloadList = () => {
        setListKey(Math.random());
    };

    const handelReloadButton = () => {
        setListKey(Math.random());
        setConversationInfo(null);
    };

    // get info
    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .setApiUrl('CONTRACT_HOSTING', `/cp/conversation/`)
            .post({ contractId })
            .then(({ data }) => {
                setConversationInfo(null);
                if (data.error === 0) {
                    setConversationInfo(data);
                } else {
                    setError(data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [contractId, currentUser, listKey]);

    const value = {
        reloadList,
        conversationInfo,
        error,
        handelReloadButton
    };

    return (
        <HostingInfoConversationContext.Provider value={value}>
            {children}
            {error?.error && error?.error !== 0 ? <HostingInfoErrorPopup error={error} /> : null}
        </HostingInfoConversationContext.Provider>
    );
}

export default HostingInfoConversationProvider;
