import { icons } from '../../../../configs/Icons';

function TabsNs({ disabled, evOnClick, ns }) {
    return (
        <div
            className={`cell cell-fill cell-md-4 cell-xl-3 cell-sm-5 ${
                disabled ? 'disabled-view' : ''
            }`}
            onClick={evOnClick}
            role="button"
            tabIndex={-1}
        >
            <div
                role="button"
                tabIndex={-1}
                className={`info-tab-item domain ns line-row-center ${ns ? 'active' : ''}`}
            >
                <div className="line-col-center">
                    <div className="logo">
                        <img
                            className="w-100 h-100 d-block"
                            src={ns ? icons.iconNsActive : icons.iconNsV2}
                            alt="ns"
                        />
                    </div>
                    <div className="title">
                        <span>Name Servers</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TabsNs;
