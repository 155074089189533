import { useTemplate } from '../../../../contexts/TemplateProvider';
import InstOption from '../../../../utils/InstOption';
import { useContact } from '../ContactManageProvider';

function ContactInfoEmailTableMenu({ showOptions, setShowOptions, myRef, data, type }) {
    const { setConfirm, setMessage } = useTemplate();
    const { makeAsPrimary, removeContact } = useContact();

    const handelPrimary = () => {
        setMessage('Setting as primary...');

        makeAsPrimary(data?.id, data?.userUid, type).then(({ error, message }) => {
            setMessage(message);
            if (error === 0) {
                setMessage(message);
                setShowOptions(false);
            }
        });
    };

    const handleRemoveContact = () => {
        if (data?.isDefault) {
            setShowOptions('default');
        } else {
            setConfirm({
                text: 'Are you sure you want delete this contact?',
                clickAction: (confirm, setNull) => {
                    removeContact(data.id, data.userUid);
                    setNull(null);
                    setMessage('Your contact has been deleted');
                }
            });
        }
    };

    return (
        <InstOption show={showOptions} setShow={setShowOptions} myRef={myRef}>
            {data?.status === 'pending' ? (
                <button onClick={null} type="button" className="default-none w-100 text-left">
                    <span>Verify Otp</span>
                </button>
            ) : (
                <button
                    onClick={handelPrimary}
                    type="button"
                    className="default-none w-100 text-left"
                >
                    <span>Make Primary</span>
                </button>
            )}
            <button
                onClick={handleRemoveContact}
                type="button"
                className="default-none w-100 text-left"
            >
                <span>Delete</span>
            </button>
        </InstOption>
    );
}

export default ContactInfoEmailTableMenu;
