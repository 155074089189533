import { createContext, useContext, useEffect, useState } from 'react';
import AxiosAuth from '../../../utils/AxiosAuth';
import { mkToken } from '../../../utils/StringOperation';

const RoleContext = createContext();

export function useRole() {
    return useContext(RoleContext);
}

const initialState = {
    roles: null
};

function RoleProvider({ children, currentUser, tbl, contractId }) {
    const [reload, setReloadData] = useState('');
    const [roleData, setRoleData] = useState(initialState);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState(null);

    // console.log(roleData);

    const handelReload = () => {
        setReloadData(mkToken(32));
    };

    const handelInviteUser = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT_ROLE', `/${tbl}/${contractId}/invite-send/`)
                .post({
                    email: formData?.email || '',
                    role: formData?.role || ''
                })
                .then(({ data }) => {
                    setError(data);
                    if (data.error === 0) {
                        setFormData(initialState);
                    }
                    resolve({ error: data.error, message: data.message });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const handelResendInvite = (roleId) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT_ROLE', `/${tbl}/${contractId}/invite-resend/`)
                .post({
                    roleId: roleId || ''
                })
                .then(({ data }) => {
                    if (data.error === 0) {
                        setFormData(initialState);
                    }
                    setError(data);
                    resolve({ error: data.error, message: data.message });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const handelCancelInvite = (roleId) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT_ROLE', `/${tbl}/${contractId}/invite-cancel/`)
                .post({
                    roleId
                })
                .then(({ data }) => {
                    if (data.error === 0) {
                        setFormData(initialState);
                    }
                    setError(data);

                    resolve({ error: data.error, message: data.message });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const handelChangeOwner = (roleId) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT_ROLE', `/${tbl}/${contractId}/change-ownership/`)
                .post({ roleId })
                .then(({ data }) => {
                    if (data.error === 0) {
                        setFormData(initialState);
                    }
                    setError(data);
                    resolve({ error: data.error, message: data.message });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const handelChangeRole = (roleId, roleKey) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT_ROLE', `/${tbl}/${contractId}/modify/`)
                .post({ roleId: roleId || '', roleKey: roleKey || '' })
                .then(({ data }) => {
                    if (data.error === 0) {
                        setFormData(initialState);
                    }

                    resolve({ error: data.error, message: data.message });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const handelRemoveRole = (roleId) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT_ROLE', `/${tbl}/${contractId}/delete/`)
                .post({
                    roleId
                })
                .then(({ data }) => {
                    setError(data);
                    if (data.error === 0) {
                        setFormData(initialState);
                    }

                    resolve({ error: data.error, message: data.message });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    useEffect(() => {
        if (contractId) {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT_ROLE', `/${tbl}/${contractId}/list/`)
                .get()
                .then(({ data }) => {
                    // console.log(data);
                    if (data.error === 0) {
                        setRoleData((cData) => ({
                            ...cData,
                            roles: data.data,
                            addButton: data.addButton
                        }));
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [contractId, currentUser, reload, tbl]);
    const value = {
        error,
        setFormData,
        formData,
        roles: roleData.roles,
        addButton: roleData.addButton,
        setRoleData,
        handelReload,
        handelInviteUser,
        handelResendInvite,
        handelCancelInvite,
        handelChangeOwner,
        handelChangeRole,
        handelRemoveRole
    };

    return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>;
}

export default RoleProvider;
