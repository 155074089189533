import { useNavigate } from 'react-router-dom';
import { ButtonPurple } from '../../../../../configs/Buttons';
import { arrowIcons } from '../../../../../configs/Icons';
import { basic } from '../../../../../configs/Images';
import { useLayout } from '../../../../contexts/LayoutProvider';

function ProfilePicture({ data }) {
    const value = 51;
    const color = [];
    const colorIdentifier = () => {
        if (value <= 25) {
            color.push(
                '#ae00b9',
                'rgba(19, 15, 64, 0.2)',
                'rgba(19, 15, 64, 0.2)',
                'rgba(19, 15, 64, 0.2)'
            );
        }
        if (value > 25 && value <= 50) {
            color.push('#ae00b9', '#ae00b9', 'rgba(19, 15, 64, 0.2)', 'rgba(19, 15, 64, 0.2)');
        }
        if (value > 50 && value < 100) {
            color.push('#ae00b9', '#ae00b9', '#ae00b9', 'rgba(19, 15, 64, 0.2)');
        }
        if (value === 100) {
            color.push('#ae00b9', '#ae00b9', '#ae00b9', '#ae00b9');
        }
        return color;
    };

    return (
        <div
            className="profile-dp line-row-center mr-3"
            style={{ borderColor: colorIdentifier().join(' ') }}
        >
            <img
                className="w-100"
                src={data?.firebase?.photoURL || data?.photoUrl || basic.imgAvatar}
                alt="user dp"
            />
            <span className="completion-value">{value}%</span>
        </div>
    );
}

function ProfileHeader({ data }) {
    const navigate = useNavigate();

    const { windowSizeName } = useLayout();

    const isMobile =
        windowSizeName === 'xs' ||
        windowSizeName === 'sm' ||
        windowSizeName === 'md' ||
        windowSizeName === 'lg';

    return (
        <div
            className="profile-header line-margin line-align"
            style={{
                paddingTop: isMobile ? '0px' : ''
            }}
        >
            <div className="cell cell-fill">
                <div className="line-align">
                    <div
                        role="button"
                        tabIndex={-1}
                        onClick={() => navigate(-1)}
                        className="mr-3 d-lg-none"
                    >
                        <img
                            className="w-100"
                            src={arrowIcons.iconArrowLeftV2}
                            alt="left arrow icon"
                        />
                    </div>
                    <ProfilePicture data={data} />
                    <div>
                        <div className="font-20">{data?.displayName || '--'}</div>
                        <div className="font-14 opacity-medium">Joined: ---</div>
                    </div>
                </div>
            </div>
            <div className="cell d-sm-none">
                <ButtonPurple evOnClick={null} title="Complete Profile" />
            </div>
        </div>
    );
}

export default ProfileHeader;
