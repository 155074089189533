/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { ButtonClose, ButtonPurple } from '../../../configs/Buttons';
import { useTemplate } from '../../contexts/TemplateProvider';
import OtpInputs from '../../utils/inputs/OtpInputs';

const ModalHeader = ({ handleCloseClick }) => (
    <div className="modal-box-header border-bottom">
        <div>
            <div className="modal-box-title">
                <span>Verify Email</span>
            </div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={handleCloseClick} />
            </div>
        </div>
    </div>
);

function ModalBody({ resendOtp, verifyOtp, formData, setFormData, setMode }) {
    const { setMessage } = useTemplate();

    const handelVerifyButton = () => {
        setMessage('Verifying....');
        setMode(1);

        verifyOtp()
            .then(({ message }) => {
                setMode(0);
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    return (
        <div className="modal-body-area">
            <div className="otp-label-text">
                <span>&bull;</span>{' '}
                <span className="break-all">
                    An OTP has been sent to <b>{formData.email}</b>
                </span>
                .<br />
                <span>&bull;</span>{' '}
                <span className="break-all">Please put the OTP to verify your authorization.</span>
                <br />
                <span>&bull;</span>{' '}
                <span className="break-all">The OTP is valid only for 5 minutes.</span>
            </div>

            <OtpInputs formData={formData} setFormData={setFormData} handelOtpSend={resendOtp} />

            <div className="fill mt-3" style={{ height: '42px' }}>
                <ButtonPurple
                    evOnClick={handelVerifyButton}
                    title="Verify"
                    disabled={false}
                    modal
                />
            </div>
        </div>
    );
}

function VerificationOtpForm({
    resendOtp,
    verifyOtp,
    closeWindow,
    formData,
    setFormData,
    stateData
}) {
    const [mode, setMode] = useState(0);

    return (
        <div className="modal-box backdrop remove-wizard-area">
            <div
                className={`modal-box-content ${
                    stateData?.verified || mode !== 0 ? 'loading-block' : ''
                }`}
            >
                <ModalHeader handleCloseClick={closeWindow} />
                <ModalBody
                    resendOtp={resendOtp}
                    verifyOtp={verifyOtp}
                    formData={formData}
                    setFormData={setFormData}
                    mode={mode}
                    setMode={setMode}
                />
            </div>
        </div>
    );
}

export default VerificationOtpForm;
