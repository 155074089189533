import { ButtonPurple } from '../../../../../../configs/Buttons';
import { useDnsInfo } from '../../DnsProvider';

function TabAAAAContentArea() {
    const { formData, setFormData } = useDnsInfo();

    const handelCreate = () => null;

    const isDisabled = !formData?.hostname || !formData?.ip || !formData?.ttl;

    return (
        <div>
            <div className="mb-1">
                <span className="content-text">
                    Use <b>@</b> to create the record at the root of the domain or enter a hostname
                    to create it elsewhere. <b>AAAA</b> records are for <b>IPv6</b> addresses only
                    and tell a request where your domain should direct to.
                </span>
            </div>
            <div className="record-input-area">
                <div className="line align-end line-g1">
                    <div className="cell cell-fill">
                        <div className="label">
                            Hostname<span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, hostname: e.target.value }))
                            }
                            placeholder="Enter @ or hostname"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>
                    <div className="cell cell-fill">
                        <div className="label">
                            Will Direct to <span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, ip: e.target.value }))
                            }
                            placeholder="Enter Custom IP"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>
                    <div className="cell cell-fill">
                        <div className="label">
                            TTL (SECONDS)<span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, ttl: e.target.value }))
                            }
                            placeholder="36000"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>
                    <div className="cell cell-12">
                        <div className="label">Comment</div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, comment: e.target.value }))
                            }
                            placeholder="Here is the Comment"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>
                    <div className="cell cell-12">
                        <div className="line justify-end mt-2">
                            <ButtonPurple
                                disabled={isDisabled}
                                evOnClick={handelCreate}
                                title="Create Record"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TabAAAAContentArea;
