function HostingInfoDotMenu({ show, evOnClick, mode, dotMenu }) {
    if (!show) {
        return null;
    }
    return (
        <div className="cell cell-fill" onClick={evOnClick} role="button" tabIndex={-1}>
            <div
                // onClick={handelDotMenu}
                // role="button"
                tabIndex={-1}
                className={`tab-dot-menu ${mode || dotMenu ? 'active' : ''}`}
            />
        </div>
    );
}

export default HostingInfoDotMenu;
