import React from 'react';
import { basic } from '../../../../configs/Images';

const ProjectInfo = () => (
    <div className="project-info">
        <div className="line">
            <div className="cell cell-fill cell-self-middle">
                <span className="project-title">BIKIRAN.COM</span>
            </div>
            <div className="cell">
                <img src={basic.iconMenu} alt="Menu" className="menu-icon" />
            </div>
        </div>
    </div>
);

const ProjectSelector = () => (
    <div className="project-selector">
        <div className="selector line">
            <div className="cell cell-self-middle">
                <img src={basic.logoBikiran1} alt="Bikiran" />
            </div>
            <div className="cell cell-fill cell-self-middle">
                <span className="project-title">BIKIRAN.COM</span>
            </div>
            <div className="cell cell-self-middle">
                <img src={basic.iconProjectSelector} alt="Menu" className="menu-icon" />
            </div>
        </div>
    </div>
);

function LeftSidebarSection() {
    return (
        <>
            <ProjectInfo />

            <ProjectSelector />
        </>
    );
}

export default LeftSidebarSection;
