/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import PaymentProvider, { usePayment } from '../../../contexts/PaymentProvider';
import PaymentInvoiceView from './PaymentInvoiceView';
import PaymentOperation from './PaymentOperation';

function CartPaymentArea() {
    const ref = useRef();
    const navigate = useNavigate();
    const { invoiceId } = useParams();

    const {
        error,
        invoiceData,
        paymentOptions,
        selectedPayment,
        setSelectedPayment,
        toComplete,
        openAddressEditor
    } = usePayment();

    const isDue = invoiceData?.statusPayment === 'due';

    useEffect(() => {
        ref.current.onchange = (ev) => {
            ev.preventDefault();
            const { value } = ev.target;

            if (value.length === 8 && /^\d*$/.test(value)) {
                navigate(`/payment/${ev.target.value}/`);
            }
        };

        ref.current.value = invoiceId;
    }, [invoiceId, navigate]);

    // if (invoiceData === null) {
    //     return <div>Loading.....</div>;
    // }

    if (invoiceData?.error) {
        return <h1 className="text-center mb-4">Page Not Found</h1>;
    }

    return (
        <div className={`container cart-payment-area ${!invoiceData ? 'placeholder-mode' : ''}`}>
            <div className="line line-no-wrap line-g3">
                <div className="cell  cell-lg-12 cell-invoice cell-xl-7 ">
                    <PaymentInvoiceView
                        error={error}
                        isDue={isDue}
                        invoiceData={invoiceData}
                        openAddressEditor={openAddressEditor}
                    />
                </div>

                <div className="cell cell-lg-12 cell-fill cell-xl-5">
                    <div className="invoice-update-input">
                        <label htmlFor="invoice" className="">
                            Update Invoice:
                        </label>
                        <input
                            ref={ref}
                            placeholder="#Invoice Number"
                            type="text"
                            maxLength={8}
                            className="form-input w-100"
                        />
                    </div>
                    <PaymentOperation
                        invoiceData={invoiceData}
                        paymentOptions={paymentOptions}
                        selectedPayment={selectedPayment}
                        setSelectedPayment={setSelectedPayment}
                        toComplete={toComplete}
                        isDue={isDue}
                    />
                </div>
            </div>
        </div>
    );
}

function PaymentSection() {
    const { invoiceId } = useParams();
    const { currentUser } = useAuth();
    const location = useLocation();
    const hash = new URLSearchParams(location.search).get('hash');

    return (
        <PaymentProvider invoiceId={invoiceId} currentUser={currentUser} hash={hash}>
            <CartPaymentArea />
        </PaymentProvider>
    );
}

export default PaymentSection;
