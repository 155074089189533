/* eslint-disable jsx-a11y/label-has-associated-control */
import { ButtonClose, ButtonPurple } from '../../../../../configs/Buttons';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import CopyText from '../../../websites/models/CopyText';
import { useDomainInfo } from '../../domains_info/DomainInfoBasicProvider';

const ModalHeader = ({ handleCloseClick }) => {
    const { domain } = useDomainInfo();
    return (
        <div className="modal-box-header">
            <div>
                <div className="modal-box-title">
                    <span>Domain Secret</span>
                    <span>
                        <small>
                            <CopyText copyValue={domain} value={domain?.toUpperCase()} />
                        </small>
                    </span>
                </div>
                <div className="modal-box-close">
                    <ButtonClose evOnClick={handleCloseClick} />
                </div>
            </div>
        </div>
    );
};

function ModalBody({ handleCloseClick }) {
    const { updateDomainSecret, cuContact } = useDomainInfo();
    const email = cuContact?.registrant?.email || '--';

    const { setMessage } = useTemplate();

    const handelSendKey = () => {
        setMessage('Sending...');
        updateDomainSecret()
            .then(({ error, message }) => {
                if (error === 0) {
                    handleCloseClick();
                }
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    return (
        <div className="modal-body-area h-100">
            <ul className="list-disc mt-0">
                <li className="">
                    <span className="font-14">
                        We will email <span className="purple">({email || '--'})</span> you with the
                        domain secret code.{' '}
                    </span>
                </li>
                <li className="">
                    <span className="font-14">
                        Please keep the domain secret code confidential.
                    </span>
                </li>
                <li className="">
                    <span className="font-14">
                        The Domain Secret Code can only be used to transfer domains.
                    </span>
                </li>
                <li className="">
                    <span className="font-14">
                        We have wizard for domain transfer, you can easily transfer domain by using
                        wizard
                    </span>
                </li>
            </ul>
            <div className="h-100 line-row-center">
                <ButtonPurple evOnClick={handelSendKey} title="Send Key to Email" modal />
            </div>
        </div>
    );
}

function BasicInfoDomainSecretPopup({ show, setModal }) {
    const handleCloseClick = () => setModal(null);
    if (!show) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content cp-wizard" style={{ width: '450px' }}>
                <ModalHeader handleCloseClick={handleCloseClick} />
                <ModalBody handleCloseClick={handleCloseClick} />
            </div>
        </div>
    );
}

export default BasicInfoDomainSecretPopup;
