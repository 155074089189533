import {
    ButtonActionCancel,
    ButtonActionDelete,
    ButtonActionEdit,
    ButtonActionSave
} from '../../../../../configs/Buttons';
import LayoutSelector from '../../../../utils/LayoutSelector';

function ButtonsWeb({ setViewType, viewType, data }) {
    if (viewType !== null) {
        return (
            <div className="line-align line-no-wrap btn-action-web">
                <span className="">
                    <ButtonActionSave evOnClick={() => setViewType(null)} />
                </span>
                <span className="ml-2 ">
                    <ButtonActionCancel evOnClick={() => setViewType(null)} />
                </span>
            </div>
        );
    }
    return (
        <div className="line-align line-no-wrap btn-action-web">
            <span className="">
                <ButtonActionEdit evOnClick={() => setViewType(data?.id)} />
            </span>
            <span className="ml-2 ">
                <ButtonActionDelete />
            </span>
        </div>
    );
}

function ButtonsMobile({ setViewType, viewType, data }) {
    if (viewType !== null && viewType === data?.id) {
        return (
            <span className="line-align line-no-wrap">
                <span className="">
                    <ButtonActionSave evOnClick={() => setViewType(null)} />
                </span>
                <span className="ml-2 ">
                    <ButtonActionCancel evOnClick={() => setViewType(null)} />
                </span>
            </span>
        );
    }
    return (
        <span className="line-align line-no-wrap">
            <span className="">
                <ButtonActionEdit evOnClick={() => setViewType(data?.id)} />
            </span>
            <span className="ml-2 ">
                <ButtonActionDelete />
            </span>
        </span>
    );
}

function SocialListButtonArea({ setViewType, viewType, data }) {
    return (
        <LayoutSelector
            sm
            md
            webComp={<ButtonsWeb setViewType={setViewType} viewType={viewType} data={data} />}
            mobileComp={<ButtonsMobile setViewType={setViewType} viewType={viewType} data={data} />}
        />
    );
}

export default SocialListButtonArea;
