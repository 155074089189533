import { useEffect, useRef, useState } from 'react';

function useOutsideClick(ref, setEditMode) {
    useEffect(() => {
        function handelClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setEditMode(null);
            }
        }
        document.addEventListener('mousedown', handelClickOutside);
        return () => {
            document.removeEventListener('mousedown', handelClickOutside);
        };
    }, [ref, setEditMode]);
}

function ParamsConent({ setEditMode, editMode }) {
    const inputRef = useRef(null);
    useOutsideClick(inputRef, setEditMode);
    return (
        <table className="table-list" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th width="30%" colSpan={2}>
                        <span>Key</span>
                    </th>
                    <th width="30%" colSpan={2}>
                        <span>Value</span>
                    </th>
                    <th colSpan={2}>
                        <span>Description</span>
                    </th>
                    <th colSpan={1}>
                        <span>...</span>
                    </th>
                    <th colSpan={1}>
                        <span>Bulk Edit</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr height="50px">
                    {editMode === 'p-key' ? (
                        <td colSpan={2} className="key-active">
                            <input
                                ref={inputRef}
                                style={{ height: '30px' }}
                                type="text"
                                className="form-input w-100"
                                placeholder="Key"
                            />
                        </td>
                    ) : (
                        <td colSpan={2}>
                            <span
                                className="d-block h-100"
                                role="button"
                                tabIndex={-1}
                                onClick={() => setEditMode('p-key')}
                            >
                                Key
                            </span>
                        </td>
                    )}
                    {editMode === 'p-val' ? (
                        <td colSpan={2} className="val-active">
                            <input
                                ref={inputRef}
                                type="text"
                                className="form-input w-100"
                                placeholder="Value"
                            />
                        </td>
                    ) : (
                        <td colSpan={2}>
                            <span
                                className="d-block h-100"
                                role="button"
                                tabIndex={-1}
                                onClick={() => setEditMode('p-val')}
                            >
                                Value
                            </span>
                        </td>
                    )}
                    <td colSpan={2}>
                        <span>Description</span>
                    </td>
                    <td colSpan={1} />
                    <td colSpan={1} />
                </tr>
            </tbody>
        </table>
    );
}

function BodyAreaContent() {
    return (
        <div>
            <div className="line-align mb-4">
                <div className="line-align">
                    <span className="line-align">
                        <input type="radio" name="type" value="none" id="none" />
                    </span>
                    <span className="line-align">none</span>
                </div>
                <div className="line-align ml-2">
                    <span className="line-align">
                        <input type="radio" name="type" value="raw" id="raw" />
                    </span>
                    <span className="line-align">raw</span>
                </div>
                <div className="line-align ml-2">
                    <select name="item" className="form-input w-100 mt-0 common-bg">
                        <option value="json">JSON</option>
                    </select>
                </div>
            </div>
            <div>
                <textarea className="w-100" name="" id="" cols="100" rows="10" />
            </div>
        </div>
    );
}

function ContentItem({ reqItem, setEditMode, editMode }) {
    if (reqItem === 0) {
        return (
            <div>
                <div className="table-head-title mb-2">Questy Params</div>
                <ParamsConent setEditMode={setEditMode} editMode={editMode} />
            </div>
        );
    }
    if (reqItem === 1) {
        return <h2 className="req-title ">Authorization</h2>;
    }
    if (reqItem === 2) {
        return <h2 className="req-title ">Headers</h2>;
    }
    if (reqItem === 3) {
        return (
            <h2 className="req-title ">
                <BodyAreaContent />
            </h2>
        );
    }
    if (reqItem === 4) {
        return <h2 className="req-title ">Pre-request Script</h2>;
    }
    if (reqItem === 5) {
        return <h2 className="req-title ">Tests</h2>;
    }
    if (reqItem === 6) {
        return <h2 className="req-title ">Settings</h2>;
    }
    if (!reqItem) {
        return null;
    }
}

function TopTabContent({ reqItem }) {
    const [editMode, setEditMode] = useState(null);
    return (
        <div className="dt-tab-content border-bottom">
            <ContentItem reqItem={reqItem} setEditMode={setEditMode} editMode={editMode} />
        </div>
    );
}

export default TopTabContent;
