import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import PageProperties from '../../../utils/PageProperties';
import HostingInfoHeader from '../hostings_info_header/HostingInfoHeader';
import TabsSection from '../hostings_info_tabs/TabSection';
import HostingInfoDatabasesProvider, { useHostingDatabase } from './HostingInfoDatabasesProvider';
import DatabasesCpanelSection from './databases_list/DatabaseListSection';

function ContentArea() {
    const { databaseInfo, handelReloadButton } = useHostingDatabase();

    return (
        <>
            <HostingInfoHeader
                path="databases/"
                info={databaseInfo}
                evReloadClick={handelReloadButton}
            />
            <div className="content-body">
                <div className="content-body-scroll">
                    <TabsSection database info={databaseInfo} />
                    <div className={!databaseInfo ? 'placeholder-mode' : ''}>
                        <DatabasesCpanelSection />
                    </div>
                </div>
            </div>
        </>
    );
}

function HostingInfoDatabasesPage() {
    const { contractId } = useParams();
    const { currentUser } = useAuth();

    useEffect(() => {
        PageProperties(`Databases \u2022 Hosting`);
    }, []);

    return (
        <HostingInfoDatabasesProvider currentUser={currentUser} contractId={contractId}>
            <div className="content-area hosting-info-page">
                <ContentArea />
            </div>
        </HostingInfoDatabasesProvider>
    );
}

export default HostingInfoDatabasesPage;
