/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useRef, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { headerIcons } from '../../../configs/Icons';
import { useAuth } from '../../contexts/AuthProvider';
import ServiceMenu from '../../utils/ServiceMenu';
import LoginArea from './components/LoginArea';
import SearchBarArea from './components/SearchBarArea';

const menu = [
    {
        id: 'home',
        title: 'Domain',
        path: '/domain/'
    },
    {
        id: 'services',
        title: 'Services',
        path: '/services/'
    },
    {
        id: 'clients',
        title: 'Clients',
        path: '/clients/'
    },
    {
        id: 'about',
        title: 'About',
        path: '/about-us/'
    }
];

function LogoArea({ navigate }) {
    return (
        <div
            onClick={() => navigate('/')}
            role="button"
            tabIndex={-1}
            className="line-align line-no-wrap "
        >
            <span className="cell-3">
                <LogoIcon navigate={navigate} />
            </span>

            <span className="cell-fill">
                <LogoText navigate={navigate} />
            </span>
        </div>
    );
}

function MenuItem({ item, cPath, show, setShow }) {
    const isServicesPage = cPath?.split('/')[1] === 'services';

    const handleServiceMenu = (e) => {
        if (e === 'services') {
            setShow(true);
        } else {
            setShow(false);
        }
    };

    if (item?.id === 'services') {
        return (
            <li
                key={item.id}
                className={`cursor-pointer ${show || (isServicesPage && 'menu-top-active')}`}
                onClick={() => handleServiceMenu(item.id)}
            >
                <span className={`clr ${show || cPath === '/services/' ? 'active' : ''}`}>
                    {item?.title}
                </span>
            </li>
        );
    }

    return (
        <li key={item.id} className={cPath === item.path ? 'menu-top-active' : ''}>
            <Link to={item?.path}>
                <span>{item?.title}</span>
            </Link>
        </li>
    );
}

function MenuArea() {
    const cPath = window.location.pathname;
    const [show, setShow] = useState(false);
    const ref = useRef();
    return (
        <div className="menu-area-s d-md-none">
            <ul className="menu-top menu-top-area">
                {menu.map((item) => (
                    <MenuItem
                        item={item}
                        key={item.id}
                        cPath={cPath}
                        setShow={setShow}
                        show={show}
                    />
                ))}
            </ul>
            <ServiceMenu show={show} setShow={setShow} myRef={ref} />
        </div>
    );
}

function LogoIcon({ navigate }) {
    return (
        <div
            onClick={() => navigate('/')}
            role="button"
            tabIndex={-1}
            className="line-align line-no-wrap mr-2 w-100"
        >
            <span>
                <img className="logo-img d-block" src={headerIcons.iconBikLogoV1} alt="logo icon" />
            </span>
        </div>
    );
}

function LogoText({ navigate }) {
    return (
        <div
            onClick={() => navigate('/')}
            role="button"
            tabIndex={-1}
            className="line-align line-no-wrap w-100"
        >
            <span>
                <img className="logo-img d-block" src={headerIcons.iconLogoText} alt="logo text" />
            </span>
        </div>
    );
}

function HeaderSection({ searchPage }) {
    const [isSearch, setIsSearch] = useState(false);

    const handleSearchIcon = () => setIsSearch(!isSearch);

    const { currentUser } = useAuth();

    const navigate = useNavigate();

    if (isSearch || searchPage) {
        return (
            <div className="header-section line-align">
                <div className="container ">
                    <div className="line-align line-g3 line-no-wrap">
                        <div className="cell cell-profile-search">
                            <div className="line-align line-no-wrap ">
                                <LogoIcon navigate={navigate} />
                            </div>
                        </div>
                        <div className="cell cell-fill">
                            <SearchBarArea
                                searchPage={searchPage}
                                setIsSearch={setIsSearch}
                                handleSearchIcon={handleSearchIcon}
                            />
                        </div>
                        <div className="cell cell-fill d-md-none">
                            <div className="login-area ">
                                <LoginArea
                                    handleSearchIcon={handleSearchIcon}
                                    currentUser={currentUser}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="header-section line-align">
            <div className="container ">
                <div className="line-align line-no-wrap">
                    <div className="cell">
                        <LogoArea navigate={navigate} />
                    </div>
                    <div className="cell-fill">
                        <MenuArea />
                    </div>
                    <div className="cell-fill">
                        <div className="login-area">
                            <LoginArea
                                handleSearchIcon={handleSearchIcon}
                                currentUser={currentUser}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderSection;
