import { fileSizeDisplay } from '../../../../utils/Show';
import ListActionButton from './ListActionButton';

function TableBody({ arr }) {
    const tdStyle = { maxWidth: '100px' };

    if (!arr?.length) {
        return (
            <tbody>
                <tr>
                    <td colSpan={100}>No Databases Found !</td>
                </tr>
            </tbody>
        );
    }
    return (
        <tbody>
            {arr?.map((data, index) => (
                <tr key={data?.database} className={data?.demo ? 'placeholder-mode' : ''}>
                    <td>{index + 1}</td>
                    <td style={tdStyle}>
                        {' '}
                        <p className="overflow-dotted no-wrap m-0">
                            <span>{data?.database}</span>
                        </p>{' '}
                    </td>
                    <td className="fw-500">
                        {data?.users?.map((user, i) => (
                            <span key={user}>{`${user} ${
                                i !== data?.users?.length - 1 && data?.users?.length > 0
                                    ? ',  '
                                    : ''
                            }`}</span>
                        )) || '--'}
                    </td>

                    <td>
                        {' '}
                        <span>{fileSizeDisplay(data?.disk_usage, ' ')}</span>{' '}
                    </td>

                    <td>
                        <ListActionButton data={data} />
                    </td>
                </tr>
            ))}
        </tbody>
    );
}

function DatabaseListWeb({ arr }) {
    return (
        <table className="table-list cell-sm-12" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th>SL</th>
                    <th width="35%">Database</th>
                    <th width="35%">Privileged Users</th>
                    <th>Size</th>
                    <th width="10%">Action</th>
                </tr>
            </thead>
            <TableBody arr={arr} />
        </table>
    );
}

export default DatabaseListWeb;
