import LayoutSelector from '../../../../utils/LayoutSelector';
import ContactInfoPhoneTableMobile from './ContactInfoPhoneTableMobile';
import ContactInfoPhoneTableWeb from './ContactInfoPhoneTableWeb';

const demoArr = [
    {
        id: 1,
        phoneNumber: '------------',
        providerId: 'google.com',
        isDefault: true,
        demo: true
    }
];

function ContactInfoPhoneTableSection({ info }) {
    const arr = info || demoArr;
    return (
        <LayoutSelector
            sm
            md
            webComp={<ContactInfoPhoneTableWeb arr={arr} />}
            mobileComp={<ContactInfoPhoneTableMobile arr={arr} />}
        />
    );
}

export default ContactInfoPhoneTableSection;
