import {
    FacebookAuthProvider,
    GithubAuthProvider,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup
} from 'firebase/auth';
import { createContext, useContext, useState } from 'react';
import { firebaseAuth } from '../../../MyFirebase';
import AxiosAuth from '../../../utils/AxiosAuth';

const AuthContext = createContext();

export function useRegistration() {
    return useContext(AuthContext);
}

const initialState = {
    type: 'email',
    username: '',
    sentId: '',
    otp: '',
    otpIsVerified: false,
    providerIsVerified: false,
    password1: '',
    password2: '',
    name: '',
    providerId: '',
    userUid: '',
    acceptTc: false
};

// const firebaseFormatter = (user) => {
//     const providerData = user?.providerData || [];
//     if (providerData.length > 0) {
//         return { ...providerData[0], userUid: user.uid };
//     }

//     return null;
// };

const firebaseFormData = (firebase) => ({
    otpIsVerified: !!firebase?.userUid,
    username: firebase?.userUid ? firebase?.email : '',
    name: firebase?.userUid ? firebase?.displayName : '',
    providerIsVerified: !!firebase?.userUid,
    providerId: firebase?.providerId || '',
    userUid: firebase?.userUid || ''
});

function RegistrationProvider({
    children,
    setExitModal,
    firebase,
    recheckLogin,
    verifyProviderRecord,
    makeAction
}) {
    const [formData, setFormData] = useState({
        ...initialState,
        ...firebaseFormData(firebase)
    });

    const handelOtpSend = () =>
        new Promise((resolve, reject) => {
            // setFormData((cVal) => ({ ...cVal, otp: '' }));
            AxiosAuth.setApiUrl('AUTH', '/registration/send-otp/')
                .post(formData)
                .then(({ data }) => {
                    if (data.error === 0) {
                        setFormData((cVal) => ({
                            ...cVal,
                            otp: '',
                            sentId: data.sentId,
                            focusIndex: 0
                        }));
                    }

                    resolve({
                        error: data.error,
                        message: data.message
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    // Verify OTP
    const handelVerifyOtp = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.setApiUrl('AUTH', '/registration/verify-otp/')
                .post(formData)
                .then(({ data }) => {
                    if (data.error === 0) {
                        setFormData((cVal) => ({
                            ...cVal,
                            otpIsVerified: true
                        }));
                    }

                    resolve({
                        error: data.error,
                        message: data.message
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const handleCreateAccount = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.setApiUrl('AUTH', '/registration/create-account/')
                .post(formData)
                .then(({ data }) => {
                    if (data.error === 0) {
                        // --Verify Login
                        signInWithEmailAndPassword(
                            firebaseAuth,
                            formData.username,
                            formData.password1
                        )
                            .then(({ user }) => {
                                verifyProviderRecord(user)
                                    .then(({ error, message, action }) => {
                                        // --Reset Form Data
                                        setFormData(initialState);

                                        // --Control Modal
                                        makeAction(null);

                                        // --Logging In
                                        recheckLogin();
                                        resolve({ error, message, action });
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    } else {
                        resolve({
                            error: data?.error || 1,
                            message: data.message || 'Invalid Data Format'
                        });
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const handleCreateAccProvider = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.setApiUrl('AUTH', '/registration/create-account-by-provider/')
                .post(formData)
                .then(({ data }) => {
                    if (data.error === 0) {
                        // --Reset Form Data
                        setFormData(initialState);

                        // --Close the window
                        setExitModal();

                        // --Logging In
                        recheckLogin();
                    }

                    resolve({
                        error: data.error,
                        message: data.message
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
        provider.addScope('https://www.googleapis.com/auth/userinfo.email');
        provider.addScope('profile');
        provider.addScope('email');
        provider.addScope('openid');

        return new Promise((resolve, reject) => {
            signInWithPopup(firebaseAuth, provider)
                .then(({ user }) => {
                    verifyProviderRecord(user)
                        .then(({ error, message, action }) => {
                            const messages = {
                                createAccount:
                                    'Your data is verified by google.com, please set a password.',
                                linkAccount:
                                    'Your email is already registered. Would you please link your account?'
                            };

                            // --Feedback
                            resolve({ error, message: messages[action] || message });

                            // --Set Action
                            makeAction(action);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    const signInWithFacebook = () => {
        const provider = new FacebookAuthProvider();
        provider.addScope('public_profile');
        provider.addScope('email');

        return new Promise((resolve, reject) => {
            signInWithPopup(firebaseAuth, provider)
                .then(({ user }) => {
                    verifyProviderRecord(user)
                        .then(({ error, message, action }) => {
                            const messages = {
                                createAccount:
                                    'Your data is verified by facebook.com, please set a password.',
                                linkAccount:
                                    'Your email is already registered. Would you please link your account?'
                            };

                            // --Feedback
                            resolve({ error, message: messages[action] || message });

                            // --Set Action
                            makeAction(action);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    const signInWithGithub = () => {
        const provider = new GithubAuthProvider();
        provider.addScope('user:email');

        return new Promise((resolve, reject) => {
            signInWithPopup(firebaseAuth, provider)
                .then(({ user }) => {
                    verifyProviderRecord(user)
                        .then(({ error, message, action }) => {
                            const messages = {
                                createAccount:
                                    'Your data is verified by github.com, please set a password.',
                                linkAccount:
                                    'Your email is already registered. Would you please link your account?'
                            };

                            // --Feedback
                            resolve({ error, message: messages[action] || message });

                            // --Set Action
                            makeAction(action);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    const value = {
        formData,
        setFormData,
        signInWithGoogle,
        signInWithFacebook,
        signInWithGithub,
        handelOtpSend,
        handelVerifyOtp,
        handleCreateAccount,
        handleCreateAccProvider
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default RegistrationProvider;
