/* eslint-disable react/no-array-index-key */
function TeamAvatarRow({ arr }) {
    const length = arr.length > 4 ? arr.length - 4 : '';
    return (
        <div className="line-align position-relative">
            {arr?.slice(0, 4)?.map((data, index) => (
                <span
                    key={index}
                    className={`team-avatar ${arr.length > 4 && index === 3 ? 'length' : ''}`}
                    style={{
                        left: index === 0 ? 0 : -index * 5,
                        content: '5'
                    }}
                >
                    <img className="d-block" src={data?.img} alt="" />
                    {length && index === 3 && <div className="team-length ">{length}</div>}
                </span>
            ))}
        </div>
    );
}

export default TeamAvatarRow;
