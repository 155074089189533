import { userIcons } from '../../../../../../configs/Icons';
import LayoutSelector from '../../../../../utils/LayoutSelector';
import BillingsListMobile from './BillingsListMobile';
import BillingsListWeb from './BillingsListWeb';

const demoArr = [
    {
        uId: '------',
        image: userIcons.iconUserV3,
        name: '-------- ----',
        email: '--------------',
        currency: '---',
        date: '-----------',
        time: '-------',
        status: '-----',
        demo: true
    }
];

const contractInfo = [
    {
        uId: 10000000,
        image: userIcons.iconUserV3,
        name: 'Mariana D. Alonso',
        email: 'bikiran123@gmail.com',
        currency: 'USD',
        date: '30-July-2022',
        time: '10:29:47',
        status: 'Active'
    },
    {
        uId: 20000000,
        image: userIcons.iconUserV3,
        name: 'Chris Evans',
        currency: 'BDT',
        date: '31-July-2022',
        time: '11:29:47',
        status: 'Inactive'
    }
];

function BillingsListSection() {
    const arr = contractInfo || demoArr;

    return (
        <LayoutSelector
            sm
            md
            webComp={<BillingsListWeb arr={arr} />}
            mobileComp={<BillingsListMobile arr={arr} />}
        />
    );
}

export default BillingsListSection;
