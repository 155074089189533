import React from 'react';
import { ButtonClose } from '../../../configs/Buttons';

import animateLoading from '../../../assets/images/icon/animation-1.svg';

function VerificationOtpLoading({ error, message, closeWindow }) {
    const handleCloseClick = () => {
        closeWindow();
    };

    return (
        <div className="modal-box backdrop remove-wizard-area">
            <div className="modal-box-content " style={{ height: '361px' }}>
                <div className="modal-box-header border-bottom">
                    <div>
                        <div className="modal-box-title">Verify Email</div>
                        <div className="modal-box-close">
                            <ButtonClose evOnClick={handleCloseClick} />
                        </div>
                    </div>
                </div>

                <div className={`modal-body-area h-100 ${error ? 'error' : null}`}>
                    <div className="line-col-center h-100">
                        {error ? message : <img src={animateLoading} alt="Loading..." />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VerificationOtpLoading;
