import { icons, userIcons } from '../../../../configs/Icons';

const roleIconPicker = (data) => {
    const shared = data?.roleIcon === 'shared';
    const developer = data?.roleIcon === 'developer';
    const manager = data?.roleIcon === 'manager';
    const support = data?.roleIcon === 'support';
    // console.log(data);

    if (!data) {
        return null;
    }
    if (shared) {
        return (
            <span className="mr-1 tooltip">
                <img className="d-block" src={userIcons.iconUserV9} alt="shared" />
                <span className="tooltip-text after-none">Shared</span>
            </span>
        );
    }
    if (developer) {
        return (
            <span className="mr-1 tooltip">
                <img className="d-block" src={icons.iconD} alt="developer" />
                <span className="tooltip-text after-none">Developer</span>
            </span>
        );
    }
    if (manager) {
        return (
            <span className="mr-1 tooltip">
                <img className="d-block" src={icons.iconM} alt="manager" />
                <span className="tooltip-text after-none">Manager</span>
            </span>
        );
    }
    if (support) {
        return (
            <span className="mr-1 tooltip">
                <img className="d-block" src={icons.iconS} alt="support" />
                <span className="tooltip-text after-none">Support</span>
            </span>
        );
    }
    return null;
};

export default roleIconPicker;
