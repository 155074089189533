function ProfileInfoPersonalPhone({ info }) {
    return (
        <div className="line-align">
            <div className="cell cell-5">
                <span className="title common-value fw-500">Phone Number:</span>
            </div>
            <div className="cell-7">
                <span className="value common-value fw-400">
                    {info?.phoneNumber || info?.phone || '--'}
                </span>
            </div>
        </div>
    );
}

export default ProfileInfoPersonalPhone;
