import { useEffect, useRef, useState } from 'react';

import { Link, useLocation, useParams } from 'react-router-dom';
import { icons } from '../../../../../configs/Icons';

function TabMenuPopup({ show, menuArr, setMode, optionKey, lastTabItem, lastTabUrl }) {
    const [searchText, setSearchText] = useState({ text: '' });
    const ref = useRef();
    const { contractId } = useParams();

    const { pathname } = useLocation();
    const isHosting = pathname.includes('hosting');

    const handelSearch = (ev) => {
        setSearchText((cuValue) => ({ ...cuValue, text: ev.target.value }));
    };

    const arr = searchText?.text?.length
        ? menuArr?.filter((el) =>
              el[optionKey || 'title'].toLowerCase().includes(searchText.text?.toLowerCase())
          )
        : menuArr?.slice(0, 10);

    useEffect(() => {
        function handelClickOutside(event) {
            if (ref?.current && !ref?.current.contains(event?.target)) {
                // alert("You clicked outside of me!");
                setMode(null);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handelClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handelClickOutside);
        };
    }, [ref, setMode]);

    if (!show) {
        return null;
    }
    if (menuArr?.length > 7) {
        return (
            <div className="menu-option-popup" ref={ref}>
                <img className="shape" src={icons.iconShape} alt="shape" />
                <div className="option">
                    <div style={{ padding: '0' }}>
                        <input
                            onChange={handelSearch}
                            type="text"
                            className="form-input w-100"
                            placeholder="search..."
                        />
                    </div>
                    {arr?.map((data) => {
                        const isActive = pathname.includes(data?.url);
                        const isItem = lastTabItem && data?.url === lastTabUrl;
                        if (pathname.includes(data?.url) || isItem) {
                            return null;
                        }
                        return (
                            <div
                                className={`${data?.cName} ${isActive ? ' active' : ''}`}
                                key={data?.title}
                            >
                                <Link
                                    className="line-align line-no-wrap"
                                    to={`/user/services/${
                                        isHosting ? 'hosting' : 'domain'
                                    }/${contractId}/${data?.url}/`}
                                >
                                    <span className="mr-2">
                                        <img className="d-block" src={data?.img} alt="" />
                                    </span>
                                    <span className="no-wrap">{data?.title}</span>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
    return (
        <div className="menu-option-popup" ref={ref}>
            <img className="shape" src={icons.iconShape} alt="shape" />
            <div className="option">
                {arr?.slice(0, 10)?.map((data) => {
                    const isActive = pathname.includes(data?.url);
                    const isItem = lastTabItem && data?.url === lastTabUrl;
                    if (pathname.includes(data?.url) || isItem) {
                        return null;
                    }
                    return (
                        <div
                            className={`${data?.cName} ${isActive ? ' active' : ''}`}
                            key={data?.title}
                        >
                            <Link
                                className="line-align line-no-wrap"
                                to={`/user/services/${
                                    isHosting ? 'hosting' : 'domain'
                                }/${contractId}/${data?.url}/`}
                            >
                                <span className="mr-2">
                                    <img
                                        className="d-block"
                                        src={isActive ? data?.imgActive : data?.img}
                                        alt=""
                                    />
                                </span>
                                <span className="no-wrap">{data?.title}</span>
                            </Link>
                        </div>
                    );
                })}
            </div>
            {/* <div className="close" role="button" tabIndex={-1} onClick={evOnClick}>
                <img className="d-block w-100" src={icons.iconDel1} alt="" />
            </div> */}
        </div>
    );
}

export default TabMenuPopup;
