import { useEffect } from 'react';
import { useAuth } from '../../contexts/AuthProvider';

function ILoginPage() {
    const { currentUser } = useAuth();

    useEffect(() => {
        if (currentUser.loading === false) {
            if (currentUser.userUid && currentUser.refreshToken) {
                window.parent.postMessage(
                    {
                        userUid: currentUser.userUid,
                        refreshToken: currentUser.refreshToken,
                        currentUser: {
                            name: currentUser.displayName,
                            username: currentUser.dob,
                            gender: currentUser.gender,
                            genderTxt: '',
                            dob: currentUser.dob,
                            dobText: '',
                            phone: currentUser.phone,
                            email: currentUser.email,
                            photoUrl: currentUser.photoUrl
                        },
                        error: 0,
                        message: 'OK'
                    },
                    'http://localhost:3000/'
                );
            } else {
                window.parent.postMessage(
                    {
                        userUid: '',
                        refreshToken: '',
                        currentUser: {
                            name: '',
                            username: 0,
                            gender: '',
                            genderTxt: '',
                            dob: 0,
                            dobText: '',
                            phone: '',
                            email: '',
                            photoUrl: ''
                        },
                        error: 1,
                        message: 'Auth Failed'
                    },
                    'http://localhost:3000/'
                );
            }
        }
    }, [currentUser]);

    return null;
}

export default ILoginPage;
