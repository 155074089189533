import { useState } from 'react';
import { ButtonActionEdit } from '../../../../../configs/Buttons';
import CopyText from '../../../websites/models/CopyText';
import BasicInfoEmailPopup from './BasicInfoEmailPopup';

function EmailView({ evOnClick, value, cpEmailChange }) {
    // const { setCopiedText, handelCopy, copiedText } = useTemplate();
    return (
        <div className="info-edit-area">
            <div className="line-row-between line-no-wrap">
                <div className="cell cell-10">
                    <CopyText value={value || '---------------------'} cName="value" />
                </div>
                <div hidden={!cpEmailChange} className="cell">
                    <div className="action-btn ml-2">
                        <ButtonActionEdit evOnClick={evOnClick} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function BasicInfoEmail({ basicInfo }) {
    const [modal, setModal] = useState(false);
    const { cpEmailChange } = basicInfo?.permissions || {};
    return (
        <>
            <div className="line-align mb-2 ">
                <div className="cell cell-5">
                    <span className="subject">Email:</span>
                </div>
                <div className="cell cell-7 ">
                    <EmailView
                        value={basicInfo?.email}
                        evOnClick={() => setModal(!modal)}
                        cpEmailChange={cpEmailChange}
                    />
                </div>
            </div>
            <BasicInfoEmailPopup show={modal} data={basicInfo} setModal={setModal} />
        </>
    );
}

export default BasicInfoEmail;
