import React, { useEffect, useRef, useState } from 'react';

function SelectedOption({ toggling, isOpen, selectedOption, defaultOption, searchInput }) {
    const cName = isOpen ? 'active' : 'inactive';
    if (searchInput && isOpen) {
        return null;
    }
    return (
        <div
            role="button"
            tabIndex={-1}
            style={{ cursor: 'default' }}
            onClick={toggling}
            className={`dropdown-btn ${cName}`}
        >
            {selectedOption || defaultOption}
        </div>
    );
}

function Options({
    handleSelectedOption,
    isOpen,
    arr,
    property,
    selectedOption,
    defaultOption,
    searchInput,
    setSearchText,
    searchText
}) {
    const optionDiv = {
        top: searchInput ? '0px' : '50px',
        paddingBottom: '30px'
    };

    return (
        <div className={`dropdown-options w-100 ${isOpen ? 'active' : ''}`} style={optionDiv}>
            <div hidden={!searchInput || !isOpen} className="option-item search-field">
                <input
                    onChange={(e) => setSearchText(e.target.value)}
                    value={searchText || ''}
                    type="text"
                    className="form-input dropdown-search-input w-100"
                    placeholder="Search...."
                />
            </div>
            {arr.map((item) => {
                const optionItem = property ? item[property] : item;

                const isSelected =
                    selectedOption?.toLowerCase() === optionItem?.toLowerCase() ||
                    defaultOption?.toLowerCase() === optionItem?.toLowerCase();

                return (
                    <div
                        key={Math.random()}
                        role="button"
                        tabIndex={-1}
                        onClick={() => handleSelectedOption(optionItem)}
                        className={`option-item ${isSelected ? 'purple' : ''}`}
                    >
                        <span className="no-wrap">{optionItem} </span>{' '}
                        {isSelected && <span>✓</span>}
                    </div>
                );
            })}
        </div>
    );
}

// need to pass state = (selectedOption, setSelectedOption) and array of options
// selectedOption state will store the value of clicked option
function DropdownMenu({
    defaultOption = 'Select',
    selectedOption,
    setSelectedOption,
    options,
    property,
    searchInput = false,
    variant = '',
    width,
    height
}) {
    const [searchText, setSearchText] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const myRef = useRef();

    // search input and option open
    const isSearchInput = isOpen && searchInput;

    // option open/close
    const toggling = () => setIsOpen(!isOpen);

    // get value and close options
    const handleSelectedOption = (value) => {
        setSelectedOption(value);
        setIsOpen(false);
        setSearchText('');
    };

    // close options when click outside

    useEffect(() => {
        function handelClickOutside(event) {
            if (myRef?.current && !myRef?.current.contains(event?.target)) {
                // alert("You clicked outside of me!");
                setIsOpen(false);
                setSearchText('');
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handelClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handelClickOutside);
        };
    }, []);

    const parentDiv =
        variant === 'border'
            ? {
                  border: isSearchInput ? '1px solid transparent' : '1px solid #130f4033',
                  borderBottom: isSearchInput
                      ? '1px solid rgba(19, 15, 64, 0.2)'
                      : '1px solid #130f4033',
                  borderRadius: '8px',
                  width: width || '150px',
                  height: height || '40px'
              }
            : { width: width || '150px' };

    const arr =
        searchText.length > 0 && searchInput && isOpen
            ? options?.filter((data) =>
                  property
                      ? data[property]?.toLowerCase()?.includes(searchText?.toLowerCase())
                      : data?.toLowerCase()?.includes(searchText?.toLowerCase())
              )
            : options;

    return (
        <div className="dropdown-area" ref={myRef} style={parentDiv}>
            <SelectedOption
                isOpen={isOpen}
                toggling={toggling}
                selectedOption={selectedOption}
                defaultOption={defaultOption}
                searchInput={searchInput}
            />

            <Options
                searchInput={searchInput}
                handleSelectedOption={handleSelectedOption}
                isOpen={isOpen}
                options={options}
                defaultOption={defaultOption}
                variant={variant}
                property={property}
                selectedOption={selectedOption}
                arr={arr}
                setSearchText={setSearchText}
                searchText={searchText}
            />
        </div>
    );
}

export default DropdownMenu;
