import { useState } from 'react';
import { ButtonGr } from '../../../../../configs/Buttons';
import PointsConverterModal from '../modals/PointsConverterModal';

function PointsConverterSection({ points }) {
    const [openModal, setOpenModal] = useState(null);

    const show = points > 0;

    return (
        <div className="converter-area line-row-between mb-4">
            <div className="cell-fill ">
                <div className={`left-content ${!show && 'text-center'}`}>
                    <p className="font-14 mb-0">Points Value:</p>
                    <p className="mt-1" style={{ color: '#F50303' }}>
                        10 BIKIRAN Points = 1 Taka
                    </p>
                </div>
            </div>
            {show && (
                <div className="cell-fill">
                    <div className="right-content">
                        <div>
                            <ButtonGr
                                evOnClick={() => setOpenModal(true)}
                                title="Cash points in Bikiran Balance"
                            />
                        </div>
                        {openModal ? (
                            <PointsConverterModal
                                points={points}
                                setOpenModal={setOpenModal}
                                openModal={openModal}
                            />
                        ) : null}
                    </div>
                </div>
            )}
        </div>
    );
}

export default PointsConverterSection;
