export const api =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_DEMO_URL
        : process.env.REACT_APP_API_URL;

const ConfigApi = {
    APP_INIT: `/init/`,

    // Auth
    AUTH: `/auth/`,
    VERIFICATIONS: `/verifications/`,

    // Search
    SEARCH: '/search/',

    // Cart & Invoices
    CART: `/cart/`,

    // User Accounts
    PROFILE: '/profiles/',
    API_ADDRESS: `${api}/accounts/address/update-address/`,

    // Payment Gateway
    PAYMENT_BKASH: `/payments/bkash-payment/`,
    PAYMENT_SSLCOM: `/payments/sslcom-payment/`,

    // Billing
    API_BILLING_INVOICE: `/invoice/`,

    // Contracts
    API_DOMAIN_LIST_DOMAINS: `${api}/contracts/domain/list/`,

    API_DOMAIN_DATA_INFO: `${api}/web-info/data/`,

    // Contracts
    // :type/, :type/:contractId/execute/, :type/:contractId/cp-login/
    CONTRACT: `/contracts/`,
    CONTRACT_ROLE: `/contracts/role/`,
    CONTRACT_HOSTING: `/contracts/hosting/`,
    CONTRACT_DOMAIN: `/contracts/domain/`
};

export const OTP = {
    ACC_CREATE: 'account_create_otp',
    ACC_LINK: 'account_link_otp',
    CONTACT_ADD: 'contact_add_otp',
    CP_DOMAIN_CHANGE: 'cpanel_domain_change_otp',
    CP_REMOVE: 'cpanel_remove_otp',
    CP_SUSPEND: 'cpanel_suspend_otp',
    CP_UNSUSPEND: 'cpanel_unsuspend_otp',
    PASS_RESET: 'password_reset_otp',
    DOMAIN_UNLOCK: 'domain_unlock_otp'
};

export default ConfigApi;
