/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

function RadioButton({ checked, blue }) {
    return (
        <div className={`input-radio ${blue ? 'blue-variant' : ''}`}>
            <input type="radio" checked={checked} readOnly />
            <label htmlFor="test1" />
        </div>
    );
}

export default RadioButton;
