/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { hostingIcons } from '../../../../configs/Icons';
import { useInit } from '../../../contexts/InitProvider';

function HostingTabs({ index }) {
    const navigate = useNavigate();
    const tabs = [
        {
            id: 1,
            position: 0,
            title: 'App Hosting',
            path: 'app/',
            img: hostingIcons.iconAppH
        },
        {
            id: 2,
            position: 1,
            title: 'Web Hosting',
            path: 'web/',
            img: hostingIcons.iconWebHV1
        },
        {
            id: 3,
            position: 2,
            title: 'Email Hosting',
            path: 'email/',
            img: hostingIcons.iconEmailH
        }
    ];

    return (
        <div className="line-row-center line-no-wrap mb-7 tabs-head-container overflow-auto">
            {tabs?.map((item) => (
                <div
                    key={item?.id}
                    role="button"
                    tabIndex={-1}
                    onClick={() => navigate(`/services/hosting/${item?.path}`)}
                    className={`tab-menu cell-md-5 cell-sm-6 ${
                        index !== item?.position ? null : 'tab-menu-active'
                    }`}
                >
                    <div
                        className={`tab-icon ${
                            index !== item?.position ? null : 'tab-icon-active'
                        }`}
                    >
                        <img className="w-100" src={item?.img} alt="tab icon" />
                    </div>
                    <div className="fs-16 fw-500">{item?.title}</div>
                </div>
            ))}
        </div>
    );
}

function DurationOptions() {
    const { handelChangeIsYearly, isYearly } = useInit();

    const handleDurationClick = (type) => {
        if (type !== 'month') {
            handelChangeIsYearly(true);
        } else {
            handelChangeIsYearly(false);
        }
    };

    return (
        <ul>
            <li
                className={!isYearly ? 'tabs-currency-active' : null}
                onClick={() => handleDurationClick('month')}
            >
                Monthly
            </li>
            <li
                className={isYearly ? 'tabs-currency-active' : null}
                onClick={() => handleDurationClick('year')}
            >
                Yearly
            </li>
        </ul>
    );
}

function Options({ currencies, selectItem, handleCurrencyChange, locale }) {
    return (
        <div className={`currency-options-container ${selectItem?.mode ? 'active' : ''}`}>
            {currencies?.map((item) => {
                if (item?.currency === locale?.currency) {
                    return (
                        <div
                            key={item?.currency}
                            role="button"
                            tabIndex={-1}
                            onClick={() => handleCurrencyChange(item)}
                            className="option active"
                        >
                            <span className="currency-name">{item?.currency}</span> <span>✓</span>
                        </div>
                    );
                }

                return (
                    <div
                        key={item?.currency}
                        role="button"
                        tabIndex={-1}
                        onClick={() => handleCurrencyChange(item)}
                        className="option"
                    >
                        <span className="currency-name">{item?.currency}</span>{' '}
                        <span>{item?.currency === 'USD' ? '$' : '৳'}</span>
                    </div>
                );
            })}
        </div>
    );
}

function CurrencyDropdown({ myRef, setSelectItem, selectItem }) {
    const { currencies, locale, handelChangeLocale } = useInit();
    const handleCurrencyChange = (item) => {
        handelChangeLocale(locale?.country, item?.currency, locale?.language);
        setSelectItem({ mode: false });
    };

    return (
        <div className="tabs-currency-area" ref={myRef}>
            <div
                role="button"
                tabIndex={-1}
                onClick={() => setSelectItem({ mode: !selectItem?.mode })}
                className={`currency-view ${selectItem?.mode ? 'active' : 'inactive'}`}
            >
                {locale?.currency}
            </div>
            <Options
                currencies={currencies}
                selectItem={selectItem}
                handleCurrencyChange={handleCurrencyChange}
                locale={locale}
            />
        </div>
    );
}

function TabsSection() {
    const [index, setIndex] = useState(0);
    const [selectItem, setSelectItem] = useState({ mode: false });

    const myRef = useRef();

    const path = useLocation();
    const { pathname } = path;
    const currentPath = pathname?.split('/')[pathname?.split('/').length - 2];

    useEffect(() => {
        function handelClickOutside(event) {
            if (myRef?.current && !myRef?.current.contains(event?.target)) {
                // alert("You clicked outside of me!");
                setSelectItem({ mode: false });
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handelClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handelClickOutside);
        };
    }, [myRef, selectItem.mode, selectItem.option, setSelectItem]);

    useEffect(() => {
        if (currentPath === 'app') {
            setIndex(0);
        }
        if (currentPath === 'web') {
            setIndex(1);
        }
        if (currentPath === 'email') {
            setIndex(2);
        }
    }, [currentPath]);

    return (
        <div className="tabs-bg common-content-section mb-120">
            <div className="container mt-120">
                <div className="tabs-container">
                    <h2 className="tabs-title">
                        Choose Your Hosting Plan & Enjoy Our Great Services
                    </h2>
                    <div className="tabs-currency">
                        <DurationOptions />

                        <CurrencyDropdown
                            myRef={myRef}
                            setSelectItem={setSelectItem}
                            selectItem={selectItem}
                        />
                    </div>

                    <HostingTabs index={index} setIndex={setIndex} />

                    <Outlet />
                </div>
            </div>
        </div>
    );
}
export default TabsSection;
