import { createContext, useContext, useEffect, useLayoutEffect, useState } from 'react';

const LayoutContext = createContext();

export function useLayout() {
    return useContext(LayoutContext);
}

const sizeList = [
    320, // 100% - max:425px - xs
    576, // 540px  - sm
    768, // 720px - md
    992, // 960px  - lg
    1200, // 1140px - xl
    1400, // 1340px - xxl
    1600, // 1540px - 3xl
    2560 // 1540px - 4xl
];

const getSizeName = (width) => {
    if (width < 320) {
        return 'xs';
    }

    if (width < 576) {
        return 'sm';
    }

    if (width < 768) {
        return 'md';
    }

    if (width < 992) {
        return 'lg';
    }

    if (width < 1200) {
        return 'xl';
    }

    if (width < 1400) {
        return 'xxl';
    }

    if (width < 1600) {
        return '3xl';
    }

    if (width < 2560) {
        return '4xl';
    }

    return '5xl';
};

function LayoutProvider({ children }) {
    const [data, setData] = useState({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        documentHeight: document.body.scrollHeight,
        documentWidth: document.body.scrollWeight
    });
    const windowType = data.windowWidth > data.windowHeight ? 'landscape' : 'portrait';
    const windowSizeName = getSizeName(data.windowWidth);

    useLayoutEffect(() => {
        function updateSize() {
            setData({
                windowWidth: window.innerWidth,
                windowHeight: window.innerHeight,
                documentHeight: document.body.scrollHeight,
                documentWidth: document.body.scrollWeight
            });
        }

        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, [windowSizeName, windowType]);

    useEffect(() => {
        document.body.className = '';

        document.body.classList.add(windowType);
        document.body.classList.add(windowSizeName);
    }, [windowSizeName, windowType]);

    const value = {
        windowWidth: data.windowWidth,
        windowHeight: data.windowHeight,
        windowType,
        windowSizeName,
        documentWidth: data.documentWidth,
        documentHeight: data.documentHeight,
        sizeList,
        bodyClassNames: [windowType, windowSizeName]
    };

    return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
}

export default LayoutProvider;
