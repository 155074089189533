/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';
import {
    ButtonActionDetails,
    ButtonActionReset,
    ButtonSolidPink
} from '../../../../../configs/Buttons';
import LayoutSelector from '../../../../utils/LayoutSelector';

function BtnRenew({ evOnClick }) {
    return (
        <LayoutSelector
            sm
            md
            webComp={<ButtonSolidPink evOnClick={evOnClick} title="Renew" />}
            mobileComp={<ButtonActionReset evOnClick={evOnClick} />}
        />
    );
}

function ButtonMobileView({ setModal }) {
    const handelButtonClick = (ev) => {
        ev.stopPropagation();
        setModal({ type: 'message' });
    };
    return (
        <span className="button-action-sm">
            <ButtonSolidPink evOnClick={handelButtonClick} title="Renew" />
        </span>
    );
}

function ButtonWebView({ id, setModal }) {
    return (
        <div className="line-align line-no-wrap">
            <Link to={`/user/services/domain/${id}/`} className="mr-1 btn-action-web">
                <ButtonActionDetails />
            </Link>
            <Link
                role="button"
                tabIndex={-1}
                onClick={() => setModal({ type: 'message' })}
                className="full-width"
                style={{ maxWidth: '55px' }}
            >
                <BtnRenew />
            </Link>
        </div>
    );
}

function TableActionButton({ item, setModal }) {
    // const navigate = useNavigate();
    const { id } = item || {};
    // const cName = isMobile ? 'btn-action' : 'btn-action-web';

    return (
        <>
            <LayoutSelector
                sm
                md
                webComp={<ButtonWebView id={id} setModal={setModal} item={item} />}
                mobileComp={<ButtonMobileView id={id} setModal={setModal} item={item} />}
            />
        </>
    );
}

export default TableActionButton;
