import { createContext, useContext, useState } from 'react';

const OtpContext = createContext();

export function useOtpInput() {
    return useContext(OtpContext);
}

function OtpInputProvider({ children, otp, setOtp, handelOtpSend }) {
    const otpBlankAr = [' ', ' ', ' ', ' ', ' ', ' '];
    const [focusIndex, setFocusIndex] = useState(0);

    const handelOtpSet = (i, val) => {
        setOtp((cOtp) => {
            const otpAr = [...otpBlankAr];
            cOtp.split('').forEach((el, x) => {
                otpAr[x] = el;
            });
            otpAr[i] = val.replace(/[^0-9]/g, '') || ' ';

            return otpAr.join('').trimEnd().substring(0, otpBlankAr.length);
        });
    };

    // Private
    const getFocusIndex = (cuIndex, index, value, keyCode) => {
        let nVal = value ? cuIndex + value.length : cuIndex;
        if (keyCode === 8) {
            nVal -= 1;
        }

        if (nVal < 1) {
            return 0;
        }

        if (nVal > otpBlankAr.length - 2) {
            return otpBlankAr.length - 1;
        }

        return nVal;
    };

    const handelOtpKeyUp = (i, val, keyCode) => {
        setFocusIndex((cFocusIndex) => getFocusIndex(cFocusIndex, i, val, keyCode));
    };

    const handelOtpFocus = (i) => {
        setFocusIndex(i);
    };

    const value = {
        otp,
        setOtp,
        focusIndex,
        setFocusIndex,
        otpBlankAr,
        handelOtpSet,
        handelOtpKeyUp,
        handelOtpFocus,
        handelOtpSend
    };

    return <OtpContext.Provider value={value}>{children}</OtpContext.Provider>;
}

export default OtpInputProvider;
