import { Link } from 'react-router-dom';

import { ButtonGr } from '../../../../configs/Buttons';
import { basic } from '../../../../configs/Images';

function LinkBrokenPage() {
    return (
        <div className="error-page-area">
            <div className="container h-100">
                <div className="content-container">
                    <div className="">
                        <div className="mb-4">
                            <img className="d-block" src={basic.oppsText} alt="text" />
                        </div>
                        <div className="mb-4">
                            <span className="content-text">
                                The link you are using to reach this page is Broken!!
                            </span>
                        </div>
                        <Link to="/">
                            <ButtonGr title="Back to Home" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LinkBrokenPage;
