import CartItemListActionButton from './CartItemListActionButton';

function TableBody({ arr }) {
    if (!arr?.length) {
        return (
            <tbody>
                <tr>
                    <td colSpan={100}>No Cart Item Found !</td>
                </tr>
            </tbody>
        );
    }
    return (
        <tbody>
            {arr?.map((data) => (
                <tr key={data?.uId} className={data?.demo ? 'placeholder-mode' : ''}>
                    <td colSpan={2}>
                        <span>{data?.uId}</span>
                    </td>
                    <td colSpan={2} className="user-avatar">
                        <span className="d-block">
                            <img className="d-block" src={data?.image} alt="" />
                        </span>
                    </td>
                    <td colSpan={3}>
                        <span>{data?.name}</span> <br />
                        <span className="purple font-12">{data?.email}</span>
                    </td>
                    <td colSpan={3}>
                        <span>{data?.type}</span> <br />{' '}
                        <span className="font-12">{data?.subType}</span>
                    </td>
                    <td colSpan={1}>
                        <span>${data?.price}</span> <br />{' '}
                        <span
                            className="table-sub-text-op"
                            style={{ textDecoration: 'line-through' }}
                        >
                            {data?.prevPrice}
                        </span>{' '}
                    </td>
                    <td colSpan={1}>
                        <span>{data?.mode}</span>
                    </td>
                    <td colSpan={2}>
                        <CartItemListActionButton />
                    </td>
                </tr>
            ))}
        </tbody>
    );
}

function CartItemListWeb({ arr }) {
    return (
        <table className="table-list cell-sm-12" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th colSpan={2}>User ID</th>
                    <th colSpan={2}>Image</th>
                    <th colSpan={3}>Name</th>
                    <th colSpan={3}>Types</th>
                    <th colSpan={1}>Price</th>
                    <th colSpan={1}>Mode</th>
                    <th width="18%" colSpan={2}>
                        Action
                    </th>
                </tr>
            </thead>
            <TableBody arr={arr} />
        </table>
    );
}

export default CartItemListWeb;
