import { useLocation } from 'react-router-dom';
import { icons } from '../../configs/Icons';
import PurchaseDomainModal from '../../projects/app/websites/modals/PurchaseDomainModal';
import { ShowFormattedPrice } from '../../projects/app/websites/models/Pricing';
import CurrencyDropdownMenu from '../../projects/utils/CurrencyDropdownMenu';

function DomainNameArea({ productInfo }) {
    return (
        <div className="line-align gap">
            <div className="domain-text-area">
                <div className="domain-text break-all">
                    {' '}
                    <span>
                        {' '}
                        {productInfo?.property?.domain?.toUpperCase() || '-----------------------'}
                    </span>
                </div>
            </div>
        </div>
    );
}

function DomainPricingArea({ productInfo, setPurchaseData }) {
    return (
        <div className="line-row-between line-no-wrap domain-pricing-area">
            <div className="cell-fill">
                <div className="text-end pricing-text">
                    <div className="no-wrap price">
                        <span className="line-align line-no-wrap pricing-area gap">
                            <span className="mr-2">
                                {ShowFormattedPrice((productInfo?.price?.registration || 0) * 12)}
                                /yr
                            </span>
                            <CurrencyDropdownMenu>
                                <span className="w-100 h-100">
                                    <img
                                        className="w-100 h-100 d-block"
                                        src={icons.iconCurrencyExchange}
                                        alt="currency change icon"
                                    />
                                </span>
                            </CurrencyDropdownMenu>
                        </span>
                    </div>
                    <div className="no-wrap renew-price">
                        <span>
                            Renew {ShowFormattedPrice((productInfo?.price?.renew || 0) * 12)}/yr
                        </span>
                    </div>
                </div>
            </div>
            <div className="cell">
                <span className="">
                    <div
                        role="button"
                        tabIndex={-1}
                        onClick={() => setPurchaseData(productInfo)}
                        className="domain-cart-button line-align justify-end span-img"
                    >
                        <span className="">Add to Cart</span>
                    </div>
                </span>
            </div>
        </div>
    );
}

function DomainRegAvailableLg({ productInfo, setPurchaseData, purchaseData }) {
    const { search } = useLocation();
    const searchObj = new URLSearchParams(search);
    const searchText = searchObj.get('q');
    const isSearchMatched =
        productInfo?.property?.domain?.toLowerCase() === searchText?.toLowerCase();
    return (
        <div className={`container ${!productInfo ? 'placeholder-mode' : ''}`}>
            <div className="domain-reg-av-area">
                {isSearchMatched ? (
                    <div
                        style={{ visibiliy: !isSearchMatched ? 'hidden' : 'visible' }}
                        className="line-align gap"
                    >
                        <span className="matched-text">Exact Match</span>
                    </div>
                ) : null}
                <DomainNameArea productInfo={productInfo} />
                <DomainPricingArea productInfo={productInfo} setPurchaseData={setPurchaseData} />
            </div>
            {purchaseData ? (
                <PurchaseDomainModal
                    productInfo={purchaseData || null}
                    setPurchaseData={setPurchaseData}
                />
            ) : null}
        </div>
    );
}

export default DomainRegAvailableLg;
