export function InpTC({ value, setValue, url }) {
    return (
        <label htmlFor="acceptTc">
            <input type="checkbox" checked={value} onChange={setValue} id="acceptTc" /> Accept{' '}
            <a href={url} target="_blank" rel="noreferrer">
                Terms & Condition
            </a>
        </label>
    );
}

function CheckInputs() {
    return null;
}

export default CheckInputs;
