import { useAuth } from '../../../../contexts/AuthProvider';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';
import { GetDate } from '../../../../utils/Show';
import { showUnitDuration } from '../../../websites/models/Pricing';

const DomainRow = ({ item, createContract }) => (
    <tr>
        <td className="details">
            <b>{item?.title}</b>
            <p
                style={{ margin: 2 }}
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{
                    __html: item?.description
                }}
            />
            Validity:
            <span>
                {GetDate(item?.subscriptionStart)} to {GetDate(item?.subscriptionEnd)}
            </span>
            <br />
            {item?.contract_status === 'not-created' ? (
                <span
                    className="button-create-contract"
                    onClick={createContract}
                    role="button"
                    tabIndex={-1}
                >
                    Click here to create contract
                </span>
            ) : null}
        </td>
        <td className="price">${(item?.price * 12).toFixed(2)}/Year</td>
        <td className="unit">{item?.duration / 12} Year</td>
        <td className="value">${(item?.price * item?.duration).toFixed(2)}</td>
    </tr>
);

const HostingRow = ({ item, createContract }) => (
    <tr>
        <td className="details">
            <b>{item?.title}</b>
            <p
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{
                    __html: item?.description
                }}
            />
            Validity:
            <span>
                {GetDate(item?.subscriptionStart)} to {GetDate(item?.subscriptionEnd)}
            </span>
            <br />
            {item?.contract_status === 'not-created' ? (
                <span
                    className="button-create-contract"
                    onClick={createContract}
                    role="button"
                    tabIndex={-1}
                >
                    Click here to create contract
                </span>
            ) : null}
        </td>
        <td className="price">{(item?.price * 12).toFixed(2)}/Year</td>
        <td className="unit">{showUnitDuration(item?.duration)}</td>
        <td className="value">{(item?.price * item?.duration).toFixed(2)}</td>
    </tr>
);

const ItemRow = ({ invoiceId, item, setInvoiceData }) => {
    const { currentUser } = useAuth();
    const { setMessage } = useTemplate();

    const createContract = () => {
        setMessage('Loading...');
        AxiosAuth.currentUserAuth(currentUser)
            .setApiUrl('API_BILLING_INVOICE', `create-contract/${invoiceId}/`)
            .post()
            .then(({ data }) => {
                setMessage(data.message);

                if (data?.error === 0) {
                    setInvoiceData(data.invoiceData);
                }
            });
    };

    return item?.title?.split(' ')[item?.title?.split(' ')?.length - 1] === 'Domain' ? (
        <DomainRow item={item} createContract={createContract} />
    ) : (
        <HostingRow item={item} createContract={createContract} />
    );
};

function InvoiceInfoTableWeb({ invoiceData, setInvoiceData, items }) {
    return (
        <table className="invoice-products" cellSpacing={0}>
            <thead>
                <tr>
                    <th className="details">Item</th>
                    <th>Price ({invoiceData?.data?.currency})</th>
                    <th className="unit">Unit</th>
                    <th className="value">Total ({invoiceData?.data?.currency})</th>
                </tr>
            </thead>

            <tbody>
                {items?.map((item) => (
                    <ItemRow
                        invoiceId={invoiceData?.data?.id}
                        item={item}
                        setInvoiceData={setInvoiceData}
                        key={item.id}
                    />
                ))}
            </tbody>
        </table>
    );
}

export default InvoiceInfoTableWeb;
