import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BtnProfileManage } from '../../../../../configs/Buttons';
import { useBasicInfo } from '../UserBasicInfoProvider';

function ProfileAddress({ currentUser }) {
    const navigate = useNavigate();
    const { mode } = useBasicInfo();

    const handelOnClick = () => {
        navigate('/user/addresses/');
    };

    return (
        <div className="line default-view-area border-bottom-none line-no-wrap">
            <div className="cell cell-3 cell-md-4  od-opacity">
                <span className="profile-subject mt-5">Address:</span>
            </div>
            <div className="cell-fill  od-opacity">
                <span className="value break-all">
                    {[
                        currentUser?.address?.line1,
                        currentUser?.address?.line2,
                        currentUser?.address?.line3,
                        currentUser?.address?.city,
                        currentUser?.address?.state,
                        currentUser?.address?.zip,
                        currentUser?.address?.country
                    ]
                        .filter((item) => !!item)
                        .join(', ') || '--'}
                </span>
            </div>
            <div className="cell-2 od-opacity">
                <span className="line justify-end ">
                    <BtnProfileManage evOnClick={handelOnClick} isDisabled={!!mode} />
                </span>
            </div>
        </div>
    );
}

export default ProfileAddress;
