import { useState } from 'react';
import { ButtonOutlinePurple, ButtonSolidPink } from '../../../../../configs/Buttons';
import LayoutSelector from '../../../../utils/LayoutSelector';
import MessageModal from '../../../system/modals/MessageModal';

function ListActionButton() {
    const [modal, setModal] = useState(false);

    return (
        <>
            <LayoutSelector
                sm
                md
                webComp={
                    <span className="d-block">
                        <ButtonOutlinePurple evOnClick={() => setModal(!modal)} title="Backup" />
                    </span>
                }
                mobileComp={
                    <span className="button-action-sm">
                        <ButtonSolidPink evOnClick={() => setModal(!modal)} title="Backup" />
                    </span>
                }
            />
            <MessageModal show={modal} setModal={setModal} />
        </>
    );
}

export default ListActionButton;
