import { icons, socialIcon } from '../../../../../configs/Icons';

function CommonIconView({ source, alter, mobile }) {
    return (
        <div className="cell">
            <div className={mobile ? 'logo-icon ml-2' : 'line contact-icon'}>
                <span className="w-100">
                    <img className="w-100 d-block" src={source} alt={alter} />
                </span>
            </div>
        </div>
    );
}

function ContactInfoIconHandler({ value }) {
    if (!value) {
        return null;
    }
    if (value === 'password') {
        return <CommonIconView source={icons.iconMailV2} alter="google icon" />;
    }
    if (value === 'google.com') {
        return <CommonIconView source={socialIcon.googleV2} alter="google icon" />;
    }
    if (value === 'facebook.com') {
        return <CommonIconView source={socialIcon.facebookV3} alter="facebook icon" />;
    }
    if (value === 'github.com') {
        return <CommonIconView source={socialIcon.githubV3} alter="github icon" />;
    }
    if (value === 'primary') {
        return <CommonIconView source={icons.iconPhonePrimary} alter="primary phone icon" />;
    }
    if (value === 'secondary' || value === 'none') {
        return <CommonIconView source={icons.iconPhoneSec} alter="secondary phone icon" />;
    }

    // return null;
}
export default ContactInfoIconHandler;
