import React from 'react';
import { useLayout } from '../contexts/LayoutProvider';

function WebView({ windowSizeName, webComp }) {
    if (
        windowSizeName === 'xs' ||
        windowSizeName === 'sm' ||
        windowSizeName === 'md' ||
        windowSizeName === 'lg'
    ) {
        return null;
    }
    return webComp;
}

function SmallView({ windowSizeName, mobileComp, webComp }) {
    if (windowSizeName === 'xs' || windowSizeName === 'sm') {
        return mobileComp;
    }
    return webComp;
}

function MediumView({ windowSizeName, mobileComp, webComp }) {
    if (windowSizeName === 'md') {
        return mobileComp;
    }
    return webComp;
}

function LargeView({ windowSizeName, mobileComp, webComp }) {
    if (windowSizeName === 'lg') {
        return mobileComp;
    }
    return webComp;
}

function XlView({ windowSizeName, mobileComp, webComp }) {
    if (windowSizeName === 'xl') {
        return mobileComp;
    }
    return webComp;
}

function SmMdView({ windowSizeName, mobileComp, webComp }) {
    if (windowSizeName === 'xs' || windowSizeName === 'sm' || windowSizeName === 'md') {
        return mobileComp;
    }
    return webComp;
}

function SmMdLgView({ windowSizeName, mobileComp, webComp }) {
    if (
        windowSizeName === 'xs' ||
        windowSizeName === 'sm' ||
        windowSizeName === 'md' ||
        windowSizeName === 'lg'
    ) {
        return mobileComp;
    }
    return webComp;
}

function SmMdLgXlView({ windowSizeName, mobileComp, webComp }) {
    if (
        windowSizeName === 'xs' ||
        windowSizeName === 'sm' ||
        windowSizeName === 'md' ||
        windowSizeName === 'lg' ||
        windowSizeName === 'xl'
    ) {
        return mobileComp;
    }
    return webComp;
}
// need to pass
// 1 - layout name [ex: sm, md, lg, xl] for mobile component [note: except these passed layout name other layout will be web component]
// 2 component for web and mobile [Ex: web: webComp, mobile: mobileComp];

// Ex: If we want show a component <MyComponent /> after medium-['md'] layout and other will be before medium-['md'] layout
// <LayoutSelector sm md mobileComp={<MyComponent />} webComp={<OtherComponent />} />
// note: after layout-'md' then comes 'sm' and 'xs' so we need to pass 'sm' also.. 'xs' is given by default
// if we don't pass 'sm' parameter the 'mobileComp={}' will be none after only 'md' layout

function LayoutSelector({ sm, md, lg, xl, mobileComp, webComp }) {
    const { windowSizeName } = useLayout();

    if (!mobileComp && !webComp) {
        return null;
    }

    if (!sm && !md && !lg && !xl) {
        return <WebView windowSizeName={windowSizeName} webComp={webComp} />;
    }

    if (sm && md && lg && xl) {
        return (
            <SmMdLgXlView
                windowSizeName={windowSizeName}
                mobileComp={mobileComp}
                webComp={webComp}
            />
        );
    }

    if (sm && md && lg) {
        return (
            <SmMdLgView windowSizeName={windowSizeName} mobileComp={mobileComp} webComp={webComp} />
        );
    }

    if (sm && md) {
        return (
            <SmMdView windowSizeName={windowSizeName} mobileComp={mobileComp} webComp={webComp} />
        );
    }

    if (xl) {
        return <XlView windowSizeName={windowSizeName} mobileComp={mobileComp} webComp={webComp} />;
    }

    if (lg) {
        return (
            <LargeView windowSizeName={windowSizeName} mobileComp={mobileComp} webComp={webComp} />
        );
    }

    if (md) {
        return (
            <MediumView windowSizeName={windowSizeName} mobileComp={mobileComp} webComp={webComp} />
        );
    }

    if (sm) {
        return (
            <SmallView windowSizeName={windowSizeName} mobileComp={mobileComp} webComp={webComp} />
        );
    }
}

export default LayoutSelector;
