import { icons } from '../../../../../configs/Icons';

function TabsStorage({ info, show, evOnClick, storage }) {
    if (!show) {
        return null;
    }
    return (
        <div
            className={`cell cell-fill cell-md-4 cell-xl-3 cell-sm-5 ${
                !info ? 'disabled-view' : ''
            }`}
            onClick={evOnClick}
            role="button"
            tabIndex={-1}
        >
            <div
                role="button"
                tabIndex={-1}
                className={`info-tab-item storage_backup line-row-center ${
                    storage ? 'active' : ''
                }`}
            >
                <div className="line-col-center">
                    <div className="logo">
                        <img
                            className="w-100 h-100 d-block"
                            src={storage ? icons.iconStorageActive : icons.iconStoragePink}
                            alt="cpanel"
                        />
                    </div>
                    <div className="title">
                        <span>Storage</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TabsStorage;
