import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import URLParse from 'url-parse';
import { useAuth } from '../../contexts/AuthProvider';
import AxiosAuth from '../../utils/AxiosAuth';

function AppLoginPage() {
    const { currentUser } = useAuth();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const initId = searchParams.get('init-id');
        const key = searchParams.get('key');
        const returnUrl = searchParams.get('return');

        AxiosAuth.currentUserAuth(currentUser)
            .setApiUrl('AUTH', '/cross-login/')
            .post({ cInitId: initId, cKey: key, cAppUrl: returnUrl })
            .then(({ data }) => {
                if (data.error === 0) {
                    const url = new URLParse(returnUrl, true);
                    url.query.initId = initId;
                    url.query.key = key;
                    url.query.token = data.gToken;

                    // --Redirect to Opener Site
                    window.location.href = url.toString();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentUser, searchParams]);

    if (!currentUser) {
        return <div>Login Required</div>;
    }

    return (
        <div>
            <button type="button">Login With Bikiran</button>
        </div>
    );
}

export default AppLoginPage;
