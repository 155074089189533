import { ListBody, ListItem, TableHeader, TableMobile } from '../../../utils/TableMobileView';
import { useDomainInfo } from '../domains_info/DomainInfoBasicProvider';
import CnsListActionButtons from './CnsListActionButtons';

function IpView({ viewType, data, id }) {
    const { setInputVal } = useDomainInfo();
    if (viewType === id) {
        return (
            <span className="w-md-100 h-100">
                <input
                    onChange={(e) => setInputVal((cuData) => ({ ...cuData, ip: e.target.value }))}
                    className="form-input w-100 table-input-field w-md-100"
                    type="text"
                    defaultValue={data?.ip || ''}
                />
            </span>
        );
    }
    return <span className="ip-title-m">{data?.ip || ''}</span>;
}

function ListDisableView({ data, viewType, setViewType, id }) {
    return (
        <div className="" style={{ filter: 'grayscale(100%)', pointerEvents: 'none' }}>
            <ListItem>
                <div>
                    <div className="line-row-between">
                        <span className=" title">{data?.hostname}</span>
                        <div>
                            <div className="btn-action">
                                <CnsListActionButtons
                                    setViewType={setViewType}
                                    viewType={viewType}
                                    data={data}
                                    id={id}
                                />
                            </div>
                        </div>
                    </div>
                    <ListBody>
                        <div className="line-align w-100" style={{ height: '35px' }}>
                            <IpView viewType={viewType} data={data} id={id} />
                        </div>
                    </ListBody>
                </div>
            </ListItem>
        </div>
    );
}

function TableBodyList({ data, setViewType, viewType, id }) {
    if (viewType !== null && viewType !== id) {
        return (
            <ListDisableView data={data} viewType={viewType} setViewType={setViewType} id={id} />
        );
    }
    return (
        <ListItem cName={data?.demo ? 'placeholder-mode' : ''}>
            <div className="line-row-between">
                <span className=" title">{data?.hostname}</span>
                <div>
                    <div className="">
                        <CnsListActionButtons
                            setViewType={setViewType}
                            viewType={viewType}
                            data={data}
                            id={id}
                        />
                    </div>
                </div>
            </div>
            <ListBody>
                <div className="line-align w-100" style={{ height: '35px' }}>
                    <IpView viewType={viewType} data={data} id={id} />
                </div>
            </ListBody>
        </ListItem>
    );
}

function DomainInfoCnsListMobile({ info, viewType, setViewType }) {
    return (
        <TableMobile>
            <TableHeader>
                <span>Host Name</span>
                <span>IP Address</span>
            </TableHeader>
            {info?.map((data, index) => (
                <TableBodyList
                    key={data?.hostname}
                    viewType={viewType}
                    setViewType={setViewType}
                    data={data}
                    id={index}
                />
            ))}
        </TableMobile>
    );
}

export default DomainInfoCnsListMobile;
