import { Link } from 'react-router-dom';
import { icons, uploadIcon } from '../../../../configs/Icons';
import InstOption from '../../../utils/InstOption';

const SupportImgOptions = ({ showOptions, setShowOptions }) => (
    <InstOption show={showOptions} setShow={setShowOptions}>
        <Link to="/user/profile/">
            <img src={uploadIcon.iconUploadImage} alt="upload Icon" /> <span>Upload Image</span>
        </Link>
        <Link to="/">
            <img src={icons.iconTakeSelfie} alt="Selfie Icon" /> <span>Take Selfie</span>
        </Link>
    </InstOption>
);

export default SupportImgOptions;
