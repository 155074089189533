/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { ButtonClose, ButtonGr } from '../../../../../configs/Buttons';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import CopyText from '../../../websites/models/CopyText';
import { useHostingInfoBasic } from '../HostingInfoBasicProvider';

const ModalHeader = ({ handleCloseClick, domain }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title sub-title">
                <span>Change cPanel Email</span>
                <span>
                    <small>
                        <CopyText copyValue={domain} value={domain?.toUpperCase()} />
                    </small>
                </span>
            </div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={handleCloseClick} />
            </div>
        </div>
    </div>
);

function Checkbox({ setFormData, formData }) {
    const handleAgreement = () => {
        setFormData((cuData) => ({
            ...cuData,
            checkBox: !formData?.checkBox
        }));
    };

    return (
        <div className="mb-3">
            <div
                className="line-inline line-no-wrap mb-1"
                role="button"
                tabIndex={-1}
                onClick={handleAgreement}
            >
                <span>
                    <input
                        defaultChecked={formData?.checkBox}
                        readOnly
                        type="checkbox"
                        name="checkbox"
                        id=""
                    />
                </span>{' '}
                <span className="font-14 ml-1">
                    Your cPanel reset password email will be changed!
                </span>
            </div>
        </div>
    );
}

function InputField({ handelEmailInput, formData, email }) {
    return (
        <div className="mb-2">
            <label htmlFor="password" className="label">
                Type cPanel Email:
            </label>
            <input
                onChange={handelEmailInput}
                defaultValue={email || formData?.email}
                placeholder="Type Your Email..."
                type="text"
                className="form-input w-100"
                autoComplete="off"
            />
        </div>
    );
}

function ModalBody({ handleCloseClick, item, setMode }) {
    const { formData, setFormData, updateContractEmail } = useHostingInfoBasic();

    const { setMessage } = useTemplate();

    const inputVal = !!formData.email;

    const handelEmailInput = (ev) => {
        setFormData((cuData) => ({
            ...cuData,
            email: ev.target.value
        }));
    };

    const handelUpdateBtn = () => {
        setMessage('Updating Email...');
        setMode(1);
        updateContractEmail()
            .then(({ error, message }) => {
                setMode(0);

                if (error === 0) {
                    handleCloseClick();
                } else {
                    setFormData((cuData) => ({
                        ...cuData,
                        email: formData?.email,
                        checkBox: formData?.checkBox
                    }));
                }
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    return (
        <div className="modal-body-area">
            <div className="line-align" />
            <InputField
                handelEmailInput={handelEmailInput}
                formData={formData}
                email={item?.email || '--'}
            />
            <Checkbox setFormData={setFormData} formData={formData} />
            <div className="line justify-end">
                <ButtonGr
                    disabled={!formData?.checkBox || !inputVal}
                    evOnClick={handelUpdateBtn}
                    title="Update"
                    modal
                />
            </div>
        </div>
    );
}

function BasicInfoEmailPopup({ show, setModal, data }) {
    const [mode, setMode] = useState(0);

    const { setFormData } = useHostingInfoBasic();

    const handleCloseClick = () => setFormData({}) + setModal(null);

    if (!show) {
        return null;
    }

    return (
        <div className="modal-box backdrop cp-password-wizard">
            <div
                className={`modal-box-content ${mode ? 'loading-block' : ''}`}
                style={{ width: '450px' }}
            >
                <ModalHeader handleCloseClick={handleCloseClick} domain={data?.domain} />

                <ModalBody handleCloseClick={handleCloseClick} item={data} setMode={setMode} />
            </div>
        </div>
    );
}

export default BasicInfoEmailPopup;
