import { useEffect } from 'react';
import PageProperties from '../../utils/PageProperties';
import HeaderSection from '../headers/HeaderSection';
import FooterSection2 from '../system/section/Footer2Section';
import MobileFooter from '../users/components/MobileFooter';
import FixedHeaderControl from '../websites/components/FixedHeaderControl';
import ImportNumberSection from './components/sms-service/ImportNumberSection';
import NumberManageSection from './components/sms-service/NumberManageSection';
import SmsFeaturesSection from './components/sms-service/SmsFeaturesSection';
import SmsMobileAppSection from './components/sms-service/SmsMobileAppSection';
import SmsPricingSection from './components/sms-service/SmsPricingSection';
import WebPortalSection from './components/sms-service/WebPortalSection';

function SmsServicePage() {
    useEffect(() => {
        PageProperties('SMS \u2022 Services');
    }, []);

    return (
        <>
            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>
            <WebPortalSection />
            <SmsMobileAppSection />
            <SmsPricingSection />
            <NumberManageSection />
            <ImportNumberSection />
            <SmsFeaturesSection />
            <MobileFooter />
            <FooterSection2 />
        </>
    );
}

export default SmsServicePage;
