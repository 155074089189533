import { MD5 } from 'crypto-js';
import { createContext, useContext, useEffect, useState } from 'react';
import EmptyPlaceholder from '../placeholders/EmptyPlaceholder';
import AxiosAuth from '../utils/AxiosAuth';

const InitContext = createContext();

export function useInit() {
    return useContext(InitContext);
}

function InitProvider({ children }) {
    const [initData, setInitData] = useState(null);
    const locale = initData?.initData?.locale || {};
    const tlds = initData?.initData?.tlds || [];

    // --Is Yearly
    const [isYearly, setIsYearly] = useState(localStorage.getItem('isDurationYearly') === 'true');

    const handelChangeLocale = (country, currency, language) => {
        localStorage.setItem('locale', JSON.stringify({ country, currency, language }));
        setInitData((cuData) => ({
            ...cuData,
            initData: { ...cuData.initData, locale: { country, currency, language } }
        }));
    };

    const handelChangeIsYearly = (yState) => {
        localStorage.setItem('isDurationYearly', yState.toString());
        setIsYearly(localStorage.getItem('isDurationYearly') === 'true');
    };

    useEffect(() => {
        AxiosAuth.setApiUrl('APP_INIT')
            .post({ hello: 'OK' })
            .then(({ data }) => {
                if (data.error === 0) {
                    // Init Info
                    setInitData(data.data);
                    localStorage.setItem('init-id', data.data.initId);
                    localStorage.setItem('init-hash', data.data.initHash);
                    localStorage.setItem('locale', JSON.stringify(data.data.initData.locale));
                }
            });
    }, [locale.currency]);

    useEffect(() => {
        const handleChangeVisibility = () => {
            if (document.visibilityState === 'visible') {
                if (
                    MD5(JSON.stringify(locale)).toString() !==
                    MD5(localStorage.getItem('locale')).toString()
                ) {
                    if (Object.keys(locale).length > 0) {
                        window.location.reload();
                    }
                }
            }
        };
        document.addEventListener('visibilitychange', handleChangeVisibility);

        return () => {
            document.removeEventListener('visibilitychange', handleChangeVisibility);
        };
    }, [locale]);

    const value = {
        initData,
        packages: initData?.siteData?.packages || [],
        currencies: initData?.initData?.currencies || [],
        countries: initData?.initData?.countries || [],
        locale,
        tlds,
        handelChangeLocale,
        isYearly,
        handelChangeIsYearly
    };

    if (initData === null) {
        return <EmptyPlaceholder />;
    }

    return <InitContext.Provider value={value}>{children}</InitContext.Provider>;
}

export default InitProvider;
