import { Link } from 'react-router-dom';
import SearchInputSection from '../../websites/components/SearchInputSection';

function SearchDomainSection() {
    const domains = [
        {
            id: 1,
            title: '.com',
            price: 12.99
        },
        {
            id: 2,
            title: '.net',
            price: 13.99
        },
        {
            id: 3,
            title: '.info',
            price: 17.49
        },
        {
            id: 4,
            title: '.org',
            price: 19.99
        },
        {
            id: 5,
            title: '.edu',
            price: 21.99
        }
    ];

    return (
        <div className="domain-pricing-info">
            <div className="line-row-between line-g2 line-no-wrap overflow-auto">
                {domains.map((domain) => (
                    <div className="cell" key={domain.id}>
                        <span className="font-14 primary">{domain?.title}</span>
                        <span className="font-16 fw-400 purple d-block">${domain?.price}</span>
                    </div>
                ))}
                <div className="cell">
                    <Link to="/services/hosting/web/">
                        <span className="font-16 fw-400 primary no-wrap">& Much More</span>
                    </Link>
                </div>
            </div>
        </div>
    );
}

function HeroSection() {
    return (
        <div className="hero-section-container">
            <div className="content-top">
                <h3 className="home-search-text">
                    Find a Domain Name & Create <br /> your website today!
                </h3>
            </div>
            <div className="content-center ">
                <div className="another-page-search">
                    <SearchInputSection key="home" />
                </div>
            </div>
            <div className="content-bottom">
                <SearchDomainSection />
            </div>
        </div>
    );
}
export default HeroSection;
