import { useEffect } from 'react';
import PageProperties from '../../../../utils/PageProperties';
import HeaderSection from '../../../headers/HeaderSection';
import FooterSection2 from '../../../system/section/Footer2Section';
import MobileFooter from '../../../users/components/MobileFooter';
import FixedHeaderControl from '../../../websites/components/FixedHeaderControl';
import ServerServiceTabSection from '../components/ServerServiceTabSection';

function ServerServicePage() {
    useEffect(() => {
        PageProperties('Server');
    }, []);

    return (
        <>
            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>
            <ServerServiceTabSection />
            <MobileFooter />
            <FooterSection2 />
        </>
    );
}

export default ServerServicePage;
