import { createContext, useContext, useEffect, useState } from 'react';
import AxiosAuth from '../../../utils/AxiosAuth';

const RoleInvitationContext = createContext();

export function useInvitation() {
    return useContext(RoleInvitationContext);
}

const initialState = null;

function RoleInvitationProvider({ children, currentUser, token }) {
    const [inviteData, setInviteData] = useState(initialState);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState(null);

    const handelAcceptInvite = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT_ROLE', `/invite-accept/`)
                .post({
                    csrf: inviteData?.csrf || '',
                    token
                })
                .then(({ data }) => {
                    if (data.error === 0) {
                        setInviteData(initialState);
                    } else {
                        setError(data);
                    }
                    resolve({ error: data.error, message: data.message, data: data.data });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const handelRejectInvite = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT_ROLE', `/invite-reject/`)
                .post({
                    csrf: inviteData?.csrf || '',
                    token
                })
                .then(({ data }) => {
                    if (data.error === 0) {
                        setInviteData(initialState);
                    } else {
                        setError(data);
                    }
                    resolve({ error: data.error, message: data.message });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    useEffect(() => {
        if (token) {
            AxiosAuth.setApiUrl('CONTRACT_ROLE', `/invite-status/`)
                .post({ token })
                .then(({ data }) => {
                    if (data.error === 0) {
                        setInviteData(data.data);
                    } else {
                        setStatus(data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [token]);

    const value = {
        status,
        error,
        inviteData,
        handelAcceptInvite,
        handelRejectInvite
    };

    return (
        <RoleInvitationContext.Provider value={value}>{children}</RoleInvitationContext.Provider>
    );
}

export default RoleInvitationProvider;
