import React, { useEffect } from 'react';
import PageProperties from '../../../utils/PageProperties';
import HeaderSection from '../../headers/HeaderSection';
import FooterSection2 from '../../system/section/Footer2Section';
import MobileFooter from '../../users/components/MobileFooter';
import ClientSection from '../components/ClientSection';
import FixedHeaderControl from '../components/FixedHeaderControl';

function ClientsPage({ page }) {
    useEffect(() => {
        if (page === 'clients') {
            document.getElementById('clients').scrollIntoView();
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [page]);

    useEffect(() => {
        PageProperties('Clients');
    }, []);

    return (
        <>
            <div id="clients" />
            <div className="mb-7">
                <FixedHeaderControl>
                    <HeaderSection />
                </FixedHeaderControl>
            </div>
            <ClientSection />
            <FooterSection2 />
            <MobileFooter />
        </>
    );
}

export default ClientsPage;
