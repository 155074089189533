import { useEffect } from 'react';
import PageProperties from '../../../utils/PageProperties';
import HeaderSection from '../../headers/HeaderSection';
import MobileFooter from '../../users/components/MobileFooter';
import FixedHeaderControl from '../../websites/components/FixedHeaderControl';
import DomainFeatureSection from '../section/DomainFeatureSection';
import FooterSection2 from '../section/Footer2Section';
import HeroSection from '../section/HeroSection';
import OfferSection from '../section/OfferSection';

function DomainPage({ page }) {
    useEffect(() => {
        if (page === 'domain') {
            document.getElementById('domain').scrollIntoView();
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [page]);

    useEffect(() => {
        PageProperties('Domain');
    }, []);

    return (
        <>
            <div id="domain" />
            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>

            <HeroSection />
            <OfferSection />

            <DomainFeatureSection />

            <FooterSection2 />
            <MobileFooter />
        </>
    );
}

export default DomainPage;
