import { useEffect, useState } from 'react';

import DropdownMenu from '../../../../utils/DropdownMenu';
import { DateInput } from '../../../../utils/inputs/DateInputs';
import PageProperties from '../../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../../headers/DashboardHeaderSection';
import TransactionTable from './transaction_list/TransactionTable';

// const transactions = []
function HeaderButton() {
    const [selectedOption, setSelectedOption] = useState();

    const domains = [
        {
            id: 1,
            title: '.Com'
        },
        {
            id: 2,
            title: '.Net'
        },
        {
            id: 3,
            title: '.Org'
        },
        {
            id: 4,
            title: '.Xyz'
        }
    ];

    return (
        <div className="line-align">
            <div>
                <DateInput height="30px" />
            </div>
            <div className="ml-2 mr-2">
                <DropdownMenu
                    options={domains}
                    property="title"
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    variant="border"
                    height="30px"
                />
            </div>
        </div>
    );
}

function ContentHead() {
    return (
        <DashboardDefaultHeader
            title="Transaction"
            path="/user/billing/invoice/"
            content={
                <div className="ml-2">
                    <HeaderButton />
                </div>
            }
        />
    );
}

function UsersTransactionPage() {
    useEffect(() => {
        PageProperties(`Transaction List \u2022 Billing`);
    }, []);

    return (
        <div className="cell cell-content h-100">
            <div className="content-section">
                <div className="content-area">
                    <ContentHead />
                    <div className="content-body-scroll">
                        <div className="d-none d-lg-block mb-3">
                            <HeaderButton />
                        </div>
                        <TransactionTable />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsersTransactionPage;
