import React from 'react';

function ServerServiceTabSection() {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '75%'
            }}
        >
            <h2 className="text-center">Comming Soon....</h2>
        </div>
    );
}

export default ServerServiceTabSection;
