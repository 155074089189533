export const status = (data) => {
    if (data?.isYou) {
        return <span className="status-view default ml-1">You</span>;
    }
    if (data?.isPending) {
        return <span className="status-view pending ml-1">Pending</span>;
    }
    return null;
};

function RoleStatus() {
    return null;
}

export default RoleStatus;
