import { useEffect, useState } from 'react';
import AxiosAuth from '../../../utils/AxiosAuth';
import { useSearchPage } from '../SearchPageProvider';
import SearchDomainRegAvailable from './SearchDomainRegAvailable';
import SearchDomainWebNoInfo from './SearchDomainWebNoInfo';
import SearchDomainWebOffline from './SearchDomainWebOffline';
import SearchDomainWebRunning from './SearchDomainWebRunning';

function WebsiteInfoView({ domain }) {
    const [webInfo, setWebInfo] = useState(null);

    useEffect(() => {
        AxiosAuth.setApiUrl('SEARCH', '/get-website-info/')
            .get({ params: { domain } })
            .then(({ data }) => {
                if (data.error === 0) {
                    setWebInfo(data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [domain]);

    if (!webInfo) {
        return <SearchDomainWebRunning />;
    }

    if (webInfo.status === 'no-info') {
        return <SearchDomainWebNoInfo webInfo={webInfo} setWebInfo={setWebInfo} />;
    }

    if (webInfo.status === 'offline') {
        return <SearchDomainWebOffline webInfo={webInfo} />;
    }

    return <SearchDomainWebRunning webInfo={webInfo} domain={domain || null} />;
}

function SearchDomainSection() {
    const { domainInfo } = useSearchPage();

    // --Placeholder
    if (domainInfo === null) {
        return <SearchDomainRegAvailable />;
    }

    // --Not a Domain
    if (domainInfo.isDomain !== true) {
        return null;
    }

    // --Domain & Not Listed Tld
    // if (domainInfo.isDomain === true && domainInfo.isListedTld !== true) {
    //     return null;
    // }

    // --Domain & Listed Tld
    if (domainInfo.isDomain === true && domainInfo.isListedTld === true && domainInfo.productInfo) {
        return <SearchDomainRegAvailable domainInfo={domainInfo} />;
    }

    // --Already Registered
    return <WebsiteInfoView domain={domainInfo.hostname} />;
}

export default SearchDomainSection;
