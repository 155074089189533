function PasswordEditSuggestion() {
    return (
        <div className="cell-6 cell-self-middle cell-md-12">
            <div className="password-suggestion">
                <div className="line line-no-wrap opacity-medium mb-2">
                    <span>&bull;</span>{' '}
                    <span className="font-14 ml-2">
                        It starts with a passphrase, “I want ice cream! for dinner in Kentucky?”
                    </span>
                </div>
                <div className="line line-no-wrap opacity-medium mb-2">
                    <span>&bull;</span>{' '}
                    <span className="font-14 ml-2">
                        Uses a rule to keep the first 2 letters of every word and capitalize every
                        second letter.
                    </span>
                </div>
                <div className="line line-no-wrap opacity-medium mb-2">
                    <span>&bull;</span> <span className="font-14 ml-2">Long at 14 characters.</span>
                </div>
                <div className="line line-no-wrap opacity-medium mb-2">
                    <span>&bull;</span>{' '}
                    <span className="font-14 ml-2">Uses special characters: “!” and “?”</span>
                </div>
                <div className="line line-no-wrap opacity-medium mb-2">
                    <span>&bull;</span>{' '}
                    <span className="font-14 ml-2">Includes uppercase and lowercase letters.</span>
                </div>
            </div>
        </div>
    );
}

export default PasswordEditSuggestion;
