import { useNavigate } from 'react-router-dom';
import { arrowIcons, solvedQIcons } from '../../../../../configs/Icons';

const questions = [
    {
        id: 1,
        title: 'How to create an account on Bikiran.com',
        img: solvedQIcons.iconCreateAccount,
        time: '3 min Read',
        type: 'account'
    },
    {
        id: 2,
        title: 'How to purchse domain from Bikiran.com',
        img: solvedQIcons.iconPurchaseDomain,
        time: '2 min Read',
        type: 'domain'
    },
    {
        id: 3,
        title: 'How to purchese hosting from Bikiran.com',
        img: solvedQIcons.iconPurchaseHosting,
        time: '3 min Read',
        type: 'hosting'
    },
    {
        id: 4,
        title: 'How to activate Bikiran SMS service by my own?',
        img: solvedQIcons.iconActiveSms,
        time: '4 min Read',
        type: 'sms-email'
    },
    {
        id: 5,
        title: 'How to send multiple email from my dashboard?',
        img: solvedQIcons.iconSendEmail,
        time: '4 min Read',
        type: 'sms-email'
    },
    {
        id: 6,
        title: 'How to manage my software From my PC?',
        img: solvedQIcons.iconManageSoftware,
        time: '2 min Read',
        type: 'software'
    }
];

function QuestionAreaBox({ item }) {
    const navigate = useNavigate();
    return (
        <div className="cell cell-4 cell-sm-12 line-row-center mb-7 mb-sm-4">
            <div
                role="button"
                tabIndex={-1}
                onClick={() => navigate(`/knowledge-base/${item.id}`)}
                className="item-container line-align"
            >
                <div className="img-head">
                    <img className="w-100" src={item.img} alt="icon" />
                </div>
                <div className="area-content">
                    <h4 className="title mt-0 mb-2">{item.title}</h4>
                    <div className="line-row-between align-unset area-footer w-100">
                        <span className="time">{item.time}</span>
                        <span>
                            <img
                                style={{ transform: 'rotate(450deg)' }}
                                src={arrowIcons.iconArrowTopS}
                                alt="iconArrow"
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function SolvedQuestionSection() {
    return (
        <div className="solved-questions-container w-100 line line-g3 line-sm-g0 mb-120">
            {questions.map((item) => (
                <QuestionAreaBox key={item.id} item={item} />
            ))}
        </div>
    );
}

export default SolvedQuestionSection;
