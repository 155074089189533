import { useState } from 'react';
import { OTP } from '../../../../../configs/ConfigApi';
import VerificationOtpProvider from '../../../verification_otp/VerificationOtpProvider';
import { useDomainInfo } from '../../domains_info/DomainInfoBasicProvider';
import BasicInfoDomainLockPopup from './BasicInfoDomainLockPopup';

function ToggleView({ isActive, evOnClick }) {
    const divStyle = {
        backgroundColor: isActive ? '#38D600' : 'rgba(146, 156, 191, 0.5)',
        justifyContent: isActive ? 'flex-end' : 'flex-start'
    };
    return (
        <div
            style={divStyle}
            className="access-view p-none"
            role="button"
            tabIndex={-1}
            onClick={evOnClick}
        >
            <span />
        </div>
    );
}

function BasicInfoDomainLock() {
    const [modal, setModal] = useState(false);
    const { isDomainActive, isTransferLock } = useDomainInfo();

    // const isActive = isPaused;

    return (
        <VerificationOtpProvider otpKey={OTP.DOMAIN_UNLOCK}>
            <div className="line mb-3">
                <div className="cell cell-5">
                    <span className="subject">Transfer Lock:</span>
                </div>
                <div className="cell cell-7" style={{ wordBreak: 'break-word' }}>
                    <span
                        className={`value line-align h-100 ${
                            !isDomainActive ? 'disabled-view' : ''
                        }`}
                    >
                        <ToggleView
                            evOnClick={() => setModal(!modal)}
                            isActive={isTransferLock}
                            dPrivacy={null}
                        />
                        <span className={`font-14 ml-2 ${isTransferLock ? 'green' : 'red'}`}>
                            {isTransferLock ? 'Locked' : 'Unlocked'}
                        </span>
                    </span>
                </div>
            </div>

            <BasicInfoDomainLockPopup show={modal} setModal={setModal} />
        </VerificationOtpProvider>
    );
}

export default BasicInfoDomainLock;
