import { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { arrowIcons, editIcons } from '../../../../configs/Icons';
import { basic } from '../../../../configs/Images';
import Iopt from '../../../utils/Iopt';
import ImgOptions from './ImgOptions';
import {
    SupportManageMenulist,
    listDemo,
    listLive,
    listMobileDemo,
    listMobileLive,
    profileMenuListDemo,
    profileMenuListLive
} from './SidebarMenuListArr';

const isDemo = process.env.NODE_ENV === 'development';

const profileMenuList = isDemo ? profileMenuListDemo : profileMenuListLive;

const list = isDemo ? listDemo : listLive;

const listMobile = isDemo ? listMobileDemo : listMobileLive;

const ProfileInfo = ({ show, currentUser, myRef }) => {
    const [showOptions, setShowOptions] = useState(false);

    const navigate = useNavigate();

    const img = currentUser?.firebase?.photoURL
        ? Iopt(currentUser?.firebase?.photoURL)
        : Iopt(currentUser?.photoUrl);

    if (!show) {
        return null;
    }

    return (
        <div className="profile-info">
            <div className="avatar-img-area">
                <img
                    className="avatar-img"
                    src={img || basic.imgAvatar}
                    // src={currentUser?.photoUrl ? Iopt(currentUser?.photoUrl) : basic.imgAvatar}
                    alt="Avatar"
                />
                <span
                    className="edit-area"
                    onClick={() => {
                        setShowOptions(true);
                    }}
                    role="button"
                    tabIndex={-1}
                >
                    <img src={editIcons.iconEditV3} alt="" />
                </span>
            </div>

            <ImgOptions
                myRef={myRef}
                showOptions={showOptions}
                setShowOptions={setShowOptions}
                // signOut={signOut}
            />
            <div className="profile-name">{currentUser?.displayName || <span>[Not Set]</span>}</div>
            <div className="profile-contact">{currentUser?.email || <span>[Not Set]</span>}</div>
            <div className="profile-button">
                <button
                    onClick={() => navigate('/user/profile-overview/')}
                    type="button"
                    className="button button-gr-read"
                    style={{ color: '#ffff' }}
                >
                    Profile Overview
                </button>
            </div>
        </div>
    );
};

const OptionSubList = ({ clickOnEndItem, subMenu, openL1, setOpenL1 }) => {
    if (!subMenu.length) {
        return null;
    }

    return (
        <ul className="label-1">
            {subMenu.map((item) => (
                <li key={item.id} className={openL1 === item.id ? 'open' : ''}>
                    <Link
                        to={item.path}
                        onClick={(ev) => {
                            setOpenL1(item.id);

                            if (typeof clickOnEndItem === 'function') clickOnEndItem(ev);
                        }}
                    >
                        <img className="icon" src={item.icon} alt={item.title} />
                        <span>{item.title}</span>
                    </Link>
                </li>
            ))}
        </ul>
    );
};

const OptionList = ({
    removeMenus = [],
    clickOnEndItem,
    menuL0,
    menuL1,
    showServiceOption,
    support
}) => {
    const [openL0, setOpenL0] = useState(menuL0);
    const [openL1, setOpenL1] = useState(menuL1);

    const optionList = () => {
        if (showServiceOption?.optionType !== 'profile') {
            if (support) {
                return SupportManageMenulist;
            }
            if (showServiceOption?.optionType === 'services') {
                return listMobile;
            }
            return list;
        }
        return profileMenuList;
    };

    return (
        <div className="option-list">
            <ul>
                {optionList()
                    .filter((item) => removeMenus.indexOf(item.id) === -1)
                    .map((item) => {
                        if (item.subMenu?.length) {
                            return (
                                <li key={item.id} className={openL0 === item.id ? 'open' : ''}>
                                    <a
                                        href={item.path}
                                        onClick={(ev) => {
                                            ev.preventDefault();

                                            setOpenL0(openL0 === item.id ? null : item.id);
                                        }}
                                    >
                                        <img className="icon" src={item.icon} alt={item.title} />
                                        <span>{item.title}</span>

                                        {item.subMenu?.length ? (
                                            <img
                                                className="arrow"
                                                src={arrowIcons.iconArrowRightV2}
                                                alt=""
                                            />
                                        ) : null}
                                    </a>

                                    {item.subMenu?.length ? (
                                        <OptionSubList
                                            subMenu={item.subMenu}
                                            openL1={openL1}
                                            setOpenL1={setOpenL1}
                                            clickOnEndItem={clickOnEndItem}
                                        />
                                    ) : null}
                                </li>
                            );
                        }
                        return (
                            <li key={item.id} className={openL0 === item.id ? 'open' : ''}>
                                <Link
                                    to={item.path || item?.urlNewWindow}
                                    target={item?.urlNewWindow && '_blank'}
                                    onClick={(ev) => {
                                        setOpenL0(item.id);

                                        if (typeof clickOnEndItem === 'function')
                                            clickOnEndItem(ev);
                                    }}
                                >
                                    <img className="icon" src={item.icon} alt={item.title} />
                                    <span>{item.title}</span>
                                    {item?.urlNewWindow ? (
                                        <img
                                            style={{
                                                transform: 'translateY(-50%)',
                                                opacity: '0.5'
                                            }}
                                            className="arrow"
                                            src={arrowIcons.iconArrowNewTabV2}
                                            alt=""
                                        />
                                    ) : null}
                                </Link>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};

function SidebarMenuSection({
    hideProfile,
    clickOnEndItem,
    currentUser,
    showServiceOption,
    support
}) {
    const ref = useRef();
    const removeMenus = [];
    if (hideProfile) {
        removeMenus.push('dashboard');
    }

    return (
        <div className="profile-section">
            <ProfileInfo myRef={ref} show={!hideProfile} currentUser={currentUser} />

            {hideProfile ? null : <div className="divider" />}

            <OptionList
                support={support}
                showServiceOption={showServiceOption}
                menuL0=""
                menuL1=""
                removeMenus={removeMenus}
                clickOnEndItem={clickOnEndItem}
            />
        </div>
    );
}

export default SidebarMenuSection;
