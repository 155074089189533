import { useState } from 'react';
import { ButtonActionEdit } from '../../../../../configs/Buttons';
import { OTP } from '../../../../../configs/ConfigApi';
import VerificationOtpProvider from '../../../verification_otp/VerificationOtpProvider';
import CopyText from '../../../websites/models/CopyText';
import BasicInfoPrimaryDomainPopup from './BasicInfoPrimaryDomainPopup';

function DomainView({ evOnClick, value, cpDomainChange }) {
    return (
        <div className="info-edit-area placeholder-moder">
            <div className="line-row-between line-no-wrap">
                <div className="cell cell-10">
                    <CopyText value={value || '---------------------'} cName="value" />
                </div>
                <div hidden={!cpDomainChange} className="cell">
                    <div className="action-btn ml-2">
                        <ButtonActionEdit evOnClick={evOnClick} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function BasicInfoPrimaryDomain({ basicInfo }) {
    const [modal, setModal] = useState(false);
    const { cpDomainChange } = basicInfo?.permissions || {};
    return (
        <>
            <div className="line-align mb-2">
                <div className="cell cell-5">
                    <span className="subject">Primary Domain:</span>
                </div>
                <div className="cell cell-7">
                    <DomainView
                        evOnClick={() => setModal(!modal)}
                        value={basicInfo?.domain}
                        cpDomainChange={cpDomainChange}
                    />
                </div>
            </div>
            <VerificationOtpProvider otpKey={OTP.CP_DOMAIN_CHANGE}>
                <BasicInfoPrimaryDomainPopup show={modal} data={basicInfo} setModal={setModal} />
            </VerificationOtpProvider>
        </>
    );
}

export default BasicInfoPrimaryDomain;
