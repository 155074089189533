import TopTabContent from './TopTabContent';

function TopTabsMenuArea({ setReqItem, reqItem }) {
    const menuItem = [
        { id: 1, title: 'Params' },
        { id: 2, title: 'Authorization' },
        { id: 3, title: 'Headers' },
        { id: 4, title: 'Body' },
        { id: 5, title: 'Pre-request Script' },
        { id: 6, title: 'Tests' },
        { id: 7, title: 'Settings' }
    ];
    return (
        <div className="req-menu-area">
            <ul className="line-align mt-1 tabs-menu-item mb-4">
                {menuItem.map((item, index) => (
                    <li key={item.id}>
                        <span
                            role="button"
                            tabIndex={-1}
                            onClick={() => setReqItem(index)}
                            className={`common-title ${
                                reqItem === index && 'common-title-active bb-active'
                            }`}
                        >
                            {item.title}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
}

function TopContentArea({ setReqItem, reqItem }) {
    return (
        <>
            <TopTabsMenuArea setReqItem={setReqItem} reqItem={reqItem} />
            <TopTabContent reqItem={reqItem} />
        </>
    );
}
export default TopContentArea;
