import {
    ButtonActionDotMenu,
    ButtonActionEdit,
    ButtonActionLogout
} from '../../../../../../configs/Buttons';
import LayoutSelector from '../../../../../utils/LayoutSelector';

function WebView() {
    return (
        <div className="line-align line-no-wrap btn-action-web">
            <span>
                <ButtonActionLogout evOnClick={null} />
            </span>
            <span className="ml-1 mr-1">
                <ButtonActionEdit evOnClick={null} />
            </span>
            <span>
                <ButtonActionDotMenu evOnClick={null} />
            </span>
        </div>
    );
}
function MobileView() {
    return (
        <div className="line-align line-no-wrap btn-action">
            <span>
                <ButtonActionLogout evOnClick={(ev) => ev.stopPropagation()} />
            </span>
            <span className="ml-1 mr-1">
                <ButtonActionEdit evOnClick={(ev) => ev.stopPropagation()} />
            </span>
            <span>
                <ButtonActionDotMenu evOnClick={(ev) => ev.stopPropagation()} />
            </span>
        </div>
    );
}

function UsersListActionButton() {
    return <LayoutSelector sm md webComp={<WebView />} mobileComp={<MobileView />} />;
}

export default UsersListActionButton;
