import { ButtonOutlineRed } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';

function TwoFacAuthUpdateWeb({ setMode }) {
    return (
        <div className="info-list-view">
            <div className="line line-g2 line-no-wrap">
                <div className="cell cell-fill">
                    <div className="line-align line-no-wrap">
                        <img className="d-block" src={icons.iconShieldV3} alt="icon device" />

                        <div className="ml-3">
                            <span className="font-16 clr">
                                Your Two-factor authentication is on
                            </span>
                            <span className="font-14 opacity-medium d-block break-all mr-1">
                                We`ll ask for a verification code via your security method if we
                                notice a login from an unrecognized device or browser.
                            </span>
                        </div>
                    </div>
                </div>
                <div className="cell cell-3 cell-md-fill text-right cell-self-middle">
                    <ButtonOutlineRed evOnClick={() => setMode(true)} title="Turn Off" />
                </div>
            </div>
        </div>
    );
}

export default TwoFacAuthUpdateWeb;
