import {
    ButtonActionCart,
    ButtonActionDetails,
    ButtonActionDotMenu
} from '../../../../../../configs/Buttons';
import LayoutSelector from '../../../../../utils/LayoutSelector';

function WebView() {
    return (
        <div className="line-align line-no-wrap btn-action-web">
            <span>
                <ButtonActionCart evOnClick={null} />
            </span>
            <span className="ml-1 mr-1">
                <ButtonActionDetails evOnClick={null} />
            </span>
            <span>
                <ButtonActionDotMenu />
            </span>
        </div>
    );
}
function MobileView() {
    return (
        <div className="line-align line-no-wrap btn-action">
            <span>
                <ButtonActionCart evOnClick={null} />
            </span>

            <span className="ml-1">
                <ButtonActionDotMenu />
            </span>
        </div>
    );
}

function CartItemListActionButton() {
    return <LayoutSelector sm md webComp={<WebView />} mobileComp={<MobileView />} />;
}

export default CartItemListActionButton;
