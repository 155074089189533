/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
    arrowIcons,
    dashboardIcons,
    domainIcons,
    icons,
    signOutIcons,
    supportIcons,
    userIcons
} from '../../../../configs/Icons';
import { basic } from '../../../../configs/Images';
import { useAuth } from '../../../contexts/AuthProvider';
import Iopt from '../../../utils/Iopt';
import LeftSideModal from '../../../utils/LeftSideModal';

const publicMenu = [
    {
        id: 'domain',
        title: 'Domain',
        url: '/domain/',
        icon: domainIcons.iconDomainV4
    },
    {
        id: 'services',
        title: 'Services',
        url: '/services/',
        icon: icons.iconServices,
        subMenu: [
            {
                id: 'hosting',
                title: 'Hosting',
                path: '/services/hosting/web/',
                icon: icons.iconWebHosting
            },
            {
                id: 'server',
                title: 'Server',
                path: '/services/server/',
                icon: icons.iconAppHosting
            },
            {
                id: 'software',
                title: 'Software',
                path: 'https://www.edusoft.com.bd/',
                redirect: true,
                icon: icons.iconSoftwareFill
            },
            {
                id: 'send',
                title: 'Send',
                path: '/services/send/sms-service/',
                icon: icons.iconSendFill
            }
        ]
    },
    {
        id: 'clients',
        title: 'Clients',
        url: '/clients/',
        icon: icons.iconClients
    },
    {
        id: 'about-us',
        title: 'About',
        url: '/about-us/',
        icon: icons.iconAboutUs
    }
];

const privateMenu = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        url: '/user/dashboard/',
        icon: dashboardIcons.iconDashboardV2
    },
    {
        id: 'myProfile',
        title: 'My Profile',
        url: '/user/profile-overview/',
        icon: userIcons.iconUserV5
    },
    {
        id: 'billing',
        title: 'Billing',
        url: '/user/billing/invoice/',
        icon: icons.iconBilling
    },
    {
        id: 'support',
        title: 'Support',
        url: '/support/',
        icon: supportIcons.iconSupportV3
    }
];

const privateMenu2 = [
    {
        id: 'logOut',
        title: 'Logout',
        url: '/logout/',
        icon: signOutIcons.iconSignOutV2
    }
];

function UserArea({ currentUser, makeAction }) {
    const navigate = useNavigate();
    const handleLoginClick = () => {
        makeAction('sign-in');
    };

    if (currentUser?.loginStatus) {
        return (
            <div
                className="line user-area"
                onClick={() => navigate('/user/profile-overview/')}
                role="button"
                tabIndex={-1}
            >
                <div className="cell">
                    <img
                        src={currentUser?.photoUrl ? Iopt(currentUser.photoUrl) : basic.imgAvatar}
                        alt="Avatar"
                        className="avatar-image ml-4 mr-4"
                        tabIndex="-1"
                        role="button"
                    />
                </div>
                <div className="cell">
                    <div className="user-sidebar-info">
                        <h3>{currentUser?.displayName || <span>[Not Set]</span>}</h3>
                        <span>{currentUser?.email || <span>[Not Set]</span>}</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="">
            <div className="text-center">
                <button
                    type="button"
                    className="button button-outline-pink"
                    onClick={handleLoginClick}
                >
                    Login
                </button>
            </div>
        </div>
    );
}

function MenuList({ currentUser, setShow, signOut }) {
    if (!currentUser?.loginStatus) {
        return null;
    }

    return (
        <>
            <img src={icons.imgHr} alt="HR" className="hr" />

            <ul className="menu-list">
                {privateMenu.map((item) => (
                    <li key={item.id}>
                        <Link
                            to={item.url}
                            onClick={() => {
                                setShow(false);
                            }}
                        >
                            <img src={item.icon} alt="" />
                            <span>{item.title}</span>
                        </Link>
                    </li>
                ))}
            </ul>
            <img src={icons.imgHr} alt="HR" className="hr" />

            <ul className="menu-list">
                {privateMenu2.map((item) => (
                    <li key={item.id}>
                        <Link
                            to={item.url}
                            onClick={(ev) => {
                                ev.preventDefault();
                                setShow(false);
                                signOut();
                            }}
                        >
                            <img src={item.icon} alt="" />
                            <span>{item.title}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </>
    );
}

function SubmenuList({ item, openL1 }) {
    return (
        <ul className={`label-1 ${openL1 ? 'active' : ''}`}>
            {item?.subMenu?.map((row) => {
                if (row?.redirect) {
                    return (
                        <li key={row.id}>
                            <Link
                                role="button"
                                onClick={() => window.open(row.path)}
                                style={{ marginLeft: 17 }}
                            >
                                <img className="icon" src={row.icon} alt={row.title} />
                                <span>{row.title}</span>
                            </Link>
                        </li>
                    );
                }
                return (
                    <li key={row.id}>
                        <Link to={row.path} style={{ marginLeft: 17 }}>
                            <img className="icon" src={row.icon} alt={row.title} />
                            <span>{row.title}</span>
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
}

export function SideBarModal({ show, setShow, currentUser, makeAction, signOut }) {
    const [openL1, setOpenL1] = useState(false);
    const navigate = useNavigate();

    const handleClick = (e) => {
        if (e === 'services') {
            setOpenL1(!openL1);
        } else {
            navigate(`/${e}/`);
            setShow(false);
        }
    };
    return (
        <LeftSideModal show={show} setShow={setShow}>
            <div className="left-modal-header text-left">
                <img
                    src={icons.iconBack}
                    alt="Back"
                    onClick={() => {
                        setShow(false);
                    }}
                    role="button"
                />
            </div>

            <div className="left-modal-body text-left">
                <UserArea currentUser={currentUser} makeAction={makeAction} />

                <img src={icons.imgHr} alt="HR" className="hr" />

                <ul className="menu-list option-list">
                    {publicMenu.map((item) => (
                        <li key={item.id}>
                            <div
                                role="button"
                                tabIndex={-1}
                                onClick={() => {
                                    handleClick(item.id);
                                }}
                                className="line-row-between"
                            >
                                <div className="line-align line-no-wrap ml-5">
                                    <img className="menu-logo" src={item.icon} alt="" />
                                    <span className="menu-title ml-3">{item.title}</span>
                                </div>
                                {item.subMenu?.length ? (
                                    <div className="sidebar-arrow-icon">
                                        <img
                                            style={{
                                                transform: openL1 ? 'rotateZ(90deg)' : ''
                                            }}
                                            className="w-100 h-100"
                                            src={arrowIcons.iconArrowRightV1}
                                            alt=""
                                        />
                                    </div>
                                ) : null}
                            </div>
                            {item?.subMenu ? <SubmenuList item={item} openL1={openL1} /> : null}
                        </li>
                    ))}
                </ul>

                <MenuList currentUser={currentUser} setShow={setShow} signOut={signOut} />
            </div>
        </LeftSideModal>
    );
}

function MobileSidebarMenu() {
    const { currentUser, makeAction, signOut } = useAuth();
    const [show, setShow] = useState(false);

    return (
        <div className="text-md-right line">
            <span className="menu-button d-block">
                <img
                    src={icons.iconMenu}
                    alt="Menu Icon"
                    onClick={() => {
                        setShow(true);
                    }}
                    role="button"
                    className="d-block"
                />
            </span>

            <SideBarModal
                show={show}
                setShow={setShow}
                currentUser={currentUser}
                signOut={signOut}
                makeAction={makeAction}
            />
        </div>
    );
}

export default MobileSidebarMenu;
