/* eslint-disable jsx-a11y/label-has-associated-control */
import PasswordEditInput from './PasswordEditInput';
import PasswordEditSuggestion from './PasswordEditSuggestion';

function PasswordEditArea({ isVisible }) {
    return (
        <div className={`password-edit-area ${isVisible ? 'show' : ''}`}>
            <div className="body">
                <div className="line line-no-wrap line-md-wrap">
                    <PasswordEditInput />
                    <PasswordEditSuggestion />
                </div>
            </div>
        </div>
    );
}

export default PasswordEditArea;
