/* eslint-disable no-unused-vars */
import { createContext, useContext, useEffect, useState } from 'react';
import { useInit } from '../../contexts/InitProvider';
import AxiosAuth from '../../utils/AxiosAuth';
import { getDomainInfo } from './models/ModelSearch';

const SearchPageContext = createContext();

export function useSearchPage() {
    return useContext(SearchPageContext);
}

function SearchPageProvider({ children, searchText, pageNo, type }) {
    // From Init Data
    const { locale, tlds } = useInit();
    const currency = locale?.currency;

    // { isDomain, isListedTld, tld, domainTxt, subdomainTxt, hostname }
    const [domainInfo, setDomainInfo] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const [searchResults, setSearchResults] = useState([]);

    // --Load Domain Info
    useEffect(() => {
        // data : { isDomain, isListedTld, tld, domainTxt, subdomainTxt, hostname, domain }
        const { data: dData } = getDomainInfo(searchText, tlds);

        if (dData.isDomain) {
            // setDomainInfo(null);
            AxiosAuth.setApiUrl('SEARCH', '/get-domain-info/')
                .post(dData)
                .then(({ data }) => {
                    setDomainInfo({
                        ...dData,
                        productInfo: data?.productInfo || null
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [searchText, tlds]);

    // --Load Suggested Domain Info
    // useEffect(() => {
    //     if (dData.isDomain) {
    //         AxiosAuth.setApiUrl('SEARCH', '/get-domain-suggested/')
    //             .get({ params: { q: searchText } })
    //             .then(({ data }) => {
    //                 if (data.error === 0) {
    //                     setSuggestions(data?.data || []);
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //             });
    //     }
    // }, [dData.isDomain, searchText]);

    // // --Load Search Result
    // useEffect(() => {
    //     if (dData.isDomain) {
    //         AxiosAuth.setApiUrl('SEARCH', '/get-search-info/')
    //             .get({ params: { q: searchText, 'page-no': pageNo, type } })
    //             .then(({ data }) => {
    //                 if (data.error === 0) {
    //                     setSearchResults(data?.data || []);
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //             });
    //     }
    // }, [dData.isDomain, pageNo, searchText, type]);

    const value = { domainInfo, suggestions, searchResults };

    return <SearchPageContext.Provider value={value}>{children}</SearchPageContext.Provider>;
}

export default SearchPageProvider;
