import { showUnitDuration } from '../../../websites/models/Pricing';
import roleIconPicker from '../../_models/RoleIconPicker';
import TableActionBtn from './TableActionBtn';

function DomainTd({ item, setModal }) {
    if (item?.isDemo) {
        return (
            <>
                <span className="fw-500 gap text-dotted">-------</span>
            </>
        );
    }
    if (item?.domain?.length === 0) {
        return (
            <div>
                <span className="fw-500">{item?.domain || '[Domain Not Set]'}</span>
                <br />
                <span
                    className="font-12 purple opacity-100"
                    role="button"
                    tabIndex={-1}
                    onClick={() => setModal({ type: 'cp-wizard', data: item })}
                >
                    Create cPanel
                </span>
            </div>
        );
    }
    return <span className="fw-500">{item?.domain?.toUpperCase()}</span>;
}

const Body = ({ arr, setModal, modal }) => {
    if (!arr?.length) {
        return (
            <tbody>
                <tr>
                    <td colSpan={100}>
                        <span className="font-16 opacity-medium">No Hosting found !</span>
                    </td>
                </tr>
            </tbody>
        );
    }
    return (
        <tbody>
            {arr
                ?.sort?.((a, b) => {
                    if (a.domain && !b.domain) {
                        return -1;
                    }
                    if (!a.domain && b.domain) {
                        return 1;
                    }
                    return 0;
                })
                ?.map((item) => (
                    <tr key={item.id} className={item?.isDemo ? 'placeholder-mode' : ''}>
                        <td className="opacity-medium">
                            <span className="">{item?.id}</span>
                        </td>
                        <td>
                            <div className="line-align line-no-wrap">
                                {roleIconPicker(item)}
                                <span className="fw-500 d-block gap">{item?.title}</span>
                            </div>
                            <span className="value">{`Since ${item?.createdOn}`}</span>
                        </td>

                        <td>
                            <span className="fw-500 d-block gap">
                                USD {item?.priceOffer?.toFixed(2)}
                            </span>

                            <span className="value">/{showUnitDuration(item?.duration)}</span>
                        </td>

                        <td>
                            <DomainTd setModal={setModal} modal={modal} item={item} />
                        </td>
                        <td>
                            <span className="fw-500 d-block gap">{item?.expiredOn}</span>
                            <span className="value">{item?.expiredDays} Days Left</span>
                        </td>
                        <td>
                            <TableActionBtn item={item} setModal={setModal} modal={modal} />
                        </td>
                    </tr>
                ))}
        </tbody>
    );
};

const HostingListTableWeb = ({ arr, modal, setModal }) => (
    // if (hostingData?.length === 0) {
    //     return <HostingPagePlaceholder hName="Web" />;
    // }

    <table className="table-list md-fon hosting-list-table" cellPadding={0} cellSpacing={0}>
        <thead>
            <tr>
                <th width="10%">ID</th>
                <th width="27.5%">Hosting Name</th>

                <th width="10%">Renew</th>
                <th width="27.5%">Domain</th>
                <th width="15%">Expired on</th>
                <th width="10%">Action</th>
            </tr>
        </thead>
        <Body arr={arr} modal={modal} setModal={setModal} />
    </table>
);
export default HostingListTableWeb;
