import React from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import SectionDivider from '../_models/section_divider/SectionDivider';
import DnsProvider from './DnsProvider';
import DnsListSection from './dns_list/DnsListSection';

function DnsArea({ domain }) {
    return (
        <div className="dns-area">
            <div className="product-info mb-4">
                <SectionDivider title="DNS Manage" />

                <DnsListSection domain={domain} />
            </div>
        </div>
    );
}

function DnsSection({ tbl, domain }) {
    const { contractId } = useParams();
    const { currentUser } = useAuth();

    return (
        <DnsProvider tbl={tbl} contractId={contractId} currentUser={currentUser}>
            <DnsArea domain={domain} />
        </DnsProvider>
    );
}

export default DnsSection;
