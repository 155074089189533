import React, { useEffect } from 'react';

import { ButtonGr } from '../../../../configs/Buttons';
import PageProperties from '../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../headers/DashboardHeaderSection';
import ProfileListTable from './profile_list/ProfileListTable';

function DomainProfilePage() {
    useEffect(() => {
        PageProperties(`Domain Profile \u2022 Profile`);
    }, []);

    return (
        <div className="cell cell-content h-100 w-100">
            <div className="content-section w-100">
                <div className="content-area w-100">
                    <DashboardDefaultHeader
                        title="Domain Profile"
                        path="/user/basic-info/"
                        content={
                            <span className="line justify-end ">
                                <ButtonGr evOnClick={null} title="Add New Profile" />
                            </span>
                        }
                        mobileContent={<ButtonGr evOnClick={null} title="Add New Profile" />}
                    />
                    <div
                        className="content-body-scroll info-area w-100"
                        style={{ paddingBottom: '180px' }}
                    >
                        <ProfileListTable />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DomainProfilePage;
