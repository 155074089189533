/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { ButtonClose, ButtonGr } from '../../../../../configs/Buttons';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import { useOtp } from '../../../verification_otp/VerificationOtpProvider';
import { useHostingInfoBasic } from '../HostingInfoBasicProvider';

const ModalHeader = ({ handleCloseClick, domain }) => (
    <div className="modal-box-header border-bottom">
        <div>
            <div className="modal-box-title sub-title">
                <span>Suspend cPanel Wizard</span>
                <span>
                    <small>{domain?.toUpperCase()}</small>
                </span>
            </div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={handleCloseClick} />
            </div>
        </div>
    </div>
);

function Checkbox({ formData, setFormData, email }) {
    const handleAgreement1 = () => {
        setFormData((cuData) => ({
            ...cuData,
            isBackedUp: !formData?.isBackedUp
        }));
    };

    const handleAgreement2 = () => {
        setFormData((cuData) => ({
            ...cuData,
            isAgree: !formData?.isAgree
        }));
    };

    return (
        <div className="mb-3">
            <div
                className="line line-no-wrap mb-1"
                role="button"
                tabIndex={-1}
                onClick={handleAgreement1}
            >
                <span>
                    <input
                        checked={formData?.isBackedUp}
                        readOnly
                        type="checkbox"
                        name="backup_data"
                        id=""
                    />
                </span>{' '}
                <span className="font-14 ml-1">
                    I acknowledge the suspension of cPanel will disable services temporarily.
                </span>
            </div>
            <div
                className="line line-no-wrap"
                role="button"
                tabIndex={-1}
                onClick={handleAgreement2}
            >
                <span>
                    <input
                        checked={formData?.isAgree}
                        readOnly
                        type="checkbox"
                        name="cp_data"
                        id=""
                    />
                </span>{' '}
                <span className="font-14 ml-1">I agree to proceed with the suspension.</span>
            </div>
            <div className="line line-no-wrap" role="button" tabIndex={-1}>
                <span>
                    <input checked readOnly disabled type="checkbox" name="cp_data" id="" />
                </span>{' '}
                <span className="font-14 ml-1">
                    To suspend the cPanel You have to verify the email ({email}).
                </span>
            </div>
        </div>
    );
}

function InputView({ formData, setFormData }) {
    const handelDomainInput = (ev) => {
        setFormData((cuData) => ({
            ...cuData,
            domain: ev.target.value
        }));
    };

    const handelReasonInput = (ev) => {
        setFormData((cuData) => ({
            ...cuData,
            reason: ev.target.value
        }));
    };

    return (
        <div className="mb-3">
            <div className="mb-2">
                <label htmlFor="domain_name" className="label">
                    Domain/Sub domain
                </label>
                <input
                    onChange={handelDomainInput}
                    value={formData?.domain}
                    placeholder="domain-name.com"
                    type="text"
                    className="form-input w-100"
                    autoComplete="off"
                    // readOnly={formData?.otp}
                />
            </div>
            <div className="">
                <label htmlFor="reason" className="label">
                    Reason
                </label>
                <input
                    onChange={handelReasonInput}
                    value={formData?.reason || ''}
                    placeholder="Describe the reason for suspending this account......"
                    type="text"
                    className="form-input w-100"
                    autoComplete="off"
                    // readOnly={formData?.otp}
                />
            </div>
        </div>
    );
}

function ModalBody({ contractInfo, handleCloseClick }) {
    const { cpSuspend } = useHostingInfoBasic();
    const { setMessage } = useTemplate();
    const { sendOtp, closeOtpWindow } = useOtp();
    const [formData, setFormData] = useState({
        domain: '',
        isAgree: false,
        isBackedUp: false
    });

    const handelSuspendBtn = () => {
        setMessage('Suspending cPanel...');

        sendOtp(contractInfo.email, ({ sentId, otp }) => {
            // domain, vSentId, vOTP
            cpSuspend(formData.domain?.toLowerCase(), formData.reason, sentId, otp)
                .then(({ error, message }) => {
                    console.log(error);
                    if (error === 0) {
                        closeOtpWindow();
                        handleCloseClick();
                    }

                    setMessage(message);
                })
                .catch((err) => {
                    setMessage(err);
                });
        });
    };

    const isSubmitDisabled = !(
        formData?.domain?.toLowerCase() === contractInfo.domain?.toLowerCase() &&
        formData.isAgree &&
        formData.isBackedUp
    );

    return (
        <div className="modal-body-area">
            <InputView formData={formData} setFormData={setFormData} />

            <Checkbox setFormData={setFormData} formData={formData} email={contractInfo?.email} />

            <div className="line justify-end">
                <ButtonGr
                    evOnClick={handelSuspendBtn}
                    title="Suspend"
                    disabled={isSubmitDisabled}
                    modal
                />
            </div>
        </div>
    );
}

function BasicinfoDzoneSuspendPopup({ setModal, show, data }) {
    const handleCloseClick = () => setModal(null);

    if (!show) {
        return null;
    }

    return (
        <div className="modal-box backdrop remove-wizard-area">
            <div className="modal-box-content">
                <ModalHeader handleCloseClick={handleCloseClick} domain={data?.domain} />
                <ModalBody handleCloseClick={handleCloseClick} contractInfo={data} />
            </div>
        </div>
    );
}

export default BasicinfoDzoneSuspendPopup;
