import { useState } from 'react';
import { icons } from '../../../../../configs/Icons';
import BasicInfoDzoneSuspendPopup from './BasicInfoDzoneSuspendPopup1';

function BasicInfoDzoneSuspend({ disable, basicInfo }) {
    const [modal, setModal] = useState(false);

    if (disable && basicInfo) {
        return null;
    }

    return (
        <>
            <div className="cell cell-4 cell-md-fill cursor-pointer mb-md-3">
                <div
                    className="line line-g1 line-no-wrap"
                    role="button"
                    tabIndex={-1}
                    onClick={() => setModal(!modal)}
                >
                    <div className="cell ">
                        <div className="danger-zone-icon">
                            <span className="w-100 d-block">
                                <img className="d-block" src={icons.iconPauseRed} alt="" />
                            </span>
                        </div>
                    </div>
                    <div className="cell">
                        <div className="">
                            <span className="fs-14 fw-500 red">Suspend cPanel</span>
                            <span className="value">
                                cPanel services will be unavailable temporarily. Backup data
                                beforehand.
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* popup */}
            <BasicInfoDzoneSuspendPopup show={modal} setModal={setModal} data={basicInfo} />
        </>
    );
}

export default BasicInfoDzoneSuspend;
