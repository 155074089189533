/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalBox from '../../../../../utils/ModalBox';
import RadioButton from '../../../../../utils/RadioButton';

const smsData = [
    {
        id: 1,
        title: 'Non-Masking',
        quantity: '0.25tk - 0.35tk'
    },
    {
        id: 2,
        title: 'Masking',
        quantity: '0.25tk - 0.35tk'
    },
    {
        id: 3,
        title: 'Device',
        quantity: '0.25tk - 0.35tk'
    }
];

function MaskingCharacterView({ title, value, inputVal }) {
    if (inputVal !== 'non-masking') {
        return (
            <div
                className="line line-no-wrap mb-2"
                style={{ filter: 'grayscale(100%)', cursor: 'default' }}
            >
                <span className="line mr-1">
                    <input disabled type="checkbox" name={value} />
                </span>
                <span className="text-sm-left">{title}</span>
            </div>
        );
    }
    return (
        <div className="line line-no-wrap mb-2">
            <span className="line mr-1">
                <input type="checkbox" name={value} />
            </span>
            <span className="text-sm-left">{title}</span>
        </div>
    );
}
function SmsAppView({ title, value, inputVal }) {
    if (inputVal !== 'device') {
        return (
            <div
                className="line line-no-wrap mb-2"
                style={{ filter: 'grayscale(100%)', cursor: 'default' }}
            >
                <span className="line mr-1">
                    <input disabled type="checkbox" name={value} />
                </span>
                <span className="text-sm-left">{title}</span>
            </div>
        );
    }
    return (
        <div className="line line-no-wrap mb-2">
            <span className="line mr-1">
                <input type="checkbox" name={value} />
            </span>
            <span className="text-sm-left">{title}</span>
        </div>
    );
}

function AgreementLetterView({ inputVal }) {
    if (inputVal.length === 0) {
        return (
            <div className="line-align disabled-view">
                <span className="line mr-1">
                    <input disabled type="checkbox" name="terms" />
                </span>
                <span className="">
                    <span>Agreement Letter</span>
                </span>
            </div>
        );
    }
    return (
        <div className="line-align">
            <span className="line mr-1">
                <input type="checkbox" name="terms" />
            </span>
            <span className="">
                <Link to="/">Agreement Letter--</Link>
            </span>
        </div>
    );
}

function TermsAndConditonView({ inputVal }) {
    if (inputVal.length === 0) {
        return (
            <div className="line-align mb-2 disabled-view">
                <span className="line mr-1">
                    <input disabled type="checkbox" name="terms" />
                </span>
                <span className="">
                    I Agreed all the <span>Term & Condition</span>--
                </span>
            </div>
        );
    }
    return (
        <div className="line-align mb-2">
            <span className="line mr-1">
                <input type="checkbox" name="terms" />
            </span>
            <span className="">
                I Agreed all the <Link to="/">Term & Condition</Link>--
            </span>
        </div>
    );
}

function TradeLincenceView({ inputVal }) {
    if (inputVal !== 'non-masking') {
        return (
            <div
                className="line-align mr-4"
                style={{ filter: 'grayscale(100%)', cursor: 'default' }}
            >
                <span className="line mr-1">
                    <input disabled type="checkbox" name="terms" />
                </span>
                <span className="">Trade Licence</span>
            </div>
        );
    }
    return (
        <div className="line-align mr-4">
            <span className="line mr-1">
                <input type="checkbox" name="terms" />
            </span>
            <span className="">Trade Licence</span>
        </div>
    );
}

function CategoryItem({ data, setInputVal, inputVal }) {
    return (
        <div
            role="button"
            tabIndex={-1}
            onClick={() => setInputVal(data?.title?.toLowerCase())}
            className="line line-no-wrap"
        >
            <span className="mt-1">
                <RadioButton checked={inputVal === data?.title?.toLowerCase()} />
            </span>
            <div className="text-left ml-2">
                <span className="fs-16 fw-500">{data?.title}</span>
                <span className="opacity-medium font-12 d-block">{data?.quantity}</span>
            </div>
        </div>
    );
}

function SmsCatagory({ setInputVal, inputVal }) {
    return (
        <div className="line w-100 mb-2">
            <div className="line-row-between w-100">
                {smsData?.map((data) => (
                    <CategoryItem
                        key={data?.id}
                        data={data}
                        setInputVal={setInputVal}
                        inputVal={inputVal}
                    />
                ))}
            </div>
        </div>
    );
}
function InputArea({ setQuantity, quantity }) {
    return (
        <div className="line-align line-g3 text-left mb-2 input-area">
            <div className="input-box cell cell-7">
                <label htmlFor="quantity">Quantity</label>
                <br />
                <input
                    onChange={(e) => setQuantity(e.target.value)}
                    className="w-100"
                    type="number"
                    name="quantity"
                    placeholder="Ex: 100,000"
                />
            </div>
            <div className="input-box cell cell-5 w-100 ammount-bdt">
                <label htmlFor="ammount">Amount (BDT)</label>
                <br />
                <input
                    disabled
                    value={`BDT ${quantity * 0.25}`}
                    className="w-100"
                    type="text"
                    name="ammount"
                    placeholder="BDT 25,000"
                />
            </div>
        </div>
    );
}

function CalculationArea({ quantity }) {
    return (
        <div className="line-align mb-4 calc-area">
            <div className="cell-5" />
            <div className="cell-7">
                <div className="line-align">
                    <span className="cell-6 title calc-text fw-500 text-left">Discount :</span>
                    <span className="cell-6 calc-text fw-400 text-right no-wrap">- BDT 0 (0%)</span>
                </div>
                <div className="line-align">
                    <span className="cell-6 title calc-text fw-500 text-left">Final Amount:</span>
                    <span className="cell-6 calc-text fw-500 text-right no-wrap">
                        BDT {quantity * 0.25}
                    </span>
                </div>
            </div>
        </div>
    );
}
function EnrollAgreementArea({ inputVal }) {
    return (
        <div className="enroll-agreement-area">
            <TermsAndConditonView inputVal={inputVal} />
            <div className="line-align mb-2">
                <TradeLincenceView inputVal={inputVal} />
                <AgreementLetterView inputVal={inputVal} />
            </div>
            <MaskingCharacterView
                inputVal={inputVal}
                title="Masking Name must be Alphanumeric & Maximum 11 Character"
                value="character"
            />

            <SmsAppView
                inputVal={inputVal}
                title="Install our SMS App on your Mobile If choose Device Package"
                value="app"
            />
        </div>
    );
}

function ModalBody() {
    const [inputVal, setInputVal] = useState('');
    const [quantity, setQuantity] = useState(0);

    return (
        <div className="sms-package-modal-area">
            <SmsCatagory setInputVal={setInputVal} inputVal={inputVal} />
            <InputArea setQuantity={setQuantity} quantity={quantity} />
            <CalculationArea setQuantity={setQuantity} quantity={quantity} />
            <EnrollAgreementArea inputVal={inputVal} />
            <button type="button" className="button-solid-purple w-50 mt-2">
                Enroll Package
            </button>
        </div>
    );
}

function CustomSmsPackageModal({ setOpenModal }) {
    const handleCloseClick = () => setOpenModal(null);
    return (
        <div className="sms-custom-package-area">
            <ModalBox
                style={{ width: '90%' }}
                show
                handleCloseClick={handleCloseClick}
                header={
                    <div className="ml-5">
                        <span className="mt-5 title">Custom Package</span>
                    </div>
                }
                body={<ModalBody handleCloseClick={handleCloseClick} />}
            />
        </div>
    );
}

export default CustomSmsPackageModal;
