import LayoutSelector from '../../../../utils/LayoutSelector';
import InvoiceInfoTableMobile from './InvoiceInfoTableMobile';
import InvoiceInfoTableWeb from './InvoiceInfoTableWeb';

function InvoiceInfoList({ invoiceData, setInvoiceData, items, total, discount, paid, payable }) {
    return (
        <div className={!invoiceData ? 'placeholder-mode' : ''}>
            <LayoutSelector
                sm
                md
                webComp={
                    <InvoiceInfoTableWeb
                        invoiceData={invoiceData}
                        setInvoiceData={setInvoiceData}
                        items={items}
                        total={total}
                        discount={discount}
                        paid={paid}
                        payable={payable}
                    />
                }
                mobileComp={
                    <InvoiceInfoTableMobile
                        invoiceData={invoiceData}
                        setInvoiceData={setInvoiceData}
                        items={items}
                        total={total}
                        discount={discount}
                        paid={paid}
                        payable={payable}
                    />
                }
            />
        </div>
    );
}

function InvoiceInfoTable({ invoiceData, setInvoiceData, items, total, discount, paid, payable }) {
    // console.log(items);

    // if (!items || items.length === 0) {
    //     return null;
    // }

    return (
        <>
            <InvoiceInfoList
                invoiceData={invoiceData}
                setInvoiceData={setInvoiceData}
                items={items}
                total={total}
                discount={discount}
                paid={paid}
                payable={payable}
            />
            <table
                className={`invoice-products ${!invoiceData ? 'placeholder-mode' : ''}`}
                cellSpacing={0}
            >
                <tfoot>
                    <tr>
                        <td colSpan={4} className="total-row">
                            <div className="invoice-summery">
                                <div className="line">
                                    <div className="cell cell-fill">Total:</div>
                                    <div className="cell">
                                        <span>{total?.toFixed(2) || '----------'}</span>
                                    </div>
                                </div>

                                <div className="line">
                                    <div className="cell cell-fill">Discount:</div>
                                    <div className="cell">
                                        <span>- {discount?.toFixed(2) || '----------'}</span>
                                    </div>
                                </div>
                                <div className="line">
                                    <div className="cell cell-fill">Paid:</div>
                                    <div className="cell">
                                        <span>- {paid?.toFixed(2) || '----------'}</span>
                                    </div>
                                </div>
                                <div className="line">
                                    <div className="cell cell-fill">Payable:</div>
                                    <div className="cell">
                                        <span>{payable?.toFixed(2) || '----------'}</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </>
    );
}

export default InvoiceInfoTable;
