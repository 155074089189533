import { useState } from 'react';
import { ButtonOutlinePink, ButtonSolidPink } from '../../../../../configs/Buttons';
import { GetDate, getDayDiff } from '../../../../utils/Show';
import MessageModal from '../../../system/modals/MessageModal';
import CopyText from '../../../websites/models/CopyText';
import { ShowPriceWithDuration } from '../../../websites/models/Pricing';
import { useDomainInfo } from '../../domains_info/DomainInfoBasicProvider';

function Divider({ title, action }) {
    return (
        <div className="line align-end line-g1 mb-4">
            <div className="cell">
                <span className="divider-title">{title}</span>
            </div>
            <div className="cell cell-fill">
                <hr className="hr mb-1" />
            </div>
            {action && <div className="cell">{action}</div>}
        </div>
    );
}

function CommonRowView({ title, value }) {
    return (
        <div className="line-align mb-2">
            <div className="cell cell-5">
                <span className="subject ">{title}:</span>
            </div>
            <div className="cell cell-7">
                <span className="value">{value || '--'}</span>
            </div>
        </div>
    );
}

function RenewalDate({ timeExpire, isExpired }) {
    const days = getDayDiff(timeExpire);

    const daysRemain = isExpired ? 'Expired' : `${days} Days Left`;

    return (
        <div className="">
            <span className="value d-block gap">{GetDate(timeExpire) || '-----------'}</span>
            <span className="font-12 opacity-medium">{daysRemain}</span>
        </div>
    );
}

function BasicInfoBillingSection() {
    const [modal, setModal] = useState(false);
    const { contractId, domain, timeIssue, timeExpire, price, isExpired } = useDomainInfo();
    return (
        <div className={`product-info mb-4 ${!domain ? 'placeholder-mode' : ''}`}>
            <Divider
                title="Billings"
                action={<ButtonSolidPink evOnClick={() => setModal(!modal)} title="Renew" />}
            />
            <MessageModal show={modal} setModal={setModal} />

            <div className="line line-g3">
                <div className="cell cell-6 cell-md-12">
                    <CommonRowView
                        title="Domain Name"
                        value={<CopyText value={domain} /> || '[Domain not set]'}
                    />
                    <CommonRowView
                        title="Title"
                        value={domain ? `.${domain?.split('.')[1]}` : '--'}
                    />
                    <CommonRowView
                        title="Contract ID: "
                        value={<CopyText value={`#${contractId}`} />}
                    />
                </div>

                <div className="cell cell-6 cell-md-12 ">
                    <CommonRowView title="Created On" value={GetDate(timeIssue)} />
                    <div className="line-align mb-2">
                        <div className="cell cell-5">
                            <span className="subject">Renewal Date:</span>
                        </div>
                        <div className="cell cell-7 overflow-dotted">
                            <span className="value ">
                                <RenewalDate timeExpire={timeExpire} isExpired={isExpired} />
                            </span>
                        </div>
                    </div>

                    <CommonRowView
                        title="Package Price"
                        value={ShowPriceWithDuration(price * 12)}
                    />
                    {/* <CommonRowView title="Created On" value={GetDate(timeIssue)} />
                    <CommonRowView title="Renewal Date" value={GetDate(timeExpire)} />
                    <CommonRowView title="Billing Status" value="--" />
                    <CommonRowView title="Package Price" value={(price * 12).toFixed(2)} /> */}
                </div>
            </div>

            <ButtonOutlinePink evOnClick={() => setModal(!modal)} title="View Invoices" />
        </div>
    );
}

export default BasicInfoBillingSection;
