/* eslint-disable jsx-a11y/label-has-associated-control */
import { ButtonClose, ButtonGr, ButtonOutlinePink } from '../../../../../configs/Buttons';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import { UpperCase } from '../../../../utils/Show';
import { useRole } from '../RoleProvider';

const roles = [
    { id: 'manager', title: 'Manager' },
    { id: 'developer', title: 'Developer' }
];

const ModalHeader = ({ handleCloseClick }) => (
    <div className="modal-box-header border-bottom">
        <div>
            <div className="modal-box-title">
                <span>Choose Role</span>
            </div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={handleCloseClick} />
            </div>
        </div>
    </div>
);

function RoleSelectionField({ setFormData, data }) {
    const handelRoleValue = (ev) => {
        setFormData((cu) => ({ ...cu, role: ev.target.value }));
    };
    return (
        <div className="w-100 mb-3">
            <label htmlFor="role" className="label">
                Select Role:
            </label>
            <select className="form-input w-100" name="roles" onChange={handelRoleValue}>
                <option hidden defaultChecked value={data?.roleKey || 'select'}>
                    {UpperCase(data?.roleKey) || 'select'}
                </option>
                {roles?.map((item) => (
                    <option key={item?.id} value={item?.id || ''}>
                        {item?.title}
                    </option>
                ))}
            </select>
        </div>
    );
}

function ModalBody({ handleCloseClick, data }) {
    const { setFormData, formData, handelChangeRole, handelReload } = useRole();

    const { setMessage } = useTemplate();

    const hanldelCancelButton = () => {
        handleCloseClick();
    };

    const handelModifyButton = () => {
        // console.log(formData);
        setMessage('Changing Role....');
        handelChangeRole(data?.roleId, formData?.role).then(({ error, message }) => {
            if (error === 0) {
                handleCloseClick();
                handelReload();
                setMessage(message);
            }
            setMessage(message);
        });
    };

    // console.log(formData);
    return (
        <div className="modal-body-area">
            <form>
                <RoleSelectionField data={data} setFormData={setFormData} />
                <div className="mt-4 line-align justify-end w-100">
                    <ButtonOutlinePink title="Cancel" evOnClick={hanldelCancelButton} modal />
                    <span className="ml-2">
                        <ButtonGr
                            evOnClick={handelModifyButton}
                            disabled={!formData?.role}
                            title="save"
                            modal
                        />
                    </span>
                </div>
            </form>
        </div>
    );
}

function RoleModifyPopup({ setModal, show, modal }) {
    const { setFormData } = useRole();
    const handleCloseClick = () => setModal({ type: null }) + setFormData({});
    if (!show) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content" style={{ width: '400px', overflow: 'unset' }}>
                <ModalHeader handleCloseClick={handleCloseClick} />
                <ModalBody handleCloseClick={handleCloseClick} data={modal?.data} />
            </div>
        </div>
    );
}

export default RoleModifyPopup;
