import TwoFacAuthBackupApp from '../twofacauth_backup_app/TwoFacAuthBackupApp';
import TwoFacAuthBackupCodes from '../twofacauth_backup_codes/TwoFacAuthBackupCodes';
import TwoFacAuthBackupKey from '../twofacauth_backup_key/TwoFacAuthBackupKey';

function ListHead() {
    return (
        <div className="info-list-header ">
            <div className="d-block">
                <span className="fs-16 fw-500 no-wrap opacity-medium">Backup Method</span>
            </div>
        </div>
    );
}

function TwoFacAuthBackupSection() {
    return (
        <div>
            <ListHead />
            <TwoFacAuthBackupApp />
            <TwoFacAuthBackupKey />
            <TwoFacAuthBackupCodes />
        </div>
    );
}

export default TwoFacAuthBackupSection;
