import { useEffect } from 'react';
import PageProperties from '../../../utils/PageProperties';
import HeaderSection from '../../headers/HeaderSection';
import FooterSection2 from '../../system/section/Footer2Section';
import MobileFooter from '../../users/components/MobileFooter';
import FixedHeaderControl from '../components/FixedHeaderControl';
import SupportCategorySection from '../components/support-page/SupportCategorySection';
import SupportFaqArea from '../components/support-page/SupportFaqArea';

function SupportPage() {
    useEffect(() => {
        PageProperties(`Support`);
    }, []);

    return (
        <>
            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>
            <SupportCategorySection />
            <SupportFaqArea />
            {/* <ForumTopicSection /> */}
            {/* <ClientSection /> */}
            <FooterSection2 />
            <MobileFooter />
        </>
    );
}

export default SupportPage;
