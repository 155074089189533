import React, { useEffect } from 'react';

import { useAuth } from '../../../contexts/AuthProvider';
import LayoutSelector from '../../../utils/LayoutSelector';
import PageProperties from '../../../utils/PageProperties';
import { DashboardArrowHeader } from '../../headers/DashboardHeaderSection';
import ProfileActivitySection from './profile_activity/ProfileActivitySection';
import ProfileDeveloperSection from './profile_developer/ProfileDeveloperSection';
import ProfileHeader from './profile_header/ProfileHeader';
import ProfileInfoSection from './profile_info/ProfileInfoSection';
import ProfileServicesSection from './profile_services/ProfileServicesSection';

function ProfileOverviewPage() {
    const { currentUser } = useAuth();
    useEffect(() => {
        PageProperties('Profile Overview');
    }, []);

    return (
        <>
            <LayoutSelector
                sm
                md
                lg
                mobileComp={<DashboardArrowHeader title="Profile Overview" path={-1} />}
            />

            <div
                className="content-body-scroll user-profile-overview-area"
                style={{ paddingBottom: '120px' }}
            >
                <ProfileHeader data={currentUser} />
                <ProfileActivitySection />
                <ProfileInfoSection currentUser={currentUser} info={currentUser} />
                <ProfileServicesSection />
                <ProfileDeveloperSection />
            </div>
        </>
    );
}

export default ProfileOverviewPage;
