import React from 'react';
import { Link } from 'react-router-dom';

// const options = [
//     {
//         id: 1,
//         title: 'Domain',
//         path: '/'
//     },
//     {
//         id: 2,
//         title: 'Hosting',
//         path: '/'
//     },
//     {
//         id: 3,
//         title: 'Server',
//         path: '/'
//     },
//     {
//         id: 4,
//         title: 'Software Service',
//         path: '/'
//     },
//     {
//         id: 5,
//         title: 'SMS ',
//         path: '/'
//     }
// ];

function RoleInvitationFooter() {
    return (
        <div className="invitation-footer-section d-sm-none">
            <div className="container mt-5 mb-5 mt-md-4 mb-md-4">
                <div className="line">
                    <div className="cell cell-6 cell-lg-12 cell-self-middle">
                        <div className="footer-service-menu menu">
                            <div className="line-align line-no-wrap">
                                <Link to="/domain/">Domain</Link>{' '}
                                <span className="dot">&bull;</span>
                                <Link to="/services/hosting/web/">Hosting</Link>{' '}
                                <span className="dot">&bull;</span>
                                <Link to="/services/server/">Server</Link>{' '}
                                <span className="dot">&bull;</span>
                                <Link to="/services/software/">Software Service</Link>{' '}
                                <span className="dot">&bull;</span>
                                <Link to="/services/marketing/sms-service/">SMS</Link>
                            </div>
                        </div>
                    </div>
                    <div className="cell cell-6 cell-lg-12 cell-self-middle">
                        <div className="footer-service-menu link">
                            <Link to="/legal/privacy-policy/">Privacy & Policy</Link>{' '}
                            <span className="dot">&bull;</span>
                            <Link to=".">Terms & Condition</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RoleInvitationFooter;
