/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { ButtonClose, ButtonGr } from '../../../../../configs/Buttons';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import InputCopy from '../../../../utils/inputs/InputCopy';
import { mkStrongPassword } from '../../../../utils/StringOperation';
import CopyText from '../../../websites/models/CopyText';
import { useHostingInfoBasic } from '../HostingInfoBasicProvider';

const ModalHeader = ({ handleCloseClick, domain }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title sub-title">
                <span>Change cPanel Password</span>
                <span>
                    <small>
                        <CopyText copyValue={domain} value={domain?.toUpperCase()} />
                    </small>
                </span>
            </div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={handleCloseClick} />
            </div>
        </div>
    </div>
);

function Checkbox({ setFormData, formData, data }) {
    const handleAgreement1 = () => {
        setFormData((cuData) => ({
            ...cuData,
            checkBox1: !formData?.checkBox1
        }));
    };
    const handleAgreement2 = () => {
        setFormData((cuData) => ({
            ...cuData,
            checkBox2: !formData?.checkBox2
        }));
    };
    const handleAgreement3 = () => {
        setFormData((cuData) => ({
            ...cuData,
            checkBox3: !formData?.checkBox3
        }));
    };

    return (
        <div className="mb-3">
            <div
                className="line-inline line-no-wrap mb-1"
                role="button"
                tabIndex={-1}
                onClick={handleAgreement1}
            >
                <span>
                    <input
                        checked={formData?.checkBox1 || false}
                        readOnly
                        type="checkbox"
                        name="checkBox1"
                        id=""
                    />
                </span>{' '}
                <span className="font-14 ml-1">You will be logged out from the cPanel.</span>
            </div>
            <div
                className="line-inline line-no-wrap"
                role="button"
                tabIndex={-1}
                onClick={handleAgreement2}
            >
                <span>
                    <input
                        checked={formData?.checkBox2 || false}
                        readOnly
                        type="checkbox"
                        name="checkBox2"
                        id=""
                    />
                </span>{' '}
                <span className="font-14 ml-1">
                    The password for your cPanel, FTP, and MySql({data?.username}) will be changed
                    in this cPanel.
                </span>
            </div>
            <div
                className="line-inline line-no-wrap"
                role="button"
                tabIndex={-1}
                onClick={handleAgreement3}
            >
                <span>
                    <input
                        checked={formData?.checkBox3 || false}
                        readOnly
                        type="checkbox"
                        name="checkBox3"
                        id=""
                    />
                </span>{' '}
                <span className="font-14 ml-1">
                    Your password must include both uppercase and lowercase letters.
                </span>
            </div>
        </div>
    );
}

function InputField({ handelPasswordInput, setFormData, formData }) {
    const handelRegeneratePassword = () => {
        setFormData((cuData) => ({
            ...cuData,
            password: mkStrongPassword(16)
        }));
    };

    return (
        <div className="mb-2">
            <div className="line align-end line-g1 line-no-wrap">
                <div className="cell cell-fill">
                    <InputCopy
                        title="Set Password"
                        placeholder="Type Password"
                        setValue={handelPasswordInput}
                        value={formData.password || ''}
                    />
                </div>
                <div className="cell">
                    <div
                        className="icon-generate-pass"
                        role="button"
                        tabIndex={-1}
                        onClick={handelRegeneratePassword}
                    />
                </div>
            </div>
        </div>
    );
}

function ModalBody({ setModal, data, setMode }) {
    const { formData, setFormData, updateSetPassword } = useHostingInfoBasic();
    const { setMessage } = useTemplate();

    const inputVal = !!formData.password;

    const handelPasswordInput = (ev) => {
        setFormData((cuData) => ({
            ...cuData,
            password: ev.target.value
        }));
    };

    const handelUpdatePassword = () => {
        setMessage('Updating Password...');
        setMode(1);
        updateSetPassword()
            .then(({ error, message }) => {
                setMode(0);
                if (error === 0) {
                    setModal(null);
                } else {
                    setFormData((cuData) => ({
                        ...cuData,
                        password: formData?.password,
                        checkBox1: formData?.checkBox1,
                        checkBox2: formData?.checkBox2,
                        checkBox3: formData?.checkBox3
                    }));
                }
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    return (
        <div className="modal-body-area">
            <div className="line-align" />
            <InputField
                handelPasswordInput={handelPasswordInput}
                setFormData={setFormData}
                formData={formData}
            />
            <Checkbox setFormData={setFormData} formData={formData} data={data || {}} />
            <div className="line justify-end">
                <ButtonGr
                    disabled={
                        !formData?.checkBox1 ||
                        !formData?.checkBox2 ||
                        !formData?.checkBox3 ||
                        !inputVal
                    }
                    evOnClick={handelUpdatePassword}
                    title="Change Password"
                    modal
                />
            </div>
        </div>
    );
}

function BasicInfoPasswordPopup({ show, setModal, data }) {
    const [mode, setMode] = useState(0);

    const { setFormData } = useHostingInfoBasic();

    const handleCloseClick = () => setFormData({}) + setModal(null);

    if (!show) {
        return null;
    }

    return (
        <div className="modal-box backdrop cp-password-wizard">
            <div
                className={`modal-box-content ${mode ? 'loading-block' : ''}`}
                style={{ width: '450px' }}
            >
                <ModalHeader handleCloseClick={handleCloseClick} domain={data?.domain} />

                <ModalBody data={data} setModal={setModal} setMode={setMode} />
            </div>
        </div>
    );
}

export default BasicInfoPasswordPopup;
