import React, { useEffect, useState } from 'react';

import { useAuth } from '../../../../contexts/AuthProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';
import ProfileInfoEmailListPopup from './ProfileInfoEmailListPopup';

function EmailListButton({ contactList, evOnClick }) {
    if (contactList && contactList?.length < 2) {
        return null;
    }
    return (
        <span
            role="button"
            tabIndex={-1}
            onClick={evOnClick}
            className="button-outline-purple counter-button cell text-center ml-2"
        >
            +{contactList?.length}
        </span>
    );
}

function ProfileInfoPersonalEmail({ info }) {
    const [openList, setOpenList] = useState(false);
    const [contactList, setContactList] = useState([]);

    const { currentUser } = useAuth();

    // --load list
    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .setApiUrl('PROFILE', 'contacts/list')
            .get(Math.random())
            .then(({ data }) => {
                if (data.error === 0) {
                    setContactList(data?.data);
                }
            });
    }, [currentUser]);

    return (
        <>
            <div className="line-align">
                <div className="cell cell-5">
                    <span className="title common-value fw-500">Email Address:</span>
                </div>
                <div className="cell-7">
                    <div className="line-row-between line-no-wrap">
                        <div className="cell-fill overflow-dotted ">
                            <span className="value common-value fw-400 ">
                                {info?.email || '--'}
                            </span>
                        </div>
                        <EmailListButton
                            evOnClick={() => setOpenList(!openList)}
                            contactList={contactList}
                        />
                    </div>
                </div>
            </div>
            <ProfileInfoEmailListPopup
                show={openList}
                setOpenList={setOpenList}
                contactList={contactList}
            />
        </>
    );
}

export default ProfileInfoPersonalEmail;
