import { Link } from 'react-router-dom';
import { basic } from '../../../../configs/Images';

const clients = [
    {
        id: 1,
        img: basic.feniCadet,
        title: 'Feni Girls Cadet College',
        services: [
            {
                title: 'Software',
                path: '/services/software/e-commerce/'
            },
            {
                title: 'Hosting',
                path: '/services/hosting/web/'
            }
        ]
    },
    {
        id: 2,
        img: basic.barishalCadet,
        title: 'Barishal Cadet College',
        services: [
            {
                title: 'Software',
                path: '/services/software/e-commerce/'
            },
            {
                title: 'Hosting',
                path: '/services/hosting/web/'
            }
        ]
    },
    {
        id: 3,
        img: basic.beacon,
        title: 'Beacon Pharma',
        services: [
            {
                title: 'Server',
                path: '/services/server/dedicated/'
            }
        ]
    },
    {
        id: 4,
        img: basic.sylhetCadet,
        title: 'Sylhet Cadet College',
        services: [
            {
                title: 'Software',
                path: '/services/software/e-commerce/'
            }
        ]
    },
    {
        id: 5,
        img: basic.janakantho,
        title: 'Daily Janakantho',
        services: [
            {
                title: 'Domain',
                path: '/domain/'
            },
            {
                title: 'Hosting',
                path: '/services/hosting/web/'
            },
            {
                title: 'SMS',
                path: '/services/marketing/sms-service/'
            }
        ]
    },
    {
        id: 6,
        img: basic.emc,
        title: 'EMC',
        services: [
            {
                title: 'Domain',
                path: '/domain/'
            },
            {
                title: 'Hosting',
                path: '/services/hosting/web/'
            },
            {
                title: 'SMS',
                path: '/services/marketing/sms-service/'
            }
        ]
    },
    {
        id: 7,
        img: basic.noaparaTd,
        title: 'Noapara Traders',
        services: [
            {
                title: 'Domain',
                path: '/domain/'
            },
            {
                title: 'Hosting',
                path: '/services/hosting/web/'
            },
            {
                title: 'SMS',
                path: '/services/marketing/sms-service/'
            }
        ]
    },
    {
        id: 8,
        img: basic.demoLogo,
        title: 'Hasan Traders',
        services: [
            {
                title: 'Domain',
                path: '/domain/'
            },
            {
                title: 'Hosting',
                path: '/services/hosting/web/'
            }
        ]
    },
    {
        id: 9,
        img: basic.rupkatha,
        title: 'Rupkatha',
        services: [
            {
                title: 'Domain',
                path: '/domain/'
            },
            {
                title: 'Hosting',
                path: '/services/hosting/web/'
            },
            {
                title: 'SMS',
                path: '/services/marketing/sms-service/'
            }
        ]
    },
    {
        id: 10,
        img: basic.clg,
        title: 'CLG School',
        services: [
            {
                title: 'Software',
                path: '/services/software/e-commerce/'
            },
            {
                title: 'Hosting',
                path: '/services/hosting/web/'
            },
            {
                title: 'Domain',
                path: '/domain/'
            }
        ]
    },
    {
        id: 11,
        img: basic.demoLogo,
        title: 'AMZAD Hossain',
        services: [
            {
                title: 'Software',
                path: '/services/software/e-commerce/'
            },
            {
                title: 'Domain',
                path: '/domain/'
            },
            {
                title: 'Hosting',
                path: '/services/hosting/web/'
            }
        ]
    },
    {
        id: 12,
        img: basic.demoLogo,
        title: 'AMZAD Hossain',
        services: [
            {
                title: 'Software',
                path: '/services/software/e-commerce/'
            },
            {
                title: 'Domain',
                path: '/domain/'
            },
            {
                title: 'Hosting',
                path: '/services/hosting/web/'
            }
        ]
    },
    {
        id: 13,
        img: basic.himaloyCement,
        title: 'Himaloy Cement',
        services: [
            {
                title: 'Domain',
                path: '/domain/'
            },
            {
                title: 'Hosting',
                path: '/services/hosting/web/'
            }
        ]
    },
    {
        id: 14,
        img: basic.magel,
        title: 'MAGEL',
        services: [
            {
                title: 'Software',
                path: '/services/software/e-commerce/'
            },
            {
                title: 'Hosting',
                path: '/services/hosting/web/'
            },
            {
                title: 'Domain',
                path: '/domain/'
            }
        ]
    },
    {
        id: 15,
        img: basic.demoLogo,
        title: 'City Traders',
        services: [
            {
                title: 'Software',
                path: '/services/software/e-commerce/'
            },
            {
                title: 'Hosting',
                path: '/services/hosting/web/'
            },
            {
                title: 'Domain',
                path: '/domain/'
            }
        ]
    },
    {
        id: 16,
        img: basic.bahanno,
        title: 'Bahanno News',
        services: [
            {
                title: 'Domain',
                path: '/domain/'
            },
            {
                title: 'Hosting',
                path: '/services/hosting/web/'
            },
            {
                title: 'SMS',
                path: '/services/marketing/sms-service/'
            }
        ]
    },
    {
        id: 17,
        img: basic.saltNSuger,
        title: 'Salt & Suger',
        services: [
            {
                title: 'Software',
                path: '/services/software/e-commerce/'
            },
            {
                title: 'Hosting',
                path: '/services/hosting/web/'
            },
            {
                title: 'Domain',
                path: '/domain/'
            }
        ]
    },
    {
        id: 18,
        img: basic.callingBell,
        title: 'CallingBell',
        services: [
            {
                title: 'Software',
                path: '/services/software/e-commerce/'
            },
            {
                title: 'Domain',
                path: '/domain/'
            }
        ]
    },
    {
        id: 19,
        img: basic.bazel,
        title: 'BAEZL',
        services: [
            {
                title: 'Software',
                path: '/services/software/e-commerce/'
            },
            {
                title: 'Hosting',
                path: '/services/hosting/web/'
            },
            {
                title: 'Domain',
                path: '/domain/'
            }
        ]
    },
    {
        id: 20,
        img: basic.demoLogo,
        title: 'PMIS',
        services: [
            {
                title: 'Software',
                path: '/services/software/e-commerce/'
            },
            {
                title: 'Server',
                path: '/services/server/dedicated/'
            }
        ]
    },
    {
        id: 21,
        img: basic.map,
        title: 'Nutrition Map',
        services: [
            {
                title: 'Hosting',
                path: '/services/hosting/web/'
            },
            {
                title: 'Domain',
                path: '/domain/'
            }
        ]
    },
    {
        id: 22,
        img: basic.kitchenBari,
        title: 'Kitchenbari',
        services: [
            {
                title: 'App',
                path: '/services/hosting/app/'
            },
            {
                title: 'Server',
                path: '/services/server/dedicated/'
            },
            {
                title: 'Domain',
                path: '/domain/'
            }
        ]
    },
    {
        id: 23,
        img: basic.demoLogo,
        title: 'Rupkatha Apparels',
        services: [
            {
                title: 'Software',
                path: '/services/software/e-commerce/'
            },
            {
                title: 'Hosting',
                path: '/services/hosting/web/'
            },
            {
                title: 'Domain',
                path: '/domain/'
            }
        ]
    },
    {
        id: 24,
        img: basic.demoLogo,
        title: 'jadusoft',
        services: [
            {
                title: 'Hosting',
                path: '/services/hosting/web/'
            },
            {
                title: 'Domain',
                path: '/domain/'
            },
            {
                title: 'SMS',
                path: '/services/marketing/sms-service/'
            }
        ]
    }
];

function ProvidedServices({ el }) {
    return (
        <Link to={el?.path} className="service-list font-14 opacity-medium mr-3">
            {el?.title}
        </Link>
    );
}

function Client({ item }) {
    return (
        <div className="cell cell-3 cell-xl-4 cell-lg-6 cell-sm-12">
            <div className="client-area mb-4">
                <div className="line-align line-no-wrap">
                    <div className="cell-2">
                        <div className="client-logo">
                            <img className="w-100 d-block" src={item?.img} alt="client logo" />
                        </div>
                    </div>

                    <div className="cell cell-fill overflow-hidden">
                        <div className="ml-3">
                            <span
                                title={item?.title}
                                className="font-20 clr no-wrap d-block overflow-dotted"
                            >
                                {item?.title}
                            </span>
                            <div className="line-align line-no-wrap">
                                {item?.services?.map((el) => (
                                    <ProvidedServices key={el?.title} el={el} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ClientSection() {
    return (
        <div className="container" style={{ marginBottom: '120px' }}>
            <div className="text-center font-36 clr mt-6 mb-7">Our Clients</div>
            <div className="client-section-container">
                <div className="line-align line-g2 ml-sm-2 mr-sm-2">
                    {clients?.map((item) => (
                        <Client key={item?.id} item={item} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ClientSection;
