import { useNavigate } from 'react-router-dom';
import { basic } from '../../../configs/Images';

function ServerPagePlaceholder({ sName }) {
    const navigate = useNavigate();
    return (
        <div className="w-100 line-col-center text-center not-found-container">
            <div className="not-found-img-area">
                <img className="w-100" src={basic.imgNotFound} alt="icon missing" />
            </div>
            <p>{sName} Server Not Found! </p>
            <button
                onClick={() =>
                    navigate(
                        `/services/server/${sName === 'Cloud' ? 'cloud-vm' : sName?.toLowerCase()}/`
                    )
                }
                type="button"
                className="button-solid-purple"
            >
                Buy {sName} Server
            </button>
        </div>
    );
}

export default ServerPagePlaceholder;
