import { useState } from 'react';
import LayoutSelector from '../../../../../utils/LayoutSelector';
import TransactionListMobile from './TransactionListMobile';
import TransactionListWeb from './TransactionListWeb';

const demoArr = [
    {
        id: 1,
        trxId: '---------',
        date: '--------',
        status: '------',
        type: '--------',
        total: '--------',
        isDemo: true
    }
];
const arr = [
    // {
    //     id: 1,
    //     trxId: '10J087HG27',
    //     date: '30-Jan-2022',
    //     status: 'Canceled',
    //     type: 'Domain',
    //     total: 'USD 12.99'
    // },
    // {
    //     id: 2,
    //     trxId: '10J087HG27',
    //     date: '30-Jan-2022',
    //     status: 'Canceled',
    //     type: 'Domain',
    //     total: 'USD 12.99'
    // },
    // {
    //     id: 3,
    //     trxId: '10J087HG27',
    //     date: '30-Jan-2022',
    //     status: 'Canceled',
    //     type: 'Domain',
    //     total: 'USD 12.99'
    // }
];

function TransactionTable() {
    const [openModal, setOpenModal] = useState(null);
    return (
        <LayoutSelector
            sm
            md
            webComp={
                <TransactionListWeb
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    arr={arr || demoArr}
                />
            }
            mobileComp={<TransactionListMobile setOpenModal={setOpenModal} arr={arr || demoArr} />}
        />
    );
}

export default TransactionTable;
