import React from 'react';
import { useAuth } from '../../../../contexts/AuthProvider';
import RoleInvitationProvider from '../RoleInvitationProvider';
import RoleInvitationCardSection from '../role_invitation_card/RoleInvitationCardSection';

function RoleInvitationSection({ token }) {
    const { currentUser } = useAuth();
    return (
        <RoleInvitationProvider currentUser={currentUser} token={token}>
            <RoleInvitationCardSection />
        </RoleInvitationProvider>
    );
}

export default RoleInvitationSection;
