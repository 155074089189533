import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import PageProperties from '../../../utils/PageProperties';
import DomainInfoBasicProvider, { useDomainInfo } from '../domains_info/DomainInfoBasicProvider';
import DomainInfoHeader from '../domains_info_header/DomainInfoHeader';
import TabsRowSection from '../domains_info_tabs/TabsRowSection';
import DomainInfoCnsListSection from './DomainInfoCnsListSection';

function ContentHead() {
    const { domainInfo, handelReloadButton } = useDomainInfo();

    return (
        <DomainInfoHeader
            path="child-nameserver/"
            info={domainInfo}
            evReloadClick={handelReloadButton}
        />
    );
}

function DomainInfoCnsPage() {
    const { currentUser } = useAuth();
    const { contractId } = useParams();

    useEffect(() => {
        PageProperties(`Child Name Servers \u2022 Domain`);
    }, []);

    return (
        <DomainInfoBasicProvider currentUser={currentUser} contractId={contractId}>
            <div className="cell cell-content h-100">
                <div className="content-section">
                    <div className="content-area">
                        <ContentHead />

                        <div className="content-body-scroll">
                            <TabsRowSection cns />
                            <DomainInfoCnsListSection />
                        </div>
                    </div>
                </div>
            </div>
        </DomainInfoBasicProvider>
    );
}

export default DomainInfoCnsPage;
