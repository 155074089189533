function ProfileDeveloperPLanguage({ arr }) {
    return (
        <div className="line">
            <div className="cell">
                <div className="line-align line-g1 ">
                    {arr?.map((item) => (
                        <div key={item?.id} className="cell cell-sm-6">
                            <div className="list-item line-no-wrap line-align mb-3 mb-md-2">
                                <span className="line item-logo">
                                    <img className="w-100" src={item?.icon} alt="icon" />
                                </span>
                                <span className="ml-2">{item?.title}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ProfileDeveloperPLanguage;
