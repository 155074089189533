import { ButtonPurple } from '../../../../../../configs/Buttons';
import { useDnsInfo } from '../../DnsProvider';

function TabMxContent() {
    const { formData, setFormData } = useDnsInfo();

    const handelCreate = () => null;

    const isDisabled =
        !formData?.hostname || !formData?.mailServer || !formData?.priority || !formData?.ttl;
    return (
        <div>
            <div className="mb-1">
                <span className="content-text">
                    <b>MX</b> records specify the mail servers responsible for accepting emails on
                    behalf of your domain, and priority value if your provider has a number of mail
                    servers for contingency.
                </span>
            </div>
            <div className="record-input-area">
                <div className="line align-end line-g1">
                    <div className="cell cell-3 cell-md-fill">
                        <div className="label">
                            Hostname<span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, hostname: e.target.value }))
                            }
                            placeholder="Enter hostname"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>
                    <div className="cell cell-3 cell-md-fill">
                        <div className="label">
                            MAIL SERVER <span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, mailServer: e.target.value }))
                            }
                            placeholder="e.g. aspmx.l.google.com"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>
                    <div className="cell cell-3 cell-md-fill">
                        <div className="label">
                            Priority<span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, priority: e.target.value }))
                            }
                            placeholder="e.g. 10"
                            type="text"
                            className=" form-input"
                        />
                    </div>
                    <div className="cell cell-3 cell-md-fill">
                        <div className="label">
                            TTL (SECONDS)<span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, ttl: e.target.value }))
                            }
                            placeholder="36000"
                            type="text"
                            className=" form-input"
                        />
                    </div>
                    <div className="cell cell-12">
                        <div className="label">Comment</div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, comment: e.target.value }))
                            }
                            placeholder="Here is the Comment"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>
                    <div className="cell cell-12">
                        <div className="line justify-end mt-2">
                            <ButtonPurple
                                disabled={isDisabled}
                                evOnClick={handelCreate}
                                title="Create Record"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TabMxContent;
