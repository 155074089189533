import { GetDate } from '../../../../utils/Show';
import ProfileInfoEmail from '../profile_info_personal_email/ProfileInfoPersonalEmail';
import ProfileInfoPersonalPhone from '../profile_info_personal_phone/ProfileInfoPersonalPhone';

function Divider({ title }) {
    return (
        <div className="line-align line-no-wrap mb-2">
            <div className="cell title fw-400">{title}</div>
            <hr className="cell-fill cell-self-end ml-1" style={{ opacity: '0.5' }} />
        </div>
    );
}

function InfoCommonView({ title, value }) {
    return (
        <div className="cell cell-6 cell-md-12 mb-md-2 ">
            <div className="line-align">
                <div className="cell cell-5">
                    <span className="title common-value fw-500">{title}</span>
                </div>
                <div className="cell-7">
                    <span className="value common-value fw-400">{value}</span>
                </div>
            </div>
        </div>
    );
}

function ProfileInfoPersonalSection({ currentUser, info }) {
    return (
        <div>
            <Divider title="Personal Info" />
            <div className="line mb-2 mb-md-0">
                <InfoCommonView title="Full Name:" value={info?.displayName} />
                <div className="cell cell-6 cell-md-12 mb-md-2">
                    <ProfileInfoEmail info={info} />
                </div>
            </div>
            <div className="line mb-2 mb-md-0">
                <InfoCommonView title="User Name:" value={info?.username || '--'} />
                <div className="cell cell-6 cell-md-12 mb-md-2">
                    <ProfileInfoPersonalPhone info={info} />
                </div>
            </div>
            <div className="line mb-4">
                <InfoCommonView title="Gender:" value={info?.gender || '--'} />
                <InfoCommonView title="Date of birth:" value={GetDate(currentUser?.dob) || '--'} />
            </div>
        </div>
    );
}

export default ProfileInfoPersonalSection;
