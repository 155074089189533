import { Link, Outlet, useLocation } from 'react-router-dom';
import HeaderSection from '../../../headers/HeaderSection';
import FooterSection2 from '../../../system/section/Footer2Section';
import MobileFooter from '../../../users/components/MobileFooter';
import FixedHeaderControl from '../../../websites/components/FixedHeaderControl';

const menuItem = [
    {
        id: 1,
        title: 'Terms of Service Agreement',
        path: 'terms-of-service/'
    },
    {
        id: 2,
        title: 'Privacy & Policy',
        path: 'privacy-policy/'
    },
    {
        id: 3,
        title: 'Refund Policy',
        path: 'refund-policy/'
    }
];

function TabsMenu() {
    // const [index, setIndex] = useState(1);
    const { pathname } = useLocation();
    const path = `${pathname.split('/')[pathname.split('/').length - 2]}/`;

    return (
        <div className="search-menu">
            <div className="container">
                <ul className="search-menu-ul overflow-auto">
                    {menuItem.map((item) => (
                        <li
                            key={item.id}
                            className={path === item.path ? 'search-menu-active' : ''}
                        >
                            <Link to={item.path}>{item.title}</Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

function TabsArea() {
    return (
        <>
            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>
            <TabsMenu />
            <Outlet />
            <MobileFooter />
            <FooterSection2 />
        </>
    );
}

export default TabsArea;
