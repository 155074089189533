import { useAuth } from '../../../contexts/AuthProvider';
import HeaderSection from '../../headers/HeaderSection';
import FixedHeaderControl from '../../websites/components/FixedHeaderControl';
import LoginProvider from '../login/LoginProvider';
import LoginSection from './LoginSection';

function LoginPage() {
    const { setSuccessLogin, verifyProviderRecord, makeAction } = useAuth();

    return (
        <LoginProvider
            setSuccessLogin={setSuccessLogin}
            verifyProviderRecord={verifyProviderRecord}
            makeAction={makeAction}
        >
            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>

            <LoginSection />
        </LoginProvider>
    );
}

export default LoginPage;
