import { useEffect } from 'react';
import PageProperties from '../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../headers/DashboardHeaderSection';
import ContactInfoSection from './contactinfo/ContactInfoSection';
// import PhoneViewSection from './components/PhoneViewSection';

// const info = [
//     {
//         displayName: 'Yeasin Arafat',
//         email: '',
//         id: 1,
//         isDefault: false,
//         phoneNumber: '01795617763',
//         providerId: 'secondary',
//         userUid: 1
//     },
//     {
//         displayName: 'Y Arafat',
//         email: '',
//         id: 2,
//         isDefault: true,
//         phoneNumber: '01735022451',
//         providerId: 'primary',
//         userUid: 2
//     }
// ];

function UsersContactPage() {
    useEffect(() => {
        PageProperties(`Contact \u2022 Profile`);
    }, []);

    return (
        <div className="cell cell-content h-100 w-100">
            <div className="content-section">
                <div className="content-area">
                    <DashboardDefaultHeader title="Contact Info" path="/user/basic-info/" />

                    <div className="content-body-scroll" style={{ paddingBottom: '180px' }}>
                        <ContactInfoSection />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default UsersContactPage;
