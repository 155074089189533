import React from 'react';

const ProgressBar = ({ bgcolor, progress, height, counter = true, bgUnfill }) => {
    const Parentdiv = {
        height,
        width: '100%',
        backgroundColor: bgUnfill || '#E8E7EC',
        borderRadius: 40,
        opacity: '0.8'
        // margin: 50
    };

    const Childdiv = {
        height: '100%',
        width: progress ? `${progress}%` : 0,
        backgroundColor: bgcolor,
        borderRadius: 40,
        textAlign: 'right',
        position: 'relative'
    };

    const progresstext = {
        // padding: 10,
        color: bgcolor,
        fontWeight: 900,
        position: 'absolute',
        top: '-18px'
        // right: 0
        // right: progress === 100 ? '0' : '-13px'
    };

    return (
        <div style={Parentdiv} className="progress-unfill">
            <div style={Childdiv} className="progress-fill">
                <span style={progresstext} className="font-12 percent-text">
                    {counter && progress ? `${progress}%` : ''}
                </span>
            </div>
        </div>
    );
};

export default ProgressBar;
