import { useState } from 'react';
import { ButtonOutlinePink } from '../../../../configs/Buttons';
import { useAuth } from '../../../contexts/AuthProvider';
import RoleProvider, { useRole } from './RoleProvider';
import RoleAssignPopup from './role_list_popup/RoleAssignPopup';
import RoleListTable from './role_list_table/RoleListTable';
import RoleTitle from './role_title/RoleTitle';

function RoleArea({ title, domain }) {
    const [modal, setModal] = useState({ type: '', data: null });

    const { addButton, roles } = useRole();

    return (
        <div className="product-info role-section mb-6">
            <RoleTitle title={title} />
            <RoleListTable />

            <div hidden={!addButton || !roles} className="mt-3">
                <ButtonOutlinePink
                    evOnClick={() => setModal({ type: 'role-assign' })}
                    title="Assign User"
                />
            </div>
            <RoleAssignPopup
                show={modal.type === 'role-assign'}
                setModal={setModal}
                domain={domain}
            />
        </div>
    );
}

function RoleSection({ tbl, contractId, title, domain }) {
    const { currentUser } = useAuth();

    return (
        <RoleProvider currentUser={currentUser} tbl={tbl} contractId={contractId}>
            <RoleArea title={title} domain={domain} />
        </RoleProvider>
    );
}

export default RoleSection;
