import { useNavigate } from 'react-router-dom';
import { icons } from '../../../../../configs/Icons';

const data = [
    {
        id: 1,
        title: 'Support Ticket',
        des: 'If you feel mejor poroblem with any of our service, please open a ticket',
        img: icons.iconTicket,
        path: '/smanager/support-ticket/'
    },
    {
        id: 2,
        title: 'Report Abuse',
        des: 'If you feel mejor poroblem with any of our service, please open a ticket',
        img: icons.iconReport,
        path: '/'
    },
    {
        id: 3,
        title: 'Knowledge Base',
        des: 'If you feel mejor poroblem with any of our service, please open a ticket',
        img: icons.iconKnowledge,
        path: '/knowledge-base/'
    }
];

function SupportCategorySection() {
    const navigate = useNavigate();
    return (
        <div className="container support-page-area">
            <div className="support-category-area">
                <div className=" line-align line-no-wrap line-g3 overflow-auto">
                    {data.map((item) => (
                        <div key={item.id} className="cell cell-4 cell-md-fill">
                            <div
                                role="button"
                                tabIndex={-1}
                                onClick={() => navigate(item?.path)}
                                className="item line-col align-center "
                            >
                                <div className="img-area">
                                    <img src={item.img} alt="iconTicket" />
                                </div>
                                <div className="title mb-2">{item.title}</div>
                                <div className="value">{item.des}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default SupportCategorySection;
