import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../../../configs/Icons';

function BasicInfoDzoneTransfer({ info, disable }) {
    // const [modal, setModal] = useState(false);
    const { contractId } = useParams();

    if (disable && info) {
        return null;
    }
    return (
        <div className="cell cell-4 cell-md-fill cursor-pointer mb-md-3">
            <Link
                to={`/user/services/domain/${contractId}/transfer-out/`}
                className="line line-g1 line-no-wrap"
            >
                <div className="cell ">
                    <div className="danger-zone-icon">
                        <span className="w-100 d-block">
                            <img className="d-block" src={icons.iconDomainTransfer} alt="" />
                        </span>
                    </div>
                </div>
                <div className="cell">
                    <div className="">
                        <span className="fs-14 fw-500 red">Transfer Out</span>
                        <span className="value">
                            Transfer-Out your domain to other place that you want
                        </span>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default BasicInfoDzoneTransfer;
