import { useEffect, useState } from 'react';
import AxiosAuth from '../../../utils/AxiosAuth';
import ResultContentArea from './ResultContentArea';

import TopContentArea from './TopContentArea';

// api/?results=50

function ReqInputArea({ setApiData, apiData }) {
    useEffect(() => {
        AxiosAuth.setUrl(apiData.reqUrl)
            .get()
            .then((data) => {
                if (apiData?.mode) {
                    setApiData({ output: data });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [apiData.mode, apiData.reqUrl, setApiData]);

    const handleSend = () => {
        setApiData({ mode: true, reqUrl: apiData.reqUrl, output: apiData?.output });
    };

    const handleValue = (ev) => {
        const { value } = ev.target;
        setApiData((cuData) => ({ ...cuData, reqUrl: value }));
    };

    return (
        <div>
            <div className="border-bottom mb-2">
                <div className="req-title ">Untitled Request</div>
            </div>
            <div className="line-align line-g1 req-input-area">
                <div className="cell cell-1">
                    <select name="item" className="form-input w-100">
                        <option value="get">Get</option>
                        <option value="post">Post</option>
                    </select>
                </div>
                <div className="cell cell-10">
                    <div className=" w-100">
                        <input
                            placeholder="Enter Request URL"
                            className="w-100 form-input"
                            type="text"
                            defaultValue={apiData.reqUrl || ''}
                            onChange={handleValue}
                        />
                    </div>
                </div>
                <div className="cell cell-1 line justify-end ">
                    <span
                        onClick={() => handleSend()}
                        className="send-btn-area w-100"
                        role="button"
                        tabIndex={-1}
                    >
                        Send
                    </span>
                </div>
            </div>
        </div>
    );
}

function ReqDetailsSection({ historyText }) {
    const [apiData, setApiData] = useState({ mode: null, reqUrl: null, output: {}, error: null });

    const [reqItem, setReqItem] = useState(0);
    const [resItem, setResItem] = useState(0);
    return (
        <div className="req-details-area">
            <ReqInputArea apiData={apiData} setApiData={setApiData} historyText={historyText} />
            <TopContentArea reqItem={reqItem} setReqItem={setReqItem} />
            <ResultContentArea
                apiData={apiData}
                setApiData={setApiData}
                setResItem={setResItem}
                resItem={resItem}
            />
        </div>
    );
}

export default ReqDetailsSection;
