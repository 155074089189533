import React, { useEffect } from 'react';
import PageProperties from '../../utils/PageProperties';
import HeaderSection from '../headers/HeaderSection';
import FooterSection2 from '../system/section/Footer2Section';
import MobileFooter from '../users/components/MobileFooter';
import FixedHeaderControl from '../websites/components/FixedHeaderControl';
import ServicesSection from './section/ServicesSection';

function ServicesPage({ page }) {
    useEffect(() => {
        if (page === 'services') {
            document.getElementById('services').scrollIntoView();
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [page]);

    useEffect(() => {
        PageProperties('Services');
    }, []);

    return (
        <>
            <div id="services" />
            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>
            <ServicesSection />

            <FooterSection2 />
            <MobileFooter />
        </>
    );
}

export default ServicesPage;
