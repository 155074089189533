import { useState } from 'react';
import { useCart } from '../../../contexts/CartProvider';
import { useInit } from '../../../contexts/InitProvider';
import { useTemplate } from '../../../contexts/TemplateProvider';
import ModalBox from '../../../utils/ModalBox';
import {
    CurrencyFormatter,
    DurationOptions,
    showOfferPrice,
    showPrice,
    ShowPriceWithDuration
} from '../models/Pricing';

const TableInfo = ({ purchaseData }) => {
    const { disk, diskType, bandwidth, cpu, ram, ep, io } = purchaseData.property;

    return (
        <table className="hosting-info text-left">
            <tbody>
                <tr>
                    <td>Disk Space:</td>
                    <td>
                        {disk / 1024} GB {diskType}
                    </td>
                </tr>
                <tr>
                    <td>Bandwidth:</td>
                    <td>{bandwidth / 1024} GB/Month</td>
                </tr>
                <tr>
                    <td>Processing:</td>
                    <td>{cpu} Core (3.4 GHZ)</td>
                </tr>
                <tr>
                    <td>RAM:</td>
                    <td>{ram / 1024} GB</td>
                </tr>
                <tr>
                    <td>Max Process:</td>
                    <td>{ep}</td>
                </tr>
                <tr>
                    <td>I/O:</td>
                    <td>{io} Mbps</td>
                </tr>
                <tr>
                    <td>Price:</td>
                    <td>
                        <span className="no-wrap">
                            {ShowPriceWithDuration(purchaseData?.price || 0)}
                            {/* {showPricePerDuration(purchaseData)} */}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

function PurchaseHostingSection1({ purchaseData, setPurchaseData }) {
    const [duration, setDuration] = useState(purchaseData.selectedDuration);
    const { resetState, handleAddToCart } = useCart();
    const { setMessage } = useTemplate();

    const { locale } = useInit();

    const handleCloseClick = () => {
        setPurchaseData(null);
        resetState();
    };

    const handleYearChange = (ev) => {
        setDuration(parseInt(ev.target.value, 10));
    };

    const handleToCart = (ev) => {
        setMessage('Loading...');
        handleAddToCart({
            ...purchaseData,
            duration
        }).then(({ message }) => {
            handleCloseClick(ev);
            setMessage(message);
        });
    };

    if (purchaseData === null) {
        return null;
    }

    return (
        <ModalBox
            show
            handleCloseClick={handleCloseClick}
            header={<div className="modal-box-title text-left">Add hosting to cart</div>}
            body={
                <>
                    <div className="login-input">
                        <div className="line mb-3">
                            <div className="cell cell-fill">
                                <TableInfo purchaseData={purchaseData} />
                            </div>
                            <div className="cell">
                                <div className="price-box mb-3">
                                    <span>{locale?.currency}</span>
                                    <span>
                                        {CurrencyFormatter(showOfferPrice(purchaseData, duration))}
                                    </span>
                                    <span>
                                        &nbsp;{locale?.currency}{' '}
                                        {CurrencyFormatter(showPrice(purchaseData, duration)) || 0}
                                        &nbsp;
                                    </span>
                                </div>

                                <DurationOptions
                                    productInfo={purchaseData}
                                    className="form-input w-100"
                                    handleYearChange={handleYearChange}
                                    duration={duration}
                                    style={{ fontSize: '13px' }}
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="button"
                                className="add-to-cart button w-100"
                                onClick={handleToCart}
                            >
                                Add to Cart
                            </button>
                        </div>
                    </div>
                </>
            }
        />
    );
}
export default PurchaseHostingSection1;
