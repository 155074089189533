import React from 'react';
import { ButtonSolidPink } from '../../../../../../configs/Buttons';
import { osIcon, userIcons } from '../../../../../../configs/Icons';
import LayoutSelector from '../../../../../utils/LayoutSelector';
import SectionDivider from '../../section_divider/SectionDivider';
import ConversationListMobile from './ConversationListMobile';
import ConversationListWeb from './ConversationListWeb';

const arr = [
    {
        id: '01',
        topics: 'This is topic -1',
        activity: 1695127194,
        team: [
            {
                img: userIcons.iconUserV3
            },
            {
                img: osIcon.mac
            },
            {
                img: userIcons.iconUserV3
            },
            {
                img: osIcon.mac
            },
            {
                img: userIcons.iconUserV3
            },
            {
                img: userIcons.iconUserV3
            },
            {
                img: osIcon.mac
            },
            {
                img: userIcons.iconUserV3
            },
            {
                img: userIcons.iconUserV3
            },
            {
                img: userIcons.iconUserV3
            },
            {
                img: osIcon.mac
            },
            {
                img: userIcons.iconUserV3
            },
            {
                img: userIcons.iconUserV3
            },
            {
                img: userIcons.iconUserV3
            },
            {
                img: osIcon.mac
            },
            {
                img: userIcons.iconUserV3
            }
        ]
    },
    {
        id: '02',
        topics: 'This is topic -2',
        activity: 1696127194,
        team: [
            {
                img: userIcons.iconUserV3
            },
            {
                img: osIcon.mac
            },
            {
                img: userIcons.iconUserV3
            }
        ]
    }
];

function Table() {
    return (
        <LayoutSelector
            sm
            md
            webComp={<ConversationListWeb arr={arr} />}
            mobileComp={<ConversationListMobile arr={arr} />}
        />
    );
}

function ConversationListSection() {
    // const [modal, setModal] = useState(false);

    return (
        <div className="product-info conversation-list-area mb-4">
            <SectionDivider title="Conversation" action={<ButtonSolidPink title="Create New" />} />
            <Table />
            {/* <MessageModal show={modal} setModal={setModal} /> */}
        </div>
    );
}

export default ConversationListSection;
