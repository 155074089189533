import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { userIcons } from '../../../../../configs/Icons';
import { useAuth } from '../../../../contexts/AuthProvider';
import LayoutSelector from '../../../../utils/LayoutSelector';
import PageProperties from '../../../../utils/PageProperties';
import { DashboardArrowHeader } from '../../../headers/DashboardHeaderSection';
import ProfileActivitySection from '../../../manage/profile-overview/profile_activity/ProfileActivitySection';
import ProfileHeader from '../../../manage/profile-overview/profile_header/ProfileHeader';
import ProfileInfoSection from '../../../manage/profile-overview/profile_info/ProfileInfoSection';
import ProfileServicesSection from '../../../manage/profile-overview/profile_services/ProfileServicesSection';

const contractInfo = [
    {
        uId: 10000000,
        photoUrl: userIcons.iconUserV3,
        displayName: 'Mariana D. Alonzo',
        email: 'name@email.com',
        status: 'Hosting',
        phone: '+880 1234 5678 90',
        loginDate: '25-Sept-2022',
        loginTime: '04:35:47 AM',
        isDone: false
    },
    {
        uId: 20000000,
        photoUrl: userIcons.iconUserV3,
        displayName: 'Chris Evans',
        email: 'name@email.com',
        phone: '+880 1234 5678 90',
        loginDate: '25-Sept-2022',
        loginTime: '04:35:47 AM',
        isDone: true
    }
];

function SupportUserDetailsPage() {
    const [openList, setOpenList] = useState({ type: null, data: '' });

    const { id } = useParams();
    const { currentUser } = useAuth();

    const user = contractInfo.filter((el) => el.uId === parseInt(id, 10));

    useEffect(() => {
        PageProperties(`User Details \u2022 Support`);
    }, []);

    return (
        <>
            <LayoutSelector
                sm
                md
                lg
                mobileComp={<DashboardArrowHeader title="User Details" path={-1} />}
            />
            <div
                className="content-body-scroll user-profile-overview-area"
                style={{ paddingBottom: '120px' }}
            >
                {user.map((data) => (
                    <div key={data?.uId}>
                        <ProfileHeader data={data} />
                        <ProfileActivitySection />
                        <ProfileInfoSection
                            info={data}
                            currentUser={currentUser}
                            openList={openList}
                            setOpenList={setOpenList}
                        />
                        <ProfileServicesSection />
                    </div>
                ))}
            </div>
        </>
    );
}

export default SupportUserDetailsPage;
