const InfoStatusHandler = (data) => {
    const textColor = [];
    if (!data) {
        return '';
    }
    if (
        data?.status === 'sent' ||
        data?.status === 'pending' ||
        data?.status === 'disabled' ||
        data?.status === 'warning'
    ) {
        textColor?.push('#FFA113');
    }
    if (data?.status === 'processing') {
        textColor?.push('#14B9FF');
    }
    if (data?.status === 'running') {
        textColor?.push('#AE00B9');
    }
    // if (data?.status === 'Pending' || data?.status === 'Disabled') {
    //     textColor?.push('#FFC039');
    // }
    if (
        data?.status === 'delivered' ||
        data?.status === 'finished' ||
        data?.status === 'active' ||
        data?.status === 'okay'
    ) {
        textColor?.push('#17DC69');
    }
    if (
        data?.status === 'failed' ||
        data?.status === 'Warning' ||
        data?.status === 'blocked' ||
        data?.status === 'Canceled'
    ) {
        textColor?.push('#F50303');
    }
    return textColor?.join(' ');
};

export default InfoStatusHandler;
