import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GetDate, getDayDiff } from '../../../../utils/Show';
import TableActionButton from './TableActionButton';

function InfoCommonView({ title, value }) {
    return (
        <div className="line-row-between align-unset">
            <div className="cell">
                <span className="value primary-70">
                    <span className="value">{title}:</span>
                </span>
            </div>
            <div className="cell">{value}</div>
        </div>
    );
}

function DomainListTableMobileView({ arr, setModal, modal }) {
    const navigate = useNavigate();

    return arr?.map((data) => {
        const days = getDayDiff(data?.timeExpire);

        const daysRemain = data?.isExpired ? 'Expired' : `${days} Days Left`;
        return (
            <div
                role="button"
                tabIndex={-1}
                onClick={() => navigate(`/user/services/domain/${data?.id}/`)}
                key={data?.id}
                className={`list-item-sm ${data?.demo ? 'placeholder-mode' : ''}`}
            >
                <div className="line-row-between mb-1">
                    <div className="cell">
                        <span className="title">{data?.domain}</span>
                        <span className="sub-title primary-50 d-block">{`Since ${GetDate(
                            data?.timeIssue
                        )}`}</span>
                    </div>
                    <div className="cell">
                        <TableActionButton item={data} setModal={setModal} modal={modal} />
                    </div>
                </div>
                <InfoCommonView
                    title="Renew"
                    value={<span className="value">{`USD ${(data?.price * 12).toFixed(2)}`}</span>}
                />
                <InfoCommonView
                    title="Expire On"
                    value={
                        <div className="text-right">
                            {' '}
                            <span className="value primary">
                                {GetDate(data?.timeExpire) || '--'}
                            </span>
                            <span className="sub-title primary-50 d-block">{daysRemain}</span>
                        </div>
                    }
                />
            </div>
        );
    });
}

export default DomainListTableMobileView;
