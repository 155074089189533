import { useState } from 'react';
import ResultStep from './ResultStep';
import SecucityAuthStep from './SecucityAuthStep';
import SendCodeStep from './SendCodeStep';
import TransferingStep from './TransferingStep';
import VerifyEmailStep from './VerifyEmailStep';

function DomainInfoTransferStepSection() {
    const [step, setStep] = useState(1);

    return (
        <div className="transfer-step">
            <div className="border-dash" />
            <VerifyEmailStep onClick={() => setStep(2)} />
            <SecucityAuthStep
                isVisible={step === 2 || step === 3 || step === 4 || step === 5}
                onClick={() => setStep(3)}
            />
            <SendCodeStep
                isVisible={step === 3 || step === 4 || step === 5}
                onClick={() => setStep(4)}
            />
            <TransferingStep isVisible={step === 4 || step === 5} onClick={() => setStep(5)} />
            <ResultStep isVisible={step === 4 || step === 5} />
        </div>
    );
}

export default DomainInfoTransferStepSection;
