import TicketListActionButton from './TicketListActionButton';

function TableBody({ arr }) {
    if (!arr?.length) {
        return (
            <tbody>
                <tr>
                    <td colSpan={100}>No Tickets Found !</td>
                </tr>
            </tbody>
        );
    }
    return arr?.map((data) => (
        <tbody key={data?.id}>
            <tr className={data?.demo ? 'placeholder-mode' : ''}>
                <td>
                    <span>{data?.date}</span>
                </td>
                <td>
                    <span>{data?.issuedBy}</span>
                </td>
                <td>
                    <span>{data?.subject}</span>
                    <br />
                    <span className="font-12 primary-70">#{data?.subId}</span>
                </td>
                <td style={{ color: data?.status === 'Solved' ? '#17DC69' : '#F69220' }}>
                    <span>{data?.status}</span>
                </td>
                <td>
                    <TicketListActionButton />
                </td>
            </tr>
        </tbody>
    ));
}

function TicketListWeb({ arr }) {
    return (
        <table className="table-list cell-sm-12" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Issued By</th>
                    <th>Subject</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
            </thead>
            <TableBody arr={arr} />
        </table>
    );
}

export default TicketListWeb;
