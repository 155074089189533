/* eslint-disable jsx-a11y/label-has-associated-control */
import { EmailAuthProvider, getAuth, reauthenticateWithCredential } from 'firebase/auth';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonClose, ButtonPurple } from '../../../../../configs/Buttons';
import { basic } from '../../../../../configs/Images';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import { InpPassword } from '../../../../utils/inputs/PasswordInputs';
import Iopt from '../../../../utils/Iopt';

const ModalHeader = ({ setMode }) => (
    <div className="modal-box-header mb-1">
        <div>
            <div className="modal-box-title">Enter password to continue</div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={() => setMode({ type: 'off' })} />
            </div>
        </div>
    </div>
);

function ModalBody({ currentUser }) {
    const [formData, setFormData] = useState({ password: '' });
    const { makeAction } = useAuth();

    const navigate = useNavigate();
    const { setMessage } = useTemplate();

    const handelUpdateData = (ev) => {
        setFormData((cVal) => ({ ...cVal, password: ev.target.value }));
    };
    const handleAuth = () => {
        const auth = getAuth();
        const credential = EmailAuthProvider.credential(auth.currentUser.email, formData.password);
        return new Promise((resolve, reject) => {
            reauthenticateWithCredential(auth.currentUser, credential)
                .then((data) => {
                    if (data?.user?.auth?.currentUser?.emailVerified) {
                        setMessage('Verified');
                        navigate('/user/security-and-login/2facAuth/');
                    }
                    resolve(data);
                })
                .catch((err) => {
                    setMessage('Wrong Password');
                    reject(err);
                });
        });
    };
    return (
        <div className="modal-body-area">
            <div className=" body-content">
                <div className="line-align border-bottom mb-1 body-header">
                    <div className="user-img">
                        <img
                            className="w-100 d-block"
                            src={
                                currentUser?.photoUrl ? Iopt(currentUser.photoUrl) : basic.imgAvatar
                            }
                            alt="avatar"
                        />
                    </div>
                    <div className="ml-3">
                        <span className="fs-16 fw-500 clr">{currentUser?.displayName || '--'}</span>
                        <span className="font-14 opacity-medium d-block">
                            {currentUser?.email || '--'}
                        </span>
                    </div>
                </div>
                <div className="mb-1 input-grp">
                    <InpPassword
                        title="Password"
                        name="password"
                        value={formData?.password}
                        setValue={handelUpdateData}
                    />
                </div>
                <div className="mb-4 text-right">
                    <span
                        role="button"
                        tabIndex={-1}
                        onClick={() => {
                            makeAction('forget-password');
                        }}
                        className="fs-14 fw-500 opacity-medium"
                    >
                        Forgot Password?
                    </span>
                </div>
                <div className="full-width">
                    <ButtonPurple evOnClick={handleAuth} title="Continue" modal />
                </div>
            </div>
        </div>
    );
}

function SecurityTwoFacAuthPassPopup({ show, setMode, currentUser }) {
    if (!show) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content two-factor-popup-area">
                <ModalHeader setMode={setMode} />

                <ModalBody currentUser={currentUser} />
            </div>
        </div>
    );
}

export default SecurityTwoFacAuthPassPopup;
