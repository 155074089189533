import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import HostingInfoHeader from '../hostings_info_header/HostingInfoHeader';
import TabsSection from '../hostings_info_tabs/TabSection';
import EmailListSection from './email_list/EmailListSection';
import HostingInfoEmailProvider, { useEmailHosting } from './HostingInfoEmailProvider';

import PageProperties from '../../../utils/PageProperties';

function ContentArea() {
    const { emailInfo, handelReloadButton } = useEmailHosting();
    return (
        <>
            <HostingInfoHeader path="emails/" info={emailInfo} evReloadClick={handelReloadButton} />
            <div className="content-body">
                <div className="content-body-scroll">
                    <TabsSection email info={emailInfo} />
                    <div className={!emailInfo ? 'placeholder-mode' : ''}>
                        <EmailListSection />
                        {/* <EmailHealthSection /> */}
                    </div>
                </div>
            </div>
        </>
    );
}

function HostingInfoEmailPage() {
    const { contractId } = useParams();
    const { currentUser } = useAuth();

    useEffect(() => {
        PageProperties(`Emails \u2022 Hosting`);
    }, []);

    return (
        <HostingInfoEmailProvider currentUser={currentUser} contractId={contractId}>
            <div className="content-area hosting-info-page">
                <ContentArea />
            </div>
        </HostingInfoEmailProvider>
    );
}

export default HostingInfoEmailPage;
