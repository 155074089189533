function BalanceHistorySection() {
    const histories = [
        {
            id: 1,
            title: 'SSLCommerz (bKash)',
            date: '08-02-2020',
            trxId: 'Trx:9X457W1H62',
            amount: 1800
        },
        {
            id: 2,
            title: 'Domain Purchase',
            date: '19-01-2020',
            trxId: '#57196734',
            amount: -978.45
        },
        {
            id: 3,
            title: 'Bikiran Point (300)',
            date: '12-01-2020',
            trxId: 'N/A',
            amount: 30
        }
    ];

    return (
        <div className="bikiran-table history-table">
            <table className="table-list web-device" cellPadding={0} cellSpacing={0}>
                <thead>
                    <tr>
                        <th width={100}>Balance In/Out</th>
                        <th width={130}>Date</th>
                        <th width={100}>ID/TrxID/Bank AC</th>
                        <th width={100}>In Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {histories.map((history) => (
                        <tr key={history.id}>
                            <td style={{ color: history.amount < 0 ? '#FB7247' : '#17DC69' }}>
                                {history.title}
                            </td>
                            <td>{history.date}</td>
                            <td>{history.trxId}</td>
                            <td>{history.amount}TK</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {histories.map((history) => (
                <table
                    key={history.id}
                    className="table-list table-list-mobile mobile-device"
                    cellPadding={0}
                    cellSpacing={0}
                >
                    <tbody className="body-list-mobile">
                        <tr>
                            <td>Earning/Cash</td>
                            <td style={{ color: history.amount < 0 ? '#FB7247' : '#17DC69' }}>
                                {history.title}
                            </td>
                        </tr>
                        <tr>
                            <td>Date</td>
                            <td>{history.date}</td>
                        </tr>
                        <tr>
                            <td>ID/TrxID/Bank AC</td>
                            <td>{history.trxId}</td>
                        </tr>
                        <tr>
                            <td>In Amount</td>
                            <td>{history.amount}</td>
                        </tr>
                    </tbody>
                </table>
            ))}
            {/* ))} */}
        </div>
    );
}

export default BalanceHistorySection;
