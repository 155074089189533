import { ButtonActionDetails, ButtonOutlinePurple } from '../../../../../../configs/Buttons';
import LayoutSelector from '../../../../../utils/LayoutSelector';
import CommonModal from '../../../../system/modals/CommonModal';

function ListActionButton({ openModal, setOpenModal }) {
    return (
        <>
            <LayoutSelector
                sm
                md
                webComp={
                    <span className="common-table-btn">
                        <ButtonOutlinePurple evOnClick={() => setOpenModal(true)} title="View" />
                    </span>
                }
                mobileComp={
                    <span className="btn-action">
                        <ButtonActionDetails evOnClick={() => setOpenModal(true)} />
                    </span>
                }
            />
            {openModal && <CommonModal openModal={openModal} setOpenModal={setOpenModal} />}
        </>
    );
}

export default ListActionButton;
