/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { forwardRef, useEffect } from 'react';
import { useAuth } from '../contexts/AuthProvider';
import { useTemplate } from '../contexts/TemplateProvider';
import AxiosAuth from '../utils/AxiosAuth';
import LoadScript from '../utils/LoadScript';

async function InitBkash(callback, scriptLoaded) {
    await LoadScript('jquery', 'https://code.jquery.com/jquery-3.6.0.min.js');

    await LoadScript(
        'bkash',
        'https://scripts.sandbox.bka.sh/versions/1.2.0-beta/checkout/bKash-checkout-sandbox.js'
    );
    const data = await AxiosAuth.setApiUrl('PAYMENT_BKASH', '/token/').post();

    function callReconfigure(val) {
        bKash.reconfigure(val);
    }

    function clickPayButton() {
        $('#bKash_button').trigger('click');
    }

    if (data) {
        if (typeof callback === 'function') {
            callback(bKash, callReconfigure, clickPayButton, data);
        }
    }
}

const Bkash = forwardRef((props, ref) => {
    const { amount, order, intent = 'sale' } = props;
    const roundedAmount = Math.round(amount);
    const { currentUser } = useAuth();
    const { setMessage } = useTemplate();

    useEffect(() => {
        InitBkash((bKash, callReconfigure, clickPayButton) => {
            let paymentId = '';
            bKash.init({
                paymentMode: 'checkout',
                paymentRequest: { amount: roundedAmount, intent },
                createRequest(request) {
                    setMessage('bKash Payment on Progress.');

                    AxiosAuth.currentUserAuth(currentUser)
                        .setApiUrl('PAYMENT_BKASH', '/create-payment/')
                        .post({
                            order,
                            amount: roundedAmount
                        })
                        .then((response) => {
                            const { data } = response;

                            if (data.error === 0) {
                                paymentId = data.bkash.paymentID;
                                bKash.create().onSuccess({ ...data.bkash });
                            } else {
                                setMessage(data.message);
                                bKash.create().onError();
                            }
                        });
                },

                executeRequestOnAuthorization() {
                    AxiosAuth.currentUserAuth(currentUser)
                        .setApiUrl('PAYMENT_BKASH', '/execute-payment/')
                        .post({
                            order,
                            amount: roundedAmount,
                            paymentId
                        })
                        .then((response) => {
                            const { data } = response;

                            if (data && data.paymentID != null) {
                                bKash.execute().onSuccess(data);
                            } else {
                                setMessage(data.error);
                                bKash.execute().onError();
                            }
                        });
                }
            });
        });

        return () => {
            $('#bKashFrameWrapper').remove();
        };
    }, [currentUser, intent, order, roundedAmount, setMessage]);

    return <button type="button" style={{ display: 'none' }} id="bKash_button" ref={ref} />;
});

export default Bkash;
