import dayjs from 'dayjs';

export const duration = (months) => {
    if (months < 12) {
        return `${months} Month`;
    }

    return `${months / 12} Year`;
};

export function GetDate(timeStamp) {
    if (!timeStamp) {
        return null;
    }
    const dateFormat = dayjs(timeStamp * 1000).format(`DD ${`MMMM`.slice(0, 3)} YYYY`);
    return dateFormat.toString() !== 'Invalid Date' ? dateFormat : '--';
}

export function GetTime(timeStamp) {
    if (!timeStamp) {
        return null;
    }
    const dateFormat = dayjs(timeStamp * 1000).format('h:mm:ss A');
    return dateFormat.toString() || null;
}

export const getDayDiff = (timestamp) => {
    const currentDate = dayjs();
    const expireDate = dayjs(timestamp * 1000);

    const days = expireDate?.diff(currentDate, 'd');

    if (!timestamp || days < 0) {
        return null;
    }

    return days;
};

// file size display
export const fileSizeDisplay = (bytes, text) => {
    const megabytes = bytes / (1024 * 1024);
    const gigabytes = megabytes / 1024;

    let usageText = `${megabytes.toFixed(2)} MB`;

    if (gigabytes >= 1) {
        usageText = `${gigabytes.toFixed(2)} GB`;
    }

    return <span>{`${usageText} ${text || 'Used'}` || '--'}</span>;
};

export function UpperCase(item) {
    return item?.charAt(0).toUpperCase() + item?.slice(1);
}

export default null;
