import { createContext, useContext, useEffect, useState } from 'react';
import AxiosAuth from '../../../utils/AxiosAuth';
import { mkToken } from '../../../utils/StringOperation';

const DomainInfoBasicContext = createContext();

export function useDnsInfo() {
    return useContext(DomainInfoBasicContext);
}

function DnsProvider({ children, currentUser, tbl, contractId }) {
    const [dnsInfo, setDnsInfo] = useState(null);
    const [formData, setFormData] = useState({});
    const [listKey, setListKey] = useState(null);
    const [error, setError] = useState(null);

    const handelReloadButton = () => {
        setListKey(mkToken(32));
        setDnsInfo(null);
    };

    // const update = () =>
    //     new Promise((resolve, reject) => {
    //         AxiosAuth.currentUserAuth(currentUser)
    //             .setApiUrl('CONTRACT', `${contractId}/update/`)
    //             .post({})
    //             .then(({ data }) => {
    //                 if (data?.error === 0) {
    //                     setListKey(Math.random());
    //                 }

    //                 resolve(data);
    //             })
    //             .catch((err) => {
    //                 reject(err);
    //             });
    //     });

    const value = {
        handelReloadButton,
        dnsInfo,
        error,
        formData,
        setFormData
    };

    useEffect(() => {
        if (contractId) {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT', `/dns/${tbl}/${contractId}/list/`)
                .get()
                .then(({ data }) => {
                    // console.log(data);
                    if (data.error === 0) {
                        setDnsInfo(data);
                        setListKey(mkToken(32));
                    }
                    setError(data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [contractId, currentUser, listKey, tbl]);

    return (
        <DomainInfoBasicContext.Provider value={value}>
            {children}
            {/* {error?.error && error?.error !== 0 ? (
                <InfoErrorPopup errorMessage={error?.message} />
            ) : null} */}
        </DomainInfoBasicContext.Provider>
    );
}

export default DnsProvider;
