import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import PageProperties from '../../../utils/PageProperties';
import InfoWarningSection from '../_models/info-warning-section/InfoWarningSection';
import CpanelSetupView from '../hostings_Info/CpanelSetupView';
import HostingInfoHeader from '../hostings_info_header/HostingInfoHeader';
import TabsSection from '../hostings_info_tabs/TabSection';
import RoleSection from '../role/RoleSection';
import HostingInfoBasicProvider, { useHostingInfoBasic } from './HostingInfoBasicProvider';
import BasicInfoBillingSection from './basicinfo_billings/BasicInfoBillingSection';
import BasicInfoDzoneSection from './basicinfo_dzone/BasicInfoDzoneSection';
import InfoSection from './basicinfo_info/InfoSection';
import BasicInfoResourceSection from './basicinfo_resource/BasicInfoResourceSection';

function ContentArea() {
    const { basicInfo, handelReloadButton, openModal } = useHostingInfoBasic();
    if (basicInfo && !basicInfo?.isExecuted) {
        return (
            <>
                <HostingInfoHeader path="" info={basicInfo} evReloadClick={handelReloadButton} />
                <InfoWarningSection info={basicInfo?.message} />
                <CpanelSetupView basicInfo={basicInfo} />
            </>
        );
    }
    return (
        <>
            <HostingInfoHeader info={basicInfo} evReloadClick={handelReloadButton} />
            <div className="content-body">
                <div className="content-body-scroll">
                    <TabsSection basic info={basicInfo} />
                    <div className={!basicInfo ? 'placeholder-mode' : ''}>
                        <InfoWarningSection info={basicInfo?.message} openModal={openModal} />
                        <InfoSection />
                        <BasicInfoResourceSection />
                        <BasicInfoBillingSection />
                        <BasicInfoDzoneSection />
                        <RoleSection
                            tbl="hosting"
                            contractId={basicInfo?.contractId}
                            title="Hosting Access"
                            domain={basicInfo?.domain}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

function HostingInfoBasicPage() {
    const { contractId } = useParams();
    const { currentUser } = useAuth();

    useEffect(() => {
        PageProperties(`Basic Info \u2022 Hosting`);
    }, []);

    return (
        <HostingInfoBasicProvider currentUser={currentUser} contractId={contractId}>
            <div className="content-area hosting-info-page">
                <ContentArea />
            </div>
        </HostingInfoBasicProvider>
    );
}

export default HostingInfoBasicPage;
