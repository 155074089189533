import React from 'react';
import { ButtonClose } from '../../../../configs/Buttons';
import { useTemplate } from '../../../contexts/TemplateProvider';
import RegistrationModalArea0 from './RegistrationModalArea0';

const ModalHeader = ({ title, setExitModal, modalProperty }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title">{title}</div>
            <div className="modal-box-close">
                <ButtonClose
                    evOnClick={
                        typeof modalProperty.onExit === 'function'
                            ? modalProperty.onExit
                            : setExitModal
                    }
                />
            </div>
        </div>
    </div>
);

const ModalBody = ({
    modalProperty,
    setCurrentUser,
    setMessage,
    setExitModal,
    signOut,
    makeAction
}) => (
    <div className="modal-box-body">
        <div>
            <RegistrationModalArea0
                modalProperty={modalProperty}
                setCurrentUser={setCurrentUser}
                setMessage={setMessage}
                setExitModal={setExitModal}
                signOut={signOut}
                makeAction={makeAction}
            />
        </div>
    </div>
);

function RegistrationModal({ modalProperty, setCurrentUser, setExitModal, signOut, makeAction }) {
    const { setMessage } = useTemplate();

    const title = 'Create account with email';

    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <ModalHeader
                    setExitModal={setExitModal}
                    title={title}
                    modalProperty={modalProperty}
                />

                <ModalBody
                    modalProperty={modalProperty}
                    setCurrentUser={setCurrentUser}
                    setMessage={setMessage}
                    setExitModal={setExitModal}
                    signOut={signOut}
                    makeAction={makeAction}
                />
            </div>
        </div>
    );
}

export default RegistrationModal;
