import BillingsListActionButton from './BillingsListActionButton';

function ContentTop({ data }) {
    return (
        <div className="line-row-between mb-2">
            <div className="line">
                <img className="d-block" src={data?.image} alt="user dp" />
                <div className="ml-1">
                    <span className="title primary">{data?.name}</span>
                    <span className="sub-title purple d-block">{data?.email}</span>
                </div>
            </div>
            <div>
                <BillingsListActionButton />
            </div>
        </div>
    );
}

function CommonRowArea({ title, value }) {
    return (
        <div className="line-row-between mb-1">
            <div className="cell">
                <span className="value primary-70">{title || '--'}:</span>
            </div>
            <div className="cell">
                <span className="value">{value || '--'}</span>
            </div>
        </div>
    );
}

function BillingsListMobile({ arr }) {
    if (!arr?.length) {
        return (
            <div className="list-item-sm">
                <span className="title primary">No Billings Found !</span>
            </div>
        );
    }
    return arr?.map((data) => (
        <div key={data?.uId} className={`list-item-sm ${data?.demo ? 'placeholder-mode' : ''}`}>
            <ContentTop data={data} />
            <CommonRowArea
                title="Date"
                value={
                    <div className="text-right">
                        <span>{data?.date}</span> <br />
                        <span className="sub-title primary-70">{data?.time}</span>
                    </div>
                }
            />
            <CommonRowArea
                title="Status"
                value={
                    <div style={{ color: data?.status === 'Active' ? '#17DC69' : '#F69220' }}>
                        <span>{data?.status}</span>
                    </div>
                }
            />
            <CommonRowArea title="Currency" value={data?.currency} />
        </div>
    ));
}

export default BillingsListMobile;
