import React, { useState } from 'react';

import { ButtonOutlinePurple } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';

function VerifyView({ handleVerifyClick }) {
    return (
        <div>
            <span className="d-block font-14 primary-70 mb-2">
                Domain name transfer code has been sent
            </span>

            <span className="mb-3 d-block">
                <input placeholder="Code" type="text" className="form-input" />
            </span>
            <ButtonOutlinePurple evOnClick={() => handleVerifyClick()} title="Verify Now" />
        </div>
    );
}

function SendCodeStep({ isVisible, onClick }) {
    const [isVerified, setIsVerified] = useState(false);

    const handleVerifyClick = () => {
        setIsVerified(true);
        onClick();
    };

    if (isVerified) {
        return (
            <div className="line line-no-wrap line-g2 mb-6">
                <div className="cell">
                    <div className="logo">
                        <img className="w-100 d-block" src={icons.iconLockPurple} alt="" />
                    </div>
                </div>
                <div className="cell mt-2">
                    <span className="font-18 primary">Send Code</span>
                    <span className="d-block font-14 primary-70">
                        Domain name transfer code has been sent
                    </span>
                </div>
            </div>
        );
    }
    return (
        <div className={`line line-no-wrap line-g2 mb-6 ${!isVisible ? 'disabled-view' : ''}`}>
            <div className="cell">
                <div className="logo">
                    <img className="w-100 d-block" src={icons.iconLockPurple} alt="" />
                </div>
            </div>
            <div className="cell mt-2">
                <span className="font-18 primary">Send Code</span>
                <VerifyView handleVerifyClick={handleVerifyClick} />
            </div>
        </div>
    );
}

export default SendCodeStep;
