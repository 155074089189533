import { forwardRef, useEffect } from 'react';
import AxiosAuth from '../utils/AxiosAuth';

let loaded = false;

function InitSslcom() {
    if (!loaded) {
        const script = document.createElement('script');
        const tag = document.getElementsByTagName('script')[0];
        script.src = `https://sandbox.sslcommerz.com/embed.min.js?${Math.random()
            .toString(36)
            .substring(7)}`;
        tag.parentNode.insertBefore(script, tag);
        loaded = true;
    }
}

const Sslcom = forwardRef((props, ref) => {
    const { orderId, currentUser, id } = props; // , amount, currency
    const { userUid = '', refreshToken = '' } = currentUser || {};

    useEffect(() => {
        InitSslcom();
        document.querySelector('#sslczPayBtn').token = `${userUid}:::${refreshToken}:::${id}`;
    }, [id, refreshToken, userUid]);

    return (
        <button
            type="button"
            style={{ display: 'none' }}
            id="sslczPayBtn"
            ref={ref}
            order={orderId}
            // eslint-disable-next-line
            endpoint={AxiosAuth.setApiUrl('PAYMENT_SSLCOM', '/endpoint/').getUrl()}
        />
    );
});

export default Sslcom;
