import { createContext, useContext, useEffect, useState } from 'react';

import AxiosAuth from '../../../utils/AxiosAuth';
import HostingInfoErrorPopup from '../hostings_Info/hostinginfo_popup/HostingInfoErrorPopup';

const HostingInfoDatabasesContext = createContext();

export function useHostingDatabase() {
    return useContext(HostingInfoDatabasesContext);
}

/* 
{
        contractId: 10000496,
        domain: 'DATABASES.COM',
        title: 'APP Hosting - 4 GB SSD'
    }
 */

function HostingInfoDatabasesProvider({ children, currentUser, contractId }) {
    const [listKey, setListKey] = useState(null);
    const [databaseInfo, setDatabaseInfo] = useState(null);
    const [error, setError] = useState(null);
    const reloadList = () => {
        setListKey(Math.random());
    };

    const handelReloadButton = () => {
        setListKey(Math.random());
        setDatabaseInfo(null);
    };

    // get info
    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .setApiUrl('CONTRACT_HOSTING', `/cp/databases/`)
            .post({ contractId })
            .then(({ data }) => {
                setDatabaseInfo(null);

                if (data.error === 0) {
                    setDatabaseInfo(data);
                    // reloadList();
                } else {
                    setError(data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [contractId, currentUser, listKey]);

    const value = {
        reloadList,
        databaseInfo,
        error,
        handelReloadButton
    };

    return (
        <HostingInfoDatabasesContext.Provider value={value}>
            {children}
            {error?.error && error?.error !== 0 ? <HostingInfoErrorPopup error={error} /> : null}
        </HostingInfoDatabasesContext.Provider>
    );
}

export default HostingInfoDatabasesProvider;
