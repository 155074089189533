import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import PageProperties from '../../../utils/PageProperties';
import DomainInfoBasicProvider, { useDomainInfo } from '../domains_info/DomainInfoBasicProvider';
import DomainInfoHeader from '../domains_info_header/DomainInfoHeader';
import TabsRowSection from '../domains_info_tabs/TabsRowSection';
import FaqSection from '../faq/FaqSection';

function ContentHead() {
    const { domainInfo, handelReloadButton } = useDomainInfo();
    return <DomainInfoHeader evReloadClick={handelReloadButton} info={domainInfo} path="faq/" />;
}

function ContentBody({ contractId }) {
    const { domainInfo } = useDomainInfo();
    return (
        <div className="content-body-scroll" style={{ paddingBottom: '180px' }}>
            <TabsRowSection faq />
            <div className={!domainInfo ? 'placeholder-mode' : ''}>
                <FaqSection contractId={contractId} />
            </div>
        </div>
    );
}

function DomainInfoFaqPage() {
    const { contractId } = useParams();
    const { currentUser } = useAuth();

    useEffect(() => {
        PageProperties(`FAQ \u2022 Domain`);
    }, []);

    return (
        <DomainInfoBasicProvider currentUser={currentUser} contractId={contractId}>
            <div className="cell cell-content h-100">
                <div className="content-section">
                    <div className="content-area">
                        <ContentHead />
                        <div className="content-body">
                            <ContentBody contractId={contractId} />
                        </div>
                    </div>
                </div>
            </div>
        </DomainInfoBasicProvider>
    );
}

export default DomainInfoFaqPage;
