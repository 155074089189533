import { Link } from 'react-router-dom';

function SearchBottomMenu({ isMenu }) {
    return (
        <ul className="home-search-bottom-menu" style={{ display: isMenu ? 'block' : 'none' }}>
            <li>
                <Link to="/services">Service</Link>
            </li>
            <li>
                <Link to="/">Security</Link>
            </li>
            <li>
                <Link to="/support">Support</Link>
            </li>
        </ul>
    );
}
export default SearchBottomMenu;
