import { useRef, useState } from 'react';
import { ButtonActionDotMenu } from '../../../../../configs/Buttons';
import ContactInfoIconHandler from '../_models/ContactInfoIconHandler';
import ContactInfoStatusHandler from '../_models/ContactInfoStatusHandler';
import ContactInfoPhoneTableMenu from './ContactInfoPhoneTableMenu';

function PhoneBody({ data, myRef }) {
    const [showOptions, setShowOptions] = useState(false);

    const type = 'phone';
    return (
        <tbody className={data?.demo ? 'placeholder-mode' : ''}>
            <tr key={data?.id}>
                <td colSpan={6}>
                    <div className="line-align line-g1 line-no-wrap">
                        <ContactInfoIconHandler value={data.providerId} />
                        <div className="cell cell-fill">
                            <div className="line-align">
                                <span className="cell-fill">
                                    <h4 className="title mt-0 mb-0">{data.phoneNumber}</h4>
                                </span>
                            </div>
                        </div>
                    </div>
                </td>
                <td colSpan={3}>
                    <ContactInfoStatusHandler data={data} type={type} />
                </td>
                <td colSpan={1}>
                    <div className="position-relative option-popup">
                        <div className="line justify-end ">
                            <span className="common-table-btn">
                                <ButtonActionDotMenu
                                    evOnClick={() => {
                                        setShowOptions(data?.id);
                                    }}
                                />
                            </span>
                        </div>
                        <ContactInfoPhoneTableMenu
                            myRef={myRef}
                            showOptions={data?.id === showOptions}
                            setShowOptions={setShowOptions}
                            data={data}
                            type="email"
                        />
                    </div>
                </td>
            </tr>
        </tbody>
    );
}

function ContactInfoPhoneTableWeb({ arr }) {
    const myRef = useRef();
    return (
        <table className="table-list cell-sm-12" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th colSpan={6}>Phone Number</th>
                    <th width="23%" colSpan={3}>
                        Status
                    </th>
                    <th className="text-right" width="10%" colSpan={1}>
                        Action
                    </th>
                </tr>
            </thead>
            {arr?.map((data) => (
                <PhoneBody key={data.id} data={data} myRef={myRef} />
            ))}
        </table>
    );
}

export default ContactInfoPhoneTableWeb;
