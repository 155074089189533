/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useEffect, useRef, useState } from 'react';

function CustomDropdown({
    items,
    onSelect,
    selectedItem,
    optionKey,
    stateKey,
    displayKey,
    defaultDisplayValue
}) {
    const [isActive, setIsActive] = useState(false);
    const [displayItem, setDisplayItem] = useState();

    const ref = useRef();

    console.log(selectedItem);

    useEffect(() => {
        function handelClickOutside(event) {
            if (ref?.current && !ref?.current.contains(event?.target)) {
                // alert("You clicked outside of me!");
                setIsActive(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handelClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handelClickOutside);
        };
    }, [ref]);

    const handleSelect = (item) => {
        const selectedValue = optionKey ? item[optionKey] : item;

        const stateValue = stateKey ? item[stateKey] : selectedValue;

        onSelect(stateValue || 'Select');

        setIsActive(false);

        if (optionKey || stateKey) {
            setDisplayItem(displayKey ? item[displayKey] : item[optionKey]);
        }
    };

    let optionItems;
    if (typeof items[0] === 'object') {
        // If items is an array of objects, display the value of the specified optionKey property as the option
        optionItems = items.map((item) => {
            const optionValue = optionKey ? item[optionKey] : item;

            const isItemActive = selectedItem === item[stateKey];

            return (
                <li
                    role="button"
                    tabIndex={-1}
                    key={optionValue}
                    className={`custom-dropdown-item ${isItemActive ? 'selected' : ''}`}
                    onClick={() => handleSelect(item)}
                >
                    {optionValue}
                </li>
            );
        });
    } else {
        // If items is an array of strings, display the value of the string as the option
        optionItems = items.map((item) => (
            <li
                role="button"
                tabIndex={-1}
                key={item}
                className={`custom-dropdown-item ${item === selectedItem ? 'selected' : ''}`}
                onClick={() => handleSelect(item)}
            >
                {item}
            </li>
        ));
    }

    const selectValue =
        (displayKey ? displayItem : selectedItem) || defaultDisplayValue || 'Select';

    return (
        <div className="custom-dropdown-container" ref={ref}>
            <div
                role="button"
                tabIndex={-1}
                className={`custom-dropdown-selected-item ${isActive ? 'active' : 'inactive'}`}
                onClick={() => setIsActive(!isActive)}
            >
                <span>{selectValue}</span>
                <span className={`custom-dropdown-arrow ${isActive ? 'up' : 'down'}`} />
            </div>
            {isActive && <ul className="custom-dropdown-items">{optionItems}</ul>}
        </div>
    );
}

export default CustomDropdown;
