import { useEffect } from 'react';
import { icons } from '../../../../../configs/Icons';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import { UpperCase } from '../../../../utils/Show';
import { useRole } from '../RoleProvider';

function RoleListTableMenuOption({ setModal, setShowOptions, showOptions, myRef, data }) {
    const { handelRemoveRole, handelCancelInvite, handelResendInvite, handelReload } = useRole();
    const { setConfirm, setMessage } = useTemplate();

    useEffect(() => {
        function handelClickOutside(event) {
            if (myRef?.current && !myRef?.current.contains(event?.target)) {
                // alert("You clicked outside of me!");
                setShowOptions(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handelClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handelClickOutside);
        };
    }, [myRef, setShowOptions]);

    // const isOwner = data.roleKey === 'owner';

    const handleButtonClick = (item) => {
        const owner = item === 'makeAsOwner';
        const modify = item === 'modify';
        const remove = item === 'remove';
        const cancel = item === 'cancel';
        const resend = item === 'resend';

        if (owner) {
            setModal({ type: 'change-owner', data });
        }
        if (resend) {
            setConfirm({
                text: 'Are you sure you want to resend this invitation?',
                textCancel: 'No',
                txtAction: 'Yes',

                clickAction: (confirm, setNull) => {
                    setMessage('Resending Invitation...');
                    handelResendInvite(data?.roleId).then(({ error, message }) => {
                        setNull(null);
                        if (error === 0) {
                            setShowOptions(false);
                            setMessage(message);
                            handelReload();
                        } else {
                            setModal({ type: 'error' });
                            setMessage(null);
                        }
                    });
                }
            });
        }
        if (cancel) {
            setConfirm({
                text: 'Are you sure you want to cancel this invitation?',
                textCancel: 'No',
                txtAction: 'Yes',

                clickAction: (confirm, setNull) => {
                    setMessage('Canceling...');
                    handelCancelInvite(data?.roleId).then(({ error, message }) => {
                        setNull(null);
                        if (error === 0) {
                            setShowOptions(false);
                            setMessage(message);
                            handelReload();
                        } else {
                            setModal({ type: 'error' });
                            setMessage(null);
                        }
                    });
                }
            });
        }
        if (modify) {
            setModal({ type: 'role-modify', data });
            // console.log('Modify Clicked');
        }

        if (remove) {
            setConfirm({
                text: 'Are you sure you want to remove this role?',
                clickAction: (confirm, setNull) => {
                    setMessage('Role Deleting...');
                    handelRemoveRole(data.roleId).then(({ error, message }) => {
                        setNull(null);
                        if (error === 0) {
                            setMessage(message);
                            handelReload();
                        } else {
                            setModal({ type: 'error' });
                            setMessage(null);
                        }
                    });
                }
            });
        }
    };

    if (!showOptions) {
        return false;
    }

    return (
        <div className="inst-option-pop" ref={myRef}>
            <img src={icons.iconShape} alt="shape" />
            <div className="inst-option">
                {data?.buttons?.map((button) => (
                    <div key={button} className="clickable">
                        <button
                            onClick={() => handleButtonClick(button)}
                            type="button"
                            className="default-none w-100 text-left no-wrap"
                        >
                            <span>
                                {button === 'makeAsOwner' ? 'Make As Owner' : UpperCase(button)}
                            </span>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default RoleListTableMenuOption;
