import { useNavigate } from 'react-router-dom';
import { basic } from '../../../configs/Images';

function CommonPlaceholder({ text, url }) {
    const navigate = useNavigate();
    return (
        <div className="w-100 line-col-center text-center not-found-container">
            <div className="not-found-img-area">
                <img className="w-100" src={basic.imgNotFound} alt="icon missing" />
            </div>
            <p>{text} Not Found! </p>
            <button
                onClick={() => navigate(`/services/${url}/`)}
                type="button"
                className="button-solid-purple"
            >
                Buy {text}
            </button>
        </div>
    );
}

export default CommonPlaceholder;
