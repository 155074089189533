/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import {
    ButtonClose,
    ButtonCloseMinus,
    ButtonGr,
    ButtonOutlinePink
} from '../../../../configs/Buttons';
import { useTemplate } from '../../../contexts/TemplateProvider';
import countries from '../../../utils/country.json';

const ModalHeader = ({ handleCloseClick, handleCheckBox }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title">
                <span>Contact Details</span>
                <span className="line-align check-text">
                    <input
                        className="mr-1"
                        onChange={handleCheckBox}
                        type="checkbox"
                        name=""
                        id=""
                    />
                    <span>Use same contact for</span>
                </span>
            </div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={handleCloseClick} />
            </div>
        </div>
    </div>
);

function Tabs({ index, setIndex, isChecked }) {
    const tabs = [
        {
            id: 1,
            title: 'Registrant'
        },
        {
            id: 2,
            title: 'Administrative'
        },
        {
            id: 3,
            title: 'Billing'
        },
        {
            id: 4,
            title: 'Technical'
        }
    ];
    // console.log(index);
    if (isChecked) {
        setIndex(0);
    }
    return (
        <ul className="line-row-between line-no-wrap overflow-auto tabs">
            {isChecked ? (
                <li
                    // hidden={isChecked}
                    className="cell-3 active"
                >
                    <div>Registrant</div>
                </li>
            ) : (
                tabs.map((tab, i) => (
                    <li
                        // hidden={isChecked}
                        className={`cell-3 cell-sm-4 ${index === i ? 'active' : ''}`}
                        style={{ cursor: 'pointer' }}
                        key={tab?.id}
                    >
                        <div onClick={() => setIndex(i)} role="button" tabIndex={-1}>
                            {tab?.title}
                        </div>
                    </li>
                ))
            )}
        </ul>
    );
}

function FaxField({ setMode, mode, value, onChange }) {
    return (
        <div className={`cell cell-12 ${mode ? 'cell cell-12 field-active' : 'field-inactive'}`}>
            <div className="line-row-between">
                <div>
                    <label htmlFor="fax">Fax</label>
                </div>
                <div className="field-close-area">
                    <ButtonCloseMinus evOnClick={() => setMode(!mode)} />
                </div>
            </div>
            <input
                name="fax"
                placeholder="Enter Fax"
                type="text"
                className="form-input w-100"
                defaultValue={value || ''}
                onChange={onChange}
            />
        </div>
    );
}

function ModalForm({ show, type, handleCloseClick, cuContact, setCuContact, updateContactInfo }) {
    const [mode, setMode] = useState(false);
    const { setMessage } = useTemplate();
    const contactAr = cuContact ? cuContact[type] : null;

    const updateDefVal = (ev) => {
        const { value, name } = ev.target;
        if (cuContact) {
            setCuContact((exContactAr) => {
                const exContactAr2 = { ...exContactAr };
                exContactAr2[type][name] = value;
                return exContactAr2;
            });
        }
        return null;
    };

    const updateDefValAddress = (ev) => {
        const { value, name } = ev.target;
        if (cuContact) {
            setCuContact((exContactAr) => {
                const exContactAr2 = { ...exContactAr };
                exContactAr2[type].address[name] = value;
                return exContactAr2;
            });
        }
        return null;
    };

    const handelSaveClick = () => {
        setMessage('Loading...');

        updateContactInfo().then(({ data }) => {
            setMessage(data.message);

            if (data.error === 0) {
                handleCloseClick();
            }
        });
    };

    if (!show) {
        return null;
    }
    return (
        <div className="address-contact-area">
            <div className="line-row-between line-g2 mb-3">
                <div className="cell cell-6 cell-sm-12">
                    <label htmlFor="fullname">Full Name</label>
                    <input
                        required
                        name="fullname"
                        placeholder="Enter Full Name"
                        type="text"
                        className="form-input w-100"
                        value={contactAr?.name || ''}
                        onChange={updateDefVal}
                    />
                </div>
                <div className="cell cell-6 cell-sm-12">
                    <label htmlFor="organization">Organization</label>
                    <input
                        name="organization"
                        placeholder="Enter Organization"
                        type="text"
                        className="form-input w-100"
                        value={contactAr?.organization || ''}
                        onChange={updateDefVal}
                    />
                </div>
                <div className="cell cell-6 cell-sm-12">
                    <label htmlFor="email">Email</label>
                    <input
                        name="email"
                        placeholder="Enter Email"
                        type="text"
                        className="form-input w-100"
                        value={contactAr?.email || ''}
                        onChange={updateDefVal}
                    />
                </div>
                <div className="cell cell-6 cell-sm-12">
                    <div className="line-row-between line-no-wrap">
                        <div>
                            <label htmlFor="telephone">Telephone</label>
                        </div>
                        <div className="contact-btn-area cell-6 line justify-end ">
                            <span
                                onClick={() => setMode(!mode)}
                                role="button"
                                tabIndex={-1}
                                title="Add Fax"
                                className="fax-icon ml-1"
                            >
                                +
                            </span>
                        </div>
                    </div>
                    <input
                        name="telephone"
                        placeholder="Enter Telephone"
                        type="text"
                        className="form-input w-100"
                        value={contactAr?.telephone || ''}
                        onChange={updateDefVal}
                    />
                </div>

                <FaxField
                    setMode={setMode}
                    mode={mode}
                    value={contactAr?.fax || ''}
                    onChange={updateDefVal}
                />
                <div className="cell cell-12">
                    <label htmlFor="line1">Address Line</label>

                    <textarea
                        onChange={updateDefValAddress}
                        value={contactAr?.address?.line1 || ''}
                        name="line1"
                        placeholder="Enter Address Line-1"
                        className="form-input w-100"
                        id=""
                        cols="100"
                        rows="3"
                    />
                </div>

                <div className="cell cell-6 cell-sm-12">
                    <label htmlFor="country">Country</label>
                    <select
                        className="form-input w-100"
                        value={contactAr?.address?.country || ''}
                        onChange={updateDefValAddress}
                        name="country"
                    >
                        <option value="">Select</option>
                        {countries.map((item) => (
                            <option key={item?.code} value={item?.code || ''}>
                                {item?.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="cell cell-6 cell-sm-12">
                    <label htmlFor="state">State</label>
                    <input
                        name="state"
                        placeholder="Enter State"
                        type="text"
                        className="form-input w-100"
                        value={contactAr?.address?.state}
                        onChange={updateDefValAddress}
                    />
                </div>
                <div className="cell cell-6 cell-sm-12">
                    <label htmlFor="city">City</label>
                    <input
                        name="city"
                        placeholder="Enter City"
                        type="text"
                        className="form-input w-100"
                        value={contactAr?.address?.city}
                        onChange={updateDefValAddress}
                    />
                </div>
                <div className="cell cell-6 cell-sm-12">
                    <label htmlFor="zip">Zip</label>
                    <input
                        name="zip"
                        placeholder="Enter Zip"
                        type="text"
                        className="form-input w-100"
                        value={contactAr?.address?.zip}
                        onChange={updateDefValAddress}
                    />
                </div>
            </div>
            <div className="line-align justify-end line-no-wrap">
                <ButtonOutlinePink evOnClick={handleCloseClick} title="Cancel" modal />
                <span className="ml-2">
                    <ButtonGr evOnClick={handelSaveClick} title="Save" modal />
                </span>
            </div>
        </div>
    );
}
function ModalBody({
    handleCloseClick,
    handleSaveClick,
    isChecked,
    cuContact,
    setCuContact,
    updateContactInfo,
    tabItem
}) {
    const [index, setIndex] = useState(tabItem);
    return (
        <div className="modal-body-area overflow-auto">
            <div className="contact-box-header">
                <Tabs isChecked={isChecked} setIndex={setIndex} index={index} />
            </div>
            <ModalForm
                show={index === 0}
                type="registrant"
                index={index}
                handleCloseClick={handleCloseClick}
                handleSaveClick={handleSaveClick}
                cuContact={cuContact}
                setCuContact={setCuContact}
                updateContactInfo={updateContactInfo}
            />
            <ModalForm
                show={index === 1}
                type="admin"
                index={index}
                handleCloseClick={handleCloseClick}
                handleSaveClick={handleSaveClick}
                cuContact={cuContact}
                setCuContact={setCuContact}
                updateContactInfo={updateContactInfo}
            />
            <ModalForm
                show={index === 2}
                type="billing"
                index={index}
                handleCloseClick={handleCloseClick}
                handleSaveClick={handleSaveClick}
                cuContact={cuContact}
                setCuContact={setCuContact}
                updateContactInfo={updateContactInfo}
            />
            <ModalForm
                show={index === 3}
                type="tech"
                index={index}
                handleCloseClick={handleCloseClick}
                handleSaveClick={handleSaveClick}
                cuContact={cuContact}
                setCuContact={setCuContact}
                updateContactInfo={updateContactInfo}
            />
        </div>
    );
}

function DomainInfoContactsModal({
    setModal,
    cuContact,
    setCuContact,
    updateContactInfo,
    tabItem
}) {
    const [isChecked, setIsChecked] = useState(null);
    const handleCloseClick = () => {
        setModal(null);
    };

    const handleSaveClick = () => {
        //
    };
    const handleCheckBox = (ev) => {
        setIsChecked(ev.target.checked);
    };

    // if (!show) {
    //     return null;
    // }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content contact-info-modal" style={{ width: '530px' }}>
                <ModalHeader handleCloseClick={handleCloseClick} handleCheckBox={handleCheckBox} />

                <ModalBody
                    handleSaveClick={handleSaveClick}
                    isChecked={isChecked}
                    handleCloseClick={handleCloseClick}
                    cuContact={cuContact}
                    setCuContact={setCuContact}
                    updateContactInfo={updateContactInfo}
                    tabItem={tabItem}
                />
            </div>
        </div>
    );
}

export default DomainInfoContactsModal;
