import { icons } from '../../../../../configs/Icons';
import { DurationOptions } from '../../../websites/models/Pricing';

function ItemLogo() {
    return (
        <div className="item-logo">
            <img className="w-100 d-block" src={icons.iconSmsBg} alt="domain icon" />
        </div>
    );
}

function ItemDetails() {
    return (
        <div>
            <div className="mb-1">
                <span className="title">SMS Service - Masking</span>
            </div>
            <div className="duration-area">
                <span>
                    <DurationOptions className="form-input year mb-1 mb-sm-0" />
                </span>
                <span className="renew-text break-word">
                    Renews December 2022 for $99.9 (12-Mo term){' '}
                </span>
            </div>
        </div>
    );
}

function Pricing() {
    return (
        <div className="text-right">
            <span role="button" className="green fw-500 fs-14 d-block mb-2 mb-sm-0">
                Add Now
            </span>
            <span className="price-offer pink-clr d-block">$249.99</span>
        </div>
    );
}

function SmsItemSection() {
    return (
        <div className="">
            <div className="line line-g1 line-no-wrap">
                <div className="cell d-md-none">
                    <ItemLogo />
                </div>
                <div className="cell cell-fill">
                    <ItemDetails />
                </div>
                <div className="cell">
                    <Pricing />
                </div>
            </div>
        </div>
    );
}

export default SmsItemSection;
