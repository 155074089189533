import { useState } from 'react';
import LayoutSelector from '../../../../utils/LayoutSelector';
import DeletePrimaryPopup from '../../_models/DeletePrimaryPopup';
import ContactInfoEmailTableMobile from './ContactInfoEmailTableMobile';
import ContactInfoEmailTableWeb from './ContactInfoEmailTableWeb';

const demoArr = [
    {
        id: 1,
        email: '------------',
        userUid: '1',
        providerId: 'google.com',
        isDefault: true,
        demo: true
    }
];

function ContactInfoEmailTableSection({ contactList, currentUser }) {
    const [showOptions, setShowOptions] = useState(false);
    const arr = contactList || demoArr;
    return (
        <>
            <LayoutSelector
                sm
                md
                webComp={
                    <ContactInfoEmailTableWeb
                        arr={arr}
                        contactList={contactList}
                        currentUser={currentUser}
                        showOptions={showOptions}
                        setShowOptions={setShowOptions}
                    />
                }
                mobileComp={
                    <ContactInfoEmailTableMobile
                        arr={arr}
                        currentUser={currentUser}
                        showOptions={showOptions}
                        setShowOptions={setShowOptions}
                    />
                }
            />
            <DeletePrimaryPopup
                show={showOptions === 'default'}
                setShowOptions={setShowOptions}
                title="Contact"
            />
        </>
    );
}

export default ContactInfoEmailTableSection;
