import { MD5 } from 'crypto-js';
import { useEffect, useRef, useState } from 'react';
import OtpInputProvider, { useOtpInput } from '../../contexts/OtpInputProvider';
import { useTemplate } from '../../contexts/TemplateProvider';

const BtnResendOtp = ({ formData, setFocusIndex, handelOtpSend }) => {
    const btnRef = useRef();
    const { setMessage } = useTemplate();
    const [count, setCount] = useState(10);

    const handelOnClick = () => {
        setMessage('Resending OTP...');
        btnRef.current.disabled = true;

        handelOtpSend()
            .then(({ error, message }) => {
                if (error === 0) {
                    setFocusIndex(0);
                }

                setMessage(message);
                btnRef.current.disabled = false;
            })
            .catch(() => {
                btnRef.current.disabled = false;
            });
    };

    useEffect(() => {
        if (formData.sentId) {
            const timer = setTimeout(() => {
                setCount((cuVal) => cuVal - 1);
            }, 1000);

            if (count < 1) {
                clearTimeout(timer);
            }
        }
    }, [count, formData.sentId]);

    if (count > 0 && formData.sentId) {
        return (
            <button type="button" className="button w-100 count-down">
                Resend in {count}s
            </button>
        );
    }

    return (
        <button type="button" className="button w-100 resend" onClick={handelOnClick} ref={btnRef}>
            Resend Code
        </button>
    );
};

const OtpCell = ({
    index,
    value,
    handelUpdateData,
    handelKeyUp,
    handelFocus,
    disabled,
    isFocused = false
}) => {
    const ref = useRef();

    useEffect(() => {
        if (isFocused && !disabled) {
            ref.current.focus();
        }
    }, [disabled, isFocused]);

    return (
        <div className="cell cell-sm-fill mb-2">
            <div>
                <input
                    ref={ref}
                    type="text"
                    value={value}
                    onChange={(ev) => {
                        handelUpdateData(index, ev.target.value.replace(/[^0-9]/g, ''));
                    }}
                    onKeyUp={(ev) => {
                        handelKeyUp(index, ev.target.value, ev.keyCode);
                    }}
                    onFocus={() => {
                        handelFocus(index);
                    }}
                    readOnly={disabled}
                    className="otp-inputs"
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

function InputOtpSection({ formData, handelOtpSend }) {
    const {
        otp,
        focusIndex,
        setFocusIndex,
        otpBlankAr,
        handelOtpSet,
        handelOtpKeyUp,
        handelOtpFocus
    } = useOtpInput();

    // --OTP
    const valMap = (otp || '').split('');
    const disabled = !formData.sentId;

    return (
        <div className="input-grp" style={{ opacity: disabled ? 0.3 : 1 }}>
            <div className="label">One Time Password</div>

            <div>
                <div className="line line-otp line-g2 line-sm-g1">
                    {otpBlankAr.map((_, index) => (
                        <OtpCell
                            key={MD5(`I-${index}-${valMap[index]}`)}
                            index={index}
                            value={valMap[index] || ''}
                            handelUpdateData={handelOtpSet}
                            disabled={disabled}
                            isFocused={index === focusIndex}
                            handelKeyUp={handelOtpKeyUp}
                            handelFocus={handelOtpFocus}
                        />
                    ))}

                    <div className="cell cell-fill cell-sm-12 mb-2">
                        <BtnResendOtp
                            formData={formData}
                            handelOtpSend={handelOtpSend}
                            setFocusIndex={setFocusIndex}
                            key={formData.sentId}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function OtpInputs({ formData, setFormData, handelOtpSend }) {
    const setOtp = (newOtp) => {
        if (typeof newOtp === 'function') {
            setFormData((cuVal) => ({ ...cuVal, otp: newOtp(cuVal.otp) }));
        } else {
            setFormData((cuVal) => ({ ...cuVal, otp: newOtp }));
        }
    };

    return (
        <OtpInputProvider otp={formData.otp} setOtp={setOtp} handelOtpSend={handelOtpSend}>
            <InputOtpSection formData={formData} handelOtpSend={handelOtpSend} />
        </OtpInputProvider>
    );
}

export default OtpInputs;
