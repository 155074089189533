import { useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthProvider';
import PageProperties from '../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../headers/DashboardHeaderSection';
import ProfileAddress from './components/ProfileAddress';
import ProfileDob from './components/ProfileDob';
import ProfileEmail from './components/ProfileEmail';
import ProfileFullName from './components/ProfileFullName';
import ProfileGender from './components/ProfileGender';
import ProfilePhone from './components/ProfilePhone';
import ProfileUserName from './components/ProfileUserName';
import UserBasicInfoProvider from './UserBasicInfoProvider';

function ContentHead() {
    return <DashboardDefaultHeader title="Basic Info" path="/user/profile-overview/" />;
}

function Divider({ title }) {
    return (
        <div className="line align-end line-g1 mb-4">
            <div className="cell">
                <span className="profile-subject">{title}</span>
            </div>
            <div className="cell cell-fill">
                <hr className="hr" />
            </div>
        </div>
    );
}

function UserDetails({ currentUser }) {
    return (
        <div className="product-info mb-6 user-profile-info-area">
            <Divider title="Personal Information" />

            <div>
                <div className="line mb-5 user-personal-info-area">
                    <div className="cell cell-12 cell-md-12 cell-sm-12">
                        <ProfileFullName
                            currentUser={currentUser}
                            key={currentUser?.displayName || 'fullName'}
                        />

                        <ProfileUserName
                            currentUser={currentUser}
                            key={currentUser?.username || 'username'}
                        />

                        <ProfileGender
                            currentUser={currentUser}
                            key={currentUser?.gender || 'gender'}
                        />

                        <ProfileDob currentUser={currentUser} key={currentUser?.dob || 'dob'} />

                        <ProfilePhone
                            currentUser={currentUser}
                            key={currentUser?.phone || 'phone'}
                        />

                        <ProfileEmail
                            currentUser={currentUser}
                            key={currentUser?.email || 'email'}
                        />
                        <ProfileAddress currentUser={currentUser} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function UserBasicInfoPage() {
    const { currentUser, recheckLogin } = useAuth();

    useEffect(() => {
        PageProperties(`Basic Info \u2022 ${currentUser?.displayName}`);
    }, [currentUser.displayName]);

    return (
        <UserBasicInfoProvider
            currentUser={currentUser}
            recheckLogin={recheckLogin}
            key={currentUser?.userUid || ''}
        >
            <div className="cell cell-content h-100">
                <div className="content-section">
                    <div className="content-area">
                        <ContentHead />

                        <div className="content-body-scroll user-profile-area">
                            <UserDetails currentUser={currentUser} />
                        </div>
                    </div>
                </div>
            </div>
        </UserBasicInfoProvider>
    );
}
export default UserBasicInfoPage;
