import InstOption from '../../../utils/InstOption';

function MenuOptions({ showOptions, setShowOptions, myRef, data }) {
    // const { setConfirm, setMessage } = useTemplate();

    return (
        <InstOption show={showOptions} setShow={setShowOptions} myRef={myRef} data={data}>
            <button onClick={null} type="button" className="default-none w-100 text-left">
                <span>Manage</span>
            </button>
            <button onClick={null} type="button" className="default-none w-100 text-left">
                <span>Delete</span>
            </button>
        </InstOption>
    );
}

export default MenuOptions;
