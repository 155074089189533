import { Link } from 'react-router-dom';

import {
    domainIcons,
    icons,
    signOutIcons,
    supportIcons,
    userIcons
} from '../../../../configs/Icons';
import InstOption from '../../../utils/InstOption';

const UserOption = ({ showOptions, setShowOptions, signOut, myRef }) => {
    const handelClick = (ev) => {
        if (ev.type === 'click') {
            ev.stopPropagation();
            setShowOptions(false);
        }
    };

    return (
        <InstOption show={showOptions} setShow={setShowOptions} myRef={myRef}>
            {/* <Link to="/user/dashboard/">
            <img src={dashboardIcons.iconDashboardV1} alt="Dashboard" /> <span>Dashboard</span>
        </Link> */}
            <Link onClick={handelClick} to="/user/profile-overview/">
                <img src={userIcons.iconUserV1} alt="My Profile" /> <span>Profile Overview</span>
            </Link>
            <Link onClick={handelClick} to="/domain">
                <img src={icons.iconWallet} alt="renew" /> <span>Renew</span>
            </Link>
            <Link onClick={handelClick} to="/user/services/domain/">
                <img src={domainIcons.iconDomainV1} alt="renew" /> <span>Domain</span>
            </Link>
            <Link onClick={handelClick} to="/user/services/hosting/">
                <img src={icons.iconHosting} alt="renew" /> <span>Hosting</span>
            </Link>
            {/* <Link onClick={handelClick} to="/user/points/">
            <img src={icons.iconPointsV1} alt="My Points" /> <span>Bikiran Points</span>
        </Link> */}
            <Link onClick={handelClick} to="/user/billing/invoice/">
                <img src={icons.iconWallet} alt="Billing" /> <span>Billing</span>
            </Link>
            <Link onClick={handelClick} to="/support">
                <img src={supportIcons.iconSupport} alt="Support" /> <span>Support</span>
            </Link>
            {/* <Link onClick={handelClick} to="/sms-dashboard/campaign">
            <img src={icons.iconSmsV2} alt="Sms" /> <span>Sms</span>
        </Link> */}
            {/* <div className="divider" style={{ margin: '7px' }} /> */}
            <div className="border-bottom line-g2" />
            {/*  clickable="off" */}
            <Link
                to="/"
                onClick={(ev) => {
                    ev.preventDefault();
                    signOut();
                }}
            >
                <img src={signOutIcons.iconSignOutV1} alt="Sign Out" /> <span>Logout</span>
            </Link>
        </InstOption>
    );
};

export default UserOption;
