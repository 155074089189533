import { useState } from 'react';
import { ButtonPurple } from '../../../../configs/Buttons';
import CpCreateWizardPopup from '../hostings/cpanel_create/CpCreateWizardPopup';
import BillingSection from '../hostings_Info_basic/basicinfo_billings/BasicInfoBillingSection';
import BasicInfoDzoneSection from '../hostings_Info_basic/basicinfo_dzone/BasicInfoDzoneSection';

function SetupView({ setModal, basicInfo }) {
    return (
        <div className="product-info cpanel mb-4">
            <div className="line-col-center">
                <span className="font-20 mb-4">You Didn&apos;t Set Up cPanel yet</span>
                <ButtonPurple
                    evOnClick={() => setModal({ type: 'create-cPanel', data: basicInfo })}
                    title="Create cPanel"
                />
            </div>
        </div>
    );
}

function CpanelSetupView({ basicInfo }) {
    const [modal, setModal] = useState({ type: '', data: {} });

    return (
        <div className="content-area hosting-info-page">
            <div className="content-body">
                <div
                    className={`content-body-scroll ${!basicInfo ? 'placeholder-mode' : ''}`}
                    style={{ paddingBottom: '150px' }}
                >
                    <SetupView setModal={setModal} basicInfo={basicInfo} />
                    <BillingSection basicInfo={basicInfo} />
                    <BasicInfoDzoneSection disablebasicInfo={basicInfo} />
                    <CpCreateWizardPopup
                        show={modal?.type === 'create-cPanel'}
                        setModal={setModal}
                        item={basicInfo}
                    />
                </div>
            </div>
        </div>
    );
}
export default CpanelSetupView;
