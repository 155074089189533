import LayoutSelector from '../../../../utils/LayoutSelector';
import AddressListTableMobile from './AddressListTableMobile';
import AddressListTableWeb from './AddressListTableWeb';

const demoArr = [
    {
        id: 1,
        isDefault: true,
        demo: true,
        name: '-------------',
        mobile: '---------',
        line1: '-----------------------',
        email: '-----------'
    }
];

function AddressListTable({ addressList, setModalData }) {
    return (
        <LayoutSelector
            sm
            md
            webComp={
                <AddressListTableWeb
                    addressList={addressList}
                    setModalData={setModalData}
                    demoArr={demoArr}
                />
            }
            mobileComp={
                <AddressListTableMobile
                    addressList={addressList}
                    setModalData={setModalData}
                    demoArr={demoArr}
                />
            }
        />
    );
}

export default AddressListTable;
