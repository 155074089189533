import React, { useEffect } from 'react';
import HeaderSection from '../../headers/HeaderSection';
import ServicesSection from '../../services/section/ServicesSection';
import FooterSection2 from '../../system/section/Footer2Section';
import HeroSection from '../../system/section/HeroSection';
import FixedHeaderControl from '../components/FixedHeaderControl';

function IndexPageBackup(props) {
    const { page } = props;

    useEffect(() => {
        if (page === 'services') {
            document.getElementById('services').scrollIntoView();
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [page]);

    return (
        <>
            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>

            <HeroSection hosting services />
            {/* <FeaturesSection /> */}
            <ServicesSection />

            <FooterSection2 />
        </>
    );
}

export default IndexPageBackup;
