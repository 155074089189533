import React, { useEffect, useState } from 'react';

function CopyText({ hidden, value, copyValue, cName, tooltipCname, arrow = true }) {
    const [copiedText, setCopiedText] = useState('Click To Copy');

    useEffect(() => {
        setTimeout(() => {
            setCopiedText('Click To Copy');
        }, 5000);
    });

    const handelCopy = (val) => {
        navigator.clipboard.writeText(val).then(
            () => {
                setCopiedText('Copied');
            },
            (err) => {
                console.log(err);
            }
        );
    };
    return (
        <span
            role="button"
            tabIndex={-1}
            onMouseLeave={() => setCopiedText('Click To Copy')}
            onClick={() => handelCopy(copyValue || value || '')}
            className={`tooltip ${tooltipCname}`}
            hidden={hidden}
        >
            <span className={cName}>{value}</span>
            <span hidden={!copiedText} className={`tooltip-text ${!arrow ? 'after-none' : ''}`}>
                {copiedText}
            </span>
        </span>
    );
}

export default CopyText;
