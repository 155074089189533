import { useEffect } from 'react';

import { DateInput } from '../../../utils/inputs/DateInputs';
import PageProperties from '../../../utils/PageProperties';
import { DashboardArrowHeader } from '../../headers/DashboardHeaderSection';
import ActivityLogList from './activitylog_list/ActivityLogList';

const demoArr = [
    {
        id: 1,
        profileId: 1,
        profilePhoto: 'https://demo.io.bikiran.com/profiles/profile-photo/10000115/photo.png',
        title: 'this is activity title',
        profileName: 'Name',
        timeLogged: 1679554764,

        demo: false
    },
    {
        id: 2,
        profileId: 2,
        profilePhoto: 'https://demo.io.bikiran.com/profiles/profile-photo/10000115/photo.png',
        title: 'this is activity title',
        profileName: 'Name',
        timeLogged: 1679554764,

        demo: false
    },
    {
        id: 3,
        profileId: 3,
        profilePhoto: 'https://demo.io.bikiran.com/profiles/profile-photo/10000115/photo.png',
        title: 'this is activity title',
        profileName: 'Name',
        timeLogged: 1679554764,

        demo: false
    },
    {
        id: 4,
        profileId: 4,
        profilePhoto: 'https://demo.io.bikiran.com/profiles/profile-photo/10000115/photo.png',
        title: 'this is activity title',
        profileName: 'Name',
        timeLogged: 1679554764,

        demo: false
    }
];

function ActivityLogPage() {
    // const [showOptions, setShowOptions] = useState('');
    // const user = {
    //     displayName: 'Yeasin Arafat',
    //     userName: 'yeasin2002',
    //     gender: 'male',
    //     dob: '1999-12-30'
    // };

    useEffect(() => {
        PageProperties(`Activity Log \u2022 Profile`);
    }, []);

    return (
        <div className="cell cell-content h-100">
            <div className="content-section">
                <div className="content-area">
                    <DashboardArrowHeader
                        title="Activity Log"
                        path="/user/basic-info/"
                        content={
                            <div className="ml-6">
                                <DateInput height="31px" width="150px" />
                            </div>
                        }
                        mobileContent={
                            <div className="line justify-end">
                                <DateInput height="30px" />
                            </div>
                        }
                    />

                    <div className="content-body-scroll">
                        <div className="user-activity-log-area">
                            <div className={`product-info mb-6 w-100 `}>
                                <ActivityLogList arr={demoArr} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ActivityLogPage;
