import { useState } from 'react';
import LayoutSelector from '../../../../utils/LayoutSelector';

import { useRole } from '../RoleProvider';
import OwnershipWarningPopup from '../role_list_popup/OwnershipWarningPopup';
import RoleErrorPopup from '../role_list_popup/RoleErrorPopup';
import RoleModifyPopup from '../role_list_popup/RoleModifyPopup';
import RoleListTableMobile from './RoleListTableMobile';
import RoleListTableWeb from './RoleListTableWeb';

function RoleListTable() {
    const [modal, setModal] = useState({ type: '', data: null });
    const { error } = useRole();

    return (
        <>
            <LayoutSelector
                sm
                md
                webComp={<RoleListTableWeb setModal={setModal} />}
                mobileComp={<RoleListTableMobile setModal={setModal} />}
            />

            <RoleModifyPopup
                show={modal?.type === 'role-modify'}
                setModal={setModal}
                modal={modal}
            />
            <OwnershipWarningPopup
                show={modal?.type === 'change-owner'}
                modal={modal}
                setModal={setModal}
            />
            <RoleErrorPopup
                show={modal?.type === 'error'}
                error={error}
                modal={modal}
                setModal={setModal}
            />
        </>
    );
}

export default RoleListTable;
