import { useState } from 'react';
import LayoutSelector from '../../../../../utils/LayoutSelector';
import ChatsColumn from './conversation_chats/ChatsColumn';
import TopicsColumn from './conversation_topics/TopicsColumn';

const arr = [
    {
        id: 1,
        users: ['Cristopher', 'Matt', 'John', 'Max', 'Well'],
        subject: 'Group con',
        chats: [
            {
                id: 1,
                isCurrent: false,
                message: [
                    {
                        id: 1,
                        title: 'Hello'
                    },
                    {
                        id: 2,
                        title: 'Can you please tell me What is education Management Software?'
                    },
                    {
                        id: 4,
                        title: 'Can you hear me ?'
                    },
                    {
                        id: 5,
                        title: 'Can you please tell me What is education Management Software?'
                    },
                    {
                        id: 6,
                        title: 'Can you hear me ?'
                    }
                ]
            },
            {
                id: 2,
                isCurrent: true,
                message: [
                    {
                        id: 7,
                        title: 'Will explain you soon. Thanks'
                    },
                    {
                        id: 8,
                        title: 'Wait'
                    },
                    {
                        id: 9,
                        title: 'Wait more'
                    },
                    {
                        id: 10,
                        title: 'Ok now its time to explain'
                    }
                ]
            }
        ]
    },
    {
        id: 2,
        users: ['Jerry'],
        subject: 'Group con-1',
        chats: [
            {
                id: 3,
                isCurrent: false,
                message: [
                    {
                        id: 1,
                        title: 'Hello'
                    },
                    {
                        id: 2,
                        title: 'How area you ?'
                    }
                ]
            },
            {
                id: 4,
                isCurrent: true,
                message: [
                    {
                        id: 7,
                        title: 'Good'
                    }
                ]
            }
        ]
    },
    {
        id: 3,
        users: ['Tom'],
        subject: 'Group con-2',
        chats: [
            {
                id: 5,
                isCurrent: false,
                message: [
                    {
                        id: 1,
                        title: 'Are you available ?'
                    },
                    {
                        id: 2,
                        title: 'Hello ?'
                    }
                ]
            },
            {
                id: 6,
                isCurrent: true,
                message: [
                    {
                        id: 7,
                        title: 'No I`m not'
                    }
                ]
            }
        ]
    }
];

function AreaWeb({ setShowData, showData }) {
    return (
        <div className="line h-100">
            <div className="cell cell-4 cell-xxl-5 cell-md-12">
                <TopicsColumn arr={arr} setShowData={setShowData} showData={showData} />
            </div>
            <div className="cell cell-8 cell-xxl-7 cell-md-12 d-md-none">
                <ChatsColumn arr={arr} setShowData={setShowData} showData={showData} />
            </div>
        </div>
    );
}
function AreaMobile({ setShowData, showData }) {
    // console.log(showData);

    if (showData.isClick) {
        return <ChatsColumn arr={arr} setShowData={setShowData} showData={showData} />;
    }
    return <TopicsColumn arr={arr} setShowData={setShowData} showData={showData} />;
}

function ConversationArea() {
    const [showData, setShowData] = useState({ id: 1, isClick: false });

    return (
        <div className="conversation-container">
            <LayoutSelector
                sm
                md
                webComp={<AreaWeb setShowData={setShowData} showData={showData} />}
                mobileComp={<AreaMobile setShowData={setShowData} showData={showData} />}
            />
        </div>
    );
}

export default ConversationArea;
