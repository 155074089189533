import { useEffect } from 'react';
import { ButtonGr } from '../../../../configs/Buttons';
import DevWrapper from '../../../utils/DevWrapper';
import PageProperties from '../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../headers/DashboardHeaderSection';
import ActivitySection from './activity/ActivitySection';
import PasswordSection from './password/PasswordSection';
import SecurityTwoFacAuthSection from './security_twofacauth/SecurityTwoFacAuthSection';

function Content() {
    return (
        <span className="line justify-end">
            <ButtonGr title="Contact Support" />
        </span>
    );
}

function ContentHead() {
    return (
        <DashboardDefaultHeader
            title="Security & Login"
            path="/user/basic-info/"
            content={<Content />}
            mobileContent={<Content />}
        />
    );
}

function SecurityPage() {
    useEffect(() => {
        PageProperties(`Security \u2022 Profile`);
    }, []);

    return (
        <div className="cell cell-content h-100 w-100">
            <div className="content-section">
                <div className="content-area">
                    <ContentHead />
                    <div
                        className="content-body-scroll security-area"
                        // style={{ paddingBottom: '180px' }}
                    >
                        <PasswordSection />
                        <DevWrapper>
                            <SecurityTwoFacAuthSection />
                        </DevWrapper>
                        <ActivitySection />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SecurityPage;
