import { useRef, useState } from 'react';
import { useAuth } from '../../../contexts/AuthProvider';
import CartIcon from './header-icons/CartIcon';
import NotificationIcon from './header-icons/NotificationIcon';
import SearchIcon from './header-icons/SearchIcon';
import MenuButtonArea from './MenuButtonArea';

function LoginArea({ currentUser, handleSearchIcon }) {
    const [showOptions, setShowOptions] = useState(false);
    const { makeAction, signOut } = useAuth();
    const myRef = useRef();
    const handleLoginButtonClick = () => {
        makeAction('sign-in');
    };

    return (
        <div className="line-align justify-end line-no-wrap position-relative">
            <SearchIcon handleSearchIcon={handleSearchIcon} />
            <NotificationIcon />
            <CartIcon />
            <MenuButtonArea
                setShowOptions={setShowOptions}
                currentUser={currentUser}
                myRef={myRef}
                showOptions={showOptions}
                signOut={signOut}
                handleLoginButtonClick={handleLoginButtonClick}
            />
        </div>
    );
}

export default LoginArea;
