import { createContext, useContext, useEffect, useState } from 'react';
import { showOfferPrice, showPrice } from '../app/websites/models/Pricing';
import AxiosAuth from '../utils/AxiosAuth';
import { mkToken } from '../utils/StringOperation';
import { useInit } from './InitProvider';

const CartContext = createContext();

export function useCart() {
    return useContext(CartContext);
}

function cart2store(productData, hash, operation) {
    return new Promise((resolve, reject) => {
        AxiosAuth.setApiUrl('CART', '/operation/:operation/', { operation })
            .post({
                hash,
                serviceId: productData.serviceId,
                packageId: productData.id,
                duration: productData.duration,
                type: productData.type,
                property: productData.property
            })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

function CartProvider({ children }) {
    const { locale } = useInit();
    const [duration, setDuration] = useState(12);
    const [cartData, setCartData] = useState([]);

    const resetState = () => {
        setDuration(12);
    };

    const handelMinusClick = (inc = 1) => {
        setDuration((currentState) => {
            if (currentState > 1) {
                return currentState - inc;
            }
            return currentState;
        });
    };

    const handelPlusClick = (inc = 1) => {
        setDuration((currentState) => {
            if (currentState < 10) {
                return currentState + inc;
            }
            return currentState;
        });
    };

    const handleAddToCart = (productData) =>
        new Promise((resolve, reject) => {
            const hash = mkToken();
            const typeAdd = productData?.type === 'domain_registration' ? 'add-domain' : 'add';
            cart2store(productData, hash, typeAdd)
                .then((data) => {
                    if (data.error === 0) {
                        setCartData(data?.cartData);
                        resetState();
                    }

                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const handleUpdateCartItem = (productData) =>
        new Promise((resolve, reject) => {
            console.log(productData);
            const typeAdd = productData?.type === 'domain' ? 'update-domain' : 'update';
            cart2store(productData, productData.hash, typeAdd)
                .then((data) => {
                    if (data.error === 0) {
                        setCartData(data?.cartData);
                        resetState();
                    }
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const handleDeleteFromCart = (productData) =>
        new Promise((resolve, reject) => {
            cart2store(productData, productData.hash, 'remove')
                .then((data) => {
                    if (data.error === 0) {
                        setCartData(data?.cartData);
                        resetState();
                    }
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const handleEmptyCart = () =>
        new Promise((resolve, reject) => {
            cart2store({}, null, 'empty')
                .then((data) => {
                    if (data.error === 0) {
                        setCartData(data?.cartData);
                        resetState();
                    }
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const handelCreateInvoice = (currentUser) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CART', '/invoice/create/')
                .post()
                .then(({ data }) => {
                    if (data.error === 0) {
                        setCartData([]);
                        resetState();
                    }
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    useEffect(() => {
        // cart2store(productData, hash, operation)
        cart2store({}, null, 'data').then((data) => {
            if (data?.error === 0) {
                setCartData(data?.cartData);
                resetState();
            }
        });
    }, [locale.currency]);

    // --Total Price Calculation
    const totalOfferPrice = cartData.reduce(
        (previousValue, item) => showOfferPrice(item, item.duration) + previousValue,
        0
    );

    const totalPrice = cartData.reduce(
        (previousValue, item) => showPrice(item, item.duration) + previousValue,
        0
    );

    const value = {
        resetState,
        duration,
        setDuration,
        handelMinusClick,
        handelPlusClick,
        cartData,
        handleAddToCart,
        handleUpdateCartItem,
        handleDeleteFromCart,
        handleEmptyCart,
        handelCreateInvoice,
        totalOfferPrice,
        totalPrice
    };

    return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}

export default CartProvider;
