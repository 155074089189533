import { useState } from 'react';
import CopyText from '../../../websites/models/CopyText';
import { useDomainInfo } from '../../domains_info/DomainInfoBasicProvider';
import BasicInfoDomainLock from '../basicinfo_info_domainlock/BasicInfoDomainLock';
import BasicInfoDomainSecret from '../basicinfo_info_domainsecret/BasicInfoDomainSecret';

function LineCommonComponent({ title, value }) {
    return (
        <div className="line mb-3">
            <div className="cell cell-5">
                <span className="subject">{title}:</span>
            </div>
            <div className="cell cell-7">
                <span className="value line-align">{value || '--'}</span>
            </div>
        </div>
    );
}

function CurrentStatusView({ status }) {
    const cName = status === 'active' ? 'circle-enable' : 'circle-disable';

    return (
        <>
            <div className={`${cName} mr-2`} />

            {status ? (
                <span className="value">{status}</span>
            ) : (
                <span className="value">Not Defined</span>
            )}
        </>
    );
}

function VerificationStatusView({ raaVerificationStatus }) {
    const cName = raaVerificationStatus === 'Verified' ? 'circle-enable' : 'circle-disable';

    return (
        <>
            <div className={`${cName} mr-2`} />
            {raaVerificationStatus ? (
                <span className="value">{raaVerificationStatus}</span>
            ) : (
                <span className="value">Not Defined</span>
            )}
        </>
    );
}

function NameServerView({ nsAr }) {
    return (
        <div className="line mb-3">
            <div className="cell cell-5">
                <span className="subject">Name Server:</span>
            </div>
            <div className="cell cell-7 break-all">
                {nsAr?.map((item) => (
                    <div key={item}>
                        <span key={item} className="value">
                            {item}
                        </span>
                        <br />
                    </div>
                ))}
            </div>
        </div>
    );
}

function DomainDetails({ domain, status, raaVerificationStatus, nsAr }) {
    return (
        <div className="line mb-1">
            <div className="cell cell-6 cell-sm-12">
                <LineCommonComponent
                    title="Domain Name"
                    value={<CopyText tooltipCname="w-100" cName="domain-value" value={domain} />}
                />
                <LineCommonComponent
                    title="Current Status"
                    value={<CurrentStatusView status={status} />}
                />
                <LineCommonComponent
                    title="Verification Status"
                    value={<VerificationStatusView raaVerificationStatus={raaVerificationStatus} />}
                />

                <NameServerView nsAr={nsAr} />
            </div>
            <div className="cell cell-6 cell-sm-12">
                <BasicInfoDomainLock />
                <BasicInfoDomainSecret />
                {/* <BasicInfoPrivacy /> */}
            </div>
        </div>
    );
}

function Divider() {
    return (
        <div className="line align-end line-no-wrap mb-4">
            <div className="divider-title">Registrant Details</div>{' '}
            <hr style={{ opacity: '30%' }} className="w-100 h-100 ml-2" />
        </div>
    );
}

function RegDetails({ details }) {
    return (
        <div className="line">
            <div className="cell cell-6 cell-sm-12">
                <LineCommonComponent title="Name" value={details?.name} />

                <LineCommonComponent title="Email Address" value={details?.email} />

                <LineCommonComponent title="Telephone" value={details?.telephone} />

                <LineCommonComponent title="Phone Number" value={details?.phone} />
            </div>

            <div className="cell cell-6 cell-sm-12">
                <LineCommonComponent
                    title="Address"
                    value={
                        <span
                            className="value"
                            style={{ fontSize: '15px', wordBreak: 'break-word' }}
                        >
                            {[
                                details?.address?.line1,
                                details?.address?.line2,
                                details?.address?.line3
                            ]
                                .filter((item) => !!item)
                                .join(', ') || '--'}
                        </span>
                    }
                />

                <LineCommonComponent title="City" value={details?.address?.city} />

                <LineCommonComponent title="State" value={details?.address?.state} />

                <LineCommonComponent title="Zip Code" value={details?.address?.zip} />

                <LineCommonComponent
                    title="Country"
                    value={<span className="value">{details?.address?.countryFull || '--'}</span>}
                />
            </div>
        </div>
    );
}

function InfoSection() {
    const [modalItem, setModalItem] = useState(false);

    const {
        domain,
        status,
        raaVerificationStatus,
        isTheftProtectedAllowed,
        price,
        timeIssue,
        daysRemainText,
        timeExpire,
        nsAr,
        contactAr
    } = useDomainInfo();

    const details = contactAr?.registrant;

    return (
        <div className={`product-info mb-6 mb-sm-0 ${!domain ? 'placeholder-mode' : ''}`}>
            <DomainDetails
                domain={domain}
                status={status}
                raaVerificationStatus={raaVerificationStatus}
                isTheftProtectedAllowed={isTheftProtectedAllowed}
                price={price}
                timeIssue={timeIssue}
                daysRemainText={daysRemainText}
                timeExpire={timeExpire}
                nsAr={nsAr}
            />

            <Divider modalItem={modalItem} setModalItem={setModalItem} />

            <RegDetails details={details} />
        </div>
    );
}

export default InfoSection;
