import { useRef } from 'react';
import { useTemplate } from '../../../contexts/TemplateProvider';

const BtnChangePassword = ({ handleChangePassword, formData, setInitialState, setExitModal }) => {
    const btnRef = useRef();
    const { setMessage } = useTemplate();

    const handelOnClick = () => {
        setMessage('Changing Password...');
        btnRef.current.disabled = true;

        handleChangePassword()
            .then(({ error, message }) => {
                if (error === 0) {
                    setInitialState();
                    setExitModal();
                }
                setMessage(message);
                btnRef.current.disabled = false;
            })
            .catch(() => {
                btnRef.current.disabled = false;
            });
    };

    console.log(formData);

    if (formData.otp.length < 6) {
        return (
            <button className="button button-gr-pink w-100 login-btn" type="button" disabled>
                Update Password
            </button>
        );
    }

    return (
        <button
            className="button button-gr-pink w-100 login-btn"
            type="button"
            onClick={handelOnClick}
            ref={btnRef}
        >
            Update Password
        </button>
    );
};

const BtnVerifyOtp = ({ handelVerifyOtp, formData, setFormData }) => {
    const btnRef = useRef();
    const { setMessage } = useTemplate();

    const handelOnClick = () => {
        setMessage('Verifying OTP...');
        btnRef.current.disabled = true;

        handelVerifyOtp()
            .then(({ error, message }) => {
                setMessage(message);
                if (error) {
                    setFormData((cuData) => ({ ...cuData, otp: '', otpKey: Math.random() }));
                }
                btnRef.current.disabled = false;
            })
            .catch(() => {
                btnRef.current.disabled = false;
            });
    };

    if (formData.otp.length < 6) {
        return (
            <button className="button button-gr-pink w-100 login-btn" type="button" disabled>
                Verify OTP
            </button>
        );
    }

    return (
        <button
            className="button button-gr-pink w-100 login-btn"
            type="button"
            onClick={handelOnClick}
            ref={btnRef}
        >
            Verify OTP
        </button>
    );
};

const BtnSendOtp = ({ handelOtpSend }) => {
    const btnRef = useRef();
    const { setMessage } = useTemplate();

    const onCLickOtpSend = () => {
        setMessage('Verifying OTP...');
        btnRef.current.disabled = true;

        handelOtpSend()
            .then(({ message }) => {
                setMessage(message);
                btnRef.current.disabled = false;
            })
            .catch(() => {
                btnRef.current.disabled = false;
            });
    };

    return (
        <button
            className="button button-gr-pink w-100 login-btn"
            type="button"
            onClick={onCLickOtpSend}
            ref={btnRef}
        >
            Send OTP
        </button>
    );
};

function ForgetPasswordButtons({
    formData,
    setFormData,
    handelVerifyOtp,
    handelOtpSend,
    handleChangePassword,
    setInitialState,
    setExitModal
}) {
    if (formData.sentId && formData.otpIsVerified) {
        return (
            <BtnChangePassword
                handleChangePassword={handleChangePassword}
                formData={formData}
                setInitialState={setInitialState}
                setExitModal={setExitModal}
            />
        );
    }

    if (formData.sentId) {
        return (
            <BtnVerifyOtp
                handelVerifyOtp={handelVerifyOtp}
                formData={formData}
                setFormData={setFormData}
            />
        );
    }

    return <BtnSendOtp handelOtpSend={handelOtpSend} />;
}

export default ForgetPasswordButtons;
