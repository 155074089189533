import { icons } from '../../../../configs/Icons';

function TabContacts({ disabled, evOnClick, contacts }) {
    return (
        <div
            className={`cell cell-fill cell-md-4 cell-xl-3 cell-sm-5 ${
                disabled ? 'disabled-view' : ''
            }`}
            onClick={evOnClick}
            role="button"
            tabIndex={-1}
        >
            <div
                role="button"
                tabIndex={-1}
                className={`info-tab-item contacts line-row-center ${contacts ? 'active' : ''}`}
            >
                <div className="line-col-center">
                    <div className="logo">
                        <img
                            className="w-100 h-100 d-block"
                            src={contacts ? icons.iconContactWhite : icons.iconContactPaste}
                            alt="ns"
                        />
                    </div>
                    <div className="title">
                        <span>Contacts</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TabContacts;
