import { useRef, useState } from 'react';

import { icons } from '../../../../../configs/Icons';
import DeletePrimaryPopup from '../../_models/DeletePrimaryPopup';
import AddressListTableActionBtn from './AddressListTableActionBtn';

function NameArea({ data }) {
    return (
        <div className="">
            <div className="line-align">
                <span className="title gap">{data.name}</span>
                {data?.isDefault ? (
                    <span className="ml-2">
                        <img className="d-block" src={icons.iconP} alt="primary icon" />
                    </span>
                ) : null}
            </div>
            <div className="sub-title primary-70-i d-block">
                <span className="d-block">{data?.mobile}</span>
            </div>
        </div>
    );
}

function NameRow({ data, setModalData, setShowOptions, showOptions, myRef }) {
    return (
        <div className="line-row-between align-unset line-no-wrap mb-2">
            <NameArea data={data} />

            <AddressListTableActionBtn
                setModalData={setModalData}
                setShowOptions={setShowOptions}
                showOptions={showOptions}
                myRef={myRef}
                data={data}
            />
        </div>
    );
}

function AddressRow({ data }) {
    return (
        <div className="w-100">
            <span className="font-14 d-block">{data?.email || '--'}</span>
            <span className="font-14 break-all">
                {data?.line1 || data?.line2 || data?.line3 || '--'}
            </span>
        </div>
    );
}

function AddressListTableMobile({ addressList, setModalData, demoArr }) {
    const [showOptions, setShowOptions] = useState(false);
    const ref = useRef();
    const arr = addressList || demoArr;

    if (!arr?.length) {
        return (
            <div className="list-item-sm">
                <span className="title primary-70-i">No Address Found !</span>
            </div>
        );
    }
    return arr?.map((data) => (
        <div key={data?.id} className={`list-item-sm ${data?.demo ? 'placeholder-mode' : ''}`}>
            <NameRow
                data={data}
                setModalData={setModalData}
                setShowOptions={setShowOptions}
                showOptions={showOptions}
                myRef={ref}
            />
            <AddressRow data={data} />
            <DeletePrimaryPopup
                show={showOptions === 'default'}
                setShowOptions={setShowOptions}
                title="Address"
            />
        </div>
    ));
}

export default AddressListTableMobile;
