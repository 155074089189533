import { arrowIcons, icons, osIcon, userIcons } from '../../../../../../../configs/Icons';
import TeamAvatarRow from '../../conversation_list/TeamAvatarRow';

// const chats = [
//     {
//         id: 1,
//         isCurrent: false,
//         users: [
//             {
//                 id: 1,
//                 title: 'Hello'
//             },
//             {
//                 id: 2,
//                 title: 'Can you please tell me What is education Management Software?'
//             },
//             {
//                 id: 4,
//                 title: 'Can you hear me ?'
//             },
//             {
//                 id: 5,
//                 title: 'Can you please tell me What is education Management Software?'
//             },
//             {
//                 id: 6,
//                 title: 'Can you hear me ?'
//             }
//         ]
//     },
//     {
//         id: 2,
//         isCurrent: true,
//         users: [
//             {
//                 id: 7,
//                 title: 'Will explain you soon. Thanks'
//             },
//             {
//                 id: 8,
//                 title: 'Wait'
//             },
//             {
//                 id: 9,
//                 title: 'Wait more'
//             },
//             {
//                 id: 10,
//                 title: 'Ok now its time to explain'
//             }
//         ]
//     }
// ];

const team = [
    {
        img: userIcons.iconUserV3
    },
    {
        img: osIcon.mac
    },
    {
        img: userIcons.iconUserV3
    },
    {
        img: osIcon.mac
    },
    {
        img: userIcons.iconUserV3
    }
];

function HeaderSection({ data, setShowData }) {
    return (
        <div className="header header-right">
            <div className="line-row-between line-no-wrap w-100">
                <div className="cell cell-fill">
                    <TopicDetails data={data} setShowData={setShowData} />
                </div>
                <div className="cell">
                    <DotMenu />
                </div>
            </div>
        </div>
    );
}

function AttachIcon() {
    return (
        <div role="button" tabIndex={-1} className="action-icon ml-2">
            <img className="d-block" src={icons.iconClip} alt="" />
        </div>
    );
}
function EmojiIcon() {
    return (
        <div role="button" tabIndex={-1} className="action-icon">
            <img className="d-block" src={icons.iconSmile} alt="" />
        </div>
    );
}

function ChatInput() {
    return (
        <div className="chat-input">
            <div className="line-row-between line-no-wrap">
                <input placeholder="Hello There..." type="text" className="" />

                <div role="button" tabIndex={-1} className="send-button">
                    <img className="d-block" src={icons.iconSendPurple} alt="" />
                </div>
            </div>
        </div>
    );
}

function FooterSection() {
    return (
        <div className="chat-footer-area">
            <div className="line-row-between line-no-wrap line-g2 line-sm-g1">
                <div className="cell">
                    <AttachIcon />
                </div>
                <div className="cell">
                    <EmojiIcon />
                </div>
                <div className="cell cell-fill">
                    <ChatInput />
                </div>
            </div>
        </div>
    );
}

function TopicDetails({ data, setShowData }) {
    return (
        <div className="line-align line-g1 line-no-wrap">
            <div
                className="cell d-none d-md-block"
                role="button"
                tabIndex={-1}
                onClick={() => setShowData({ isClick: false })}
            >
                <img className="d-block" src={arrowIcons.iconArrowLeftV2} alt="" />
            </div>
            <div className="cell">
                <div className="avatar">
                    <img className="d-block w-100" src={userIcons.iconUserV3} alt="" />
                </div>
            </div>
            <div className="cell cell-fill overflow-dotted">
                <div className="">
                    <span className="font-4 primary-50 no-wrap">{data.subject}</span>
                    <TeamAvatarRow arr={team} width="17px" />
                </div>
            </div>
        </div>
    );
}

function DotMenu() {
    return (
        <div className="dot-menu">
            <span>&bull;</span>
            <span>&bull;</span>
            <span>&bull;</span>
        </div>
    );
}

function ChatBubble({ color, data }) {
    if (data?.isCurrent) {
        return (
            <div className="current">
                {data?.message?.map((text) => (
                    <div key={text?.id} style={{ color }} className="chat-bubble">
                        <span className="user-name">Cristopher Henry</span>
                        <div className="text">{text?.title}</div>
                    </div>
                ))}
            </div>
        );
    }
    return (
        <div className="others">
            {data?.message?.map((text) => (
                <div key={text?.id} style={{ color }} className="chat-bubble">
                    <span className="user-name">Cristopher Henry</span>
                    <div className="text">{text?.title}</div>
                </div>
            ))}
        </div>
    );
}

function BodySection({ data }) {
    return (
        <div className="body overflow-auto">
            <div>
                {data?.chats.map((item) => (
                    <ChatBubble key={item?.id} data={item} />
                ))}
            </div>
        </div>
    );
}

function ChatsColumn({ arr, setShowData, showData }) {
    return arr
        ?.filter((el) => el.id === showData.id)
        ?.map((data) => (
            <div key={data.id} className="chats-column-section">
                <HeaderSection data={data} setShowData={setShowData} />
                <BodySection data={data} />
                <FooterSection data={data} />
            </div>
        ));
}

export default ChatsColumn;
