import { useEffect, useState } from 'react';
import { ButtonClose } from '../../../../configs/Buttons';

import { icons } from '../../../../configs/Icons';

const ModalHeader = ({ handleCloseClick }) => (
    <div className="modal-box-header mb-5" style={{ borderBottom: '1px solid gray' }}>
        <div>
            <div className="modal-box-title">Icon Code</div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={handleCloseClick} />
            </div>
        </div>
    </div>
);

function ModalBody({ code }) {
    const [mode, setMode] = useState(null);
    useEffect(() => {
        setTimeout(() => {
            setMode(false);
        }, 5000);
    });
    return (
        <div className="container h-100 text-center mb-5">
            {/* <span className="cell-12 mb-1 green">{text}</span> */}
            {mode && <span className="i-copied-text">Copied</span>}
            <div className="icon-code-area">
                <div className="input-area line-align line-no-wrap">
                    <input type="text" value={code} readOnly className="w-100" />
                    <span
                        className="line"
                        role="button"
                        tabIndex={-1}
                        onClick={() => {
                            navigator.clipboard.writeText(code);
                            if (navigator.clipboard.writeText(code)) {
                                setMode(true);
                            }
                        }}
                    >
                        <img
                            className="w-100"
                            src={mode ? icons.iconCheckD : icons.iconCopy}
                            alt="icon"
                        />
                    </span>
                </div>
            </div>
        </div>
    );
}

function IconsInfoModal({ setOpenModal, openModal, arrName, name }) {
    const code = `${arrName}.${name}`;
    const handleCloseClick = () => setOpenModal(null);

    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content" style={{ maxWidth: '400px' }}>
                <ModalHeader handleCloseClick={handleCloseClick} />

                <ModalBody
                    code={code}
                    style={{ width: '90%' }}
                    handleCloseClick={handleCloseClick}
                    openModal={openModal}
                />
            </div>
        </div>
    );
}

export default IconsInfoModal;
