import React from 'react';
import ProfileInfoPersonalSection from '../profile_info_personal/ProfileInfoPersonalSection';
import ProfileInfoSocialSection from '../profile_info_social/ProfileInfoSocialSection';

function Divider({ title }) {
    return (
        <div className="line-align line-no-wrap mb-2">
            <div className="cell title fw-400">{title}</div>
            <hr className="cell-fill cell-self-end ml-1" style={{ opacity: '0.5' }} />
        </div>
    );
}

function AddressInfo({ info }) {
    return (
        <div className="line-align line-no-wrap mb-4">
            <div className="cell-2 cell-md-5">
                <span className="title common-value fw-500">Address:</span>
            </div>
            <div className="cell-10 cell-md-7">
                <span className="value common-value fw-400 address-text">
                    {info?.address?.line1 || '--'}
                </span>
            </div>
        </div>
    );
}

function ProfileInfoSection({ currentUser, info }) {
    return (
        <div className="product-info mb-4 profile-info-area">
            <ProfileInfoPersonalSection currentUser={currentUser} info={info} />

            <Divider title="Location" />

            <AddressInfo info={info} />

            <Divider title="Social Media" />

            <ProfileInfoSocialSection />
        </div>
    );
}

export default ProfileInfoSection;
