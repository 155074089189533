import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import PageProperties from '../../../utils/PageProperties';
import HostingInfoHeader from '../hostings_info_header/HostingInfoHeader';
import TabsSection from '../hostings_info_tabs/TabSection';
import ConversationListSection from '../_models/conversation/conversation_list/ConversationListSection';
import HostingInfoConversationProvider, {
    useHostingConversation
} from './HostingInfoConversationProvider';

function ContentArea() {
    const { conversationInfo, handelReloadButton } = useHostingConversation();
    return (
        <>
            <HostingInfoHeader
                path="conversation/"
                info={conversationInfo}
                evReloadClick={handelReloadButton}
            />
            <div className="content-body">
                <div className="content-body-scroll">
                    <TabsSection conversation info={conversationInfo} />
                    <div className={!conversationInfo ? 'placeholder-mode' : ''}>
                        <ConversationListSection />
                    </div>
                </div>
            </div>
        </>
    );
}

function HostingInfoConversationListPage() {
    const { contractId } = useParams();
    const { currentUser } = useAuth();

    useEffect(() => {
        PageProperties(`Conversation \u2022 Hosting`);
    }, []);

    return (
        <HostingInfoConversationProvider currentUser={currentUser} contractId={contractId}>
            <div className="content-area hosting-info-page">
                <ContentArea />
            </div>
        </HostingInfoConversationProvider>
    );
}

export default HostingInfoConversationListPage;
