// folder
import iconBikBalance from '../assets/images/icon/icon-bik-balance.svg';
import iconBikCoin from '../assets/images/icon/icon-bik-coin.svg';
import iconSendFill from '../assets/images/icon/icon-send-fill.svg';
import iconSoftwareFill from '../assets/images/icon/icon-software-fill.svg';

import iconBikLogoV3 from '../assets/images/icon/icon-bik-logoV2.svg';
import iconBikLogoV4 from '../assets/images/icon/icon-bik-logoV4.svg';
import iconCartBlack from '../assets/images/icon/icon-cart-black.svg';
import iconCartPurple from '../assets/images/icon/icon-cart-purple.svg';
import iconClip from '../assets/images/icon/icon-clip.svg';
import iconDnsFill from '../assets/images/icon/icon-dns-fill.svg';
import iconDotV2 from '../assets/images/icon/icon-dot-menuV2.svg';
import iconHomeBlack from '../assets/images/icon/icon-home-black.svg';
import iconInfoRed from '../assets/images/icon/icon-info-red.svg';
import iconInfoWhite from '../assets/images/icon/icon-info-white.svg';
import iconSendPurple from '../assets/images/icon/icon-send-purple.svg';
import iconServiceBlack from '../assets/images/icon/icon-service-black.svg';
import iconServicePurple from '../assets/images/icon/icon-service-purple.svg';
import iconShare from '../assets/images/icon/icon-share.svg';
import iconSmile from '../assets/images/icon/icon-smile.svg';
import iconUserBlack from '../assets/images/icon/icon-user-black.svg';
import iconUserPurple from '../assets/images/icon/icon-user-purple.svg';
import iconWalletV2 from '../assets/images/icon/icon-walletV2.svg';

const api = 'https://files.bikiran.com/';

const iconCloseModal = `${api}assets/images/btn-close.svg`;
/* modal close icon */
const iconConverterP = `${api}assets/images/convertIcon.svg`;
const iconCrossV2 = `${api}assets/images/CrossIcon.svg`;
const iconDelV4 = `${api}assets/images/dm-del.svg`;
const iconEditV2 = `${api}assets/images/dm-edit.svg`;
const iconAddV2 = `${api}assets/images/icon/add-server.svg`;
const iconActivityActive = `${api}assets/images/icon/icon-activity-active.svg`;
const iconActivityLog = `${api}assets/images/icon/icon-activity-log.svg`;
const iconActivity = `${api}assets/images/icon/icon-activity.svg`;
const iconArrowNewTabV2 = `${api}assets/images/icon/icon-arrow-new-tab-v2.svg`;
const iconArrowNewTab = `${api}assets/images/icon/icon-arrow-new-tab.svg`;
const iconArrowLeftV3 = `${api}assets/images/icon/icon-arrow-rightV3.svg`;
const iconArrowBottomV4 = `${api}assets/images/icon/icon-arrowV2.svg`;
const iconBackupActive = `${api}assets/images/icon/icon-backup-active.svg`;
const iconBackupBg = `${api}assets/images/icon/icon-backup-bg.svg`;
const iconBackup = `${api}assets/images/icon/icon-backup.svg`;
const iconBikLogoV2 = `${api}assets/images/icon/icon-bik-logo.svg`;
const iconBillingV1 = `${api}assets/images/icon/icon-billing-bg.svg`;
const iconCloseMinus = `${api}assets/images/icon/icon-btn-close-minus.svg`;
const iconCalender = `${api}assets/images/icon/icon-calendar.svg`;
const iconCartV2 = `${api}assets/images/icon/icon-cart-bg.svg`;
const iconCartInactive = `${api}assets/images/icon/icon-cart-inactive.svg`;
const iconCartV1 = `${api}assets/images/icon/icon-cart.svg`;
const iconChatActive = `${api}assets/images/icon/icon-chat-activeV1.svg`;
const iconChat = `${api}assets/images/icon/icon-chat.svg`;
const iconCloseMinusActive = `${api}assets/images/icon/icon-close-minus-active.svg`;
const iconCloudBg = `${api}assets/images/icon/icon-cloud-s.svg`;
const iconCnsBlueActive = `${api}assets/images/icon/icon-cns-blue-active.svg`;
const iconCnsBlue = `${api}assets/images/icon/icon-cns-blue.svg`;
const iconConfigActive = `${api}assets/images/icon/icon-config-active.svg`;
const iconConfigDark = `${api}assets/images/icon/icon-config-dark.svg`;
const iconConfigLight = `${api}assets/images/icon/icon-config-light.svg`;
const iconContactActive = `${api}assets/images/icon/icon-contact-active.svg`;
const iconContactV1 = `${api}assets/images/icon/icon-contact-bg.svg`;
const iconContactDel = `${api}assets/images/icon/icon-contact-del.svg`; /* users contact page delete icon */
const iconContactInactive = `${api}assets/images/icon/icon-contact-inactive.svg`;
const iconContactPaste = `${api}assets/images/icon/icon-contact-paste.svg`;
const iconContactWhite = `${api}assets/images/icon/icon-contact-white.svg`;
const iconContractActive = `${api}assets/images/icon/icon-contract-active.svg`;
const iconContractGreen = `${api}assets/images/icon/icon-contract-green.svg`;
const iconContractPurple = `${api}assets/images/icon/icon-contract-purple.svg`;
const iconContract = `${api}assets/images/icon/icon-contract.svg`;
const iconCopy = `${api}assets/images/icon/icon-copy-text.svg`;
const iconCopyV2 = `${api}assets/images/icon/icon-copyV2.svg`;
const iconCpWhite = `${api}assets/images/icon/icon-cp-white.svg`;
const imgCpanelV2 = `${api}assets/images/icon/icon-cpanelV2.svg`;
const iconCpu = `${api}assets/images/icon/icon-cpu.svg`;
const iconCurrencyExchange = `${api}assets/images/icon/icon-currency-exchange.svg`;
const iconDtransferActive = `${api}assets/images/icon/icon-d-transferV2-active.svg`;
const iconDtransfer = `${api}assets/images/icon/icon-d-transferV2.svg`;
const iconD = `${api}assets/images/icon/icon-d.svg`;
const iconDark = `${api}assets/images/icon/icon-dark-mode.svg`;
const iconDashboardV2 = `${api}assets/images/icon/icon-dashboard-1.svg`;
const iconDashboardActive = `${api}assets/images/icon/icon-dashboard-active.svg`;
const iconDashboardInactive = `${api}assets/images/icon/icon-dashboard-inactive.svg`;
const iconDashboardV4 = `${api}assets/images/icon/icon-dashboardV3.svg`;
const iconDatabaseActive = `${api}assets/images/icon/icon-database-active.svg`;
const imgDatabase = `${api}assets/images/icon/icon-database.svg`;
const iconDbBg = `${api}assets/images/icon/icon-db-bg.svg`;
const iconDedicatedBg = `${api}assets/images/icon/icon-dedicated-s.svg`;
const iconDelV5 = `${api}assets/images/icon/icon-deleteV5.svg`;
const iconDeveloperOption = `${api}assets/images/icon/icon-developer-option.svg`;
const iconDiskFill = `${api}assets/images/icon/icon-disk-fill.svg`;
const iconDiskFillV2 = `${api}assets/images/icon/icon-disk-fillV2.svg`;
const iconDislikePurple = `${api}assets/images/icon/icon-dislike-purple.svg`;
const iconDislikeWhite = `${api}assets/images/icon/icon-dislike-white.svg`;
const iconDnsActive = `${api}assets/images/icon/icon-dns-active.svg`;
const iconDnsBg = `${api}assets/images/icon/icon-dns-bg.svg`;
const iconDns = `${api}assets/images/icon/icon-dns.svg`;
const iconSearchV2 = `${api}assets/images/icon/icon-domain-search.svg`;
const iconDoneBg = `${api}assets/images/icon/icon-done-bg.svg`;
const iconDotMenuActiveV1 = `${api}assets/images/icon/icon-dot-menu-activeV1.svg`;
const iconDotMenuV1 = `${api}assets/images/icon/icon-dot-menuV1.svg`;
const iconDotOpActive = `${api}assets/images/icon/icon-dot-option-active.svg`;
const iconDotOpH = `${api}assets/images/icon/icon-dot-option-hover.svg`;
const iconDotOp = `${api}assets/images/icon/icon-dot-option.svg`;
const iconDownV2 = `${api}assets/images/icon/icon-down-v3.svg`;
const iconDownloadV1 = `${api}assets/images/icon/icon-download-v1.svg`;
const iconEditV2Active = `${api}assets/images/icon/icon-editV2-active.svg`;
const iconEmailV3 = `${api}assets/images/icon/icon-emailV3.svg`;
const iconEp = `${api}assets/images/icon/icon-ep.svg`;
const iconExportBulk = `${api}assets/images/icon/icon-export-bulk.svg`;
const iconExportLight = `${api}assets/images/icon/icon-export-light.svg`;
const iconExportV2 = `${api}assets/images/icon/icon-exportV2.svg`;
const iconEyeActive = `${api}assets/images/icon/icon-eye-active.svg`;
const iconEyeInactive = `${api}assets/images/icon/icon-eye-inactive.svg`;
const iconFaqActive = `${api}assets/images/icon/icon-faq-active.svg`;
const iconFaq = `${api}assets/images/icon/icon-faq.svg`;
const imgFileV2 = `${api}assets/images/icon/icon-file.svg`;
const iconFilter = `${api}assets/images/icon/icon-filter.svg`;
const iconHomePurple = `${api}assets/images/icon/icon-home-purple.svg`;
const iconHostingV2 = `${api}assets/images/icon/icon-hosting-q.svg`;
const iconInfoActive = `${api}assets/images/icon/icon-info-active.svg`;
const iconInfoInactive = `${api}assets/images/icon/icon-info-inactive.svg`;
const iconInfoSky = `${api}assets/images/icon/icon-info-sky.svg`;
const iconInfoYellow = `${api}assets/images/icon/icon-info-yellow.svg`;
const iconInvoiceCandleActive = `${api}assets/images/icon/icon-invoice-cancel-active.svg`;
const iconInvoiceCancel = `${api}assets/images/icon/icon-invoice-cancel.svg`;
const iconIo = `${api}assets/images/icon/icon-io.svg`;
const iconLight = `${api}assets/images/icon/icon-light-mode.svg`;
const iconLikePurple = `${api}assets/images/icon/icon-like-purple.svg`;
const iconLikeWhite = `${api}assets/images/icon/icon-like-white.svg`;
const iconLockPurple = `${api}assets/images/icon/icon-lock-purple.svg`;
const iconSignOutInactive = `${api}assets/images/icon/icon-logout-active.svg`;
const iconSignOutV3 = `${api}assets/images/icon/icon-logoutV3.svg`;
const iconM = `${api}assets/images/icon/icon-m.svg`;
const iconMailActive = `${api}assets/images/icon/icon-mail-activeV2.svg`;
const iconMailV1 = `${api}assets/images/icon/icon-mailV1.svg`;
const iconMailV2 = `${api}assets/images/icon/icon-mailV2.svg`;
const iconMessageActive = `${api}assets/images/icon/icon-message-active.svg`;
const iconMessageInactive = `${api}assets/images/icon/icon-message-inactive.svg`;
const iconUserV5 = `${api}assets/images/icon/icon-my-profile-1.svg`;
const iconMysql = `${api}assets/images/icon/icon-mysql.svg`;
const iconNotificationV1 = `${api}assets/images/icon/icon-notification.svg`;
const iconNsActive = `${api}assets/images/icon/icon-ns-active.svg`;
const iconNsPurple = `${api}assets/images/icon/icon-ns-purple.svg`;
const iconNsV2 = `${api}assets/images/icon/icon-ns.svg`;
const iconP = `${api}assets/images/icon/icon-p.svg`;
const iconPackageActiveV2 = `${api}assets/images/icon/icon-package-activeV2.svg`;
const iconPackageInactiveV2 = `${api}assets/images/icon/icon-package-inactiveV2.svg`;
const iconPauseRed = `${api}assets/images/icon/icon-pause-red.svg`;
const iconPhonePrimary = `${api}assets/images/icon/icon-phone-primary.svg`;
const iconPhoneSec = `${api}assets/images/icon/icon-phone-sec.svg`;
const imgPhpV2 = `${api}assets/images/icon/icon-phpV2.svg`;
const iconPlayRed = `${api}assets/images/icon/icon-play-red.svg`;
const iconRamFill = `${api}assets/images/icon/icon-ram-fill.svg`;
const iconReGenerate = `${api}assets/images/icon/icon-re-generate.svg`;
const imgRedirectPurple = `${api}assets/images/icon/icon-redirect-blue.svg`;
const iconReloadActive = `${api}assets/images/icon/icon-reload-active.svg`;
const iconReload = `${api}assets/images/icon/icon-reload.svg`;
const iconRemoveCp = `${api}assets/images/icon/icon-remove-cp.svg`;
const iconReportInactive = `${api}assets/images/icon/icon-report-inactive.svg`;
const imgResetActive = `${api}assets/images/icon/icon-reset-active.svg`;
const imgReset = `${api}assets/images/icon/icon-reset.svg`;
const iconS = `${api}assets/images/icon/icon-s.svg`;
const iconSearchM = `${api}assets/images/icon/icon-search-m.svg`;
const iconSecurityPurple = `${api}assets/images/icon/icon-security-purple.svg`;
const iconSecurityV2 = `${api}assets/images/icon/icon-security.svg`;
const iconDashboardV3 = `${api}assets/images/icon/icon-sms-dashboard.svg`;
const iconStorageActive = `${api}assets/images/icon/icon-storage-active.svg`;
const iconStoragePink = `${api}assets/images/icon/icon-storage-pink.svg`;
const iconSubsInactive = `${api}assets/images/icon/icon-subs-inactive.svg`;
const iconSupportV3 = `${api}assets/images/icon/icon-support-1.svg`;
const iconSupportManager = `${api}assets/images/icon/icon-support-manager.svg`;
const iconSupportV4 = `${api}assets/images/icon/icon-support-t-bg.svg`;
const iconSuspendCp = `${api}assets/images/icon/icon-suspend-cp.svg`;
const iconUserV3 = `${api}assets/images/icon/icon-topic.svg`;
const iconTransferBg = `${api}assets/images/icon/icon-transfer-bg.svg`;
const iconUpdateV1Active = `${api}assets/images/icon/icon-updateV1-active.svg`;
const iconUpdateV1 = `${api}assets/images/icon/icon-updateV1.svg`;
const iconUserV7 = `${api}assets/images/icon/icon-user-inactive.svg`;
const iconUserV2 = `${api}assets/images/icon/icon-user-q.svg`;
const iconUserV8 = `${api}assets/images/icon/icon-users.svg`;
const iconUserV6 = `${api}assets/images/icon/icon-userV6.svg`;
const iconUserV9 = `${api}assets/images/icon/icon-userV9.svg`;
const iconVpsBg = `${api}assets/images/icon/icon-vps-s.svg`;
const iconWarningLine = `${api}assets/images/icon/icon-warning-line.svg`;
const iconWebHV4 = `${api}assets/images/icon/icon-web-hostingV2.svg`;
const iconWebsite = `${api}assets/images/icon/icon-website.svg`;
const iconConfigInactive = `${api}assets/images/icon/iconConfigInactive.svg`;
const iconRightV2 = `${api}assets/images/icon/iconRightV2.svg`;
const iconInvoiceV1 = `${api}assets/images/icon/icons-invoiceV1.svg`;
const imgPaymentSuccess = `${api}assets/images/img-payment-success.svg`;
const iconArrowLeftV2 = `${api}assets/images/leftArrow.svg`;

const iconFacebookW = `${api}assets/images/icon/icon-facebook-w.svg`;
const iconFacebookV3 = `${api}assets/images/icon/icon-facebookV3.svg`;
const iconGithubW = `${api}assets/images/icon/icon-github-w.svg`;
const iconGithubV3 = `${api}assets/images/icon/icon-githubV3.svg`;
const iconGoogleW = `${api}assets/images/icon/icon-google-w.svg`;
const iconGoogleV2 = `${api}assets/images/icon/icon-googleV2.svg`;
const iconInfo = `${api}assets/images/icon/icon-info.svg`;
const iconSkypeV1 = `${api}assets/images/icon/icon-skype-color.svg`;
const iconWhatsappV1 = `${api}assets/images/icon/icon-whatsapp-color.svg`;
const iconWhatsappV2 = `${api}assets/images/icon/icon-whatsapp-white.svg`;

// payment method icons
const iconBkash = `${api}assets/images/icon/icon-brand-bkash.svg`;
const iconBankCards = `${api}assets/images/icon/icon-brand-card-bank.svg`;
const iconGooglePay = `${api}assets/images/icon/icon-brand-google-pay.svg`;
const iconNagad = `${api}assets/images/icon/icon-brand-nagad.svg`;
const iconPaypal = `${api}assets/images/icon/icon-brand-paypal.svg`;

// search-bar icons
const iconHistoryS = `${api}assets/images/history-icon-black.svg`;
const iconSearchV1 = `${api}assets/images/Search-icon-black.svg`;
const iconDomainS = `${api}assets/images/web-icon-black.svg`;

// shape icons
const iconShape = `${api}assets/images/shape-top-arrow.svg`;

// newsletter section icons
const iconSendMail = `${api}assets/images/email-btn.svg`;

// smsapi page
const iconUploadNumber = `${api}assets/images/icon/icon-phone-upload.svg`; /* phone upload icon */

// smsapi campaign-list page icons
const iconAddContact = `${api}assets/images/icon/icon-add-contact.svg`;
const iconAddFile = `${api}assets/images/icon/icon-add-file.svg`;
const iconAddGrp = `${api}assets/images/icon/icon-add-group.svg`;
const iconAddV1 = `${api}assets/images/icon/icon-add-sms.svg`;
const iconAddV3 = `${api}assets/images/icon/icon-addV2.svg`;
const iconPrint = `${api}assets/images/icon/icon-print-sms.svg`;
const iconSearch = `${api}assets/images/icon/icon-search-sms.svg`;

// users profile page icons
const iconProfileManageGray = `${api}assets/images/icon/ser-profile-mng-icon-gray.svg`;
const iconProfileEditGray = `${api}assets/images/icon/user-profile-edit-icon-gray.svg`;
const iconProfileEdit = `${api}assets/images/icon/user-profile-edit-icon.svg`;
const iconProfileManage = `${api}assets/images/icon/user-profile-mng-icon.svg`;

// users domain page
const iconDomainDel = `${api}assets/images/icon/icon-del-domain.svg`; /* delete icon  */
const iconDel = `${api}assets/images/icon/icon-del.svg`; /* delete icon  */
const iconDomainTransfer = `${api}assets/images/icon/icon-transferOut.svg`; /* domain transfer icon */
const iconManagement = `${api}assets/images/management.svg`;
const iconPricing = `${api}assets/images/pricing.svg`;
const iconSupportV2 = `${api}assets/images/support-advantage.svg`;

// users domain modify area icons
const iconCns = `${api}assets/images/icon/childName-servers.svg`;
const iconContactDt = `${api}assets/images/icon/contact-details.svg`;
const iconNs = `${api}assets/images/icon/name-servers.svg`;
const iconTheftProtection = `${api}assets/images/icon/theft-projection.svg`;

// domain page feature area icons
const iconCpanel = `${api}assets/images/cp.svg`;
const iconServerV1 = `${api}assets/images/server.svg`;
const iconSsd = `${api}assets/images/ssd.svg`;
const iconSupportV1 = `${api}assets/images/support.svg`;

// domain page other services area icons
const iconBook = `${api}assets/images/book-icon.svg`;
const iconConsultation = `${api}assets/images/consultation-Icon.svg`;
const iconCrossV1 = `${api}assets/images/cross-icon.svg`;
const iconArrowDownV1 = `${api}assets/images/down-arrow.svg`;
const iconEmailV1 = `${api}assets/images/email-service.svg`;
const iconManageV2 = `${api}assets/images/icon/icon-manageV2.svg`;
const iconNotificationFill = `${api}assets/images/icon/icon-notification-fill.svg`;
const iconManageV1 = `${api}assets/images/manage-icon.svg`;
const iconNews = `${api}assets/images/news-icon.svg`;
const iconNotificationV2 = `${api}assets/images/notification.svg`;
const iconOffice = `${api}assets/images/office-icon.svg`;
const iconSecurity = `${api}assets/images/security-icon.svg`;
const iconSmsV1 = `${api}assets/images/sms-service.svg`;
const iconSoftwareV1 = `${api}assets/images/software-icon.svg`;
const iconWebmail = `${api}assets/images/webmail-icon.svg`;

// footer area social icons
const iconBikiranBannerV2 = `${api}assets/images/banner-bikiran.svg`;
const iconGh = `${api}assets/images/icon-github-link.svg`;
const iconLi = `${api}assets/images/icon-linkedin-link.svg`;
const iconSk = `${api}assets/images/icon-skype-link.svg`;
const iconYt = `${api}assets/images/icon-youtube-link.svg`;
const iconContactMail = `${api}assets/images/icon/icon-contacts-mail.svg`;
const iconContactPhone = `${api}assets/images/icon/icon-contacts-phone.svg`;
const iconPaymentGateway = `${api}assets/images/icon/icon-payment-gateway.svg`;
const iconFb = `${api}assets/images/icons-facebook-link.svg`;

// result page area
const iconBikLogo = `${api}assets/images/bikFav.svg`;
const iconGoogleV3 = `${api}assets/images/GFav.svg`;
const iconArrowRightV1 = `${api}assets/images/icon/icon-arrow-next.svg`;
const iconArrowLeftV1 = `${api}assets/images/icon/icon-arrow-prev.svg`;
const iconDefaultResult = `${api}assets/images/result-icon.svg`;

// search page domain found area icons
const iconDot = `${api}assets/images/dotIcon.svg`;
const iconDomainDemo = `${api}assets/images/fabDemoIcon.svg`;
const iconIp = `${api}assets/images/icon/IPIcon.svg`;

// profile picture edit popup icons
const iconTakeSelfie = `${api}assets/images/icon/icon-selfie.svg`;
const iconUploadImage = `${api}assets/images/icon/icon-upload-img.svg`;
const iconEditV3 = `${api}assets/images/icon/profile-edit.svg`;

// smsapi page sidebar menu menu icons
const iconMessagingInactive = `${api}assets/images/icon/icon-messaging-default.svg`;
const iconMessagingActive = `${api}assets/images/icon/Icon-messaging.svg`;

// const iconOverview = api + 'assets/images/icon/icon-overview.svg';
const iconPackagesActive = `${api}assets/images/icon/icon-packages-active.svg`;
const iconPackagesInactive = `${api}assets/images/icon/icon-packages.svg`;
const iconPhonebookActive = `${api}assets/images/icon/icon-phonebook-active.svg`;
const iconPhonebookInactive = `${api}assets/images/icon/icon-phonebook.svg`;
const iconReportsActive = `${api}assets/images/icon/icon-reports-active.svg`;
const iconReportsInactive = `${api}assets/images/icon/icon-reports.svg`;

// users sidebar menu menu icons
const iconAddress = `${api}assets/images/icon/icon-address.svg`;
const iconAppHosting = `${api}assets/images/icon/icon-app-hosting.svg`;
const iconArrowRightV2 = `${api}assets/images/icon/icon-arrow-right.svg`;
const iconCloudServer = `${api}assets/images/icon/icon-cloud-server.svg`;
const iconContact = `${api}assets/images/icon/icon-contact.svg`;
const iconDedicatedServer = `${api}assets/images/icon/icon-dedicated-server.svg`;
const iconDomainV3 = `${api}assets/images/icon/icon-domain-profile.svg`;
const iconDomainV1 = `${api}assets/images/icon/icon-domain.svg`;
const iconDomainV4 = `${api}assets/images/icon/icon-domainV4.svg`;
const iconEmailV2 = `${api}assets/images/icon/icon-email.svg`;
const iconHosting = `${api}assets/images/icon/icon-hosting.svg`;
const iconMailHosting = `${api}assets/images/icon/icon-mail-hosting.svg`;
const iconUserV1 = `${api}assets/images/icon/icon-my-profile.svg`;
const iconOverview = `${api}assets/images/icon/icon-overview.svg`;
const iconPassChange = `${api}assets/images/icon/icon-pass-change.svg`;
const iconServerV2 = `${api}assets/images/icon/icon-server.svg`;
const iconShield = `${api}assets/images/icon/icon-shield.svg`;
const iconShieldV2 = `${api}assets/images/icon/icon-shieldV2.svg`;
const iconShieldV3 = `${api}assets/images/icon/icon-shieldV3.svg`;
const iconSmsV2 = `${api}assets/images/icon/icon-sms.svg`;
const iconSocial = `${api}assets/images/icon/icon-social.svg`;
const iconSoftwareV2 = `${api}assets/images/icon/icon-software.svg`;
const iconVps = `${api}assets/images/icon/icon-vps.svg`;
const iconWebHosting = `${api}assets/images/icon/icon-web-hosting.svg`;

// billing area icons
const iconSignPaid = `${api}assets/images/sign-paid.svg`;
const iconSignUnpaid = `${api}assets/images/sign-unpaid.svg`;

// arrow icon smsapi menu
// const iconArrowBottom = api + 'assets/images/icon/sms-arrow-active.svg';

// table data sor icons
const iconSortDown = `${api}assets/images/sort-down.svg`;
const iconSortUp = `${api}assets/images/sort-up.svg`;

// arrow icons
const iconArrowTopS = `${api}assets/images/icon/icon-q-close.svg`;
const iconArrowBottomS = `${api}assets/images/icon/icon-q-default.svg`;
const iconArrowBottom = `${api}assets/images/icon/sms-arrow-active.svg`;

// hosting icons
const iconAppH = `${api}assets/images/app.svg`;
const iconEmailH = `${api}assets/images/email.svg`;
const iconWebHV1 = `${api}assets/images/web.svg`;

// header icons
const iconBikLogoV1 = `${api}assets/images/icon/icon-bik-logoV1.svg`;

const iconLogoText = `${api}assets/images/icon/icon-logo-text.svg`;
const iconBikiranBannerV1 = `${api}assets/images/Logo.svg`;
const iconLogo = `${api}assets/images/LogoIcon.svg`;

// mobile footer icons
const iconDashboard = `${api}assets/images/icon/icon-dashboard-3.svg`;
const iconHomeV1 = `${api}assets/images/icon/icon-home-3.svg`;
const iconUserV4 = `${api}assets/images/icon/icon-profile-3.svg`;
const iconSubscription = `${api}assets/images/icon/icon-subscription-3.svg`;
const iconCart = `${api}assets/images/icon/mobile-cart-menu.svg`;

// services page icons
const iconAppHostingV2 = `${api}assets/images/icon/icon-app-hosting-round-5.svg`;
const iconAppHostingV1 = `${api}assets/images/icon/icon-app-hosting-round.svg`;
const iconCloudVm5 = `${api}assets/images/icon/icon-cloud-vm-round-5.svg`;
const iconCloudVm = `${api}assets/images/icon/icon-cloud-vm-round.svg`;
const iconDedicatedServer5 = `${api}assets/images/icon/icon-dedicated-server-round-5.svg`;
const iconDedicatedServerV1 = `${api}assets/images/icon/icon-dedicated-server-round.svg`;
const iconEcomSoft5 = `${api}assets/images/icon/icon-ecom-soft-round-5.svg`;
const iconEcomSoft = `${api}assets/images/icon/icon-ecom-soft-round.svg`;
const iconEduSoft5 = `${api}assets/images/icon/icon-edu-soft-round-5.svg`;
const iconEduSoft = `${api}assets/images/icon/icon-edu-soft-round.svg`;
const iconEmailHostingV2 = `${api}assets/images/icon/icon-email-hosting-round-5.svg`;
const iconEmailHostingV1 = `${api}assets/images/icon/icon-email-hosting-round.svg`;
const iconEmailService5 = `${api}assets/images/icon/icon-email-service-round-5.svg`;
const iconEmailService = `${api}assets/images/icon/icon-email-service-round.svg`;
const iconNewsPortal5 = `${api}assets/images/icon/icon-news-portal-round-5.svg`;
const iconNewsPortal = `${api}assets/images/icon/icon-news-portal-round.svg`;
const iconPushNotification5 = `${api}assets/images/icon/icon-push-notification-round-5.svg`;
const iconPushNotification = `${api}assets/images/icon/icon-push-notification-round.svg`;
const iconSmsService5 = `${api}assets/images/icon/icon-sms-service-round-5.svg`;
const iconSmsService = `${api}assets/images/icon/icon-sms-service-round.svg`;
const iconVps5 = `${api}assets/images/icon/icon-vps-round-5.svg`;
const iconVpsV1 = `${api}assets/images/icon/icon-vps-round.svg`;
const iconWebHV3 = `${api}assets/images/icon/icon-web-hosting-round-5.svg`;
const iconWebHV2 = `${api}assets/images/icon/icon-web-hosting-round.svg`;

// support page category
const iconKnowledge = `${api}assets/images/icon/icon-knowledge.svg`;
const iconReportWhite = `${api}assets/images/icon/icon-report-white.svg`;
const iconReport = `${api}assets/images/icon/icon-report.svg`;
const iconTicket = `${api}assets/images/icon/icon-support-ticket.svg`;

// domain reg available icons
const iconCheckD = `${api}assets/images/domain-found.svg`;
const iconCheckInactive = `${api}assets/images/icon/icon-check-inactive.svg`;

// support q area icons
const iconArrowBottomV3 = `${api}assets/images/icon/icon-down.svg`;
const iconArrowTopV2 = `${api}assets/images/icon/icon-up.svg`;

// sms page icons
const iconDupNum = `${api}assets/images/icon/duplicate-num-icon.svg`;
const iconFltNum = `${api}assets/images/icon/fltr-num.svg`;
const iconNumDt = `${api}assets/images/icon/num-database-icon.svg`;

// solved question page icons
const iconActiveSms = `${api}assets/images/icon/icon-active-sms.svg`;
const iconCreateAccount = `${api}assets/images/icon/icon-create-account.svg`;
const iconManageSoftware = `${api}assets/images/icon/icon-manage-software.svg`;
const iconPurchaseDomain = `${api}assets/images/icon/icon-purchase-domain.svg`;
const iconPurchaseHosting = `${api}assets/images/icon/icon-purchase-hosting.svg`;
const iconSendEmail = `${api}assets/images/icon/icon-send-email.svg`;

// solved question ans icons
const iconDmAns = `${api}assets/images/icon-purchase-ans.jpg`;
const iconDomainV2 = `${api}assets/images/icon/icon-domain-q.svg`;
const iconPay = `${api}assets/images/icon/icon-pay-q.svg`;
const iconSms = `${api}assets/images/icon/icon-sms-q.svg`;
const iconSoftware = `${api}assets/images/icon/icon-software-q.svg`;

// edit icons
const iconEditV1 = `${api}assets/images/icon/icon-edit.svg`;
const iconEditV4 = `${api}assets/images/icon/icon-editV4.svg`;

// cart details page icons
const iconDomainBg = `${api}assets/images/cart-domain-bg.svg`;
const iconHostingBg = `${api}assets/images/cart-hosting-bg.svg`;
const iconDelete = `${api}assets/images/icon/icon-delete.svg`;
const iconEmailBg = `${api}assets/images/icon/icon-email-bg.svg`;
const iconSmsBg = `${api}assets/images/icon/icon-sms-bg.svg`;
const iconEmptyCart = `${api}assets/images/img-empty-cart.svg`;

// user options
const iconBalance = `${api}assets/images/icon/bik-balance.svg`;
const iconPoints = `${api}assets/images/icon/bik-point.svg`;
const iconPointsV1 = `${api}assets/images/icon/icon-bik-points.svg`;
const iconDashboardV1 = `${api}assets/images/icon/icon-dashboard.svg`;
const iconSignOutV1 = `${api}assets/images/icon/icon-sign-out.svg`;
const iconSupport = `${api}assets/images/icon/icon-support.svg`;
const iconWallet = `${api}assets/images/icon/icon-wallet.svg`;

// mobile sidebar menu
const imgHr = `${api}assets/images/bg-hr.svg`;
const iconAboutUs = `${api}assets/images/icon/icon-about-us.svg`;
const iconBack = `${api}assets/images/icon/icon-arrow-left.svg`;
const iconClients = `${api}assets/images/icon/icon-clients.svg`;
const iconHomeV3 = `${api}assets/images/icon/icon-home-inactive.svg`;
const iconHomeV2 = `${api}assets/images/icon/icon-home.svg`;
const iconMenu = `${api}assets/images/icon/icon-menu.svg`;
const iconMenuV2 = `${api}assets/images/icon/icon-menuV2.svg`;
const iconServices = `${api}assets/images/icon/icon-services.svg`;
const iconSignOutV2 = `${api}assets/images/icon/icon-sign-out-1.svg`;
const iconBilling = `${api}assets/images/icon/icon-wallet-1.svg`;

// Contact icons
const iconFaxActive = `${api}assets/images/icon/icon-fax-active.svg`;
const iconFax = `${api}assets/images/icon/icon-fax.svg`;
const iconTelActive = `${api}assets/images/icon/icon-tel-active.svg`;
const iconTel = `${api}assets/images/icon/icon-tel.svg`;

// programming languages
const iconC = `${api}assets/images/icon/icon-c.svg`;
const iconJs = `${api}assets/images/icon/icon-js.svg`;
const iconPhp = `${api}assets/images/icon/icon-php.svg`;
const iconPython = `${api}assets/images/icon/icon-python.svg`;

// Marketplace
const iconFiverr = `${api}assets/images/icon/icon-fiverr.svg`;
const iconFreelancer = `${api}assets/images/icon/icon-freelancer.svg`;
const iconUpwork = `${api}assets/images/icon/icon-upwork.svg`;

// Os Knowledge
const iconAndroid = `${api}assets/images/icon/icon-android.svg`;
const iconAndroidV2 = `${api}assets/images/icon/icon-androidV2.svg`;
const iconLinux = `${api}assets/images/icon/icon-linux.svg`;
const iconMacOs = `${api}assets/images/icon/icon-macOs.svg`;
const iconWindows = `${api}assets/images/icon/icon-windows.svg`;
const iconWindowsV2 = `${api}assets/images/icon/icon-windowsV2.svg`;

// Bishojit
const iconDel1 = `${api}assets/images/icon/icon-delete-1.svg`;
const iconTic = `${api}assets/images/icon/icon-tic.svg`;

// web running section icons
const iconDomainLocation = `${api}assets/images/icon/icon-domain-location.svg`;
const iconDomainMail = `${api}assets/images/icon/icon-domain-mail.svg`;
const iconDomainNum = `${api}assets/images/icon/icon-domain-num.svg`;
const iconNsV1 = `${api}assets/images/icon/icon-nsV1.svg`;

// input password icon
const iconPassHide = `${api}assets/images/icon/icon-pass-hide.svg`;
const iconPassShow = `${api}assets/images/icon/icon-pass-show.svg`;

// security and login
const iconAuthApp = `${api}assets/images/icon/icon-app-auth.svg`;
const iconRecoveryCodes = `${api}assets/images/icon/icon-recovery-codes.svg`;
const iconSecurityKey = `${api}assets/images/icon/icon-security-key.svg`;

// services type icon
const iconServiceAll = `${api}assets/images/icon/Icon-service-all.svg`;
const iconServiceBackup = `${api}assets/images/icon/icon-service-backup.svg`;
const iconServiceEmail = `${api}assets/images/icon/icon-service-email.svg`;
const iconServiceHosting = `${api}assets/images/icon/Icon-service-hosting.svg`;
const iconServiceSend = `${api}assets/images/icon/Icon-service-send.svg`;
const iconServiceServer = `${api}assets/images/icon/Icon-service-server.svg`;
const iconServiceSoftware = `${api}assets/images/icon/Icon-service-software.svg`;
const iconWebSecurity = `${api}assets/images/icon/icon-service-wSecurity.svg`;

export const socialIcon = {
    google: iconGoogleW,
    googleV2: iconGoogleV2,
    facebook: iconFacebookW,
    facebookV2: iconFb,
    facebookV3: iconFacebookV3,
    github: iconGithubW,
    githubV2: iconGh,
    githubV3: iconGithubV3,
    youtube: iconYt,
    skype: iconSk,
    linkedin: iconLi,
    skypeV1: iconSkypeV1,
    whatsapp: iconWhatsappV1,
    whatsappV2: iconWhatsappV2
};

export const programmingLangIcon = {
    cProgram: iconC,
    python: iconPython,
    js: iconJs,
    php: iconPhp
};

export const marketplaceIcon = {
    upwrok: iconUpwork,
    fiverr: iconFiverr,
    freelancer: iconFreelancer
};

export const osIcon = {
    windows: iconWindows,
    windowsV2: iconWindowsV2,
    mac: iconMacOs,
    android: iconAndroid,
    androidV2: iconAndroidV2,
    linux: iconLinux
};

export const userIcons = {
    iconUserV1,
    iconUserV2,
    iconUserV3,
    iconUserV4,
    iconUserV5,
    iconUserV6,
    iconUserV7,
    iconUserV8,
    iconUserV9
};

export const deleteIcons = {
    iconDelete,
    iconContactDel,
    iconDomainDel,
    iconDelV4,
    iconDelV5
};

export const inputPassIcons = {
    iconPassShow,
    iconPassHide
};

export const loginAreaIcons = {
    iconSearchM,
    iconNotificationV1,
    iconCartV1
};

export const searchBarIcons = {
    iconHistoryS,
    iconDomainS,
    iconSearchV1,
    iconSearchV2
};

export const editIcons = {
    iconEditV1,
    iconEditV2,
    iconEditV2Active,
    iconEditV3,
    iconEditV4
};

export const bikLogo = {
    iconLogo
};

export const addIcons = {
    iconAddV1,
    iconAddV2,
    iconAddGrp,
    iconAddFile,
    iconAddV3,
    iconAddContact
};

export const headerIcons = {
    iconBikiranBannerV1,
    iconBikLogoV1,
    iconBikLogoV4,
    iconLogoText
};

export const homeIcons = {
    iconHomeV1,
    iconHomeV2,
    iconHomeV3
};

export const footerIcons = {
    iconBikiranBannerV2,
    iconContactMail,
    iconContactPhone,
    iconPaymentGateway,
    iconSubscription,
    iconCart
};

export const dashboardIcons = {
    iconDashboard,
    iconDashboardV1,
    iconDashboardV2,
    iconDashboardV4
};

export const servicesIcons = {
    iconServiceHosting,
    iconServiceServer,
    iconServiceSoftware,
    iconServiceSend,
    iconWebSecurity,
    iconServiceBackup,
    iconServiceEmail,
    iconServiceAll
};

export const servicesPageIcons = {
    iconEcomSoft5,
    iconEcomSoft,
    iconEduSoft5,
    iconEduSoft,
    iconEmailService5,
    iconEmailService,
    iconNewsPortal5,
    iconNewsPortal,
    iconPushNotification5,
    iconPushNotification,
    iconSmsService5,
    iconSmsService
};
export const hostingIcons = {
    iconAppHostingV1,
    iconAppHostingV2,
    iconEmailH,
    iconEmailHostingV2,
    iconEmailHostingV1,
    iconWebHV3,
    iconWebHV2,
    iconHostingV2,
    iconWebHV1,
    iconAppH
};

export const smsPageIcons = {
    iconPrint,
    iconSearch,
    iconMessagingActive,
    iconPackagesActive,
    iconPhonebookActive,
    iconReportsActive
};

export const paymentMethodIcons = {
    iconBkash,
    iconBankCards,
    iconGooglePay,
    iconNagad,
    iconPaypal
};

export const sortIcons = {
    iconSortUp,
    iconSortDown
};

export const contactIcons = {
    iconTel,
    iconTelActive,
    iconFax,
    iconFaxActive
};

export const arrowIcons = {
    iconArrowRightV1,

    iconArrowTopS,
    iconArrowBottomS,
    iconArrowDownV1,
    iconArrowLeftV1,
    iconArrowLeftV2,
    iconArrowLeftV3,
    iconArrowRightV2,
    iconArrowBottomV3,
    iconArrowTopV2,
    iconArrowBottom,

    iconArrowNewTab,
    iconArrowNewTabV2,
    iconArrowBottomV4
};

export const iconsModal = {
    iconCloseModal
};

export const crossIcons = {
    iconCrossV1,
    iconCrossV2
};

export const domainModify = {
    iconCns,
    iconContactDt,
    iconNs,
    iconTheftProtection
};

export const uploadIcon = {
    iconUploadImage,
    iconUploadNumber
};

export const solvedQIcons = {
    iconActiveSms,
    iconCreateAccount,
    iconManageSoftware,
    iconPurchaseDomain,
    iconPurchaseHosting,
    iconSendEmail
};

export const domainIcons = {
    iconDomainV1,
    iconDomainV2,
    iconDomainV3,
    iconDomainV4,
    iconDomainDemo
};

export const supportIcons = {
    iconSupport,
    iconSupportV1,
    iconSupportV2,
    iconSupportV3
};

export const signOutIcons = {
    iconSignOutV1,
    iconSignOutV2,
    iconSignOutV3,
    iconSignOutInactive
};

export const notificationIcons = {
    iconNotificationV2,
    iconNotificationFill
};

export const modeIcons = {
    iconLight,
    iconDark
};

export const icons = {
    iconInfo,

    iconDomainTransfer,
    iconProfileEdit,
    iconProfileEditGray,
    iconProfileManage,
    iconManageV2,
    iconProfileManageGray,

    iconCpanel,
    iconServerV1,
    iconSsd,

    iconBook,
    iconConsultation,
    iconEmailV1,
    iconManageV1,
    iconNews,

    iconOffice,
    iconSecurity,
    iconSmsV1,
    iconSoftwareV1,
    iconWebmail,
    iconBikLogo,
    iconGoogleV3,

    iconDefaultResult,
    iconDot,
    iconIp,
    iconNs,

    iconTakeSelfie,

    iconManagement,
    iconPricing,

    iconMessagingInactive,

    iconPackagesInactive,

    iconPhonebookInactive,

    iconReportsInactive,
    iconAddress,
    iconAppHosting,
    iconCloudServer,
    iconContact,
    iconDedicatedServer,

    iconEmailV2,
    iconHosting,
    iconMailHosting,
    iconOverview,
    iconServerV2,
    iconShield,
    iconShieldV2,
    iconShieldV3,
    iconPassChange,
    iconSmsV2,
    iconSocial,
    iconSoftwareV2,
    iconVps,
    iconWebHosting,
    iconSignPaid,
    iconSignUnpaid,
    iconCheckD,
    iconCheckInactive,
    iconKnowledge,
    iconReport,
    iconReportWhite,
    iconTicket,

    iconCloudVm5,
    iconCloudVm,
    iconVps5,
    iconVpsV1,
    iconDedicatedServer5,
    iconDedicatedServerV1,
    iconDmAns,
    iconPay,
    iconSms,
    iconSoftware,
    imgPaymentSuccess,
    iconDomainBg,
    iconSmsBg,
    iconEmailBg,
    iconHostingBg,
    iconEmptyCart,
    iconConverterP,
    iconBalance,
    iconPoints,
    iconPointsV1,
    iconCopy,
    iconCopyV2,

    iconWallet,

    iconAboutUs,
    iconBack,
    iconClients,
    iconMenu,
    iconMenuV2,
    iconServices,
    iconBilling,

    iconTic,
    iconDel1,

    iconPhonePrimary,
    iconPhoneSec,
    iconMailV1,
    iconMailV2,
    iconMailActive,

    iconShape,

    iconDupNum,
    iconFltNum,
    iconNumDt,

    iconSendMail,

    imgHr,

    iconSupportManager,

    iconEyeActive,
    iconEyeInactive,

    iconDotMenuV1,
    iconDotMenuActiveV1,

    iconCalender,

    iconUpdateV1,
    iconUpdateV1Active,

    iconWebHV4,

    iconSupportV4,
    iconContactV1,

    iconBillingV1,
    iconCartV2,
    iconUserV6,
    iconInvoiceV1,
    iconInvoiceCancel,
    iconInvoiceCandleActive,
    iconEmailV3,

    iconCloseMinus,
    iconCloseMinusActive,

    iconNsV1,
    iconDomainMail,
    iconDomainNum,
    iconDomainLocation,

    iconActivityLog,
    iconDeveloperOption,
    iconSecurityV2,

    iconAuthApp,
    iconSecurityKey,
    iconRecoveryCodes,

    iconP,

    iconSubsInactive,
    iconDashboardInactive,
    iconCartInactive,

    iconContract,

    iconCurrencyExchange,

    iconDashboardV3,
    iconReportInactive,
    iconConfigInactive,
    iconConfigActive,
    iconContactInactive,
    iconMessageInactive,
    iconReportsActive,
    iconDashboardActive,
    iconContactActive,
    iconMessageActive,

    iconConfigDark,
    iconConfigLight,

    iconPackageActiveV2,
    iconPackageInactiveV2,

    iconInfoYellow,
    iconInfoSky,

    iconExportBulk,
    iconFilter,
    iconExportV2,
    iconExportLight,

    imgCpanelV2,
    imgDatabase,
    iconInfoInactive,
    iconInfoActive,
    iconDatabaseActive,
    imgPhpV2,
    imgFileV2,
    imgReset,
    imgResetActive,
    imgRedirectPurple,
    iconReGenerate,
    iconDiskFill,
    iconRamFill,
    iconCpWhite,
    iconDiskFillV2,
    iconMysql,
    iconWebsite,
    iconCpu,
    iconEp,
    iconIo,

    iconReload,
    iconReloadActive,

    iconRemoveCp,
    iconSuspendCp,
    iconPauseRed,
    iconPlayRed,
    iconDel,

    iconM,
    iconS,
    iconD,
    iconWarningLine,
    iconChat,
    iconChatActive,

    iconDotOp,
    iconDotOpActive,
    iconDotOpH,

    iconActivity,
    iconActivityActive,
    iconNsV2,
    iconNsActive,
    iconContractPurple,
    iconContractGreen,
    iconContractActive,
    iconNsPurple,
    iconCnsBlue,
    iconCnsBlueActive,
    iconDtransferActive,
    iconDtransfer,
    iconStorageActive,
    iconStoragePink,
    iconDownV2,
    iconRightV2,
    iconHomePurple,

    iconFaqActive,
    iconBackupActive,
    iconDnsActive,
    iconFaq,
    iconBackup,
    iconDns,
    iconDedicatedBg,
    iconCloudBg,
    iconVpsBg,
    iconBackupBg,
    iconDnsBg,
    iconBikLogoV2,

    iconDbBg,
    iconDislikeWhite,
    iconLikePurple,
    iconLikeWhite,
    iconDislikePurple,

    iconContactWhite,
    iconContactPaste,
    iconDownloadV1,

    iconSecurityPurple,
    iconLockPurple,

    iconTransferBg,
    iconDoneBg,

    iconSendFill,
    iconSoftwareFill,

    iconBikBalance,
    iconBikCoin,

    iconUserPurple,
    iconUserBlack,
    iconHomeBlack,
    iconServicePurple,
    iconServiceBlack,
    iconCartPurple,
    iconCartBlack,
    iconShare,
    iconInfoWhite,
    iconInfoRed,
    iconDnsFill,
    iconDotV2,
    iconBikLogoV3,
    iconClip,
    iconSmile,
    iconSendPurple,
    iconWalletV2
};

function Icons() {
    return null;
}

export default Icons;
