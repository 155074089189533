import { createContext, useContext, useState } from 'react';
import AxiosAuth from '../../../../utils/AxiosAuth';

const CpCreateWizardContext = createContext();

export function useCreateCpWizard() {
    return useContext(CpCreateWizardContext);
}

const initialState = {
    domain: '',
    isDomainVerified: false,
    isDomainError: false,
    username: '',
    isUsernameVerified: false,
    isUsernameError: false,
    password: ''
};

function CpCreateWizardProvider({ children, contractId }) {
    const [formData, setFormData] = useState(initialState);

    const handelDomainVerify = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.setApiUrl('CONTRACT_HOSTING', '/cp/create-wizard/chk-domain/')
                .post({
                    contractId,
                    domain: formData.domain
                })
                .then(({ data }) => {
                    setFormData((cVal) => ({
                        ...cVal,
                        isDomainVerified: data.error === 0,
                        isDomainError: data.error !== 0,
                        username: data?.data?.username || '',
                        password: data?.data?.password || ''
                    }));

                    resolve({
                        error: data.error,
                        message: data.message
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const handelUsernameVerify = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.setApiUrl('CONTRACT_HOSTING', '/cp/create-wizard/chk-username/')
                .post({
                    contractId,
                    domain: formData.domain,
                    username: formData.username
                })
                .then(({ data }) => {
                    setFormData((cVal) => ({
                        ...cVal,
                        isUsernameVerified: data.error === 0,
                        isUsernameError: data.error !== 0
                    }));

                    resolve({
                        error: data.error,
                        message: data.message
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const handelCreateCp = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.setApiUrl('CONTRACT_HOSTING', '/cp/create-wizard/create/')
                .post({
                    contractId,
                    domain: formData.domain,
                    username: formData.username,
                    password: formData.password
                })
                .then(({ data }) => {
                    if (data.error === 0) {
                        setFormData(initialState);
                    }

                    resolve({
                        error: data.error,
                        message: data.message
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const value = {
        formData,
        setFormData,
        handelDomainVerify,
        handelUsernameVerify,
        handelCreateCp
    };

    return (
        <CpCreateWizardContext.Provider value={value}>{children}</CpCreateWizardContext.Provider>
    );
}

export default CpCreateWizardProvider;
