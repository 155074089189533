import { ButtonActionDelete, ButtonActionEdit } from '../../../../configs/Buttons';
import { useDomainInfo } from '../domains_info/DomainInfoBasicProvider';
import CnsListActionButtons from './CnsListActionButtons';

function IpView({ viewType, data, id }) {
    const { setInputVal } = useDomainInfo();

    if (viewType === id) {
        return (
            <span className="w-md-100 h-100">
                <input
                    onChange={(e) => setInputVal((cuData) => ({ ...cuData, ip: e.target.value }))}
                    className="form-input w-100 table-input-field w-md-100"
                    type="text"
                    defaultValue={data || ''}
                />
            </span>
        );
    }
    return (
        <span className="">
            <span>{data}</span>
        </span>
    );
}

// function HostnameInputView({ hostname, setCnsInfo, domain }) {
//     const handelHostnameData = (ev) => {
//         const { value } = ev?.target;

//         let newVal = value?.replace(/[^a-zA-Z0-9.-]/g, '');
//         newVal = newVal?.replace(/[.]+/gm, '.');
//         newVal = newVal?.replace(/[-]+/gm, '-');

//         setCnsInfo((cuState) => ({ ...cuState, hostname: newVal }));
//     };

//     const dot = hostname?.substring(hostname?.length - 1, hostname?.length) === '.';

//     const handleFilter = (ev) => {
//         const newVal = ev.target?.value
//             ?.split('.')
//             ?.filter((v) => !!v)
//             ?.map((item) =>
//                 item
//                     ?.split('-')
//                     ?.filter((v) => !!v)
//                     ?.join('-')
//             )
//             ?.join('.');
//         setCnsInfo((cuState) => ({ ...cuState, hostname: newVal }));
//     };

//     return (
//         <div className="input-back-text w-100" style={{ whiteSpace: 'nowrap' }}>
//             {hostname ? (
//                 <span className="back-text">
//                     <span style={{ opacity: '0' }}>{hostname}</span>
//                     <span className="purple">{dot ? '' : '.'}</span>
//                     <span className="purple">{domain}</span>
//                 </span>
//             ) : null}

//             <input
//                 className="form-input w-100"
//                 type="text"
//                 placeholder={`Eg: ns1.${domain}`}
//                 value={hostname}
//                 onChange={handelHostnameData}
//                 onBlur={handleFilter}
//             />
//         </div>
//     );
// }

function TableDisableView({ data, setViewType, viewType, id }) {
    return (
        <tbody className="disabled-view">
            <tr>
                <td colSpan={2}>
                    <span>
                        <span>{data?.hostname}</span>
                    </span>
                </td>
                <td colSpan={5} width="50%">
                    <IpView viewType={viewType} data={data?.ip} id={id} />
                </td>
                <td colSpan={2}>
                    <div className="line-align line-no-wrap btn-action-web">
                        <span className="">
                            <ButtonActionEdit evOnClick={() => setViewType(id)} />
                        </span>
                        <span className="ml-2 ">
                            <ButtonActionDelete />
                        </span>
                    </div>
                </td>
            </tr>
        </tbody>
    );
}

function TableBody({ setViewType, viewType, data, id }) {
    if (viewType !== null && viewType !== id) {
        return (
            <TableDisableView setViewType={setViewType} viewType={viewType} data={data} id={id} />
        );
    }
    return (
        <tbody className={data?.demo ? 'placeholder-mode' : ''}>
            <tr>
                <td colSpan={2}>
                    <span>{data?.hostname}</span>
                </td>
                <td colSpan={5} width="50%">
                    <IpView viewType={viewType} data={data?.ip} id={id} />
                </td>
                <td colSpan={2}>
                    <CnsListActionButtons
                        setViewType={setViewType}
                        viewType={viewType}
                        data={data}
                        id={id}
                    />
                </td>
            </tr>
        </tbody>
    );
}

function DomainInfoCnsListWeb({ info, viewType, setViewType }) {
    return (
        <table className="table-list cell-sm-12" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th colSpan={2}>Host Name</th>
                    <th colSpan={5}>IP Address</th>
                    <th colSpan={2} width="13%">
                        Action
                    </th>
                </tr>
            </thead>
            {info?.map((data, index) => (
                <TableBody
                    key={data?.hostname}
                    data={data}
                    viewType={viewType}
                    setViewType={setViewType}
                    id={index}
                />
            ))}
        </table>
    );
}

export default DomainInfoCnsListWeb;
