import React from 'react';

export function BackToLink() {
    return null;
}

export function BackToLogin({ makeAction }) {
    return (
        <div className="text-center">
            <button
                className="button d-inline-block w-auto button-transparent"
                type="button"
                onClick={() => {
                    makeAction('sign-in');
                }}
            >
                <span
                    className="text-g"
                    style={{
                        color: '#130F40',
                        opacity: '0.5'
                    }}
                >
                    Already have an account?
                </span>
                &nbsp;
                <span className="text-g">Sign In</span>
            </button>
        </div>
    );
}

export function SignUpLink({ makeAction }) {
    return (
        <div className="text-center">
            <button
                className="button d-inline-block w-auto button-transparent"
                type="button"
                onClick={() => {
                    makeAction('sign-up');
                }}
            >
                <span
                    className="text-g"
                    style={{
                        color: '#130F40',
                        opacity: '0.5'
                    }}
                >
                    Don&apos;t have an account?
                </span>
                &nbsp;
                <span className="text-g">Sign Up</span>
            </button>
        </div>
    );
}

function AuthLinks() {
    return <div>AuthLinks</div>;
}

export default AuthLinks;
