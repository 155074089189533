import { useState } from 'react';
import { icons, loginAreaIcons } from '../../../../configs/Icons';
import BodyJsonResult from './BodyJsonResult';

function BodyMenuHeader({ setItemIndex, itemIndex, handleSearchBar }) {
    const menuItem = [
        { id: 1, title: 'Pretty' },
        { id: 2, title: 'Raw' },
        { id: 3, title: 'Preview' },
        { id: 4, title: 'Visualize' }
    ];
    return (
        <div className="body-menu-header line-align">
            <div className="line-row-between line-no-wrap w-100">
                <div className="line-align line-no-wrap">
                    <div className="mt-0">
                        <ul className="line-row-center tabs-menu-item common-bg mt-0 mb-0 ">
                            {menuItem.map((item, index) => (
                                <li key={item.id} className="line-align">
                                    <span
                                        role="button"
                                        tabIndex={-1}
                                        onClick={() => setItemIndex(index)}
                                        className={`common-title line-align ${
                                            itemIndex === index && 'common-title-active'
                                        }`}
                                    >
                                        {item.title}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className=" ml-1 mb-0">
                        <select name="item" className="form-input w-100 mt-0 common-bg">
                            <option value="json">JSON</option>
                        </select>
                    </div>
                </div>
                <div className="body-search line-align line-no-wrap">
                    <div
                        role="button"
                        tabIndex={-1}
                        onClick={() => handleSearchBar()}
                        className="common-img-area common-bg"
                    >
                        <img src={loginAreaIcons.iconSearchM} alt="search icon" />
                    </div>
                    <div className="common-img-area common-bg ml-1">
                        <img src={icons.iconCopy} alt="copy icon" />
                    </div>
                </div>
            </div>
        </div>
    );
}

function ResultBodyContent({ apiData }) {
    const [itemIndex, setItemIndex] = useState(0);
    const [mode, setMode] = useState(false);
    const handleSearchBar = () => {
        setMode(!mode);
    };
    return (
        <div>
            <BodyMenuHeader
                handleSearchBar={handleSearchBar}
                setItemIndex={setItemIndex}
                itemIndex={itemIndex}
            />

            <BodyJsonResult apiData={apiData} />
        </div>
    );
}

export default ResultBodyContent;
