import { GetDate } from '../../../utils/Show';
import { showUnitDuration } from '../../websites/models/Pricing';

const DomainRowMobile = ({ item }) => (
    <div className="invoice-products-sm mb-3 print-none">
        <div className="line mb-4">
            <div className="cell cell-4">
                <span className="title">Item</span>
            </div>
            <div className="cell cell-8">
                <div className="value">
                    <b>{item?.title}</b>
                    <p
                        className="mt-1 mb-4"
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                            __html: item?.description
                        }}
                    />
                    Validity:{' '}
                    <span>
                        {GetDate(item?.subscriptionStart)} to {GetDate(item?.subscriptionEnd)}
                    </span>
                </div>
            </div>
        </div>
        <div className="line mb-4">
            <div className="cell cell-4">
                {' '}
                <span className="title">Price (BDT)</span>
            </div>
            <div className="cell cell-8">
                <span className="value">{(item?.price * 12).toFixed(2)}/Year</span>
            </div>
        </div>
        <div className="line mb-4">
            <div className="cell cell-4">
                {' '}
                <span className="title">Unit</span>
            </div>
            <div className="cell cell-8">
                <span className="value">{item?.duration / 12} Year</span>
            </div>
        </div>
        <div className="line">
            <div className="cell cell-4">
                <span className="title">Total (BDT)</span>
            </div>
            <div className="cell cell-8">
                <span className="value">{(item?.price * item?.duration).toFixed(2)}</span>
            </div>
        </div>
    </div>
);

const HostingRowMobile = ({ item }) => (
    <div className="invoice-products-sm mb-3">
        <div className="line mb-4">
            <div className="cell cell-4">
                <span className="title">Item</span>
            </div>
            <div className="cell cell-8">
                <div className="value">
                    <b>{item?.title}</b>
                    <p
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                            __html: item?.description
                        }}
                    />
                    Validity:{' '}
                    <span>
                        {GetDate(item?.subscriptionStart)} to {GetDate(item?.subscriptionEnd)}
                    </span>
                </div>
            </div>
        </div>
        <div className="line mb-4">
            <div className="cell cell-4">
                {' '}
                <span className="title">Price (BDT)</span>
            </div>
            <div className="cell cell-8">
                <span className="value">{(item?.price * 12).toFixed(2)}/Year</span>
            </div>
        </div>
        <div className="line mb-4">
            <div className="cell cell-4">
                {' '}
                <span className="title">Unit</span>
            </div>
            <div className="cell cell-8">
                <span className="value">{showUnitDuration(item?.duration)}</span>
            </div>
        </div>
        <div className="line">
            <div className="cell cell-4">
                <span className="title">Total (BDT)</span>
            </div>
            <div className="cell cell-8">
                <span className="value">{(item?.price * item?.duration).toFixed(2)}</span>
            </div>
        </div>
    </div>
);

const ListMobile = ({ item }) => {
    if (item?.type === 'domain') {
        return <DomainRowMobile item={item} />;
    }

    return <HostingRowMobile item={item} />;
};

function InvoiceTableMobile({ invoiceData }) {
    const { items } = invoiceData;

    return items?.map((item) => <ListMobile item={item} key={item?.id} />);
}

export default InvoiceTableMobile;
