function PaymentSectionHeader() {
    return (
        <div className="container print-none">
            <div className="cart-payment-header">
                <h2>Payment</h2>
            </div>
        </div>
    );
}

export default PaymentSectionHeader;
