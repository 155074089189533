import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import PageProperties from '../../../utils/PageProperties';
import DomainInfoBasicProvider, { useDomainInfo } from '../domains_info/DomainInfoBasicProvider';
import DomainInfoHeader from '../domains_info_header/DomainInfoHeader';
import TabsRowSection from '../domains_info_tabs/TabsRowSection';
import DomainInfoContactsAdminSection from './DomainInfoContactsAdminSection';
import DomainInfoContactsBillingSection from './DomainInfoContactsBillingSection';
import DomainInfoContactsRegistrantSection from './DomainInfoContactsRegistrantSection1';
import DomainInfoContactsTechSection from './DomainInfoContactsTechSection';

function ContentHead() {
    const { domainInfo, handelReloadButton } = useDomainInfo();

    return (
        <DomainInfoHeader path="contacts/" info={domainInfo} evReloadClick={handelReloadButton} />
    );
}

function ContentBody() {
    const { domainInfo } = useDomainInfo();
    return (
        <div className="content-body-scroll" style={{ paddingBottom: '100px' }}>
            <TabsRowSection contacts />
            <div className={`line line-g2 ${!domainInfo ? 'placeholder-mode' : ''}`}>
                <div className="cell cell-6 cell-md-12">
                    <DomainInfoContactsRegistrantSection />
                </div>
                <div className="cell cell-6 cell-md-12">
                    <DomainInfoContactsAdminSection />
                </div>
                <div className="cell cell-6 cell-md-12">
                    <DomainInfoContactsBillingSection />
                </div>
                <div className="cell cell-6 cell-md-12">
                    <DomainInfoContactsTechSection />
                </div>
            </div>
        </div>
    );
}

function DomainInfoContactsPage() {
    const { currentUser } = useAuth();
    const { contractId } = useParams();

    useEffect(() => {
        PageProperties(`Contacts \u2022 Domain`);
    }, []);

    return (
        <DomainInfoBasicProvider currentUser={currentUser} contractId={contractId}>
            <div className="cell cell-content h-100">
                <div className="content-section">
                    <div className="content-area">
                        <ContentHead />

                        <ContentBody />
                    </div>
                </div>
            </div>
        </DomainInfoBasicProvider>
    );
}

export default DomainInfoContactsPage;
