import { useEffect } from 'react';
import { ButtonGr } from '../../../../../configs/Buttons';
import PageProperties from '../../../../utils/PageProperties';
import { DateInput } from '../../../../utils/inputs/DateInputs';
import { DashboardDefaultHeader } from '../../../headers/DashboardHeaderSection';
import SmsListSection from './sms_list/SmsListSection';

function HeaderButtons() {
    return (
        <div className="line-row-between">
            <div className="cell">
                <div className="ml-6">
                    <DateInput height="30px" />
                </div>
            </div>

            <div className="cell-fill">
                <div className="line justify-end ">
                    <div className="ml-2 ">
                        <ButtonGr title="Create Contract" />
                    </div>
                </div>
            </div>
        </div>
    );
}

function ContentBodyArea() {
    return (
        <div className="list-container w-100">
            <SmsListSection />
        </div>
    );
}

function ContractsSmsPage() {
    useEffect(() => {
        PageProperties(`Software \u2022 Contract \u2022 Support`);
    }, []);

    return (
        <div className="cell cell-content h-100 w-100 domain-section">
            <div className="content-section">
                <div className="content-area user-contract-page-area">
                    <DashboardDefaultHeader
                        title="Software List"
                        path="/smanager/ticket/"
                        content={<HeaderButtons />}
                        mobileContent={<ButtonGr title="Create Contract" />}
                    />

                    <div className="content-body-scroll" style={{ paddingBottom: '180px' }}>
                        <ContentBodyArea />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ContractsSmsPage;
