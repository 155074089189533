import { OTP } from '../../../../../configs/ConfigApi';
import VerificationOtpProvider from '../../../verification_otp/VerificationOtpProvider';
import BasicInfoDzoneCdelete from '../basicinfo_dzone_cdelete/BasicInfoDzoneCdelete';
import BasicInfoDzoneRemove from '../basicinfo_dzone_remove/BasicInfoDzoneRemove1';
import BasicInfoDzoneSuspend from '../basicinfo_dzone_suspend/BasicInfoDzoneSuspend';
import BasicInfoDzoneUnSuspend from '../basicinfo_dzone_unsuspend/BasicInfoDzoneUnSuspend';
import { useHostingInfoBasic } from '../HostingInfoBasicProvider';

function Divider() {
    return (
        <div className="line align-end line-g1 mb-4">
            <div className="cell">
                <span className="divider-title red">Danger Zone</span>
            </div>
            <div className="cell cell-fill">
                <hr
                    style={{
                        backgroundColor: '#EC1C24',
                        height: '1px',
                        border: 'none'
                    }}
                    className="hr mb-1"
                />
            </div>
        </div>
    );
}

function BasicInfoDzoneSection({ disable }) {
    const { basicInfo } = useHostingInfoBasic();
    const { cpSuspend, cpRemove, contractDelete } = basicInfo?.permissions || {};
    const isSuspend = basicInfo && cpSuspend;
    const isRemove = basicInfo && cpRemove;
    const isContractDelete = basicInfo && contractDelete;

    if (basicInfo && !isSuspend && !isRemove && !isContractDelete) {
        return null;
    }
    return (
        <div className="product-info dzone-section fill mb-6">
            <Divider />
            <div className="line line-g1 line-sm-wrap">
                <VerificationOtpProvider otpKey={OTP.CP_SUSPEND}>
                    <BasicInfoDzoneSuspend
                        basicInfo={basicInfo}
                        disable={disable || !isSuspend || basicInfo?.isSuspended}
                    />
                </VerificationOtpProvider>

                <BasicInfoDzoneUnSuspend
                    disable={disable || !isSuspend || !basicInfo?.isSuspended}
                />

                <VerificationOtpProvider otpKey={OTP.CP_REMOVE}>
                    <BasicInfoDzoneRemove basicInfo={basicInfo} disable={disable || !isRemove} />
                </VerificationOtpProvider>

                <VerificationOtpProvider otpKey="cp-delete-contract">
                    <BasicInfoDzoneCdelete basicInfo={basicInfo} disable={!isContractDelete} />
                </VerificationOtpProvider>
            </div>
        </div>
    );
}

export default BasicInfoDzoneSection;
