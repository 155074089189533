import BillingsListActionButton from './BillingsListActionButton';

function TableBody({ arr }) {
    if (!arr?.length) {
        return (
            <tbody>
                <tr>
                    <td colSpan={100}>No Billings Found !</td>
                </tr>
            </tbody>
        );
    }
    return (
        <tbody>
            {arr.map((data) => (
                <tr key={data?.uId} className={data?.demo ? 'placeholder-mode' : ''}>
                    <td>
                        <span>{data?.uId}</span>
                    </td>
                    <td className="user-avatar">
                        <span className="d-block">
                            <img className="d-block" src={data?.image} alt="user dp" />
                        </span>
                    </td>
                    <td>
                        <span>{data?.name}</span> <br />
                        <span className="purple font-12 break-word">{data?.email}</span>
                    </td>

                    <td>
                        <span>{data?.currency}</span>
                    </td>
                    <td>
                        <span>{data?.date}</span> <br />
                        <span className="font-12">{data?.time}</span>
                    </td>
                    <td style={{ color: data?.status === 'Active' ? '#17DC69' : '#F69220' }}>
                        <span>{data?.status}</span>
                    </td>

                    <td>
                        <BillingsListActionButton />
                    </td>
                </tr>
            ))}
        </tbody>
    );
}

function BillingsListWeb({ arr }) {
    return (
        <table className="table-list cell-sm-12" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th>User ID</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Currency</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th width="18%">Action</th>
                </tr>
            </thead>
            <TableBody arr={arr} />
        </table>
    );
}

export default BillingsListWeb;
