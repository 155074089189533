import { useState } from 'react';
import { ButtonOutlinePink } from '../../../../../configs/Buttons';
import CommonModal from '../../../system/modals/CommonModal';
import CustomSmsPackageModal from './modals/CustomSmsPackageModal';
import SectionTitle from './SectionTitle';

function SmsTabHeader({ setType, type }) {
    return (
        <div className="line-row-center mb-7">
            <div className="sms-tab-area ">
                <button
                    onClick={() => setType('non-masking')}
                    type="button"
                    className={type !== 'non-masking' ? 'inactive' : 'button-solid-sky'}
                >
                    Non-Masking
                </button>
                <button
                    onClick={() => setType('masking')}
                    type="button"
                    className={type !== 'masking' ? 'inactive' : 'button-solid-sky'}
                >
                    Masking
                </button>
            </div>
        </div>
    );
}

function PackageItem({ item }) {
    const [openModal, setOpenModal] = useState(null);
    return (
        <div key={item.id} className="cell cell-3 cell-lg-4 cell-sm-6">
            <div className="package-item">
                <p className="title mb-0">{item.title}</p>
                <p className="ammount clr-gray mt-0 mb-0">{item.quantity} SMS PACKAGE</p>
                <p className="mt-1 mb-0">
                    <span className="price-sm clr-gray mr-1">BDT</span>{' '}
                    <span className="price">{item.price}</span>
                    <span className="price-sm pink">+VAT</span>
                </p>
                <p className="quantity clr-gray mt-0">PER PCS SMS</p>
                <p>
                    <span className="fs-12 fw-500 opacity-medium">Category :</span>{' '}
                    <span className="fs-12 fs-500 black">{item.catagory}</span>
                </p>
                <ButtonOutlinePink evOnClick={() => setOpenModal(true)} title="Enroll Now" />
            </div>
            {openModal && <CommonModal setOpenModal={setOpenModal} />}
        </div>
    );
}

function CustomPackageItem() {
    const [openModal, setOpenModal] = useState(null);
    return (
        <div className="cell cell-3 cell-lg-4 cell-sm-6 custom-package-area ">
            <div className="package-item line-col-center">
                <p className="ammount">
                    Can`t find what you want? <br /> Let’s Create your own Package
                </p>
                <button
                    onClick={() => setOpenModal(true)}
                    type="button"
                    className="button-solid-sky btn-custom-package"
                >
                    Custom Package
                </button>

                {openModal && (
                    <CustomSmsPackageModal setOpenModal={setOpenModal} openModal={openModal} />
                )}
                <p className="ammount">Price Calculator</p>
            </div>
        </div>
    );
}

function SmsPricing({ type }) {
    const items = [
        {
            id: 1,
            title: 'SMS',
            quantity: 10000,
            price: 0.5,
            catagory: 'non-masking'
        },
        {
            id: 2,
            title: 'SMS',
            quantity: 10000,
            price: 0.35,
            catagory: 'masking'
        },
        {
            id: 3,
            title: 'SMS',
            quantity: 10000,
            price: 0.5,
            catagory: 'non-masking'
        },
        {
            id: 4,
            title: 'SMS',
            quantity: 10000,
            price: 0.5,
            catagory: 'masking'
        },
        {
            id: 5,
            title: 'SMS',
            quantity: 10000,
            price: 0.35,
            catagory: 'non-masking'
        },
        {
            id: 6,
            title: 'SMS',
            quantity: 10000,
            price: 0.5,
            catagory: 'masking'
        }
    ];
    return (
        <div className="line-row-between align-unset line-g3 line-md-g2 sms-package-area">
            {items
                .filter((el) => el.catagory === type)
                .map((item) => (
                    <PackageItem item={item} key={item.id} />
                ))}
            <div className="cell-lg-4 d-none d-sm-none d-lg-block" />
            <CustomPackageItem />
            <div className="cell-lg-4 d-none d-sm-none d-lg-block" />
        </div>
    );
}

function SmsPricingSection() {
    const [type, setType] = useState('non-masking');
    return (
        <div className="container mb-120">
            <div className="sms-pricing-area">
                <SectionTitle titleSm="Packages" title="SMS Pricing" />
                <SmsTabHeader setType={setType} type={type} />
                <SmsPricing type={type} />
            </div>
        </div>
    );
}

export default SmsPricingSection;
