/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { ButtonOutlinePurple, ButtonOutlineRed } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import PasswordEditArea from '../password_edit/PasswordEditArea';

function ButtonView({ isVisible, setMode }) {
    if (isVisible) {
        return <ButtonOutlineRed evOnClick={() => setMode({ type: null })} title="Cancel" />;
    }
    return (
        <ButtonOutlinePurple evOnClick={() => setMode({ type: 'password-edit' })} title="Edit" />
    );
}

function PasswordView({ setMode, isVisible }) {
    return (
        <div className={`info-list-view ${isVisible ? 'bg-active' : ''}`}>
            <div className="line line-g2 line-no-wrap">
                <div className="cell cell-fill">
                    <div className="line-align line-no-wrap">
                        <div className="cell-md-2 d-md-none">
                            <div className="list-logo">
                                <img
                                    className="d-block w-100"
                                    src={icons.iconPassChange}
                                    alt="icon device"
                                />
                            </div>
                        </div>

                        <div className="content-text">
                            <span className="font-16 clr">
                                <span className="line-row-between line-no-wrap mb-md-2">
                                    <span className="no-wrap">Change password</span>
                                    <span className="ml-4">
                                        <span className="d-none d-md-block">
                                            <ButtonView isVisible={isVisible} setMode={setMode} />
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <span className="font-14 opacity-medium d-block break-word">
                                It`s a good idea to use a strong password that you`re not using
                                elsewhere
                            </span>
                        </div>
                    </div>
                </div>

                <div className="cell cell-2 cell-md-fill text-right cell-self-middle common-view d-md-none">
                    <span className="">
                        <ButtonView isVisible={isVisible} setMode={setMode} />
                    </span>
                </div>
            </div>
        </div>
    );
}

function PasswordSection() {
    const [mode, setMode] = useState({ type: '', data: {} });
    const isVisible = mode?.type === 'password-edit';
    return (
        <div className="mb-6">
            <div
                className={`info-list-header opacity-medium border-transparent ${
                    isVisible ? 'border-bottom-i' : ''
                }`}
            >
                <span className="fs-16 fw-500">Login & Password</span>
            </div>
            <PasswordView setMode={setMode} isVisible={isVisible} />
            <PasswordEditArea isVisible={isVisible} />
        </div>
    );
}

export default PasswordSection;
