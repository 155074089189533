import { basic } from '../../../../../configs/Images';
import LayoutSelector from '../../../../utils/LayoutSelector';
import SocialListMobile from './SocialListMobile';
import SocialListWeb from './SocialListWeb';

const info = [
    {
        id: 1,
        name: 'Facebook',
        img: basic.logoFb,
        url: 'https://www.facebook.com'
    },
    {
        id: 2,
        name: 'WhatsApp',
        img: basic.logoWhatsapp,
        url: 'https://www.whatsapp.com/'
    },
    {
        id: 3,
        name: 'Skype',
        img: basic.logoSkype,
        url: 'https://www.skype.com'
    },
    {
        id: 4,
        name: 'Github',
        img: basic.logoGithub,
        url: 'https://www.github.com'
    },
    {
        id: 5,
        name: 'Twitter',
        img: basic.logoTwitter,
        url: 'https://www.whatsapp.com'
    }
];

function SocialListSection() {
    return (
        <LayoutSelector
            sm
            md
            webComp={<SocialListWeb info={info} />}
            mobileComp={<SocialListMobile info={info} />}
        />
    );
}

export default SocialListSection;
