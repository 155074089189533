import { ButtonOutlinePurple } from '../../../../../configs/Buttons';
import LayoutSelector from '../../../../utils/LayoutSelector';
import ProgressBar from '../../../../utils/ProgressBar';

function RowWebView({ title, space, btnTitle, evOnClick, color, progress, cName, info }) {
    return (
        <div className="line line-g1 line-no-wrap mb-2">
            <div className={`cell cell-3 ${cName}`}>
                <span className="subject no-wrap" style={{ color: '#8987A0', opacity: '100%' }}>
                    {title}
                </span>
            </div>
            <div className="cell cell-fill">
                <div className="line-align mt-2">
                    <ProgressBar bgcolor={color} progress={info ? progress : null} height={3} />
                </div>
            </div>
            <div className="cell cell-2 text-left">
                <div className="used-amount">
                    <span className="subject">{space}</span>
                </div>
            </div>
            <div className="cell">
                <div className="line justify-end" style={{ width: !btnTitle ? '84px' : '' }}>
                    {btnTitle && (
                        <span className="w-100">
                            {' '}
                            <ButtonOutlinePurple evOnClick={evOnClick} title={btnTitle} />
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
}
function RowMobileView({ title, space, btnTitle, evOnClick, color, progress, info }) {
    return (
        <div className="line-align line-g2 line-no-wrap mb-4">
            <div className="cell cell-fill overflow-dotted">
                <div className="line line-g2 line-md-wrap">
                    <div className="cell cell-8 cell-self-middle overflow-dotted">
                        <span className="subject">{title}</span>{' '}
                    </div>
                    <div className="cell cell-4 cell-self-middle text-right">
                        <span className="subject text-dotted">{space}</span>
                    </div>
                    <div className="cell cell-12 cell-self-end">
                        <div className="line-align h-100 mt-3">
                            <ProgressBar
                                bgcolor={color}
                                progress={info ? progress : null}
                                height={3}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cell">
                <div className="line justify-end" style={{ width: !btnTitle ? '84px' : '' }}>
                    {btnTitle && (
                        <span>
                            <ButtonOutlinePurple evOnClick={evOnClick} title={btnTitle} />
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
}

const InfoRowView = ({ title, space, btnTitle, evOnClick, color, progress, cName, info }) => (
    <LayoutSelector
        sm
        md
        webComp={
            <RowWebView
                title={title}
                space={space}
                btnTitle={btnTitle}
                evOnClick={evOnClick}
                color={color}
                progress={progress}
                cName={cName}
                info={info}
            />
        }
        mobileComp={
            <RowMobileView
                title={title}
                space={space}
                btnTitle={btnTitle}
                evOnClick={evOnClick}
                color={color}
                progress={progress}
                info={info}
            />
        }
    />
);

export default InfoRowView;
