import React, { useEffect } from 'react';
import PageProperties from '../../../utils/PageProperties';
import HeaderSection from '../../headers/HeaderSection';
import FooterSection2 from '../../system/section/Footer2Section';
import MobileFooter from '../../users/components/MobileFooter';
import AboutSection from '../components/AboutSection';
import FixedHeaderControl from '../components/FixedHeaderControl';

function AboutPage({ page }) {
    useEffect(() => {
        if (page === 'about') {
            document.getElementById('about').scrollIntoView();
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [page]);

    useEffect(() => {
        PageProperties('About');
    }, []);

    return (
        <>
            <div id="about" />
            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>
            <AboutSection />
            {/* <ChatBtnSection /> */}
            <FooterSection2 />
            <MobileFooter />
        </>
    );
}

export default AboutPage;
