import { Link } from 'react-router-dom';
import { ButtonActionReload, ButtonGr } from '../../../../../configs/Buttons';
import { DashboardDefaultHeader } from '../../../headers/DashboardHeaderSection';

function HeaderButtonMobile({ handelReload }) {
    return (
        <div className="line justify-end">
            <div className="header-action-btn">
                <ButtonActionReload evOnClick={handelReload} />
            </div>
        </div>
    );
}

function HeaderButtonWeb({ handelReload }) {
    return (
        <div className="line justify-end line-no-wrap">
            <span style={{ width: '30px' }} className="mr-2">
                <ButtonActionReload evOnClick={handelReload} />
            </span>

            <span>
                <Link to="/services/hosting/web/">
                    <ButtonGr title="Buy More" />
                </Link>
            </span>
        </div>
    );
}

function HostingListHeader({ handelReload, listDetails }) {
    return (
        <DashboardDefaultHeader
            title="Hosting"
            subTitle={listDetails}
            path="/user/profile-overview/"
            content={<HeaderButtonWeb handelReload={handelReload} />}
            mobileContent={<HeaderButtonMobile handelReload={handelReload} />}
        />
    );
}

export default HostingListHeader;
