import React from 'react';
import { icons } from '../../../../configs/Icons';

const ModalBody = ({ name, email, signOut, makeAction }) => {
    const handelYesClick = () => {
        makeAction('sign-up');
    };

    const handelNoClick = () => {
        signOut().then(() => {
            makeAction('');
        });
    };

    return (
        <div className="modal-box-body">
            <div>
                <div className="create-an-account">
                    <div>
                        <img src={icons.iconInfo} alt="" />
                    </div>
                    <div>
                        Hello <b>{name}</b>, your email <b>{email}</b> is not registered with
                        BIKIRAN. Would you please follow the registration process?
                    </div>

                    <div className="text-center">
                        <button type="button" onClick={handelNoClick} className="button">
                            No
                        </button>
                        <button
                            type="button"
                            onClick={handelYesClick}
                            className="button button-green"
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

function RegistrationLinkWarningModal({ show, firebase = {}, signOut, makeAction }) {
    if (!show) {
        return null;
    }

    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <ModalBody
                    name={firebase?.displayName || 'User'}
                    email={firebase?.email || ''}
                    signOut={signOut}
                    makeAction={makeAction}
                />
            </div>
        </div>
    );
}

export default RegistrationLinkWarningModal;
