/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import {
    ButtonClose,
    ButtonCloseMinus,
    ButtonGr,
    ButtonOutlinePink
} from '../../../../configs/Buttons';
import { useTemplate } from '../../../contexts/TemplateProvider';
import countries from '../../../utils/country.json';

const ModalHeader = ({ closeEditors }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title">
                <span>Update Address</span>
            </div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={closeEditors} />
            </div>
        </div>
    </div>
);

function TelephoneField({ formData, handelChangeValue, handelChangeForm }) {
    if (formData.isTelephone && formData.isFax) {
        return (
            <div className="cell cell-6 field-active">
                <div className="line-row-between">
                    <div>
                        <label htmlFor="telephone">Telephone</label>
                    </div>
                    <div className="field-close-area">
                        <ButtonCloseMinus
                            evOnClick={() => handelChangeValue('isTelephone', false)}
                        />
                    </div>
                </div>
                <input
                    name="telephone"
                    onChange={handelChangeForm}
                    value={formData?.telephone || ''}
                    placeholder="Enter Telephone"
                    type="text"
                    className="form-input w-100"
                />
            </div>
        );
    }

    return (
        <div
            className={
                formData.isTelephone ? 'cell cell-12 field-active' : 'cell cell-12 field-inactive '
            }
        >
            <div className="line-row-between">
                <div>
                    <label htmlFor="telephone">Telephone</label>
                </div>
                <div className="field-close-area">
                    <ButtonCloseMinus evOnClick={() => handelChangeValue('isTelephone', false)} />
                </div>
            </div>
            <input
                name="telephone"
                onChange={handelChangeForm}
                value={formData?.telephone || ''}
                placeholder="Enter Telephone"
                type="text"
                className="form-input w-100"
            />
        </div>
    );
}
function FaxField({ formData, handelChangeValue, handelChangeForm }) {
    if (formData.isTelephone && formData.isFax) {
        return (
            <div className="cell cell-6 field-active">
                <div className="line-row-between">
                    <div>
                        <label htmlFor="fax">Fax</label>
                    </div>
                    <div className="field-close-area">
                        <ButtonCloseMinus evOnClick={() => handelChangeValue('isFax', false)} />
                    </div>
                </div>
                <input
                    name="fax"
                    onChange={handelChangeForm}
                    value={formData?.fax || ''}
                    placeholder="Enter Fax"
                    type="text"
                    className="form-input w-100"
                />
            </div>
        );
    }

    return (
        <div
            className={formData.isFax ? 'cell cell-12 field-active' : 'cell cell-12 field-inactive'}
        >
            <div className="line-row-between">
                <div>
                    <label htmlFor="fax">Fax</label>
                </div>
                <div className="field-close-area">
                    <ButtonCloseMinus evOnClick={() => handelChangeValue('isFax', false)} />
                </div>
            </div>
            <input
                name="fax"
                onChange={handelChangeForm}
                value={formData?.fax || ''}
                placeholder="Enter Fax"
                type="text"
                className="form-input w-100"
            />
        </div>
    );
}

function ModalBody({ setAddress, closeEditors, modalProperty, country }) {
    const apiData = modalProperty?.data || {};

    const [formData, setFormData] = useState({
        ...apiData,
        isTelephone: !!apiData.telephone,
        isFax: !!apiData.fax,
        saveAsDefault: false,
        saveAsDomainProfile: false,
        country
    });

    const { setMessage } = useTemplate();

    const handelChangeForm = (ev) => {
        const { name, value } = ev.target;
        setFormData((cuData) => ({ ...cuData, [name]: value }));
    };

    const handelChangeValue = (name, value) => {
        setFormData((cuData) => ({ ...cuData, [name]: value }));
    };

    const handelSaveAsDP = (value) => {
        if (value === true) {
            setFormData((cuData) => ({ ...cuData, saveAsDomainProfile: true, isTelephone: true }));
        } else {
            setFormData((cuData) => ({ ...cuData, saveAsDomainProfile: false }));
        }
    };

    const handelClickSave = () => {
        // setMessage('Updating Address...');

        setAddress(formData)
            .then(({ message }) => {
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    const isLocationBd =
        formData?.country?.toLowerCase() === 'bd' && country?.toLowerCase() === 'bangladesh';

    return (
        <div className="modal-body-area overflow-auto">
            <div className="line-row-between line-g2">
                <div className="cell cell-6">
                    <label htmlFor="fullname">Full Name</label>
                    <input
                        name="name"
                        onChange={handelChangeForm}
                        value={formData?.name || ''}
                        placeholder="Enter Full Name"
                        type="text"
                        className="form-input w-100"
                    />
                </div>
                <div className="cell cell-6">
                    <label htmlFor="org">Organization</label>
                    <input
                        name="org"
                        onChange={handelChangeForm}
                        value={formData?.org || ''}
                        placeholder="Enter Organization"
                        type="text"
                        className="form-input w-100"
                    />
                </div>
                <div className="cell cell-6">
                    <label htmlFor="email">Email</label>
                    <input
                        name="email"
                        onChange={handelChangeForm}
                        value={formData?.email || ''}
                        placeholder="Enter Email"
                        type="email"
                        className="form-input w-100"
                    />
                </div>
                <div className="cell cell-6">
                    <div className="line-row-between">
                        <div>
                            <label htmlFor="mobile">Mobile</label>
                        </div>
                        <div className="contact-btn-area cell-6 line line-no-wrap justify-end ">
                            <span
                                onClick={() => handelChangeValue('isTelephone', true)}
                                role="button"
                                tabIndex={-1}
                                title="Add Telephone"
                                className="tel-icon"
                            >
                                +
                            </span>
                            <span
                                onClick={() => handelChangeValue('isFax', true)}
                                role="button"
                                tabIndex={-1}
                                title="Add Fax"
                                className="fax-icon ml-1"
                            >
                                +
                            </span>
                        </div>
                    </div>
                    <input
                        name="mobile"
                        onChange={handelChangeForm}
                        value={formData?.mobile || ''}
                        placeholder="Enter Mobile"
                        type="tel"
                        className="form-input w-100"
                    />
                </div>
                <TelephoneField
                    formData={formData}
                    handelChangeValue={handelChangeValue}
                    handelChangeForm={handelChangeForm}
                />

                <FaxField
                    formData={formData}
                    handelChangeValue={handelChangeValue}
                    handelChangeForm={handelChangeForm}
                />

                <div className="cell cell-12">
                    <label htmlFor="line1">Address Line</label>

                    <textarea
                        name="line1"
                        onChange={handelChangeForm}
                        value={formData?.line1 || ''}
                        className="form-input w-100"
                        placeholder="Enter Address.."
                        cols="100"
                        rows="3"
                    />
                </div>
                <div className="cell cell-6">
                    <label htmlFor="country">Country</label>
                    <select
                        name="country"
                        onChange={handelChangeForm}
                        value={formData?.country}
                        className="form-input  mb-0"
                    >
                        <option value="">Select</option>
                        {countries.map((item) => (
                            <option key={item?.code} value={item?.code}>
                                {item?.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="cell cell-6">
                    <label htmlFor="state">State</label>
                    <input
                        name="state"
                        onChange={handelChangeForm}
                        value={formData?.state || ''}
                        placeholder="Enter State"
                        type="text"
                        className="form-input w-100"
                    />
                </div>

                {isLocationBd ? (
                    <div className="cell cell-12">
                        <span className="d-block font-12 no-wrap red" style={{ marginTop: '-8px' }}>
                            Your bill`s currency will be converted to BDT.
                        </span>
                    </div>
                ) : null}

                <div className="cell cell-6">
                    <label htmlFor="city">City</label>
                    <input
                        name="city"
                        onChange={handelChangeForm}
                        value={formData?.city || ''}
                        placeholder="Enter City"
                        type="text"
                        className="form-input w-100"
                    />
                </div>
                <div className="cell cell-6">
                    <label htmlFor="zip">Zip</label>
                    <input
                        name="zip"
                        onChange={handelChangeForm}
                        value={formData?.zip || ''}
                        placeholder="Enter Zip Code"
                        type="text"
                        className="form-input w-100"
                    />
                </div>
                <div className="cell cell-12">
                    <div
                        className="line line-g1"
                        onClick={() => {
                            handelChangeValue('saveAsDefault', !formData.saveAsDefault);
                        }}
                        tabIndex="-1"
                        role="button"
                    >
                        <span className="cell">
                            <input type="checkbox" checked={formData.saveAsDefault} readOnly />
                        </span>
                        <span className="cell"> Save as default address</span>
                    </div>
                </div>
                <div className="cell cell-12">
                    <div
                        className="line line-g1"
                        onClick={() => {
                            handelSaveAsDP(!formData.saveAsDomainProfile);
                        }}
                        tabIndex="-1"
                        role="button"
                    >
                        <span className="cell">
                            <input
                                type="checkbox"
                                checked={formData.saveAsDomainProfile}
                                readOnly
                            />
                        </span>
                        <span className="cell"> Save as domain profile</span>
                    </div>
                </div>
            </div>

            <div className="w-100 text-right mt-3">
                <span>
                    <ButtonOutlinePink evOnClick={closeEditors} title="Cancel" />
                </span>
                <span className="ml-2">
                    <ButtonGr evOnClick={handelClickSave} title="Save" />
                </span>
            </div>
        </div>
    );
}

function PaymentAddressModifyModal({ show, modalProperty, setAddress, closeEditors, country }) {
    if (!show) {
        return null;
    }

    // console.log(modalProperty);

    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content user-address-modal-area">
                <ModalHeader closeEditors={closeEditors} />
                <ModalBody
                    setAddress={setAddress}
                    closeEditors={closeEditors}
                    modalProperty={modalProperty}
                    country={country}
                />
            </div>
        </div>
    );
}

export default PaymentAddressModifyModal;
