import { Link } from 'react-router-dom';
import UsersListActionButton from './UsersListActionButton';

function TableBody({ arr }) {
    if (!arr?.length) {
        return (
            <tbody>
                <tr>
                    <td colSpan={100}>No Users Found !</td>
                </tr>
            </tbody>
        );
    }
    return arr?.map((data) => (
        <tbody key={data?.uId}>
            <tr className={data?.demo ? 'placeholder-mode' : ''}>
                <td>
                    <span>{data?.uId}</span>
                </td>
                <td className="user-avatar">
                    <span className="d-block">
                        <img className="d-block" src={data?.image} alt="" />
                    </span>
                </td>
                <td>
                    <Link to={`/smanager/users/${data?.uId}`}>
                        <span>{data?.name}</span> <br />
                        <span className="purple font-12">{data?.email}</span>
                    </Link>
                </td>
                <td>
                    <span>{data?.phone}</span>
                </td>
                <td>
                    <span>{data?.loginDate}</span> <br />
                    <span className="font-12">{data?.loginTime}</span>
                </td>

                <td>
                    <UsersListActionButton />
                </td>
            </tr>
        </tbody>
    ));
}

function UsersListWeb({ arr }) {
    return (
        <table className="table-list cell-sm-12" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th>User ID</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th>Last Login</th>
                    <th>Action</th>
                </tr>
            </thead>
            <TableBody arr={arr} />
        </table>
    );
}

export default UsersListWeb;
