import { useEffect, useState } from 'react';
import { ButtonGr } from '../../../../../configs/Buttons';
import PageProperties from '../../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../../headers/DashboardHeaderSection';
import TicketDetailsModal from '../../modals/TicketDetailsModal';
import TicketListSection from './ticket_list/TicketListSection';

function HeaderContent() {
    return (
        <div className="line justify-end">
            <ButtonGr evOnClick={null} title="Issue Ticket" />
        </div>
    );
}

function ContentBodyArea() {
    const [showDt, setShowDt] = useState(null);

    return (
        <div className="list-container w-100">
            <TicketListSection />

            <TicketDetailsModal showDt={showDt} show={showDt !== null} setShowDt={setShowDt} />
        </div>
    );
}

function SupportTicketPage() {
    useEffect(() => {
        PageProperties(`Support Ticket`);
    }, []);

    return (
        <div className="cell cell-content h-100 w-100 domain-section">
            <div className="content-section">
                <div className="content-area user-support-ticket-area">
                    <DashboardDefaultHeader
                        title="Support Ticket"
                        path="/smanager/ticket/"
                        content={<HeaderContent />}
                        mobileContent={<HeaderContent />}
                    />

                    <div className="content-body-scroll" style={{ paddingBottom: '180px' }}>
                        <ContentBodyArea />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SupportTicketPage;
