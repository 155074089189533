import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import PageProperties from '../../../utils/PageProperties';
import DomainInfoBasicProvider, { useDomainInfo } from '../domains_info/DomainInfoBasicProvider';
import DomainInfoHeader from '../domains_info_header/DomainInfoHeader';
import TabsRowSection from '../domains_info_tabs/TabsRowSection';
import ConversationListSection from '../_models/conversation/conversation_list/ConversationListSection';

function ContentArea() {
    const { domainInfo, handelReloadButton } = useDomainInfo();
    return (
        <>
            <DomainInfoHeader
                path="conversation/"
                info={domainInfo}
                evReloadClick={handelReloadButton}
            />
            <div className="content-body">
                <div className={`content-body-scroll `}>
                    <TabsRowSection conversation info={domainInfo} />
                    <ConversationListSection />
                </div>
            </div>
        </>
    );
}

function DomainInfoConversationPage() {
    const { currentUser } = useAuth();
    const { contractId } = useParams();

    useEffect(() => {
        PageProperties(`Conversation \u2022 Domain`);
    }, []);

    return (
        <DomainInfoBasicProvider currentUser={currentUser} contractId={contractId}>
            <div className="content-area hosting-info-page">
                <ContentArea />
            </div>
        </DomainInfoBasicProvider>
    );
}

export default DomainInfoConversationPage;
