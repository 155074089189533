import { ButtonOutlineGr, ButtonOutlineRed } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import SecurityTwoFacAuthVerifyPassPopup from './SecurityTwoFacAuthPassPopup';

function ListLogo() {
    return (
        <div className="cell-md-2">
            <div className="list-logo">
                <img className="d-block w-100" src={icons.iconShieldV2} alt="icon device" />
            </div>
        </div>
    );
}

function ButtonView({ setMode, isActive }) {
    if (isActive) {
        return (
            <ButtonOutlineGr evOnClick={() => setMode({ type: 'off', data: {} })} title="Turn On" />
        );
    }
    return (
        <ButtonOutlineRed evOnClick={() => setMode({ type: 'on', data: {} })} title="Turn Off" />
    );
}

function CommonView({ value, cName }) {
    return <span className={`font-14 ${cName}`}>{value}</span>;
}

function TitleTextArea() {
    return (
        <div className="line-inline line-align line-no-wrap">
            <ListLogo />
            <div className="content-text">
                <CommonView value="Use two-factor authentication." cName="clr" />
                <CommonView
                    value="We`ll ask for a login code for an unrecognized device or
                                    browser."
                    cName="opacity-medium d-block break-all"
                />
            </div>
        </div>
    );
}

function InfoListArea({ setMode, isActive, currentUser, mode }) {
    return (
        <div className="info-list-view">
            <div className="line line-g2 line-no-wrap">
                <div className="cell cell-8 cell-md-fill">
                    <TitleTextArea />
                </div>

                <div className="cell-fill cell-self-middle text-left">
                    <CommonView value="Activated" cName="text-left green" />
                </div>

                <div className="cell cell-2 cell-md-fill text-right cell-self-middle">
                    <ButtonView setMode={setMode} isActive={isActive} />
                </div>

                {/* popup */}
                <SecurityTwoFacAuthVerifyPassPopup
                    show={isActive}
                    setMode={setMode}
                    mode={mode}
                    currentUser={currentUser}
                />
            </div>
        </div>
    );
}

function SecurityTwoFacAuthWeb({ setMode, isActive, currentUser, mode }) {
    return (
        <div className="mb-6">
            <div className="info-list-header opacity-medium">
                <span className="fs-16 fw-500 no-wrap">Two-Factor authentication</span>
            </div>
            <InfoListArea
                isActive={isActive}
                setMode={setMode}
                mode={mode}
                currentUser={currentUser}
            />
        </div>
    );
}

export default SecurityTwoFacAuthWeb;
