import { icons } from '../../../../../configs/Icons';

function TabsDatabase({ info, evOnClick, database }) {
    return (
        <div
            className={`cell cell-fill cell-md-4 cell-xl-3 cell-sm-5 ${
                !info ? 'disabled-view' : ''
            }`}
            onClick={evOnClick}
            role="button"
            tabIndex={-1}
        >
            <div
                role="button"
                tabIndex={-1}
                className={`info-tab-item database line-row-center ${database ? 'active' : ''}`}
            >
                <div className="line-col-center">
                    <div className="logo">
                        <img
                            className="w-100 h-100 d-block"
                            src={database ? icons.iconDatabaseActive : icons.imgDatabase}
                            alt="cpanel"
                        />
                    </div>
                    <div className="title">
                        <span>Databases</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TabsDatabase;
