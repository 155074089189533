import { ButtonOutlinePink } from '../../../../configs/Buttons';
import Divider from '../../websites/models/SectionDivider';
import { useDomainInfo } from '../domains_info/DomainInfoBasicProvider';

function CommonRowView({ title, value }) {
    return (
        <div className="line mb-2">
            <div className="cell cell-5">
                <span className="subject ">{title}:</span>
            </div>
            <div className="cell cell-7">
                <span className="value">{value || '--'}</span>
            </div>
        </div>
    );
}

function DomainInfoContactsRegistrantSection() {
    const { cuContact, setModal } = useDomainInfo();
    const { name, email, telephone, org, address } = cuContact?.registrant || {};
    const { line1, line2, line3, countryFull } = address || {};

    return (
        <div className="product-info fill mb-4" style={{ backgroundColor: 'rgb(174 0 185 / 3%)' }}>
            <Divider
                title="Registrant"
                action={<ButtonOutlinePink evOnClick={() => setModal(0)} title="Edit" />}
            />
            <div className="">
                <div className="">
                    <CommonRowView title="Name" value={name || '--'} />
                    <CommonRowView title="Email" value={email || '--'} />
                    <CommonRowView title="Telephone" value={telephone || '--'} />
                    <CommonRowView title="Organization" value={org || '--'} />
                    <CommonRowView
                        title="Address"
                        value={`${line1 || line2 || line3}, ${countryFull}` || '--'}
                    />
                </div>
                {/* <div className="cell cell-6 cell-md-12"> */}
                {/* <CommonRowView
                        title="Status"
                        value={
                            <span>
                                <span
                                    hidden={!status}
                                    className={
                                        status === 'active' ? 'circle-enable' : 'circle-disable'
                                    }
                                />
                                <span className="ml-1">{status || '--'}</span>
                            </span>
                        }
                    /> */}

                {/* <CommonRowView title="Contract ID" value={contractId || '--'} /> */}
                {/* </div> */}
            </div>
        </div>
    );
}

export default DomainInfoContactsRegistrantSection;
