import React, { useEffect } from 'react';

import { ButtonGr } from '../../../../configs/Buttons';
import CommonPlaceholder from '../../../placeholders/components/CommonPlaceholder';
import PageProperties from '../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../headers/DashboardHeaderSection';

function ContentHead() {
    return <DashboardDefaultHeader title="Email Service" path="/user/profile-overview/" />;
}

function ContentBodyArea({ data }) {
    if (!data?.length) {
        return <CommonPlaceholder text="Email Service" url="marketing/email" />;
    }
    return (
        <div className="product-info mb-6">
            <div>
                <div className="line mb-5">
                    <div className="cell cell-6 cell-sm-12">
                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">Type:</span>
                            </div>
                            <div className="cell cell-7">
                                <span className="value">Non Masking</span>
                            </div>
                        </div>

                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">Domain: </span>
                            </div>
                            <div className="cell cell-7">
                                <span className="value">bikiran.com</span>
                            </div>
                        </div>
                    </div>
                    <div className="cell cell-6 cell-sm-12">
                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">Current Balance: </span>
                            </div>
                            <div className="cell cell-7">
                                <span className="value">3478 SMS</span>
                            </div>
                        </div>
                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">Due Ammount: </span>
                            </div>
                            <div className="cell cell-7">
                                <span className="value">BDT 0</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <span className="">
                        <ButtonGr evOnClick={null} title="Contact Us" />
                    </span>
                </div>
            </div>
        </div>
    );
}
function UsersEmailPage({ data }) {
    useEffect(() => {
        PageProperties(`Email List`);
    }, []);

    return (
        <div className="cell cell-content h-100">
            <div className="content-section">
                <div className="content-area">
                    <ContentHead data={data} />
                    <div className="content-body-scroll">
                        <ContentBodyArea data={data} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsersEmailPage;
