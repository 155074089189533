import React from 'react';
import { ButtonActionEdit } from '../../../../../configs/Buttons';

function RowCommonView({ title, value }) {
    return (
        <div className="line-row-between mb-1">
            <div className="cell">
                <span className="value primary-70-i">{title || '--'}:</span>
            </div>
            <div className="cell">
                <span className="value primary">{value || '--'}</span>
            </div>
        </div>
    );
}

function DnsListMobile({ arr }) {
    if (!arr?.length) {
        return (
            <div>
                <div className="list-item-sm">
                    <span className="title">No Data Found!</span>
                </div>
            </div>
        );
    }
    return (
        <div>
            {arr?.map((data) => (
                <div
                    key={data?.id}
                    className={`list-item-sm ${data?.demo ? 'placeholder-mode' : ''}`}
                >
                    <div className="line-row-between mb-2">
                        <div>
                            <span className="title primary">{data?.name}</span>
                            <span className="sub-title primary-50 d-block">({data?.type})</span>
                        </div>
                        <span className="btn-action">
                            <ButtonActionEdit evOnClick={null} />
                        </span>
                    </div>
                    <RowCommonView title="Content" value={data?.content} />
                    <RowCommonView title="Proxy" value={data?.proxy} />
                    <RowCommonView title="Status" value={data?.ttl} />
                </div>
            ))}
        </div>
    );
}

export default DnsListMobile;
