import LayoutSelector from '../projects/utils/LayoutSelector';
import { editIcons, icons } from './Icons';

export function BtnProfileEdit({ evOnClick, isDisabled }) {
    if (isDisabled) {
        return (
            <button type="button" className="btn-profile-edit" disabled>
                <img className="w-100 " src={icons.iconProfileEditGray} alt="Edit Icon" />
            </button>
        );
    }

    return (
        <button type="button" onClick={evOnClick} className="btn-profile-edit">
            <img className="w-100 " src={editIcons.iconEditV4} alt="Edit Icon" />
            <img className="w-100 " src={icons.iconProfileEditGray} alt="Edit Icon" />
        </button>
    );
}

export function BtnProfileManage({ evOnClick, isDisabled }) {
    if (isDisabled) {
        return (
            <button type="button" className="btn-profile-manage d-block" disabled>
                <img className="w-100 " src={icons.iconProfileManageGray} alt="Edit Icon" />
            </button>
        );
    }
    return (
        <button type="button" onClick={evOnClick} className="btn-profile-manage d-block">
            <img className="w-100 " src={icons.iconManageV2} alt="Edit Icon" />
            <img className="w-100 " src={icons.iconProfileManageGray} alt="Edit Icon" />
        </button>
    );
}

export function ButtonClose({ evOnClick }) {
    return <button className="button squire" type="button" onClick={evOnClick} />;
}

export function ButtonCloseMinus({ evOnClick }) {
    return <button className="button-close-minus default-none" type="button" onClick={evOnClick} />;
}

// table action buttons

export function ButtonActionCart({ evOnClick }) {
    return <button className="icon-action-cart default-none" type="button" onClick={evOnClick} />;
}

export function ButtonActionLogout({ evOnClick }) {
    return <button className="icon-action-logOut default-none" type="button" onClick={evOnClick} />;
}

export function ButtonActionDetails({ evOnClick }) {
    return <button className="icon-action-eye default-none" type="button" onClick={evOnClick} />;
}

export function ButtonActionCpanel({ evOnClick, disabled }) {
    return (
        <button
            className="icon-action-cpanel default-none"
            type="button"
            onClick={evOnClick}
            disabled={disabled}
        />
    );
}

export function ButtonActionDotMenu({ evOnClick, purple }) {
    return (
        <button
            className={`icon-action-dot-menu default-none ${
                purple ? 'purple-variant' : 'red-variant'
            }`}
            type="button"
            onClick={evOnClick}
        />
    );
}

export function ButtonActionDotMenuV2({ evOnClick }) {
    return (
        <button
            className="icon-action-dot-menu default-none v2"
            type="button"
            onClick={evOnClick}
        />
    );
}

export function ButtonActionReset({ evOnClick }) {
    return (
        <button className={`icon-action-reset default-none `} type="button" onClick={evOnClick} />
    );
}

export function ButtonActionEdit({ evOnClick }) {
    return <button className="icon-action-edit default-none" type="button" onClick={evOnClick} />;
}

export function ButtonActionChat({ evOnClick }) {
    return <button className="icon-action-chat default-none" type="button" onClick={evOnClick} />;
}

export function ButtonActionUpdate({ evOnClick }) {
    return <button className="icon-action-update default-none" type="button" onClick={evOnClick} />;
}

export function ButtonActionSave({ evOnClick }) {
    return <button className="icon-action-save default-none" type="button" onClick={evOnClick} />;
}

export function ButtonActionDelete({ evOnClick }) {
    return <button className="icon-action-delete default-none" type="button" onClick={evOnClick} />;
}

export function ButtonActionCancel({ evOnClick }) {
    return <button className="icon-action-cancel default-none" type="button" onClick={evOnClick} />;
}

export function ButtonActionReload({ evOnClick }) {
    return <button className="icon-reload default-none" type="button" onClick={evOnClick} />;
}

export function ButtonActionInvoiceCancel({ evOnClick }) {
    return (
        <button
            className="icon-action-invoice-cancel default-none"
            type="button"
            onClick={evOnClick}
        />
    );
}

export function ButtonActionUnblock({ evOnClick }) {
    return (
        <button className="icon-action-unblock default-none" type="button" onClick={evOnClick} />
    );
}

export function ButtonGr({ evOnClick, title, modal, disabled }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`${!modal ? 'button-web' : 'button-modal'} btn-gr default-none  `}
            disabled={disabled}
        >
            {title}
        </button>
    );
}

export function ButtonOutlineGr({ evOnClick, title, modal }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`button-line-gr default-none ${!modal ? 'button-web' : 'button-modal'}`}
        >
            {title}
        </button>
    );
}

export function ButtonCancel({ evOnClick, title }) {
    return (
        <button onClick={evOnClick} type="button" className="btn-cancel default-none">
            {title}
        </button>
    );
}

export function ButtonOutlinePurple({ evOnClick, title, modal }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`${!modal ? 'button-web' : 'button-modal'} button-line-purple default-none`}
        >
            {title}
        </button>
    );
}

export function ButtonOutlineRed({ evOnClick, title, modal }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`${!modal ? 'button-web' : 'button-modal'} button-line-red default-none`}
        >
            {title}
        </button>
    );
}

export function ButtonPurple({ evOnClick, title, modal, disabled }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`${!modal ? 'button-web' : 'button-modal'} button-purple default-none  `}
            disabled={disabled}
        >
            {title}
        </button>
    );
}

export function ButtonSolidOrng({ evOnClick, title }) {
    return (
        <button onClick={evOnClick} type="button" className="btn-solid-orange default-none   w-100">
            {title}
        </button>
    );
}

export function ButtonOutlineOrng({ evOnClick, title, modal }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`btn-outline-orange default-none ${!modal ? 'button-web' : 'button-modal'}`}
        >
            {title}
        </button>
    );
}

export function ButtonSolidPink({ evOnClick, title, modal }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`${
                !modal ? 'button-web' : 'button-modal'
            } btn-solid-pink default-none   w-100`}
        >
            {title}
        </button>
    );
}

export function ButtonOutlinePink({ evOnClick, title, modal }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`${!modal ? 'button-web' : 'button-modal'} btn-outline-pink default-none`}
        >
            {title}
        </button>
    );
}

export function ButtonSolidGray({ evOnClick, title, modal }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`btn-solid-gray default-none   w-100 ${
                !modal ? 'button-web' : 'button-web'
            }`}
        >
            {title}
        </button>
    );
}

export function ButtonSolidSky({ evOnClick, title, modal }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`btn-solid-sky default-none   w-100 ${!modal ? 'button-web' : 'button-web'}`}
        >
            {title}
        </button>
    );
}

export function ButtonExport({ evOnClick, title }) {
    return (
        <LayoutSelector
            sm
            md
            mobileComp={
                <span role="button" tabIndex={-1} onClick={evOnClick}>
                    <img
                        className="cursor-pointer d-block"
                        src={icons.iconExportBulk}
                        alt="icon export bulk"
                    />
                </span>
            }
            webComp={
                <button onClick={evOnClick} type="button" className="btn-export default-none w-100">
                    {title || 'Export'}
                </button>
            }
        />
    );
}
export function ButtonFilter({ evOnClick, title }) {
    return (
        <LayoutSelector
            sm
            md
            mobileComp={
                <span role="button" tabIndex={-1} onClick={evOnClick}>
                    <img
                        className="cursor-pointer d-block"
                        src={icons.iconFilter}
                        alt="icon export bulk"
                    />
                </span>
            }
            webComp={
                <button onClick={evOnClick} type="button" className="btn-filter default-none w-100">
                    {title || 'Filter'}
                </button>
            }
        />
    );
}

function Buttons() {
    return null;
}

export default Buttons;
