/* eslint-disable jsx-a11y/label-has-associated-control */
import { ButtonClose, ButtonGr, ButtonOutlinePink } from '../../../../configs/Buttons';

const ModalHeader = ({ setModalType }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title">Contribute Location</div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={() => setModalType(null)} />
            </div>
        </div>
    </div>
);

function ModalBody() {
    return (
        <div className="modal-body-area ">
            <div className="w-100">
                <div className="line line-g2 mb-4">
                    <div className="cell cell-12 mb-1">
                        <label className="label" htmlFor="address">
                            Full Address
                        </label>
                        <input
                            // ref={nameRef}
                            name="address"
                            placeholder="Enter Full Address"
                            type="text"
                            className="form-input w-100"
                        />
                    </div>
                    <div className="cell cell-6">
                        <label className="label" htmlFor="city">
                            City
                        </label>
                        <input
                            name="city"
                            placeholder="Enter City"
                            type="text"
                            className="form-input w-100"
                        />
                    </div>
                    <div className="cell cell-6">
                        <label className="label" htmlFor="zip">
                            Zip Code
                        </label>
                        <input
                            name="zip"
                            placeholder="Enter Zip Code"
                            type="text"
                            className="form-input w-100"
                        />
                    </div>
                </div>
                <div className="location-map-area mb-4">
                    <iframe
                        title="location"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14702.187595216064!2d89.50256299999998!3d22.89319045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1667624669694!5m2!1sen!2sbd"
                        width="600"
                        height="450"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        className="w-100 h-100"
                        scrolling="auto"
                    />
                </div>
                <div className="w-100 text-right">
                    <span>
                        <ButtonOutlinePink evOnClick={null} title="Cancel" modal />
                    </span>
                    <span className="ml-2">
                        <ButtonGr evOnClick={null} title="Contribute" modal />
                    </span>
                </div>
            </div>
        </div>
    );
}

function ContributeLocationModal({ setModalType, show }) {
    if (!show) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content add-location-area">
                <ModalHeader setModalType={setModalType} />
                <ModalBody />
            </div>
        </div>
    );
}

export default ContributeLocationModal;
