import { useNavigate } from 'react-router-dom';
import BillingsListActionButton from './UsersListActionButton';

function ContentTop({ data }) {
    return (
        <div className="line-row-between mb-2">
            <div className="line">
                <img className="d-block" src={data?.image} alt="user dp" />
                <div className="ml-1">
                    <span className="title primary">{data?.name}</span>
                    <span className="sub-title purple d-block">{data?.email}</span>
                </div>
            </div>
            <div>
                <BillingsListActionButton />
            </div>
        </div>
    );
}

function CommonRowArea({ title, value }) {
    return (
        <div className="line-row-between mb-1">
            <div className="cell">
                <span className="value primary-70">{title || '--'}:</span>
            </div>
            <div className="cell">
                <span className="value">{value || '--'}</span>
            </div>
        </div>
    );
}

function UsersListMobile({ arr }) {
    const navigate = useNavigate();
    if (!arr?.length) {
        return (
            <div className="list-item-sm">
                <span className="title primary">No Users Found !</span>
            </div>
        );
    }
    return arr?.map((data) => (
        <div
            key={data?.uId}
            role="button"
            tabIndex={-1}
            onClick={() => navigate(`/smanager/users/${data?.uId}`)}
            className={`list-item-sm ${data?.demo ? 'placeholder-mode' : ''}`}
        >
            <ContentTop data={data} />
            <CommonRowArea
                title="Last Login"
                value={
                    <div className="text-right">
                        {' '}
                        <span>{data?.loginDate}</span> <br />
                        <span className="sub-title primary-70">{data?.loginTime}</span>
                    </div>
                }
            />
            <CommonRowArea title="Phone Number" value={data.phone} />
        </div>
    ));
}

export default UsersListMobile;
