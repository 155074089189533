import React, { useEffect, useRef } from 'react';

function MobileFooterModal({ children, show, setShow }) {
    const ref = useRef();

    useEffect(() => {
        function handelClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                // alert("You clicked outside of me!");
                setShow(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handelClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handelClickOutside);
        };
    }, [ref, setShow]);

    if (!show) {
        return null;
    }

    return (
        <div className="footer-modal overflow-auto">
            <div className="footer-options overflow-auto" ref={ref}>
                {children}
            </div>
        </div>
    );
}

export default MobileFooterModal;
