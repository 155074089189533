import { Link, useParams } from 'react-router-dom';
// import DomainCnsModal from './DomainCnsModal';

function DomainPcns({ title, description, img }) {
    // const [modal, setModal] = useState(false);
    const { contractId } = useParams();
    return (
        <>
            <div className="cell cell-4 cell-md-6 cell-sm-12 mb-sm-3">
                <Link
                    to={`/user/services/domain/${contractId}/child-nameserver/`}
                    className="line line-no-wrap line-g1 mb-2"
                >
                    <div className="cell">
                        <img className="d-block" src={img} alt="" />
                    </div>
                    <div className="cell overflow-dotted">
                        <span className="fs-14 fw-500 no-wrap">{title}</span>
                        <span className="value">{description}</span>
                    </div>
                </Link>
            </div>
            {/* <DomainCnsModal show={modal} setModal={setModal} /> */}
        </>
    );
}

export default DomainPcns;
