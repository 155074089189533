import { useRef, useState } from 'react';
import { ButtonActionDotMenu } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';

import ContactInfoIconHandler from '../_models/ContactInfoIconHandler';
import ContactInfoPhoneTableMenu from './ContactInfoPhoneTableMenu';

function TableActionBtn({ showOptions, setShowOptions, myRef, data }) {
    return (
        <div className="position-relative">
            <div className="line-align justify-end ">
                <div className="btn-action">
                    <ButtonActionDotMenu
                        evOnClick={() => {
                            setShowOptions(data?.id);
                        }}
                    />
                </div>
            </div>
            <div className="">
                <ContactInfoPhoneTableMenu
                    myRef={myRef}
                    showOptions={data?.id === showOptions}
                    setShowOptions={setShowOptions}
                    data={data}
                    type="phone"
                />
            </div>
        </div>
    );
}

function Phones({ data }) {
    return (
        <div className="line-align line-g1 line-no-wrap">
            <div>
                <ContactInfoIconHandler value={data.providerId} mobile />
            </div>
            <span className="title cell">{data.phoneNumber}</span>
            {data?.isDefault ? (
                <span className="ml-2">
                    <img className="d-block" src={icons.iconP} alt="primary icon" />
                </span>
            ) : null}
        </div>
    );
}

function ContactInfoPhoneTableMobile({ arr }) {
    const [showOptions, setShowOptions] = useState(false);
    const myRef = useRef();

    if (!arr?.length) {
        return (
            <div className="list-item-sm">
                <span className="title primary-70-i">No Phone Numbers Found !</span>
            </div>
        );
    }
    return arr?.map((data) => (
        <div key={data?.id} className={`list-item-sm ${data?.demo ? 'placeholder-mode' : ''}`}>
            <div className="line-row-between line-no-wrap">
                <Phones data={data} />
                <TableActionBtn
                    showOptions={showOptions}
                    setShowOptions={setShowOptions}
                    myRef={myRef}
                    data={data}
                />
            </div>
        </div>
    ));
}

export default ContactInfoPhoneTableMobile;
