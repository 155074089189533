import { useState } from 'react';
import { ButtonPurple } from '../../../../configs/Buttons';
import { editIcons, icons } from '../../../../configs/Icons';
import { DashboardArrowHeader } from '../../headers/DashboardHeaderSection';

function TransferOutProcessItem() {
    return (
        <div className="line-align line-g2 overflow-auto arrow-menu">
            <div className="cell">
                <span className="title mb-4 active">Verify Email</span>
            </div>
            <div className="cell">
                <span className="title mb-4">Security Authenication</span>
            </div>
            <div className="cell">
                <span className="title mb-4 active">Send Code</span>
            </div>
            <div className="cell">
                <span className="title mb-4">Transfaring</span>
            </div>
            <div className="cell">
                <span className="title mb-4">Result</span>
            </div>
        </div>
    );
}

function Email({ isEdit }) {
    if (isEdit) {
        return (
            <div className="">
                <input
                    className="form-input"
                    placeholder="type new email.."
                    defaultValue="yourname@gmail.com"
                    type="text"
                />
            </div>
        );
    }
    return <span className="font-14 w-100">yourname@gmail.com</span>;
}

function EmailView({ isEdit, setIsEdit }) {
    return (
        <div className="line-row-between line-g1 line-no-wrap line-sm-wrap email-view-area">
            <div className="cell">
                <span className="font-16">Registant Email:</span>
            </div>
            <div className="cell cell-fill">
                <Email isEdit={isEdit} />
            </div>
            <div className="cell">
                <div
                    onClick={() => setIsEdit(!isEdit)}
                    role="button"
                    tabIndex={-1}
                    className="icon"
                >
                    <img
                        className="d-block w-100"
                        src={isEdit ? icons.iconDel1 : editIcons.iconEditV1}
                        alt="icon"
                    />
                </div>
            </div>
        </div>
    );
}

function DomainTransferBody({ setIsEdit, isEdit }) {
    return (
        <div className="text-center body">
            <p className="font-16 opacity-medium mb-3 mb-sm-3">
                Please check that your email address is correct, as we will send{' '}
                <span className="d-md-none">
                    <br />
                </span>{' '}
                the transfercode there
            </p>
            <hr className="mb-sm-3 d-none d-md-block" />
            <EmailView setIsEdit={setIsEdit} isEdit={isEdit} />
            <div className="mt-4">
                <ButtonPurple title="Proceed" />
            </div>
        </div>
    );
}

function ContentBody() {
    const [isEdit, setIsEdit] = useState(false);
    return (
        <div className="product-info domain-transfer-container h-100 border-none-tp p-l-r">
            <TransferOutProcessItem />
            <DomainTransferBody setIsEdit={setIsEdit} isEdit={isEdit} />
        </div>
    );
}

function DomainTransferPage() {
    return (
        <div className="cell cell-content h-100">
            <div className="content-section">
                <div className="content-area">
                    <DashboardArrowHeader title="Transfer Out (--------)" path={-1} />
                    <div className="content-body-scroll">
                        <ContentBody />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DomainTransferPage;
