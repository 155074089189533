import TabAAAAContentArea from './TabAAAAContentArea';
import TabAContent from './TabAContent';
import TabCaaContent from './TabCaaContent';
import TabCnameContent from './TabCnameContent';
import TabMxContent from './TabMxContent';
import TabNsContent from './TabNsContent';
import TabSrvContent from './TabSrvContent';
import TabTxtContent from './TabTxtContent';

function TabContentController({ tabItem }) {
    if (tabItem === 'A') {
        return <TabAContent show={tabItem === 'A'} />;
    }
    if (tabItem === 'AAAA') {
        return <TabAAAAContentArea show={tabItem === 'AAAA'} />;
    }
    if (tabItem === 'CNAME') {
        return <TabCnameContent show={tabItem === 'CNAME'} />;
    }
    if (tabItem === 'MX') {
        return <TabMxContent show={tabItem === 'MX'} />;
    }
    if (tabItem === 'NS') {
        return <TabNsContent show={tabItem === 'NS'} />;
    }
    if (tabItem === 'SRV') {
        return <TabSrvContent show={tabItem === 'SRV'} />;
    }
    if (tabItem === 'TXT') {
        return <TabTxtContent show={tabItem === 'TXT'} />;
    }
    if (tabItem === 'CAA') {
        return <TabCaaContent show={tabItem === 'CAA'} />;
    }
    return <div />;
}

export default TabContentController;
