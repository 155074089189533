import React from 'react';
import HeaderSection from '../../headers/HeaderSection';
import RoleInvitationFooter from '../../manage/role/role_invitation/RoleInvitationFooter';
import MobileFooter from '../../users/components/MobileFooter';
import FixedHeaderControl from '../components/FixedHeaderControl';
import LinkBrokenPage from './LinkBrokenPage';

function NotFoundPage() {
    return (
        <>
            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>
            <LinkBrokenPage />
            <RoleInvitationFooter />
            <MobileFooter />
        </>
    );
}

export default NotFoundPage;
