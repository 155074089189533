import { useEffect } from 'react';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';

const getDomainData = ({ searchText }) =>
    new Promise((resolve, reject) => {
        AxiosAuth.setUrl(ConfigApi.API_DOMAIN_DATA_INFO, {
            params: { url: searchText }
        })
            .get()
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function SearchDomainWebNoInfo({ webInfo, setWebInfo }) {
    // const navigate = useNavigate();

    useEffect(() => {
        getDomainData({ searchText: webInfo.domain })
            .then((response) => {
                setWebInfo((cuData) => {
                    const newData = [...cuData];
                    newData[0] = response.data.result;
                    return newData;
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [setWebInfo, webInfo.domain]);

    return (
        <div className="hero-bg">
            <div className="container" style={{ marginBottom: '60px' }}>
                <div className="container placeholder-container" style={{ marginBottom: '120px' }}>
                    <div
                        className="domain-found-container line-align"
                        style={{ padding: '26px 20px 26px 26px' }}
                    >
                        <div className="domain-name line">
                            <div
                                className="domain-icon mr-4 placeholder-animation"
                                style={{ borderRadius: '15px' }}
                            >
                                <span />
                            </div>
                            <div className="mt-2">
                                <p className="placeholder-animation">
                                    <span>Congratulation, Your domain is Available!</span>
                                </p>
                                <h5 className="placeholder-animation" style={{ width: '70%' }}>
                                    <span>www.domains.com</span>
                                </h5>
                            </div>
                        </div>
                        <div className="pricing line-align">
                            <div className="price">
                                <p
                                    className="placeholder-animation"
                                    style={{
                                        width: '70%',
                                        marginLeft: 'auto'
                                    }}
                                >
                                    <span>$9.18/yr</span>
                                </p>
                                <p className="placeholder-animation">
                                    <span>Renew $19.99/yr</span>
                                </p>
                            </div>
                            <div className="cart">
                                <button type="button" className="line-align placeholder-btn">
                                    <span>
                                        <div className="ml-6">
                                            <span>Add to Cart</span>
                                        </div>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchDomainWebNoInfo;
