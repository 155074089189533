import { useEffect } from 'react';
import PageProperties from '../../../../utils/PageProperties';
import HeaderSection from '../../../headers/HeaderSection';
import MobileFooter from '../../../users/components/MobileFooter';
import FixedHeaderControl from '../../../websites/components/FixedHeaderControl';

function CloudVmPage() {
    useEffect(() => {
        PageProperties('Cloud VM \u2022 Server');
    }, []);

    return (
        <>
            <FixedHeaderControl>
                <HeaderSection searchBar />
            </FixedHeaderControl>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '75%'
                }}
            >
                <h2 className="text-center">Comming Soon....</h2>
            </div>
            <MobileFooter />
        </>
    );
}

export default CloudVmPage;
