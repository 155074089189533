import EmailListActionButton from './EmailListActionButton';

function TableBody({ arr }) {
    if (!arr) {
        <tbody>
            <tr>
                <td colSpan={100}>No Hosting Found!</td>
            </tr>
        </tbody>;
    }
    return (
        <tbody>
            {arr.map((data) => (
                <tr key={data?.uId} className={data?.demo ? 'placeholder-mode' : ''}>
                    <td>
                        <span>{data?.uId}</span>
                    </td>
                    <td className="user-avatar">
                        <span className="d-block">
                            <img className="d-block" src={data?.image} alt="" />
                        </span>
                    </td>
                    <td>
                        <span>{data?.name}</span> <br />
                        <span className="purple font-12">{data?.email}</span>
                    </td>
                    <td>
                        <span>{data?.quantity}</span> <br />{' '}
                        <span className="font-12">{data?.type}</span>
                    </td>
                    <td>
                        <span>{data?.issueDate}</span>
                    </td>
                    <td>
                        <span>${data?.price}</span>
                    </td>
                    <td>
                        <EmailListActionButton />
                    </td>
                </tr>
            ))}
        </tbody>
    );
}

function EmailListWeb({ arr }) {
    return (
        <table className="table-list cell-sm-12" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th>User ID</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Types</th>
                    <th>Issue Date</th>
                    <th>Price</th>
                    <th>Action</th>
                </tr>
            </thead>
            <TableBody arr={arr} />
        </table>
    );
}

export default EmailListWeb;
