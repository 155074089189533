import React, { useState } from 'react';
import { ButtonGr } from '../../../../configs/Buttons';
import { icons, iconsModal } from '../../../../configs/Icons';
import { useTemplate } from '../../../contexts/TemplateProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import OtpInputs from '../../../utils/inputs/OtpInputs';

const ModalHeader = ({ setExitModal, modalProperty }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title">Link with your account</div>
            <div className="modal-box-close">
                <button
                    className="button squire"
                    type="button"
                    onClick={
                        typeof modalProperty.onExit === 'function'
                            ? modalProperty.onExit
                            : setExitModal
                    }
                >
                    <img src={iconsModal.iconCloseModal} alt="Close" />
                </button>
            </div>
        </div>
    </div>
);

const ModalBody0 = ({ name, email, photoURL, providerId, handelNoClick, handelOtpSend }) => (
    <div className="modal-box-body">
        <div>
            <div className="create-an-account">
                <div>
                    {photoURL ? (
                        <img
                            src={photoURL}
                            alt=""
                            style={{
                                border: '0px solid',
                                borderRadius: '50%',
                                boxShadow: '0 0 11px -3px #000000'
                            }}
                        />
                    ) : (
                        <img src={icons.iconInfo} alt="" />
                    )}
                </div>
                <div>
                    Hello <b>{name}</b>, your email <b>{email}</b> is already registered with
                    BIKIRAN. Would you link your <b>{providerId}</b> account?
                </div>

                <div className="text-center">
                    <button type="button" onClick={handelNoClick} className="button">
                        No
                    </button>
                    <button
                        type="button"
                        onClick={handelOtpSend}
                        className="button button-gr-green"
                    >
                        Yes
                    </button>
                </div>
            </div>
        </div>
    </div>
);

const ModalBody1 = ({ formData, setFormData, providerId, handelOtpSend, handelLinkAccount }) => (
    <div className="modal-box-body">
        <div>
            <div className="login-modal-area">
                <div className="mb-2">
                    To link your <b>{providerId}</b> account please verify the email (
                    {formData.username}) by OTP.
                </div>

                <div className="mb-2">
                    <OtpInputs
                        formData={formData}
                        setFormData={setFormData}
                        handelOtpSend={handelOtpSend}
                        key={formData.otpKey}
                    />
                </div>

                <div className="text-center mb-6 full-width">
                    {!formData.sentId ? (
                        <ButtonGr evOnClick={handelOtpSend} title="Send OTP" modal />
                    ) : (
                        <ButtonGr
                            evOnClick={handelLinkAccount}
                            disabled={formData.otp.length !== 6}
                            title="Verify & Link Account"
                            modal
                        />
                    )}
                </div>
            </div>
        </div>
    </div>
);

function LoginLinkAccount({
    show,
    makeAction,
    setExitModal,
    signOut,
    firebase,
    recheckLogin,
    modalProperty
}) {
    const [formData, setFormData] = useState({
        type: 'email',
        username: firebase?.email || '',
        sentId: '',
        otp: '',
        otpKey: 0,
        userUid: firebase?.userUid || '',
        step: 0
    });
    const { setMessage } = useTemplate();

    const handelNoClick = () => {
        signOut().then(() => {
            makeAction('');
        });
    };

    const handelOtpSend = () =>
        new Promise((resolve, reject) => {
            setMessage('Sending OTP ...');
            AxiosAuth.setApiUrl('AUTH', '/link-account/send-otp/')
                .post(formData)
                .then(({ data }) => {
                    if (data.error === 0) {
                        setFormData((cVal) => ({
                            ...cVal,
                            otp: '',
                            sentId: data.sentId,
                            step: 1
                        }));
                    }

                    // --Notification
                    setMessage(data?.message);

                    // --Return
                    resolve({
                        error: data?.error || 1,
                        message: data?.message || 'Technical Error'
                    });
                })
                .catch((err) => {
                    setMessage(err);
                    reject(err);
                });
        });

    const handelLinkAccount = () =>
        new Promise((resolve, reject) => {
            setMessage('Verifying OTP...');
            AxiosAuth.setApiUrl('AUTH', '/link-account/verify/')
                .post(formData)
                .then(({ data }) => {
                    if (data.error === 0) {
                        recheckLogin();
                        makeAction(null);
                    } else {
                        setFormData((cVal) => ({
                            ...cVal,
                            otp: '',
                            otpKey: Math.random()
                        }));
                    }

                    // --Notification
                    setMessage(data.message);

                    // --Return
                    resolve({
                        error: data?.error || 1,
                        message: data?.message || 'Technical Error'
                    });
                })
                .catch((err) => {
                    setMessage(err);
                    reject(err);
                });
        });

    if (!show) {
        return null;
    }

    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <ModalHeader setExitModal={setExitModal} modalProperty={modalProperty} />

                {formData.step === 0 ? (
                    <ModalBody0
                        name={firebase?.displayName || '---'}
                        email={firebase?.email || '---'}
                        photoURL={firebase?.photoURL || '---'}
                        providerId={firebase?.providerId || '---'}
                        handelNoClick={handelNoClick}
                        handelOtpSend={handelOtpSend}
                    />
                ) : null}

                {formData.step === 1 ? (
                    <ModalBody1
                        formData={formData}
                        setFormData={setFormData}
                        providerId={firebase?.providerId || null}
                        handelOtpSend={handelOtpSend}
                        handelLinkAccount={handelLinkAccount}
                    />
                ) : null}
            </div>
        </div>
    );
}

export default LoginLinkAccount;
