import { Link, useNavigate } from 'react-router-dom';
import { ButtonGr, ButtonOutlineRed } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';

function WarningText({ error }) {
    return (
        <>
            <div className="line-row-center align-unset mb-4">
                <img className="d-block" src={icons.iconWarningLine} alt="" />
            </div>
            <div className="clr">
                <span className="font-16 red fw-500">
                    {!error ? 'Network Error' : error?.message}
                </span>
            </div>
        </>
    );
}

function ModalBody({ error }) {
    const navigate = useNavigate();

    return (
        <div className="modal-body-area text-center mb-2 mt-2">
            <WarningText error={error} />
            <div className="line-row-center mt-4">
                <ButtonOutlineRed evOnClick={() => navigate(-1)} title="Back" modal />

                <Link to="/smanager/ticket/" className="ml-2">
                    <ButtonGr title="Contact Support" modal />
                </Link>
            </div>
        </div>
    );
}

function HostingInfoErrorPopup({ error }) {
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <ModalBody error={error} />
            </div>
        </div>
    );
}

export default HostingInfoErrorPopup;
