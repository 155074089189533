function TabsDotMenu({ disabled, show, evOnClick, mode, dotMenu }) {
    if (!show) {
        return null;
    }
    return (
        <div
            className={`cell cell-fill ${disabled ? 'disabled-view' : ''}`}
            onClick={evOnClick}
            role="button"
            tabIndex={-1}
        >
            <div
                // onClick={handelDotMenu}
                // role="button"
                tabIndex={-1}
                className={`tab-dot-menu ${mode || dotMenu ? 'active' : ''}`}
            />
        </div>
    );
}

export default TabsDotMenu;
