import { ButtonClose } from '../../../../configs/Buttons';

const ModalHeader = ({ setModal }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title" />
            <div className="modal-box-close">
                <ButtonClose evOnClick={() => setModal(null)} />
            </div>
        </div>
    </div>
);

function ModalBody({ text }) {
    return (
        <div className="container h-100 text-center">
            <div style={{ padding: '16px 20px' }}>
                <h2 className="font-18">{text || 'Operation will available soon...'}</h2>
            </div>
        </div>
    );
}

function MessageModal({ show, setModal, text }) {
    if (!show) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <ModalHeader setModal={setModal} />

                <ModalBody text={text} />
            </div>
        </div>
    );
}

export default MessageModal;
