import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { paymentMethodIcons } from '../../configs/Icons';
import PaymentAddressModifyModal from '../app/payment/modals/PaymentAddressModifyModal';
import PaymentDomainProfileModal from '../app/payment/modals/PaymentDomainProfileModal';

import Bkash from '../gateways/Bkash';
import Sslcom from '../gateways/Sslcom';
import AxiosAuth from '../utils/AxiosAuth';

const PaymentContext = createContext();

export function usePayment() {
    return useContext(PaymentContext);
}

const icons = {
    bkash: paymentMethodIcons.iconBkash,
    nagad: paymentMethodIcons.iconNagad,
    sslcom: paymentMethodIcons.iconBankCards,
    paypal: paymentMethodIcons.iconPaypal,
    googlepay: paymentMethodIcons.iconGooglePay
};

function isAddress(address) {
    if (!address) {
        return false;
    }
    return (
        [address?.name, address?.line1, address?.mobile, address?.email].filter((item) => !!item)
            .length === 4
    );
}

function isHavingDomain(items) {
    for (let i = 0; i < items.length; i += 1) {
        if (items[i].type === 'domain') {
            return true;
        }
    }

    return false;
}

const defModalProperty = {
    type: null,
    data: null
};

function PaymentProvider({ children, invoiceId, currentUser }) {
    const [invoiceData, setInvoiceData] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [modalProperty, setModalProperty] = useState(defModalProperty);
    const [menuState, setMenuState] = useState({ balance: false, points: false });

    // --References
    const bKashRef = useRef(null);
    const sslcomRef = useRef(null);

    // --Update Operation
    const closeEditors = () => {
        setModalProperty(defModalProperty);
    };

    const openAddressEditor = () => {
        setModalProperty((cuData) => ({
            ...cuData,
            type: 'address-modify',
            data: invoiceData?.addressBilling || null
        }));
    };

    const setAddress = (addressData) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CART', `/invoice/${invoiceData?.id}/update-billing-address/`)
                .post(addressData)
                .then(({ data }) => {
                    if (data.error === 0) {
                        closeEditors();
                    }
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const openDomainProfileEditor = () => {
        setModalProperty((cuData) => ({ ...cuData, type: 'domain-profile' }));
    };

    const handelDomainProfileSave = (dProfileData) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CART', `/invoice/${invoiceData?.id}/update-domain-profile/`)
                .post(dProfileData)
                .then(({ data }) => {
                    if (data.error === 0) {
                        closeEditors();
                    }
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    // const handelPaymentProcessing = (dProfileData) =>
    //     new Promise((resolve, reject) => {
    //         AxiosAuth.currentUserAuth(currentUser)
    //             .setApiUrl('CART', `/invoice/${invoiceData?.id}/update-domain-profile/`)
    //             .post(dProfileData)
    //             .then(({ data }) => {
    //                 if (data.error === 0) {
    //                     closeEditors();
    //                 }
    //                 resolve(data);
    //             })
    //             .catch((err) => {
    //                 reject(err);
    //             });
    //     });

    // --Submit Operation
    const handelPayBtnClick = () => {
        if (selectedPayment?.provider === 'bkash') {
            bKashRef.current.click();
        }
        if (selectedPayment?.provider === 'sslcom') {
            sslcomRef.current.click();
        }
    };

    // --Load Invoice Information
    useEffect(() => {
        if (currentUser) {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CART', `/invoice/${invoiceId}/data/`)
                .post()
                .then(({ data }) => {
                    if (data.error === 0) {
                        setInvoiceData(data.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [currentUser, invoiceId, modalProperty]);

    // --Messages & Logics
    const toComplete = [];
    const isAddressPresent = isAddress(invoiceData?.addressBilling);
    // console.log(isAddressPresent);

    if (invoiceData?.invoiceInfo?.dueAmount <= 0) {
        toComplete.push({
            id: 1,
            message: `${`\u2022`} No Due Amount`,
            onClickAction: null
        });
    }

    if (!isAddressPresent) {
        toComplete.push({
            id: 2,
            message: `${`\u2022`} Please update address information`,
            onClickAction: openAddressEditor
        });
    }

    if (!isAddressPresent && isHavingDomain(invoiceData?.items || [])) {
        toComplete.push({
            id: 3,
            message: `${`\u2022`} Please update domain profile`,
            onClickAction: openDomainProfileEditor
        });
    }

    if (!selectedPayment) {
        toComplete.push({
            id: 4,
            message: `${`\u2022`} Please select payment method`,
            onClickAction: null
        });
    }

    const paymentOptions = (invoiceData?.paymentMethods || []).map((item) => {
        const out = { ...item };

        if (!out.icon) {
            out.icon = icons[out.provider];
        }

        return out;
    });

    const value = {
        invoiceData,
        paymentOptions,
        selectedPayment,
        toComplete,
        setSelectedPayment,
        handelPayBtnClick,
        openDomainProfileEditor,
        openAddressEditor,
        menuState,
        setMenuState
    };

    return (
        <PaymentContext.Provider value={value}>
            {children}

            {/* Initiate */}
            {selectedPayment?.provider === 'bkash' ? (
                <Bkash
                    ref={bKashRef}
                    order={invoiceData?.id || ''}
                    amount={invoiceData?.totalAmountOffer.toFixed(2) || 0}
                    currency="BDT"
                />
            ) : null}

            {selectedPayment?.provider === 'sslcom' ? (
                <Sslcom
                    ref={sslcomRef}
                    orderId={invoiceData?.id || ''}
                    currentUser={currentUser}
                    id={selectedPayment.id}
                />
            ) : null}

            {/* Modals */}
            <PaymentDomainProfileModal
                show={modalProperty.type === 'domain-profile'}
                handelDomainProfileSave={handelDomainProfileSave}
                closeEditors={closeEditors}
                modalProperty={modalProperty}
            />

            <PaymentAddressModifyModal
                show={modalProperty.type === 'address-modify'}
                setAddress={setAddress}
                closeEditors={closeEditors}
                modalProperty={modalProperty}
                country={invoiceData?.addressBilling?.country || '--'}
            />

            {/* <PaymentProcessing
                invoiceData={invoiceData}
                hash={hash}
                handelPaymentProcessing={handelPaymentProcessing}
            /> */}
        </PaymentContext.Provider>
    );
}

export default PaymentProvider;
