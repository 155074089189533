import { createContext, useContext, useEffect, useState } from 'react';

import AxiosAuth from '../../../utils/AxiosAuth';
import HostingInfoErrorPopup from '../hostings_Info/hostinginfo_popup/HostingInfoErrorPopup';
import BasicInfoDzoneUnSuspendPopup from './basicinfo_dzone_unsuspend/BasicInfoDzoneUnSuspendPopup';

const HostingInfoBasicContext = createContext();

export function useHostingInfoBasic() {
    return useContext(HostingInfoBasicContext);
}

function HostingInfoBasicProvider({ children, currentUser, contractId }) {
    const [formData, setFormData] = useState({});
    const [listKey, setListKey] = useState(null);
    const [basicInfo, setBasicInfo] = useState(null);
    const [error, setError] = useState(null);
    const [modal, setModal] = useState(false);

    const reloadList = () => {
        setListKey(Math.random());
    };

    const handelReloadButton = () => {
        setListKey(Math.random());
        setBasicInfo(null);
    };

    const openModal = (type) => {
        setModal(type);
    };

    const updateContractEmail = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT_HOSTING', '/cp/update-contract-email/')
                .post({ contractId, email: formData?.email || basicInfo?.email })
                .then(({ data }) => {
                    // console.log(data);
                    setFormData({});
                    reloadList();
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const updatePrimaryDomain = (domain, vSentId, vOTP) =>
        new Promise((resolve, reject) => {
            if (!vSentId || !vOTP) {
                resolve({
                    error: 1,
                    message: 'Verification Required',
                    action: 'verifyEmail'
                });
            } else {
                AxiosAuth.currentUserAuth(currentUser)
                    .setApiUrl('CONTRACT_HOSTING', '/cp/update-primary-domain/')
                    .post({
                        contractId,
                        domain,
                        vSentId,
                        vOTP
                    })
                    .then(({ data }) => {
                        setFormData({});
                        reloadList();
                        resolve({
                            error: data.error,
                            message: data.message,
                            action: data.action
                        });
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        });
    // new Promise((resolve, reject) => {
    //     AxiosAuth.currentUserAuth(currentUser)
    //         .setApiUrl('CONTRACT_HOSTING', '/cp/update-primary-domain/')
    //         .post({ contractId, domain: formData?.domain || basicInfo?.domain })
    //         .then(({ data }) => {
    //             // console.log(data);
    //             setFormData({});
    //             reloadList();

    //             resolve(data);
    //         })
    //         .catch((err) => {
    //             reject(err);
    //         });
    // });
    const updateSetPassword = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT_HOSTING', '/cp/update-password/')
                .post({ contractId, password: formData?.password })
                .then(({ data }) => {
                    // console.log(data);
                    setFormData({});
                    reloadList();

                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    const updateShellAccess = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT_HOSTING', '/cp/update-shell-access/')
                .post({ contractId, shell: basicInfo?.shellAccess ? 'off' : 'on' })
                .then(({ data }) => {
                    if (data?.error === 0) {
                        setFormData({});
                        reloadList();
                    }

                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const cpSuspend = (domain, reason, vSentId, vOTP) =>
        new Promise((resolve, reject) => {
            if (!vSentId || !vOTP) {
                resolve({
                    error: 1,
                    message: 'Verification Required',
                    action: 'verifyEmail'
                });
            } else {
                AxiosAuth.currentUserAuth(currentUser)
                    .setApiUrl('CONTRACT_HOSTING', 'cp/suspending')
                    .post({
                        contractId,
                        domain,
                        reason,
                        vSentId,
                        vOTP
                    })
                    .then(({ data }) => {
                        reloadList();
                        resolve({
                            error: data.error,
                            message: data.message,
                            action: data.action
                        });
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        });

    const cpUnSuspend = (domain, vSentId, vOTP) =>
        new Promise((resolve, reject) => {
            if (!vSentId || !vOTP) {
                resolve({
                    error: 1,
                    message: 'Verification Required',
                    action: 'verifyEmail'
                });
            } else {
                AxiosAuth.currentUserAuth(currentUser)
                    .setApiUrl('CONTRACT_HOSTING', 'cp/un-suspending')
                    .post({
                        contractId,
                        domain,
                        vSentId,
                        vOTP
                    })
                    .then(({ data }) => {
                        reloadList();
                        resolve({
                            error: data.error,
                            message: data.message,
                            action: data.action
                        });
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        });

    const cpRemove = (domain, vSentId, vOTP) =>
        new Promise((resolve, reject) => {
            if (!vSentId || !vOTP) {
                resolve({
                    error: 1,
                    message: 'Verification Required',
                    action: 'verifyEmail'
                });
            } else {
                AxiosAuth.currentUserAuth(currentUser)
                    .setApiUrl('CONTRACT_HOSTING', 'cp/remove')
                    .post({
                        contractId,
                        domain,
                        vSentId,
                        vOTP
                    })
                    .then(({ data }) => {
                        reloadList();
                        resolve({
                            error: data.error,
                            message: data.message,
                            action: data.action
                        });
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        });

    const cpContactDelete = (domain, vSentId, vOTP) =>
        new Promise((resolve, reject) => {
            if (!vSentId || !vOTP) {
                resolve({
                    error: 1,
                    message: 'Verification Required',
                    action: 'verifyEmail'
                });
            } else {
                AxiosAuth.currentUserAuth(currentUser)
                    .setApiUrl('CONTRACT_HOSTING', 'cp/delete')
                    .post({
                        contractId,
                        domain,
                        vSentId,
                        vOTP
                    })
                    .then(({ data }) => {
                        reloadList();
                        resolve({
                            error: data.error,
                            message: data.message,
                            action: data.action
                        });
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        });

    // get info
    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .setApiUrl('CONTRACT_HOSTING', `/${contractId}/info/`)
            .get({ listKey })
            .then(({ data }) => {
                setBasicInfo(null);
                if (data.error === 0) {
                    setBasicInfo(data.data);
                    // reloadList();
                    setError(data);
                } else {
                    setError(data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [contractId, currentUser, listKey]);

    const value = {
        openModal,
        reloadList,
        basicInfo,
        formData,
        setFormData,
        error,
        updateContractEmail,
        updatePrimaryDomain,
        updateSetPassword,
        updateShellAccess,
        handelReloadButton,
        cpSuspend,
        cpUnSuspend,
        cpRemove,
        cpContactDelete
    };

    return (
        <HostingInfoBasicContext.Provider value={value}>
            {children}
            {error?.error && error?.error !== 0 ? <HostingInfoErrorPopup error={error} /> : null}

            {modal === 'cpUnsuspend' && (
                <BasicInfoDzoneUnSuspendPopup
                    openModal={openModal}
                    data={basicInfo}
                    cpUnSuspend={cpUnSuspend}
                />
            )}
        </HostingInfoBasicContext.Provider>
    );
}

export default HostingInfoBasicProvider;
