import { ButtonClose, ButtonGr } from '../../../../configs/Buttons';

const ModalHeader = ({ setModalType }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title">Contribute Phone Number</div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={() => setModalType(null)} />
            </div>
        </div>
    </div>
);

function ModalBody() {
    return (
        <div className="modal-body-area">
            <div className="w-100">
                <input
                    type="text"
                    placeholder="Type Phone Number"
                    className="form-input w-100 mb-4"
                />{' '}
                <br />
                <div className="w-100 full-width">
                    <ButtonGr evOnClick={null} title="Add Phone" modal />
                </div>
            </div>
        </div>
    );
}

function ContributePhoneModal({ setModalType, show }) {
    if (!show) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content add-info-area">
                <ModalHeader setModalType={setModalType} />

                <ModalBody />
            </div>
        </div>
    );
}

export default ContributePhoneModal;
