import React, { useEffect, useRef, useState } from 'react';
import { domainIcons, icons } from '../../../../configs/Icons';
import SectionDivider from '../_models/section_divider/SectionDivider';
import FaqList from './faq_list/FaqList';

function Options({ show, setShow, myRef }) {
    useEffect(() => {
        function handelClickOutside(event) {
            if (myRef?.current && !myRef?.current.contains(event?.target)) {
                // alert("You clicked outside of me!");
                setShow(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handelClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handelClickOutside);
        };
    }, [myRef, setShow]);

    if (!show) {
        return null;
    }
    return (
        <div className="inst-option-pop">
            <img src={icons.iconShape} alt="shape" />
            <div className="inst-option">
                <div className="clickable">
                    <div className="line-row-between">
                        <div className="menu-logo">
                            <img className="d-block w-100" src={icons.imgDatabase} alt="" />
                        </div>
                        <span className="ml-2">Databases</span>
                    </div>
                </div>
                <div className="clickable">
                    <div className="line-row-between">
                        <div className="menu-logo">
                            <img className="d-block w-100" src={domainIcons.iconDomainV3} alt="" />
                        </div>
                        <span className="ml-2">Domain</span>
                    </div>
                </div>
                <div className="clickable">
                    <div className="line-row-between">
                        <div className="menu-logo">
                            <img className="d-block w-100" src={icons.iconMailV1} alt="" />
                        </div>
                        <span className="ml-2">Email</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function MenuDropdown({ myRef }) {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className="position-relative" ref={myRef}>
            <div
                role="button"
                tabIndex={-1}
                className={`custom-dropdown-selected-item ${isActive ? 'active' : 'inactive'}`}
                onClick={() => setIsActive(!isActive)}
            >
                Select
                <span className={`custom-dropdown-arrow ${isActive ? 'up' : 'down'}`} />
            </div>
            <div className="option-popup">
                <Options show={isActive} setShow={setIsActive} myRef={myRef} />
            </div>
        </div>
    );
}

function FaqSection() {
    const ref = useRef();

    return (
        <div className="product-info mb-4">
            <div className="faq-list-section">
                <SectionDivider title="FAQ`s" action={<MenuDropdown myRef={ref} />} />
                <FaqList />
            </div>
        </div>
    );
}

export default FaqSection;
