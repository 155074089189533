import ActivityListSection from '../activity_list/ActivityListSection';

function ListHeader() {
    return (
        <div className="info-list-header ">
            <div className="line-row-between">
                <span className="fs-16 fw-500 no-wrap opacity-medium">Currently Logged In</span>
                <span role="button" tabIndex={-1} className="red fs-16 fw-500 no-wrap">
                    Revoke All
                </span>
            </div>
        </div>
    );
}

function ActivitySection() {
    return (
        <div>
            <ListHeader />
            <ActivityListSection />
        </div>
    );
}

export default ActivitySection;
