import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import PageProperties from '../../../utils/PageProperties';
import DomainInfoBasicProvider, { useDomainInfo } from '../domains_info/DomainInfoBasicProvider';
import DomainInfoHeader from '../domains_info_header/DomainInfoHeader';
import TabsRowSection from '../domains_info_tabs/TabsRowSection';
import DomainInfoNsListSection from './DomainInfoNsListSection';

function ContentHead() {
    const { domainInfo, handelReloadButton } = useDomainInfo();

    return (
        <DomainInfoHeader
            path="nameservers/"
            info={domainInfo}
            evReloadClick={handelReloadButton}
        />
    );
}

function DomainInfoNsPage() {
    const { contractId } = useParams();
    const { currentUser } = useAuth();

    useEffect(() => {
        PageProperties(`Name Servers \u2022 Domain`);
    }, []);

    return (
        <DomainInfoBasicProvider currentUser={currentUser} contractId={contractId}>
            <div className="cell cell-content h-100">
                <div className="content-section domain-info-page">
                    <div className="content-area">
                        <ContentHead />

                        <div className="content-body-scroll">
                            <TabsRowSection ns />
                            <DomainInfoNsListSection />
                        </div>
                    </div>
                </div>
            </div>
        </DomainInfoBasicProvider>
    );
}

export default DomainInfoNsPage;
