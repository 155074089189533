/* eslint-disable jsx-a11y/label-has-associated-control */
import { ButtonClose, ButtonGr } from '../../../../configs/Buttons';
import { useTemplate } from '../../../contexts/TemplateProvider';
import { useDomainInfo } from '../domains_info/DomainInfoBasicProvider';

const ModalHeader = ({ handleCloseClick, domain }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title">
                <span className="font-18">Child Name Servers</span>{' '}
                <span className="font-14 d-block opacity-medium">{domain}</span>
            </div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={handleCloseClick} />
            </div>
        </div>
    </div>
);

function IpInputView({ setInputVal, ip }) {
    const handelIpData = (ev) => {
        const { value } = ev.target;
        setInputVal((cuState) => ({ ...cuState, ip: value }));
    };

    return (
        <div className="w-100 mb-4">
            <div className="font-16 mb-1">IP Address:</div>
            <input
                className="form-input w-100"
                placeholder="Enter Ip Address..."
                onChange={handelIpData}
                type="text"
                defaultValue={ip}
            />
        </div>
    );
}

function HostnameInputView({ hostname, setInputVal, domain }) {
    const handelHostnameData = (ev) => {
        const { value } = ev?.target;

        let newVal = value?.replace(/[^a-zA-Z0-9.-]/g, '');
        newVal = newVal?.replace(/[.]+/gm, '.');
        newVal = newVal?.replace(/[-]+/gm, '-');

        setInputVal((cuState) => ({ ...cuState, hostname: newVal }));
    };

    const dot = hostname?.substring(hostname?.length - 1, hostname?.length) === '.';

    const handleFilter = (ev) => {
        const newVal = ev.target?.value
            ?.split('.')
            ?.filter((v) => !!v)
            ?.map((item) =>
                item
                    ?.split('-')
                    ?.filter((v) => !!v)
                    ?.join('-')
            )
            ?.join('.');
        setInputVal((cuState) => ({ ...cuState, hostname: newVal }));
    };

    return (
        <div className="input-back-text w-100 mb-2" style={{ whiteSpace: 'nowrap' }}>
            <div className="font-16 mb-1">Hostname:</div>
            {hostname ? (
                <span className="back-text">
                    <span style={{ opacity: '0' }}>{hostname}</span>
                    <span className="purple">{dot ? '' : '.'}</span>
                    <span className="purple">{domain}</span>
                </span>
            ) : null}

            <input
                className="form-input w-100"
                type="text"
                placeholder={`Ex: ns1.${domain}`}
                value={hostname}
                onChange={handelHostnameData}
                onBlur={handleFilter}
            />
        </div>
    );
}

function AddCnsView({ handelCnsAdd, domain }) {
    const { setInputVal, inputVal } = useDomainInfo();

    return (
        <div>
            <HostnameInputView
                setInputVal={setInputVal}
                hostname={inputVal?.hostname || ''}
                domain={domain || ''}
            />
            <IpInputView setInputVal={setInputVal} ip={inputVal?.ip} />
            <div className="line justify-end">
                <ButtonGr evOnClick={handelCnsAdd} title="Add" modal />
            </div>
        </div>
    );
}

function ModalBody({ handleCloseClick }) {
    const { domain, addChildNameServer, handelReloadButton } = useDomainInfo();

    const { setMessage } = useTemplate();
    const handelCnsAdd = () => {
        setMessage('Adding...');
        addChildNameServer()
            .then(({ error, message }) => {
                if (error === 0) {
                    handleCloseClick();
                    handelReloadButton();
                }
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err);
            });
    };
    return (
        <div className="modal-body-area">
            <AddCnsView handelCnsAdd={handelCnsAdd} domain={domain || '--'} />
        </div>
    );
}

function DomainInfoCnsModal({ show, setModal }) {
    const { domain, setInputVal } = useDomainInfo();
    const handleCloseClick = () => setModal(null) + setInputVal({ hostname: '', ip: '' });

    if (!show) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content" style={{ width: '400px' }}>
                <ModalHeader handleCloseClick={handleCloseClick} domain={domain || '--'} />

                <ModalBody handleCloseClick={handleCloseClick} />
            </div>
        </div>
    );
}

export default DomainInfoCnsModal;
