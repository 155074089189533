import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { searchBarIcons } from '../../../../configs/Icons';
import { useSearch } from '../../../contexts/SearchProvider';

function SuggestionItem({ item, addHistory, removeHistory }) {
    return (
        <div className="home-s-o-r">
            <Link
                to={item.url}
                onClick={() => {
                    addHistory(item);
                }}
            >
                <img src={item.icon} alt="Search" />
                <p className="cell-fill"> {item.text}</p>
            </Link>

            {item.delButton ? (
                <button type="button" onClick={() => removeHistory(item)}>
                    Delete
                </button>
            ) : null}
        </div>
    );
}

function SearchListSection({ isFocused, searchSuggestion, addHistory, removeHistory }) {
    if (!(isFocused && searchSuggestion.length > 0)) {
        return null;
    }

    return (
        <div className="home-search-option-area">
            <div className="home-search-option">
                <div className="home-search-option-result">
                    <div className="search-divider" />
                    <ul>
                        {searchSuggestion?.slice(0, 8)?.map((item) => (
                            <li key={item.id}>
                                <SuggestionItem
                                    item={item}
                                    addHistory={addHistory}
                                    removeHistory={removeHistory}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

function SearchInputSection({ exitBtn }) {
    // --Searching Parameter from URL
    const { search } = useLocation();
    const gSearchText = new URLSearchParams(search).get('q');

    // --Parameters from Context
    const {
        isFocused,
        setIsFocused,
        searchText,
        setSearchText,
        searchSuggestion,
        addHistory,
        removeHistory,
        handleSearch,
        setIsAppend
    } = useSearch();
    const navigate = useNavigate();

    const checkKey = (e) => {
        const ve = e || window.event;

        if (ve.keyCode === 38) {
            console.log(38);
        } else if (ve.keyCode === 40) {
            console.log(40);
        } else if (ve.keyCode === 37) {
            console.log(37);
        } else if (ve.keyCode === 39) {
            console.log(39);
        }
    };

    useEffect(() => {
        setSearchText(gSearchText || '');
    }, [gSearchText, setSearchText]);

    useEffect(() => {
        setIsAppend(true);

        return () => {
            setIsAppend(false);
            setIsFocused(false);
        };
    }, [setIsAppend, setIsFocused]);

    return (
        <div className="home-search-group-div">
            <div className="home-search-input-group">
                <form
                    onSubmit={(ev) => {
                        handleSearch(ev, navigate);
                    }}
                >
                    <input
                        type="text"
                        className=""
                        placeholder="Search Anything"
                        onFocus={() => {
                            setIsFocused(true);
                        }}
                        onBlur={() => {
                            setTimeout(() => {
                                setIsFocused(false);
                            }, 250);
                        }}
                        onChange={(ev) => {
                            setSearchText(ev.target.value);
                        }}
                        value={searchText}
                        onKeyDown={checkKey}
                    />
                </form>

                <button
                    type="button"
                    onClick={(ev) => {
                        handleSearch(ev, navigate);
                    }}
                >
                    <img src={searchBarIcons.iconSearchV1} alt="Search" />
                </button>

                {exitBtn}
            </div>

            <SearchListSection
                isFocused={isFocused}
                searchSuggestion={searchSuggestion}
                addHistory={addHistory}
                removeHistory={removeHistory}
            />
        </div>
    );
}

export default SearchInputSection;
