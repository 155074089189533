import React, { useEffect, useState } from 'react';

import { ButtonActionReload, ButtonOutlinePink } from '../../../../configs/Buttons';
import { useAuth } from '../../../contexts/AuthProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import { DashboardArrowHeader } from '../../headers/DashboardHeaderSection';
import MessageModal from '../../system/modals/MessageModal';
import HeaderMenuPopup from '../_models/header_menu_popup/HeaderMenuPopup';

function HeaderTitleArea({ info, path, evReloadClick }) {
    const [hostingData, setHostingData] = useState(null);
    const [showOption, setShowOption] = useState(false);

    const { currentUser } = useAuth();

    useEffect(() => {
        if (showOption) {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT_HOSTING', `/list/`)
                .get()
                .then(({ data }) => {
                    if (data.error === 0) {
                        setHostingData(data.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setHostingData(null);
        }
    }, [currentUser, showOption]);

    if (!info) {
        return (
            <div>
                <span className="title gap d-block overflow-dotted">-----------------</span>
                <span className="title gap d-block overflow-dotted">---------</span>
            </div>
        );
    }
    return (
        <HeaderMenuPopup
            info={info}
            data={hostingData}
            title="title"
            domain="domain"
            evOnClick={() => setShowOption(!showOption)}
            evReloadClick={evReloadClick}
            showOption={showOption}
            setShowOption={setShowOption}
            path={path}
        />
    );
}

function HeaderContent({ evReloadClick, modal, setModal }) {
    return (
        <div className="line justify-end">
            <span className="header-action-btn" style={{ width: '30px' }}>
                <ButtonActionReload evOnClick={evReloadClick} />
            </span>
            <span className="ml-2">
                <ButtonOutlinePink evOnClick={() => setModal(true)} title="Renew" />
                <MessageModal show={modal} setModal={setModal} />
            </span>
        </div>
    );
}

function HostingInfoHeader({ info, evReloadClick, path }) {
    const [modal, setModal] = useState(false);

    return (
        <>
            <DashboardArrowHeader
                title={info?.title}
                titleArea={
                    <HeaderTitleArea path={path} info={info} evReloadClick={evReloadClick} />
                }
                placeholder={!info}
                path="/user/services/hosting/"
                content={
                    <HeaderContent evReloadClick={evReloadClick} info={info} setModal={setModal} />
                }
                mobileContent={
                    <HeaderContent evReloadClick={evReloadClick} info={info} setModal={setModal} />
                }
            />
            <MessageModal show={modal} setModal={setModal} />
        </>
    );
}

export default HostingInfoHeader;
