import React from 'react';
import { userIcons } from '../../../../../../configs/Icons';
import LayoutSelector from '../../../../../utils/LayoutSelector';
import HostingListMobile from './HostingListMobile';
import HostingListWeb from './HostingListWeb';

const demoArr = [
    {
        uId: 10000000,
        image: userIcons.iconUserV3,
        name: '-----------------',
        email: '--------------',
        quantity: '---------------',
        type: '-----------',
        issueDate: '------------',
        price: '------',
        isDone: false,
        demo: true
    }
];

const contractInfo = [
    {
        uId: 10000000,
        image: userIcons.iconUserV3,
        name: 'Mariana D. Alonso',
        email: 'name@email.com',
        quantity: '2GB SSD Hosting',
        type: 'Web Hosting',
        issueDate: '30-July-2022',
        price: '149.50',
        isDone: false
    },
    {
        uId: 20000000,
        image: userIcons.iconUserV3,
        name: 'Chris Evans',
        email: 'name@email.com',
        quantity: '2GB SSD Hosting',
        type: 'Email Hosting',
        issueDate: '31-Oct-2023',
        price: '154.20',
        isDone: true
    }
];

function HostingListSection() {
    const arr = contractInfo || demoArr;

    return (
        <LayoutSelector
            sm
            md
            webComp={<HostingListWeb arr={arr} />}
            mobileComp={<HostingListMobile arr={arr} />}
        />
    );
}

export default HostingListSection;
