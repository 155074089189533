import { ButtonGr, ButtonOutlinePink } from '../../../../configs/Buttons';
import { icons } from '../../../../configs/Icons';

function WarningText({ message }) {
    return (
        <>
            <div className="line-row-center align-unset mb-4">
                <img className="d-block" src={icons.iconWarningLine} alt="" />
            </div>
            <div className="clr">
                <span className="font-16 red fw-500">{message || 'Error Occurred!'}</span>
            </div>
        </>
    );
}

function ActionButton({ setModal, singleBtn, leftBtn, RightBtn, evOnClick }) {
    if (singleBtn) {
        return (
            <div className="line-row-center">
                <span>
                    <ButtonGr evOnClick={evOnClick} title={singleBtn || 'Ok'} modal />
                </span>
            </div>
        );
    }
    return (
        <div className="line-align justify-end">
            <ButtonOutlinePink evOnClick={() => setModal(null)} title={leftBtn || 'Cancel'} modal />
            <span className="ml-2">
                <ButtonGr evOnClick={evOnClick} title={RightBtn || 'Delete'} modal />
            </span>
        </div>
    );
}

function ModalBody({ setModal, message, leftBtn, RightBtn, singleBtn, evOnClick }) {
    // const message = 'this is demo message';

    return (
        <div className="modal-body-area text-center mb-2 mt-2">
            <WarningText message={message} />
            <div className="mt-4">
                <ActionButton
                    setModal={setModal}
                    singleBtn={singleBtn}
                    leftBtn={leftBtn}
                    RightBtn={RightBtn}
                    evOnClick={evOnClick}
                />
            </div>
        </div>
    );
}

function WarningPopup({ show, setModal, message, leftBtn, RightBtn, singleBtn, evOnClick }) {
    if (!show) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <ModalBody
                    setModal={setModal}
                    message={message}
                    leftBtn={leftBtn}
                    RightBtn={RightBtn}
                    singleBtn={singleBtn}
                    evOnClick={evOnClick}
                />
            </div>
        </div>
    );
}

export default WarningPopup;
