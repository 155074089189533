import { Link } from 'react-router-dom';

function SectionTitleArea() {
    return (
        <div>
            <h3 className="section-title mb-1 mt-0">BIKIRAN - Terms of Service Agreement</h3>
            <div className="sub-title mt-1 mb-1">Updated On: 6 July 2022</div>
            <div className="sub-title mt-1 mb-4">
                To view our archived Terms of Service Agreement,{' '}
                <span className="purple">
                    <Link to="/" className="purple">
                        click here.
                    </Link>
                </span>
            </div>
        </div>
    );
}
function CommonSectionText({ sl, title }) {
    return (
        <h4 className="value-title fs-25 mb-12">
            <span style={{ color: 'rgba(19, 15, 64, 0.3)', marginRight: '5px' }}>{sl}.</span>
            {title}
        </h4>
    );
}

function CommonListArea({ info }) {
    return (
        <ul>
            {info.map((data) => (
                <li className="list-disc" key={data}>
                    <span>{data}</span>
                </li>
            ))}
        </ul>
    );
}

function CommonSectionSubText({ title }) {
    return <h4 className="value-title mb-2 ">{title}</h4>;
}

function InfoArea() {
    return (
        <div className="mb-5">
            <h4 className="value-title fs-16 mb-3">
                We know it’s tempting to skip these Terms of Service, but it’s important to
                establish what you can expect from us as you use BIKIRAN services, and what we
                expect from you.
            </h4>
            <p className="mt-0 mb-3">
                This Universal Terms of Service Agreement (`Agreement`) sets forth the terms and
                conditions of the use of our systems, software, platforms, APIs, and the use and/or
                purchase of our products and related services and for the purchase and/or use of any
                products and services acquired through BIKIRAN from our partners and/or affiliates
                (collectively `Services`).
            </p>
            <p className="mt-0 mb-3">
                In this Agreement `You` and `Your` refer to You as the user of our Services, or any
                agent, employee, servant or person authorized to act on Your behalf. `We`, `Us` and
                `Our` refer to BIKIRAN. This Agreement explains Our obligations to You, and explains
                Your obligations to Us for using Our Services. These obligations are in addition to
                (not in lieu of) any specific terms and conditions that apply to the particular
                Services.
            </p>
            <p className="mt-0 mb-0">
                When You use Our site, Your account (or You permit someone else to use it) to
                purchase or otherwise acquire access to Services or to cancel Your Services (even if
                We were not notified of such authorization), You signify Your agreement to the terms
                and conditions contained in this Agreement, along with the following policies and
                the applicable product agreements, which are incorporated by reference herein.
            </p>
        </div>
    );
}

function TermsModification() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="1" title="Term of Agreement; Modification:" />
            <p className="mt-0 mb-0">
                BIKIRAN may, in its sole and absolute discretion, change or modify this Agreement,
                and any policies or agreements which are incorporated herein, at any time, and such
                changes or modifications shall be effective immediately upon posting to this Site.
                Your use of this Site or the Services after such changes or modifications have been
                made shall constitute your acceptance of this Agreement as last revised. If you do
                not agree to be bound by this Agreement as last revised, do not use (or continue to
                use) this Site or the Services. In addition, BIKIRAN may occasionally notify you of
                changes or modifications to this Agreement by email. It is therefore very important
                that you keep your shopper account (“Account”) information current. BIKIRAN assumes
                no liability or responsibility for your failure to receive an email notification if
                such failure results from an inaccurate email address. In addition, BIKIRAN may
                terminate Your use of Services for any violation or breach of any of the terms of
                this Agreement by You. BIKIRAN RESERVES THE RIGHT TO MODIFY, CHANGE, OR DISCONTINUE
                ANY ASPECT OF THIS SITE OR THE SERVICES, INCLUDING WITHOUT LIMITATION PRICES AND
                FEES FOR THE SAME, AT ANY TIME.
            </p>
        </div>
    );
}
function Eligibility() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="2" title="Eligibility & Authority:" />
            <p className="mt-0 mb-3">
                Our site and Services are available only to users who can form legally binding
                contracts under applicable law. By using this site and/or the Services, you
                represent and warrant that you are at least eighteen (18) years of age and not a
                person barred from purchasing or receiving the Services found under the laws of the
                United States or other applicable jurisdiction.
            </p>
            <p className="mt-0 mb-0">
                If You are entering this Agreement on behalf of a corporate entity, you represent
                and warrant that You have the legal authority to bind such corporate entity to the
                terms and conditions contained in this Agreement (and those additional agreements
                and policies incorporated by reference), in which case “You”, “Your” will also refer
                to such corporate entity. If, after acceptance of this Agreement, we find that You
                do not have the legal authority to bind said corporate entity, you will be
                personally responsible for the obligations contained in this Agreement and any/all
                related agreements that You enter into based on the Services You use, including but
                not limited to the payment obligations. BIKIRAN shall not be liable for any loss or
                damage resulting from Our reliance on any instruction, notice, document, or
                communication reasonably believed by Us to be genuine and originating from an
                authorized representative of Your corporate entity. If there is reasonable doubt
                about the authenticity of any such instruction, notice, document, or communication,
                we reserve the right (but undertake no duty) to require additional authentication
                from You. You further agree to be bound by the terms of this Agreement, and any
                other agreement and/or policies required by the Services purchased, for transactions
                entered by You, anyone acting as Your agent, and anyone who uses Your account or the
                Services, whether or not authorized by You.
            </p>
        </div>
    );
}
function Account() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="3" title="Your Account:" />

            <p className="mt-0 mb-0">
                In order to access some of the features of this Site or use some of the Services,
                you will have to create an Account. You represent and warrant to BIKIRAN that all
                information you submit when you create your Account is accurate, current and
                complete, and that you will keep your Account information accurate, current and
                complete. If BIKIRAN has reason to believe that your Account information is untrue,
                inaccurate, out-of-date or incomplete, BIKIRAN reserves the right, in its sole and
                absolute discretion, to suspend or terminate your Account. You are solely
                responsible for the activity that occurs on your Account, whether authorized by you
                or not, and you must keep your Account information secure, including without
                limitation your customer number/login, password, Payment Method(s) (as defined
                below), and shopper PIN. For security purposes, BIKIRAN recommends that you change
                your password and shopper PIN at least once every six(6) months for each Account.
                You must notify BIKIRAN immediately of any breach of security or unauthorized use of
                your Account. BIKIRAN will not be liable for any loss you incur due to any
                unauthorized use of your Account. You, however, may be liable for any loss BIKIRAN
                or others incur caused by your Account, whether caused by you, or by an authorized
                person, or by an unauthorized person.
            </p>
        </div>
    );
}
function AccountData() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="4" title="Your Account; Transfer of Data Abroad:" />

            <CommonSectionSubText title="Your Account:" />
            <p className="mt-0 mb-3">
                In order to access some of the features of this Site or use some of the Services,
                you will have to create an Account. You represent and warrant to BIKIRAN that all
                information you submit when you create your Account is accurate, current and
                complete, and that you will keep your Account information accurate, current and
                complete. If BIKIRAN has reason to believe that your Account information is untrue,
                inaccurate, out-of-date or incomplete, BIKIRAN reserves the right, in its sole and
                absolute discretion, to suspend or terminate your Account. You are solely
                responsible for the activity that occurs on your Account, whether authorized by you
                or not, and you must keep your Account information secure, including without
                limitation your customer number/login, password, Payment Method(s) (as defined
                below), and shopper PIN. For security purposes, BIKIRAN recommends that you change
                your password and shopper PIN at least once every six(6) months for each Account.
                You must notify BIKIRAN immediately of any breach of security or unauthorized use of
                your Account. BIKIRAN will not be liable for any loss you incur due to any
                unauthorized use of your Account. You, however, may be liable for any loss BIKIRAN
                or others incur caused by your Account, whether caused by you, or by an authorized
                person, or by an unauthorized person.
            </p>
            <CommonSectionSubText title="Transfer of Data Abroad:" />
            <p className="mt-0 mb-0">
                If you are visiting this Site and/or using Our Services from a country other than
                the country in which Our servers are located, Your communications with Us and/or use
                of Our Services may result in the transfer of personal information (including Your
                Account information) across international boundaries. By visiting this Site, using
                Our Services, and communicating electronically with Us, You consent to such
                transfers.
            </p>
        </div>
    );
}

function RulesOfConduct() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="5" title="General Rules of Conduct:" />
            <CommonSectionSubText title="You acknowledge and agree that:" />
            <p className="mt-0 mb-3">
                Your use of this Site and the Services, including any content you submit, will
                comply with this Agreement, any applicable Services Agreement or policy that may
                apply to your Services and all applicable local, state, national and international
                laws, rules, and regulations.
            </p>
            <p className="mt-0 mb-3">
                You will not collect or harvest (or permit anyone else to collect or harvest) any
                User Content (as defined below) or any non-public or personally identifiable
                information about another User or any other person or entity without their express
                prior written consent.
            </p>
            <p className="mt-0 mb-3">
                You will not use this Site or the Services in a manner (as determined by BIKIRAN in
                its sole and absolute discretion) that: Is illegal or promotes or encourages illegal
                activity.
            </p>
            <p className="mt-0 mb-3">
                Promotes, encourages or engages in the exploitation of children, or any activity
                related to the proliferation of child sexual abuse material (CSAM); Promotes,
                encourages, or engages in terrorism, violence against people, animals, or property.
                Promotes, encourages, or engages in any spam or other unsolicited bulk email, or
                computer or network hacking or cracking.
            </p>
            <p className="mt-0 mb-3">
                Violates the Ryan Haight Online Pharmacy Consumer Protection Act of 2008 or similar
                legislation, or promotes, encourages, or engages in the sale or distribution of
                prescription medication without a valid prescription.
            </p>
            <p className="mt-0 mb-3">
                Violates the Fight Online Sex Trafficking Act of 2017 or similar legislation or
                promotes or facilitates prostitution and/or sex trafficking.
            </p>
            <p className="mt-0 mb-3">
                Infringes on the intellectual property rights of another User or any other person or
                entity.
            </p>
            <p className="mt-0 mb-3">
                Violates the privacy or publicity rights of another User or any other person or
                entity or breaches any duty of confidentiality that you owe to another User or any
                other person or entity.
            </p>
            <p className="mt-0 mb-3">
                Violates the privacy or publicity rights of another User or any other person or
                entity or breaches any duty of confidentiality that you owe to another User or any
                other person or entity.
            </p>
            <p className="mt-0 mb-3">
                Interferes with the operation of this Site or the Services found at this Site.
            </p>
            <p className="mt-0 mb-3">
                Contains or installs any viruses, worms, bugs, Trojan horses or other code, files or
                programs designed to, or capable of, disrupting, damaging, or limiting the
                functionality of any software or hardware; or
            </p>
            <p className="mt-0 mb-3">
                Contains false or deceptive language, or unsubstantiated or comparative claims,
                regarding BIKIRAN or BIKIRAN’s Services.
            </p>
            <p className="mt-0 mb-3">
                You will not perform any false, abusive, or fraudulent activity. You will not
                perform any action that imposes, or may impose, in our discretion, an unreasonable
                or disproportionately large load on our infrastructure.
            </p>
            <p className="mt-0 mb-3">
                You will not copy or distribute in any medium any part of this Site or the Services
                , except where expressly authorized by BIKIRAN.
            </p>
            <p className="mt-0 mb-3">
                You will not modify or alter any part of this Site or the Services found at this
                Site or any of its related technologies.
            </p>
            <p className="mt-0 mb-3">
                You will not modify or alter any part of this Site or the Services found at this
                Site or any of its related technologies.
            </p>
            <p className="mt-0 mb-3">
                You will not access BIKIRAN Content (as defined below) or User Content through any
                technology or means other than through this Site itself, or as BIKIRAN may
                designate.
            </p>
            <p className="mt-0 mb-3">
                You agree to back-up all of your User Content so that you can access and use it when
                needed. BIKIRAN does not warrant that it backs-up any Account or User Content, and
                you agree to accept as a risk the loss of all of your User Content.
            </p>
            <p className="mt-0 mb-3">
                You will not re-sell or provide the Services for a commercial purpose, including any
                of BIKIRAN`s related technologies, without BIKIRAN`s express prior written consent.
            </p>
            <p className="mt-0 mb-3">
                You will not circumvent, disable, or otherwise interfere with the security-related
                features of this Site or the Services found at this Site (including without
                limitation those features that prevent or restrict use or copying of any BIKIRAN
                Content or User Content) or enforce limitations on the use of this Site or the
                Services found at this Site, the BIKIRAN Content or the User Content therein.
            </p>
            <p className="mt-0 mb-3">
                You agree to provide government-issued photo identification and/or government-issued
                business identification as required for verification of identity when requested.
            </p>
            <p className="mt-0 mb-3">
                You are aware that BIKIRAN may from time-to-time call you about your account, and
                that, for the purposes of any and all such call(s), you may be subject to call
                recording and hereby consent to the same, subject to any applicable laws and our
                restrictions and obligations thereunder, including, where permissible, to record the
                entirety of such calls regardless of whether BIKIRAN asks you on any particular call
                for consent to record such call. You further acknowledge and agree that, to the
                extent permitted by applicable law, any such recording(s) may be submitted as
                evidence in any legal proceeding in which BIKIRAN is a party. Further, by providing
                your telephone or mobile number, you consent to receive marketing telephone calls
                from or on behalf of BIKIRAN that may be initiated by an automatic telephone dialing
                system and/or use an artificial or prerecorded voice. You understand that providing
                consent is not a condition of purchasing any good or service from BIKIRAN.
                Similarly, by providing your mobile number, you consent to receive marketing text
                messages from or on behalf of BIKIRAN that may be sent by an automatic telephone
                dialing system. You understand that providing consent is not a condition of
                purchasing any good or service from BIKIRAN. Message and data rates may apply.
            </p>
            <p className="mt-0 mb-3">
                Without limiting any of the rights set forth elsewhere in this Agreement, BIKIRAN
                expressly reserves the right to deny, cancel, terminate, suspend, or limit future
                access to this Site or any Services (including but not limited to the right to
                cancel or transfer any domain name registration) to any User (i) whose Account or
                Services were previously terminated or suspended, whether due to breach of this or
                any other Agreement or any BIKIRAN policy, or (ii) who otherwise engages or has
                engaged in inappropriate or unlawful activity while utilizing the Site or Services
                (as determined by BIKIRAN in its sole and absolute discretion).
            </p>
            <p className="mt-0 mb-0">
                If your purchase or account activity shows signs of fraud, abuse, or suspicious
                activity, BIKIRAN may cancel any service associated with your name, email address or
                account and close any associated BIKIRAN accounts. If BIKIRAN, in its sole
                discretion, determines that any conducted activity is fraudulent, BIKIRAN reserves
                the right to take any necessary legal action and you may be liable for monetary
                losses to BIKIRAN including litigation costs and damages. To contest cancellation of
                Services or freezing or closure of an account, please contact BIKIRAN Care.
            </p>
        </div>
    );
}

function DataProtecting() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="6" title="Data Protection, Use & Processing:" />
            <p className="mt-0 mb-3">
                BIKIRAN offers certain hosted Services available to you that may involve the
                processing of personal data about you, your customers and/or web users (“Your Data”)
                in the course of your use of these Services (“Covered Services”). Your Data, for the
                purpose of this Section, excludes any User Content. BIKIRAN’s Controller to
                Processor Data Processing Addendum, which is hereby incorporated by reference and
                applicable to Covered Services, is meant to provide you contractual assurance that
                we have robust mechanisms to ensure the processing of Your Data, including transfers
                of Your Data from the European Economic Area to a third country, meets with
                compliance under applicable data privacy laws.
            </p>
            <p className="mt-0 mb-3">
                For the purposes of the Controller to Processor DPA and the Standard Contractual
                Clauses attached to the DPA (when and as applicable), you (and your applicable
                affiliates) are considered the Data Controller/Data Exporter, and your acceptance of
                the Terms of Service governing Covered Services at the time of purchase of any
                Covered Services will also be treated as your acknowledgement and acceptance of the
                Controller to Processor DPA and its appendices (including the Standard Contractual
                Clauses and its appendices, as applicable). If you wish to print, sign, and return a
                physical copy of the Controller to Processor DPA, please send an email request to{' '}
                <a href="mailto:privacy@bikiran.com" className="purple">
                    privacy@bikiran.com
                </a>
                .
            </p>
            <p className="mt-0 mb-0">
                Covered Services, as defined in this Section and in the Controller to Processor DPA,
                include hosted services that are subject to the terms and conditions of the
                following Agreements: (1){' '}
                <span className="purple">Domain Registration Agreement</span>, (2){' '}
                <span className="purple">Private Email Terms of Service</span>, (3){' '}
                <span className="purple">Hosting Terms of Service</span>, (4){' '}
                <span className="purple">PremiumDNS Terms of Service</span>, (5){' '}
                <span className="purple">Website Terms of Service</span>, (6){' '}
                <span className="purple">Whois Privacy Service Agreement</span> (7){' '}
                <span className="purple">SSL Certificates Service Agreement</span> and (8){' '}
                <span className="purple">SMS Service Terms of Service</span>,
            </p>
        </div>
    );
}

function UserContent() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="7" title="User Content:" />
            <p className="mt-0 mb-3">
                In addition to the general rules above, the provisions in this section apply
                specifically to Your use of BIKIRAN Content and User Content posted to BIKIRAN’s
                site (i.e. those sites which We directly control or maintain). The applicable
                provisions are not intended to and do not have the effect of transferring any
                ownership or licensed rights (including intellectual property rights) You may have
                in content posted to Your websites.
            </p>
            <CommonSectionSubText title="BIKIRAN Content:" />
            <p className="mt-0 mb-3">
                Except for User Content, with respect to all content on this site and the Services
                We offer, all right, title and interest in and to all (i) registered and
                unregistered trademarks, service marks and logos; (ii) patents, patent applications,
                and patentable ideas, inventions, and/or improvements; (iii) trade secrets,
                proprietary information, and know-how; (iv) all divisions, continuations, reissues,
                renewals, and extensions thereof now existing or hereafter filed, issued, or
                acquired; (v) registered and unregistered copyrights including, without limitation,
                any forms, images, audiovisual displays, text, software and (vi) all other
                intellectual property, proprietary rights or other rights related to intangible
                property which are used, developed, comprising, embodied in, or practiced in
                connection with any of the Services identified herein (“IP rights”) are owned by
                BIKIRAN, and/or where applicable its partners and affiliates, and You agree to make
                no claim of interest in or ownership of any such IP rights. You acknowledge that no
                title to the IP rights is transferred to You and that You do not obtain any rights,
                express or implied, in the Services, other than the rights expressly granted in this
                Agreement.
            </p>
            <p className="mt-0 mb-3">
                BIKIRAN Content is provided to you “as is”, “as available” and “with all faults” for
                Your information and personal, non-commercial use only and may not be downloaded,
                copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed,
                or otherwise exploited for any purposes whatsoever without Our express prior written
                consent. No right or permission under any copyright, trademark, patent, or other
                proprietary right is granted by this Agreement. We reserve all rights not expressly
                granted in and to the BIKIRAN Content, this site, Our Services, and this Agreement
                do not transfer ownership of any of these rights.
            </p>
            <CommonSectionSubText title="User Content:" />
            <p className="mt-0 mb-3">
                Some of the features of Our site(s) or the Services may allow users to view, post,
                publish, share, store, or manage (a) ideas, opinions, recommendations, or advice
                (“User Submissions”), or (b) literary, artistic, musical, or other content,
                including but not limited to photos and videos, (c) reviews, rankings and/or product
                ratings (“User Reviews”) (collectively “User Content”). User Content also includes
                all content submitted through your Account. By posting or publishing User Content to
                this site or to the Services We offer, You represent and warrant to Us that (i) You
                have all necessary rights to distribute User Content via this site or via the
                Services, either because You are the author of the User Content and have the right
                to distribute the same, or because You have the appropriate distribution rights,
                licenses, consents, and/or permissions to use, in writing, from the copyright or
                other owner of the User Content, and (ii) the User Content does not violate the
                rights of any third party.
            </p>
            <CommonSectionSubText title="Security:" />
            <p className="mt-0 mb-0">
                You agree not to circumvent, disable, or otherwise interfere with the
                security-related features of this site or Our Services (including without limitation
                those features that prevent or restrict use or copying of any BIKIRAN Content or
                User Content) or enforce limitations on the use of this site or Our Services, the
                BIKIRAN Content or the User Content therein.
            </p>
        </div>
    );
}

function UseOfUserContent() {
    const info = [
        'Your User Submissions and/or User Reviews are entirely voluntary.',
        'Your User Submissions and/or User Reviews do not establish a confidential relationship or obligate Us to treat Your User Submissions as confidential or secret.',
        'BIKIRAN has no obligation, either express or implied, to develop or use Your User Submissions or User Reviews, and no compensation is due to You or to anyone else for any intentional or unintentional use of Your User Submissions or User Reviews.',
        'BIKIRAN may be working on the same or similar content, it may already know of such content from other sources, it may simply wish to develop this (or similar) content on its own, or it may have taken / will take some other action.'
    ];
    return (
        <div className="mb-5">
            <CommonSectionText sl="8" title="BIKIRAN’s Use of User Content:" />
            <p className="mt-0 mb-3">
                The provisions in this section apply specifically to BIKIRAN’s use of User Content
                posted to Our websites (i.e., those sites which BIKIRAN directly controls or
                maintains). The applicable provisions are not intended to and do not have the effect
                of transferring any ownership or licensed rights (including intellectual property
                rights) You may have in content posted to Your hosted websites
            </p>
            <p className="mt-0 mb-3">
                <span className="value-title fs-16">In General</span>. You shall be solely
                responsible for any and all of Your User Content or User Content that is submitted
                through Your Account, and the consequences of, and requirements for, distributing
                it.
            </p>
            <CommonSectionSubText title="User Submissions & User Reviews. " />
            <p className="mt-0 mb-2">You acknowledge and agree that:</p>
            <CommonListArea info={info} />
            <p className="mt-0 mb-3">
                BIKIRAN shall own exclusive rights (including all intellectual property and other
                proprietary rights) to any User Submissions and/or User Reviews posted to Our
                site(s), and shall be entitled to the unrestricted use and dissemination of any User
                Submissions or User Reviews posted to Our site(s) for any purpose, commercial or
                otherwise, without acknowledgment or compensation to You or to anyone else.
            </p>
            <CommonSectionSubText title="User Content (Other Than User Submissions/User Reviews):" />
            <p className="mt-0 mb-3">
                If You have a website or other content hosted by Us, You shall retain all of Your
                ownership or rights in User Content.
            </p>
            <p className="mt-0 mb-0">
                By posting or publishing User Content to this site or through Our Services, you
                authorize Us to use the intellectual property and other proprietary rights in and to
                Your User Content to enable inclusion and use of the User Content in the manner
                contemplated by this Agreement. You hereby grant BIKIRAN a worldwide, non-exclusive,
                royalty-free, sublicensable (through multiple tiers), and transferable license to
                use, reproduce, distribute, prepare derivative works of, combine with other works,
                display, and perform Your User Content in connection with this site, the Services
                and BIKIRAN’s (and BIKIRAN’s affiliates’) business(es), including without limitation
                for promoting and redistributing all or part of this site in any media formats and
                through any media channels without restrictions of any kind and without payment or
                other consideration of any kind, or permission or notification, to you or any third
                party. You also hereby grant each user of this Site a non-exclusive license to
                access Your User Content (apart from User Content that you designate “private” or
                “password protected”) through this site, and to use, reproduce, distribute, prepare
                derivative works of, combine with other works, display, and perform your User
                Content as permitted through the functionality of this site and under this
                Agreement. The above licenses granted by You in your User Content terminate within a
                commercially reasonable time after You remove or delete Your User Content from this
                Site. You understand and agree, however, that BIKIRAN may retain (but not
                distribute, display, or perform) server copies of Your User Content that have been
                removed or deleted. The above licenses granted by You in Your User Content are
                perpetual and irrevocable. Notwithstanding anything to the contrary contained
                herein, BIKIRAN shall not use any User Content that has been designated “private” or
                “password protected” by You for the purpose of promoting this site or BIKIRAN’s (or
                BIKIRAN’s affiliates’) business(es).
            </p>
        </div>
    );
}

function AvailabilityOfService() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="9" title="Availability of Website & Services:" />
            <p className="mt-0 mb-3">
                We shall use commercially reasonable efforts to attempt to provide this site, the
                Services available to purchase on Our site and Our Services on twenty-four (24)
                hours a day, seven (7) days a week basis. You acknowledge and agree that from time
                to time this site may be inaccessible or inoperable for any reason including, but
                not limited to, equipment malfunctions; periodic maintenance, repairs or
                replacements that We undertake from time to time; or causes beyond our reasonable
                control or that are not reasonably foreseeable including, but not limited to,
                interruption or failure of telecommunication or digital transmission links, hostile
                network attacks, network congestion or other failures. You acknowledge and agree
                that We have no control over the availability of this Site or Services on a
                continuous or uninterrupted basis, and that We assume no liability to You or any
                other party regarding such, including but not limited to loss of revenue.
            </p>
            <p className="mt-0 mb-3">
                From time to time, BIKIRAN may offer new Services (limited preview services or new
                features to existing Services) in a pre-release version. New Services, new features
                to existing Services or limited preview services shall be known, individually and
                collectively, as “Beta Services”. Beta Services, unless otherwise exempted, are
                subject to the following:
            </p>
            <p className="mt-0 mb-3">
                If You elect to use any Beta Services, then your use of the Beta Services is subject
                to the following terms and conditions: (i) You acknowledge and agree that the Beta
                Services are pre-release versions and may not work properly; (ii) You acknowledge
                and agree that Your use of the Beta Services may expose You to unusual risks of
                operational failures; (iii) The Beta Services are provided as is, so We do not
                recommend using them in production or mission critical environments; (iv) We reserve
                the right to modify, change, or discontinue any aspect of the Beta Services at any
                time; (v) Commercially released versions of the Beta Services may change
                substantially, and programs that use or run with the Beta Services may not work with
                the commercially released versions or subsequent releases; (vi) BIKIRAN may limit
                availability of customer service support time dedicated to support of the Beta
                Services; (vii) You acknowledge and agree to provide prompt feedback regarding Your
                experience with the Beta Services in a form reasonably requested by Us, including
                information necessary to enable us to duplicate errors or problems you experience.
                You acknowledge and agree that we may use Your feedback for any purpose, including
                product development purposes. At Our request You will provide Us with comments that
                We may use publicly for press materials and marketing collateral. Any intellectual
                property inherent in Your feedback or arising from Your use of the Beta Services
                shall be owned exclusively by BIKIRAN; (viii) You acknowledge and agree that all
                information regarding Your use of the Beta Services, including Your experience with
                and opinions regarding the Beta Services, is confidential, and may not be disclosed
                to a third party or used for any purpose other than providing feedback to BIKIRAN;
                (ix) the Beta Services are provided “as is”, “as available”, and “with all faults”.
                To the fullest extent permitted by law, BIKIRAN disclaims all warranties, statutory,
                express, or implied, with respect to the Beta Services including, but not limited
                to, any implied warranties of title, merchantability, fitness for a particular
                purpose and non-infringement.{' '}
            </p>
            <p className="mt-0 mb-3">
                BIKIRAN offers, as part of the Services available to You, products and/or services
                from third parties. These products and/or services are subject to the terms of this
                Agreement, including any additional policies and agreements required, and this
                Agreement controls as between You and BIKIRAN. Nothing contained in any agreement
                executed between You and a third-party provider shall eliminate, reduce or add to
                the obligations of BIKIRAN as described herein.
            </p>
            <p className="mt-0 mb-0">
                All paid Services are non-refundable, unless otherwise specified.
            </p>
        </div>
    );
}

function ProductCredits() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="10" title="Product Credits:" />
            <p className="mt-0 mb-3">
                In the event you are provided with a product credit (“Credit”), whether for
                redemption of the purchase of a specific product or for free with the purchase of
                another product (“Purchased Product”), you acknowledge and agree that such Credit is
                only valid for one (1) year and is only available with a valid purchase and may be
                terminated in the event the product purchased is deleted, cancelled, transferred or
                not renewed. The Credit will expire one (1) year from date of purchase of the
                Purchased Product if the Credit has not been redeemed. If the Credit is redeemed,
                after the initial subscription period, the product will automatically renew at the
                then-current renewal price until cancelled.{' '}
            </p>
            <p className="mt-0 mb-0">
                If you wish to cancel the automatic renewal of the product, you may do so by
                visiting your Account or by contacting customer service. In the event your Purchased
                Product includes a free domain name, if you cancel the Purchased Product, the list
                price for the domain name will be deducted from the refund amount. The list price is
                the price of the domain name listed on BIKIRAN`s website and is not subject to any
                promotion, discount, or other reduction in price. For Credits issued for free with
                another Purchased Product, you acknowledge and agree that we may swap your Credit
                for a similar product, in our sole discretion.{' '}
            </p>
        </div>
    );
}

function MonitoringOfContent() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="11" title="Monitoring of Content; Account Termination Policy:" />
            <p className="mt-0 mb-3">
                BIKIRAN generally does not pre-screen User Content (whether posted to a website
                hosted by BIKIRAN or posted to this Site). However, BIKIRAN reserves the right (but
                undertakes no duty) to do so and decide whether any item of User Content is
                appropriate and/or complies with this Agreement.
            </p>
            <p className="mt-0 mb-3">
                BIKIRAN may remove any item of User Content (whether posted to a website hosted by
                BIKIRAN or posted to this Site) and/or terminate a User’s access to this Site or the
                Services found at this Site for posting or publishing any material in violation of
                this Agreement, or for otherwise violating this Agreement (as determined by BIKIRAN
                in its sole and absolute discretion), at any time and without prior notice.
            </p>
            <p className="mt-0 mb-0">
                BIKIRAN may also terminate a User’s access to this Site, or the Services found at
                this Site if BIKIRAN has reason to believe the User is a repeat offender. If BIKIRAN
                terminates your access to this Site or the Services found at this Site, BIKIRAN may,
                in its sole and absolute discretion, remove and destroy any data and files stored by
                you on its servers.
            </p>
        </div>
    );
}

function DiscountServices() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="12" title="Discounted Services; End-of-Life Policy:" />
            <p className="mt-0 mb-3">
                BIKIRAN reserves the right to cease offering or providing any of the (i) Services or
                (ii) individual features, functionalities, or aspects of the Services at any time,
                for any or no reason, and without prior notice. Although BIKIRAN makes great effort
                to maximize the lifespan of all its Services and features, functionalities, or
                aspects of the Services, there are times when a Service or specific feature,
                functionality, or aspect of a Service that we offer will be discontinued or reach
                its End-of-Life (`EOL`). If that is the case, those Services, or the specific
                feature, functionality, or aspect of that Service, will no longer be supported by
                BIKIRAN, in any way, effective on the EOL date
            </p>
            <p className="mt-0 mb-3">
                Notice and Migration. If any Service we offer has reached or will reach EOL, we will
                attempt to notify you thirty or more days in advance of the EOL date. It is your
                responsibility to take all necessary steps to replace the Service by migrating to a
                new Service before the EOL date, or by entirely ceasing reliance on said Service
                before the EOL date. In either case, BIKIRAN will either offer a comparable Service
                for you to migrate to for the remainder of the term of your purchase, a prorated
                in-store credit, or a prorated refund, to be determined by BIKIRAN in its sole and
                absolute discretion.
            </p>
            <p className="mt-0 mb-3">
                BIKIRAN may, with or without notice to you, migrate you to the most up-to-date
                version of the Service, if available. You agree to take full responsibility for all
                loss or damage arising from any such migration. If a feature, functionality, or
                aspect of any Service we offer has reached or will reach EOL, then we will attempt
                to notify you thirty or more days in advance of the EOL date. However, if the
                Service maintains a least reasonably equivalent functionality without such feature,
                functionality, or aspect, as determined by BIKIRAN in its sole and absolute
                discretion, BIKIRAN will not be required to offer a comparable feature or
                functionality for the Service or a refund.
            </p>
            <p className="mt-0 mb-0">
                No Liability. BIKIRAN will not be liable to you or any third party for any
                modification, suspension, or discontinuance of any of the (i) Services or (ii)
                individual features, functionalities, or aspects of the Services we may offer,
                provide, or facilitate access to.
            </p>
        </div>
    );
}
function BillingAndPayment() {
    return (
        <div className="mb-5">
            <CommonSectionText
                sl="13"
                title="Billing & Payment; Currency; Termination & Cancellation Policy:"
            />
            <p className="mt-0 mb-3">
                <span className="fs-16">Billing and Payment:</span> All fees for the Services shall
                be in accordance with BIKIRAN`s fee schedule then in effect, the terms of which are
                incorporated herein by reference, and shall be due at the time You order the
                Services, unless otherwise noted. You may pay for Services by providing a valid
                credit or debit card, an electronic check (from your personal or business checking
                account, as appropriate), PayPal, or any other payment method then accepted by
                BIKIRAN (each a “Payment Method”); provided, however, that We may at Our option
                require that You pay fees through a particular payment means (such as by credit card
                or by wire transfer) or that You change from one payment provider to another.
            </p>
            <p className="mt-0 mb-3">
                Charges for the Service(s) will be billed to Your chosen Payment Method as charges
                for “BIKIRAN.COM.” The statement descriptor may also include a unique identifier of
                the transaction. BIKIRAN is not responsible for any changes in statement descriptors
                made by Your credit card issuer. If You choose to pay for the Service(s) by credit
                card, our payment processing service provider may, at the beginning of the payment
                process, pre-authorize the transaction charges against Your credit card. This
                process confirms both the validity of the credit card and the availability of
                sufficient funds to finalize the transaction. If, after commencing the payment
                process, you subsequently elect not to finalize Your transaction, we will clear any
                pre-authorized charges from Our systems and reverse the payment within two [2]
                hours.
            </p>
            <p className="mt-0 mb-3">
                Depending on Your credit card provider and their policies, pre-authorized charges
                may continue to be reflected in Your credit card account details for longer than
                this two [2] hour period. All prices and fees are non-refundable unless otherwise
                expressly noted, even if Your Services are suspended, terminated, or transferred
                prior to the end of the Services term. BIKIRAN expressly reserves the right to
                change or modify its prices and fees at any time, and such changes or modifications
                shall be posted online at this site, or the relevant site of the Service, and
                effective immediately without need for further notice to You. If You have purchased
                or obtained Services for a period of months or years, changes or modifications in
                prices and fees shall be effective when the Services in question come up for renewal
                as further described below.
            </p>
            <p className="mt-0 mb-3 value-solid">
                IN ORDER TO ENSURE THAT YOU DO NOT EXPERIENCE AN INTERRUPTION OR LOSS OF SERVICES,
                MOST SERVICES OFFER AN AUTOMATIC RENEWAL OPTION. THE AUTOMATIC RENEWAL OPTION WILL
                ATTEMPT TO AUTOMATICALLY RENEW THE APPLICABLE SERVICE FOR A RENEWAL PERIOD EQUAL IN
                TIME TO THE MOST RECENT SERVICE PERIOD, EXCEPT FOR DOMAIN NAMES WHICH WILL RENEW FOR
                THE ORIGINAL SERVICE PERIOD. FOR EXAMPLE, FOR PRODUCTS OTHER THAN DOMAINS, IF YOUR
                LAST SERVICE PERIOD IS FOR ONE YEAR, YOUR RENEWAL PERIOD WILL BE FOR ONE YEAR. WITH
                THE AUTOMATIC RENEWAL OPTION, BIKIRAN WILL ATTEMPT TO RENEW THE APPLICABLE SERVICE
                WHEN IT COMES UP FOR RENEWAL AND WILL TAKE PAYMENT FROM THE PAYMENT METHOD YOU HAVE
                ON FILE WITH US AT BIKIRAN’S THEN CURRENT RATES, WHICH YOU ACKNOWLEDGE AND AGREE MAY
                BE HIGHER OR LOWER THAN THE RATES FOR THE ORIGINAL SERVICE PERIOD. PLEASE NOTE THAT
                RENEWAL DATES VARY BY SERVICE. FOR INSTANCE, SOME SERVICES MAY RENEW THIRTY (30)
                DAYS PRIOR TO EXPIRATION AND OTHERS MAY RENEW FIVE (5) DAYS PRIOR TO EXPIRATION. YOU
                MAY ENABLE OR DISABLE THE AUTOMATIC RENEWAL OPTION AT ANY TIME. HOWEVER, SHOULD YOU
                ELECT TO DISABLE THE AUTOMATIC RENEWAL OPTION AND FAIL TO MANUALLY RENEW YOUR
                SERVICES BEFORE THEY EXPIRE OR IF YOUR PAYMENT METHOD CHOSEN FOR YOUR AUTOMATIC
                RENEWAL SHOULD FAIL, YOU MAY EXPERIENCE AN INTERRUPTION OR LOSS OF SERVICES, AND
                BIKIRAN SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY REGARDING THE SAME. BIKIRAN
                RESERVES THE RIGHT TO DETERMINE WHETHER A SECOND ATTEMPT AT PAYMENT WILL BE MADE
                SHOULD THE CHOSEN PAYMENT METHOD FAIL.
            </p>
            <p className="mt-0 mb-3 value-solid">
                IN ADDITION, BIKIRAN MAY PARTICIPATE IN “RECURRING BILLING PROGRAMS” OR “ACCOUNT
                UPDATER SERVICES” SUPPORTED BY YOUR THIRD-PARTY BILLING PROVIDER. IF YOU ARE
                ENROLLED IN AN AUTOMATIC RENEWAL OPTION AND WE ARE UNABLE TO SUCCESSFULLY CHARGE
                YOUR EXISTING PAYMENT METHOD, YOUR CREDIT CARD PROVIDER (OR YOUR BANK) MAY NOTIFY US
                OF UPDATES TO YOUR CREDIT CARD NUMBER AND/OR EXPIRATION DATE, OR THEY MAY
                AUTOMATICALLY CHARGE YOUR NEW CREDIT CARD ON OUR BEHALF WITHOUT NOTIFICATION TO US.
                IN ACCORDANCE WITH RECURRING BILLING PROGRAM REQUIREMENTS, IN THE EVENT THAT WE ARE
                NOTIFIED OF AN UPDATE TO YOUR CREDIT CARD NUMBER AND/OR EXPIRATION DATE, BIKIRAN
                WILL AUTOMATICALLY UPDATE YOUR PAYMENT PROFILE ON YOUR BEHALF. BIKIRAN MAKES NO
                GUARANTEES THAT WE WILL REQUEST OR RECEIVE UPDATED CREDIT CARD INFORMATION. YOU
                ACKNOWLEDGE AND AGREE THAT IT IS YOUR SOLE RESPONSIBILITY TO MODIFY AND MAINTAIN
                YOUR ACCOUNT SETTINGS, INCLUDING BUT NOT LIMITED TO (I) SETTING YOUR RENEWAL OPTIONS
                AND (II) ENSURING YOUR ASSOCIATED PAYMENT METHOD(S) ARE CURRENT AND VALID. FURTHER,
                YOU ACKNOWLEDGE AND AGREE THAT YOUR FAILURE TO DO SO MAY RESULT IN THE INTERRUPTION
                OR LOSS OF SERVICES, AND BIKIRAN SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY
                REGARDING THE SAME.
            </p>
            <p className="mt-0 mb-3">
                If for any reason BIKIRAN is unable to charge your payment method for the full
                amount owed for the Services provided, or if We receive notification of a
                chargeback, reversal, payment dispute, or are charged a penalty for any fee
                previously charged to your payment method, You agree that BIKIRAN may pursue all
                available lawful remedies in order to obtain payment, including but not limited to,
                immediate cancellation, without notice to You, of any domain names or Services
                registered or renewed on Your behalf. You also agree that all rights to and interest
                in and use of any Services, content and/or products purchased through Us, including
                all data hosted on Our systems shall be assumed by Us in satisfaction of any
                indebtedness by You to Us. Our reinstatement fee is currently equal to the fee
                charged by the payment processor involved in the transaction reversal.
            </p>
            <p className="mt-0 mb-3">
                BIKIRAN also reserves the right to charge You reasonable `administrative fees` or
                `processing fees` for (i) tasks BIKIRAN may perform outside the normal scope of its
                Services, (ii) additional time and/or costs We may incur in providing its Services,
                and/or (iii) Your noncompliance with this Agreement (as determined by BIKIRAN in its
                sole and absolute discretion). Typical administrative or processing fee scenarios
                include, but are not limited to (i) customer service issues that require additional
                personal time or attention; (ii) investigation into the use of BIKIRAN services
                where it is alleged and verified by BIKIRAN that your use of such services were for
                abuse; (iii) account maintenance where there has been inactivity in your account for
                6 months or more; (iv) UDRP action(s) in connection with Your domain name(s) and/or
                disputes that require accounting or legal services, whether performed by BIKIRAN
                staff or by outside firms retained by BIKIRAN; (v) recouping any and all costs and
                fees, including the cost of Services, incurred by BIKIRAN as the results of
                chargebacks or other payment disputes brought by You, Your bank or payment method
                processor. These administrative fees or processing fees will be billed to the
                payment method You have on file with BIKIRAN.
            </p>
            <p className="mt-0 mb-3">
                If You signed up for a monthly payment plan, your monthly billing date will be
                determined based on the day of the month you purchased the products or Services. If
                You signed up for an annual (or longer) payment plan, and You elected the automatic
                renewal option, BIKIRAN will automatically renew Your Services when they come up for
                renewal and will take payment in accordance with the designated payment method at
                BIKIRAN`s then current rates.
            </p>
            <p className="mt-0 mb-3">
                <span className="value-title fs-16">Currency:</span> While all purchases are
                processed in US dollars, BIKIRAN may provide an estimated conversion price to
                currencies other than US dollars. You acknowledge and agree that the pricing
                displayed during the checkout process is an estimate. Due to potential slight time
                delays between actual purchase and the payment settlement, the actual price charged
                may fluctuate. Accordingly, BIKIRAN makes no representations or warranties that the
                actual price will be the same or substantially like the actual price You will pay,
                and You waive all claims based upon any discrepancy between the estimate and the
                actual price. In addition, you may be charged VAT or additional offshore margin
                and/or fees, based on the country indicated in Your billing address section. Any
                amounts to be charged will appear during the checkout process.
            </p>
            <p className="mt-0 mb-3">
                <span className="value-title fs-16">Termination & Cancellation Policy:</span> The
                initial term of Your agreement with BIKIRAN shall be as set forth in Your Order
                Form. The Initial Term shall begin upon commencement of the Services in the Order
                Form. After the Initial Term, your agreement with BIKIRAN shall automatically renew
                for successive terms of equal length as the Initial Term, unless terminated or
                cancelled by either party as provided in this section.
            </p>
            <p className="mt-0 mb-3">
                This agreement may be terminated: (i) by You when You discontinue the use of Our
                Services or (ii) by BIKIRAN at any time, without prior notice, if, in BIKIRAN`s
                judgment, You are in violation of any terms or conditions herein; or (iii) in
                BIKIRAN`s sole judgment, Your use of the Services places or is likely to place
                unreasonable demands upon BIKIRAN or could disrupt BIKIRAN`s business operations; or
                (iv) by BIKIRAN if it so determines that You are or are alleged to be violating the
                terms and conditions of any other agreement entered into by You and BIKIRAN.
            </p>
            <p className="mt-0 mb-3">
                In the event of termination or suspension of Services under the above circumstances,
                You agree (a) that no pre-paid fees will be refunded to You; and (b) that BIKIRAN
                may take control of any domain name associated with the terminated Services,
                provided such domain name was registered through the domain name registration of
                BIKIRAN.
            </p>
            <p className="mt-0 mb-3">
                Refunds do NOT apply to Services unless otherwise specified.
            </p>
            <p className="mt-0 mb-0">
                In the event of termination of this Agreement caused by Your default hereunder, you
                shall bear all costs of termination, including any reasonable costs BIKIRAN incurs
                in closing Your account. You agree to pay all costs incurred by BIKIRAN in enforcing
                Your compliance with this section. Upon termination, you shall destroy any copy of
                the materials provided to You hereunder and referenced herein. You agree that upon
                termination or discontinuance for any reason, BIKIRAN may delete all information
                related to You on the Services.
            </p>
        </div>
    );
}

function AdditionalRights() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="14" title="Additional Reservation of Rights:" />
            <p className="mt-0 mb-3">
                BIKIRAN expressly reserves the right to deny, cancel, terminate, suspend, lock, or
                modify access to (or control of) any Account or Services (including the right to
                cancel or transfer any domain name registration) for any reason (as determined by
                BIKIRAN in its sole and absolute discretion), including but not limited to the
                following: (i) to correct mistakes made by BIKIRAN in offering or delivering any
                Services (including any domain name registration), (ii) to protect the integrity and
                stability of, and correct mistakes made by, any domain name registry or registrar,
                (iii) to assist with our fraud and abuse detection and prevention efforts, (iv) to
                comply with court orders against you and/or your domain name or website and
                applicable local, state, national and international laws, rules and regulations, (v)
                to comply with requests of law enforcement, including subpoena requests, (vi) to
                comply with any dispute resolution process, (vii) to defend any legal action or
                threatened legal action without consideration for whether such legal action or
                threatened legal action is eventually determined to be with or without merit, (viii)
                to avoid any civil or criminal liability on the part of BIKIRAN, its officers,
                directors, employees and agents, as well as BIKIRAN`s affiliates, including, but not
                limited to, instances where you have sued or threatened to sue BIKIRAN, or (ix) to
                respond to an excessive amount of complaints related in any way to your Account,
                domain name(s), or content on your website that could result in damage to BIKIRAN’s
                business, operations, reputation or shareholders.
            </p>
            <p className="mt-0 mb-3">
                BIKIRAN expressly reserves the right to review every Account for excessive space and
                bandwidth utilization, and to terminate or apply additional fees to those Accounts
                that exceed allowed levels.
            </p>
            <p className="mt-0 mb-3">
                BIKIRAN expressly reserves the right to terminate, without notice to you, all
                Services where, in BIKIRAN`s sole discretion, you are harassing or threatening
                BIKIRAN and/or any of BIKIRAN`s employees
            </p>
            <p className="mt-0 mb-0">
                BIKIRAN Content. Except for User Content, the content on this Site and the Services,
                including without limitation the text, software, scripts, source code, API,
                graphics, photos, sounds, music, videos and interactive features and the trademarks,
                service marks and logos contained therein (“BIKIRAN Content”), are owned by or
                licensed to BIKIRAN in perpetuity, and are subject to copyright, trademark, and/or
                patent protection in the United States and foreign countries, and other intellectual
                property rights under United States and foreign laws. BIKIRAN Content is provided to
                you “as is”, “as available” and “with all faults” for your information and personal,
                non-commercial use only and may not be downloaded, copied, reproduced, distributed,
                transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any
                purposes whatsoever without the express prior written consent of BIKIRAN. No right
                or license under any copyright, trademark, patent, or other proprietary right or
                license is granted by this Agreement. BIKIRAN reserves all rights not expressly
                granted in and to the BIKIRAN Content, this Site and the Services, and this
                Agreement do not transfer ownership of any of these rights.
            </p>
        </div>
    );
}
function CustomerSupport() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="15" title="Customer Support:" />
            <p className="mt-0 mb-3">
                BIKIRAN provides customer support to You at no additional fee for issues related to
                BIKIRAN Services only. BIKIRAN has the right to decide what is a service-related
                issue and to charge additional fees or refuse support for non-service-related
                issues. Any fees paid by You for providing non-service-related support are
                non-refundable. BIKIRAN will also require, before assistance can be given, that You
                verify Your identity in relation to the Account in question. We will determine, in
                Our sole and absolute discretion, what must be provided for verification purposes,
                including the use of secure validation tools such as{' '}
                <a
                    href="https://www.validation.com"
                    target="_blank"
                    className="purple"
                    rel="noreferrer"
                >
                    Validation.com
                </a>
                .
            </p>
            <p className="mt-0 mb-3">
                Unless otherwise directed by a specific Service, you can request customer support
                only by opening Live Chat or Ticket through the Support system located in the
                Customer area. BIKIRAN will have no liability to provide customer support if it is
                requested in any other way apart from the Support system or the instructions
                specific to the Service at issue. You are solely responsible to use the appropriate
                Support category when opening Live Chat or posting Ticket. BIKIRAN will have no
                liability to respond to tickets opened in inappropriate categories. BIKIRAN shall
                not be liable for any delay in Live Chat and/or Ticket opened in inappropriate
                categories. You acknowledge that by asking our customer support representatives for
                assistance, you authorize their intervention and operation in Your account.
            </p>
            <p className="mt-0 mb-3">
                You must provide BIKIRAN with all information and access to facilities that BIKIRAN
                may reasonably require to provide the requested customer support. You are solely
                liable for performing and storing a back-up copy of data, files, hosting account and
                any other content prior to requesting customer support and agreeing to any
                interference or operation, provided by BIKIRAN. In the event You are not satisfied
                with the outcome of any action You shall be solely responsible for restoring the
                back-up copies of Your data. You should not abuse the Support system. Abuse of the
                Support system includes, but is not limited to, excessive number of Live Chats and
                Tickets opened by a single Customer, aggressive and/or harassing behavior,
                repetitive use of inappropriate categories for opening Live Chats, posting Tickets,
                etc. Any abuse of the Support system may result in warning, Support access
                restrictions, account suspension or possible account termination with no refund.
                BIKIRAN has the sole right to decide what constitutes abuse of the Support system.
            </p>
        </div>
    );
}
function DisclaimerOFWarranties() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="16" title="Disclaimer of Representations and Warranties:" />
            <p className="mt-0 mb-3 value-solid">
                YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT YOUR USE OF THIS SITE AND OUR SERVICES
                SHALL BE AT YOUR OWN RISK AND THAT SERVICES FOUND ARE PROVIDED “AS IS”, “AS
                AVAILABLE” AND “WITH ALL FAULTS”. BIKIRAN, ITS OFFICERS, DIRECTORS, EMPLOYEES,
                AGENTS, AND ALL THIRD PARTY SERVICE PROVIDERS DISCLAIM ALL WARRANTIES, STATUTORY,
                EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF TITLE,
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. BIKIRAN, ITS
                OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS MAKE NO REPRESENTATIONS OR WARRANTIES
                ABOUT (I) THE ACCURACY, COMPLETENESS, OR CONTENT OF THIS SITE, (II) THE ACCURACY,
                COMPLETENESS, OR CONTENT OF ANY SITES LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING
                OR OTHERWISE) TO THIS SITE, AND/OR (III) THE SERVICES FOUND AT THIS SITE OR ANY
                SITES LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, AND
                BIKIRAN ASSUMES NO LIABILITY OR RESPONSIBILITY FOR THE SAME. YOU UNDERSTAND AND
                AGREE THAT ANY MATERIAL AND/OR DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE
                OF OUR SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY
                RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM
                THE DOWNLOAD OF SUCH MATERIAL AND/OR DATA
            </p>
            <p className="mt-0 mb-3 value-solid">
                IN ADDITION, YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT NO ORAL OR WRITTEN
                INFORMATION OR ADVICE PROVIDED BY NAMECHEAP, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR
                AGENTS (INCLUDING WITHOUT LIMITATION ITS SUPPORT CENTER OR CUSTOMER SERVICE
                REPRESENTATIVES), AND THIRD PARTY SERVICE PROVIDERS WILL (I) CONSTITUTE LEGAL OR
                FINANCIAL ADVICE OR (II) CREATE A WARRANTY OF ANY KIND WITH RESPECT TO THIS SITE OR
                THE SERVICES FOUND AT THIS SITE, AND USERS SHOULD NOT RELY ON ANY SUCH INFORMATION
                OR ADVICE.
            </p>
            <p className="mt-0 mb-0 value-solid">
                THE FOREGOING DISCLAIMER OF REPRESENTATIONS AND WARRANTIES SHALL APPLY TO THE
                FULLEST EXTENT PERMITTED BY LAW AND SHALL SURVIVE ANY TERMINATION OR EXPIRATION OF
                THIS AGREEMENT OR YOUR USE OF THIS SITE OR OUR SERVICES.
            </p>
        </div>
    );
}
function LimitationOfLiability() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="17" title="Limitation of Liability; Waiver and Release:" />
            <p className="mt-0 mb-3 value-solid">
                IN NO EVENT SHALL BIKIRAN, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AND ALL THIRD
                PARTY SERVICE PROVIDERS, BE LIABLE TO YOU OR ANY OTHER PERSON OR ENTITY FOR ANY
                DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES
                WHATSOEVER, INCLUDING ANY THAT MAY RESULT FROM (I) THE ACCURACY, COMPLETENESS, OR
                CONTENT OF THIS SITE, (II) THE ACCURACY, COMPLETENESS, OR CONTENT OF ANY SITES
                LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, (III) OUR
                SERVICES WHETHER FOUND AT THIS SITE OR ANY SITES LINKED (THROUGH HYPERLINKS, BANNER
                ADVERTISING OR OTHERWISE) TO THIS SITE, (IV) PERSONAL INJURY OR PROPERTY DAMAGE OF
                ANY NATURE WHATSOEVER, (V) THIRD-PARTY CONDUCT OF ANY NATURE WHATSOEVER, (VI) ANY
                UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS AND/OR ANY AND ALL CONTENT, PERSONAL
                INFORMATION, FINANCIAL INFORMATION OR OTHER INFORMATION AND DATA STORED THEREIN,
                (VII) ANY INTERRUPTION OR CESSATION OF SERVICES TO OR FROM THIS SITE OR ANY SITES
                LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, (VIII)
                ANY VIRUSES, WORMS, BUGS, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR
                FROM THIS SITE OR ANY SITES LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING OR
                OTHERWISE) TO THIS SITE, (IX) ANY USER CONTENT OR CONTENT THAT IS DEFAMATORY,
                HARASSING, ABUSIVE, HARMFUL TO MINORS OR ANY PROTECTED CLASS, PORNOGRAPHIC,
                “X-RATED”, OBSCENE OR OTHERWISE OBJECTIONABLE, AND/OR (X) ANY LOSS OR DAMAGE OF ANY
                KIND INCURRED AS A RESULT OF YOUR USE OF THIS SITE OR OUR SERVICES, WHETHER BASED ON
                WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL OR EQUITABLE THEORY, AND WHETHER OR NOT
                BIKIRAN IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
            <p className="mt-0 mb-3 value-solid">
                YOU AGREE THAT BIKIRAN WILL NOT BE LIABLE FOR ANY (I) SUSPENSION OR LOSS OF THE
                SERVICES; (II) INTERRUPTION OF BUSINESS; (III) ACCESS DELAYS OR ACCESS INTERRUPTIONS
                TO THE WEBSITE(S) PROVIDED THROUGH OR BY THE SERVICES; (IV) LOSS OR LIABILITY
                RESULTING FROM ACTS OF GOD; (V) DATA NON-DELIVERY, MIS-DELIVERY, CORRUPTION,
                DESTRUCTION OR OTHER MODIFICATION; (IV) EVENTS BEYOND THE CONTROL OF BIKIRAN; (VII)
                THE PROCESSING OF YOUR APPLICATION FOR SERVICES; OR (VIII) LOSS OR LIABILITY
                RESULTING FROM THE UNAUTHORIZED USE OR MISUSE OF YOUR ACCOUNT IDENTIFIER OR
                PASSWORD.
            </p>
            <p className="mt-0 mb-3 value-solid">
                IN ADDITION, YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT ANY CAUSE OF ACTION ARISING
                OUT OF OR RELATED TO THIS SITE OR OUR SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR
                AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE SUCH CAUSE OF ACTION SHALL BE
                PERMANENTLY BARRED.
            </p>
            <p className="mt-0 mb-3 value-solid">
                IN ADDITION, YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT IN NO EVENT SHALL BIKIRAN’S
                TOTAL AGGREGATE LIABILITY EXCEED THE TOTAL AMOUNT PAID BY YOU FOR THE PARTICULAR
                SERVICES THAT ARE THE SUBJECT OF THE CAUSE OF ACTION.
            </p>
            <p className="mt-0 mb-3 value-solid">
                THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY
                LAW AND SHALL SURVIVE ANY TERMINATION OR EXPIRATION OF THIS AGREEMENT OR YOUR USE OF
                THIS SITE OR THE SERVICES FOUND AT THIS SITE.
            </p>
            <p className="mt-0 mb-0 value-solid">
                NO WAIVER OF ANY PROVISION OF THIS AGREEMENT SHALL BE EFFECTIVE UNLESS IT IS IN
                WRITING AND SIGNED BY AN AUTHORIZED REPRESENTATIVE OF BIKIRAN.
            </p>
        </div>
    );
}
function Indemnification() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="18" title="Indemnification:" />

            <p className="mt-0 mb-0">
                You agree to protect, defend, indemnify and hold harmless BIKIRAN and its officers,
                directors, employees, agents, and third party service providers from and against any
                and all claims, demands, costs, expenses, losses, liabilities and damages of every
                kind and nature (including, without limitation, reasonable attorneys’ fees) imposed
                upon or incurred by BIKIRAN directly or indirectly arising from (i) your use of and
                access to this Site or the Services found at this Site; (ii) your violation of any
                provision of this Agreement or the policies or agreements which are incorporated
                herein; and/or (iii) your violation of any third-party right, including without
                limitation any intellectual property or other proprietary right. The indemnification
                obligations under this section shall survive any termination or expiration of this
                Agreement or your use of this Site or the Services found at this Site
            </p>
        </div>
    );
}
function LocalLaws() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="19" title="Compliance with Local Laws:" />

            <p className="mt-0 mb-0">
                BIKIRAN makes no representation or warranty that the content available on this Site
                or the Services found at this Site are appropriate in every country or jurisdiction,
                and access to this Site or the Services found at this Site from countries or
                jurisdictions where its content is illegal is prohibited. Users who choose to access
                this Site or the Services found at this Site are responsible for compliance with all
                local laws, rules, and regulations.
            </p>
        </div>
    );
}
function USexportLaws() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="20" title="U.S. Export Laws:" />

            <p className="mt-0 mb-0">
                This Site and Our Services are subject to the export laws, restrictions, regulations
                and administrative acts of the United States Department of Commerce, Department of
                Treasury Office of Foreign Assets Control (“OFAC”), State Department, and other
                United States authorities (collectively, “U.S. Export Laws”). Users shall not use
                the Services found at this Site to collect, store, or transmit any technical
                information or data that is controlled under U.S. Export Laws. Users shall not
                export or re-export, or allow the export or re-export of, the Services found at this
                Site in violation of any U.S. Export Laws. None of Our Services may be downloaded or
                otherwise exported or re-exported (i) into (or to a national or resident of) any
                country with which the United States has embargoed trade; or (ii) to anyone on the
                U.S. Treasury Department`s list of Specially Designated Nationals or the U.S.
                Commerce Department`s Denied Persons List, or any other denied parties lists under
                U.S. Export Laws. By using this site and Our Services, You agree to the foregoing
                and represent and warrant that You are not a national or resident of, located in, or
                under the control of, any restricted country; and You are not on any denied parties
                list; and You agree to comply with all U.S. Export Laws (including “anti-boycott”,
                “deemed export” and “deemed re-export” regulations). If You access this site or Our
                Services from other countries or jurisdictions, you do so on Your own initiative and
                You are responsible for compliance with the local laws of that jurisdiction, if and
                to the extent those local laws are applicable and do not conflict with U.S. Export
                Laws. If such laws conflict with U.S. Export Laws, you shall not access this site or
                Our Services. The obligations under this section shall survive any termination or
                expiration of this Agreement or Your use of this site or Our Services.
            </p>
        </div>
    );
}
function GoverningLaw() {
    return (
        <div className="mb-5">
            <CommonSectionText
                sl="21"
                title="Governing Law; Jurisdiction; Waiver of Trial By Jury:"
            />

            <p className="mt-0 mb-0">
                Except as otherwise set forth in the UDRP or any similar policy with respect to any
                dispute regarding the Services provided under this Agreement, your rights and
                obligations and all actions contemplated by this Agreement shall be governed by the
                laws of the United States of America and the State of Arizona. You agree that any
                action to enforce this agreement or any matter relating to Your use of the Services
                must be brought exclusively in the United States District Court of Arizona, or if
                there is no jurisdiction in such court, then in a state court in Maricopa County,
                State of Arizona. You agree to waive the right to a trial by jury in any action or
                proceeding that takes place relating to or arising out of this Agreement.
            </p>
        </div>
    );
}
function BeneficiaryRights() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="22" title="No Third Party Beneficiary Rights:" />

            <p className="mt-0 mb-0">
                This Agreement, and any/all supplements to it, is not intended to and shall not be
                construed to give any Third Party any interest or rights (including, without
                limitation, any third-party beneficiary rights) with respect to or in connection
                with any agreement or provision contained herein or contemplated hereby.
            </p>
        </div>
    );
}
function Notices() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="23" title="Notices:" />

            <p className="mt-0 mb-0">
                You agree that any notices required to be given under this Agreement by Us to You
                will be deemed to have been given if delivered in accordance with the account
                and/or, if domain related, the domain name Whois information You have provided. You
                acknowledge that it is Your responsibility to maintain current contact information
                in the account and/or domain name Whois information You have provided.
            </p>
        </div>
    );
}
function FinalAgreement() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="24" title="Final Agreement:" />
            <p className="mt-0 mb-0">
                This Agreement, together with all modifications, constitutes the complete and
                exclusive agreement between You and Us, and supersedes and governs all prior
                proposals, agreements, or other communications. This Agreement may not be amended or
                modified by You except by means of a written document signed by both You and an
                authorized representative of Us. By applying for BIKIRAN’s Services through the
                online application process or otherwise, or by using the Services under this
                Agreement, you acknowledge that You have read and agree to be bound by all terms and
                conditions of this Agreement and documents incorporated by reference.
            </p>
        </div>
    );
}
function AgencyRelationship() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="25" title="No Agency Relationship:" />
            <p className="mt-0 mb-0">
                Nothing contained in this Agreement shall be construed as creating any agency,
                partnership, or other form of joint enterprise between the parties hereto. Each
                party shall ensure that the foregoing persons shall not represent to the contrary,
                either expressly, implicitly, by appearance or otherwise
            </p>
        </div>
    );
}
function AssignmentAndResale() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="26" title="Assignment and Resale:" />
            <p className="mt-0 mb-0">
                Except as otherwise set forth herein, your rights under this Agreement are not
                assignable or transferable. Any attempt by Your creditors to obtain an interest in
                Your rights under this Agreement, whether by attachment, levy, garnishment or
                otherwise, renders this Agreement voidable at Our option. You agree not to
                reproduce, duplicate, copy, sell, resell, or otherwise exploit for any commercial
                purposes any of the Services (or portion thereof) without BIKIRAN`s prior express
                written consent.
            </p>
        </div>
    );
}
function UnclaimedProperty() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="27" title="Unclaimed Property: Dormancy Charges:" />
            <p className="mt-0 mb-0">
                Please be advised that if a customer has an outstanding account balance (a credit
                positive balance) for three (3) years or more for any reason, and (i) BIKIRAN is
                unable to issue payment to such customer or (ii) BIKIRAN issued payment to such
                customer in the form of a paper check, but the check was never cashed, then BIKIRAN
                shall turn over such account balance to the State of Arizona in accordance with
                state law. You acknowledge and agree that in either case (i) or (ii) above, BIKIRAN
                may withhold a dormancy charge in an amount equal to the lesser of $25.00 or the
                total outstanding account balance associated with such customer.
            </p>
        </div>
    );
}
function ForceMajeure() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="28" title="Force Majeure:" />
            <p className="mt-0 mb-0">
                Neither party shall be deemed in default hereunder, nor shall it hold the other
                party responsible for, any cessation, interruption or delay in the performance of
                its obligations hereunder due to causes beyond its control including, but not
                limited to: earthquake; flood; fire; storm; natural disaster; act of God; war;
                terrorism; armed conflict; labor strike; lockout; boycott; supplier failures,
                shortages, breaches, or delays; or any law, order regulation, direction, action or
                request of the government, including any federal, state and local governments having
                or claiming jurisdiction over BIKIRAN, or of any department, agency, commission,
                bureau, corporation or other instrumentality of any federal, state, or local
                government, or of any civil or military authority; or any other cause or
                circumstance, whether of a similar or dissimilar nature to the foregoing, beyond the
                reasonable control of the affected party, provided that the party relying upon this
                section (i) shall have given the other party written notice thereof promptly and, in
                any event, within five (5) days of discovery thereof and (ii) shall take all steps
                reasonably necessary under the circumstances to mitigate the effects of the force
                majeure event upon which such notice is based; provided further, that in the event a
                force majeure event described in this section extends for a period in excess of
                thirty (30) days in the aggregate, BIKIRAN may immediately terminate this Agreement.
            </p>
        </div>
    );
}
function EnglishLangControl() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="29" title="English Language Control:" />
            <p className="mt-0 mb-0">
                This Agreement, along with all policies and the applicable product agreements
                identified above and incorporated herein by reference (collectively, the
                “Agreement”), is executed in the English language. To the extent any translation is
                provided to you, it is provided for convenience purposes only, and in the event of
                any conflict between the English and translated version, where permitted by law, the
                English version will control and prevail. Where the translated version is required
                to be provided to you and is to be considered binding by law (i) both language
                versions shall have equal validity, (ii) each party acknowledges that it has
                reviewed both language versions and that they are substantially the same in all
                material respects, and (iii) in the event of any discrepancy between these two
                versions, the translated version may prevail, provided that the intent of the
                Parties has been fully taken into consideration
            </p>
        </div>
    );
}
function Headings() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="30" title="Headings:" />
            <p className="mt-0 mb-0">
                The section headings appearing in this Agreement are inserted only as a matter of
                convenience and in no way define, limit, construe or describe the scope or extent of
                such section or in any way affect such section.
            </p>
        </div>
    );
}
function ContactInfo() {
    return (
        <div className="mb-5">
            <CommonSectionText sl="31" title="Contact Information:" />
            <p className="mt-0 mb-0">
                If you have any questions, concerns, or complaints about our Privacy Policy, or how
                we handle your personal data, you may contact our Office of the Data Protection
                Officer by email at{' '}
                <a href="mailto:privacy@bikiran.com" className="purple">
                    privacy@bikiran.com
                </a>
                . In the alternative, you may contact us by either of the following means:
            </p>
            <ul className="address-area">
                <li className="list-disc value-title fs-16">
                    <p className="title">Office Address</p>
                    <p className="value">
                        <span className="title">Khulna</span>: Room 401, Sheikh Kamal IT Incubation
                        & Training Center KUET, KUET Road, Khulna 9203, Bangladesh
                    </p>
                    <p className="value">
                        <span className="title">Dhaka</span>: House-02, Block-F, Shangbadik Abashik
                        Elaka, Kalshi Road, Mirpur-11, Dhaka-1216, Bangladesh
                    </p>
                </li>
                <li className="list-disc value-title fs-16">
                    <p className="title">Phone Number</p>
                    <p className="value">
                        <span className="title">
                            <a href="tel:+880 1925 363333">+880 1925 363333</a>
                        </span>{' '}
                        <span className="title ml-2">
                            <a href="tel:+880 1616 000951">+880 1616 000951</a>
                        </span>
                    </p>
                </li>
            </ul>
        </div>
    );
}

function TermsAgreementSection() {
    return (
        <div className="policy-area-container container">
            <SectionTitleArea />
            <hr style={{ opacity: '40%' }} />
            <InfoArea />
            <TermsModification />
            <Eligibility />
            <Account />
            <AccountData />
            <RulesOfConduct />
            <DataProtecting />
            <UserContent />
            <UseOfUserContent />
            <AvailabilityOfService />
            <ProductCredits />
            <MonitoringOfContent />
            <DiscountServices />
            <BillingAndPayment />
            <AdditionalRights />
            <CustomerSupport />
            <DisclaimerOFWarranties />
            <LimitationOfLiability />
            <Indemnification />
            <LocalLaws />
            <USexportLaws />
            <GoverningLaw />
            <BeneficiaryRights />
            <Notices />
            <FinalAgreement />
            <AgencyRelationship />
            <AssignmentAndResale />
            <UnclaimedProperty />
            <ForceMajeure />
            <EnglishLangControl />
            <Headings />
            <ContactInfo />
        </div>
    );
}

export default TermsAgreementSection;
