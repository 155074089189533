/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    ButtonActionCpanel,
    ButtonActionDetails,
    ButtonActionDotMenu,
    ButtonActionReset,
    ButtonSolidPink
} from '../../../../../configs/Buttons';
import InstOption from '../../../../utils/InstOption';
import LayoutSelector from '../../../../utils/LayoutSelector';

function BtnRenew({ evOnClick }) {
    return (
        <LayoutSelector
            sm
            md
            webComp={<ButtonSolidPink evOnClick={evOnClick} title="Renew" />}
            mobileComp={<ButtonActionReset evOnClick={evOnClick} />}
        />
    );
}

function Options({ show, setShow, domain, setModal, item }) {
    const myRef = useRef();
    if (!show) {
        return null;
    }
    return (
        <div ref={myRef}>
            <InstOption show={show} setShow={setShow} myRef={myRef}>
                <button
                    hidden={!domain}
                    onClick={(e) => setModal({ type: 'cp-link', data: item }) + e.stopPropagation()}
                    type="button"
                    className="default-none w-100-i text-left no-wrap"
                >
                    <span>Login to cPanel</span>
                </button>
                <button
                    onClick={(e) => setModal({ type: 'message' }) + e.stopPropagation()}
                    type="button"
                    className="default-none w-100-i text-left no-wrap"
                >
                    <span>Renew</span>
                </button>
            </InstOption>
        </div>
    );
}

export function ButtonMobileView1({ domain, setModal, item }) {
    const [show, setShow] = useState(false);

    const handelDotClick = (event) => {
        event.stopPropagation();
        setShow(!show);
    };

    return (
        <div className="position-relative option-popup">
            <ButtonActionDotMenu evOnClick={handelDotClick} />
            <Options
                setShow={setShow}
                show={show}
                domain={domain}
                setModal={setModal}
                item={item}
            />
        </div>
    );
}

function ButtonMobileView({ setModal }) {
    const handelButtonClick = (ev) => {
        ev.stopPropagation();
        setModal({ type: 'message' });
    };

    return (
        <span className="button-action-sm">
            <ButtonSolidPink evOnClick={handelButtonClick} title="Renew" />
        </span>
    );
}

function ButtonWebView({ domain, id, setModal, item }) {
    return (
        <div className="line-align line-no-wrap">
            <span className={`${!domain ? 'disabled-view' : ''} btn-action-web`}>
                <ButtonActionCpanel evOnClick={() => setModal({ type: 'cp-link', data: item })} />
            </span>
            <Link to={`/user/services/hosting/${id}/`} className="ml-1 mr-1 btn-action-web">
                <ButtonActionDetails />
            </Link>
            <Link
                role="button"
                tabIndex={-1}
                onClick={() => setModal({ type: 'message' })}
                className="full-width"
                style={{ maxWidth: '55px' }}
            >
                <BtnRenew />
            </Link>
        </div>
    );
}

function TableActionBtn({ item, setModal }) {
    // const navigate = useNavigate();
    const { domain, id } = item || {};
    // const cName = isMobile ? 'btn-action' : 'btn-action-web';

    return (
        <>
            <LayoutSelector
                sm
                md
                webComp={<ButtonWebView domain={domain} id={id} setModal={setModal} item={item} />}
                mobileComp={
                    <ButtonMobileView domain={domain} id={id} setModal={setModal} item={item} />
                }
            />
        </>
    );
}

export default TableActionBtn;
