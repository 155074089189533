import { loginAreaIcons } from '../../../../../configs/Icons';
import { useSearch } from '../../../../contexts/SearchProvider';

function SearchIcon({ handleSearchIcon }) {
    const { isAppend } = useSearch();
    if (isAppend) {
        return null;
    }
    return (
        <div onClick={handleSearchIcon} className="icon-search" role="button" tabIndex={-1}>
            <div className="line-align line-md-none">
                <img src={loginAreaIcons.iconSearchM} alt="icon search" />
            </div>
        </div>
    );
}

export default SearchIcon;
