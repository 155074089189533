/* eslint-disable jsx-a11y/label-has-associated-control */
import { useRef, useState } from 'react';
import {
    ButtonClose,
    ButtonCloseMinus,
    ButtonGr,
    ButtonOutlinePink
} from '../../../../../configs/Buttons';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import countries from '../../../../utils/country.json';
import { useAddress } from '../ProfileAddressProvider';
import FormatAddress from '../_models/FormatAddress';

const ModalHeader = ({ setModalData }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title">
                <span>Update Address</span>
            </div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={() => setModalData({ type: null })} />
            </div>
        </div>
    </div>
);

function TelephoneField({ refName, setMode, mode, val }) {
    if (mode.tel && mode.fax) {
        return (
            <div className="cell cell-6 field-active">
                <div className="line-row-between">
                    <div>
                        <label htmlFor="telephone">Telephone</label>
                    </div>
                    <div className="field-close-area">
                        <ButtonCloseMinus
                            evOnClick={() => setMode({ tel: false, fax: mode.fax })}
                        />
                    </div>
                </div>
                <input
                    ref={refName}
                    name="telephone"
                    placeholder="Enter Telephone"
                    type="text"
                    className="form-input w-100"
                    defaultValue={val || ''}
                />
            </div>
        );
    }
    return (
        <div className={mode.tel ? 'cell cell-12 field-active' : 'cell cell-12 field-inactive'}>
            <div className="line-row-between">
                <div>
                    <label htmlFor="telephone">Telephone</label>
                </div>
                <div className="field-close-area">
                    <ButtonCloseMinus evOnClick={() => setMode({ tel: false, fax: mode.fax })} />
                </div>
            </div>
            <input
                ref={refName}
                name="telephone"
                placeholder="Enter Telephone"
                type="text"
                className="form-input w-100"
                defaultValue={val || ''}
            />
        </div>
    );
}
function FaxField({ refName, setMode, mode, val }) {
    if (mode.tel && mode.fax) {
        return (
            <div className="cell cell-6 field-active">
                <div className="line-row-between">
                    <div>
                        <label htmlFor="fax">Fax</label>
                    </div>
                    <div className="field-close-area">
                        <ButtonCloseMinus
                            evOnClick={() => setMode({ tel: mode.tel, fax: false })}
                        />
                    </div>
                </div>
                <input
                    ref={refName}
                    name="fax"
                    placeholder="Enter Fax"
                    type="text"
                    className="form-input w-100"
                    defaultValue={val || ''}
                />
            </div>
        );
    }
    return (
        <div className={mode.fax ? 'cell cell-12 field-active' : 'cell cell-12 field-inactive'}>
            <div className="line-row-between">
                <div>
                    <label htmlFor="fax">Fax</label>
                </div>
                <div className="field-close-area">
                    <ButtonCloseMinus evOnClick={() => setMode({ tel: mode.tel, fax: false })} />
                </div>
            </div>
            <input
                ref={refName}
                name="fax"
                placeholder="Enter Fax"
                type="text"
                className="form-input w-100"
                defaultValue={val || ''}
            />
        </div>
    );
}

function ModalBody({ setModalData, cuData }) {
    const [mode, setMode] = useState({ tel: false, fax: false });
    const { updateAddress } = useAddress();
    const { setMessage } = useTemplate();

    const nameRef = useRef();
    const orgRef = useRef();
    const emailRef = useRef();
    const mobileRef = useRef();
    const telephoneRef = useRef();
    const faxRef = useRef();
    const line1Ref = useRef();
    const countryRef = useRef();
    const stateRef = useRef();
    const cityRef = useRef();
    const zipRef = useRef();

    const handleFormData = () => {
        const name = nameRef.current.value || '';
        const organization = orgRef.current.value || '';
        const email = emailRef.current.value || '';
        const mobile = mobileRef.current.value || '';
        const telephone = telephoneRef.current.value || '';
        const fax = faxRef.current.value || '';
        const line1 = line1Ref.current.value || '';
        const country = countryRef.current.value || '';
        const state = stateRef.current.value || '';
        const city = cityRef.current.value || '';
        const zip = zipRef.current.value || '';

        const result = FormatAddress(
            name,
            organization,
            email,
            mobile,
            telephone,
            fax,
            line1,
            country,
            state,
            city,
            zip
        );
        setMessage('Updating Address..');
        updateAddress(cuData?.id, result.address, cuData?.hash).then(({ error, message }) => {
            setMessage(message);
            if (error === 0) {
                setModalData({
                    type: null,
                    data: {}
                });
            }
        });
    };

    const { name, organization, email, mobile, telephone, fax, line1, country, state, city, zip } =
        cuData;

    // console.log(cuData, 'cudata');
    return (
        <div className="modal-body-area overflow-auto">
            <div className="line-row-between line-g2">
                <div className="cell cell-6 cell-sm-12">
                    <label htmlFor="fullname">Full Name</label>
                    <input
                        ref={nameRef}
                        name="fullname"
                        placeholder="Enter Full Name"
                        type="text"
                        className="form-input w-100"
                        defaultValue={name || ''}
                    />
                </div>
                <div className="cell cell-6 cell-sm-12">
                    <label htmlFor="organization">Organization</label>
                    <input
                        ref={orgRef}
                        name="organization"
                        placeholder="Enter Organization"
                        type="text"
                        className="form-input w-100"
                        defaultValue={organization || ''}
                    />
                </div>
                <div className="cell cell-6 cell-sm-12">
                    <label htmlFor="email">Email</label>
                    <input
                        ref={emailRef}
                        name="email"
                        placeholder="Enter Email"
                        type="text"
                        className="form-input w-100"
                        defaultValue={email || ''}
                    />
                </div>
                <div className="cell cell-6 cell-sm-12">
                    <div className="line-row-between line-no-wrap">
                        <div>
                            <label htmlFor="mobile">Mobile</label>
                        </div>
                        <div className="contact-btn-area cell-6 line justify-end ">
                            <span
                                onClick={() => setMode({ tel: true, fax: !!mode.fax })}
                                role="button"
                                tabIndex={-1}
                                title="Add Telephone"
                                className={`tel-icon ${mode.tel ? 'active' : ''}`}
                            >
                                +
                            </span>
                            <span
                                onClick={() => setMode({ fax: true, tel: !!mode.tel })}
                                role="button"
                                tabIndex={-1}
                                title="Add Fax"
                                className={`fax-icon ml-1 ${mode.fax ? 'active' : ''}`}
                            >
                                +
                            </span>
                        </div>
                    </div>
                    <input
                        ref={mobileRef}
                        name="mobile"
                        placeholder="Enter Mobile"
                        type="text"
                        className="form-input w-100"
                        defaultValue={mobile || ''}
                    />
                </div>
                <TelephoneField
                    setMode={setMode}
                    refName={telephoneRef}
                    mode={mode}
                    val={telephone}
                />

                <FaxField setMode={setMode} refName={faxRef} mode={mode} val={fax} />
                <div className="cell cell-12">
                    <label htmlFor="line1">Address Line</label>

                    <textarea
                        ref={line1Ref}
                        defaultValue={line1 || ''}
                        name="line1"
                        placeholder="Enter Address Line-1"
                        className="form-input w-100"
                        id=""
                        cols="100"
                        rows="3"
                    />
                </div>

                <div className="cell cell-6 cell-sm-12">
                    <label htmlFor="country">Country</label>
                    <select
                        defaultValue={country || ''}
                        ref={countryRef}
                        name="country"
                        className="form-input"
                    >
                        <option value="">Select</option>
                        {countries.map((item) => (
                            <option key={item?.code} value={item?.code || ''}>
                                {item?.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="cell cell-6 cell-sm-12">
                    <label htmlFor="state">State</label>
                    <input
                        ref={stateRef}
                        name="state"
                        placeholder="Enter State"
                        type="text"
                        className="form-input w-100"
                        defaultValue={state || ''}
                    />
                </div>
                <div className="cell cell-6 cell-sm-12">
                    <label htmlFor="city">City</label>
                    <input
                        ref={cityRef}
                        name="city"
                        placeholder="Enter City"
                        type="text"
                        className="form-input w-100"
                        defaultValue={city || ''}
                    />
                </div>
                <div className="cell cell-6 cell-sm-12">
                    <label htmlFor="zip">Zip</label>
                    <input
                        ref={zipRef}
                        name="zip"
                        placeholder="Enter Zip"
                        type="text"
                        className="form-input w-100"
                        defaultValue={zip || ''}
                    />
                </div>
            </div>
            <div className="w-100 text-right mt-3">
                <span>
                    <ButtonOutlinePink
                        evOnClick={() => {
                            setModalData(() => ({
                                type: null,
                                data: {}
                            }));
                        }}
                        title="Cancel"
                        modal
                    />
                </span>
                <span className="ml-2">
                    <ButtonGr evOnClick={handleFormData} title="Save" modal />
                </span>
            </div>
        </div>
    );
}

function UpdateAddressModal({ show, setModalData, modalData }) {
    const cuData = modalData?.data?.data;
    if (!show || show === null) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content user-address-modal-area">
                <ModalHeader setModalData={setModalData} />
                <ModalBody cuData={cuData} setModalData={setModalData} />
            </div>
        </div>
    );
}

export default UpdateAddressModal;
