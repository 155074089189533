import React, { useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { dashboardIcons, icons } from '../../../../configs/Icons';

import { useLayout } from '../../../contexts/LayoutProvider';
import MobileFooterModal from '../../../utils/MobileFooterModal';
import SidebarMenuSection from './SidebarMenuSection';

const menuList = [
    {
        id: 1,
        title: 'Home',
        url: '/',
        img: icons.iconHomePurple,
        imgInactive: icons.iconHomeBlack
    },
    {
        id: 2,
        title: 'Services',
        url: '/',
        img: icons.iconServicePurple,
        imgInactive: icons.iconServiceBlack
    },
    {
        id: 3,
        title: 'Dashboard',
        url: '/user/dashboard/',
        img: icons.iconDashboardActive,
        imgInactive: dashboardIcons.iconDashboardV4
    },
    {
        id: 4,
        title: 'Cart',
        url: '/cart/',
        img: icons.iconCartPurple,
        imgInactive: icons.iconCartBlack
    },
    {
        id: 5,
        title: 'Profile',
        url: '/user/profile-overview/',
        img: icons.iconUserPurple,
        imgInactive: icons.iconUserBlack
    }
];

function MenuItem({ data, showServiceOption, pathname, handleClickOption }) {
    const navigate = useNavigate();
    const isActive =
        showServiceOption?.optionType === data?.title.toLowerCase() || pathname === data?.url;
    const logo = isActive ? data?.img : data?.imgInactive;

    const handelMenuClick = (ev) => {
        handleClickOption();
        if (ev) {
            if (data?.id === 2 || data?.id === 5) {
                ev.preventDefault();
            } else {
                navigate(data?.url);
            }
            handleClickOption(data?.id);
        }
    };
    const cName =
        showServiceOption?.optionType === data?.title.toLowerCase() || pathname === data?.url
            ? 'active position-relative'
            : '';
    return (
        <div
            key={data?.id}
            onClick={handelMenuClick}
            role="button"
            tabIndex={-1}
            className={`menu-item ${cName}`}
        >
            <div className="line-col-center h-100 position-relative">
                <div className="menu-logo">
                    <img className="w-100 h-100" src={logo} alt="" />
                </div>
                <span className="title">{data?.title}</span>
            </div>
        </div>
    );
}

function MobileFooterMenu({ showServiceOption, handleClickOption, pathname, myRef }) {
    return (
        <div ref={myRef}>
            <div className="footerv2-container">
                <div className="footer-menu">
                    <div className="line-row-between line-no-wrap w-100 h-100">
                        {menuList.map((data) => (
                            <MenuItem
                                key={data?.id}
                                data={data}
                                showServiceOption={showServiceOption}
                                pathname={pathname}
                                handleClickOption={handleClickOption}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

function MobileFooter({ support }) {
    const { windowSizeName: sizeName } = useLayout();
    const [showServiceOption, setShowServiceOption] = useState({ optionType: '' });

    const { pathname } = useLocation();

    if (['xs', 'sm', 'md', 'lg'].indexOf(sizeName) === -1) {
        return null;
    }

    const handleClickOption = (id) => {
        if (id === 1) {
            return setShowServiceOption({ optionType: 'home' });
        }
        if (id === 2) {
            return setShowServiceOption({ optionType: 'services' });
        }
        if (id === 3) {
            return setShowServiceOption({ optionType: 'dashboard' });
        }
        if (id === 4) {
            return setShowServiceOption({ optionType: 'cart' });
        }
        if (id === 5) {
            return setShowServiceOption({ optionType: 'profile' });
        }

        return null;
    };

    return (
        <>
            {/* <div className="admin-menu-mobile-gap" /> */}
            <MobileFooterModal
                show={
                    showServiceOption?.optionType === 'profile' ||
                    showServiceOption?.optionType === 'services'
                }
                setShow={setShowServiceOption}
            >
                <div className="footer-menu-area">
                    <SidebarMenuSection
                        support={support}
                        showServiceOption={showServiceOption}
                        hideProfile
                        clickOnEndItem={() => {
                            setShowServiceOption(null);
                        }}
                    />
                </div>
            </MobileFooterModal>
            <MobileFooterMenu
                handleClickOption={handleClickOption}
                showServiceOption={showServiceOption}
                pathname={pathname}
                // myRef={myRef}
            />
            {/* <div className="admin-menu-mobile">
                <div className="admin-menu-mobile" style={{ zIndex: '1' }}>
                    <div className="menu-area">
                        {menuList?.map((item) =>
                            item?.id !== 3 ? (
                                <div key={item?.id}>
                                    <Link
                                        to={item?.url}
                                        onClick={(ev) => {
                                            if (item?.id === 2 || item?.id === 5) {
                                                ev.preventDefault();
                                            }
                                            handleClickOption(item?.id);
                                        }}
                                    >
                                        <img
                                            src={
                                                showServiceOption?.optionType ===
                                                    item?.title.toLowerCase() ||
                                                pathname === item?.url
                                                    ? item?.img
                                                    : item?.imgInactive
                                            }
                                            alt="icons"
                                        />
                                        <span
                                            className={
                                                showServiceOption?.optionType ===
                                                    item?.title.toLowerCase() ||
                                                pathname === item?.url
                                                    ? 'menu-active'
                                                    : null
                                            }
                                        >
                                            {item?.title}
                                        </span>
                                    </Link>
                                </div>
                            ) : (
                                <div key={item?.id} className="footer-dashboard-option">
                                    <Link
                                        to="/user/dashboard/"
                                        style={{ position: 'relative' }}
                                        onClick={() => {
                                            handleClickOption(item?.id);
                                        }}
                                    >
                                        <div className="test-menu">
                                            <img
                                                src={
                                                    showServiceOption?.optionType ===
                                                        item?.title.toLowerCase() ||
                                                    pathname === item?.url
                                                        ? item?.img
                                                        : item?.imgInactive
                                                }
                                                alt="Dashboard"
                                            />
                                        </div>
                                        <span
                                            className={
                                                showServiceOption?.optionType ===
                                                    item?.title.toLowerCase() ||
                                                pathname === item?.url
                                                    ? 'menu-active'
                                                    : null
                                            }
                                            style={{ position: 'relative', top: '-27px' }}
                                        >
                                            Dashboard
                                        </span>
                                    </Link>
                                </div>
                            )
                        )} */}
            {/* <div>
                            <Link
                                to="/"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setShowServiceOption({ optionType: 'services' });
                                }}
                            >
                                <img src={footerIcons.iconSubscription} alt="Subscription" />
                                <span>Services</span>
                            </Link>
                        </div>
                        <div className="footer-dashboard-option">
                            <Link to="/user/dashboard/" style={{ position: 'relative' }}>
                                <div className="test-menu">
                                    <img src={dashboardIcons.iconDashboard} alt="Dashboard" />
                                </div>
                                <span style={{ position: 'relative', top: '-27px' }}>
                                    Dashboard
                                </span>
                            </Link>
                        </div>
                        <div>
                            <Link to="/cart/">
                                <img src={footerIcons.iconCart} alt="Support" />

                                <span>Cart</span>
                            </Link>
                        </div>
                        <div>
                            <Link
                                to="/user/profile/"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setShowServiceOption({ optionType: 'profile' });
                                }}
                            >
                                <img src={userIcons.iconUserV4} alt="Profile" />
                                <span>Profile</span>
                            </Link>
                        </div> */}
            {/* </div>
                </div>
            </div> */}
        </>
    );
}

export default MobileFooter;
