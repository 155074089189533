import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonGr } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useLayout } from '../../../../contexts/LayoutProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';
import PageProperties from '../../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../../headers/DashboardHeaderSection';
import InvoiceInfoTable from '../invoice_info_table/InvoiceInfoTable';
import BillingsInvoiceUserInfoArea from './BillingsInvoiceUserInfoArea';

const HeaderButton = ({ navigate }) => (
    <div className="line justify-end print-none">
        <span className="mr-2 d-sm-none">
            <ButtonGr
                evOnClick={() => {
                    navigate('/user/billing/invoice/');
                }}
                title="Back to list"
            />{' '}
        </span>
        <span className="">
            <ButtonGr evOnClick={() => window.print()} title="Print" />
        </span>
    </div>
);

function BillingsInvoiceInfoPage() {
    const [invoiceData, setInvoiceData] = useState(null);
    const { currentUser } = useAuth();
    const { invoiceId } = useParams();

    const navigate = useNavigate();
    const { windowSizeName } = useLayout();

    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .setApiUrl('API_BILLING_INVOICE', `/details/${invoiceId}/`)
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    setInvoiceData(data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentUser, invoiceId]);

    useEffect(() => {
        PageProperties(`Invoice \u2022 Billing`);
    }, []);

    // if (invoiceData === null) {
    //     return null;
    // }

    return (
        <div className="content-area">
            <DashboardDefaultHeader
                title={`Invoice #${invoiceData?.data?.id}`}
                path={-1}
                content={<HeaderButton navigate={navigate} />}
                mobileContent={<HeaderButton navigate={navigate} />}
            />

            <div className="content-body">
                <div className="content-body-scroll">
                    <div className="admin-invoice-area">
                        <BillingsInvoiceUserInfoArea invoiceInfo={invoiceData} />

                        <InvoiceInfoTable
                            invoiceData={invoiceData}
                            setInvoiceData={setInvoiceData}
                            items={invoiceData?.data?.items}
                            total={invoiceData?.data?.amountBilled}
                            discount={invoiceData?.data?.amountDiscount}
                            paid={invoiceData?.data?.amountPaid}
                            payable={invoiceData?.data?.amountPayable}
                            windowSizeName={windowSizeName}
                        />

                        {invoiceData && invoiceData?.data?.statusPayment !== 'paid' ? (
                            <div className="text-right">
                                <ButtonGr
                                    evOnClick={() => {
                                        navigate(`/payment/${invoiceData?.data?.id}/`);
                                    }}
                                    title="Pay Now"
                                />
                            </div>
                        ) : null}

                        <div hidden={!invoiceData} className="paid-sign">
                            {invoiceData?.data?.statusPayment === 'paid' ? (
                                <img src={icons.iconSignPaid} alt="Paid" />
                            ) : (
                                <img src={icons.iconSignUnpaid} alt="Unpaid" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BillingsInvoiceInfoPage;
