import React from 'react';
import { ButtonClose } from '../../configs/Buttons';

function ModalBox({ show, handleCloseClick, header, body /*  footer */ }) {
    if (show !== true) {
        return null;
    }

    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <div className="modal-box-header">
                    <div>
                        {header}
                        {handleCloseClick ? (
                            <div className="mr-5">
                                <ButtonClose evOnClick={handleCloseClick} />
                            </div>
                        ) : // <button
                        //     className="button squire modal-box-close"
                        //     type="button"
                        //     onClick={handleCloseClick}
                        // >
                        //     <img src={iconsModal.iconCloseModal} alt="Close" />
                        // </button>
                        null}
                    </div>
                </div>
                <div className="modal-box-body">
                    <div>{body}</div>
                </div>
                {/* <div className="modal-box-footer">
                    <div>{footer}</div>
                </div> */}
            </div>
        </div>
    );
}

export default ModalBox;
