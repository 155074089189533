import { icons } from '../../../../../configs/Icons';

const items = [
    {
        title: 'Filter your numbers',
        img: icons.iconFltNum
    },
    {
        title: 'Remove Duplicates',
        img: icons.iconDupNum
    },
    {
        title: 'Create Number Database',
        img: icons.iconNumDt
    }
];

function NumberManageSection() {
    return (
        <div className="container number-manage-area mb-120">
            <div className="line-align mr-sm-5 ml-sm-5 line-g3">
                {items?.map((item) => (
                    <div key={Math.random()} className="cell cell-4 cell-lg-12 mb-3">
                        <div className="manage-item line-align line-no-wrap">
                            <div className="item-logo ">
                                <img className="" src={item?.img} alt="icon" />
                            </div>
                            <div className="ml-5">
                                <span className="font-20 pink">{item?.title}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default NumberManageSection;
