import { useState } from 'react';
import { ButtonClose } from '../../../../../configs/Buttons';
import CustomDropdown from '../../../websites/models/CustomDropdown';
import TabContentController from './dns_record_tab_content/TabContentController';

// const menu = ['A', 'AAAA', 'CNAME', 'MX', 'NS', 'PTR', 'SPF', 'SRV', 'TXT', 'SOA', 'CAA'];

function SectionDivider({ title, dropdown, action }) {
    return (
        <div className="line align-start line-no-wrap line-g1 mb-2">
            <div className="cell cell-fill">
                <div className="divider-title">
                    <div className="line-align">
                        <span className="mr-3">{title}</span>
                        {dropdown}
                    </div>
                </div>
            </div>

            {action && (
                <div className="cell">
                    <span className="divider-action">{action}</span>
                </div>
            )}
        </div>
    );
}

// function TabRow({ types, setTabItem, tabItem }) {
//     const { setFormData } = useDnsInfo();

//     const handelTabClick = (data) => {
//         setTabItem(data);
//         setFormData({});
//     };
//     return (
//         <div className="tabs-menu overflow-auto">
//             <div className="line-align line-no-wrap  ">
//                 {types?.map((data) => (
//                     <div
//                         role="button"
//                         tabIndex={-1}
//                         onClick={() => handelTabClick(data)}
//                         key={data}
//                         className="cell"
//                     >
//                         <span className={`tab ${tabItem === data ? 'active' : ''}`}>{data}</span>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// }

function DnsRecordArea({ evCloseClick, isActive, types }) {
    const [tabItem, setTabItem] = useState('A');

    // console.log(tabItem);
    return (
        <div
            className={`product-info add-record-area mb-4 ${
                isActive ? 'view-active' : 'view-inactive'
            }`}
        >
            <SectionDivider
                line="none"
                title="Create new record"
                dropdown={
                    <CustomDropdown items={types} onSelect={setTabItem} selectedItem={tabItem} />
                }
                action={<ButtonClose evOnClick={evCloseClick} />}
            />
            {/* <TabRow types={types} tabItem={tabItem} setTabItem={setTabItem} /> */}
            <TabContentController tabItem={tabItem} />
        </div>
    );
}

export default DnsRecordArea;
