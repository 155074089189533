import { useRef, useState } from 'react';
import { icons } from '../../../../../configs/Icons';

import AddressListTableActionBtn from './AddressListTableActionBtn';

function TableBody({ setModalData, addressList, demoArr }) {
    const [showOptions, setShowOptions] = useState(false);
    const ref = useRef();

    const arr = addressList || demoArr;
    return (
        <tbody className={!addressList ? 'placeholder-mode' : ''}>
            {arr?.map((data) => (
                <tr key={data?.id}>
                    <td colSpan={2}>
                        <div className="line line-no-wrap">
                            {data?.isDefault && (
                                <div className="primary-icon">
                                    <span>
                                        <img src={icons.iconP} alt="icon primary" />
                                    </span>
                                </div>
                            )}
                            <div className="ml-1">
                                <span className="d-block gap no-wrap">{data.name}</span>
                                <small className="table-sub-text-op d-block">
                                    <span>{data?.mobile}</span>
                                </small>
                            </div>
                        </div>
                    </td>
                    <td colSpan={3}>
                        <span>{data?.line1 || data?.line2 || data?.line3 || '--'}</span>
                    </td>
                    <td colSpan={3}>
                        <span>{data?.email}</span>
                    </td>
                    <td colSpan={1}>
                        <AddressListTableActionBtn
                            setModalData={setModalData}
                            setShowOptions={setShowOptions}
                            showOptions={showOptions}
                            myRef={ref}
                            data={data}
                        />
                    </td>
                </tr>
            ))}
        </tbody>
    );
}

function AddressListTableWeb({ addressList, setModalData, demoArr }) {
    return (
        <table className="table-list cell-sm-12" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th colSpan={2} width="21%">
                        Name
                    </th>
                    <th colSpan={3} width="38%">
                        Address
                    </th>
                    <th colSpan={3}>Email</th>
                    <th colSpan={1} width="10.5%">
                        Action
                    </th>
                </tr>
            </thead>
            <TableBody addressList={addressList} setModalData={setModalData} demoArr={demoArr} />
        </table>
    );
}

export default AddressListTableWeb;
