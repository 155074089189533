import { ButtonGr } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';

function WarningText({ error }) {
    return (
        <>
            <div className="line-row-center align-unset mb-4">
                <img className="d-block" src={icons.iconWarningLine} alt="" />
            </div>
            <div className="clr">
                <span className="font-16 red fw-500">{error?.message}</span>
            </div>
        </>
    );
}

function ModalBody({ setModal, error }) {
    // const error = 'this is demo message';

    return (
        <div className="modal-body-area text-center mb-2 mt-2">
            <WarningText error={error} />
            <div className="mt-4">
                <ButtonGr evOnClick={() => setModal({ type: null })} title="Ok" modal />
            </div>
        </div>
    );
}

function RoleErrorPopup({ show, modal, setModal, error }) {
    if (!show || !modal?.type) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <ModalBody setModal={setModal} error={error} />
            </div>
        </div>
    );
}

export default RoleErrorPopup;
