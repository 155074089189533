function ProfileInfoSocialSection() {
    return (
        <div className="line-align line-g1">
            <div className="cell cell-sm-6">
                <div className="common-social-btn mb-2 google">
                    <span className="cell-2" /> <span className="cell-fill">Google</span>{' '}
                </div>
            </div>
            <div className="cell cell-sm-6">
                <div className="common-social-btn mb-2 facebook">
                    <span className="cell-2" /> <span className="cell-fill">Facebook</span>{' '}
                </div>
            </div>
            <div className="cell cell-sm-6">
                <div className="common-social-btn mb-2 whatsapp">
                    <span className="cell-2" /> <span className="cell-fill">Whatsapp</span>{' '}
                </div>
            </div>
        </div>
    );
}

export default ProfileInfoSocialSection;
