import React, { useEffect } from 'react';

import PageProperties from '../../utils/PageProperties';
import HeaderSection from '../headers/HeaderSection';
import FooterSection2 from '../system/section/Footer2Section';
import FixedHeaderControl from '../websites/components/FixedHeaderControl';
import PaymentSection from './comps/PaymentSection';
import PaymentSectionHeader from './comps/PaymentSectionHeader';

function PaymentPage() {
    useEffect(() => {
        PageProperties('Payment');
    }, []);

    return (
        <>
            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>

            <PaymentSectionHeader />

            <PaymentSection />

            <FooterSection2 />
            {/* <MobileFooter /> */}
        </>
    );
}

export default PaymentPage;
