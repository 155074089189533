import { ButtonActionDetails } from '../../../../../configs/Buttons';
import { UpperCase } from '../../../../utils/Show';
import InfoStatusHandler from '../../../users/models/InfoStatusHandler';

function Body({ invoiceList, navigate }) {
    return invoiceList?.map((item) => (
        <tbody key={item?.id} className={item?.isDemo ? 'placeholder-mode' : ''}>
            <tr>
                <td>
                    <span>#{item?.id}</span>{' '}
                </td>
                <td>
                    {' '}
                    <span>{item?.dateDue}</span>{' '}
                </td>
                <td>
                    {' '}
                    <span>{item?.items}</span>{' '}
                </td>
                <td>
                    {' '}
                    <span>{item?.priceOffer?.toFixed(2)}</span>{' '}
                </td>
                <td>
                    {' '}
                    <span>{item?.paidAmount?.toFixed(2)}</span>{' '}
                </td>
                <td>
                    {' '}
                    <span style={{ color: InfoStatusHandler(item) }}>
                        {UpperCase(item?.status)}
                    </span>{' '}
                </td>
                <td>
                    <span className="btn-action-web">
                        <ButtonActionDetails
                            evOnClick={() => {
                                navigate(`/user/billing/invoice/${item?.id}/`);
                            }}
                        />
                    </span>
                </td>
            </tr>
        </tbody>
    ));
}

function InvoiceListWeb({ invoiceList, navigate }) {
    return (
        <table className="table-list" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th width={100}>#</th>
                    <th width={130}>Due Date</th>
                    <th width={100}>Items</th>
                    <th width={100}>Amount</th>
                    <th width={100}>Paid</th>
                    <th width={100}>Status</th>
                    <th width="8%">Action</th>
                </tr>
            </thead>
            <Body invoiceList={invoiceList} navigate={navigate} />
        </table>
    );
}

export default InvoiceListWeb;
