import React, { useState } from 'react';
import { BtnProfileEdit, ButtonCancel } from '../../../../../configs/Buttons';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import { useBasicInfo } from '../UserBasicInfoProvider';

const compId = 'editGender';
const genders = [
    {
        id: 'male',
        title: 'Male'
    },
    {
        id: 'female',
        title: 'Female'
    },
    {
        id: 'other',
        title: 'Other'
    }
];

const EditView = ({
    currentUser,
    formData,
    setFormData,
    handelExitEdit,
    handelSubmitForm,
    mode
}) => {
    const handleUpdate = (ev) => {
        setFormData((cuData) => ({ ...cuData, gender: ev.target.value }));
    };

    return (
        <div className={`edit-view-area ${mode === compId ? 'show' : ''}`}>
            <div>
                <div>
                    <div className="mb-2">
                        <span className="profile-subject">Gender:</span>
                    </div>
                    <div className="mb-1">
                        <select
                            onChange={handleUpdate}
                            value={formData.gender}
                            name="gender"
                            className="form-input w-100"
                        >
                            <option value="">Select Gender</option>
                            {genders.map((item) => (
                                <option value={item.id} key={item.id}>
                                    {item.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="line line-sm-no-wrap text-start note-text">
                        {/* <span className="value fw-500">Note:</span>
                    &nbsp;
                    <span className="value fw-400">Change your --</span> */}
                    </div>
                </div>
                <div className="line justify-end ">
                    <ButtonCancel evOnClick={handelExitEdit} title="Cancel" />

                    <button
                        onClick={handelSubmitForm}
                        type="button"
                        className="default-none btn-gr btn-gr-web ml-2"
                        disabled={currentUser?.gender === formData.gender}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

function ProfileGender({ currentUser }) {
    const { setMessage } = useTemplate();
    const { updateGender, mode, setMode } = useBasicInfo();
    const [formData, setFormData] = useState({
        gender: currentUser?.gender || ''
    });

    const handelClickEdit = () => {
        setMode(compId);
    };

    const handelExitEdit = () => {
        setMode(null);
    };

    const handelSubmitForm = () => {
        setMessage('Updating gender ...');
        updateGender(formData)
            .then(({ message }) => {
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    return (
        <>
            <div className={`line default-view-area ${mode === compId ? 'border-none' : ''}`}>
                <div className="cell cell-self-middle cell-3 cell-md-4 od-opacity">
                    <span className="profile-subject">Gender:</span>
                </div>
                <div className="cell-fill cell-self-middle od-opacity">
                    <span className="value">
                        {currentUser?.gender
                            ? genders.find((item) => item.id === currentUser.gender)?.title
                            : '--'}
                    </span>
                </div>
                <div className="cell cell-self-middle od-opacity">
                    <BtnProfileEdit evOnClick={handelClickEdit} isDisabled={!!mode} />
                </div>
            </div>
            <EditView
                currentUser={currentUser}
                formData={formData}
                setFormData={setFormData}
                handelExitEdit={handelExitEdit}
                handelSubmitForm={handelSubmitForm}
                mode={mode}
            />
        </>
    );
}

export default ProfileGender;
