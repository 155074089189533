import {
    FacebookAuthProvider,
    GithubAuthProvider,
    GoogleAuthProvider,
    signInWithPopup
} from 'firebase/auth';
import { createContext, useContext, useEffect, useState } from 'react';
import { firebaseAuth } from '../../../MyFirebase';
import AxiosAuth from '../../../utils/AxiosAuth';

const ContactContext = createContext();

export function useContact() {
    return useContext(ContactContext);
}

function ContactManageProvider({ children, currentUser, recheckLogin }) {
    const [contactList, setContactList] = useState(null);
    const [listKey, setListKey] = useState(null);

    const sendEmailOtp = (formData) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('PROFILE', 'contact/email/send-otp')
                .post(formData)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const addEmailContact = (formData) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('PROFILE', 'contact/email/verify-and-link')
                .post(formData)
                .then(({ data }) => {
                    if (data.error === 0 || data.action === 'recheck-login') {
                        recheckLogin();
                    }

                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const sendPhoneOtp = (formData) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('PROFILE', 'contact/phone/send-otp')
                .post(formData)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const addPhoneContact = (formData) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('PROFILE', 'contact/phone/verify-and-link')
                .post(formData)
                .then(({ data }) => {
                    if (data.error === 0 || data.action === 'recheck-login') {
                        recheckLogin();
                    }

                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const addGoogleContact = () => {
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
        provider.addScope('https://www.googleapis.com/auth/userinfo.email');
        provider.addScope('profile');
        provider.addScope('email');
        provider.addScope('openid');

        return new Promise((resolve, reject) => {
            signInWithPopup(firebaseAuth, provider)
                .then(({ user }) => {
                    AxiosAuth.currentUserAuth(currentUser)
                        .setApiUrl('PROFILE', 'contacts/add')
                        .post({ userUid: user?.uid })
                        .then(({ data }) => {
                            if (data.error === 0 || data.action === 'recheck-login') {
                                recheckLogin();
                            }

                            resolve(data);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    const addFacebookContact = () => {
        const provider = new FacebookAuthProvider();
        provider.addScope('public_profile');
        provider.addScope('email');

        return new Promise((resolve, reject) => {
            signInWithPopup(firebaseAuth, provider)
                .then(({ user }) => {
                    AxiosAuth.currentUserAuth(currentUser)
                        .setApiUrl('PROFILE', 'contacts/add')
                        .post({ userUid: user?.uid })
                        .then(({ data }) => {
                            if (data.error === 0 || data.action === 'recheck-login') {
                                recheckLogin();
                            }

                            resolve(data);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    const addGithubContact = () => {
        const provider = new GithubAuthProvider();
        provider.addScope('user:email');

        return new Promise((resolve, reject) => {
            signInWithPopup(firebaseAuth, provider)
                .then(({ user }) => {
                    AxiosAuth.currentUserAuth(currentUser)
                        .setApiUrl('PROFILE', 'contacts/add')
                        .post({ userUid: user?.uid })
                        .then(({ data }) => {
                            if (data.error === 0 || data.action === 'recheck-login') {
                                recheckLogin();
                            }

                            resolve(data);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    const removeContact = (id, userUid) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('PROFILE', 'contacts/remove')
                .post({ id, userUid })
                .then(({ data }) => {
                    if (data.error === 0) {
                        setListKey(Math.random());
                    }

                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    // type = email/phone
    const makeAsPrimary = (id, userUid, type) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('PROFILE', 'contacts/make-primary')
                .post({ id, userUid, type })
                .then(({ data }) => {
                    if (data.error === 0) {
                        setListKey(Math.random());
                    }

                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const reloadList = () => {
        setListKey(Math.random());
    };

    // --load list
    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .setApiUrl('PROFILE', 'contacts/list')
            .get({ listKey })
            .then(({ data }) => {
                if (data.error === 0) {
                    setContactList(data.data);
                } else {
                    setContactList([]);
                }
            });
    }, [currentUser, listKey]);

    const value = {
        contactList,
        setContactList,
        sendEmailOtp,
        addEmailContact,
        sendPhoneOtp,
        addPhoneContact,
        addGoogleContact,
        addFacebookContact,
        addGithubContact,
        removeContact,
        makeAsPrimary,
        reloadList
    };
    return <ContactContext.Provider value={value}>{children}</ContactContext.Provider>;
}

export default ContactManageProvider;
