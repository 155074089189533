// import balanceBg from '../../../../assets/images/balance-card.png';
import { useEffect } from 'react';
import { ButtonGr } from '../../../../configs/Buttons';
import PageProperties from '../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../headers/DashboardHeaderSection';
import BalanceHistorySection from '../components/BalanceHistorySection';

function ContentHead() {
    return (
        <DashboardDefaultHeader
            title="My Bikiran Balance"
            path={-1}
            content={
                <div className="line justify-end">
                    <ButtonGr title="Earn Points" />
                </div>
            }
        />
    );
}

function BannerArea({ balance }) {
    return (
        <div className="bik-balance-bg">
            <div className="bik-balance-container">
                <div className="line-align">
                    <div className="cell d-md-none">
                        <div className="balance-left-content">
                            <h4 className="fs-25 mb-1">Christopher Henry’s</h4>
                            <h3 className="font-36 mt-0">Bikiran Balance</h3>
                        </div>
                    </div>
                    <div className="cell-fill">
                        <div className="balance-area">
                            <div className="text-area mr-5">
                                <h4 className="font-14 mt-0 mb-2">Your Bikiran Balance</h4>
                                <div className="line-row-center">
                                    <div className="line-align">
                                        <small className="fz-16 mt-0 mb-0">TK.</small>
                                        <h2 className="font-36 mt-0 mb-0 ammount">
                                            {balance || 0}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

// function BannerArea2() {
//     return (
//         <div className="bik-balance-bg-2">
//             <div className="line-row-between line-g2 line-no-wrap line-md-wrap">
//                 <div className="cell cell-fill cell-md-12 overflow-dotted">
//                     <span className="name">Christopher Henry’s</span> <br />
//                     <span className="title">Bikiran Balance</span>
//                 </div>
//                 <div className="cell cell-md-12">
//                     <div className="balance-container line-col-center align-end">
//                         <div className="line-col-center align-end mr-5">
//                             <span className="font-14">Your Bikiran Balance</span>
//                             <span>
//                                 <span className="font-16 fw-500">TK.</span>
//                                 <span className="title">854.55</span>
//                             </span>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

function ContentBodyArea() {
    return (
        <div className="product-info mb-6">
            <BannerArea />
            <div className="line-align mt-4 mb-3 fz-16 fw-500">BIKIRAN Balance History</div>
            <BalanceHistorySection />
        </div>
    );
}
function UsersBalance() {
    useEffect(() => {
        PageProperties(`Balance`);
    }, []);

    return (
        <div className="cell cell-content h-100">
            <div className="content-section">
                <div className="content-area">
                    <ContentHead />
                    <div className="content-body-scroll">
                        <ContentBodyArea />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default UsersBalance;
