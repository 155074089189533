import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import PageProperties from '../../../utils/PageProperties';
import DnsSection from '../dns/DnsSection';
import DomainInfoBasicProvider, { useDomainInfo } from '../domains_info/DomainInfoBasicProvider';
import DomainInfoHeader from '../domains_info_header/DomainInfoHeader';
import TabsRowSection from '../domains_info_tabs/TabsRowSection';

function ContentHead() {
    const { domainInfo, handelReloadButton } = useDomainInfo();
    return <DomainInfoHeader evReloadClick={handelReloadButton} info={domainInfo} path="dns/" />;
}

function ContentBody() {
    const { domainInfo, domain } = useDomainInfo();
    return (
        <div className="content-body-scroll" style={{ paddingBottom: '180px' }}>
            <TabsRowSection dns />
            <div className={!domainInfo ? 'placeholder-mode' : ''}>
                <DnsSection tbl="domain" domain={domain} />
            </div>
        </div>
    );
}

function DomainInfoDnsPage() {
    const { contractId } = useParams();
    const { currentUser } = useAuth();

    useEffect(() => {
        PageProperties(`DNS \u2022 Domain`);
    }, []);

    return (
        <DomainInfoBasicProvider currentUser={currentUser} contractId={contractId}>
            <div className="cell cell-content h-100">
                <div className="content-section">
                    <div className="content-area">
                        <ContentHead />
                        <div className="content-body">
                            <ContentBody />
                        </div>
                    </div>
                </div>
            </div>
        </DomainInfoBasicProvider>
    );
}

export default DomainInfoDnsPage;
