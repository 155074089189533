import React from 'react';
import { domainIcons } from '../../../../configs/Icons';
import { ExistingDomainBtn } from './SearchDomainWebRunning';

const questions = [
    {
        id: 1,
        title: 'Are you owner of this domain?'
    },
    {
        id: 2,
        title: 'Are you looking for your website design?'
    },
    {
        id: 3,
        title: 'Do you need Hosting for your website?'
    },
    {
        id: 4,
        title: 'Want to transfer domain to Bikiran.com?'
    }
];

function Questions({ q }) {
    return (
        <div className="cell cell-3 cell-xl-4 cell-lg-6 cell-sm-12">
            <div key={q.id} className="offline-question w-100">
                <span>
                    <button className="question-cross-btn" type="button" />
                </span>
                <p>
                    <span>{q.title}</span>
                </p>
                <div>
                    <span>
                        <button type="button" className="noBtn ">
                            No
                        </button>
                    </span>
                    <span className="ml-2">
                        <button type="button" className="yesBtn ">
                            Yes
                        </button>
                    </span>
                </div>
            </div>
        </div>
    );
}

function SearchDomainWebOffline({ webInfo }) {
    // console.log(webInfo);

    const handleTitleClick = () => {
        window.open(`https://www.${webInfo?.domain}`, '_blank');
    };
    return (
        <div className={`container mb-4 ${!webInfo ? 'placeholder-mode' : ''}`}>
            <div className="domain-offline-area mb-4">
                <div className="area-header">
                    <div
                        onClick={handleTitleClick}
                        role="button"
                        tabIndex={-1}
                        className="line-align line-no-wrap line-sm-no-wrap overflow-hidden"
                    >
                        <div className="cell">
                            <div className="domain-web-logo-area line-row-center">
                                <span className="d-block w-100">
                                    <img
                                        className="w-100 d-block"
                                        src={webInfo?.favicon || domainIcons.iconDomainDemo}
                                        alt="logo icon"
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="cell-fill overflow-hidden">
                            <div className=" ml-4">
                                <h3 className="line-align mt-0 mb-0 domain-title gap">
                                    <span>
                                        <>
                                            {webInfo?.domain?.toUpperCase() || '-----------------'}{' '}
                                            &bull;{' '}
                                        </>
                                        <span>
                                            [We are unable to collect information from this Website]
                                        </span>
                                    </span>
                                </h3>
                                <p className="font-14 red mt-0 mb-0">
                                    <span>This domain has already Registered</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="area-body">
                    <div className="line-row-between line-g2">
                        {questions?.map((q) => (
                            <Questions key={q.id} q={q} />
                        ))}
                    </div>
                </div>
            </div>
            <div className="container">
                <ExistingDomainBtn domain={webInfo?.domain} />
            </div>
        </div>
    );
}

export default SearchDomainWebOffline;
