import { useEffect, useState } from 'react';

import { ButtonActionReload, ButtonGr } from '../../../../../configs/Buttons';
import { useLayout } from '../../../../contexts/LayoutProvider';
import PageProperties from '../../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../../headers/DashboardHeaderSection';
import MessageModal from '../../../system/modals/MessageModal';
import MethodList from './method_list/MethodList';

function HeaderContent() {
    const [modal, setModal] = useState(false);

    return (
        <>
            <div className="line justify-end  ">
                <span className="header-action-btn">
                    <ButtonActionReload />
                </span>
                <span className="ml-1">
                    <ButtonGr evOnClick={() => setModal(true)} title="Add Card" />
                </span>
            </div>
            <MessageModal show={modal} setModal={setModal} />
        </>
    );
}

function ContentHead() {
    return (
        <DashboardDefaultHeader
            title="Payment Method"
            path="/user/billing/invoice/"
            content={<HeaderContent />}
            mobileContent={<HeaderContent />}
        />
    );
}

function ContentBodyArea() {
    return <MethodList />;
}

function UsersPaymentMethodPage() {
    const { windowSizeName } = useLayout();

    const windowSize =
        windowSizeName === 'xs' || windowSizeName === 'sm' || windowSizeName === 'md';

    useEffect(() => {
        PageProperties(`Payment Methods \u2022 Billing`);
    }, []);

    return (
        <div className="cell cell-content h-100">
            <div className="content-section">
                <div className="content-area">
                    <ContentHead />

                    <div className="content-body-scroll">
                        <ContentBodyArea windowSize={windowSize} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsersPaymentMethodPage;
