import { useState } from 'react';
import { ButtonOutlinePink, ButtonPurple } from '../../../../configs/Buttons';
import { basic } from '../../../../configs/Images';
import LayoutSelector from '../../../utils/LayoutSelector';
import { useDomainInfo } from '../domains_info/DomainInfoBasicProvider';
import SectionDivider from '../_models/section_divider/SectionDivider';
import DomainInfoCnsListMobile from './DomainInfoCnsListMobile';
import DomainInfoCnsListWeb from './DomainInfoCnsListWeb';
import DomainInfoCnsModal from './DomainInfoCnsModal';

// const info = [
//     {
//         id: 1,
//         hostname: 'ns1.whoexperts.com',
//         ip: '190.167.68.109'
//     },
//     {
//         id: 2,
//         hostname: 'ns2.whoexperts.com',
//         ip: '192.168.68.107'
//     }
// ];
const demoArr = [
    {
        hostname: '----------',
        ip: '----------',
        demo: true
    }
];

function NotFoundView({ setModal, modal }) {
    return (
        <>
            <div className="w-100 line-col-center text-center">
                <div className=" mt-6">
                    <img
                        className="w-100 not-found-img"
                        src={basic.imgNotFound}
                        alt="icon missing"
                    />
                </div>
                <p className="font-18">No CNS Found! </p>
                <ButtonPurple title="Add CNS" evOnClick={() => setModal(!modal)} modal />
            </div>
            <DomainInfoCnsModal show={modal} setModal={setModal} />
        </>
    );
}

function DomainInfoCnsListSection() {
    const [modal, setModal] = useState(false);
    const [viewType, setViewType] = useState(null);

    const { cnsAr } = useDomainInfo();

    const arr = Object?.entries(cnsAr || {})?.map(([key, value]) => ({
        hostname: Array?.isArray(key) ? key?.join(', ') : key?.toString(),
        ip: Array?.isArray(value) ? value?.join(', ') : value?.toString()
    }));

    // console.log(arr);

    if (cnsAr && !arr?.length) {
        return <NotFoundView setModal={setModal} modal={modal} />;
    }
    return (
        <div className="product-info domain-cns-list-area mb-4">
            <SectionDivider
                title="Child Name Servers"
                action={<ButtonOutlinePink title="Add" evOnClick={() => setModal(!modal)} />}
            />

            <LayoutSelector
                sm
                md
                webComp={
                    <DomainInfoCnsListWeb
                        info={arr?.length !== 0 ? arr : demoArr}
                        viewType={viewType}
                        setViewType={setViewType}
                    />
                }
                mobileComp={
                    <DomainInfoCnsListMobile
                        info={arr?.length !== 0 ? arr : demoArr}
                        viewType={viewType}
                        setViewType={setViewType}
                    />
                }
            />

            <div className="cell cell-6 cell-md-12" />
            <DomainInfoCnsModal show={modal} setModal={setModal} />
        </div>
    );
}

export default DomainInfoCnsListSection;
