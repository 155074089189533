import { icons } from '../../../../../configs/Icons';

function TabsConversation({ info, show, evOnClick, conversation }) {
    if (!show) {
        return null;
    }
    return (
        <div
            className={`cell cell-fill cell-md-4 cell-xl-3 cell-sm-5 ${
                !info ? 'disabled-view' : ''
            }`}
            onClick={evOnClick}
            role="button"
            tabIndex={-1}
        >
            <div
                role="button"
                tabIndex={-1}
                className={`info-tab-item conversation-tab line-row-center ${
                    conversation ? 'active' : ''
                }`}
            >
                <div className="line-col-center">
                    <div className="logo">
                        <img
                            className="w-100 h-100 d-block"
                            src={conversation ? icons.iconChatActive : icons.iconChat}
                            alt="cpanel"
                        />
                    </div>
                    <div className="title">
                        <span>Start Conversation</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TabsConversation;
