import React, { useEffect, useRef, useState } from 'react';
import { useInit } from '../contexts/InitProvider';

function Options({ currencies, mode, handleOptionClick, locale }) {
    return (
        <div className={`dropdown-options ${mode?.status ? 'active' : ''}`}>
            {currencies?.map((item) => {
                if (item?.currency === locale?.currency) {
                    return (
                        <div
                            key={item?.currency}
                            role="button"
                            tabIndex={-1}
                            onClick={() => handleOptionClick(item)}
                            className="option-item active"
                        >
                            <span className="currency-name">{item?.currency}</span> <span>✓</span>
                        </div>
                    );
                }
                return (
                    <div
                        key={item?.currency}
                        role="button"
                        tabIndex={-1}
                        onClick={() => handleOptionClick(item)}
                        className="option-item"
                    >
                        <span className="currency-name">{item?.currency}</span>{' '}
                        <span>{item?.currency === 'USD' ? '$' : '৳'}</span>
                    </div>
                );
            })}
        </div>
    );
}

function CurrencyDropdownMenu({ children }) {
    const { locale, currencies, handelChangeLocale } = useInit();

    const [mode, setMode] = useState({ status: false, value: locale?.currency });

    const handleOptionClick = (item) => {
        handelChangeLocale(locale?.country, item?.currency, locale?.language);
        setMode({ status: false, value: item?.currency });
    };

    const myRef = useRef();

    useEffect(() => {
        function handelClickOutside(event) {
            if (myRef?.current && !myRef?.current.contains(event?.target)) {
                // alert("You clicked outside of me!");
                setMode({ status: false, value: mode?.value });
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handelClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handelClickOutside);
        };
    }, [mode.value]);

    return (
        <div className="currency-dropdown-area" ref={myRef}>
            <div
                role="button"
                tabIndex={-1}
                style={{ cursor: 'default' }}
                onClick={() => setMode({ status: !mode.status, value: locale?.currency })}
                className={`${!children ? 'dropdown-btn' : ''} ${
                    mode.status ? 'active' : 'inactive'
                }`}
            >
                {children || locale?.currency || mode.value}
            </div>

            <Options
                mode={mode}
                currencies={currencies}
                locale={locale}
                handleOptionClick={handleOptionClick}
            />
        </div>
    );
}

export default CurrencyDropdownMenu;
