import { ButtonPurple } from '../../../../../../configs/Buttons';
import { useDnsInfo } from '../../DnsProvider';

function TabCnameContent() {
    const { formData, setFormData } = useDnsInfo();

    const handelCreate = () => null;

    const isDisabled = !formData?.hostname || !formData?.aliasOf || !formData?.ttl;

    return (
        <div>
            <div className="mb-1">
                <span className="content-text">
                    <b>CNAME</b> records act as an alias by mapping a hostname to another hostname.
                </span>
            </div>
            <div className="record-input-area">
                <div className="line align-end line-g1">
                    <div className="cell cell-fill">
                        <div className="label">
                            Hostname<span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, hostname: e.target.value }))
                            }
                            placeholder="Enter hostname"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>
                    <div className="cell cell-fill">
                        <div className="label">
                            IS AN ALIAS OF <span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, aliasOf: e.target.value }))
                            }
                            placeholder="Enter @ or hostname"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>
                    <div className="cell cell-fill">
                        <div className="label">
                            TTL (SECONDS)<span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, ttl: e.target.value }))
                            }
                            placeholder="36000"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>
                    <div className="cell cell-12">
                        <div className="label">Comment</div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, comment: e.target.value }))
                            }
                            placeholder="Here is the Comment"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>
                    <div className="cell cell-12">
                        <div className="line justify-end mt-2">
                            <ButtonPurple
                                disabled={isDisabled}
                                evOnClick={handelCreate}
                                title="Create Record"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TabCnameContent;
