import { useEffect, useState } from 'react';
import { ButtonGr } from '../../../../../configs/Buttons';
import { userIcons } from '../../../../../configs/Icons';
import PageProperties from '../../../../utils/PageProperties';
import { DateInput } from '../../../../utils/inputs/DateInputs';
import { DashboardDefaultHeader } from '../../../headers/DashboardHeaderSection';
import ServerListSection from './server_list/ServerListSection';

const demoData = [
    {
        uId: 10000000,
        image: userIcons.iconUserV3,
        name: '-----------------',
        email: '--------------',
        quantity: '---------------',
        type: '-----------',
        issueDate: '------------',
        price: '------',
        isDone: false,
        demo: true
    }
];

const contractInfo = [
    {
        uId: 10000000,
        image: userIcons.iconUserV3,
        name: 'Mariana D. Alonso',
        email: 'name@email.com',
        quantity: '2GB SSD Hosting',
        type: 'Web Hosting',
        issueDate: '30-July-2022',
        price: '149.50',
        isDone: false
    },
    {
        uId: 20000000,
        image: userIcons.iconUserV3,
        name: 'Chris Evans',
        email: 'name@email.com',
        quantity: '2GB SSD Hosting',
        type: 'Email Hosting',
        issueDate: '31-Oct-2023',
        price: '154.20',
        isDone: true
    }
];

function HeaderButtons() {
    if (!contractInfo) {
        return null;
    }
    return (
        <div className="line-row-between">
            <div className="cell">
                <div className="ml-6">
                    <DateInput height="30px" />
                </div>
            </div>

            <div className="cell-fill">
                <div className="line justify-end ">
                    <div className="ml-2 ">
                        <ButtonGr title="Create Contract" />
                    </div>
                </div>
            </div>
        </div>
    );
}

function ContentBodyArea() {
    const [modalData, setModalData] = useState({ type: '', data: {} });
    const arr = contractInfo || demoData;
    return (
        <div className="list-container w-100">
            <ServerListSection
                modalData={modalData}
                setModalData={setModalData}
                contractInfo={arr}
            />
        </div>
    );
}

function ContractsServerPage() {
    useEffect(() => {
        PageProperties(`Server \u2022 Contract \u2022 Support`);
    }, []);

    return (
        <div className="cell cell-content h-100 w-100 domain-section">
            <div className="content-section">
                <div className="content-area user-contract-page-area">
                    <DashboardDefaultHeader
                        title="Server List"
                        path="/smanager/ticket/"
                        content={<HeaderButtons />}
                        mobileContent={<ButtonGr title="Create Contract" />}
                    />

                    <div className="content-body-scroll" style={{ paddingBottom: '180px' }}>
                        <ContentBodyArea />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ContractsServerPage;
