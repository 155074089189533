import { DashboardDefaultHeader } from '../../headers/DashboardHeaderSection';

function ContentHead() {
    return <DashboardDefaultHeader title="Dashboard" path={-1} />;
}
// function ChartArea() {
//     return (
//         <div className="product-info mb-6">
//             <div>
//                 <div className="line-align mb-5">
//                     <div className="cell-6">
//                         <h3 style={{ float: 'left' }}>Server Traffic Source</h3>
//                     </div>
//                     <div className="cell-6 input-box">
//                         <select className="form-input" name="This Month" style={{ float: 'right' }}>
//                             <option value="jan">Jan</option>
//                             <option value="feb">Feb</option>
//                             <option value="march">March</option>
//                             <option value="april">April</option>
//                         </select>
//                     </div>
//                 </div>
//                 <div className="line-align mb-5">
//                     <div className="cell-12 w-100 line">
//                         <hr className="cell-12" style={{ border: '1px dashed #D0CFD9' }} />
//                         <hr className="cell-12" style={{ border: '1px dashed #D0CFD9' }} />
//                         <hr className="cell-12" style={{ border: '1px dashed #D0CFD9' }} />
//                         <hr className="cell-12" style={{ border: '1px dashed #D0CFD9' }} />
//                         <hr className="cell-12" style={{ border: '1px dashed #D0CFD9' }} />
//                     </div>
//                 </div>
//                 <div className="line-align">
//                     <div className="cell-12 w-100 line-row-between align-unset">
//                         <p>1</p>
//                         <p>1</p>
//                         <p>1</p>
//                         <p>1</p>
//                         <p>1</p>
//                         <p>1</p>
//                         <p>1</p>
//                         <p>1</p>
//                         <p>1</p>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }
// function CpuUsed() {
//     return (
//         <div className="product-info mb-6 cpu-area text-center" style={{ padding: '20px' }}>
//             <div>Daily CPU Use</div>
//             <div className="cpu-bg mt-2 text-center">
//                 <div>
//                     <img src={basic.cpuBg} alt="icon" />
//                 </div>
//                 <div className="cpu-indicator">
//                     <img src={basic.cpuInd} alt="icon" />
//                 </div>
//                 <span className="ml-2">45.9%</span>
//             </div>
//             <div>
//                 <p className="mt-0 mb-0">
//                     Used Status : <span style={{ color: 'green' }}>Good</span>
//                 </p>
//             </div>
//         </div>
//     );
// }
// function StorageUsed() {
//     return (
//         <div className="line-align mb-4" style={{ backgroundColor: '#FF8C00' }}>
//             <div>
//                 <p>76%</p>
//             </div>
//             <div>Storage Uses</div>
//         </div>
//     );
// }
// function MemoryUsed() {
//     return (
//         <div className="line-align" style={{ backgroundColor: '#F50057' }}>
//             <div>
//                 <p>49%</p>
//             </div>
//             <div>Memory Uses</div>
//         </div>
//     );
// }
// function Usage() {
//     return (
//         <div className="product-info storage-used mb-6">
//             <StorageUsed />
//             <MemoryUsed />
//         </div>
//     );
// }
// function DomainList() {
//     return (
//         <div className="product-info mb-6">
//             <span className="title">Domain List</span>
//             <table className="table-list text-left" cellPadding={0} cellSpacing={0}>
//                 <thead>
//                     <tr>
//                         <th colSpan={2}>Name</th>
//                         <th colSpan={1}>Expire Date</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     <tr>
//                         <td
//                             className="text-left"
//                             colSpan={2}
//                             // style={{ color: history.ammount < 0 ? '#FB7247' : '#17DC69' }}
//                         >
//                             thedomainname.com
//                         </td>
//                         <td className="text-left" style={{ paddingLeft: '0px' }} colSpan={1}>
//                             25-Sept-2021 ...
//                         </td>
//                     </tr>
//                 </tbody>
//             </table>
//         </div>
//     );
// }
// function ContentBodyArea() {
//     return (
//         <>
//             <ChartArea />
//             <div className="line line-g3">
//                 <div className="cell cell-3 cell-md-12">
//                     <CpuUsed />
//                 </div>
//                 <div className="cell cell-3 cell-md-12">
//                     <Usage />
//                 </div>
//                 <div className="cell cell-6 cell-md-12">
//                     <DomainList />
//                 </div>
//             </div>
//         </>
//     );
// }

function UsersDashboardPage() {
    return (
        <div className="cell cell-content h-100">
            <div className="content-section">
                <div className="content-area">
                    <ContentHead />
                    <div className="content-body-scroll" style={{ paddingBottom: '120px' }}>
                        <div className="product-info h-100">
                            <div className="line-col-center h-100">
                                <span>Comming Soon....</span>
                            </div>
                        </div>
                        {/* <ContentBodyArea />
                        <ContentBodyArea /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsersDashboardPage;
