import { useState } from 'react';
import { userIcons } from '../../../../../../../configs/Icons';
import { useLayout } from '../../../../../../contexts/LayoutProvider';

// const topics = [
//     {
//         id: 1,
//         name: 'Cristopher',
//         text: 'This is the last message'
//     },
//     {
//         id: 2,
//         users: ['Cristopher', 'Matt', 'John', 'Max', 'Well'],
//         text: 'This is the Subject of the topic'
//     },
//     {
//         id: 3,
//         name: 'Web Development',
//         text: 'This is the Subject of the topic'
//     }
// ];

function Header() {
    return (
        <div className="header header-left">
            <span className="font-18 primary-70">Topics</span>
        </div>
    );
}

function TopicInfo({ data }) {
    return (
        <div>
            <span className="line-row-between line-no-wrap">
                <span className="title">
                    <span className="line-align line-no-wrap">
                        {data?.users?.slice(0, 2)?.map((user, index) => (
                            <span key={user}>
                                {user}
                                {data?.users?.length > 2 && data?.users?.length > 1 && index !== 1
                                    ? ', \u00A0'
                                    : ''}
                            </span>
                        ))}{' '}
                        {data?.users?.length > 1 && <span>+{data?.users?.length - 2}</span>}
                    </span>
                </span>
                <span className="font-12 primary-50">
                    <span className="">12:32</span>
                </span>
            </span>
            <span className="value d-block">{data?.subject}</span>
        </div>
    );
}

function TopicItem({ arr, setShowData }) {
    const [prevIndex, setPrevIndex] = useState('');
    const { windowSizeName } = useLayout();

    const handelItemClick = (data) => {
        if (['xs', 'sm', 'md'].includes(windowSizeName)) {
            setShowData({ id: data.id, isClick: true });
        } else {
            setShowData({ id: data.id });
        }
    };

    return arr?.map((data, index) => (
        <div
            key={data?.id}
            role="button"
            tabIndex={-1}
            onClick={() => handelItemClick(data)}
            onMouseEnter={() => setPrevIndex(index - 1)}
            onMouseLeave={() => setPrevIndex('')}
            style={{
                borderBottom: prevIndex === index ? '1px solid transparent' : ''
            }}
            className="topic-item"
        >
            <div className="line line-no-wrap line-g1">
                <div className="cell">
                    <div className="avatar">
                        <img className="d-block w-100" src={userIcons.iconUserV3} alt="" />
                    </div>
                </div>
                <div className="cell cell-fill overflow-hidden">
                    <TopicInfo data={data} />
                </div>
            </div>
        </div>
    ));
}

function TopicsColumn({ arr, setShowData }) {
    return (
        <div className="topics-column-section">
            <Header />
            <div className="body">
                <TopicItem arr={arr} setShowData={setShowData} />
            </div>
        </div>
    );
}

export default TopicsColumn;
