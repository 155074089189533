import LayoutSelector from '../../../../../utils/LayoutSelector';
import MethodListMobile from './MethodListMobile';
import MethodListWeb from './MethodListWeb';

const demoArr = [
    {
        id: 1,
        acType: '---------',
        acNum: '----------------',
        expDate: '-----------',

        isDemo: true
    }
];
const arr = [
    // {
    //     id: 1,
    //     acType: 'Visa',
    //     acNum: '1234 56** **78 9012',
    //     expDate: '05/26'
    // },
    // {
    //     id: 2,
    //     acType: 'Bkash',
    //     acNum: '122342 23** **38 9032',
    //     expDate: '08/20'
    // }
];

function MethodList() {
    return (
        <LayoutSelector
            sm
            md
            webComp={<MethodListWeb arr={arr || demoArr} />}
            mobileComp={<MethodListMobile arr={arr || demoArr} />}
        />
    );
}

export default MethodList;
