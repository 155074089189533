import { useNavigate } from 'react-router-dom';
import { hostingIcons, icons } from '../../../../../configs/Icons';
import SectionTitle from './SectionTitle';

const serviceOptions = [
    {
        id: 1,
        image: hostingIcons.iconAppHostingV1,
        image5: hostingIcons.iconAppHostingV2,
        title: 'Custom Number Inputs on Web Portal/App'
    },
    {
        id: 2,

        image: hostingIcons.iconEmailHostingV1,
        image5: hostingIcons.iconEmailHostingV2,
        title: 'Import from Phone Book on Portal/App'
    },
    {
        id: 3,

        image: icons.iconCloudVm,
        image5: icons.iconCloudVm5,
        title: 'Import from Contacts Groups on Web Portal/App'
    },
    {
        id: 4,

        image: icons.iconVpsV1,
        image5: icons.iconVps5,
        title: 'Import from Cell Phone/Handset Device'
    },
    {
        id: 5,

        image: icons.iconDedicatedServerV1,
        image5: icons.iconDedicatedServer5,
        title: 'Import from another app (JSON/XML)'
    },
    {
        id: 6,
        image: icons.iconDedicatedServerV1,
        image5: icons.iconDedicatedServer5,
        title: 'Import from files (Word,Excel, PDF, or Any Text Format)'
    }
];

const Option = ({ item, navigate }) => (
    <div
        className="cell cell-4"
        role="button"
        tabIndex="-1"
        onClick={(ev) => {
            ev.preventDefault();
            navigate(item.url);
        }}
    >
        <div className="service-option mng-num-option">
            <div className="img" style={{ backgroundImage: `url(${item.image})` }}>
                <div className="animate" style={{ backgroundImage: `url(${item.image5})` }} />
            </div>
            <h3 className="title">{item.title}</h3>
            <p>{item.description}</p>
        </div>
    </div>
);

function ImportNumberSection() {
    const navigate = useNavigate();
    return (
        <div className="services-area mt-120" id="services">
            <div className="container">
                <SectionTitle titleSm="You Also Can do" title="Inputs/Import/Manage Number " />
                <div className="service-description-area">
                    <div className="line">
                        {serviceOptions.map((item) => (
                            <Option item={item} key={item.id} navigate={navigate} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImportNumberSection;
