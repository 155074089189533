import { domainModify } from '../../../../../configs/Icons';
import DomainPcns from '../basicinfo_domainp_cns/DomainPcns';
import DomainPcontact from '../basicinfo_domainp_contact/DomainPcontact';
import DomainPns from '../basicinfo_domainp_ns/DomainPns';

// const items = [
//     {
//         id: 'contact',
//         title: 'Contact Details',
//         img: domainModify.iconContactDt,
//         description: 'Manage contacts associated with this Domain Name',
//         show: true
//     },
//     {
//         id: 'nameServer',
//         title: 'Name Servers',
//         img: domainModify.iconNs,
//         description: 'Manage Name Servers that this Domain Name uses',
//         show: true
//     },
//     {
//         id: 'cns',
//         title: 'Child Name Servers',
//         img: domainModify.iconCns,
//         description: 'Manage Child Name Servers of this Domain Name',
//         show: true
//     }
//     {
//         id: 'dnsec',
//         title: 'DNSSEC',
//         img: domainModify.iconTheftProtection,
//         description: 'Protect your DNS data by adding digital signatures',
//         show: false
//     }
// ];

function Divider() {
    return (
        <div className="line-align line-no-wrap mb-4">
            <span className="divider-title">Domain Property</span>
            <hr
                style={{
                    width: '100%',
                    opacity: '30%'
                }}
                className="ml-2"
            />
        </div>
    );
}

function BasicInfoDomainPsection() {
    return (
        <div className="product-info domain-property-list-area mb-6 mb-sm-0">
            <Divider />
            <div className="line-row-between line-g2">
                <DomainPcontact
                    title="Contact Details"
                    description="Manage contacts associated with this Domain Name"
                    img={domainModify.iconContactDt}
                />
                <DomainPns
                    title="Name Servers"
                    description="Manage Name Servers that this Domain Name uses"
                    img={domainModify.iconNs}
                />
                <DomainPcns
                    title="Child Name Servers"
                    description="Manage Child Name Servers of this Domain Name"
                    img={domainModify.iconCns}
                />
            </div>
        </div>
    );
}
export default BasicInfoDomainPsection;
