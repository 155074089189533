function CalculationSection() {
    return (
        <div className="renew-calc-summery-area">
            <div className="summery-header">
                <span>Summery</span>
            </div>
            <div className="summery-body">
                <div className="line-align">
                    <div className="cell">
                        <span className="font-16">Subtotal</span>
                    </div>
                    <div className="cell-fill text-right">
                        <span className="fs-16  ff-amount">$49.99</span>
                    </div>
                </div>

                <div className="line-align mb-1 mt-1">
                    <div className="cell">
                        <span className="font-16">Promotion</span>
                    </div>
                    <div className="cell-fill text-right">
                        <span className="fs-16  ff-amount">-$5</span>
                    </div>
                </div>
                <div className="line-align mb-2">
                    <div className="cell">
                        <span className="font-16">Total</span>
                    </div>
                    <div className="cell-fill text-right">
                        <span className="fs-16  ff-amount">-$44.99</span>
                    </div>
                </div>
                <div className="text-center mb-2">
                    <div className="saved-text">Your Just Saved -----</div>
                </div>
                <div className="w-100 line-align text-center">
                    <span className="button-solid-purple common-button fs-14 fw-500 w-100">
                        I am Ready to Pay
                    </span>
                </div>
            </div>
        </div>
    );
}

export default CalculationSection;
