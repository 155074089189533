import { useRef } from 'react';
import { icons, socialIcon } from '../../../../configs/Icons';
import { useTemplate } from '../../../contexts/TemplateProvider';

const ProviderIcon = ({ providerId }) => {
    if (providerId === 'google.com') {
        return (
            <span className="icon">
                <img src={socialIcon.google} alt={providerId} />
            </span>
        );
    }

    if (providerId === 'facebook.com') {
        return (
            <span className="icon">
                <img src={socialIcon.facebook} alt={providerId} />
            </span>
        );
    }

    if (providerId === 'github.com') {
        return (
            <span className="icon">
                <img src={socialIcon.github} alt={providerId} />
            </span>
        );
    }

    return null;
};

const BtnCreateAccByProvider = ({ formData, handleCreateAccProvider, handleCloseClick }) => {
    const btnRef = useRef();
    const { setMessage } = useTemplate();

    const handelOnClick = () => {
        setMessage('Creating Account...');
        btnRef.current.disabled = true;

        handleCreateAccProvider()
            .then(({ error, message }) => {
                if (error === 0) {
                    handleCloseClick();
                }
                setMessage(message);
                btnRef.current.disabled = false;
            })
            .then(() => {
                btnRef.current.disabled = false;
            });
    };

    return (
        <button
            className="button button-gr-pink w-100 login-btn"
            type="button"
            onClick={handelOnClick}
            ref={btnRef}
        >
            <ProviderIcon providerId={formData?.providerId} />
            <span> Create Account </span>
        </button>
    );
};

const BtnCreateAccount = ({ handleCreateAccount, setInitialState, handleCloseClick }) => {
    const btnRef = useRef();
    const { setMessage } = useTemplate();

    const handelOnClick = () => {
        setMessage('Creating Account...');
        btnRef.current.disabled = true;

        handleCreateAccount()
            .then(({ error, message }) => {
                if (error === 0) {
                    setInitialState();
                    handleCloseClick();
                }
                setMessage(message);
                btnRef.current.disabled = false;
            })
            .catch(() => {
                btnRef.current.disabled = false;
            });
    };

    return (
        <button
            className="button button-gr-pink w-100 login-btn"
            type="button"
            onClick={handelOnClick}
            ref={btnRef}
        >
            Create Account
        </button>
    );
};

const BtnVerifyOtp = ({ handelVerifyOtp, formData }) => {
    const btnRef = useRef();
    const { setMessage } = useTemplate();

    const handelOnClick = () => {
        setMessage('Verifying OTP...');
        btnRef.current.disabled = true;

        handelVerifyOtp()
            .then(({ message }) => {
                setMessage(message);
                btnRef.current.disabled = false;
            })
            .catch(() => {
                btnRef.current.disabled = false;
            });
    };

    if (formData.otp.length < 6) {
        return (
            <button className="button button-gr-pink w-100 login-btn" type="button" disabled>
                Verify OTP
            </button>
        );
    }

    return (
        <button
            className="button button-gr-pink w-100 login-btn"
            type="button"
            onClick={handelOnClick}
            ref={btnRef}
        >
            Verify OTP
        </button>
    );
};

const BtnSendOtp = ({ handelOtpSend }) => {
    const btnRef = useRef();
    const { setMessage } = useTemplate();

    const onCLickOtpSend = () => {
        setMessage('Sending OTP...');
        btnRef.current.disabled = true;

        handelOtpSend()
            .then(({ message }) => {
                setMessage(message);
                btnRef.current.disabled = false;
            })
            .catch(() => {
                btnRef.current.disabled = false;
            });
    };

    return (
        <button
            className="button button-gr-pink w-100 login-btn"
            type="button"
            onClick={onCLickOtpSend}
            ref={btnRef}
        >
            Send OTP
        </button>
    );
};

function RegistrationButtons({
    formData,
    handelOtpSend,
    handelVerifyOtp,
    handleCreateAccount,
    handleCreateAccProvider,
    handleCloseClick
}) {
    if (formData.providerIsVerified) {
        return (
            <>
                <BtnCreateAccByProvider
                    handleCreateAccProvider={handleCreateAccProvider}
                    formData={formData}
                    handleCloseClick={handleCloseClick}
                />
                <div className="line reg-info">
                    <img src={icons.iconInfo} alt="info" />
                    <span>You could log in with a password or {formData.providerId}</span>
                </div>
            </>
        );
    }

    if (formData.sentId && formData.otpIsVerified) {
        return (
            <BtnCreateAccount
                handleCreateAccount={handleCreateAccount}
                formData={formData}
                handleCloseClick={handleCloseClick}
            />
        );
    }

    if (formData.sentId) {
        return <BtnVerifyOtp handelVerifyOtp={handelVerifyOtp} formData={formData} />;
    }

    return <BtnSendOtp handelOtpSend={handelOtpSend} />;
}

export default RegistrationButtons;
