import { icons } from '../../../../configs/Icons';

function TabsBasicInfo({ evOnClick, basicInfo }) {
    return (
        <div
            className="cell cell-fill cell-md-4 cell-xl-3 cell-sm-5"
            onClick={evOnClick}
            role="button"
            tabIndex={-1}
        >
            <div
                role="button"
                tabIndex={-1}
                className={`info-tab-item basic-info line-row-center ${basicInfo ? 'active' : ''}`}
            >
                <div className="line-col-center">
                    <div className="logo">
                        <img
                            className="w-100 h-100 d-block"
                            src={basicInfo ? icons.iconInfoActive : icons.iconInfoInactive}
                            alt="cpanel"
                        />
                    </div>
                    <div className="title">
                        <span>Basic Info</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TabsBasicInfo;
