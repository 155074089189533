import { useNavigate } from 'react-router-dom';
import { ButtonGr, ButtonSolidGray } from '../../../../../configs/Buttons';
import { basic } from '../../../../../configs/Images';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import Iopt from '../../../../utils/Iopt';
import LinkBrokenPage from '../../../websites/pages/LinkBrokenPage';
import { useInvitation } from '../RoleInvitationProvider';

const list = [
    {
        id: 1,
        text: 'By accept the invitation you will be able to manage/access the Contract.'
    },
    {
        id: 2,
        text: 'You must have log in/register before accept the invitation.'
    },
    {
        id: 3,
        text: 'You need this email in your account to accept invitations.'
    },
    {
        id: 4,
        text: 'If you not the right person, please reject the invitation or ignore this invitation.'
    },
    {
        id: 5,
        text: 'The validity of the invitation is only for 7 days.'
    }
];

function Buttons() {
    const { handelAcceptInvite, handelRejectInvite } = useInvitation();
    const { currentUser, makeAction } = useAuth();
    const { setMessage } = useTemplate();
    const navigate = useNavigate();

    const handelAcceptButton = () => {
        if (!currentUser.loginStatus) {
            makeAction('sign-in');
        } else {
            setMessage('Accepting...');
            handelAcceptInvite()
                .then(({ error, message, data }) => {
                    setMessage(message);

                    if (error === 0) {
                        navigate(`/user/services/${data.type}/${data.contractId}/`);
                    }
                })
                .catch((err) => {
                    setMessage(err);
                });
        }
    };

    const handelRejectButton = () => {
        if (!currentUser.loginStatus) {
            makeAction('sign-in');
        } else {
            setMessage('Rejecting...');
            handelRejectInvite()
                .then(({ error, message }) => {
                    setMessage(message);
                    if (error === 0) {
                        setMessage(message);
                        navigate(`/`);
                    }
                })
                .catch((err) => {
                    setMessage(err);
                });
        }
    };

    return (
        <div className="line-row-between line-g1 line-no-wrap">
            <div className="cell cell-6">
                <span>
                    <ButtonSolidGray evOnClick={handelRejectButton} title="Reject" />
                </span>
            </div>
            <div className="cell cell-6">
                <span>
                    <ButtonGr evOnClick={handelAcceptButton} title="Accept" />
                </span>
            </div>
        </div>
    );
}

function UserInfo({ name }) {
    return (
        <div className="user-info">
            <span className="">{name || '--'} Invited You</span> <br />
            <span className="">to Collaborate with the team</span>
        </div>
    );
}

function InvitationCriteria({ inviteData }) {
    return (
        <div className="invitation-criteria">
            <ul>
                {list?.map((data) => (
                    <li key={data?.id}>
                        <span>
                            {inviteData ? data?.text : '----------------------------------'}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
}

function RoleInvitationCardSection() {
    const { inviteData, status } = useInvitation();
    if (status?.error !== 0 && status) {
        return <LinkBrokenPage />;
    }
    return (
        <div className={`invitation-area ${!inviteData ? 'placeholder-mode' : ''}`}>
            <div className="invitation-card">
                <div className="line-row-center">
                    <div className="user-img">
                        <span className="d-block">
                            <img
                                src={
                                    inviteData?.profileImg
                                        ? Iopt(inviteData?.profileImg)
                                        : basic.imgAvatar
                                }
                                alt="user avatar"
                            />
                        </span>
                    </div>
                </div>
                <UserInfo name={inviteData?.name} />
                <InvitationCriteria inviteData={inviteData} />
                <Buttons />
            </div>
        </div>
    );
}

export default RoleInvitationCardSection;
