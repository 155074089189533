import React, { useEffect } from 'react';
import { ButtonGr } from '../../../../configs/Buttons';
import PageProperties from '../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../headers/DashboardHeaderSection';
import DnsSection from './DnsSection';

function UserDnsPage() {
    useEffect(() => {
        PageProperties('DNS List');
    }, []);

    return (
        <div className="cell cell-content h-100">
            <div className="content-section">
                <div className="content-area">
                    <DashboardDefaultHeader
                        title="Domain Namer Server"
                        path="/user/basic-info/"
                        content={
                            <div className="ml-6">
                                <ButtonGr title="Mark all as Read" />
                            </div>
                        }
                        mobileContent={
                            <span className="">
                                <ButtonGr title="Mark all as Read" />
                            </span>
                        }
                    />

                    <div className="content-body-scroll">
                        <DnsSection />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserDnsPage;
