import React, { useState } from 'react';

import { useAuth } from '../../../../contexts/AuthProvider';
import AddPhoneModal from '../contactinfo_phone_table/ContactInfoPhoneAddModal';
import ContactInfoPhoneTableSection from '../contactinfo_phone_table/ContactInfoPhoneTableSection';
// import ContactInfoStatusHandler from '../_models/ContactInfoStatusHandler';

function ContactInfoPhoneSection({ info }) {
    const [modalData, setModalData] = useState({
        type: null
    });
    const { currentUser } = useAuth();

    const handelAddPhone = () => {
        setModalData((cuData) => ({ ...cuData, type: 'add-phone' }));
    };

    return (
        <div>
            <div>
                <ContactInfoPhoneTableSection info={info} />
            </div>
            <div className="line-align line-md-no-wrap justify-end mt-4 mb-6">
                <div className="cell cell-sm-6">
                    <div
                        role="button"
                        tabIndex={-1}
                        onClick={handelAddPhone}
                        className="line-align add-btn-common add-phone"
                    >
                        <span className="ml-4">Add</span>
                    </div>
                </div>
            </div>
            <AddPhoneModal
                show={modalData.type === 'add-phone'}
                modalData={modalData}
                setModalData={setModalData}
                currentUser={currentUser}
            />
        </div>
    );
}

export default ContactInfoPhoneSection;
