import React from 'react';

function SmsFeaturesSection() {
    return (
        <div className="container mb-120">
            <div className="sms-page-area sms-feature-area">
                <div className="feature-item">
                    <div className="line-col-center">
                        <h3 className="title mb-2">API Integration</h3>
                        <p className="content-value mt-0 mb-5">
                            Once you choose our package, Our API Acutomatically <br /> Install to
                            your account.
                        </p>
                        <button type="button" className="content-value">
                            Read documentation
                        </button>
                    </div>
                </div>
                <div className="feature-item">
                    <div className="  line-col-center">
                        <h3 className="title mb-2">Block List Option</h3>
                        <p className="content-value mt-0 mb-5">
                            You could manage the Blocklist if message send failed <br /> It saves
                            your money
                        </p>
                        <button type="button" className="content-value">
                            Get a Quotation
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SmsFeaturesSection;
