import { ButtonActionDotMenu, ButtonActionEdit } from '../../../../../configs/Buttons';
import { useLayout } from '../../../../contexts/LayoutProvider';
import DeletePrimaryPopup from '../../_models/DeletePrimaryPopup';
import AddressListTableMenuOption from './AddressListTableMenuOption';

function EditBtn({ data, setModalData }) {
    return (
        <span>
            <ButtonActionEdit
                evOnClick={() => {
                    setModalData(() => ({
                        type: 'update',
                        data: { data }
                    }));
                }}
            />
        </span>
    );
}

function DotMenu({ setShowOptions, id }) {
    return (
        <span className="ml-2 btn-action-web">
            <ButtonActionDotMenu
                evOnClick={() => {
                    setShowOptions(id);
                }}
            />
        </span>
    );
}
function AddressListTableActionBtn({ setModalData, setShowOptions, showOptions, myRef, data }) {
    const { windowSizeName } = useLayout();
    const isMobile = windowSizeName === 'xs' || windowSizeName === 'sm' || windowSizeName === 'md';
    return (
        <div className="position-relative">
            <div
                className={`line-align line-no-wrap ${isMobile ? 'btn-action' : 'btn-action-web'}`}
            >
                <EditBtn data={data} setModalData={setModalData} />
                <DotMenu setShowOptions={setShowOptions} id={data?.id} />
            </div>
            <div className="option-popup">
                <AddressListTableMenuOption
                    myRef={myRef}
                    showOptions={data?.id === showOptions}
                    setShowOptions={setShowOptions}
                    data={data}
                />
            </div>
            <DeletePrimaryPopup
                show={showOptions === 'default'}
                setShowOptions={setShowOptions}
                title="Contact"
            />
        </div>
    );
}

export default AddressListTableActionBtn;
