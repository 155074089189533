import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import { useLayout } from '../../../contexts/LayoutProvider';
import ScrollProvider from '../../../contexts/ScrollProvider';
import LayoutSelector from '../../../utils/LayoutSelector';
import HeaderSection from '../../headers/HeaderSection';
import FixedHeaderControl from '../../websites/components/FixedHeaderControl';
import MobileFooter from '../components/MobileFooter';
import SidebarMenuSection from '../components/SidebarMenuSection';

function Header() {
    return (
        <div>
            <LayoutSelector
                webComp={
                    <FixedHeaderControl>
                        <HeaderSection />
                    </FixedHeaderControl>
                }
            />
        </div>
    );
}

function UserAuthHandler() {
    const { currentUser, makeAction, modalProperty } = useAuth();
    const { windowSizeName: sizeName } = useLayout();

    useEffect(() => {
        if (
            !currentUser.loading &&
            currentUser.loginStatus === false &&
            modalProperty.modalType === null
        ) {
            makeAction('sign-in', () => null);
        }
    }, [currentUser, makeAction, modalProperty]);

    if (!currentUser) {
        return <div />;
    }

    if (currentUser?.loginStatus !== true) {
        return (
            <div>
                <HeaderSection showLoginPopup />
            </div>
        );
    }

    return (
        <ScrollProvider>
            <div className={['h-100 header-b', 'browser', `browser-${sizeName}`].join(' ')}>
                <Header />

                <div className="body-section">
                    <div className="container h-100">
                        <div className="line line-no-wrap h-100">
                            <div className="cell cell-profile h-100 sidebar-menu-area">
                                <SidebarMenuSection currentUser={currentUser} />
                            </div>
                            <div className="cell cell-content h-100 w-100 overflow-hidden">
                                <div className="content-section">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <MobileFooter />
            </div>
        </ScrollProvider>
    );
}

export default UserAuthHandler;
