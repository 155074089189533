import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import PageProperties from '../../../utils/PageProperties';
import HostingInfoHeader from '../hostings_info_header/HostingInfoHeader';
import TabsSection from '../hostings_info_tabs/TabSection';
import InfoActivityLogArea from '../info_activity/InfoActivityLogArea';
import InfoActivityLogProvider, { useActivityInfo } from '../info_activity/InfoActivityLogProvider';

function ContentHead() {
    const { activityInfo, handelReloadButton } = useActivityInfo();

    return (
        <HostingInfoHeader
            path="activity/"
            info={activityInfo}
            evReloadClick={handelReloadButton}
        />
    );
}

function ContentBody() {
    const { activityInfo } = useActivityInfo();
    return (
        <div className="content-body-scroll">
            <TabsSection info={activityInfo} dotMenu activity />
            <InfoActivityLogArea />
        </div>
    );
}

function HostingActivityLogPage() {
    const { contractId } = useParams();
    const { currentUser } = useAuth();

    useEffect(() => {
        PageProperties(`Activity Log \u2022 Hosting`);
    }, []);

    return (
        <InfoActivityLogProvider
            apiUrl="CONTRACT_HOSTING"
            currentUser={currentUser}
            contractId={contractId}
        >
            <div className="cell cell-content h-100">
                <div className="content-section">
                    <div className="content-area">
                        <ContentHead />

                        <ContentBody />
                    </div>
                </div>
            </div>
        </InfoActivityLogProvider>
    );
}
export default HostingActivityLogPage;
