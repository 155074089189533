import { useRef } from 'react';

import { ButtonActionDotMenu } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import ContactInfoIconHandler from '../_models/ContactInfoIconHandler';
import ContactInfoEmailTableMenu from './ContactInfoEmailTableMenu';

function CuUserText({ cUser }) {
    if (!cUser) {
        return null;
    }
    return <span className="logged-mail-text">You are logged in with this email</span>;
}

function Email({ data }) {
    return (
        <div className="line-align line-no-wrap">
            <div>
                <ContactInfoIconHandler value={data.providerId} mobile />
            </div>
            <span className="title ml-2">{data.email}</span>
            {data?.isDefault ? (
                <span className="ml-2">
                    <img className="d-block" src={icons.iconP} alt="primary icon" />
                </span>
            ) : null}
        </div>
    );
}

function ButtonActionMobile({ setShowOptions, showOptions, myRef, data }) {
    return (
        <div className="btn-action position-relative line">
            <span>
                <ButtonActionDotMenu
                    evOnClick={() => {
                        setShowOptions(data?.id);
                    }}
                />
            </span>
            <div className="">
                <ContactInfoEmailTableMenu
                    myRef={myRef}
                    showOptions={data?.id === showOptions}
                    setShowOptions={setShowOptions}
                    data={data}
                    type="email"
                />
            </div>
        </div>
    );
}

function ContactInfoEmailTableMobile({ arr, currentUser, showOptions, setShowOptions }) {
    const myRef = useRef();

    if (!arr?.length) {
        return (
            <div className="list-item-sm">
                <span className="title primary-70-i">No Address Found !</span>
            </div>
        );
    }
    return arr?.map((data) => {
        const cUser = data?.userUid === currentUser?.userUid || data?.demo;

        return (
            <div key={data?.id} className={`list-item-sm ${data?.demo ? 'placeholder-mode' : ''}`}>
                <div className="line-row-between line--no-wrap">
                    <Email data={data} />

                    <div>
                        <ButtonActionMobile
                            setShowOptions={setShowOptions}
                            showOptions={showOptions}
                            myRef={myRef}
                            data={data}
                        />
                    </div>
                </div>

                <CuUserText cUser={cUser} />
            </div>
        );
    });
}

export default ContactInfoEmailTableMobile;
