import { useState } from 'react';
import { ButtonActionEdit } from '../../../../../configs/Buttons';
import BasicInfoPasswordPopup from './BasicInfoPasswordPopup';

function PasswordView({ evOnClick, value, cpPasswordChange }) {
    return (
        <div className="info-edit-area">
            <div className="line-row-between line-no-wrap">
                <div className="cell cell-10">
                    <span className="value">{value || '--------------------------'}</span>
                </div>
                <div hidden={!cpPasswordChange} className="cell">
                    <div className="action-btn ml-2">
                        <ButtonActionEdit evOnClick={evOnClick} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function BasicInfoPassword({ basicInfo }) {
    const [modal, setModal] = useState(false);
    const { cpPasswordChange } = basicInfo?.permissions || {};
    return (
        <>
            <div className="line-align mb-2">
                <div className="cell cell-5">
                    <span className="subject">Password:</span>
                </div>
                <div className="cell cell-7">
                    <PasswordView
                        evOnClick={() => setModal(!modal)}
                        value="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                        cpPasswordChange={cpPasswordChange}
                    />
                </div>
            </div>
            <BasicInfoPasswordPopup show={modal} data={basicInfo} setModal={setModal} />
        </>
    );
}

export default BasicInfoPassword;
