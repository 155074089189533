/* eslint-disable react/no-array-index-key */
import { ButtonSolidPink } from '../../../../../configs/Buttons';
import { GetDate, UpperCase } from '../../../../utils/Show';

function TitleArea({ data }) {
    return (
        <div className="line-row-between line-no-wrap mb-2">
            <span className="title mr-2">{data?.email}</span>
            <span className="button-action-sm">
                <ButtonSolidPink title="Manage" />
            </span>
        </div>
    );
}

function CommonRowArea({ title, value }) {
    return (
        <div className="line-row-between">
            <div className="cell cell-4">
                <span className="value primary-70-i">{title}</span>
            </div>
            <div className="cell cell-fill">
                <span className="value">{value}</span>
            </div>
        </div>
    );
}

function EmailListTableMobile({ arr }) {
    if (!arr?.length) {
        return (
            <div className="list-item-sm">
                <span className="title">No Email Found!</span>
            </div>
        );
    }
    return arr?.map((data) => (
        <div key={data?.id} className="list-item-sm">
            <TitleArea data={data} />
            <CommonRowArea title="Created On" value={GetDate(data?.timeUpdate) || '--'} />
            <CommonRowArea title="B.Rate" value="0%" />
            <CommonRowArea title="Status" value={UpperCase(data?.status) || 0} />
        </div>
    ));
}

export default EmailListTableMobile;
