import { useState } from 'react';
import { icons } from '../../../../../configs/Icons';
import CpLoginLinkPopup from '../../hostings/cpanel_login/CpLoginLinkPopup';

function TabsCpLogin({ info, contractId }) {
    const [modal, setModal] = useState({ type: '', data: null });

    const cName = !info?.permissions?.cpLogin ? 'disabled-view' : '';
    return (
        <>
            <div
                className={`cell cell-fill cell-md-4 cell-xl-3 cell-sm-5 ${cName}`}
                onClick={() => setModal({ type: 'cp-login', data: info })}
                role="button"
                tabIndex={-1}
            >
                <div className="info-tab-item cpanel line-row-center">
                    <div className="line-col-center">
                        <div className="logo">
                            <img
                                className="w-100 h-100 d-block"
                                src={icons.imgCpanelV2}
                                alt="cpanel"
                            />
                        </div>
                        <div className="title">
                            <span>cPanel Login</span>
                        </div>
                    </div>
                </div>
            </div>
            <CpLoginLinkPopup
                show={modal?.type === 'cp-login'}
                setModal={setModal}
                modal={modal}
                id={contractId}
            />
        </>
    );
}

export default TabsCpLogin;
