import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonOutlinePink } from '../../../../../configs/Buttons';
import { GetDate, getDayDiff } from '../../../../utils/Show';
import MessageModal from '../../../system/modals/MessageModal';
import CopyText from '../../../websites/models/CopyText';
import { ShowPriceWithDuration } from '../../../websites/models/Pricing';
import Divider from '../../hostings/_models/SectionDivider';
import { useHostingInfoBasic } from '../HostingInfoBasicProvider';

function CommonAreaView({ title, value, copy }) {
    return (
        <div className="info-edit-area">
            <div className="line-align line-g2 line-no-wrap mb-2 ">
                <div className="cell cell-5">
                    <span className="subject">{title}:</span>
                </div>
                <div className="cell cell-7">
                    {copy ? (
                        <CopyText value={value || '---------------------'} cName="value" />
                    ) : (
                        <span className="value">{value || '--------------------------'}</span>
                    )}
                </div>
            </div>
        </div>
    );
}

function RenewalDate({ timeExpire }) {
    const daysRemain = getDayDiff(timeExpire);

    return (
        <div className="">
            <span className="value d-block gap">{GetDate(timeExpire) || '-----------'}</span>
            <span className="font-12 opacity-medium">{daysRemain} Days Left</span>
        </div>
    );
}

function BasicInfoBillingSection() {
    const [modal, setModal] = useState(false);
    const { basicInfo } = useHostingInfoBasic();
    const { title, contractId, timeIssue, timeExpire, domain, price } = basicInfo || {};
    const navigate = useNavigate();

    // const date1 = dayjs('2023-03-14');
    // const date2 = dayjs('2024-02-28');

    // console.log(date2.diff(date1, 'days'));

    return (
        <div className="product-info mb-4">
            <Divider title="Billings" />

            <div className="line mb-3">
                <div className="cell cell-6 cell-md-12">
                    <CommonAreaView title="Domain Name" value={domain || '[Domain not set]'} copy />
                    <CommonAreaView title="Title" value={title} />
                    <CommonAreaView title="Contract ID: " value={contractId} copy />
                </div>

                <div className="cell cell-6 cell-md-12 ">
                    <CommonAreaView title="Created On" value={GetDate(timeIssue)} />
                    <div className="line line-g2 mb-2">
                        <div className="cell cell-5">
                            <span className="subject">Renewal Date:</span>
                        </div>
                        <div className="cell cell-7 overflow-dotted">
                            <span className="value ">
                                <RenewalDate timeExpire={timeExpire} />
                            </span>
                        </div>
                    </div>

                    <CommonAreaView title="Package Price" value={ShowPriceWithDuration(price)} />
                </div>
            </div>
            <div className="line-align">
                <span>
                    <ButtonOutlinePink
                        evOnClick={() =>
                            navigate(`/user/billing/invoice/?contract-id=${contractId}`)
                        }
                        title="View Invoices"
                    />
                </span>
                <span className="ml-2">
                    <ButtonOutlinePink
                        // evOnClick={() => navigate(`/user/contracts/hosting/renew/${contractId}/`)}
                        evOnClick={() => setModal(true)}
                        title="Renew"
                    />
                    <MessageModal show={modal} setModal={setModal} />
                </span>
            </div>
        </div>
    );
}
export default BasicInfoBillingSection;
