import React, { useEffect, useState } from 'react';

import ServerPagePlaceholder from '../../../../placeholders/components/ServerPagePlaceholder';
import PageProperties from '../../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../../headers/DashboardHeaderSection';
import CommonModal from '../../../system/modals/CommonModal';

function HeaderButton({ data }) {
    if (!data?.length) {
        return null;
    }
    return (
        <div className="line-align line-g1 justify-end ">
            <div className="cell mb-sm-2">
                <input type="text" name="" placeholder="Search.." className="form-input" />
            </div>
            <div className="cell">
                <select name="" className="form-input ml-2 ml-sm-0  mb-sm-2">
                    <option>Status</option>
                    <option>Active</option>
                    <option>Not Active</option>
                    <option>Expired</option>
                    <option>Expired soon</option>
                    <option>Recovery Mode</option>
                </select>
            </div>
        </div>
    );
}

function ContentHead({ data }) {
    useEffect(() => {
        PageProperties('Dedicated Server');
    }, []);

    return (
        <DashboardDefaultHeader
            title="Dedicated Server"
            path="/user/services/server/cloud/"
            content={
                <div className="line justify-end">
                    <HeaderButton data={data} />
                </div>
            }
        />
    );
}

function ContentBodyArea({ data }) {
    const [openModal, setOpenModal] = useState(null);

    if (!data?.length) {
        return <ServerPagePlaceholder sName="Dedicated" />;
    }
    return (
        <div className="product-info mb-6">
            <div>
                <div className="line mb-5">
                    <div className="cell cell-6 cell-sm-12">
                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">Host Name:</span>
                            </div>
                            <div className="cell cell-7">
                                <span className="value">server1.bikiran.com</span>
                            </div>
                        </div>

                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">Expired on: </span>
                            </div>
                            <div className="cell cell-7">
                                <div>
                                    <span className="value">30 - July - 2020</span>
                                    <br />
                                    <span className="value-info">194 Days Remain</span>
                                </div>
                            </div>
                        </div>

                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">Registered on: </span>
                            </div>
                            <div className="cell cell-7">
                                <span className="value">30 - July - 2019</span>
                            </div>
                        </div>
                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">Package: </span>
                            </div>
                            <div className="cell cell-7">
                                <span className="value">2GB SSD</span>
                            </div>
                        </div>
                    </div>
                    <div className="cell cell-6 cell-sm-12">
                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">IP Address:</span>
                            </div>
                            <div className="cell cell-7">
                                <span className="value">192.168.31.107</span>
                                <br />
                                <span className="value">192.168.56.107</span>
                            </div>
                        </div>

                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">Renew Price:</span>
                            </div>
                            <div className="cell cell-7">
                                <span className="value">BDT 1799.00</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <button
                        onClick={() => setOpenModal(true)}
                        type="button"
                        className="button button-outline-pink mr-4"
                    >
                        Show More
                    </button>
                    <button
                        onClick={() => setOpenModal(true)}
                        type="button"
                        className="button button-outline-green"
                    >
                        Contact Us
                    </button>
                </div>
                {openModal && <CommonModal openModal={openModal} setOpenModal={setOpenModal} />}
            </div>
        </div>
    );
}
function UsersDedicatedServerPage({ data }) {
    return (
        <div className="cell cell-content h-100">
            <div className="content-section">
                <div className="content-area">
                    <ContentHead data={data} />
                    <div className="content-body-scroll">
                        <div className="d-none d-lg-block mb-3">
                            <div className="line justify-end ">
                                <HeaderButton data={data} />
                            </div>
                        </div>
                        <ContentBodyArea data={data} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsersDedicatedServerPage;
