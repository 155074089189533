import { icons } from '../../../configs/Icons';

export function InpEmail({
    value,
    setValue,
    title,
    placeholder,
    vendorIcon,
    resetComponent,
    isChecked = false,
    disabled = false
}) {
    return (
        <div className="input-grp">
            <div className="label">{title}</div>

            <div className="inp-with-icon" disabled={disabled}>
                {vendorIcon ? <img src={vendorIcon} alt="Tick" /> : null}
                <input
                    type="email"
                    placeholder={placeholder}
                    value={value}
                    onChange={setValue}
                    disabled={disabled}
                    className="form-input"
                />
                {isChecked ? <img src={icons.iconTic} alt="Tick" /> : null}
                {isChecked && resetComponent ? resetComponent : null}
            </div>
        </div>
    );
}

export function InpPhone({
    value,
    setValue,
    title,
    placeholder,
    vendorIcon,
    resetComponent,
    isChecked = false,
    disabled = false
}) {
    return (
        <div className="input-grp">
            <div className="label">{title}</div>

            <div className="inp-with-icon" disabled={disabled}>
                {vendorIcon ? <img src={vendorIcon} alt="Tick" /> : null}
                <input
                    type="tel"
                    placeholder={placeholder}
                    value={value}
                    onChange={setValue}
                    disabled={disabled}
                    className="form-input"
                />
                {isChecked ? <img src={icons.iconTic} alt="Tick" /> : null}
                {isChecked && resetComponent ? resetComponent : null}
            </div>
        </div>
    );
}

export function InpFullName({
    value,
    setValue,
    title,
    placeholder,
    vendorIcon,
    isChecked = false,
    disabled = false
}) {
    return (
        <div className="input-grp">
            <div className="label">{title}</div>

            <div className="inp-with-icon mb-2" disabled={disabled}>
                {vendorIcon ? <img src={vendorIcon} alt="Tick" /> : null}
                <input
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    onChange={setValue}
                    disabled={disabled}
                />
                {isChecked ? <img src={icons.iconTic} alt="Tick" /> : null}
            </div>
        </div>
    );
}

function TextInputs() {
    return null;
}

export default TextInputs;
