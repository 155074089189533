import { useEffect } from 'react';
import { ButtonGr } from '../../../../configs/Buttons';
import PageProperties from '../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../headers/DashboardHeaderSection';
import TwoFacAuthBackupSection from './twofacauth_backup/TwoFacAuthBackupSection';
import TwoFacAuthSecuritySection from './twofacauth_security/TwoFacAuthSecuritySection';
import TwoFacAuthUpdateSection from './twofacauth_update/TwoFacAuthUpdateSection';

function ContentHead() {
    return (
        <DashboardDefaultHeader
            title="Two-Factor authentication"
            path="/user/basic-info/"
            content={
                <span className="line justify-end ">
                    <ButtonGr title="Contact Support" />
                </span>
            }
            mobileContent={
                <span className="line justify-end ">
                    <ButtonGr title="Contact Support" />
                </span>
            }
        />
    );
}

function TwoFactorAuthPage() {
    useEffect(() => {
        PageProperties(`Two Factor Authentication \u2022 Profile`);
    }, []);

    return (
        <div className="cell cell-content h-100 w-100">
            <div className="content-section">
                <div className="content-area">
                    <ContentHead />
                    <div
                        className="content-body-scroll security-area"
                        style={{ paddingBottom: '180px', position: 'relative' }}
                    >
                        <TwoFacAuthUpdateSection />
                        <TwoFacAuthSecuritySection />
                        <TwoFacAuthBackupSection />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default TwoFactorAuthPage;
