function BodyJsonResult({ apiData }) {
    console.log(apiData.output);
    return (
        <div className="w-100 mt-4">
            {apiData?.error ? (
                <div>
                    <span>{apiData?.error?.code}</span> <br />
                    <span>Messgae : {apiData?.error?.message}</span>
                </div>
            ) : (
                <textarea
                    name="jsonData"
                    id=""
                    cols="30"
                    rows="20"
                    className="w-100 border-none outline-none"
                    onChange={(ev) => ev.target.value}
                    value={JSON.stringify(apiData.output, null, 4) || ''}
                />
            )}
        </div>
    );
}

export default BodyJsonResult;
