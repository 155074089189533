/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useNavigate } from 'react-router-dom';
import { icons } from '../../../../configs/Icons';
import { usePayment } from '../../../contexts/PaymentProvider';
import RadioButton from '../../../utils/RadioButton';
import { CurrencyFormatter } from '../../websites/models/Pricing';
import ToggleMenu from '../../websites/models/ToggleMenu';

const demoPaymentArr = [
    {
        id: 1,
        title: '----------------',
        subtitle: '--------------',
        demo: true
    }
];

function ShowCurrencySign() {
    const { invoiceData } = usePayment();

    if (invoiceData?.currency === 'USD') {
        return '$';
    }
    if (invoiceData?.currency === 'BDT') {
        return '৳';
    }
    return '';
}

function ShowFormattedPrice(value) {
    return `${ShowCurrencySign()}${CurrencyFormatter(value) || 0}`;
}

const PaymentItems = ({ item, payment, setSelectedPayment }) => {
    const handleSetPayment = () => {
        setSelectedPayment(item);
    };

    const subtitle =
        item.id === payment?.id
            ? `We will be charged ${item.currency} ${item.amount.toFixed(2)}`
            : item.subtitle;

    return (
        <li
            className={item.id === payment?.id ? 'active' : ''}
            onClick={handleSetPayment}
            role="button"
            tabIndex="-1"
        >
            <div className="line line-no-wrap line-g2">
                <div className="cell pl-1">
                    <span>
                        <RadioButton checked={item.id === payment?.id || false} />
                    </span>
                </div>
                <div className="cell pl-1">
                    <span className="d-block">
                        <img className="d-block" src={item?.icon} alt={`${item.key} payment`} />
                    </span>
                </div>
                <div className="cell pl-1">
                    <div className="payment-option-text">
                        <span>
                            <b>{item.title}</b>
                        </span>
                        <br />
                        <span>{subtitle}</span>
                    </div>
                </div>
            </div>
        </li>
    );
};

const PaymentOption = ({ paymentOptions, selectedPayment, setSelectedPayment }) => {
    const { toComplete, handelPayBtnClick } = usePayment();

    const arr = paymentOptions || demoPaymentArr;
    // console.log(invoiceData?.amountBalance?.toFixed(2));

    return (
        <ul className="list payment-options">
            {arr?.map((item) => (
                <PaymentItems
                    item={item}
                    key={item?.id}
                    payment={selectedPayment}
                    setSelectedPayment={setSelectedPayment}
                />
            ))}
            <div className="cart-area mt-4">
                <div className="payment-error-message mb-3">
                    {toComplete?.length ? (
                        <ul>
                            {toComplete.map((item) => (
                                <li
                                    key={item.id}
                                    onClick={item?.onClickAction || null}
                                    role="button"
                                    tabIndex="-1"
                                >
                                    {item.message}
                                </li>
                            ))}
                        </ul>
                    ) : null}
                </div>
                <button
                    onClick={handelPayBtnClick}
                    disabled={!!toComplete?.length || !selectedPayment}
                    type="button"
                    className="w-100 button button-ready-to-pay w-sm-100"
                >
                    {selectedPayment ? selectedPayment.btnText : 'I am Ready to Pay!'}
                </button>
            </div>
        </ul>
    );
};

function FullPaidView() {
    const navigate = useNavigate();
    return (
        <div className="payment-operation-area print-none">
            <div className="payment-success-view">
                <div className="round-bordered text-center">
                    <img src={icons.imgPaymentSuccess} alt="Payment Success" />

                    <div className="payment-success-text">Payment Success</div>

                    <div className="payment-notice-text">
                        Thank you for your order. Please check your inbox
                        <br />
                        to check all the details.
                    </div>

                    <div className="hr mt-4 mb-4" />
                    {/* 
                    <div className="payment-info-text">
                        TrxID: <b>1008J3SD7N</b>
                        <br />
                        Card No: <b>1234 56** **87 6421</b>
                    </div> */}

                    <button
                        className="button button-payment-success"
                        type="button"
                        onClick={() => {
                            navigate('/services');
                        }}
                    >
                        Continue Shopping
                    </button>
                </div>
            </div>
        </div>
    );
}

// function CouponInputArea() {
//     return(
//         <div className="cart-coupon-area w-100 mb-5">
//                 <div className="line-row-between line-no-wrap  h-100">
//                     <input placeholder="Enter promo code..." type="text" className="form-input" />
//                     <ButtonOutlinePink title="Apply" />
//                 </div>
//             </div>
//     )
// }

function SubTotal({ invoiceData }) {
    return (
        <div className="line mb-1">
            <div className="cell cell-fill">
                <span className="subtotal-text">
                    Subtotal ({(invoiceData?.items || []).length} Item
                    {(invoiceData?.invoiceItems || []).length > 1 ? 's' : ''} )
                </span>
            </div>
            <div className="cell">
                <span className="subtotal-amount">
                    {ShowFormattedPrice(invoiceData?.amountBilled || 0)}
                </span>
            </div>
        </div>
    );
}

function Discount({ invoiceData }) {
    return (
        <div className="line mb-1">
            <div className="cell cell-fill">
                <span className="subtotal-text">Discount</span>
            </div>
            <div className="cell">
                <span className="subtotal-amount">
                    -{ShowFormattedPrice(invoiceData?.amountDiscount || 0)}
                </span>
            </div>
        </div>
    );
}
function OtherDiscount({ show, title, value }) {
    if (!show) {
        return null;
    }
    return (
        <div className="line mb-1">
            <div className="cell cell-fill">
                <span className="subtotal-text">{title}</span>
            </div>
            <div className="cell">
                <span className="subtotal-amount">-{value}</span>
            </div>
        </div>
    );
}

function PayableTotal({ invoiceData }) {
    return (
        <div className="line mb-2">
            <div className="cell cell-fill">
                <span className="payable-text">Payable total</span>
            </div>
            <div className="cell">
                <span className="payable-amount">
                    {ShowFormattedPrice(invoiceData?.amountPayable || 0)}
                </span>
            </div>
        </div>
    );
}

function SummeryView({ invoiceData }) {
    const { menuState } = usePayment();
    // const pointAmount = ConvertPoints(points);
    return (
        <div className="cart-area cart-calc-area">
            <div className="calc-title mb-3">Order Summery</div>
            <SubTotal invoiceData={invoiceData} />
            <Discount invoiceData={invoiceData} />
            <OtherDiscount
                show={menuState?.balance}
                title="From bikiran balance"
                value={ShowFormattedPrice(invoiceData?.amountBalance || 0)}
            />
            <OtherDiscount
                show={menuState?.points}
                title="From bikiran points"
                value={ShowFormattedPrice(invoiceData?.coinBalance || 0)}
            />

            {/* coupon input */}

            <PayableTotal invoiceData={invoiceData} />

            <div className="text-right mb-4">
                <span className="vat-text">VAT included, where applicable</span>
            </div>

            <div className="hr mb-4" />
            <div className="text-center">
                <span className="offer-total-text">
                    Your Just Saved {ShowFormattedPrice(invoiceData?.amountDiscount || 0)} On your
                    order
                </span>
            </div>
        </div>
    );
}

function ToggleMenuView({ title, value, img, evOnclick, isActive, margin }) {
    return (
        <div
            role="button"
            tabIndex={-1}
            onClick={evOnclick}
            className={`payment-toggle-menu-area  ${margin || 'mb-2'}`}
        >
            <div className="line-row-between">
                <div className="cell cell-fill">
                    <div className="line-align">
                        <div className="mr-2">
                            <img className="d-block" src={img} alt="menu icon" />
                        </div>
                        <div className="payment-option-text">
                            <span>
                                <b>{title}</b>
                            </span>
                            <br />
                            <span>{value}</span>
                        </div>
                    </div>
                </div>
                <div className="cell">
                    <ToggleMenu
                        // evOnClick={evOnclick}
                        isActive={isActive}
                        width={35}
                        height={18}
                        bg="#AE00B9"
                    />
                </div>
            </div>
        </div>
    );
}

function PaymentOperation({
    invoiceData,
    paymentOptions,
    selectedPayment,
    setSelectedPayment,
    toComplete,
    isDue
}) {
    const { setMenuState, menuState } = usePayment();

    const handleToggle = (menu) => {
        setMenuState((prevState) => ({ ...prevState, [menu]: !prevState[menu] }));
    };

    if (invoiceData && !isDue) {
        return <FullPaidView />;
    }
    return (
        <div className="payment-operation-area">
            <SummeryView invoiceData={invoiceData} toComplete={toComplete} />
            {/* <div className="hr mb-4 d-none d-md-block" /> */}
            <ToggleMenuView
                title="Bikiran Balance"
                value={`Available Balance is ${invoiceData?.amountBalance}`}
                img={icons.iconBikBalance}
                evOnclick={() => handleToggle('balance')}
                isActive={menuState?.balance}
            />
            <ToggleMenuView
                title="Bikiran Points"
                value={`Available Points is ${invoiceData?.coinAmount}`}
                img={icons.iconBikCoin}
                evOnclick={() => handleToggle('points')}
                isActive={menuState?.points}
                margin="mb-4"
            />
            <div className={selectedPayment ? 'round-bordered' : 'round-bordered red-area'}>
                <div className="payment-option-title">Payment Method</div>
                <PaymentOption
                    paymentOptions={paymentOptions}
                    selectedPayment={selectedPayment}
                    setSelectedPayment={setSelectedPayment}
                    invoiceData={invoiceData}
                />
            </div>
        </div>
    );
}

export default PaymentOperation;
