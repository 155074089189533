import { useEffect } from 'react';
import PageProperties from '../../../utils/PageProperties';
import HeaderSection from '../../headers/HeaderSection';
import FooterSection2 from '../../system/section/Footer2Section';
import MobileFooter from '../../users/components/MobileFooter';
import ClientSection from '../components/ClientSection';
import QuestionDetailsSection from '../components/knowledge-base-page/QuestionDetailsSection';

function QuestionDetailsPage() {
    useEffect(() => {
        PageProperties(`Q&A \u2022 Support`);
    }, []);

    return (
        <>
            <HeaderSection />
            <div className="container">
                <QuestionDetailsSection />
                <ClientSection />
            </div>
            <MobileFooter />
            <FooterSection2 />
        </>
    );
}

export default QuestionDetailsPage;
