import { GetDate } from '../../../../utils/Show';

const BillingsInvoiceUserInfoArea = ({ invoiceInfo }) => (
    <div className={`line ${!invoiceInfo ? 'placeholder-mode' : ''}`}>
        <div className="cell cell-4">
            <div>
                <h4 style={{ margin: 0 }} className="font-18">
                    To
                </h4>
                <h5 style={{ margin: 0, fontSize: 14 }}>
                    <span>{invoiceInfo?.data?.addressBilling?.name || '------------'}</span>
                </h5>
                <p className={`font-12 no-wrap ${!invoiceInfo ? 'mt-0' : 'mt-1'}`}>
                    <span>
                        {[
                            invoiceInfo?.data?.addressBilling?.line1,
                            invoiceInfo?.data?.addressBilling?.line2
                        ]
                            .filter((item) => !!item)
                            .join(', ')}
                        <br />
                        {[
                            invoiceInfo?.data?.addressBilling?.city,
                            invoiceInfo?.data?.addressBilling?.state,
                            invoiceInfo?.data?.addressBilling?.country,
                            invoiceInfo?.data?.addressBilling?.zip
                        ]
                            .filter((item) => !!item)
                            .join(', ') || '-----------------'}
                    </span>
                </p>
            </div>
        </div>

        <div className="cell cell-8">
            <div className="text-right">
                <h5 style={{ margin: 0, fontWeight: 'normal' }}>
                    Date of Issue:{' '}
                    <span>{GetDate(invoiceInfo?.data?.issueDate) || '-------------'}</span>
                </h5>
                <h5 style={{ margin: 0 }}>
                    Payment Due Date:{' '}
                    <span>{GetDate(invoiceInfo?.data?.dueToDate) || '-------------'}</span>
                </h5>
            </div>
        </div>
    </div>
);

export default BillingsInvoiceUserInfoArea;
