import { useAuth } from '../../../../contexts/AuthProvider';
import ContactInfoEmailSection from '../contactinfo_email/ContactInfoEmailSection';
import ContactInfoPhoneSection from '../contactinfo_phone/ContactInfoPhoneSection';
import ContactManageProvider from '../ContactManageProvider';

function ContactInfoSection() {
    const { currentUser, recheckLogin } = useAuth();
    return (
        <div className="user-contact-info-area use-info-area">
            <ContactManageProvider currentUser={currentUser} recheckLogin={recheckLogin}>
                <ContactInfoEmailSection currentUser={currentUser} />
                <ContactInfoPhoneSection info={[]} />
            </ContactManageProvider>
        </div>
    );
}

export default ContactInfoSection;
