import React, { useState } from 'react';
import { icons } from '../../../../../configs/Icons';
import InfoMailPopup from './InfoMailPopup';

function BasicInfoShareSection() {
    const [modal, setModal] = useState(false);

    return (
        <>
            <div
                role="button"
                tabIndex={-1}
                onClick={() => setModal(!modal)}
                className="line-inline line-align sent-info-area"
            >
                <img className="d-block" src={icons.iconShare} alt="share" />
                <span className="font-14 purple fw-500 ml-2">Share this information</span>
            </div>
            <InfoMailPopup setModal={setModal} show={modal} />
        </>
    );
}

export default BasicInfoShareSection;
