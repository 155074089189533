import { useRef, useState } from 'react';
import {
    addIcons,
    arrowIcons,
    bikLogo,
    contactIcons,
    dashboardIcons,
    deleteIcons,
    domainIcons,
    domainModify,
    editIcons,
    footerIcons,
    headerIcons,
    homeIcons,
    hostingIcons,
    icons,
    iconsModal,
    inputPassIcons,
    loginAreaIcons,
    marketplaceIcon,
    modeIcons,
    notificationIcons,
    osIcon,
    paymentMethodIcons,
    programmingLangIcon,
    searchBarIcons,
    servicesIcons,
    servicesPageIcons,
    signOutIcons,
    smsPageIcons,
    socialIcon,
    solvedQIcons,
    sortIcons,
    supportIcons,
    uploadIcon,
    userIcons
} from '../../../../configs/Icons';
import HeaderSection from '../../headers/HeaderSection';
import FooterSection2 from '../../system/section/Footer2Section';
import MobileFooter from '../../users/components/MobileFooter';
import IconsInfoModal from '../modals/IconsInfoModal';
import FixedHeaderControl from './FixedHeaderControl';

function SectionDevider({ title }) {
    return (
        <div style={{ marginBottom: '50px' }} className="line-align line-no-wrap">
            <span className="no-wrap fs-25 mr-1">{title}</span>
            <hr className="w-100" />
        </div>
    );
}

function CommonView({ mode, item, name }) {
    const [openModal, setOpenModal] = useState(null);
    const [elName, setElName] = useState(null);

    const handelClick = (i) => {
        setOpenModal(true);
        setElName(i);
    };
    return (
        <div
            className="line-align icon-section-area"
            style={{ backgroundColor: mode ? 'rgb(60 65 76)' : '' }}
        >
            {Object.keys(item).map((el) => (
                <div
                    key={el}
                    role="button"
                    tabIndex={-1}
                    onClick={() => handelClick(el)}
                    className="cell-2 cell line-row-center mb-6"
                >
                    <img
                        style={{ maxWidth: '50px', maxHeight: '50px', width: '100%' }}
                        className="mb-6"
                        src={item[el]}
                        alt={el}
                    />
                </div>
            ))}
            {openModal && (
                <IconsInfoModal
                    arrName={name}
                    name={elName}
                    setOpenModal={setOpenModal}
                    openModal={openModal}
                />
            )}
        </div>
    );
}

function IconGridArea({ handleMode, mode }) {
    return (
        <div className="container mt-6 mb-6">
            <div role="button" tabIndex={-1} onClick={handleMode} className="mode-view-area">
                <img
                    className="w-100"
                    src={mode ? modeIcons.iconDark : modeIcons.iconLight}
                    alt="icon"
                />
            </div>
            <SectionDevider title="Icons" />
            <CommonView mode={mode} item={icons} name="icons" />

            <SectionDevider title="Social icons" />
            <CommonView mode={mode} item={socialIcon} name="socialIcon" />

            <SectionDevider title="Upload icons" />
            <CommonView mode={mode} item={uploadIcon} name="uploadIcon" />

            <SectionDevider title="Search bar icons" />
            <CommonView mode={mode} item={searchBarIcons} name="searchBarIcons" />

            <SectionDevider title="Services page icons" />
            <CommonView mode={mode} item={servicesPageIcons} name="servicesPageIcons" />

            <SectionDevider title="Footer icons" />
            <CommonView mode={mode} item={footerIcons} name="footerIcons" />

            <SectionDevider title="Domain icons" />
            <CommonView mode={mode} item={domainIcons} name="domainIcons" />

            <SectionDevider title="services icons" />
            <CommonView mode={mode} item={servicesIcons} name="servicesIcons" />

            <SectionDevider title="Programming Languages Icons" />
            <CommonView mode={mode} item={programmingLangIcon} name="programmingLangIcon" />

            <SectionDevider title="Marketplace Icons" />
            <CommonView mode={mode} item={marketplaceIcon} name="marketplaceIcon" />

            <SectionDevider title="OS Knowledge Icons" />
            <CommonView mode={mode} item={osIcon} name="osIcon" />

            <SectionDevider title="Dashboard icons" />
            <CommonView mode={mode} item={dashboardIcons} name="dashboardIcons" />

            <SectionDevider title="Password Input icons" />
            <CommonView mode={mode} item={inputPassIcons} name="inputPassIcons" />

            <SectionDevider title="User icons" />
            <CommonView mode={mode} item={userIcons} name="userIcons" />

            <SectionDevider title="Support icons" />
            <CommonView mode={mode} item={supportIcons} name="supportIcons" />

            <SectionDevider title="Arrow icons" />
            <CommonView mode={mode} item={arrowIcons} name="arrowIcons" />

            <SectionDevider title="Solved Question area icons" />
            <CommonView mode={mode} item={solvedQIcons} name="solvedQIcons" />

            <SectionDevider title="Delete icons" />
            <CommonView mode={mode} item={deleteIcons} name="deleteIcons" />

            <SectionDevider title="Mode icons" />
            <CommonView mode={mode} item={modeIcons} name="modeIcons" />

            <SectionDevider title="contact icons" />
            <CommonView mode={mode} item={contactIcons} name="modeIcons" />

            <SectionDevider title="Login area icons" />
            <CommonView mode={mode} item={loginAreaIcons} name="loginAreaIcons" />

            <SectionDevider title="Header icons" />
            <CommonView mode={mode} item={headerIcons} name="headerIcons" />

            <SectionDevider title="Payment Method icons" />
            <CommonView mode={mode} item={paymentMethodIcons} name="paymentMethodIcons" />

            <SectionDevider title="Sms page icons" />
            <CommonView mode={mode} item={smsPageIcons} name="smsPageIcons" />

            <SectionDevider title="Modal icons" />
            <CommonView mode={mode} item={iconsModal} name="iconsModal" />

            <SectionDevider title="Home icons" />
            <CommonView mode={mode} item={homeIcons} name="homeIcons" />

            <SectionDevider title="Add icons" />
            <CommonView mode={mode} item={addIcons} name="addIcons" />

            <SectionDevider title="Edit icons" />
            <CommonView mode={mode} item={editIcons} name="editIcons" />

            <SectionDevider title="Notification icons" />
            <CommonView mode={mode} item={notificationIcons} name="notificationIcons" />

            <SectionDevider title="Bikiran logo icons" />
            <CommonView mode={mode} item={bikLogo} name="bikLogo" />

            <SectionDevider title="Sign Out icons" />
            <CommonView mode={mode} item={signOutIcons} name="signOutIcons" />

            <SectionDevider title="Sort icons" />
            <CommonView mode={mode} item={sortIcons} name="sortIcons" />

            <SectionDevider title="Domain Modify Section icons" />
            <CommonView mode={mode} item={domainModify} name="domainModify" />

            <SectionDevider title="Hosting icons" />
            <CommonView mode={mode} item={hostingIcons} name="hostingIcons" />
        </div>
    );
}

function IconGrid() {
    const [mode, setMode] = useState(false);
    const ref = useRef();

    const handleMode = () => {
        setMode(!mode);
        ref.current.classList.add('dark-active');
    };

    return (
        <>
            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>
            <IconGridArea handleMode={handleMode} mode={mode} ref={ref} />

            <MobileFooter />
            <FooterSection2 />
        </>
    );
}

export default IconGrid;
