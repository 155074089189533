import React from 'react';
import { ButtonOutlineRed } from '../../../../../configs/Buttons';

function TwoFacAuthUpdateMobile({ setMode }) {
    return (
        <div className="info-list-view">
            <div className="line-row-between line-g1 line-no-wrap mb-2">
                <div className="cell cell-fill">
                    <span className="font-16 clr break-word">
                        Your Two-factor authentication is on
                    </span>
                </div>
                <div className="cell">
                    <ButtonOutlineRed evOnClick={() => setMode(true)} title="Turn Off" />
                </div>
            </div>
            <div className="">
                <span className="font-14 opacity-medium d-block break-all">
                    We`ll ask for a verification code via your security method if we notice a login
                    from an unrecognized device or browser.
                </span>
            </div>
        </div>
    );
}

export default TwoFacAuthUpdateMobile;
