/* eslint-disable no-restricted-syntax */
import { useEffect, useRef, useState } from 'react';
import { ButtonOutlinePink } from '../../../../configs/Buttons';
import CurrencyDropdownMenu from '../../../utils/CurrencyDropdownMenu';
import { CurrencyFormatter, ShowCurrencySign } from '../../websites/models/Pricing';

function PromotionAmount({ totalPrice, totalOfferPrice }) {
    return (
        <div className="line mb-5">
            <div className="cell cell-fill">
                <span className="promotion-text">Promotion</span>
            </div>
            <div className="cell">
                <span className="subtotal-amount fw-400">
                    {totalPrice > totalOfferPrice ? '-' : ''}
                    {/* {ShowCurrencySign()} */}
                    {/* {(totalPrice - totalOfferPrice).toFixed(2)} */}
                    {CurrencyFormatter(totalPrice - totalOfferPrice)}
                </span>
            </div>
        </div>
    );
}

function PaybleAmount({ totalOfferPrice }) {
    return (
        <div className="line mb-3">
            <div className="cell cell-fill">
                <span className="payable-text">Payable total</span>
            </div>
            <div className="cell">
                <span className="payable-amount fw-700">
                    {ShowCurrencySign()}
                    {CurrencyFormatter(totalOfferPrice)}
                </span>
            </div>
        </div>
    );
}

function Subtotal({ cartData, totalPrice }) {
    return (
        <div className="line mt-1 mb-3">
            <div className="cell cell-fill">
                <span className="subtotal-text">
                    Subtotal ({cartData.length}
                    Item{cartData.length > 1 ? 's' : ''})
                </span>
            </div>
            <div className="cell">
                <span className="subtotal-amount fw-700">
                    {ShowCurrencySign()}
                    {CurrencyFormatter(totalPrice)}
                    {/* {totalPrice.toFixed(2)} */}
                </span>
            </div>
        </div>
    );
}

function SavedText({ totalPrice, totalOfferPrice }) {
    return (
        <div className="text-center mb-5">
            <span className="offer-total-text">
                Your Just Saved {ShowCurrencySign()}
                {CurrencyFormatter(totalPrice - totalOfferPrice)}{' '}
                {/* {(totalPrice - totalOfferPrice).toFixed(2)} */}
                On your order
            </span>
        </div>
    );
}

function TermsAgreement({ handleTermsValue, checkValue }) {
    return (
        <div className="line-align line-no-wrap mb-3">
            <span
                role="button"
                tabIndex={-1}
                onClick={handleTermsValue}
                className="line-align line-no-wrap"
            >
                <span className="mr-1">
                    <input
                        className="d-block"
                        checked={checkValue || false}
                        onChange={handleTermsValue}
                        type="checkbox"
                        name="terms"
                    />
                </span>
                <span className="font-14">I Agreed all the</span>
            </span>
            &nbsp;
            <span className="font-14">
                <a href="/legal/terms-of-service/" target="_blank">
                    <span className="purple">Term & Condition</span>
                </a>
            </span>
        </div>
    );
}

function CartOrderSummery({
    cartData,
    totalPrice,
    totalOfferPrice,
    handelReadyToPay,
    loginHash,
    setCheckValue,
    checkValue
}) {
    const ref = useRef();
    const [stHash, setStHash] = useState(loginHash);

    useEffect(() => {
        // console.log(stHash, loginHash);
        if (stHash !== loginHash) {
            ref.current.click();
            setStHash(loginHash);
        }
    }, [loginHash, stHash]);

    const handleTermsValue = () => {
        setCheckValue(!checkValue);
    };

    return (
        <>
            <div className="hr mb-4 d-none d-md-block" />

            <div className="line-row-between mb-4">
                <span className="calc-title">Order Summery</span>
                <CurrencyDropdownMenu />
            </div>

            <Subtotal cartData={cartData} totalPrice={totalPrice} />

            <PromotionAmount totalPrice={totalPrice} totalOfferPrice={totalOfferPrice} />

            <div className="cart-coupon-area w-100 mb-5">
                <div className="line-row-between line-no-wrap h-100">
                    <input placeholder="Enter promo code..." type="text" className="form-input" />
                    <ButtonOutlinePink title="Apply" />
                </div>
            </div>

            <PaybleAmount totalOfferPrice={totalOfferPrice} />

            <div className="text-right mb-5">
                <span className="vat-text">VAT included, where applicable</span>
            </div>

            <div className="hr mb-5" />

            <SavedText totalPrice={totalPrice} totalOfferPrice={totalOfferPrice} />

            <TermsAgreement handleTermsValue={handleTermsValue} checkValue={checkValue} />

            <button
                type="button"
                className="w-100 button button-ready-to-pay w-sm-100 mb-2"
                onClick={handelReadyToPay}
                ref={ref}
            >
                I am Ready to Pay!
            </button>

            <div className="text-center">
                <span
                    style={{
                        visibility: checkValue !== null && !checkValue ? 'visible' : 'hidden'
                    }}
                    className={`red font-14 mb-2 `}
                >
                    Please Accept Terms and Condition !!
                </span>
            </div>
        </>
    );
}

export default CartOrderSummery;
