import { useState } from 'react';
import { ButtonOutlinePink, ButtonPurple } from '../../../../configs/Buttons';
import { basic } from '../../../../configs/Images';
import { useDomainInfo } from '../domains_info/DomainInfoBasicProvider';
import SectionDivider from '../_models/section_divider/SectionDivider';
import DomainInfoNsModal from './DomainInfoNsModal';

const demoArr = ['demo1.bikiran.net', 'demo2.bikiran.net'];

function NotFoundView({ setModal, modal }) {
    return (
        <>
            <div className="w-100 line-col-center text-center">
                <div className=" mt-6">
                    <img
                        className="w-100 not-found-img"
                        src={basic.imgNotFound}
                        alt="icon missing"
                    />
                </div>
                <p className="font-18">No Name Server Found! </p>
                <ButtonPurple title="Add CNS" evOnClick={() => setModal(!modal)} modal />
            </div>
            <DomainInfoNsModal show={modal} setModal={setModal} />
        </>
    );
}

function DomainInfoNsListSection() {
    const [modal, setModal] = useState(false);

    const { nsAr, domainInfo } = useDomainInfo();

    const arr = nsAr || demoArr;

    if (nsAr && !nsAr?.length) {
        return <NotFoundView setModal={setModal} modal={modal} />;
    }
    return (
        <div className="product-info mb-4">
            <SectionDivider
                title="Name Severs"
                action={<ButtonOutlinePink evOnClick={() => setModal(!modal)} title="Modify" />}
            />
            <div className={`line ${!domainInfo ? 'placeholder-mode' : ''}`}>
                <div className="cell cell-6 cell-md-12">
                    {arr?.map((data, index) => (
                        <div key={data} className="line-align mb-2">
                            <div className="cell cell-5">
                                <span className="subject ">NameServer {index + 1}:</span>
                            </div>
                            <div className="cell cell-7">
                                <span className="value">{data || '--'}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="cell cell-6 cell-md-12" />
            </div>
            <DomainInfoNsModal show={modal} setModal={setModal} />
        </div>
    );
}

export default DomainInfoNsListSection;
