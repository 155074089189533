import { marketplaceIcon, osIcon, programmingLangIcon } from '../../../../../configs/Icons';
import ProfileDeveloperMarketplace from '../profile_developer_marketplace/ProfileDeveloperMarketplace';
import ProfileDeveloperOs from '../profile_developer_os/ProfileDeveloperOs';
import ProfileDeveloperPLanguage from '../profile_developer_planguage/ProfileDeveloperPlanguage';

const options = {
    pLanguages: [
        {
            id: 1,
            title: 'C++',
            icon: programmingLangIcon.cProgram
        },
        {
            id: 2,
            title: 'Python',
            icon: programmingLangIcon.python
        },
        {
            id: 3,
            title: 'Javascript',
            icon: programmingLangIcon.js
        },
        {
            id: 4,
            title: 'PHP',
            icon: programmingLangIcon.php
        }
    ],
    marketplace: [
        {
            id: 1,
            title: 'Upwork',
            icon: marketplaceIcon.upwrok
        },
        {
            id: 2,
            title: 'Fiverr',
            icon: marketplaceIcon.fiverr
        },
        {
            id: 3,
            title: 'Freelancer',
            icon: marketplaceIcon.freelancer
        }
    ],
    os: [
        {
            id: 1,
            title: 'Windows',
            icon: osIcon.windows
        },
        {
            id: 2,
            title: 'MacOS',
            icon: osIcon.mac
        },
        {
            id: 3,
            title: 'Android',
            icon: osIcon.android
        },
        {
            id: 4,
            title: 'LinuxOS',
            icon: osIcon.linux
        }
    ]
};

function Divider({ title }) {
    return (
        <div className="line-align line-no-wrap mb-2">
            <div className="cell title fw-400">{title}</div>
            <hr className="cell-fill cell-self-end ml-1" style={{ opacity: '0.5' }} />
        </div>
    );
}

function ProfileDeveloperSection() {
    return (
        <div className="product-info mb-6 developer-profile-area">
            <Divider title="Developer Profile" />
            <div className="font-16 fw-500 primary mb-2">Programming Languages</div>
            <ProfileDeveloperPLanguage arr={options?.pLanguages} />
            <div className="font-16 fw-500 primary mb-2">Marketplace List</div>
            <ProfileDeveloperMarketplace arr={options?.marketplace} />
            <div className="font-16 fw-500 primary mb-2">OS Knowledge</div>
            <ProfileDeveloperOs arr={options?.os} />
        </div>
    );
}

export default ProfileDeveloperSection;
