/* eslint-disable jsx-a11y/label-has-associated-control */
import { useRef } from 'react';
import { ButtonClose, ButtonGr, ButtonOutlinePink } from '../../../../configs/Buttons';
import { useTemplate } from '../../../contexts/TemplateProvider';
import countries from '../../../utils/country.json';

const ModalHeader = ({ handleCloseClick }) => (
    <div className="modal-box-header" style={{ border: '1px solid #8080803b' }}>
        <div>
            <div className="modal-box-title">
                <span>Contact Details</span>
            </div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={handleCloseClick} />
            </div>
        </div>
    </div>
);

function getParams(refNames) {
    const data = {};
    refNames.forEach((item) => {
        data[item.current.name] = item.current.value;
    });

    return data;
}

function ModalBodyContact({ handleCloseClick, handelDomainProfileSave }) {
    const { setMessage } = useTemplate();

    const nameRef = useRef();
    const orgRef = useRef();
    const emailRef = useRef();
    const mobileRef = useRef();
    const telephoneRef = useRef();
    const faxRef = useRef();
    const line1Ref = useRef();
    const countryRef = useRef();
    const stateRef = useRef();
    const cityRef = useRef();
    const zipRef = useRef();

    const handleContactForm = () => {
        const formData = getParams([
            nameRef,
            orgRef,
            emailRef,
            mobileRef,
            telephoneRef,
            faxRef,
            line1Ref,
            countryRef,
            stateRef,
            cityRef,
            zipRef
        ]);

        setMessage('Updating Domain Profile...');
        handelDomainProfileSave(formData)
            .then(({ message }) => {
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    return (
        <div className="modal-body-area overflow-auto">
            <div className="line-row-between line-g2 cart-domain-profile-area">
                <div className="cell cell-6">
                    <label htmlFor="fullname">Full Name</label>
                    <input
                        ref={nameRef}
                        name="fullname"
                        placeholder="Enter Full Name"
                        type="text"
                        className="form-input w-100"
                    />
                </div>
                <div className="cell cell-6">
                    <label htmlFor="organization">Organization</label>
                    <input
                        ref={orgRef}
                        name="organization"
                        placeholder="Enter Organization"
                        type="text"
                        className="form-input w-100"
                    />
                </div>
                <div className="cell cell-6">
                    <label htmlFor="email">Email</label>
                    <input
                        ref={emailRef}
                        name="email"
                        placeholder="Enter Email"
                        type="text"
                        className="form-input w-100"
                    />
                </div>
                <div className="cell cell-6">
                    <label htmlFor="telephone">Telephone</label>
                    <input
                        ref={telephoneRef}
                        name="telephone"
                        placeholder="Enter Telephone"
                        type="text"
                        className="form-input w-100"
                    />
                </div>

                <div className="cell cell-12">
                    <label htmlFor="line1">Address Line</label>

                    <textarea
                        ref={line1Ref}
                        name="line1"
                        className="form-input w-100"
                        placeholder="Enter Address.."
                        id=""
                        cols="100"
                        rows="3"
                    />
                </div>
                <div className="cell cell-6">
                    <label htmlFor="country">Country</label>
                    <select ref={countryRef} name="country" className="form-input">
                        <option value="">Select</option>
                        {countries.map((item) => (
                            <option key={item?.code} value={item?.code || ''}>
                                {item?.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="cell cell-6">
                    <label htmlFor="state">State</label>
                    <input
                        ref={stateRef}
                        name="state"
                        placeholder="Enter State"
                        type="text"
                        className="form-input w-100"
                    />
                </div>
                <div className="cell cell-6">
                    <label htmlFor="city">City</label>
                    <input
                        ref={cityRef}
                        name="city"
                        placeholder="Enter City"
                        type="text"
                        className="form-input w-100"
                    />
                </div>
                <div className="cell cell-6">
                    <label htmlFor="zip">Zip</label>
                    <input
                        ref={zipRef}
                        name="zip"
                        placeholder="Enter Zip"
                        type="text"
                        className="form-input w-100"
                    />
                </div>
            </div>
            <div className="w-100 text-right mt-3">
                <span>
                    <ButtonOutlinePink evOnClick={handleCloseClick} title="Cancel" modal />
                </span>
                <span className="ml-2">
                    <ButtonGr evOnClick={() => handleContactForm()} title="Save" modal />
                </span>
            </div>
        </div>
    );
}

function PaymentDomainProfileModal({
    show,
    handelDomainProfileSave,
    openAddressEditor,
    closeEditors
}) {
    if (!show) {
        return null;
    }

    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <ModalHeader handleCloseClick={closeEditors} />
                <ModalBodyContact
                    openAddressEditor={openAddressEditor}
                    handleCloseClick={closeEditors}
                    handelDomainProfileSave={handelDomainProfileSave}
                />
            </div>
        </div>
    );
}

export default PaymentDomainProfileModal;
