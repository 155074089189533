import { icons } from '../../../../../configs/Icons';
import { DurationOptions } from '../../../websites/models/Pricing';

function ItemLogo() {
    return (
        <div className="item-logo">
            <img className="w-100 d-block" src={icons.iconDomainBg} alt="domain icon" />
        </div>
    );
}

function DomainDetails() {
    return (
        <div>
            <div className="mb-1">
                <span className="title break-word">RENEWITEM.COM</span>
                <span className="type-text d-block">(ITEM) renew</span>{' '}
            </div>
            <div className="duration-area">
                <span>
                    <DurationOptions className="form-input year mb-1 mb-sm-0" />
                </span>
                <span className="renew-text break-word">
                    Renews December 2022 for $99.9 (12-Mo term){' '}
                </span>
            </div>
        </div>
    );
}

function Pricing() {
    return (
        <div className="text-right">
            <span role="button" className="remove-text d-block mb-2 mb-sm-0">
                Remove
            </span>
            <span className="price-offer clr d-block">$49.99/Year</span>
            <span className="price-original clr d-block">$99.99</span>
            <span className="offer-text no-wrap clr d-block">50% off</span>
        </div>
    );
}

function RenewItemSection() {
    return (
        <div className="info-section">
            <div className="line line-g1 line-no-wrap">
                <div className="cell d-md-none">
                    <ItemLogo />
                </div>
                <div className="cell cell-fill">
                    <DomainDetails />
                </div>
                <div className="cell">
                    <Pricing />
                </div>
            </div>
        </div>
    );
}

export default RenewItemSection;
