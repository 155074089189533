import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { arrowIcons, domainIcons, editIcons, icons, userIcons } from '../../../../configs/Icons';
import { basic } from '../../../../configs/Images';
import Iopt from '../../../utils/Iopt';
import SupportImgOptions from './SupportImgOptions';

const list = [
    {
        id: 'support-ticket',
        title: 'Support Ticket',
        path: '/smanager/ticket/',
        icon: icons.iconSupportV4
    },
    {
        id: 'contracts',
        title: 'Contracts',
        path: '/smanager/contracts/',
        icon: icons.iconContract,
        subMenu: [
            {
                id: 'hosting/',
                title: 'Hosting',
                path: '/smanager/contracts/hosting/',
                icon: icons.iconWebHV4
            },
            {
                id: 'domain',
                title: 'Domain',
                path: '/smanager/contracts/domain/',
                icon: domainIcons.iconDomainV3
            },
            {
                id: 'server',
                title: 'Server',
                path: '/smanager/contracts/server/',
                icon: icons.iconDedicatedServer
            },
            {
                id: 'software',
                title: 'Software',
                path: '/smanager/contracts/software/',
                icon: icons.iconSocial
            },
            {
                id: 'sms',
                title: 'Sms',
                path: '/smanager/contracts/sms/',
                icon: icons.iconMessagingInactive
            },
            {
                id: 'email',
                title: 'Email',
                path: '/smanager/contracts/email/',
                icon: icons.iconEmailV3
            }
        ]
    },
    {
        id: 'billings',
        title: 'Billings',
        path: '/smanager/billings/',
        icon: icons.iconInvoiceV1
    },
    {
        id: 'cart',
        title: 'Cart',
        path: '/smanager/cart/',
        icon: icons.iconCartV2
    },
    {
        id: 'users',
        title: 'Users',
        path: '/smanager/users/',
        icon: userIcons.iconUserV8
    },
    {
        id: 'activity-log',
        title: 'Activity Log',
        path: '/smanager/activity-log/',
        icon: icons.iconBillingV1
    }
];

const ProfileInfo = ({ show, currentUser }) => {
    const [showOptions, setShowOptions] = useState(false);
    const navigate = useNavigate();
    if (!show) {
        return null;
    }

    return (
        <div className="profile-info">
            <div className="avatar-img-area">
                <img
                    className="avatar-img"
                    src={currentUser?.photoUrl ? Iopt(currentUser?.photoUrl) : basic.imgAvatar}
                    alt="Avatar"
                />
                <span
                    className="edit-area"
                    onClick={() => {
                        setShowOptions(true);
                    }}
                    role="button"
                    tabIndex={-1}
                >
                    <img src={editIcons.iconEditV3} alt="icon edit" />
                </span>
            </div>

            <SupportImgOptions
                showOptions={showOptions}
                setShowOptions={setShowOptions}
                // signOut={signOut}
            />
            <div className="profile-name">{currentUser?.displayName || <span>[Not Set]</span>}</div>
            <div className="profile-contact">{currentUser?.email || <span>[Not Set]</span>}</div>
            <div className="profile-button">
                <button
                    onClick={() => navigate('/user/profile-overview/')}
                    type="button"
                    className="button button-gr-read"
                    style={{ color: '#ffff' }}
                >
                    Profile Overview
                </button>
            </div>
        </div>
    );
};

const OptionSubList = ({ clickOnEndItem, subMenu, openL1, setOpenL1 }) => {
    if (!subMenu.length) {
        return null;
    }

    return (
        <ul className="label-1">
            {subMenu.map((item) => (
                <li key={item.id} className={openL1 === item.id ? 'open' : ''}>
                    <Link
                        to={item.path}
                        onClick={(ev) => {
                            setOpenL1(item.id);

                            if (typeof clickOnEndItem === 'function') clickOnEndItem(ev);
                        }}
                    >
                        <img className="icon" src={item.icon} alt={item.title} />
                        <span>{item.title}</span>
                    </Link>
                </li>
            ))}
        </ul>
    );
};

const OptionList = ({ removeMenus = [], clickOnEndItem, menuL0, menuL1 }) => {
    const [openL0, setOpenL0] = useState(menuL0);
    const [openL1, setOpenL1] = useState(menuL1);

    return (
        <div className="option-list">
            <ul>
                {list
                    .filter((item) => removeMenus.indexOf(item.id) === -1)
                    .map((item) => {
                        if (item.subMenu?.length) {
                            return (
                                <li key={item.id} className={openL0 === item.id ? 'open' : ''}>
                                    <a
                                        href={item.path}
                                        onClick={(ev) => {
                                            ev.preventDefault();

                                            setOpenL0(openL0 === item.id ? null : item.id);
                                        }}
                                    >
                                        <img className="icon" src={item.icon} alt={item.title} />
                                        <span>{item.title}</span>

                                        {item.subMenu?.length ? (
                                            <img
                                                className="arrow"
                                                src={arrowIcons.iconArrowRightV2}
                                                alt="icon arrow right"
                                            />
                                        ) : null}
                                    </a>

                                    {item.subMenu?.length ? (
                                        <OptionSubList
                                            subMenu={item.subMenu}
                                            openL1={openL1}
                                            setOpenL1={setOpenL1}
                                            clickOnEndItem={clickOnEndItem}
                                        />
                                    ) : null}
                                </li>
                            );
                        }
                        return (
                            <li key={item.id} className={openL0 === item.id ? 'open' : ''}>
                                <Link
                                    to={item.path}
                                    onClick={(ev) => {
                                        setOpenL0(item.id);

                                        if (typeof clickOnEndItem === 'function')
                                            clickOnEndItem(ev);
                                    }}
                                >
                                    <img className="icon" src={item.icon} alt={item.title} />
                                    <span>{item.title}</span>
                                </Link>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};

function SupportSidebarMenuSection({ hideProfile, clickOnEndItem, currentUser }) {
    const removeMenus = [];
    if (hideProfile) {
        removeMenus.push('dashboard');
    }

    return (
        <div className="profile-section ">
            <ProfileInfo show={!hideProfile} currentUser={currentUser} />

            {hideProfile ? null : <div className="divider" />}

            <OptionList
                menuL0="dashboard"
                menuL1="server_vps"
                removeMenus={removeMenus}
                clickOnEndItem={clickOnEndItem}
            />
        </div>
    );
}

export default SupportSidebarMenuSection;
