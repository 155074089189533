import React from 'react';
import { useSearch } from '../../../contexts/SearchProvider';
import SearchBottomMenu from './SearchBottomMenu';
import SearchInputSection from './SearchInputSection';

function HomeSearchSection() {
    const { isFocused } = useSearch();

    return (
        <div className="home-search-bg">
            <div className={`content-top ${isFocused ? 'focused' : ''}`}>
                <h3 className={`home-search-text ${isFocused ? 'content-top-text' : ''}`}>
                    Search your Domain Name
                    <br /> or Your Tech Support
                </h3>
            </div>
            <div className="content-center">
                <div className="another-page-search">
                    <SearchInputSection key="home" />
                </div>
            </div>
            <div className="content-bottom">
                <SearchBottomMenu isMenu />
            </div>
        </div>
    );
}

export default HomeSearchSection;
