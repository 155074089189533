import { ButtonOutlinePurple, ButtonPurple } from '../../../../../configs/Buttons';
import { basic } from '../../../../../configs/Images';

function WebPortalSection() {
    return (
        <div className="container mt-120 sms-page-area mb-6">
            <div className="line-align line-no-wrap line-sm-wrap service-area">
                <div className="cell">
                    <img className="w-100" src={basic.webPortal} alt="" />
                </div>
                <div className="cell-fill">
                    <div className="line-row-center text-sm-center">
                        <div>
                            <span className="title d-block mb-1">Web Portal</span>
                            <span className="value clr">
                                Experience the Messaging of better <br /> Management
                            </span>
                            <div className="line-align line-no-wrap mt-5">
                                <div>
                                    <ButtonOutlinePurple title="log in to Portal" />
                                </div>
                                <div className="ml-3">
                                    <ButtonPurple title="Get Started" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WebPortalSection;
