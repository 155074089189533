/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { ButtonOutlinePurple } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import { fileSizeDisplay } from '../../../../utils/Show';

const MenuItem = ({ title, subMenu }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSubItems = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="s-menu-item mb-sm-2">
            <div
                role="button"
                tabIndex={-1}
                className={`s-menu-item-header ${subMenu ? 'with-arrow' : ''} ${
                    isOpen ? 'active' : ''
                }`}
                onClick={toggleSubItems}
            >
                {/* {subMenu && <i className={`arrow-m ${isOpen ? 'up' : 'down'}`} />} */}
                <img
                    className="d-block mr-1"
                    src={isOpen ? icons.iconDownV2 : icons.iconRightV2}
                    alt=""
                />
                {title}
            </div>
            {isOpen && subMenu && subMenu.length > 0 && (
                <ul className="s-sub-menu">
                    {subMenu.map((subItem) => (
                        <SubMenuItem key={Math.random()} {...subItem} />
                    ))}
                </ul>
            )}
        </div>
    );
};

function SubMenuItemTitle({ owner, containedUsed }) {
    return (
        <div className="sub-item-title">
            <div className="line line-no-wrap line-sm-wrap">
                <div className="line-align line-no-wrap line-sm-wrap">
                    <span className="d-sm-block">{`${owner}:`}</span>
                    <span className="ml-1 d-sm-block no-wrap">
                        {fileSizeDisplay(containedUsed)}
                    </span>
                </div>
                <div>
                    <ButtonOutlinePurple evOnClick={null} title="backup" />
                </div>
            </div>
        </div>
    );
}

const SubMenuItem = ({ owner, contained_usage: containedUsed, subMenu }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSubItems = () => {
        setIsOpen(!isOpen);
    };

    return (
        <li className="s-sub-menu-item">
            <div
                role="button"
                tabIndex={-1}
                className={`s-sub-menu-item-header ${!subMenu ? 'active' : ''} ${
                    isOpen ? 'active' : ''
                }`}
                onClick={toggleSubItems}
            >
                {subMenu && (
                    <img
                        className="d-block mr-1"
                        src={isOpen ? icons.iconDownV2 : icons.iconRightV2}
                        alt=""
                    />
                )}
                <SubMenuItemTitle owner={owner} containedUsed={containedUsed} />
            </div>
            {isOpen && subMenu && subMenu.length > 0 && (
                <ul className="s-sub-sub-menu">
                    {subMenu.map((subItem) => (
                        <SubMenuItem key={Math.random()} {...subItem} />
                    ))}
                </ul>
            )}
        </li>
    );
};

function CollapseMenu({ title, subMenu }) {
    return <MenuItem title={title} subMenu={subMenu} />;
}

export default CollapseMenu;
