import { Link } from 'react-router-dom';
import { arrowIcons } from '../../../../../configs/Icons';
import LayoutSelector from '../../../../utils/LayoutSelector';
import SearchInput from '../../../../utils/inputs/SearchInput';
import Divider from '../../hostings/_models/SectionDivider';
import { useEmailHosting } from '../HostingInfoEmailProvider';
import EmailListTableMobile from './EmailListTableMobile';
import EmailListTableWeb from './EmailListTableWeb';

// const arr = [
//     {
//         id: '01',
//         email: 'namenamenamename@email.com-1',
//         timeExpire: 1832841135,
//         timeIssue: 1675074735,
//         status: 'okay'
//     },
//     {
//         id: '02',
//         email: 'name@email.com-2',
//         timeExpire: 1832841135,
//         timeIssue: 1675074735,
//         status: 'warning'
//     },
//     {
//         id: '03',
//         email: 'name@email.com-3',
//         timeExpire: 1832841135,
//         timeIssue: 1675074735,
//         status: 'okay'
//     },
//     {
//         id: '04',
//         email: 'name@email.com-4',
//         timeExpire: 1832841135,
//         timeIssue: 1675074735,
//         status: 'warning'
//     },
//     {
//         id: '05',
//         email: 'name@email.com-5',
//         timeExpire: 1832841135,
//         timeIssue: 1675074735,
//         status: 'blocked'
//     },
//     {
//         id: '06',
//         email: 'name@email.com-6',
//         timeExpire: 1832841135,
//         timeIssue: 1675074735,
//         status: 'okay'
//     },
//     {
//         id: '07',
//         email: 'name@email.com-7',
//         timeExpire: 1832841135,
//         timeIssue: 1675074735,
//         status: 'warning'
//     },
//     {
//         id: '08',
//         email: 'name@email.com-8',
//         timeExpire: 1832841135,
//         timeIssue: 1675074735,
//         status: 'okay'
//     },
//     {
//         id: '09',
//         email: 'name@email.com-9',
//         timeExpire: 1832841135,
//         timeIssue: 1675074735,
//         status: 'blocked'
//     },
//     {
//         id: '00',
//         email: 'name@email.com-10',
//         timeExpire: 1832841135,
//         timeIssue: 1675074735,
//         status: 'okay'
//     }
// ];

const demoArr = [
    {
        id: 1111111,
        email: '------------------',
        timeUpdate: 1677405713,
        demo: true
    }
];

function EmailListSection() {
    const { emailInfo } = useEmailHosting();
    const isExpand = emailInfo?.data?.length > 10;
    return (
        <div className="product-info table-v2 mb-4">
            <Divider
                title="List of Email"
                action={<SearchInput height="35px" cName="action-input" />}
            />

            <div className={isExpand ? 'mb-3' : ''}>
                <LayoutSelector
                    sm
                    md
                    webComp={<EmailListTableWeb arr={emailInfo?.data || demoArr} />}
                    mobileComp={<EmailListTableMobile arr={emailInfo?.data || demoArr} />}
                />
            </div>

            <Link hidden={!isExpand} className="purple font-16 fw-500" to="/">
                <div className="line-align line-no-wrap">
                    <span>View All Email</span>{' '}
                    <span>
                        <img
                            className="d-block ml-1"
                            src={arrowIcons.iconArrowLeftV3}
                            alt="arrow icon"
                        />
                    </span>
                </div>
            </Link>
        </div>
    );
}

export default EmailListSection;
