import React from 'react';
import { useNavigate } from 'react-router-dom';
import LayoutSelector from '../../../../utils/LayoutSelector';
import InvoiceListMobile from './InvoiceListMobile';
import InvoiceListWeb from './InvoiceListWeb';

const demoArr = [
    {
        id: 22002201,
        dateDue: '--------',
        items: '--------',
        priceOffer: 10000000,
        paidAmount: 10000000,
        status: '-------',
        isDemo: true
    },
    {
        id: 22002200,
        dateDue: '--------',
        items: '--------',
        priceOffer: 10000000,
        paidAmount: 10000000,
        status: '-------',
        isDemo: true
    }
];

function InvoiceTableList({ invoiceList }) {
    const navigate = useNavigate();
    return (
        <LayoutSelector
            sm
            md
            webComp={<InvoiceListWeb invoiceList={invoiceList || demoArr} navigate={navigate} />}
            mobileComp={
                <InvoiceListMobile invoiceList={invoiceList || demoArr} navigate={navigate} />
            }
        />
    );
}

export default InvoiceTableList;
