import { useState } from 'react';
import {
    ButtonActionCancel,
    ButtonActionDelete,
    ButtonActionEdit,
    ButtonActionSave
} from '../../../../configs/Buttons';
import { useLayout } from '../../../contexts/LayoutProvider';
import { useTemplate } from '../../../contexts/TemplateProvider';
import { useDomainInfo } from '../domains_info/DomainInfoBasicProvider';
import WarningPopup from '../_models/WarningPopup';
// import { useCnsInfo } from '../domains_info_cns/DomainInfoCnsProvider';

function CnsListActionButtons({ setViewType, viewType, data, id }) {
    const [modal, setModal] = useState();

    const { setMessage } = useTemplate();
    const { windowSizeName } = useLayout();
    const isMobile = windowSizeName === 'xs' || windowSizeName === 'sm' || windowSizeName === ' md';

    const { updateChildNameServer, removeChildNameServer, inputVal, handelReloadButton } =
        useDomainInfo();

    const handleCnsUpdate = () => {
        setMessage('Updating...');
        updateChildNameServer(data?.hostname, inputVal?.ip || data?.ip)
            .then(({ error, message }) => {
                if (error === 0) {
                    setViewType(null);
                    handelReloadButton();
                }

                setMessage(message);
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    const handelDelete = () => {
        setMessage('Deleting...');
        removeChildNameServer(data?.hostname, data?.ip).then(({ error, message }) => {
            if (error === 0) {
                handelReloadButton();
                setModal();
            }
            setMessage(message);
        });
    };

    if (viewType === id && viewType !== null) {
        return (
            <div
                className={`line-align line-no-wrap ${isMobile ? 'btn-action' : 'btn-action-web'}`}
            >
                <span className="">
                    <ButtonActionSave evOnClick={handleCnsUpdate} />
                </span>
                <span className="ml-2 ">
                    <ButtonActionCancel evOnClick={() => setViewType(null)} />
                </span>
            </div>
        );
    }
    return (
        <>
            <div
                className={`line-align line-no-wrap ${isMobile ? 'btn-action' : 'btn-action-web'}`}
            >
                <span className="">
                    <ButtonActionEdit evOnClick={() => setViewType(id)} />
                </span>
                <span className="ml-2 ">
                    <ButtonActionDelete evOnClick={() => setModal(!modal)} />
                </span>
            </div>
            <WarningPopup
                show={modal}
                setModal={setModal}
                message={
                    <div>
                        <span>
                            Deleting this CNS will disable all websites configured under it. Please
                            proceed with caution.
                        </span>
                        <div className="d-block font-14 clr">
                            <ul className="">
                                <li className="mb-1"> Hostname: {data?.hostname}</li>
                                <li>IP: {data?.ip}</li>
                            </ul>
                        </div>
                    </div>
                }
                evOnClick={handelDelete}
            />
        </>
    );
}

export default CnsListActionButtons;
