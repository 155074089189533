import React from 'react';
import PostmanHistoryContent from './PostmanHistoryContent';
import ReqDetailsSection from './ReqDetailsSection';

function HistoryArea({ setHistoryText }) {
    return (
        <div className="history-area border-right">
            <div className="border-bottom">
                <div className="history-title">History</div>
            </div>
            <PostmanHistoryContent setHistoryText={setHistoryText} />
        </div>
    );
}

function PostmanSection({ setHistoryText, historyText }) {
    return (
        <div className="postman-area-container mb-7">
            <div className="line line-no-wrap">
                <HistoryArea setHistoryText={setHistoryText} />
                <ReqDetailsSection historyText={historyText} />
            </div>
        </div>
    );
}

export default PostmanSection;
