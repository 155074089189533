import { useInit } from '../../../contexts/InitProvider';
import { GetDate } from '../../../utils/Show';
import { showUnitDuration } from '../../websites/models/Pricing';

const DomainRow = ({ item }) => (
    <tr className="domain-row">
        <td className="details">
            <b>{item?.title || '-------------'}</b>
            <p
                className="mt-1 mb-4"
                style={{ fontSize: '20px' }}
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{
                    __html: item?.description || '----------------'
                }}
            />
            Validity:{' '}
            <span>
                {GetDate(item?.subscriptionStart) || '---------'} to{' '}
                {GetDate(item?.subscriptionEnd) || '----------'}
            </span>
        </td>
        <td className="price">
            <span>{(item?.price * 12).toFixed(2) || '----------------'}/Year</span>
        </td>
        <td className="unit">
            <span>{item?.duration / 12 || '-------------'} Year</span>
        </td>
        <td className="value">
            <span>{(item?.price * item?.duration).toFixed(2) || '----------------'}</span>
        </td>
    </tr>
);

const HostingRow = ({ item }) => (
    <tr className="hosting-row">
        <td className="details">
            <b>{item?.title}</b>
            <p
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{
                    __html: item?.description || '------------------'
                }}
            />
            Validity:{' '}
            <span>
                {GetDate(item?.subscriptionStart)} to {GetDate(item?.subscriptionEnd)}
            </span>
        </td>
        <td className="price">
            <span>{(item?.price * 12).toFixed(2) || '----------------'}/Year</span>
        </td>
        <td className="unit">
            <span>{showUnitDuration(item?.duration) || '----------------'}</span>
        </td>
        <td className="value">
            <span>{(item?.price * item?.duration).toFixed(2) || '----------------'}</span>
        </td>
    </tr>
);

const ItemRow = ({ item }) => {
    if (item?.type === 'domain') {
        return <DomainRow item={item} />;
    }

    return <HostingRow item={item} />;
};

function InvoiceTableWeb({ invoiceData }) {
    const { items, currency } = invoiceData;
    const { locale } = useInit();
    return (
        <table className="invoice-products" cellSpacing={0}>
            <thead>
                <tr>
                    <th className="details">Item</th>
                    <th width={100}>Price ({currency || locale?.currency})</th>
                    <th className="unit" width={100}>
                        Unit
                    </th>
                    <th className="value" width={86}>
                        Total ({currency || locale?.currency})
                    </th>
                </tr>
            </thead>

            <tbody>
                {items?.map((item) => (
                    <ItemRow item={item} key={item?.id} />
                ))}
            </tbody>
        </table>
    );
}

export default InvoiceTableWeb;
