import React, { useRef, useState } from 'react';
import { basic } from '../../../../../configs/Images';
import { useRole } from '../RoleProvider';
import { status } from './RoleListStatus';
import ListActionButton from './RoleListTableActionBtn';

const demoArr = [
    { id: '--', name: '--------', date: '-----------', role: '---------', isDemo: true }
];

function RoleListTableMobile({ setModal }) {
    const [showOptions, setShowOptions] = useState(false);

    const ref = useRef();

    const { roles } = useRole();

    const arr = roles || demoArr;

    return (
        <div>
            {arr?.map((data) => (
                <div role="button" tabIndex={-1} key={data?.roleId + 1} className="list-item-sm">
                    <div className="line-row-between line-g1 line-no-wrap">
                        <div className="cell cell-fill overflow-dotted">
                            <div className="line-align line-g1 line-no-wrap">
                                <div className="cell">
                                    <div className="user-img">
                                        <span className="d-block">
                                            <img
                                                className="w-100 d-block"
                                                src={basic.imgAvatar}
                                                alt=""
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="cell cell-fill">
                                    <div className="line-align">
                                        <span className="title">{data?.name || '--'}</span>
                                        {status(data)}
                                    </div>
                                    <div>
                                        <span className="sub-title primary-50">
                                            ({data?.roleTitle})
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cell">
                            <ListActionButton
                                setModal={setModal}
                                setShowOptions={setShowOptions}
                                showOptions={data?.roleId === showOptions}
                                isShow={data?.buttons?.length > 0}
                                data={data}
                                myRef={ref}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default RoleListTableMobile;
