import { useEffect, useState } from 'react';
import { ButtonClose } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';

const ModalHeader = ({ setOpenList }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title">Email List</div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={() => setOpenList(null)} />
            </div>
        </div>
    </div>
);

function Placeholder() {
    const list = [1, 2, 3, 4];
    return (
        <ul className="profile-contact-list">
            {list.map((data) => (
                <li key={data} className="item placeholder-container">
                    <div
                        className="line-align placeholder-animation"
                        style={{ height: '20px', marginBottom: '5px' }}
                    >
                        <span className="cell-fill">{data}yeasinn2002@gmail.com</span>
                    </div>
                </li>
            ))}
        </ul>
    );
}

function ModalBody({ contactList }) {
    const [mode, setMode] = useState({ type: null, data: {} });

    // email copied icon hide
    useEffect(() => {
        if (mode?.type !== null) {
            setTimeout(() => {
                setMode({ type: null });
            }, 5000);
        }
    });

    // const { contactList, setMode, mode } = useProfileOverview();

    if (contactList.length === 0) {
        return <Placeholder />;
    }
    return (
        <ul className="profile-contact-list">
            {contactList.map((item) => (
                <li key={item?.id} className="item">
                    <div className="line-align">
                        <span className="cell-fill">{item?.email}</span>
                        <span className="cell">
                            <div
                                className="line cursor-pointer copy-icon-area"
                                role="button"
                                tabIndex={-1}
                                onClick={() => {
                                    navigator.clipboard.writeText(item?.email);
                                    if (navigator.clipboard.writeText(item?.email)) {
                                        setMode({ type: item?.userUid });
                                    }
                                }}
                            >
                                <img
                                    className="w-100"
                                    src={
                                        mode?.type === item?.userUid
                                            ? icons.iconCheckD
                                            : icons.iconCopy
                                    }
                                    alt="icon"
                                />
                            </div>
                        </span>
                    </div>
                </li>
            ))}
        </ul>
    );
}

function ProfileInfoEmailListPopup({ show, setOpenList, contactList }) {
    if (!show) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <ModalHeader setOpenList={setOpenList} />

                <ModalBody contactList={contactList} />
            </div>
        </div>
    );
}

export default ProfileInfoEmailListPopup;
