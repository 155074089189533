import { createContext, useContext, useEffect, useState } from 'react';
import AxiosAuth from '../../../utils/AxiosAuth';

const AddressContext = createContext();

export function useAddress() {
    return useContext(AddressContext);
}

function ProfileAddressProvider({ children, currentUser }) {
    const [addressList, setAddressList] = useState(null);
    const [listKey, setListKey] = useState(null);
    const [mode, setMode] = useState();

    const reloadList = () => {
        setListKey(Math.random());
        setAddressList(null);
    };

    const createAddress = (formattedAddress) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('PROFILE', 'address/create')
                .post({ address: formattedAddress })
                .then(({ data }) => {
                    if (data.error === 0) {
                        reloadList();
                    }
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const updateAddress = (addressId, formattedAddress, hash) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('PROFILE', 'address/update')
                .post({ addressId, address: formattedAddress, hash })
                .then(({ data }) => {
                    // console.log(data);
                    if (data.error === 0) {
                        reloadList();
                    }

                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const removeAddress = (addressId, hash) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('PROFILE', 'address/remove')
                .post({ addressId, hash })
                .then(({ data }) => {
                    if (data.error === 0) {
                        reloadList();
                    }

                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const setAddressAsPrimary = (addressId, hash) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('PROFILE', 'address/set-primary')
                .post({ addressId, hash })
                .then(({ data }) => {
                    // console.log(data);
                    if (data.error === 0) {
                        reloadList();
                    }

                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const getPrimaryAddress = () => {
        addressList.find((item) => item.isPrimary);
    };

    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .setApiUrl('PROFILE', 'address/list')
            .get({ listKey })
            .then(({ data }) => {
                if (data.error === 0) {
                    setAddressList(data.data);
                } else {
                    setAddressList([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentUser, listKey]);

    const value = {
        addressList,
        setAddressList,
        createAddress,
        updateAddress,
        removeAddress,
        setAddressAsPrimary,
        getPrimaryAddress,
        reloadList,
        mode,
        setMode
    };

    return <AddressContext.Provider value={value}>{children}</AddressContext.Provider>;
}

export default ProfileAddressProvider;
