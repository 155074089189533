import '../../assets/css/bikiran/style.webPlaceholder.css';
import { basic } from '../../configs/Images';

function EmptyPlaceholder() {
    return (
        <div className="logo-placeholder">
            <img src={basic.logo} alt="" />
        </div>
    );
}

export default EmptyPlaceholder;
