import { createContext, useContext, useState } from 'react';
import AxiosAuth from '../../../utils/AxiosAuth';

const UserProfileContext = createContext();

export function useBasicInfo() {
    return useContext(UserProfileContext);
}

function UserBasicInfoProvider({ children, currentUser, recheckLogin }) {
    const [formData, setFormData] = useState({});
    const [mode, setMode] = useState(null);

    const updateName = (fData) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('PROFILE', '/update-name/')
                .post(fData)
                .then(({ data }) => {
                    if (data.error === 0) {
                        recheckLogin();
                        setMode(null);
                    }

                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const updateUsername = (username, password) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('PROFILE', '/update-username/')
                .post({
                    username,
                    password
                })
                .then(({ data }) => {
                    if (data.error === 0) {
                        recheckLogin();
                        setMode(null);
                    }

                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const updateGender = (fData) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('PROFILE', '/update-gender/')
                .post(fData)
                .then(({ data }) => {
                    if (data.error === 0) {
                        recheckLogin();
                        setMode(null);
                    }

                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    const updateDob = (fData) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('PROFILE', '/update-dob/')
                .post(fData)
                .then(({ data }) => {
                    if (data.error === 0) {
                        recheckLogin();
                        setMode(null);
                    }

                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const value = {
        formData,
        setFormData,
        mode,
        setMode,
        updateName,
        updateUsername,
        updateGender,
        updateDob
    };

    return <UserProfileContext.Provider value={value}>{children}</UserProfileContext.Provider>;
}

export default UserBasicInfoProvider;
