import React, { useEffect } from 'react';
import { ButtonGr } from '../../../../configs/Buttons';
import { DashboardArrowHeader } from '../../headers/DashboardHeaderSection';

import PageProperties from '../../../utils/PageProperties';

function UserOrgRolePage() {
    useEffect(() => {
        PageProperties(`Organizational Role \u2022 Profile`);
    }, []);

    return (
        <div className="cell cell-content h-100">
            <div className="content-section">
                <div className="content-area">
                    <DashboardArrowHeader
                        title="Organizational Role"
                        path="/user/basic-info/"
                        content={
                            <div className="ml-6">
                                <ButtonGr title="Mark all as Read" />
                            </div>
                        }
                        mobileContent={
                            <span className="">
                                <ButtonGr title="Mark all as Read" />
                            </span>
                        }
                    />

                    <div className="content-body-scroll">
                        <div className="user-activity-log-area">
                            <div className="">
                                <div className="line-col-center mt-6">
                                    <span className="font-18 primary">
                                        Content will available soon...
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserOrgRolePage;
