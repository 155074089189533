import TicketListActionButton from './TicketListActionButton';

function ContentTop({ data }) {
    return (
        <div className="line-row-between mb-2">
            <div className="">
                <span className="title primary">{data?.subject}</span>
            </div>
            <div>
                <TicketListActionButton />
            </div>
        </div>
    );
}

function CommonRowArea({ title, value }) {
    return (
        <div className="line-row-between mb-1">
            <div className="cell">
                <span className="value primary-70">{title || '--'}:</span>
            </div>
            <div className="cell">
                <span className="value">{value || '--'}</span>
            </div>
        </div>
    );
}

function TicketListMobile({ arr }) {
    if (!arr?.length) {
        return (
            <div className="list-item-sm">
                <span className="title primary">No Tickets Found !</span>
            </div>
        );
    }
    return arr?.map((data) => (
        <div key={data?.id} className={`list-item-sm ${data?.demo ? 'placeholder-mode' : ''}`}>
            <ContentTop data={data} />
            <CommonRowArea title="Date" value={data?.date} />
            <CommonRowArea title="Issued By" value={data?.issuedBy} />
            <CommonRowArea
                title="Status"
                value={
                    <div style={{ color: data?.status === 'Solved' ? '#17DC69' : '#F69220' }}>
                        <span>{data?.status}</span>
                    </div>
                }
            />
        </div>
    ));
}

export default TicketListMobile;
