import React, { useEffect } from 'react';
import CommonPlaceholder from '../../../placeholders/components/CommonPlaceholder';
import PageProperties from '../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../headers/DashboardHeaderSection';

function UsersBackupPage() {
    useEffect(() => {
        PageProperties('Backup');
    }, []);

    return (
        <div className="cell cell-content h-100">
            <div className="content-section">
                <div className="content-area">
                    <DashboardDefaultHeader title="Backup" path={-1} />
                    <div className="content-body-scroll">
                        <CommonPlaceholder text="Backup" url="" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsersBackupPage;
