import { useRef, useState } from 'react';
import { ButtonActionDotMenuV2 } from '../../../../configs/Buttons';
import { basic } from '../../../../configs/Images';
import { GetDate, GetTime } from '../../../utils/Show';
import MenuOptions from './MenuOptions';

function Divider({ title, action }) {
    return (
        <div className="line align-end line-no-wrap line-g1 mb-4">
            <div className="cell">
                <div className="divider-title">{title}</div>
            </div>
            <div className="cell cell-fill">
                <hr className={`hr mb-1 ${action ? ' divider-line' : ''}`} />
            </div>
            {action && (
                <div className="cell">
                    <span className="divider-action">{action}</span>
                </div>
            )}
        </div>
    );
}
function UserImage({ data }) {
    return (
        <div className="user-image">
            <span className="d-block">
                <img
                    className="w-100 d-block font-14"
                    src={data?.profilePhoto || basic.imgAvatar}
                    alt="user"
                />
            </span>
        </div>
    );
}

function ActivityText({ data }) {
    return (
        <div className="">
            <p className="activity-text gap">
                <span className="mr-1">{data?.profileName}</span>
                <span className="opacity-medium fw-400">{data?.title}</span>
            </p>
            <div className="activity-time">
                {!data?.demo ? (
                    <span className="line-align line-no-wrap">
                        <span>{GetDate(data?.timeLogged)}</span>
                        <span className="ml-1 mr-1 font-16">&bull;</span>
                        <span>{GetTime(data?.timeLogged)}</span>
                    </span>
                ) : (
                    <span>----------------------------------</span>
                )}
            </div>
        </div>
    );
}

function ListItem({ arr }) {
    const [prevIndex, setPrevIndex] = useState('');
    const [showOptions, setShowOptions] = useState(null);
    const myRef = useRef();

    return arr?.map((data, index) => {
        if (data?.profileId === null || data?.profileId === 0) {
            return null;
        }

        return (
            <div
                key={data?.id}
                className={`log-list-area ${data?.demo ? 'placeholder-mode' : ''} `}
                onMouseEnter={() => setPrevIndex(index - 1)}
                onMouseLeave={() => setPrevIndex('')}
                style={{
                    borderBottom: prevIndex === index ? '1px solid transparent' : ''
                }}
            >
                <div className="line-align line-g1 line-no-wrap">
                    <div className="cell">
                        <UserImage data={data} />
                    </div>
                    <div className="cell cell-fill">
                        <ActivityText data={data} />
                    </div>
                    <div className="cell">
                        <div className="position-relative">
                            <div className="common-table-btn">
                                <ButtonActionDotMenuV2 evOnClick={() => setShowOptions(data?.id)} />
                            </div>
                            <div className="option-popup options-area">
                                <MenuOptions
                                    showOptions={showOptions === data?.id}
                                    setShowOptions={setShowOptions}
                                    myRef={myRef}
                                    data={data}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    });
}

function NotificationList({ arr }) {
    if (!arr?.length) {
        return <div className="font-16 primary mt-2 mb-2">No Notification Found!</div>;
    }
    return (
        <div className={!arr ? 'placeholder-mode' : ''}>
            <Divider title="Notification" />
            <ListItem arr={arr} />
        </div>
    );
}

export default NotificationList;
