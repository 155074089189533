import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { arrowIcons, icons } from '../../../configs/Icons';
import { useAuth } from '../../contexts/AuthProvider';
import LayoutSelector from '../../utils/LayoutSelector';
import { SideBarModal } from '../users/components/MobileSidebarMenu';

function Menu() {
    const { currentUser, makeAction, signOut } = useAuth();
    const [show, setShow] = useState(false);
    return (
        <div className="text-md-right">
            <span
                onClick={() => {
                    setShow(true);
                }}
                role="button"
                tabIndex={-1}
                className="menu-button d-block"
            >
                <img src={icons.iconMenuV2} alt="Menu Icon" className="d-block" />
            </span>

            <SideBarModal
                show={show}
                setShow={setShow}
                currentUser={currentUser}
                signOut={signOut}
                makeAction={makeAction}
            />
        </div>
    );
}

export function ArrowIcon({ path }) {
    const navigate = useNavigate();
    return (
        <div role="button" tabIndex={-1} onClick={() => navigate(path)} className="head-arrow-icon">
            <img className="w-100 d-block" src={arrowIcons.iconArrowLeftV2} alt="arrowIcon" />
        </div>
    );
}

export function MobileHeaderOld({
    title,
    subTitle,
    path,
    placeholder,
    mobileContent,
    headerContent
}) {
    const handelCname = () => {
        const cName = ['dashboard-header-section'];
        if (mobileContent) {
            cName.push('mobile-content');
        }
        if (placeholder) {
            cName.push('placeholder-mode');
        }
        return cName;
    };
    return (
        <div
            className={handelCname().join(' ')}
            // style={{ marginBottom: headerContent ? 0 : '10px' }}
        >
            <div className={`line-row-between line-no-wrap ${mobileContent ? 'mb-2' : ''}`}>
                <div className="cell cell-fill cell-sm-6">
                    <div className="line-align line-no-wrap">
                        <ArrowIcon path={path} />
                        <div className="ml-3 text-dotted">
                            <div className="line-col align-start text-dotted">
                                <span className="title gap d-block text-dotted">
                                    {title || '-----------------'}
                                </span>
                                <span className="font-12 d-block">{subTitle}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="cell cell-fill">
                    {headerContent ? (
                        <div className="line-align line-no-wrap justify-end">{headerContent}</div>
                    ) : (
                        <div className="line justify-end">
                            <Menu />
                        </div>
                    )}
                </div>
            </div>
            <div hidden={!mobileContent} className="w-100">
                {mobileContent}
            </div>
        </div>
    );
}

function MobileHeader2({ title, subTitle, path, placeholder, mobileContent }) {
    const handelCname = () => {
        const cName = ['dashboard-header-section'];
        // if (mobileContent) {
        //     cName.push('mobile-content');
        // }
        if (placeholder) {
            cName.push('placeholder-mode');
        }
        return cName;
    };
    return (
        <div className={handelCname().join(' ')}>
            <div className="line-row-between line-no-wrap">
                <div className="cell cell-fill cell-sm-7">
                    <div className="line-align line-no-wrap">
                        <ArrowIcon path={path} />
                        <div className="ml-3 text-dotted">
                            <div className="line-col align-start text-dotted">
                                <span className="title gap d-block text-dotted">
                                    {title || '-----------------'}
                                </span>
                                <span className="font-12 d-block">{subTitle}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="cell cell-fill">
                    <div className="line justify-end line-no-wrap right-content">
                        {mobileContent ? (
                            <div className="">{mobileContent}</div>
                        ) : (
                            <div className="">
                                <Menu />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export function DashboardDefaultHeaderBackup({
    title,
    titleText,
    subTitle,
    minHeight,
    path,
    content,
    placeholder,
    mobileContent,
    headerContent
}) {
    return (
        <LayoutSelector
            sm
            md
            lg
            webComp={
                <div
                    style={{ minHeight }}
                    className={`dashboard-header-section ${placeholder ? 'placeholder-mode' : ''}`}
                >
                    <div className="w-100">
                        <div className="line-align line-g1 line-no-wrap">
                            <div className="cell overflow-dotted">
                                {titleText || (
                                    <span className="title gap d-block overflow-dotted">
                                        {title || '-----------------'}
                                    </span>
                                )}
                                <span hidden={!subTitle} className="sub-title d-block">
                                    {subTitle}
                                </span>
                            </div>
                            <div className="cell cell-fill right-content">{content}</div>
                        </div>
                    </div>
                </div>
            }
            mobileComp={
                <MobileHeader2
                    title={title}
                    subTitle={subTitle}
                    path={path}
                    mobileContent={mobileContent}
                    headerContent={headerContent}
                />
            }
        />
    );
}
export function DashboardDefaultHeader({
    title,
    titleArea,
    subTitle,
    path,
    content,
    placeholder,
    mobileContent
}) {
    return (
        <LayoutSelector
            sm
            md
            lg
            webComp={
                <div
                    className={`dashboard-header-section ${placeholder ? 'placeholder-mode' : ''}`}
                >
                    <div className="line-align line-no-wrap w-100">
                        <div className="cell cell-6">
                            {titleArea || <span className="title">{title}</span>}
                            <span hidden={!subTitle} className="sub-title d-block">
                                {subTitle}
                            </span>
                        </div>
                        <div className="cell cell-6">
                            <div className="line justify-end right-content">{content}</div>
                        </div>
                    </div>
                </div>
            }
            mobileComp={
                <MobileHeader2
                    title={title}
                    subTitle={subTitle}
                    path={path}
                    placeholder={placeholder}
                    mobileContent={mobileContent}
                />
            }
        />
    );
}

export function DashboardArrowHeaderOld({
    title,
    titleText,
    subTitle,
    minHeight,
    path,
    content,
    placeholder,
    mobileContent,
    headerContent
}) {
    return (
        <LayoutSelector
            sm
            md
            lg
            webComp={
                <div
                    style={{ minHeight }}
                    className={`dashboard-header-section ${placeholder ? 'placeholder-mode' : ''}`}
                >
                    <div className="line-align line-no-wrap w-100">
                        <div>
                            <ArrowIcon path={path} />
                        </div>
                        <div className="ml-4">
                            <div className="line-col align-start">
                                {titleText || (
                                    <span className="title gap d-block overflow-dotted">
                                        {title || '-----------------'}
                                    </span>
                                )}
                                <span hidden={!subTitle} className="sub-title d-block">
                                    {subTitle}
                                </span>
                            </div>
                        </div>
                        <div className="cell cell-fill right-content">{content}</div>
                    </div>
                </div>
            }
            mobileComp={
                <MobileHeaderOld
                    title={title}
                    subTitle={subTitle}
                    path={path}
                    placeholder={placeholder}
                    mobileContent={mobileContent}
                    headerContent={headerContent}
                />
            }
        />
    );
}

export function DashboardArrowHeader({
    title,
    titleArea,
    subTitle,
    path,
    content,
    placeholder,
    mobileContent
}) {
    return (
        <LayoutSelector
            sm
            md
            lg
            webComp={
                <div
                    className={`dashboard-header-section ${placeholder ? 'placeholder-mode' : ''}`}
                >
                    <div className="line-align line-no-wrap w-100">
                        <div className="cell cell-6">
                            <div className="line-align line-no-wrap">
                                <div className="cell cell-1">
                                    <ArrowIcon path={path} />
                                </div>
                                <div className="cell cell-fill">
                                    {titleArea || <span className="title">{title}</span>}
                                    <span hidden={!subTitle} className="sub-title d-block">
                                        {subTitle}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="cell cell-6">
                            <div className="line justify-end right-content">{content}</div>
                        </div>
                    </div>
                </div>
            }
            mobileComp={
                <MobileHeader2
                    title={title}
                    subTitle={subTitle}
                    path={path}
                    placeholder={placeholder}
                    mobileContent={mobileContent}
                />
            }
        />
    );
}

function DashboardHeaderSection() {
    return null;
}

export default DashboardHeaderSection;
