function FloatingMessage() {
    // if (process.env.NODE_ENV === 'development') {
    //     return <div className="beta-text local">Local & Demo</div>;
    // }

    // if (process.env.REACT_APP_FLOATING_ST !== 'true') {
    //     return null;
    // }

    // return <div className="beta-text">{process.env.REACT_APP_FLOATING_MESSAGE}</div>;
    return null;
}

export default FloatingMessage;
