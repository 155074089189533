const api = 'https://files.bikiran.com/';

export const basic = {
    imgAvatar: `${api}assets/images/img-avatar.jpg`,
    domainImg: `${api}assets/images/domain-bg.svg`,
    emailImg: `${api}assets/images/email-bg.svg`,
    hostingImg: `${api}assets/images/hosting-bg.svg`,
    smsImg: `${api}assets/images/sms-bg.svg`,
    imgNotFound: `${api}assets/images/icon/icon-missing.svg`,
    logo: `${api}assets/images/logo-bikiran-1.svg`,
    oppsText: `${api}assets/images/opps-text.svg`,
    bahanno: `${api}assets/images/clients/bahanno.png`,
    barishalCadet: `${api}assets/images/clients/barishal-cadet.png`,
    bazel: `${api}assets/images/clients/bazel.png`,
    map: `${api}assets/images/clients/bd-map.png`,
    beacon: `${api}assets/images/clients/beacon.png`,
    callingBell: `${api}assets/images/clients/calling-bell.png`,
    clg: `${api}assets/images/clients/clg-scl.png`,
    demoLogo: `${api}assets/images/clients/demo-logo.png`,
    emc: `${api}assets/images/clients/emc.png`,
    feniCadet: `${api}assets/images/clients/feni-cadet.png`,
    himaloyCement: `${api}assets/images/clients/himaloy-cement.png`,
    janakantho: `${api}assets/images/clients/janakantho.png`,
    kitchenBari: `${api}assets/images/clients/kitchen-bari.png`,
    magel: `${api}assets/images/clients/magel.png`,
    noaparaTd: `${api}assets/images/clients/noapara-td.png`,
    rupkatha: `${api}assets/images/clients/rupkatha.png`,
    saltNSuger: `${api}assets/images/clients/salt-and-suger.png`,
    sylhetCadet: `${api}assets/images/clients/sylhet-cadet.png`,
    iconDrawing: `${api}assets/images/icon/icon-drawing.svg`,
    iconFly: `${api}assets/images/icon/icon-fly.svg`,
    iconPc: `${api}assets/images/icon/icon-pc.svg`,
    iconStairs: `${api}assets/images/icon/icon-stairs.svg`,
    cpuInd: `${api}assets/images/icon/chart-indicator.svg`,
    cpuBg: `${api}assets/images/icon/dashboard-status.svg`,
    devImg: `${api}assets/images/dev.png`,
    webPortal: `${api}assets/images/web-portal-bg.png`,
    mobileAppIcon: `${api}assets/images/sms-m-app.png`,
    iconMenu: `${api}assets/images/images/icon/icon-menu.svg`,
    iconProjectSelector: `${api}assets/images/icon/icon-project-selector.svg`,
    logoBikiran1: `${api}assets/images/logo-bikiran-1.svg`,

    logoFb: `${api}assets/images/logo-fb.png`,
    logoGithub: `${api}assets/images/logo-github.png`,
    logoSkype: `${api}assets/images/logo-skype.png`,
    logoTwitter: `${api}assets/images/logo-twitter.png`,
    logoWhatsapp: `${api}assets/images/logo-whatsapp.png`
};

function Images() {
    return null;
}

export default Images;
