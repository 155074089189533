/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { ButtonClose, ButtonGr } from '../../../../../configs/Buttons';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import CopyText from '../../../websites/models/CopyText';
import { useHostingInfoBasic } from '../HostingInfoBasicProvider';

const ModalHeader = ({ handelCloseClick }) => {
    const { basicInfo } = useHostingInfoBasic();
    const { domain } = basicInfo || {};
    return (
        <div className="modal-box-header">
            <div>
                <div className="modal-box-title sub-title">
                    <span>Share information</span>
                    <span>
                        <small>
                            <CopyText copyValue={domain} value={domain?.toUpperCase()} />
                        </small>
                    </span>
                </div>
                <div className="modal-box-close">
                    <ButtonClose evOnClick={handelCloseClick} />
                </div>
            </div>
        </div>
    );
};

function EmailInput({ setFormData, setEmails, emails }) {
    const handelEmailInput = (emailArr) => {
        setEmails(emailArr);
        setFormData((cuData) => ({ ...cuData, emails: [emailArr] }));
    };
    return (
        <div className="mb-2 multi-mail-input">
            <Divider title="Receiver Email" />

            <ReactMultiEmail
                placeholder="Type Email..."
                emails={emails}
                onChange={(_emails: string[]) => handelEmailInput(_emails)}
                autoFocus
                getLabel={(email, index, removeEmail) => (
                    <div data-tag key={index}>
                        <div data-tag-item>{email}</div>
                        <span
                            role="button"
                            tabIndex={-1}
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                        >
                            ×
                        </span>
                    </div>
                )}
            />
        </div>
    );
}

function CustomMessageInput({ setFormData }) {
    return (
        <div className="mb-2 multi-mail-input">
            <Divider title="Custom Message" />

            <input
                onChange={(e) => setFormData((cuData) => ({ ...cuData, message: e.target.value }))}
                placeholder="message..."
                type="text"
                className="form-input w-100"
            />
        </div>
    );
}

function Divider({ hidden, title }) {
    return <div className={`font-16 fw-500 mb-1 ${hidden ? 'd-none' : ''}`}>{title}</div>;
}

function InfoItem({ title, value, margin }) {
    return (
        <div className={`line line-no-wrap line-g2 ${margin || 'mb-1'}`}>
            <div className="cell cell-4 ">
                <span className="subject">{title}</span>
            </div>
            <div className="cell">
                <span>:</span>
            </div>
            <div className="cell cell-fill overflow-dotted">
                <span className="value">{value || '--'}</span>
            </div>
        </div>
    );
}

function InfoArea() {
    const [showInfo, setShowInfo] = useState(false);

    const { basicInfo } = useHostingInfoBasic();

    const { title, domain, disk, bandwidth, cpu, ram, ep, io, ip, username, nameservers, email } =
        basicInfo || {};

    return (
        <>
            <Divider title="Information" />
            <div
                className={`info-area ${
                    showInfo ? 'overflow-auto active' : 'inactive overflow-hidden'
                }`}
            >
                <Divider title="Basic Information" />
                <InfoItem title="Package" value={title} />
                <InfoItem
                    title="Domain"
                    value={<CopyText copyValue={domain} value={domain?.toUpperCase()} />}
                    margin="mb-2"
                />

                <Divider title="recourses Information" />
                <InfoItem title="Disk Space" value={`${disk / 1024} GB`} />
                <InfoItem title="Bandwidth" value={`${bandwidth / 1024} GB`} />
                <InfoItem title="CPU" value={`${cpu} Core`} />
                <InfoItem title="RAM" value={`${ram / 1024} GB`} />
                <InfoItem title="EP" value={ep} />
                <InfoItem title="I/O" value={`${io} Mb/s`} margin="mb-2" />

                <Divider title="cPanel Access Information" />
                <InfoItem title="IP Address" value={ip} />
                <InfoItem title="cPanel" />
                <InfoItem title="User Name" value={username} />
                <InfoItem title="Password" margin="mb-2" />

                <Divider hidden={!nameservers?.length} title="Name Servers" />
                {nameservers?.map((data, index) => (
                    <InfoItem key={data} title={`Name Server${index + 1}`} value={data} />
                ))}

                <Divider title="cPanel Access Information" />
                <InfoItem title="Contact Email" value={email} />
                <InfoItem title="Phone Number" margin="mb-7" />

                <span
                    onClick={() => setShowInfo(!showInfo)}
                    role="button"
                    tabIndex={-1}
                    className="show-text"
                >
                    {showInfo ? 'Less' : 'More'}
                </span>
                <div className="bg-layer" />
            </div>
        </>
    );
}

function ModalBody({ setEmails, emails, formData, setFormData }) {
    const { setMessage } = useTemplate();

    const handelSendButton = () => {
        setMessage('Sending Email...');
        // sendFunc()
        //     .then(({ error, message }) => {
        //         if (error === 0) {
        //             handelCloseClick();
        //         }
        //         setMessage(message);
        //     })
        //     .catch((err) => {
        //         setMessage(err);
        //     });
    };

    return (
        <div className="modal-body-area overflow-auto">
            <EmailInput setFormData={setFormData} setEmails={setEmails} emails={emails} />

            <InfoArea />

            <CustomMessageInput setFormData={setFormData} formData={formData} />

            <div className="line justify-end">
                <ButtonGr disabled={!emails} evOnClick={handelSendButton} title="Send" modal />
            </div>
        </div>
    );
}

function InfoMailPopup({ show, setModal }) {
    const [emails, setEmails] = useState([]);
    const [formData, setFormData] = useState([]);

    const handelCloseClick = () => {
        setEmails([]);
        setModal(false);
    };

    if (!show) {
        return null;
    }
    return (
        <div className="modal-box backdrop share-info-popup">
            <div className="modal-box-content">
                <ModalHeader handelCloseClick={handelCloseClick} />

                <ModalBody
                    handleCloseClick={handelCloseClick}
                    setEmails={setEmails}
                    emails={emails}
                    setFormData={setFormData}
                    formData={formData}
                />
            </div>
        </div>
    );
}

export default InfoMailPopup;
