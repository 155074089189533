import { useState } from 'react';
import { useCart } from '../../../contexts/CartProvider';
import { useInit } from '../../../contexts/InitProvider';
import { useTemplate } from '../../../contexts/TemplateProvider';
import ModalBox from '../../../utils/ModalBox';
import {
    CurrencyFormatter,
    DurationOptions,
    ShowCurrencySign,
    showPriceDomain
} from '../models/Pricing';

const TableInfo = ({ productInfo, offerPrice, duration }) => (
    <table className="hosting-info">
        <tbody>
            <tr>
                <td colSpan={2} style={{ opacity: 1 }} className="break-all">
                    <span style={{ opacity: 0.5 }}>Domain </span>
                    <span>: {productInfo.property.domain?.toUpperCase()}</span>
                </td>
            </tr>
            <tr>
                <td>1s Year Price</td>
                <td>
                    : {ShowCurrencySign()}
                    {CurrencyFormatter(offerPrice?.price / (duration / 12))} <br />
                </td>
            </tr>
            <tr>
                <td>Renew</td>
                <td>
                    : {ShowCurrencySign()}
                    {CurrencyFormatter(productInfo?.price?.renew * 12)}/yr
                </td>
            </tr>

            {offerPrice?.save > 0 ? (
                <tr>
                    <td>You Saved</td>
                    <td>
                        <small className="purchase-domain-offer-txt">
                            : {ShowCurrencySign()}
                            {CurrencyFormatter(offerPrice?.save || 0)}
                        </small>
                    </td>
                </tr>
            ) : null}
        </tbody>
    </table>
);

function PurchaseDomainModal({ productInfo, setPurchaseData }) {
    const [duration, setDuration] = useState(productInfo?.selectedDuration);
    const { resetState, handleAddToCart } = useCart();
    const { setMessage } = useTemplate();

    const { locale } = useInit();

    const handleCloseClick = () => {
        setPurchaseData(null);
        resetState();
    };

    const handleYearChange = (ev) => {
        setDuration(ev.target.value);
    };

    if (productInfo === null) {
        return null;
    }

    const offerPrice = productInfo?.durations.filter(
        (item) => item.duration === parseInt(duration, 10)
    )[0];

    return (
        <ModalBox
            show
            handleCloseClick={handleCloseClick}
            header={<div className="modal-box-title">Add domain to cart</div>}
            body={
                <div className="login-input">
                    <div className="line mb-3">
                        <div className="cell cell-fill">
                            <TableInfo
                                duration={duration}
                                offerPrice={offerPrice}
                                productInfo={productInfo || null}
                            />
                        </div>
                        <div className="cell">
                            <div className="price-box mb-3">
                                <span>{locale?.currency}</span>
                                <span>{CurrencyFormatter(offerPrice?.price || 0)}</span>
                                <span>
                                    &nbsp;{locale?.currency}{' '}
                                    {CurrencyFormatter(showPriceDomain(productInfo, duration) || 0)}
                                    &nbsp;
                                </span>
                            </div>

                            <DurationOptions
                                productInfo={productInfo}
                                className="form-input w-100"
                                handleYearChange={handleYearChange}
                                duration={duration}
                                style={{ fontSize: '13px' }}
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            type="button"
                            className="add-to-cart button w-100"
                            onClick={(ev) => {
                                setMessage('Loading...');
                                handleAddToCart({
                                    ...productInfo,
                                    duration
                                }).then(({ message }) => {
                                    handleCloseClick(ev);
                                    setMessage(message);
                                });
                            }}
                        >
                            Add to Cart
                        </button>
                    </div>
                </div>
            }
        />
    );
}

export default PurchaseDomainModal;
