import { createContext, useContext, useState } from 'react';
import AxiosAuth from '../../../utils/AxiosAuth';

const ForgetPasswordContext = createContext();

export function useForgetPassword() {
    return useContext(ForgetPasswordContext);
}

const initialState = {
    type: 'email',
    username: '',
    sentId: '',
    otp: '',
    otpKey: '',
    otpIsVerified: false,
    password1: '',
    password2: ''
};

const otpBlankAr = [' ', ' ', ' ', ' ', ' ', ' '];

function ForgetPasswordProvider({ children }) {
    const [formData, setFormData] = useState({ ...initialState });

    const handelOtpSend = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.setApiUrl('AUTH', '/forget-password/send-otp/')
                .post(formData)
                .then(({ data }) => {
                    if (data.error === 0) {
                        setFormData((cVal) => ({
                            ...cVal,
                            otp: '',
                            sentId: data.sentId,
                            focusIndex: 0
                        }));
                    }

                    resolve({
                        error: data.error,
                        message: data.message
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const handelVerifyOtp = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.setApiUrl('AUTH', '/forget-password/verify-otp/')
                .post(formData)
                .then(({ data }) => {
                    if (data.error === 0) {
                        setFormData((cVal) => ({
                            ...cVal,
                            otpIsVerified: true
                        }));
                    }

                    resolve({
                        error: data.error,
                        message: data.message
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const handleChangePassword = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.setApiUrl('AUTH', '/forget-password/change/')
                .post(formData)
                .then(({ data }) => {
                    if (data.error === 0) {
                        setFormData((cVal) => ({
                            ...cVal,
                            otpIsVerified: true
                        }));
                    }

                    resolve({
                        error: data.error,
                        message: data.message
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const setInitialState = () => {
        setFormData({ ...initialState });
    };

    const value = {
        otpBlankAr,
        formData,
        setFormData,
        handelOtpSend,
        handelVerifyOtp,
        handleChangePassword,
        setInitialState
    };

    return (
        <ForgetPasswordContext.Provider value={value}>{children}</ForgetPasswordContext.Provider>
    );
}

export default ForgetPasswordProvider;
