import { useState } from 'react';

function TwoFacAuthSecurityHead() {
    const [mode, setMode] = useState(false);
    return (
        <div className="info-list-header ">
            <div className="line-row-between">
                <span className="fs-16 fw-500 no-wrap opacity-medium">Security Method</span>
                <span
                    onClick={() => setMode(!mode)}
                    role="button"
                    tabIndex={-1}
                    className="purple fs-16 fw-500 no-wrap"
                >
                    Add Phone Number
                </span>
            </div>
        </div>
    );
}

export default TwoFacAuthSecurityHead;
