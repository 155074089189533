import { ButtonClose, ButtonGr, ButtonOutlinePink } from '../../../../../configs/Buttons';

const ModalHeader = ({ setMode }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title ">Two-Factor Authentication</div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={() => setMode(null)} />
            </div>
        </div>
    </div>
);

function ModalBody({ setMode }) {
    return (
        <div className="modal-body-area">
            <span className="fs-16 fw-400 text-center mb-3 opacity-medium">
                You will no longer receive a verification code if we notice an attempted login from
                a device or browser we don`t recognize.
            </span>
            <div className="border-bottom mb-4 mt-2" />
            <div className="line-align justify-end ">
                <span>
                    <ButtonOutlinePink evOnClick={() => setMode(null)} title="Cancel" modal />
                </span>
                <span className="ml-2">
                    <ButtonGr title="Turn Off" modal />
                </span>
            </div>
        </div>
    );
}

function TwoFacAuthSecurityUpdatePopup({ show, setMode }) {
    if (!show) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <ModalHeader setMode={setMode} />

                <ModalBody setMode={setMode} />
            </div>
        </div>
    );
}

export default TwoFacAuthSecurityUpdatePopup;
