import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import PageProperties from '../../../utils/PageProperties';
import DomainInfoBasicProvider, { useDomainInfo } from '../domains_info/DomainInfoBasicProvider';
import DomainInfoHeader from '../domains_info_header/DomainInfoHeader';
import TabsRowSection from '../domains_info_tabs/TabsRowSection';
import RoleSection from '../role/RoleSection';
import InfoWarningSection from '../_models/info-warning-section/InfoWarningSection';
import BasicInfoBillingSection from './basicinfo_billing/BasicInfoBillingSection';
import BasicInfoDomainInfoSection from './basicinfo_domaininfo/BasicInfoDomainInfoSection';
import BasicInfoDomainPsection from './basicinfo_domainp/BasicInfoDomainPsection';
import BasicInfoDzoneSection from './basicinfo_dzone/BasicInfoDzoneSection';
import InfoSection from './basicinfo_info/InfoSection';

function ContentHead() {
    const { domainInfo, handelReloadButton } = useDomainInfo();
    // console.log(domainInfo);
    return <DomainInfoHeader evReloadClick={handelReloadButton} info={domainInfo} path="" />;
}

function ContentBody({ contractId }) {
    const { isDomainActive, domainInfo } = useDomainInfo();
    return (
        <div className="content-body-scroll" style={{ paddingBottom: '180px' }}>
            <div className="domain-details-page domain-info-page">
                <TabsRowSection basicInfo isActive={domainInfo && isDomainActive} />
                <InfoWarningSection info={domainInfo?.message || {}} />
                <InfoSection />
                <BasicInfoDomainPsection />
                <BasicInfoBillingSection />
                <BasicInfoDzoneSection />
                <RoleSection
                    tbl="domain"
                    contractId={contractId}
                    title="Domain Access"
                    domain={domainInfo?.domain}
                />
                <BasicInfoDomainInfoSection />
            </div>
        </div>
    );
}

function DomainInfoBasicPage() {
    const { currentUser } = useAuth();
    const { contractId } = useParams();

    useEffect(() => {
        PageProperties(`Basic Info \u2022 Domain`);
    }, []);

    return (
        <DomainInfoBasicProvider currentUser={currentUser} contractId={contractId}>
            <div className="cell cell-content h-100">
                <div className="content-section">
                    <div className="content-area">
                        <ContentHead />
                        <div className="content-body">
                            <ContentBody contractId={contractId} />
                        </div>
                    </div>
                </div>
            </div>
        </DomainInfoBasicProvider>
    );
}

export default DomainInfoBasicPage;
