import React from 'react';
import { socialIcon } from '../../../../configs/Icons';

const SocialLoginButtons = ({
    formData,
    signOut,
    signInWithGoogle,
    signInWithFacebook,
    signInWithGithub,
    setMessage
}) => {
    const providerId = formData?.providerId || '';

    const handelGoogleLogin = () => {
        setMessage({
            text: 'Logging in ... through Google account',
            timeout: 6000
        });

        signInWithGoogle()
            .then(({ message }) => {
                setMessage({
                    text: message,
                    timeout: 8000
                });
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    const handelFacebookLogin = () => {
        setMessage({
            text: 'Logging in ... through Facebook account',
            timeout: 6000
        });

        signInWithFacebook()
            .then(({ message }) => {
                setMessage({
                    text: message,
                    timeout: 8000
                });
            })
            .catch((err) => console.log(err.message));
    };

    const handelGithubLogin = () => {
        setMessage({
            text: 'Logging in ... through Github account',
            timeout: 60000
        });

        signInWithGithub()
            .then(({ message }) => {
                setMessage({
                    text: message,
                    timeout: 8000
                });
            })
            .catch((err) => console.log(err.message));
    };

    return (
        <>
            {providerId ? (
                <div className="reg-reset">
                    <button type="button" onClick={signOut}>
                        Reset
                    </button>
                </div>
            ) : null}

            <div className="line line-g2 mb-2">
                <div className="cell cell-4 cell-sm-12 mb-2">
                    <button
                        className="button social-login-btn-gl w-sm-100 w-100 button-login-op login-btn"
                        type="button"
                        onClick={handelGoogleLogin}
                        disabled={providerId}
                    >
                        <img src={socialIcon.google} alt="Google" />
                        <span>Google</span>
                    </button>
                </div>

                <div className="cell cell-4 cell-sm-12 mb-2">
                    <button
                        className="button social-login-btn-fb w-sm-100 w-100 button-login-op login-btn"
                        type="button"
                        onClick={handelFacebookLogin}
                        disabled={providerId}
                    >
                        <img src={socialIcon.facebook} alt="Facebook" />
                        <span>Facebook</span>
                    </button>
                </div>

                <div className="cell cell-4 cell-sm-12">
                    <button
                        className="button social-login-btn-gt w-sm-100 w-100 button-login-op login-btn"
                        type="button"
                        onClick={handelGithubLogin}
                        disabled={providerId}
                    >
                        <img src={socialIcon.github} alt="Github" />
                        <span>Github</span>
                    </button>
                </div>
            </div>
        </>
    );
};

export default SocialLoginButtons;
