import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import PageProperties from '../../../utils/PageProperties';
import DomainInfoBasicProvider, { useDomainInfo } from '../domains_info/DomainInfoBasicProvider';
import DomainInfoHeader from '../domains_info_header/DomainInfoHeader';
import TabsRowSection from '../domains_info_tabs/TabsRowSection';
import SectionDivider from '../_models/section_divider/SectionDivider';
import DomainInfoTransferStepSection from './domain_info_transfer_stage/DomainInfoTransferStepSection';

function ContentHead() {
    const { domainInfo, handelReloadButton } = useDomainInfo();
    return (
        <DomainInfoHeader
            evReloadClick={handelReloadButton}
            info={domainInfo}
            path="transfer-out/"
        />
    );
}

function ContentBody() {
    return (
        <div className="content-body-scroll" style={{ paddingBottom: '120px' }}>
            <TabsRowSection transfer />
            <div className="product-info domain-transfer-page">
                <SectionDivider title="Transfer Out" />
                <DomainInfoTransferStepSection />
            </div>
        </div>
    );
}

function DomainInfoTransferPage() {
    const { contractId } = useParams();
    const { currentUser } = useAuth();

    useEffect(() => {
        PageProperties(`Transfer Out \u2022 Domain`);
    }, []);

    return (
        <DomainInfoBasicProvider currentUser={currentUser} contractId={contractId}>
            <div className="cell cell-content h-100">
                <div className="content-section">
                    <div className="content-area">
                        <ContentHead />

                        <ContentBody />
                    </div>
                </div>
            </div>
        </DomainInfoBasicProvider>
    );
}

export default DomainInfoTransferPage;
