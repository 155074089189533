import { ButtonClose } from '../../../../configs/Buttons';
import ForgetPasswordModalArea from './ForgetPasswordModalArea';

const ModalHeader = ({ setExitModal, modalProperty }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title">Forget Password?</div>
            <div className="modal-box-close">
                <ButtonClose
                    evOnClick={
                        typeof modalProperty.onExit === 'function'
                            ? modalProperty.onExit
                            : setExitModal
                    }
                />
            </div>
        </div>
    </div>
);

const ModalBody = ({ setExitModal, makeAction }) => (
    <div className="modal-box-body">
        <div>
            <ForgetPasswordModalArea setExitModal={setExitModal} makeAction={makeAction} />
        </div>
    </div>
);

function ForgetPasswordModal({ setExitModal, makeAction, modalProperty }) {
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <ModalHeader setExitModal={setExitModal} modalProperty={modalProperty} />

                <ModalBody setExitModal={setExitModal} makeAction={makeAction} />
            </div>
        </div>
    );
}

export default ForgetPasswordModal;
