import { icons } from '../../../../configs/Icons';
import OtpInputs from '../../../utils/inputs/OtpInputs';
import { InpPassword, InpPasswordSBS } from '../../../utils/inputs/PasswordInputs';
import { InpEmail } from '../../../utils/inputs/TextInputs';
import { BackToLogin } from '../components/AuthLinks';
import ForgetPasswordButtons from './ForgetPasswordButtons';
import { useForgetPassword } from './ForgetPasswordProvider';

function ForgetPasswordModalArea({ setExitModal, makeAction }) {
    const {
        formData,
        setFormData,
        handelOtpSend,
        handelVerifyOtp,
        handleChangePassword,
        setInitialState
    } = useForgetPassword();

    const handelUpdateUsername = (ev) => {
        const { value } = ev.target;
        // const type = ValidateUsernamePattern(value);

        setFormData((cVal) => ({
            ...cVal,
            // type,
            username: value,
            sentId: ''
        }));
    };

    const handelUpdatePassword = (ev) => {
        const { name, value } = ev.target;
        setFormData((cVal) => ({
            ...cVal,
            [name]: value
        }));
    };

    return (
        <div className="login-modal-area">
            <div className="line line-g2">
                <div className="cell cell-12 input-grp mb-2">
                    <InpEmail
                        value={formData.username}
                        setValue={handelUpdateUsername}
                        title="Email Address"
                        placeholder="ex: username@email.com"
                        vendorIcon={icons.iconEmailV1}
                        isChecked={formData.otpIsVerified}
                        disabled={formData.sentId || formData.providerId}
                    />
                </div>

                {!formData.otpIsVerified ? (
                    <div className="cell cell-12 mb-2">
                        <OtpInputs
                            formData={formData}
                            setFormData={setFormData}
                            handelOtpSend={handelOtpSend}
                            key={formData.otpKey}
                        />
                    </div>
                ) : null}

                {formData.otpIsVerified ? (
                    <div className="cell cell-12 input-grp login-pass mb-4">
                        <InpPasswordSBS>
                            <InpPassword
                                value={formData.password1}
                                setValue={handelUpdatePassword}
                                title="Password"
                                name="password1"
                            />
                            <InpPassword
                                value={formData.password2}
                                setValue={handelUpdatePassword}
                                title="Verify Password"
                                name="password2"
                            />
                        </InpPasswordSBS>
                    </div>
                ) : null}
            </div>

            <div className="mb-3">
                <ForgetPasswordButtons
                    formData={formData}
                    setFormData={setFormData}
                    handelVerifyOtp={handelVerifyOtp}
                    handelOtpSend={handelOtpSend}
                    handleChangePassword={handleChangePassword}
                    setInitialState={setInitialState}
                    setExitModal={setExitModal}
                />
            </div>

            <BackToLogin makeAction={makeAction} />
        </div>
    );
}

export default ForgetPasswordModalArea;
