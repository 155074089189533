import { useState } from 'react';
import { ButtonActionDetails } from '../../../../../configs/Buttons';
import { useDomainInfo } from '../../domains_info/DomainInfoBasicProvider';
import BasicInfoDomainSecretPopup from './BasicInfoDomainSecretPopup';
// import BasicInfoPasswordPopup from './BasicInfoPasswordPopup';

function PasswordView({ evOnClick, value }) {
    const { isDomainActive } = useDomainInfo();
    const domainChangeSecret = true;

    return (
        <div className="info-edit-area w-100">
            <div className="line-row-between line-no-wrap">
                <div className="cell cell-10">
                    <span className="value">{value || '--------------------------'}</span>
                </div>
                <div hidden={!domainChangeSecret || !isDomainActive} className="cell">
                    <div className="action-btn ml-2">
                        <ButtonActionDetails evOnClick={evOnClick} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function BasicInfoDomainSecret() {
    const [modal, setModal] = useState(false);

    return (
        <>
            <div className="line mb-3">
                <div className="cell cell-5">
                    <span className="subject">Domain Secret:</span>
                </div>
                <div className="cell cell-7 word-break">
                    <span className="value line-align">
                        <PasswordView
                            evOnClick={() => setModal(!modal)}
                            value="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                        />
                    </span>
                </div>
            </div>

            <BasicInfoDomainSecretPopup show={modal} setModal={setModal} />
        </>
    );
}

export default BasicInfoDomainSecret;
