import React from 'react';
import LayoutSelector from '../../../utils/LayoutSelector';
import InvoiceTableMobile from './InvoiceTableMobile';
import InvoiceTableWeb from './InvoiceTableWeb';

function InvoiceItem({ invoiceData, print }) {
    if (print) {
        return <InvoiceTableWeb invoiceData={invoiceData} />;
    }
    return (
        <LayoutSelector
            sm
            md
            webComp={<InvoiceTableWeb invoiceData={invoiceData} />}
            mobileComp={<InvoiceTableMobile invoiceData={invoiceData} />}
        />
    );
}

export default InvoiceItem;
