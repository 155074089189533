import { useNavigate } from 'react-router-dom';
import { loginAreaIcons } from '../../../../../configs/Icons';
import { useCart } from '../../../../contexts/CartProvider';

function CartIcon() {
    const { cartData } = useCart();
    const navigate = useNavigate();
    return (
        <div onClick={() => navigate('/cart/')} className="icon-cart" role="button" tabIndex={-1}>
            <div className="cart-area-s line-align line-md-none">
                <img src={loginAreaIcons.iconCartV1} alt="icon cart" />
                {cartData.length > 0 ? (
                    <span className="cart-length">{cartData.length}</span>
                ) : null}
            </div>
        </div>
    );
}

export default CartIcon;
