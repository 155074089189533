function PostmanHistoryContent({ setHistoryText }) {
    const history = [
        {
            id: 1,
            title: 'https://thawing-basin-06378.herokuapp.com/foods',
            time: '11:25PM'
        },
        {
            id: 2,
            title: 'https://www.bikiran.com/service/domain/eeeeeeeeeeeee',
            time: '11:25PM'
        },
        {
            id: 3,
            title: 'https://www.bikiran.com/service/domain/fffffffffffff',
            time: '11:25PM'
        },
        {
            id: 4,
            title: 'https://www.bikiran.com/service/domain/ggggggggggggg',
            time: '11:25PM'
        },
        {
            id: 5,
            title: 'https://www.bikiran.com/service/domain/hhhhhhhhhhhhh',
            time: '11:25PM'
        }
    ];

    return (
        <div className="history-list-area">
            {history.map((item) => (
                <div key={item?.id} className="history-item border-bottom">
                    <div role="button" tabIndex={-1} onClick={() => setHistoryText(item.title)}>
                        <p className="time">{item?.time}</p>
                        <p title="copied">{item?.title}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default PostmanHistoryContent;
