import { createContext, useContext, useEffect, useState } from 'react';
import AxiosAuth from '../../../utils/AxiosAuth';
import DomainInfoContactsModal from '../domains_info_contacts/DomainInfoContactsModal';
import DomainInfoErrorPopup from './DomainInfoErrorPopup';

const DomainInfoBasicContext = createContext();

export function useDomainInfo() {
    return useContext(DomainInfoBasicContext);
}

const initialState = { hostname: '', ip: '' };

function DomainInfoBasicProvider({ children, currentUser, contractId }) {
    const [domainInfo, setDomainInfo] = useState(null);
    const [inputVal, setInputVal] = useState(initialState);
    const [modal, setModal] = useState(null);
    const [listKey, setListKey] = useState(null);
    const [error, setError] = useState(null);
    const [domainDetailsHash, setDomainDetailsHash] = useState(null);
    const [cuContact, setCuContact] = useState(domainInfo?.domainData?.contactAr || {});

    // console.log(domainInfo);
    // handleSaveClick
    const handelReloadButton = () => {
        setListKey(Math.random());
        setDomainInfo(null);
    };

    const updateContactInfo = () => {
        const formattedData = {};

        Object.keys(cuContact).forEach((key) => {
            const item = cuContact[key];

            formattedData[key] = {
                name: item.name,
                organization: item.organization,
                phone: item.phone,
                telephone: item.telephone,
                email: item.email,

                line1: item.address.line1,
                line2: item.address.line2,
                line3: item.address.line3,
                country: item.address.country,
                state: item.address.state,
                city: item.address.city,
                zip: item.address.zip
            };
        });

        return AxiosAuth.currentUserAuth(currentUser)
            .setApiUrl('CONTRACT_DOMAIN', `/${contractId}/update-contact/`)
            .post(formattedData);
    };

    const sendVerifyEmail = () => {
        //
    };

    const purchasePrivacyProtection = () => {
        //
    };

    const updateDomainSecret = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT_DOMAIN', `${contractId}/transfer-out/send-domain-secret/`)
                .post({})
                .then(({ data }) => {
                    if (data?.error === 0) {
                        setListKey(Math.random());
                    }

                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const handelDomainUnlock = (domain, vSentId, vOTP) =>
        new Promise((resolve, reject) => {
            if (!vSentId || !vOTP) {
                resolve({
                    error: 1,
                    message: 'Verification Required',
                    action: 'verifyEmail'
                });
            } else {
                AxiosAuth.currentUserAuth(currentUser)
                    .setApiUrl('CONTRACT_DOMAIN', `${contractId}/transfer-out/unlock/`)
                    .post({
                        contractId,
                        domain: domainInfo?.domain,
                        vSentId,
                        vOTP
                    })
                    .then(({ data }) => {
                        setListKey(Math.random());
                        resolve({
                            error: data.error,
                            message: data.message,
                            action: data.action
                        });
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        });

    const handelDomainLock = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT_DOMAIN', `${contractId}/transfer-out/lock`)
                .post({ domain: domainInfo?.domain, contractId })
                .then(({ data }) => {
                    if (data.error === 0) {
                        setListKey(Math.random());
                    }

                    resolve({
                        error: data.error,
                        message: data.message,
                        action: data.action
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const updateNameServers = (nameServersAr) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT_DOMAIN', `${contractId}/update-nameservers`)
                .post({
                    nameServersAr
                })
                .then(({ data }) => {
                    if (data.error === 0) {
                        setDomainDetailsHash(data?.hash);
                    }
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    // add CNS
    const addChildNameServer = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT_DOMAIN', `${contractId}/cns/add`)
                .post({
                    hostname: `${inputVal?.hostname}.${domainInfo?.domain}`,
                    ip: inputVal?.ip
                })
                .then(({ data }) => {
                    if (data.error === 0) {
                        setInputVal(initialState);
                    }
                    resolve({ error: data.error, message: data.message });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    // Delete CNS
    const removeChildNameServer = (hostname, ip) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT_DOMAIN', `${contractId}/cns/delete`)
                .post({
                    hostname,
                    ip
                })
                .then(({ data }) => {
                    if (data.error === 0) {
                        setInputVal(initialState);
                    }
                    resolve({ error: data.error, message: data.message });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    // Update CNS
    const updateChildNameServer = (hostname, ip) =>
        new Promise((resolve, reject) => {
            AxiosAuth.currentUserAuth(currentUser)
                .setApiUrl('CONTRACT_DOMAIN', `${contractId}/cns/update-ip`)
                .post({
                    hostname,
                    ip
                })
                .then(({ data }) => {
                    if (data.error === 0) {
                        setInputVal(initialState);
                    }
                    resolve({ error: data.error, message: data.message });
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const transferOutStatus = () => {
        //
    };

    const transferOutVerifyOtp = () => {
        //
    };

    const transferOutUnlockStep = () => {
        // Unlock the domain & send the Domain Auth Key
    };

    const {
        domain,
        domainData,
        roleData,
        dateRegistered,
        dateExpired,
        price,
        timeExpire,
        timeIssue,
        permissions,
        isExpired
    } = domainInfo || {};

    const {
        status,
        raaVerificationStatus,
        isPrivacyProtectedAllowed,
        isTheftProtectedAllowed,
        nsAr,
        cnsAr,
        daysRemainText,
        contactAr,
        isPaused
    } = domainData || {};

    const {
        contractDelete,
        domainChangeChildNameServer,
        domainChangeContract,
        domainChangeNameServer,
        domainChangePrivacyProtection,
        domainChangeTransfer,
        domainInfo: dInfo
    } = permissions || {};

    const isDomainActive = domainInfo?.domainData?.status === 'active';

    const value = {
        isDomainActive,
        handelReloadButton,
        domainInfo,
        contractId,
        domain,
        status,
        raaVerificationStatus,
        isPrivacyProtectedAllowed,
        isPaused,
        isTheftProtectedAllowed,
        isTransferLock: !!domainInfo?.domainData?.isTransferLock,
        dateRegistered,
        isExpired,
        dateExpired,
        timeExpire,
        timeIssue,
        daysRemainText,
        price,
        nsAr,
        cnsAr,
        contactAr,
        cuContact,
        roleData,
        setDomainDetailsHash,
        setCuContact,
        sendVerifyEmail,
        purchasePrivacyProtection,
        updateDomainSecret,
        handelDomainUnlock,
        handelDomainLock,
        updateContactInfo,
        updateNameServers,
        addChildNameServer,
        removeChildNameServer,
        updateChildNameServer,
        transferOutStatus,
        transferOutVerifyOtp,
        transferOutUnlockStep,
        contractDelete,
        domainChangeChildNameServer,
        domainChangeContract,
        domainChangeNameServer,
        domainChangePrivacyProtection,
        domainChangeTransfer,
        dInfo,
        inputVal,
        setInputVal,
        modal,
        setModal
    };

    useEffect(() => {
        // setLoading(true);
        AxiosAuth.currentUserAuth(currentUser)
            .setApiUrl('CONTRACT', `/domain/${contractId}/details/`)
            .get()
            .then(({ data }) => {
                // setLoading(false);
                setError(data);
                if (!data.error) {
                    setDomainInfo(data.data);
                    setCuContact(data?.data?.domainData?.contactAr);
                }
            })
            .catch((err) => {
                // setLoading(false);
                console.log(err);
            });
    }, [currentUser, contractId, domainDetailsHash, listKey]);

    return (
        <DomainInfoBasicContext.Provider value={value}>
            {children}
            {error?.error && error?.error !== 0 ? (
                <DomainInfoErrorPopup errorMessage={error?.message} />
            ) : null}
            {modal !== null ? (
                <DomainInfoContactsModal
                    cuContact={cuContact}
                    setCuContact={setCuContact}
                    updateContactInfo={updateContactInfo}
                    setModal={setModal}
                    tabItem={modal}
                />
            ) : null}
        </DomainInfoBasicContext.Provider>
    );
}

export default DomainInfoBasicProvider;
