import React, { useState } from 'react';
import { iconsModal } from '../../../../configs/Icons';
import HostingBundleSection from './HostingBundleSection';
import OtherServiceSection from './OtherServiceSection';

function SectionHeader({ setIsHosting, isHosting }) {
    return (
        <div className="section-header">
            <div
                className={`hosting-text ${isHosting ? 'active' : ''}`}
                role="button"
                tabIndex={-1}
                onClick={() => setIsHosting(true)}
            >
                <div className="line-row-center">
                    <span className="font-16">I Already Have Hosting</span>
                    <span className="cross-icon line-row-center ml-2">
                        <img className="d-block w-100" src={iconsModal.iconCloseModal} alt="" />
                    </span>
                </div>
            </div>
            <div className="line-row-center">
                <div className="line-col-center">
                    <div className="save-text">
                        <span className="font-20 green">Save up to 35%</span>
                    </div>
                    <span className="font-20 clr">With a domain, Hosting bundle.</span>
                </div>
            </div>
        </div>
    );
}

function OfferSection() {
    const [isHosting, setIsHosting] = useState(false);
    return (
        <div className="container mb-120">
            <div className="offer-section-container">
                <SectionHeader isHosting={isHosting} setIsHosting={setIsHosting} />
                <div className="item-container">
                    <div className={`item-1 ${isHosting ? 'active' : 'inactive'}`}>
                        <HostingBundleSection />
                    </div>
                    <div className={`item-2 ${isHosting ? 'active' : 'inactive'}`}>
                        <OtherServiceSection />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OfferSection;
