/* eslint-disable jsx-a11y/label-has-associated-control */
import { ButtonClose, ButtonPurple } from '../../../../../configs/Buttons';
import { basic } from '../../../../../configs/Images';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import { InpPassword } from '../../../../utils/inputs/PasswordInputs';
import Iopt from '../../../../utils/Iopt';
import { useBasicInfo } from '../UserBasicInfoProvider';

const ModalHeader = ({ setModal }) => (
    <div className="modal-box-header mb-1">
        <div>
            <div className="modal-box-title">Enter password to continue</div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={() => setModal(null)} />
            </div>
        </div>
    </div>
);

function ModalBody() {
    // const [formData, setFormData] = useState({ password: '' });
    const { currentUser } = useAuth();
    const { setMessage } = useTemplate();
    const { updateUsername, formData, setFormData } = useBasicInfo();

    const handelUpdateData = (ev) => {
        setFormData((cVal) => ({ ...cVal, password: ev.target.value }));
    };
    const handleAuth = () => {
        setMessage('Updating username ...');
        updateUsername(formData?.username, formData?.password)
            .then(({ message }) => {
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err);
            });
        // console.log(formData);
    };
    return (
        <div className="modal-body-area">
            <div className=" body-content">
                <div className="line-align border-bottom mb-1 body-header">
                    <div className="user-img">
                        <img
                            className="w-100 d-block"
                            src={
                                currentUser?.photoUrl ? Iopt(currentUser.photoUrl) : basic.imgAvatar
                            }
                            alt="avatar"
                        />
                    </div>
                    <div className="ml-3">
                        <span className="fs-16 fw-500 clr">{currentUser?.displayName || '--'}</span>
                        <span className="font-14 opacity-medium d-block">
                            {currentUser?.email || '--'}
                        </span>
                    </div>
                </div>
                <div className="mb-3 input-grp">
                    <InpPassword
                        title="Password"
                        name="password"
                        value={formData?.password || ''}
                        setValue={handelUpdateData}
                    />
                </div>

                <div className="full-width">
                    <ButtonPurple evOnClick={handleAuth} title="Continue" modal />
                </div>
            </div>
        </div>
    );
}

function VerifyPasswordPopup({ show, setModal }) {
    if (!show) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content two-factor-popup-area">
                <ModalHeader setModal={setModal} />

                <ModalBody />
            </div>
        </div>
    );
}

export default VerifyPasswordPopup;
