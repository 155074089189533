import { userIcons } from '../../../../../../configs/Icons';
import LayoutSelector from '../../../../../utils/LayoutSelector';
import UsersListMobile from './UsersListMobile';
import UsersListWeb from './UsersListWeb';

const demoArr = [
    {
        uId: '------',
        image: userIcons.iconUserV3,
        name: '--------',
        email: '-----------',
        status: '-----',
        phone: '---------',
        loginDate: '----------',
        loginTime: '--------',
        isDone: false,
        demo: true
    }
];

const contractInfo = [
    {
        uId: 10000000,
        image: userIcons.iconUserV3,
        name: 'Mariana D. Alonso',
        email: 'name@email.com',
        status: 'Hosting',
        phone: '+880 1234 5678 90',
        loginDate: '25-Sept-2022',
        loginTime: '04:35:47 AM',
        isDone: false
    },
    {
        uId: 20000000,
        image: userIcons.iconUserV3,
        name: 'Chris Evans',
        email: 'name@email.com',
        phone: '+880 1234 5678 90',
        loginDate: '25-Sept-2022',
        loginTime: '04:35:47 AM',
        isDone: true
    }
];

function UsersListSection() {
    const arr = contractInfo || demoArr;

    return (
        <LayoutSelector
            sm
            md
            webComp={<UsersListWeb arr={arr} />}
            mobileComp={<UsersListMobile arr={arr} />}
        />
    );
}

export default UsersListSection;
