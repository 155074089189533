import { useState } from 'react';
import { deleteIcons } from '../../../../../configs/Icons';

function BasicInfoDzoneDdelete({ info, disable }) {
    const [modal, setModal] = useState(false);

    if (disable && info) {
        return null;
    }
    return (
        <div className="cell cell-4 cell-md-fill cursor-pointer mb-md-3">
            <div
                className="line line-g1 line-no-wrap"
                role="button"
                tabIndex={-1}
                onClick={() => setModal(!modal)}
            >
                <div className="cell ">
                    <div className="danger-zone-icon">
                        <span className="w-100 d-block">
                            <img className="d-block" src={deleteIcons.iconDomainDel} alt="" />
                        </span>
                    </div>
                </div>
                <div className="cell">
                    <div className="">
                        <span className="fs-14 fw-500 red">Delete Domain</span>
                        <span className="value">
                            If you close your business with this domain, delete from here.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BasicInfoDzoneDdelete;
