import ResultTabsContentArea from './ResultTabsContentArea';

function ResultTabsMenuArea({ setResItem, resItem }) {
    const menuItem = [
        { id: 1, title: 'Body' },
        { id: 2, title: 'Cookies' },
        { id: 3, title: 'Headers' },
        { id: 4, title: 'Test Result' }
    ];
    return (
        <div className="result-menu-area">
            <ul className="line-align mt-1 mb-1  tabs-menu-item">
                {menuItem.map((item, index) => (
                    <li key={item.id}>
                        <span
                            role="button"
                            tabIndex={-1}
                            onClick={() => setResItem(index)}
                            className={`common-title ml-2 ${
                                resItem === index && 'common-title-active'
                            }`}
                        >
                            {item.title}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
}

function ResultContentArea({ setResItem, resItem, apiData, setApiData }) {
    return (
        <>
            <ResultTabsMenuArea setResItem={setResItem} resItem={resItem} />
            <ResultTabsContentArea apiData={apiData} setApiData={setApiData} resItem={resItem} />
        </>
    );
}

export default ResultContentArea;
