import { userIcons } from '../../../../../../configs/Icons';
import LayoutSelector from '../../../../../utils/LayoutSelector';
import CartItemListMobile from './CartItemListMobile';
import CartItemListWeb from './CartItemListWeb';

const demoArr = [
    {
        uId: '------',
        image: userIcons.iconUserV3,
        name: '--------',
        email: '---------',
        status: '------',
        type: '-----',
        subType: '-------',
        price: '-----',
        prevPrice: '------',
        mode: '---',
        isDone: false,
        demo: true
    }
];

const contractInfo = [
    {
        uId: 10000000,
        image: userIcons.iconUserV3,
        name: 'Mariana D. Alonso',
        email: 'name@email.com',
        status: 'Hosting',
        type: 'Hosting',
        subType: 'Web Hosting',
        price: '149.50',
        prevPrice: '16.49/mo',
        mode: 'New',
        isDone: false
    },
    {
        uId: 20000000,
        image: userIcons.iconUserV3,
        name: 'Chris Evans',
        email: 'name@email.com',
        type: 'Hosting',
        subType: 'Email Hosting',
        price: '154.20',
        prevPrice: '16.49/mo',
        mode: 'New',
        isDone: true
    }
];

function CartItemListSection() {
    const arr = contractInfo || demoArr;

    return (
        <LayoutSelector
            sm
            md
            webComp={<CartItemListWeb arr={arr} />}
            mobileComp={<CartItemListMobile arr={arr} />}
        />
    );
}

export default CartItemListSection;
