import LayoutSelector from '../../../../utils/LayoutSelector';
import ActivityListMobile from './ActivityListMobile';
import ActivityListWeb from './ActivityListWeb';

const arr = [
    {
        id: 1,
        device: 'Windows PC',
        browser: 'Google Chrome',
        location: 'Khulna, Bangladesh',
        isActive: true
    },
    {
        id: 2,
        device: 'Android Mobile',
        browser: 'Microsoft Edge',
        location: 'Dhaka, Bangladesh',
        isActive: false
    },
    {
        id: 3,
        device: 'Windows PC',
        browser: 'Mozila Firebox',
        location: 'Dhaka, Bangladesh',
        isActive: false
    }
];

function ActivityListSection() {
    return (
        <LayoutSelector
            sm
            md
            webComp={<ActivityListWeb arr={arr} />}
            mobileComp={<ActivityListMobile arr={arr} />}
        />
    );
}

export default ActivityListSection;
