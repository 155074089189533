/* eslint-disable no-alert */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteIcons, icons } from '../../../../configs/Icons';
import { useAuth } from '../../../contexts/AuthProvider';
import { useCart } from '../../../contexts/CartProvider';
import { useTemplate } from '../../../contexts/TemplateProvider';
import CartOrderSummery from './CartOrderSummery';
import CartProductArea from './CartProductArea';

const EmptyCart = ({ handleEmptyCart }) => {
    const { setConfirm, setMessage } = useTemplate();

    const handleButtonClick = () => {
        setConfirm({
            text: 'Are you sure you want to empty the cart?',
            clickAction: () => {
                setMessage('Emptying...');
                handleEmptyCart().then(() => {
                    setConfirm(null);
                    setMessage('All item removed from cart');
                });
            }
        });
    };

    return (
        <span
            role="button"
            tabIndex="-1"
            className="empty-cart d-md-none"
            onClick={handleButtonClick}
        >
            <img src={deleteIcons.iconDelete} alt="Delete" />
            <span>Empty Cart</span>
        </span>
    );
};

const TitleLine = ({ handleEmptyCart }) => (
    <div className="line mb-6 d-md-none">
        <div className="cell cell-fill d-md-none">
            <span className="cart-title">Service you chosen</span>
        </div>
        <div className="cell cell-self-middle">
            <EmptyCart handleEmptyCart={handleEmptyCart} />
        </div>
    </div>
);

// const SearchDomain = () => (
//     <div className="search-domain cell-md-12 w-md">
//         <div className="dialog">Your domain name is the identity of your great idea!</div>

//         <div className="form-input">
//             <input
//                 type="text"
//                 name="domain_name"
//                 placeholder="Find your perfect domain here!"
//                 autoComplete="off"
//             />
//             <span>
//                 <img src={searchBarIcons.iconSearchV2} alt="search" />
//             </span>
//         </div>
//     </div>
// );

const EmptyCartView = () => (
    <div className="container cart-area d-sm-flex">
        <div className="cart-image cell-md-12">
            <img src={icons.iconEmptyCart} alt="Empty Cart" />
        </div>

        <div className="empty-cart-text cell-md-12">
            <div>There are no items in your bucket</div>
            <div>Keep Shopping</div>
        </div>

        {/* <SearchDomain /> */}
    </div>
);

const EmptyCartOption2 = ({ cartData, handleEmptyCart }) => (
    <>
        {cartData.length > 3 ? (
            <div className="line mb-4">
                <div className="cell cell-fill">&nbsp;</div>
                <div className="cell cell-self-middle">
                    <EmptyCart handleEmptyCart={handleEmptyCart} />
                </div>
            </div>
        ) : null}
    </>
);

function CartDetailsSection() {
    const [checkValue, setCheckValue] = useState(null);

    const {
        cartData,
        handleUpdateCartItem,
        handleEmptyCart,
        handleDeleteFromCart,
        totalOfferPrice,
        totalPrice,
        handelCreateInvoice
    } = useCart();

    const { currentUser, makeAction, loginHash } = useAuth();
    const { setMessage } = useTemplate();
    const navigate = useNavigate();

    const handelReadyToPay = () => {
        if (!checkValue) {
            setCheckValue(false);
            return null;
        }
        if (!currentUser.loginStatus) {
            makeAction('sign-in');
        } else {
            setMessage('Creating Invoice ...');
            handelCreateInvoice(currentUser)
                .then(({ error, message, action, data }) => {
                    if (error) {
                        setMessage(message);
                        if (action === 'login-window') {
                            makeAction('sign-in');
                        }
                    } else {
                        setMessage(message);
                        navigate(`/payment/${data?.invoiceId}/`);
                    }
                })
                .catch((err) => {
                    setMessage(err.toString());
                });
        }
        return null;
    };

    if (cartData.length === 0) {
        return <EmptyCartView />;
    }

    return (
        <div className="container cart-area">
            <div className="line line-no-wrap line-md-wrap line-g4 ml-sm-4 mr-sm-4">
                <div className="cell cell-cart cell-lg-7 cell-md-12 ">
                    <TitleLine handleEmptyCart={handleEmptyCart} />

                    <CartProductArea
                        cartData={cartData}
                        handleDeleteFromCart={handleDeleteFromCart}
                        handleUpdateCartItem={handleUpdateCartItem}
                    />

                    <EmptyCartOption2 cartData={cartData} handleEmptyCart={handleEmptyCart} />
                </div>

                <div className="cell cell-fill cell-lg-5 cell-md-12 cart-calc-area">
                    <CartOrderSummery
                        cartData={cartData}
                        totalPrice={totalPrice}
                        totalOfferPrice={totalOfferPrice}
                        handelReadyToPay={handelReadyToPay}
                        loginHash={loginHash}
                        setCheckValue={setCheckValue}
                        checkValue={checkValue}
                    />
                </div>
            </div>
        </div>

        // {openModal && (
        //     <CartDomainProfileModal
        //         setOpenModal={setOpenModal}
        //         openModal={openModal}
        //         goToPayment={handelReadyToPay}
        //     />
        // )}
    );
}

export default CartDetailsSection;
