function PrimaryView() {
    return <span className="primary-btn common-button">Primary</span>;
}
function PendingView() {
    return <span className="pending-btn common-button ml-4 d-sm-none">Pending</span>;
}

const ContactInfoStatusHandler = ({ data }) => {
    if (data?.status === 'pending') {
        return <PendingView />;
    }

    if (data.isDefault) {
        return <PrimaryView />;
    }
    return null;
};
export default ContactInfoStatusHandler;
