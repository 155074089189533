import { ButtonOutlinePurple } from '../../../../../configs/Buttons';
import { osIcon } from '../../../../../configs/Icons';

function ListItem({ item }) {
    return (
        <div className="info-list-view">
            <div className="line ">
                <div className="cell ">
                    <div className="line-align line-no-wrap">
                        <div className="list-logo d-md-none">
                            <img
                                className="d-block w-100"
                                src={
                                    item?.device === 'Windows PC'
                                        ? osIcon.windowsV2
                                        : osIcon.androidV2
                                }
                                alt="icon device"
                            />
                        </div>
                        <div className="content-text">
                            <span className="font-16 clr">{item?.device}</span>
                            <span className="font-14 opacity-medium d-block">{item?.location}</span>
                        </div>
                    </div>
                </div>

                <div className="cell-4 cell-self-middle text-center">
                    <span className="font-14 clr">{item?.browser}</span>
                </div>

                <div className="cell-fill text-center">
                    {item?.isActive && <span className="font-14 green">Active Now</span>}
                </div>

                <div className="cell-2 cell-md-fill text-right cell-self-middle">
                    {!item?.isActive && <ButtonOutlinePurple title="Revoke Now" />}
                </div>
            </div>
        </div>
    );
}

function ActivityListWeb({ arr }) {
    return arr?.map((item) => <ListItem key={item?.id} item={item} />);
}

export default ActivityListWeb;
