import React, { useEffect } from 'react';

import CommonPlaceholder from '../../../placeholders/components/CommonPlaceholder';
import PageProperties from '../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../headers/DashboardHeaderSection';

function ContentBodyArea({ data }) {
    if (!data?.length) {
        return <CommonPlaceholder text="SMS" url="send/sms-service/" />;
    }
    return (
        <div className="product-info">
            <div>Data Found</div>
        </div>
    );
}
function UsersSmsPage() {
    const data = [];

    useEffect(() => {
        PageProperties('SMS Package');
    }, []);

    return (
        <div className="cell cell-content h-100">
            <div className="content-section">
                <div className="content-area">
                    <DashboardDefaultHeader title="Sms Package" path="/user/profile-overview/" />
                    <div className="content-body-scroll">
                        <ContentBodyArea data={data} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsersSmsPage;
