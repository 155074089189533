/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { deleteIcons, icons } from '../../../../configs/Icons';
import { useTemplate } from '../../../contexts/TemplateProvider';
import {
    CurrencyFormatter,
    DurationOptions,
    nextRenewalMonth,
    ShowCurrencySign,
    showOfferPercent,
    showOfferPrice,
    showPrice
} from '../../websites/models/Pricing';

const ProductItem = ({ item, handleUpdateCartItem, handleDeleteFromCart }) => {
    const { setMessage, setConfirm } = useTemplate();

    const handleYearChange = (ev) => {
        const duration = parseInt(ev.target.value, 10);
        setMessage('Loading...');
        handleUpdateCartItem({ ...item, duration }).then(({ message }) => {
            setMessage(message);
        });
    };
    const DomainItem = ({ handleDeleteClick, offerPercent }) => (
        <div className="item-area line">
            <div className="cell-3 d-xl-none left-item">
                <img className="d-block" src={icons.iconDomainBg} alt="domain icon bg" />
            </div>
            <div className="cell-7 cell-lg-7 cell-xl-9 center-item ">
                <div>
                    <span className="title break-all">{item?.property?.domain.toUpperCase()}</span>
                    <p className="type-text mt-0 mb-1">
                        {item?.property?.tld?.toUpperCase()} Domain Registration
                    </p>
                </div>
                <div>
                    <DurationOptions
                        productInfo={item}
                        className="form-input year mb-1"
                        handleYearChange={handleYearChange}
                        duration={item.duration}
                        style={{ fontSize: '13px' }}
                    />

                    <div className="renew-text">
                        Renews {nextRenewalMonth(item.duration)} for {ShowCurrencySign()}
                        {CurrencyFormatter(item?.price * 12)}{' '}
                        {/* {(item?.price * 12).toFixed(2)}  */}
                        (1-yr term)
                    </div>
                </div>
            </div>
            <div className="cell cell-2 cell-lg-5 cell-xl-3 right-item text-right no-wrap">
                <div className="mb-1 mt-1">
                    <img
                        src={deleteIcons.iconDelete}
                        alt="Delete"
                        className="delete"
                        onClick={handleDeleteClick}
                        role="button"
                        tabIndex="-1"
                    />
                </div>
                <div className="">
                    <div className="price-offer">
                        {ShowCurrencySign()}
                        <span className="ff-amount fw-600">
                            {CurrencyFormatter(showOfferPrice(item, item.duration))}
                        </span>
                        {/* {showOfferPrice(item, item.duration).toFixed(2)} */}
                    </div>
                    {offerPercent > 0 ? (
                        <>
                            <div className="price-original">
                                {ShowCurrencySign()}
                                {CurrencyFormatter(showPrice(item, item.duration))}
                                {/* {showPrice(item, item.duration).toFixed(2)} */}
                            </div>
                            <div className="offer-text no-wrap">{offerPercent.toFixed(2)}% off</div>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );

    const HostingItem = ({ handleDeleteClick, offerPercent }) => (
        <div className="item-area line" style={{ padding: '11px 15px' }}>
            <div className="cell-3 d-xl-none left-item">
                <img className="d-block" src={icons.iconHostingBg} alt="hosting icon bg" />
            </div>
            <div className="cell-7 cell-lg-7 cell-xl-8 center-item ">
                <div>
                    <span className="title no-wrap">{item.title}</span>
                    <p className="type-text mt-0 mb-1">
                        {item?.property?.disk / 1024}GB {item?.service_mode} hosting
                    </p>
                </div>
                <div>
                    <DurationOptions
                        productInfo={item}
                        className="form-input year mb-2"
                        handleYearChange={handleYearChange}
                        duration={item.duration}
                        style={{ fontSize: '13px' }}
                    />
                    <div className="renew-text">
                        Renews {nextRenewalMonth(item.duration)} for {ShowCurrencySign()}
                        {/* {showPrice(item, item.duration).toFixed(2)} */}
                        {CurrencyFormatter(showPrice(item, item.duration))} ({item.duration}-Mo
                        term)
                    </div>
                </div>
            </div>
            <div className="cell cell-2 cell-lg-5 cell-xl-4 right-item text-right no-wrap">
                <div className="mb-2 mt-1">
                    <img
                        src={deleteIcons.iconDelete}
                        alt="Delete"
                        className="delete"
                        onClick={handleDeleteClick}
                        role="button"
                        tabIndex="-1"
                    />
                </div>
                <div>
                    <div
                        className="price-offer"
                        style={offerPercent > 0 ? {} : { fontSize: '20px', direction: 'rtl' }}
                    >
                        {ShowCurrencySign()}
                        <span className="ff-amount fw-600">
                            {CurrencyFormatter(showOfferPrice(item, item.duration))}
                        </span>
                        {/* {showOfferPrice(item, item.duration).toFixed(2)} */}
                    </div>
                    {offerPercent > 0 ? (
                        <>
                            <div className="price-original">
                                {ShowCurrencySign()}
                                {CurrencyFormatter(showPrice(item, item.duration))}
                                {/* {showPrice(item, item.duration).toFixed(2)} */}
                            </div>
                            <div className="offer-text">{offerPercent.toFixed(2)}% off</div>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );

    const handleDeleteClick = () => {
        setConfirm({
            text: 'Are you sure you want to delete this item?',
            clickAction: () => {
                setMessage('Removing...');

                handleDeleteFromCart(item).then(({ message }) => {
                    setConfirm(null);
                    setMessage(message);
                });
            }
        });
    };

    const offerPercent = showOfferPercent(item, item.duration);
    return (
        <>
            {item?.type === 'domain' ? (
                <DomainItem
                    item={item}
                    offerPercent={offerPercent}
                    handleDeleteClick={handleDeleteClick}
                />
            ) : (
                <HostingItem
                    item={item}
                    offerPercent={offerPercent}
                    handleDeleteClick={handleDeleteClick}
                />
            )}
        </>
    );
};

function CartProductArea({ cartData, handleUpdateCartItem, handleDeleteFromCart }) {
    return (
        <div className="cart-product-area">
            {cartData.map((item) => (
                <ProductItem
                    item={item}
                    key={item.hash}
                    handleDeleteFromCart={handleDeleteFromCart}
                    handleUpdateCartItem={handleUpdateCartItem}
                />
            ))}
        </div>
    );
}

export default CartProductArea;
