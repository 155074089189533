import { useRef, useState } from 'react';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';
import { getFullCountryName } from '../../../../utils/CountryName';
import LayoutSelector from '../../../../utils/LayoutSelector';
import ModalBox from '../../../../utils/ModalBox';
import { mkToken } from '../../../../utils/StringOperation';
import MessageModal from '../../../system/modals/MessageModal';
import CpCreateWizardPopup from '../../hostings/cpanel_create/CpCreateWizardPopup';
import CpLoginLinkPopup from '../../hostings/cpanel_login/CpLoginLinkPopup';
import HostingListTableMobile from './HostingListTableMobile';
import HostingListTableWeb from './HostingListTableWeb';

const demoArr = [
    {
        id: '------',
        domain: '---------',
        np: '---',
        priceOffer: 101,
        duration: 10,
        createdOn: '--------',
        expiredOn: '--------',
        isDemo: true
    }
];

const HostingSetupArea = ({ hostingProperty, setHostingProperty, setUniqueKey }) => {
    const { currentUser } = useAuth();
    const { setMessage } = useTemplate();
    const refLocation = useRef();
    const refPrimaryDomain = useRef();
    const refCPanelUsername = useRef();
    const refCPanelPassword = useRef();
    const handleSubmitForm = () => {
        setMessage('Loading...');
        AxiosAuth.currentUserAuth(currentUser)
            .setApiUrl('CONTRACT_HOSTING', `/web/${hostingProperty.id}/execute/`)
            .post({
                id: hostingProperty.id,
                cpHost: refLocation.current.value,
                cpDomain: refPrimaryDomain.current.value,
                cpUser: refCPanelUsername.current.value,
                cpPass: refCPanelPassword.current.value
            })
            .then(({ data }) => {
                setMessage(data.message);

                if (data.error === 0) {
                    setHostingProperty(null);
                    setUniqueKey(mkToken(32));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleCloseClick = () => {
        setHostingProperty(null);
    };

    if (!hostingProperty) {
        return null;
    }

    return (
        <ModalBox
            show
            handleCloseClick={handleCloseClick}
            body={
                <>
                    <div className="modal-box-title">Setting up hosting</div>
                    <div className="login-input">
                        <div className="line mb-3">
                            <div className="cell-12">
                                <div className="label">Location</div>
                                <select className="form-input mb-2" ref={refLocation}>
                                    <option value="">Select Location</option>
                                    {hostingProperty?.locations?.map((item) => (
                                        <option value={item.host} key={item.location}>
                                            {getFullCountryName(item.location)}
                                        </option>
                                    ))}
                                </select>

                                <div className="label">Primary Domain</div>
                                <input
                                    type="text"
                                    ref={refPrimaryDomain}
                                    className="form-input d-block w-100 mb-2"
                                    placeholder="ex: domainname.com"
                                />

                                <div className="label">cPanel Username</div>
                                <input
                                    type="text"
                                    ref={refCPanelUsername}
                                    className="form-input d-block w-100 mb-2"
                                    placeholder="ex: domain22"
                                />

                                <div className="label">cPanel Password</div>
                                <input
                                    type="text"
                                    ref={refCPanelPassword}
                                    className="form-input d-block w-100"
                                    placeholder="ex: ••••••••••••••"
                                />
                            </div>
                        </div>

                        <div>
                            <ul className="list-warning">
                                <li>It will create new cPanel account on server.</li>
                                <li>
                                    You can&apos;t change the primary domains or username after
                                    create the cPanel account.
                                </li>
                                <li>Any time, you may reset cPanel account.</li>
                            </ul>
                        </div>

                        <div>
                            <button
                                type="button"
                                className="add-to-cart button w-100"
                                onClick={handleSubmitForm}
                            >
                                Create cPanel account
                            </button>
                        </div>
                    </div>
                </>
            }
        />
    );
};

function HostingListTable({ hostingData, setUniqueKey }) {
    const [hostingProperty, setHostingProperty] = useState(null);
    const [modal, setModal] = useState({ type: '', data: {} });
    const arr = hostingData || demoArr;
    return (
        <div className="mb-3">
            <LayoutSelector
                sm
                md
                webComp={
                    <HostingListTableWeb
                        arr={arr}
                        setUniqueKey={setUniqueKey}
                        hostingProperty={hostingProperty}
                        setHostingProperty={setHostingProperty}
                        setModal={setModal}
                        modal={modal}
                    />
                }
                mobileComp={
                    <HostingListTableMobile
                        arr={arr}
                        setUniqueKey={setUniqueKey}
                        hostingProperty={hostingProperty}
                        setHostingProperty={setHostingProperty}
                        setModal={setModal}
                    />
                }
            />
            <MessageModal show={modal?.type === 'message'} setModal={setModal} />

            <CpCreateWizardPopup
                show={modal?.type === 'cp-wizard'}
                setModal={setModal}
                item={modal?.data || {}}
                setUniqueKey={setUniqueKey}
            />
            <CpLoginLinkPopup
                show={modal?.type === 'cp-link'}
                setModal={setModal}
                modal={modal}
                id={modal?.data?.id || {}}
            />

            <HostingSetupArea
                hostingProperty={hostingProperty}
                setHostingProperty={setHostingProperty}
                setUniqueKey={setUniqueKey}
            />
        </div>
    );
}

export default HostingListTable;
