import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import PageProperties from '../../../utils/PageProperties';
import DomainInfoHeader from '../domains_info_header/DomainInfoHeader';
import TabsRowSection from '../domains_info_tabs/TabsRowSection';
import InfoActivityLogArea from '../info_activity/InfoActivityLogArea';
import InfoActivityLogProvider, { useActivityInfo } from '../info_activity/InfoActivityLogProvider';

function ContentHead() {
    const { activityInfo, handelReloadButton } = useActivityInfo();

    return (
        <DomainInfoHeader path="activity/" info={activityInfo} evReloadClick={handelReloadButton} />
    );
}

function ContentBody() {
    const { activityInfo } = useActivityInfo();
    return (
        <div className="content-body-scroll">
            <TabsRowSection info={activityInfo} activity />
            <InfoActivityLogArea />
        </div>
    );
}

function DomainActivityLogPage() {
    const { contractId } = useParams();
    const { currentUser } = useAuth();

    useEffect(() => {
        PageProperties(`Activity Log \u2022 Domain`);
    }, []);

    return (
        <InfoActivityLogProvider
            apiUrl="CONTRACT_DOMAIN"
            currentUser={currentUser}
            contractId={contractId}
        >
            <div className="cell cell-content h-100">
                <div className="content-section">
                    <div className="content-area">
                        <ContentHead />

                        <ContentBody />
                    </div>
                </div>
            </div>
        </InfoActivityLogProvider>
    );
}
export default DomainActivityLogPage;
