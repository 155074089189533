import { ButtonPurple } from '../../../../../../configs/Buttons';
import { useDnsInfo } from '../../DnsProvider';

function TabCaaContent() {
    const { formData, setFormData } = useDnsInfo();

    const handelCreate = () => null;

    const isDisabled =
        !formData?.hostname ||
        !formData?.domain ||
        !formData?.tags ||
        !formData?.flags ||
        !formData?.ttl;

    return (
        <div>
            <div className="mb-1">
                <span className="content-text">
                    <b>CAA</b> records specify which certificate authorities are permitted to issue
                    certificates for a domain. Use them to reduce the risk of unintended certificate
                    miss-issue.
                </span>
            </div>
            <div className="record-input-area">
                <div className="line align-end line-g1">
                    <div className="cell cell-4 cell-xl-6 cell-lg-fill">
                        <div className="label">
                            Hostname<span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, hostname: e.target.value }))
                            }
                            placeholder="Enter hostname"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>
                    <div className="cell cell-4 cell-xl-6 cell-lg-fill">
                        <div className="label">
                            AUTHORITY GRANTED FOR <span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, domain: e.target.value }))
                            }
                            placeholder="e.g. mydomain.com"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>

                    <div className="cell cell-4 cell-xl-6 cell-lg-fill">
                        <div className="label">
                            Tags<span className="red">*</span>
                        </div>
                        <select
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, tags: e.target.value }))
                            }
                            className="form-input"
                            name="tags"
                        >
                            <option defaultChecked value="">
                                Choose a tag
                            </option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                        </select>
                    </div>

                    <div className="cell cell-4 cell-xl-6 cell-lg-fill">
                        <div className="label">
                            Flags<span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, flags: e.target.value }))
                            }
                            placeholder="e.g. 0"
                            type="text"
                            className=" form-input"
                        />
                    </div>
                    <div className="cell cell-4 cell-xl-6 cell-lg-fill">
                        <div className="label">
                            TTL (SECONDS)<span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, ttl: e.target.value }))
                            }
                            placeholder="36000"
                            type="text"
                            className=" form-input"
                        />
                    </div>
                    <div className="cell cell-12">
                        <div className="label">Comment</div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, comment: e.target.value }))
                            }
                            placeholder="Here is the Comment"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>
                    <div className="cell cell-12">
                        <div className="line justify-end mt-2">
                            <ButtonPurple
                                disabled={isDisabled}
                                evOnClick={handelCreate}
                                title="Create Record"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TabCaaContent;
