import React from 'react';
import LoginModal from './LoginModal';
import LoginProvider from './LoginProvider';

function LoginController({
    show,
    modalProperty,
    currentUser,
    setCurrentUser,
    setExitModal,
    setSuccessLogin,
    makeAction,
    verifyProviderRecord
}) {
    if (!show) {
        return null;
    }

    return (
        <LoginProvider
            setSuccessLogin={setSuccessLogin}
            verifyProviderRecord={verifyProviderRecord}
            makeAction={makeAction}
        >
            <LoginModal
                modalProperty={modalProperty}
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
                setExitModal={setExitModal}
                makeAction={makeAction}
            />
        </LoginProvider>
    );
}

export default LoginController;
