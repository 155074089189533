import React from 'react';
import InvoiceItem from '../../section/InvoiceItem';

const PaymentInvoiceProducts = ({ invoiceData, print }) => {
    const { items, amountBilled, amountDiscount, amountPaid, amountPayable } = invoiceData || {};

    if (items?.length === 0) {
        return (
            <table className="invoice-products" cellSpacing={0}>
                <tfoot>
                    <tr>
                        <td colSpan={4} className="total-row">
                            <div className="invoice-summery">No Data Found!</div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    }

    return (
        <>
            <InvoiceItem invoiceData={invoiceData || {}} print={print} />
            <table className="invoice-products" cellSpacing={0}>
                <tfoot>
                    <tr>
                        <td colSpan={4} className="total-row">
                            <div className="invoice-summery">
                                <div className="line">
                                    <div className="cell cell-fill">Total:</div>
                                    <div className="cell">
                                        <span>
                                            {(amountBilled || 0).toFixed(2) || '-----------------'}
                                        </span>
                                    </div>
                                </div>

                                <div className="line">
                                    <div className="cell cell-fill">Discount:</div>
                                    <div className="cell">
                                        <span>
                                            -{' '}
                                            {(amountDiscount || 0).toFixed(2) ||
                                                '-----------------'}
                                        </span>
                                    </div>
                                </div>

                                <div className="line">
                                    <div className="cell cell-fill">Paid:</div>
                                    <div className="cell">
                                        <span>
                                            - {(amountPaid || 0).toFixed(2) || '-----------------'}
                                        </span>
                                    </div>
                                </div>

                                <div className="line">
                                    <div className="cell cell-fill">Payable:</div>
                                    <div className="cell">
                                        <span>
                                            {(amountPayable || 0).toFixed(2) || '-----------------'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </>
    );
};

export default PaymentInvoiceProducts;
