import countries from './country.json';

export function getFullCountryName(sortName) {
    const sortNameUp = sortName.toUpperCase();
    return countries.find((item) => sortNameUp === item.code).name;
}

function Country() {
    return null;
}

export default Country;
