import React from 'react';
import ForgetPasswordModal from './ForgetPasswordModal';
import ForgetPasswordProvider from './ForgetPasswordProvider';

function ForgetPasswordController({ show, setExitModal, makeAction, modalProperty }) {
    if (!show) {
        return null;
    }

    return (
        <ForgetPasswordProvider>
            <ForgetPasswordModal
                setExitModal={setExitModal}
                makeAction={makeAction}
                modalProperty={modalProperty}
            />
        </ForgetPasswordProvider>
    );
}

export default ForgetPasswordController;
