import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PageProperties from '../../utils/PageProperties';
import HeaderSection from '../headers/HeaderSection';
import FooterSection2 from '../system/section/Footer2Section';
import MobileFooter from '../users/components/MobileFooter';
import FixedHeaderControl from '../websites/components/FixedHeaderControl';
import SearchDomainSection from './domains/SearchDomainSection';
import SearchPageProvider from './SearchPageProvider';

export function SearchMenu({ searchText, sTabText }) {
    return (
        <div className="search-menu">
            <div className="container">
                <ul className="search-menu-ul overflow-auto">
                    <li className={!sTabText ? 'search-menu-active' : ''}>
                        <Link to={`/search/?q=${searchText}`}>All Result</Link>
                    </li>
                    <li className={sTabText === 'bikiran' ? 'search-menu-active' : ''}>
                        <Link to={`/search/?q=${searchText}&tab=bikiran`}>Our Services</Link>
                    </li>
                    <li className={sTabText === 'dev' ? 'search-menu-active' : ''}>
                        <Link to={`/search/?q=${searchText}&tab=dev`}>Development</Link>
                    </li>
                    <li className={sTabText === 'ecom' ? 'search-menu-active' : ''}>
                        <Link to={`/search/?q=${searchText}&tab=ecom`}>eCommerce</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

// const setContentTab = (SearchContentAr, searchTabText) => {
//     if (searchTabText === 'bikiran') {
//         SearchContentAr.push({
//             id: 'bikiran',
//             data: <ServiceSearchBikiran />
//         });
//     }
//     if (searchTabText === 'dev') {
//         SearchContentAr.push({
//             id: 'dev',
//             data: <DevelopmentSearch />
//         });
//     }
//     if (searchTabText === 'ecom') {
//         SearchContentAr.push({
//             id: 'ecom',
//             data: <EcomSearch />
//         });
//     }
// };

// const setContent = (SearchContentAr, dataInfo, setDataInfo, searchText) => {
//     if (dataInfo?.length < 1) {
//         SearchContentAr.push({
//             id: 'error',
//             data: <SearchErrorSection searchText={searchText} />
//         });
//     }

//     dataInfo?.forEach((item) => {
//         // domainWebNoInfo
//         if (item.model === 'domainWebNoInfo') {
//             SearchContentAr.push({
//                 id: item.model,
//                 data: (
//                     <SearchDomainWebNoInfo
//                         info={item.result}
//                         setDataInfo={setDataInfo}
//                         SearchContentAr={SearchContentAr}
//                     />
//                 )
//             });
//         }

//         if (item.model === 'domainRegAvailable') {
//             SearchContentAr.push({
//                 id: item.model,
//                 data: <SearchDomainWebNoInfo info={item.result} />
//             });
//         }

//         if (item.model === 'domainWebRunning') {
//             SearchContentAr.push({ id: item.model, data: <SearchDomainWebRunning info={item.result} /> });
//         }

//         if (item.model === 'domainWebOffline') {
//             SearchContentAr.push({ id: item.model, data: <SearchDomainWebOffline info={item.result} /> });
//         }

//         if (item.model === 'search') {
//             SearchContentAr.push({ id: item.model, data: <ResultSection info={item.result} /> });
//         }

//         if (item.model === 'suggestedDomain') {
//             SearchContentAr.push({
//                 id: item.model,
//                 data: <SuggestedDomainsSection info={item.result} />
//             });
//         }
//     });
// };

function SearchPageSection({ searchText, type }) {
    // const SearchContentAr = [];

    // if (searchTabText) {
    //     setContentTab(SearchContentAr, searchTabText);
    // } else {
    //     setContent(SearchContentAr, dataInfo, setDataInfo, searchText);
    // }

    // Placeholder
    // if (!dataInfo) {
    //     return (
    //         <>
    //             <FixedHeaderControl>
    //                 <HeaderSection />
    //             </FixedHeaderControl>

    //             <SearchMenu />

    //             <div className="container">
    //                 <DomainFoundPlaceholder />
    //             </div>

    //             <ChatBtnSection />
    //             <FooterSection2 />
    //         </>
    //     );
    // }

    return (
        <>
            <FixedHeaderControl>
                <HeaderSection searchPage />
            </FixedHeaderControl>

            <SearchMenu searchText={searchText} sTabText={type} />

            <SearchDomainSection />

            <FooterSection2 />
            <MobileFooter />
        </>
    );
}

function SearchPage() {
    const { search } = useLocation();
    const searchObj = new URLSearchParams(search);
    const searchText = searchObj.get('q');
    const pageNo = searchObj.get('page-no');
    const type = searchObj.get('tab');

    useEffect(() => {
        PageProperties(searchText);
    }, [searchText]);

    return (
        <SearchPageProvider searchText={searchText} pageNo={pageNo} type={type}>
            <SearchPageSection searchText={searchText} pageNo={pageNo} type={type} />
        </SearchPageProvider>
    );
}

export default SearchPage;
