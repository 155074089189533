import React from 'react';
import SectionDivider from '../_models/section_divider/SectionDivider';

function HostingInfoBackupList() {
    // const { contractId } = useParams();
    // const { currentUser } = useAuth();

    return (
        <div className="product-info mb-4">
            <SectionDivider title="Backup" />
        </div>
    );
}

export default HostingInfoBackupList;
