import {
    FacebookAuthProvider,
    GithubAuthProvider,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup
} from 'firebase/auth';
import { createContext, useContext, useState } from 'react';
import { firebaseAuth } from '../../../MyFirebase';
import AxiosAuth from '../../../utils/AxiosAuth';

const AuthContext = createContext();

export function useLogin() {
    return useContext(AuthContext);
}

function LoginProvider({ children, setSuccessLogin, verifyProviderRecord, makeAction }) {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        saveBrowser: '',
        isOtpRequired: '',
        sentId: '',
        otp: ''
    });

    const signInWithEmail = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.setApiUrl('AUTH', 'login/get-id')
                .post({ type: 'email', email: formData.username })
                .then(({ data }) => {
                    if (data.error === 0) {
                        signInWithEmailAndPassword(firebaseAuth, data.userEmail, formData.password)
                            .then(({ user }) => {
                                verifyProviderRecord(user).then(({ error, message }) => {
                                    // --Firebase Success Login
                                    setSuccessLogin();

                                    // --Feedback
                                    resolve({ error, message });
                                });
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    } else {
                        reject(new Error(data.message));
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
        provider.addScope('https://www.googleapis.com/auth/userinfo.email');
        provider.addScope('profile');
        provider.addScope('email');
        provider.addScope('openid');

        return new Promise((resolve, reject) => {
            signInWithPopup(firebaseAuth, provider)
                .then(({ user }) => {
                    verifyProviderRecord(user).then(({ error, message, action }) => {
                        // --Firebase Success Login
                        setSuccessLogin();

                        // --Feedback
                        resolve({ error, message });

                        // --Set Action
                        makeAction(action);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    const signInWithFacebook = () => {
        const provider = new FacebookAuthProvider();
        provider.addScope('public_profile');
        provider.addScope('email');

        return new Promise((resolve, reject) => {
            signInWithPopup(firebaseAuth, provider)
                .then(({ user }) => {
                    verifyProviderRecord(user).then(({ error, message, action }) => {
                        // --Firebase Success Login
                        setSuccessLogin();

                        // --Feedback
                        resolve({ error, message });

                        // --Set Action
                        makeAction(action);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    const signInWithGithub = () => {
        const provider = new GithubAuthProvider();
        provider.addScope('user:email');

        return new Promise((resolve, reject) => {
            signInWithPopup(firebaseAuth, provider)
                .then(({ user }) => {
                    verifyProviderRecord(user).then(({ error, message, action }) => {
                        // --Firebase Success Login
                        setSuccessLogin();

                        // --Feedback
                        resolve({ error, message });

                        // --Set Action
                        makeAction(action);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    const value = {
        formData,
        setFormData,
        signInWithEmail,
        signInWithGoogle,
        signInWithFacebook,
        signInWithGithub
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default LoginProvider;
