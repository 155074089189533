import { useEffect } from 'react';
import PageProperties from '../../../utils/PageProperties';
import HeaderSection from '../../headers/HeaderSection';
import FooterSection2 from '../../system/section/Footer2Section';
import MobileFooter from '../../users/components/MobileFooter';
import CommonAreaHead from '../components/CommonAreaHead';
import FixedHeaderControl from '../components/FixedHeaderControl';
import SolvedQuestionSection from '../components/knowledge-base-page/SolvedQuestionSection';

function KnowledgeBasePage() {
    useEffect(() => {
        PageProperties(`Knowledge Base \u2022 Support`);
    }, []);

    return (
        <>
            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>
            <div className="container mt-6">
                <CommonAreaHead title="Letest Question" value="That we Solved" />
                <SolvedQuestionSection />
            </div>
            <FooterSection2 />
            <MobileFooter />
        </>
    );
}

export default KnowledgeBasePage;
