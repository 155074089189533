import { icons, supportIcons } from '../../../../configs/Icons';

const supports = [
    {
        id: 1,
        title: 'Simple Pricing',
        img: icons.iconPricing,
        description:
            'Domains start at $9/year. Email forwarding, privacy protection, one-click DNSSEC, 2-Step Verification, and more included.'
    },
    {
        id: 2,
        title: 'DNS Management',
        img: icons.iconManagement,
        description:
            'Domains start at $9/year. Email forwarding, privacy protection, one-click DNSSEC, 2-Step Verification, and more included.'
    },
    {
        id: 3,
        title: 'Speed, Security, Support',
        img: supportIcons.iconSupportV2,
        description:
            'Experience speed and security using DNS servers that run on our Bikiran infrastructure with 24/7 friendly support.'
    }
];

function Item({ data }) {
    return (
        <div className="cell cell-4 cell-md-6 cell-sm-8">
            <div className="feature-item line-row-center">
                <div className="line-col-center">
                    <div className="logo">
                        <img className="w-100 d-block" src={data?.img} alt="icon" />
                    </div>
                    <span className="title mb-2">{data?.title}</span>
                    <span className="font-14 primary-30 text-center">{data?.description}</span>
                </div>
            </div>
        </div>
    );
}
function DomainFeatureSection() {
    return (
        <div className="container mb-120">
            <div className="feature-section">
                <div className="text-center">
                    <span className="font-35 primary ">
                        Put the Power of <br /> Bikiran behind your domain
                    </span>
                </div>
                <div className="line line-g3 line-md-no-wrap overflow-auto ml-sm-4 mr-sm-4">
                    {supports.map((data) => (
                        <Item key={data?.id} data={data} />
                    ))}
                </div>
            </div>
        </div>
    );
}
export default DomainFeatureSection;
