import React from 'react';
import ListActionButton from './ListActionButton';

function RowCommonView({ title, value }) {
    return (
        <div className="line-row-between mb-1">
            <div className="cell">
                <span className="value primary-70">{title || '--'}:</span>
            </div>
            <div className="cell">
                <span className="value primary">{value || '--'}</span>
            </div>
        </div>
    );
}

function MethodListMobile({ arr }) {
    if (arr?.length === 0) {
        return (
            <div className="list-item-sm">
                <span className="title primary">No Payment Method Found !</span>
            </div>
        );
    }
    return arr?.map((data) => (
        <div key={data?.id} className={`list-item-sm ${data?.isDemo ? 'placeholder-mode' : ''}`}>
            <div className="line-row-between mb-2">
                <span className="title">{data?.acType}</span>
                <span>
                    <ListActionButton />
                </span>
            </div>
            <RowCommonView title="Ac. Number" value={data?.acNum} />
            <RowCommonView title="Expire Date" value={data?.expDate} />
        </div>
    ));
}

export default MethodListMobile;
