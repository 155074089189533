import { useEffect } from 'react';

import { ButtonGr } from '../../../../configs/Buttons';
import PageProperties from '../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../headers/DashboardHeaderSection';
import SocialListSection from './social-list/SocialListSection';

function ContentHead() {
    return (
        <DashboardDefaultHeader
            title="Social Media"
            path="/user/basic-info/"
            content={
                <span className="line justify-end ">
                    <ButtonGr title="Add New" />
                </span>
            }
            mobileContent={
                <span className="line justify-end ">
                    <ButtonGr title="Add New" />
                </span>
            }
        />
    );
}

function UserSocialMediaPage() {
    useEffect(() => {
        PageProperties(`Social Media \u2022 Profile`);
    }, []);

    return (
        <div className="cell cell-content h-100 w-100">
            <div className="content-section">
                <div className="content-area">
                    <ContentHead />
                    <div
                        className="content-body-scroll user-social-media-area"
                        style={{ paddingBottom: '180px', position: 'relative' }}
                    >
                        <SocialListSection />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default UserSocialMediaPage;
