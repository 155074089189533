/* eslint-disable jsx-a11y/label-has-associated-control */
import { ButtonClose, ButtonGr, ButtonOutlinePink } from '../../../../../configs/Buttons';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import CopyText from '../../../websites/models/CopyText';
import { useRole } from '../RoleProvider';

const roles = [
    { id: 'manager', title: 'Manager' },
    { id: 'developer', title: 'Developer' }
];

const ModalHeader = ({ handleCloseClick, domain }) => (
    <div className="modal-box-header border-bottom">
        <div>
            <div className="modal-box-title">
                <span>Assign Role</span>
                <span>
                    <small>
                        <CopyText copyValue={domain} value={domain?.toUpperCase()} />
                    </small>
                </span>
            </div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={handleCloseClick} />
            </div>
        </div>
    </div>
);

function RoleSelectionField({ setFormData }) {
    const handelRoleValue = (ev) => {
        setFormData((cu) => ({ ...cu, role: ev.target.value }));
    };
    return (
        <div className="w-100 mb-3">
            <label htmlFor="role" className="label">
                Select Role:
            </label>
            <select className="form-input w-100" name="roles" onChange={handelRoleValue}>
                <option defaultChecked value="Select">
                    Select
                </option>
                {roles?.map((data) => (
                    <option key={data?.id} value={data?.id || ''}>
                        {data?.title}
                    </option>
                ))}
            </select>
        </div>
    );
}

function EmailField({ setFormData, formData }) {
    const handelEmailValue = (ev) => {
        setFormData((cu) => ({ ...cu, email: ev.target.value }));
    };
    return (
        <div className=" w-100 mb-1">
            <label htmlFor="email" className="label">
                User Email:
            </label>
            <input
                onChange={handelEmailValue}
                value={formData?.email || ''}
                className="form-input w-100"
                type="text"
                name="email"
                placeholder="EX: email@gmail.com"
            />
        </div>
    );
}

function ModalBody({ handleCloseClick }) {
    const { handelReload, handelInviteUser, setFormData, formData } = useRole();

    const { setMessage } = useTemplate();

    const handelCancelButton = () => {
        handleCloseClick();
    };

    const handelAssignButton = () => {
        setMessage('Assigning User...');
        handelInviteUser()
            .then(({ error, message }) => {
                if (error === 0) {
                    handleCloseClick();
                    handelReload();
                }
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    // console.log(formData);
    return (
        <div className="modal-body-area">
            <form>
                <EmailField formData={formData} setFormData={setFormData} />
                <RoleSelectionField formData={formData} setFormData={setFormData} />
                <div className="mt-4 line-align justify-end w-100">
                    <ButtonOutlinePink title="Cancel" evOnClick={handelCancelButton} modal />
                    <span className="ml-2">
                        <ButtonGr
                            evOnClick={handelAssignButton}
                            disabled={!formData?.email || !formData?.role}
                            title="Assign"
                            modal
                        />
                    </span>
                </div>
            </form>
        </div>
    );
}

function RoleAssignPopup({ setModal, show, domain }) {
    const { setFormData } = useRole();
    const handleCloseClick = () => setModal({ type: null }) + setFormData({});
    if (!show) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content" style={{ width: '400px', overflow: 'unset' }}>
                <ModalHeader handleCloseClick={handleCloseClick} domain={domain} />
                <ModalBody handleCloseClick={handleCloseClick} />
            </div>
        </div>
    );
}

export default RoleAssignPopup;
