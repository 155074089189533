import TwoFacAuthSecurityHead from '../twofacauth_security_head/TwoFacAuthSecurityHead';
import TwoFacAuthSecurityList from '../twofacauth_security_list/TwoFacAuthSecurityList';

function TwoFacAuthSecuritySection() {
    return (
        <div className="mb-6">
            <TwoFacAuthSecurityHead />
            <TwoFacAuthSecurityList />
        </div>
    );
}

export default TwoFacAuthSecuritySection;
