import { useEffect, useState } from 'react';

import { ButtonOutlinePink } from '../../../../configs/Buttons';
import { useInit } from '../../../contexts/InitProvider';
import PageProperties from '../../../utils/PageProperties';
import PurchaseHostingSection1 from '../../websites/components/PurchaseHostingSection1';
import { ShowPriceWithDuration } from '../../websites/models/Pricing';

const tabs = [
    {
        id: 1,
        title: 'Package',
        area: '13%'
    },
    {
        id: 2,
        title: 'RAM',
        area: '9%'
    },
    {
        id: 3,
        title: "CPU's",
        area: '9%'
    },
    {
        id: 4,
        title: 'Bandwidth',
        area: '15%'
    },
    {
        id: 5,
        title: 'Pricing',
        area: '16%'
    },
    {
        id: 6,
        title: 'On Renew',
        area: '16%'
    },

    {
        id: 7,
        title: '',
        area: '8%'
    }
];

function PackageItem({ item, setPurchaseData }) {
    return (
        <tr>
            <td className="package-quantity">
                {`${item?.property?.disk / 1024 || 0} GB ${item?.property?.diskType || 0}` || '--'}
            </td>
            <td>{`${item?.property?.ram / 1024} GB` || '--'}</td>
            <td>{item?.property?.cpu}x</td>
            <td>{`${item?.property?.bandwidth / 1024} GB` || '--'}</td>
            <td>
                <span>{ShowPriceWithDuration(item?.price || 0) || '--'}</span>
            </td>
            <td>
                <span>{ShowPriceWithDuration(item?.price || 0) || '--'}</span>
            </td>

            <td style={{ width: '10%' }}>
                <ButtonOutlinePink
                    title="Enroll Now"
                    evOnClick={() => {
                        setPurchaseData(item);
                    }}
                />
            </td>
        </tr>
    );
}

function AppHostingSection() {
    const [purchaseData, setPurchaseData] = useState(null);
    const { packages } = useInit();

    useEffect(() => {
        PageProperties('App Hosting');
    }, []);

    return (
        <>
            <div className="hosting-package-area overflow-auto">
                <table>
                    <thead>
                        <tr>
                            {tabs?.map((tab) => (
                                <th width={tab?.area} key={tab?.id}>
                                    {tab?.title}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {packages
                            ?.filter((fData) => fData.type === 'app_hosting')
                            ?.map((item) => (
                                <PackageItem
                                    key={item?.id}
                                    item={item}
                                    setPurchaseData={setPurchaseData}
                                />
                            ))}
                    </tbody>
                </table>
            </div>
            {purchaseData ? (
                <PurchaseHostingSection1
                    purchaseData={purchaseData}
                    setPurchaseData={setPurchaseData}
                />
            ) : null}
        </>
    );
}

export default AppHostingSection;
