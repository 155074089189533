import React, { useState } from 'react';
import { BtnProfileEdit, ButtonCancel } from '../../../../../configs/Buttons';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import { useBasicInfo } from '../UserBasicInfoProvider';

const compId = 'editName';

const EditView = ({
    currentUser,
    formData,
    setFormData,
    handelExitEdit,
    handelSubmitForm,
    mode
}) => {
    const handleUpdate = (ev) => {
        setFormData((cuData) => ({ ...cuData, fullName: ev.target.value }));
    };

    return (
        <div className={`edit-view-area ${mode === compId ? 'show' : ''}`}>
            <div>
                <div>
                    <div className="mb-2">
                        <span className="profile-subject">Full Name:</span>
                    </div>
                    <div className="mb-1">
                        <input
                            type="text"
                            className="form-input w-100"
                            onChange={handleUpdate}
                            value={formData.fullName}
                        />
                    </div>
                    <div className="line line-sm-no-wrap text-start note-text">
                        <span className="value fw-500">Note:</span>
                        &nbsp;
                        <span className="value fw-400">Change your name</span>
                    </div>
                </div>
                <div className="line justify-end ">
                    <ButtonCancel evOnClick={handelExitEdit} title="Cancel" />
                    <button
                        onClick={handelSubmitForm}
                        type="button"
                        className="default-none btn-gr btn-gr-web ml-2"
                        disabled={currentUser?.displayName === formData.fullName.trim()}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

function ProfileFullName({ currentUser }) {
    const { setMessage } = useTemplate();
    const { updateName, mode, setMode } = useBasicInfo();
    const [formData, setFormData] = useState({
        fullName: currentUser?.displayName || ''
    });

    const handelClickEdit = () => {
        setMode(compId);
    };

    const handelExitEdit = () => {
        setMode(null);
    };

    const handelSubmitForm = () => {
        setMessage('Updating name ...');
        updateName(formData)
            .then(({ message }) => {
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    return (
        <>
            <div className={`line default-view-area ${mode === compId ? 'border-none' : ''}`}>
                <div className="cell  cell-3 cell-md-4 od-opacity">
                    <span className="profile-subject">Full Name:</span>
                </div>
                <div className="cell-fill  od-opacity">
                    <span className="value">{currentUser?.displayName || '--'}</span>
                </div>
                <div className="cell  od-opacity">
                    <BtnProfileEdit evOnClick={handelClickEdit} isDisabled={!!mode} />
                </div>
            </div>
            <EditView
                currentUser={currentUser}
                formData={formData}
                setFormData={setFormData}
                handelExitEdit={handelExitEdit}
                handelSubmitForm={handelSubmitForm}
                mode={mode}
            />
        </>
    );
}

export default ProfileFullName;
