function PointsFeatureArea({ points }) {
    return (
        <div className="bik-points-bg mb-4">
            <div className="line-align line-no-wrap h-100 bik-points-container">
                <div className="cell-fill">
                    <div className="fs-25 mb-1" style={{ fontWeight: '400px' }}>
                        Welcome to
                    </div>
                    <div className="font-36 no-wrap">Bikiran Point</div>
                </div>
                <div className="cell-fill">
                    <div className="text-right mr-3">
                        <div className="font-16 mb-1">You Have</div>
                        <div className="fz-60 mt-0 mb-1">{points}</div>
                        <div className="font-16 mt-0 no-wrap">Bikiran Points</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PointsFeatureArea;
