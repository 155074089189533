import React, { useEffect, useState } from 'react';

import { ButtonGr, ButtonOutlinePurple } from '../../../../configs/Buttons';
import CommonPlaceholder from '../../../placeholders/components/CommonPlaceholder';
import PageProperties from '../../../utils/PageProperties';
import { DashboardDefaultHeader } from '../../headers/DashboardHeaderSection';
import CommonModal from '../../system/modals/CommonModal';

function ContentHead() {
    return <DashboardDefaultHeader title="Software" path="/user/profile-overview/" />;
}
function ContentBodyArea({ data }) {
    const [openModal, setOpenModal] = useState(null);

    if (!data?.length) {
        return <CommonPlaceholder text="Software Service" url="" />;
    }

    return (
        <div className="product-info mb-6">
            <div>
                <div className="line mb-5">
                    <div className="cell cell-6 cell-sm-12">
                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">Subscription Name:</span>
                            </div>
                            <div className="cell cell-7">
                                <span className="value">eCommerce web app</span>
                            </div>
                        </div>

                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">Price: </span>
                            </div>
                            <div className="cell cell-7">
                                <span className="value">BDT 5000/M</span>
                            </div>
                        </div>
                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">Registered on: </span>
                            </div>
                            <div className="cell cell-7">
                                <span className="value">30 - July - 2019</span>
                            </div>
                        </div>
                    </div>
                    <div className="cell cell-6 cell-sm-12">
                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">Expired on: </span>
                            </div>
                            <div className="cell cell-7">
                                <div>
                                    <span className="value">30 - July - 2020</span>
                                    <br />
                                    <span className="value-info">194 Days Remain</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {/* <button
                        onClick={() => setOpenModal(true)}
                        type="button"
                        className="button button-outline-pink mr-4"
                    >
                        
                    </button> */}
                    <span>
                        <ButtonOutlinePurple
                            evOnClick={() => setOpenModal(true)}
                            title="Show More"
                        />
                    </span>
                    <span className="ml-3">
                        <ButtonGr evOnClick={() => setOpenModal(true)} title="Contact Us" />
                    </span>

                    {/* <button
                        onClick={() => setOpenModal(true)}
                        type="button"
                        className="button button-gr-green"
                    >
                        
                    </button> */}
                </div>
                {openModal && <CommonModal openModal={openModal} setOpenModal={setOpenModal} />}
            </div>
        </div>
    );
}
function UsersSoftwarePage() {
    useEffect(() => {
        PageProperties(`Software List`);
    }, []);

    return (
        <div className="cell cell-content h-100">
            <div className="content-section">
                <div className="content-area">
                    <ContentHead />
                    <div className="content-body-scroll">
                        <ContentBodyArea />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsersSoftwarePage;
