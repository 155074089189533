import React, { useEffect, useRef } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ButtonGr } from '../../../../../configs/Buttons';
import { arrowIcons, icons } from '../../../../../configs/Icons';

// import { arrowIcons, icons } from '../../../../../configs/Icons';
import CopyText from '../../../websites/models/CopyText';

const demoArr = [
    {
        id: 11111111111,
        title: '------------------',
        domain: '-------------',
        demo: true
    }
];

function BuyMoreBtn({ show }) {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const isHosting = pathname?.includes('hosting');
    const path = isHosting ? '/services/hosting/web/' : '/domain/';

    if (!show) {
        return null;
    }
    return (
        <div className="" style={{ background: 'unset' }}>
            <ButtonGr evOnClick={() => navigate(path)} title="Buy More" />
        </div>
    );
}

function ListItem({ title, id, domain }) {
    const isNull = !domain;
    return (
        <div className="clickable">
            <button
                style={{ cursor: 'default' }}
                type="button"
                className="default-none w-100 text-left no-wrap clr"
            >
                <div className="overflow-dotted mr-4">
                    <span className="title gap ">
                        {isNull ? title?.toUpperCase() : title || '--'}
                    </span>
                </div>
                <div className="line align-start line-no-wrap sub-title">
                    <CopyText copyValue={id} value={`#${id}`} />
                    <span hidden={!id || !domain} className="ml-2 mr-2 ml-sm-1 mr-sm-1 font-18">
                        &bull;
                    </span>{' '}
                    <CopyText copyValue={domain} value={domain?.toUpperCase()} />
                </div>
            </button>
        </div>
    );
}

function OtherServicesList({
    arr,
    setShowOption,
    showOption,
    id,
    path,
    evReloadClick,
    title,
    domain
}) {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const isHosting = pathname?.includes('hosting');

    const handelItemClick = (ev, route) => {
        const ServicePath = isHosting ? 'hosting' : 'domain';
        navigate(`/user/services/${ServicePath}/${route}`);

        if (ev) {
            setShowOption(false);
            evReloadClick();
        }
    };

    if (!showOption) {
        return null;
    }
    return arr
        ?.filter?.((el) => parseInt(el?.id, 10) !== parseInt(id, 10))
        ?.map((data) => {
            // const isActivityPage = path === 'activity/' ? `${data?.id}/${path}` : null;

            const route = data?.domain ? `${data?.id}/${path || ''}` : `${data?.id}/`;

            return (
                <div
                    key={data?.id}
                    role="button"
                    tabIndex={-1}
                    onClick={(ev) => handelItemClick(ev, route)}
                    className={`clickable child-list ${data?.demo ? 'placeholder-mode' : ''}`}
                >
                    <button
                        onClick={null}
                        type="button"
                        className="default-none w-100 text-left no-wrap clr"
                    >
                        <div>
                            <span className="title gap d-inline-block">
                                {isHosting
                                    ? data[title]
                                    : data[title]?.toUpperCase() || '-----------------------'}
                            </span>
                        </div>
                        <div className="line align-start line-no-wrap sub-title">
                            <span hidden={!data?.id} className="tooltip">
                                <span>#{data?.id || '--------------'}</span>
                            </span>
                            <span
                                hidden={!data?.id || !data[domain]}
                                className="ml-2 mr-2 ml-sm-1 mr-sm-1 font-18"
                            >
                                &bull;
                            </span>{' '}
                            <span hidden={!data[domain]} className="tooltip overflow-dotted">
                                <span>{data[domain]?.toUpperCase()}</span>
                            </span>
                        </div>
                    </button>
                </div>
            );
        });
}

function HeaderMenuPopup({
    setShowOption,
    showOption,
    title,
    domain,
    evOnClick,
    path,
    evReloadClick,
    info,
    data
}) {
    const { contractId } = useParams();
    const myRef = useRef();

    useEffect(() => {
        function handelClickOutside(event) {
            if (myRef?.current && !myRef?.current.contains(event?.target)) {
                // alert("You clicked outside of me!");
                setShowOption(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handelClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handelClickOutside);
        };
    }, [myRef, setShowOption, showOption]);

    const arr = data || demoArr;
    return (
        <div className="position-relative" ref={myRef}>
            {' '}
            <div className={`header-info-title-area ${showOption ? 'active' : ''}`}>
                <div className="inst-option-pop line-col-center">
                    <div className="inst-option">
                        <ListItem
                            title={info[title]}
                            id={parseInt(contractId, 10)}
                            domain={info[domain]}
                        />
                    </div>
                    <div className="inst-option list overflow-auto">
                        {showOption && arr && arr?.length !== 0 ? (
                            <OtherServicesList
                                evReloadClick={evReloadClick}
                                id={parseInt(contractId, 10)}
                                setShowOption={setShowOption}
                                showOption={showOption}
                                arr={arr}
                                path={path}
                                domain={domain}
                                title={title}
                            />
                        ) : null}
                        <BuyMoreBtn show={showOption && data} />
                    </div>
                    <span className="action-icon" role="button" tabIndex={-1} onClick={evOnClick}>
                        <img
                            className={`d-block `}
                            src={showOption ? icons.iconDel1 : arrowIcons.iconArrowBottomV4}
                            alt="arrow"
                        />
                    </span>
                </div>
            </div>
        </div>
    );
}

export default HeaderMenuPopup;
