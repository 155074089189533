function SearchInput({ width = '100%', height, cName, placeholder, setValue, value, onBlur }) {
    return (
        <input
            onChange={setValue || null}
            onBlur={onBlur || null}
            placeholder={placeholder || 'Search...'}
            value={value}
            type="text"
            className={`search-input ${cName}`}
            style={{ width, height }}
        />
    );
}

export default SearchInput;
