/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { ButtonGr } from '../../../../../configs/Buttons';

function PasswordEditInput() {
    return (
        <div className="cell-6 cell-md-12">
            <div className="password-input-area">
                <div className="mb-1">
                    <label htmlFor="oldPassword" className="label">
                        Old Password
                    </label>{' '}
                    <input
                        className="form-input w-100"
                        type="text"
                        placeholder="Type Old Password"
                    />
                </div>
                <div className="mb-1">
                    <label htmlFor="onewPassword" className="label">
                        New Password
                    </label>{' '}
                    <input
                        className="form-input w-100"
                        type="text"
                        placeholder="Type New Password"
                    />
                </div>
                <div className="">
                    <label htmlFor="reTypePassword" className="label">
                        Re-Type Password
                    </label>{' '}
                    <input
                        className="form-input w-100"
                        type="text"
                        placeholder="Re-Type New Password"
                    />
                </div>
                <div className="mt-4 line justify-end ">
                    <ButtonGr evOnClick={null} title="Save Password" />
                </div>
            </div>
        </div>
    );
}

export default PasswordEditInput;
