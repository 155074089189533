import React from 'react';
import { UpperCase } from '../../../../../utils/Show';
import InfoStatusHandler from '../../../../users/models/InfoStatusHandler';
import ListActionButton from './ListActionButton';

function Body({ arr }) {
    if (arr?.length === 0) {
        return (
            <tbody>
                <tr>
                    <td colSpan={100}>
                        <span className="font-16">No Transactions Found !</span>
                    </td>
                </tr>
            </tbody>
        );
    }
    return arr?.map((item) => (
        <tbody key={item?.id} className={item?.isDemo ? 'placeholder-mode' : ''}>
            <tr key={item.id}>
                <td colSpan={1}>
                    <span>#{item.trxId || '--'}</span>
                </td>
                <td colSpan={1}>
                    <span>{item.date || '--'}</span>
                </td>
                <td>
                    <span style={{ color: InfoStatusHandler(item) }}>
                        {UpperCase(item?.status) || '--'}
                    </span>{' '}
                </td>
                <td colSpan={1}>
                    <span>{item.type || '--'}</span>
                </td>
                <td colSpan={1}>
                    <span>{item.total || '--'}</span>
                </td>
                <td>
                    <ListActionButton />
                </td>
            </tr>
        </tbody>
    ));
}

function TransactionListWeb({ arr }) {
    return (
        <table className="table-list web-device" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th colSpan={1}>TrxID</th>
                    <th colSpan={1}>Date</th>
                    <th colSpan={1}>Status</th>
                    <th colSpan={1}>Type</th>
                    <th colSpan={1}>Total</th>
                    <th colSpan={1}>Action</th>
                </tr>
            </thead>
            <Body arr={arr} />
        </table>
    );
}

export default TransactionListWeb;
