import LayoutSelector from '../../../utils/LayoutSelector';

function TableActionButton({ show, children }) {
    if (!show) {
        return null;
    }
    return (
        <LayoutSelector
            sm
            md
            lg
            mobileComp={<div className="table-action-button">{children}</div>}
        />
    );
}

export default TableActionButton;
