import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BtnProfileManage } from '../../../../../configs/Buttons';
import { useBasicInfo } from '../UserBasicInfoProvider';

function ProfilePhone({ currentUser }) {
    const navigate = useNavigate();
    const { mode } = useBasicInfo();

    const handelOnClick = () => {
        navigate('/user/contacts/');
    };

    return (
        <div className="line default-view-area">
            <div className="cell  cell-3 cell-md-4 od-opacity">
                <span className="profile-subject">Phone:</span>
            </div>
            <div className="cell-fill  od-opacity">
                <span className="value">{currentUser.phone || '--'}</span>
            </div>
            <div className="cell  od-opacity">
                <BtnProfileManage evOnClick={handelOnClick} isDisabled={!!mode} />
            </div>
        </div>
    );
}

export default ProfilePhone;
