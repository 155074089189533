import { useEffect } from 'react';
import { ButtonGr } from '../../../../configs/Buttons';
import PageProperties from '../../../utils/PageProperties';
import PointsConverterSection from './components/PointsConverterSection';
import PointsFeatureArea from './components/PointsFeatureArea';
import PointsHistorySection from './components/PointsHistorySection';
import UsersPointsProvider from './UsersPointsProvider';

function ContentHead() {
    return (
        <div className="content-head mb-4">
            <div className="line line-g1 mb-3 ml-6 mr-6 mt-4">
                <div className="cell">
                    <h1 className="content-title text-left">My Bikiran Points</h1>
                    <small className="subject">
                        You can pay using your BIKIRAN points when you buy any product.
                    </small>
                </div>
                <div className="cell-fill">&nbsp;</div>
                <div className="cell cell-self-middle">
                    <div>
                        <ButtonGr evOnClick={null} title="Earn Points" />
                    </div>
                </div>
            </div>
        </div>
    );
}
function ContentBodyArea() {
    const points = 9;
    return (
        <div className="product-info mb-6">
            <PointsFeatureArea points={points} />
            <PointsConverterSection points={points} />
            <div className="line-align mb-4 fz-16 fw-500">BIKIRAN Points History</div>
            <PointsHistorySection points={points} />
        </div>
    );
}
function UsersPointPage() {
    useEffect(() => {
        PageProperties(`Points`);
    }, []);

    return (
        <UsersPointsProvider>
            <div className="cell cell-content h-100">
                <div className="content-section">
                    <div className="content-area">
                        <ContentHead />
                        <div className="content-body-scroll">
                            <ContentBodyArea />
                        </div>
                    </div>
                </div>
            </div>
        </UsersPointsProvider>
    );
}

export default UsersPointPage;
