import { fileSizeDisplay } from '../../../../utils/Show';
import ListActionButton from './ListActionButton';

function TitleArea({ data }) {
    return (
        <div className="line-row-between line-no-wrap mb-2">
            <span>
                <span className="title primary mr-2">{data?.database}</span>
                <span className="sub-title primary-50 d-block">
                    {fileSizeDisplay(data?.disk_usage, ' ')}
                </span>
            </span>
            <ListActionButton data={data} />
        </div>
    );
}

function DatabaseListMobile({ arr }) {
    if (!arr?.length) {
        return (
            <div>
                <div className="list-item-sm">
                    <span className="title primary">No Database Found !</span>
                </div>
            </div>
        );
    }
    return (
        <div>
            {arr?.map((data) => (
                <div key={data?.database} className="list-item-sm">
                    <TitleArea data={data} />
                    <div className="line-row-between align-unset w-100">
                        <div className="cell cell-4">
                            <span className="value primary-70-i">Databases:</span>
                        </div>
                        <div className="cell cell-8">
                            <div className="line justify-end">
                                {data?.users?.map((user, i) => (
                                    <span className="value" key={user}>
                                        {` ${user}${
                                            i !== data?.users?.length - 1 && data?.users?.length > 0
                                                ? ','
                                                : ''
                                        }`}
                                        &nbsp;
                                    </span>
                                )) || '--'}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default DatabaseListMobile;
