/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import { ButtonGr } from '../../../../configs/Buttons';
import { icons } from '../../../../configs/Icons';

const ModalHeader = () => (
    <div className="modal-box-header">
        <div>
            <div className="line-row-center w-100">
                <a href="https://www.bikiran.com/" className="logo">
                    <img className="d-block w-100" src={icons.iconBikLogoV3} alt="" />
                </a>
            </div>
        </div>
    </div>
);

function FirstStep({ show, setFormData, formData }) {
    // Fetch the IP address when the component mounts
    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then((response) => response.json())
            .then((data) => setFormData((cuData) => ({ ...cuData, ip: data?.ip })))
            .catch((error) => console.error(error));
    }, [setFormData]);

    const handleInputValue = (ev) => {
        setFormData((cuData) => ({ ...cuData, ip: ev.target.value }));
    };

    if (!show) {
        return null;
    }
    return (
        <div className="mb-3">
            <label className="label" htmlFor="ip">
                Put IP Address:
            </label>
            <input
                className="form-input w-100"
                placeholder="Enter IP...."
                type="text"
                value={formData?.ip || ''}
                onChange={handleInputValue}
            />
        </div>
    );
}

function SecondStep({ show, setFormData, formData }) {
    const handleInputValue = (ev) => {
        setFormData((cuData) => ({ ...cuData, port: ev.target.value }));
    };

    if (!show) {
        return null;
    }
    return (
        <div className="mb-3">
            <label className="label" htmlFor="ip">
                Port:
            </label>
            <input
                className="form-input w-100"
                placeholder="Enter Port...."
                type="text"
                value={formData?.port}
                maxLength={5}
                onChange={handleInputValue}
            />
        </div>
    );
}

function ThirdStep({ show, setFormData, formData }) {
    const handleUsernameValue = (ev) => {
        setFormData((cuData) => ({ ...cuData, username: ev.target.value }));
    };
    const handlePasswordValue = (ev) => {
        setFormData((cuData) => ({ ...cuData, password: ev.target.value }));
    };

    if (!show) {
        return null;
    }
    return (
        <div className="mb-3">
            <div className="">
                <label className="label" htmlFor="ip">
                    Username:
                </label>
                <input
                    className="form-input w-100"
                    placeholder="Enter Username...."
                    type="text"
                    value={formData?.username}
                    onChange={handleUsernameValue}
                />
            </div>
            <span role="button" tabIndex={-1} className="purple font-12">
                Get Username
            </span>
            <div className="mb-2">
                <label className="label" htmlFor="ip">
                    Password:
                </label>
                <input
                    className="form-input w-100"
                    placeholder="Enter Password...."
                    type="text"
                    value={formData?.password}
                    onChange={handlePasswordValue}
                />
            </div>
        </div>
    );
}

function ServiceItem({ title, value }) {
    return (
        <div className="line mb-2">
            <div className="cell cell-3">
                <span className="font-16 primary-50">{title}:</span>
            </div>
            <div className="cell cell-fill">
                <span className="font-16">{value}</span>
            </div>
        </div>
    );
}

function ServicesView() {
    return (
        <div className="wizard-body">
            <div className="mb-3">
                <ServiceItem title="Services" value="--" />
                <ServiceItem title="Items" value="--" />
            </div>
        </div>
    );
}

function ModalBody() {
    const [formData, setFormData] = useState({ ip: '', port: '', username: '', password: '' });
    const [step, setStep] = useState(1);

    const handelNextButton = () => {
        if (step < 4) {
            setStep(step + 1);
        } else {
            setStep(step);
        }
    };

    if (step === 4) {
        return <ServicesView />;
    }
    return (
        <div className="wizard-body">
            <div>
                <span className="body-title">Access Control</span>

                <FirstStep show={step === 1} formData={formData} setFormData={setFormData} />
                <SecondStep show={step === 2} formData={formData} setFormData={setFormData} />
                <ThirdStep show={step === 3} formData={formData} setFormData={setFormData} />

                <div className="line-row-center">
                    <span className="action-button">
                        <ButtonGr evOnClick={handelNextButton} title="Next" modal />
                    </span>
                </div>
            </div>
        </div>
    );
}

function AccessWizardPage() {
    return (
        <div className="modal-box backdrop access-wizard-area">
            <div className="modal-box-content">
                <ModalHeader />

                <ModalBody />
            </div>
        </div>
    );
}

export default AccessWizardPage;
