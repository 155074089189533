import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonActionReload, ButtonGr } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../contexts/AuthProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';
import PageProperties from '../../../../utils/PageProperties';
import { mkToken } from '../../../../utils/StringOperation';
import { DashboardDefaultHeader } from '../../../headers/DashboardHeaderSection';
import TableActionButton from '../../_models/TableActionButton';
import InvoiceTableList from './InvoiceTableList';

function HeaderButtonMobile({ handelReload }) {
    return (
        <div className="line justify-end">
            <div className="header-action-btn">
                <ButtonActionReload evOnClick={handelReload} />
            </div>
        </div>
    );
}

function BillingsInvoiceListPage() {
    const [invoiceList, setInvoiceList] = useState(null);

    const [uniqueKey, setUniqueKey] = useState(mkToken(32));

    const handelReload = () => {
        setUniqueKey(mkToken(32));

        setInvoiceList(null);
    };

    const { currentUser } = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        PageProperties(`Invoice List`);
    }, []);

    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .setApiUrl('API_BILLING_INVOICE', '/list/')
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    setInvoiceList(data.invoices);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentUser, uniqueKey]);

    // if (invoiceList === null) {
    //     return null;
    // }

    return (
        <div className="content-area">
            <DashboardDefaultHeader
                title="Invoices"
                path="/user/profile-overview/"
                content={
                    <span className="line justify-end">
                        <ButtonGr evOnClick={() => navigate('/domain')} title="Buy More" />
                    </span>
                }
                mobileContent={<HeaderButtonMobile handelReload={handelReload} />}
            />

            <div className="content-body">
                <div className="content-body-scroll">
                    <InvoiceTableList invoiceList={invoiceList} />
                    <TableActionButton show={invoiceList}>
                        <div className="line-row-center mb-4">
                            <Link to="/services/hosting/web/">
                                <ButtonGr title="Buy More" />
                            </Link>
                        </div>
                    </TableActionButton>
                </div>
            </div>
        </div>
    );
}

export default BillingsInvoiceListPage;
