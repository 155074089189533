import { ButtonClose } from '../../../../configs/Buttons';
import { socialIcon } from '../../../../configs/Icons';
import { useTemplate } from '../../../contexts/TemplateProvider';
import { InpPassword } from '../../../utils/inputs/PasswordInputs';
import { SignUpLink } from '../components/AuthLinks';
import { useLogin } from './LoginProvider';

const ModalHeader = ({ setExitModal, modalProperty }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title">Login</div>
            <div className="modal-box-close">
                <ButtonClose
                    evOnClick={
                        typeof modalProperty.onExit === 'function'
                            ? modalProperty.onExit
                            : setExitModal
                    }
                />
            </div>
        </div>
    </div>
);

const InputUsername = ({ value, setValue }) => {
    const handelUpdateData = (ev) => {
        setValue((cVal) => ({ ...cVal, username: ev.target.value }));
    };

    return (
        <div className="cell cell-12 input-grp mb-2">
            <div className="label">Email Address</div>
            <input
                type="text"
                className="form-input d-block w-100"
                placeholder="ex: username@email.com"
                value={value}
                onChange={handelUpdateData}
            />
        </div>
    );
};

const InputPassword = ({ value, setValue }) => {
    const handelUpdateData = (ev) => {
        setValue((cVal) => ({ ...cVal, password: ev.target.value }));
    };

    return (
        <div className=" cell cell-12 input-grp login-pass mb-2">
            <InpPassword
                title="Password"
                name="password"
                value={value}
                setValue={handelUpdateData}
            />
            {/* <div className="label">Password</div>
            <input
                type="password"
                className="form-input d-block w-100"
                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                value={value}
                onChange={handelUpdateData}
            /> */}
        </div>
    );
};

const LoginForm = ({ formData, setFormData, signInWithEmail, makeAction, setMessage }) => {
    const handleLogin = (ev) => {
        ev.preventDefault();

        if (!formData.username) {
            // refUsername?.current.focus();
            setMessage('Please enter email');
        } else if (!formData.password) {
            // refPassword?.current.focus();
            setMessage('Please enter password');
        } else {
            setMessage('Logging In...');

            signInWithEmail()
                .then(() => {
                    // handleCloseClick();
                    setMessage('Logged in successfully');
                })
                .catch((err) => {
                    console.log(err);
                    setMessage('Failed to login');
                });
        }
    };

    return (
        <>
            <div className="line line-g2">
                <InputUsername value={formData.username} setValue={setFormData} />

                <InputPassword value={formData.password} setValue={setFormData} />
            </div>

            <div className="forget-pass mb-3">
                <a
                    href="/"
                    onClick={(ev) => {
                        ev.preventDefault();
                        makeAction('forget-password');
                    }}
                >
                    Forgot Password?
                </a>
            </div>

            <div className="mb-3">
                <button
                    className="button button-gr-pink w-100 login-btn"
                    type="button"
                    onClick={handleLogin}
                >
                    Sign In
                </button>
            </div>
        </>
    );
};

const SocialLoginButtons = ({
    signInWithGoogle,
    signInWithFacebook,
    signInWithGithub,
    setMessage
}) => {
    const handelGoogleLogin = () => {
        setMessage({
            text: 'Logging in ... through Google account',
            timeout: 60000
        });

        signInWithGoogle()
            .then(({ message }) => {
                setMessage({
                    text: message,
                    timeout: 8000
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handelFacebookLogin = () => {
        setMessage({
            text: 'Logging in ... through Facebook account',
            timeout: 60000
        });
        signInWithFacebook()
            .then(({ message }) => {
                setMessage({
                    text: message,
                    timeout: 8000
                });
            })
            .catch((err) => console.log(err));
    };

    const handelGithubLogin = () => {
        setMessage({
            text: 'Logging in ... through Github account',
            timeout: 60000
        });

        signInWithGithub()
            .then(({ message }) => {
                setMessage({
                    text: message,
                    timeout: 8000
                });
            })
            .catch((err) => console.log(err));
    };

    return (
        <div className="line line-g3 mb-2">
            <div className="cell cell-12 mb-2">
                <button
                    className="button social-login-btn-gl w-sm-100 w-100 button-login-op login-btn"
                    type="button"
                    onClick={handelGoogleLogin}
                >
                    <img src={socialIcon.google} alt="Google" />
                    <span>Sign In With Google</span>
                </button>
            </div>

            <div className="cell cell-12 mb-2">
                <button
                    className="button social-login-btn-fb w-sm-100 w-100 button-login-op login-btn"
                    type="button"
                    onClick={handelFacebookLogin}
                >
                    <img src={socialIcon.facebook} alt="Facebook" />
                    <span>Sign In With Facebook</span>
                </button>
            </div>

            <div className="cell cell-12">
                <button
                    className="button social-login-btn-gt w-sm-100 w-100 button-login-op login-btn"
                    type="button"
                    onClick={handelGithubLogin}
                >
                    <img src={socialIcon.github} alt="Github" />
                    <span>Sign In With Github</span>
                </button>
            </div>
        </div>
    );
};

const ModalBody = ({ modalProperty, currentUser, setCurrentUser, setMessage, makeAction }) => {
    const {
        formData,
        setFormData,
        signInWithEmail,
        signInWithGoogle,
        signInWithFacebook,
        signInWithGithub
    } = useLogin();

    return (
        <div className="modal-box-body">
            <div>
                <div className="login-modal-area">
                    <LoginForm
                        formData={formData}
                        setFormData={setFormData}
                        signInWithEmail={signInWithEmail}
                        modalProperty={modalProperty}
                        currentUser={currentUser}
                        setCurrentUser={setCurrentUser}
                        makeAction={makeAction}
                        setMessage={setMessage}
                    />

                    <div className="text-center text-g mb-4 line">
                        <div className="cell-5 login-divider">
                            <hr />{' '}
                        </div>
                        <div className="cell-2">or</div>
                        <div className="cell-5 login-divider">
                            <hr />
                        </div>
                    </div>

                    <SocialLoginButtons
                        signInWithGoogle={signInWithGoogle}
                        signInWithFacebook={signInWithFacebook}
                        signInWithGithub={signInWithGithub}
                        setMessage={setMessage}
                    />

                    <SignUpLink makeAction={makeAction} />
                </div>
            </div>
        </div>
    );
};

function LoginModal({ modalProperty, currentUser, setCurrentUser, setExitModal, makeAction }) {
    const { setMessage } = useTemplate();

    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <ModalHeader setExitModal={setExitModal} modalProperty={modalProperty} />

                <ModalBody
                    modalProperty={modalProperty}
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                    setMessage={setMessage}
                    makeAction={makeAction}
                />
            </div>
        </div>
    );
}

export default LoginModal;
