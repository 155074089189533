import { ButtonOutlinePurple } from '../../../../../configs/Buttons';
import { osIcon } from '../../../../../configs/Icons';

function ListItem({ item }) {
    return (
        <div className="info-list-view">
            <div className="line-align line-no-wrap ">
                <div className="cell ">
                    <div className="line-align line-no-wrap">
                        <div className="list-logo d-sm-none mr-2">
                            <img
                                className="d-block w-100"
                                src={
                                    item?.device === 'Windows PC'
                                        ? osIcon.windowsV2
                                        : osIcon.androidV2
                                }
                                alt="icon device"
                            />
                        </div>
                        <div className="content-text">
                            <span className="font-16 clr">{item?.device}</span>
                            <span className="font-14 opacity-medium d-block">{item?.location}</span>
                        </div>
                    </div>
                </div>

                <div className="cell-fill">
                    <div className="line justify-end mb-1">
                        <span className="font-14 clr d-block">{item?.browser}</span>
                    </div>
                    {item?.isActive ? (
                        <div className="text-right">
                            <span className="font-14 green">Active Now</span>
                        </div>
                    ) : (
                        <div className="text-right">
                            <ButtonOutlinePurple title="Revoke Now" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function ActivityListMobile({ arr }) {
    return arr?.map((item) => <ListItem key={item?.id} item={item} />);
}

export default ActivityListMobile;
