import { useNavigate, useParams } from 'react-router-dom';
import { ButtonPurple } from '../../../../../../configs/Buttons';
import { GetDate } from '../../../../../utils/Show';

function TopRow({ data }) {
    const { contractId } = useParams();
    const navigate = useNavigate();

    const handelManage = () => {
        navigate(`/user/services/hosting/${contractId}/conversation/team/`);
    };

    return (
        <div className="line-row-between mb-2">
            <div className="cell">
                <span className="title primary">{data?.topics}</span>
            </div>
            <div className="cell">
                <span className="button-action-sm">
                    <ButtonPurple title="Manage" evOnClick={handelManage} />
                </span>
            </div>
        </div>
    );
}

function RowCommonArea({ title, value }) {
    return (
        <div className="line-align line-no-wrap mb-1">
            <div className="cell cell-4">
                <span className="value primary-70-i">{title}</span>
            </div>
            <div className="cell cell-fill">
                <div className="line justify-end">
                    <span className="value primary">{value}</span>
                </div>
            </div>
        </div>
    );
}

function ConversationListMobile({ arr }) {
    return arr?.map((data) => {
        const length = data?.team?.length > 4 ? data?.team?.length - 4 : '';

        return (
            <div key={data?.id} className="list-item-sm">
                <TopRow data={data} />
                <RowCommonArea
                    title="Team"
                    value={
                        <div className="line-align position-relative">
                            {data?.team?.slice(0, 4)?.map((item, index) => (
                                <span
                                    className={`team-avatar ${
                                        data?.team?.length > 4 && index === 3 ? 'length' : ''
                                    }`}
                                    key={Math.random()}
                                    style={{
                                        left: index === 0 ? 0 : -index * 5,
                                        content: '5'
                                    }}
                                >
                                    <img className="d-block" src={item?.img} alt="" />
                                    {length && index === 3 && (
                                        <div className="team-length ">{length}</div>
                                    )}
                                </span>
                            ))}
                        </div>
                    }
                />
                <RowCommonArea title="Last Activity" value={GetDate(data?.activity)} />
            </div>
        );
    });
}

export default ConversationListMobile;
