import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import PageProperties from '../../../utils/PageProperties';
import HostingInfoHeader from '../hostings_info_header/HostingInfoHeader';
import TabsSection from '../hostings_info_tabs/TabSection';
import HostingInfoStorageProvider, { useHostingStorageInfo } from './HostingInfoStorageProvider';
import StorageInfoSection from './storageinfo_info/StorageInfoSection';

const demoData = [
    {
        fileUsed: [
            {
                id: 1,
                title: 'thisisaverylargedomainthisisaverylargedomainthisisaverylargedomainthisisaverylargedomain.com',
                used: '2KB',
                color: '#FFA113',
                progress: 28
            },
            {
                id: 2,
                title: 'thisisaverylargedomain.com',
                used: '5MB',
                color: '#25D4A9',
                progress: 19
            },
            {
                id: 3,
                title: 'thisisaverylargedomain.comthisisaverylargedomainthisisaverylargedomainthisisaverylargedomain',
                used: '2KB',
                color: '#5685FF',
                progress: 10
            },
            {
                id: 4,
                title: 'thisisaverylargedomain.com',
                used: '7Kb',
                color: '#FF859B',
                progress: 38
            },
            {
                id: 5,
                title: 'thisisaverylargedomain.com',
                used: '2KB',
                color: '#F50303',
                progress: 100
            }
        ],
        databaseUsed: [
            {
                id: 1,
                title: 'Database Name',
                used: '2KB',
                color: '#FFA113',
                progress: 28
            },
            {
                id: 2,
                title: 'Database Name',
                used: '5MB',
                color: '#25D4A9',
                progress: 19
            },
            {
                id: 3,
                title: 'Database Name',
                used: '2KB',
                color: '#5685FF',
                progress: 10
            },
            {
                id: 4,
                title: 'Database Name',
                used: '7Kb',
                color: '#FF859B',
                progress: 38
            },
            {
                id: 5,
                title: 'Database Name',
                used: '2KB',
                color: '#F50303',
                progress: 100
            }
        ],
        emailUsed: [
            {
                id: 1,
                title: 'name@example.com',
                used: '2.8 GB',
                color: '#FFA113',
                progress: 28
            },
            {
                id: 2,
                title: 'thedailynayadiganta@example.com',
                used: '2.9 GB',
                color: '#25D4A9',
                progress: 19
            },
            {
                id: 3,
                title: 'name@example.com',
                used: '548 MB',
                color: '#5685FF',
                progress: 10
            },
            {
                id: 4,
                title: 'name@example.com',
                used: '1 GB',
                color: '#FF859B',
                progress: 38
            },
            {
                id: 5,
                title: 'name@example.com',
                used: '400 MB',
                color: '#F50303',
                progress: 100
            }
        ]
    }
];

function ContentArea() {
    const { storageInfo, handelReloadButton } = useHostingStorageInfo();
    return (
        <>
            <HostingInfoHeader
                path="storage/"
                info={storageInfo}
                evReloadClick={handelReloadButton}
            />
            <div className="content-body">
                <div
                    className="content-body-scroll storage-info-area"
                    style={{ paddingBottom: '120px' }}
                >
                    <TabsSection storage info={storageInfo} />

                    <div className={!storageInfo ? 'placeholder-mode' : ''}>
                        <StorageInfoSection demoData={demoData} />
                    </div>
                </div>
            </div>
        </>
    );
}

function HostingInfoStoragePage() {
    const { contractId } = useParams();
    const { currentUser } = useAuth();
    useEffect(() => {
        PageProperties(`Storage \u2022 Hosting`);
    }, []);

    return (
        <HostingInfoStorageProvider currentUser={currentUser} contractId={contractId}>
            <div className="content-area hosting-info-page">
                <ContentArea />
            </div>
        </HostingInfoStorageProvider>
    );
}

export default HostingInfoStoragePage;
