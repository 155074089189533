import { ButtonOutlinePink, ButtonSolidPink } from '../../../../../../configs/Buttons';
import { useTemplate } from '../../../../../contexts/TemplateProvider';
import LayoutSelector from '../../../../../utils/LayoutSelector';

function ListActionButton() {
    const { setConfirm, setMessage } = useTemplate();

    const handleButtonClick = () => {
        setConfirm({
            text: 'Are you sure you want to delete this method?',
            clickAction: () => {
                setMessage('Deleting...');
            }
        });
    };
    return (
        <>
            <LayoutSelector
                sm
                md
                webComp={
                    <span className="common-table-btn">
                        <ButtonOutlinePink evOnClick={handleButtonClick} title="Delete" />
                    </span>
                }
                mobileComp={
                    <span className="button-action-sm">
                        <ButtonSolidPink title="Delete" evOnClick={handleButtonClick} />
                    </span>
                }
            />
        </>
    );
}

export default ListActionButton;
