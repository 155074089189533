import React from 'react';
import RegistrationModal from './RegistrationModal';
import RegistrationProvider from './RegistrationProvider';

function RegistrationController({
    show,
    modalProperty,
    currentUser,
    setCurrentUser,
    setExitModal,
    signOut,
    makeAction,
    recheckLogin,
    verifyProviderRecord,
    setSuccessLogin
}) {
    if (currentUser.loginStatus) {
        return null;
    }

    if (!show) {
        return null;
    }

    return (
        <RegistrationProvider
            setExitModal={setExitModal}
            firebase={currentUser.firebase}
            key={currentUser?.firebase?.userUid}
            recheckLogin={recheckLogin}
            verifyProviderRecord={verifyProviderRecord}
            makeAction={makeAction}
            setSuccessLogin={setSuccessLogin}
        >
            <RegistrationModal
                modalProperty={modalProperty}
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
                setExitModal={setExitModal}
                signOut={signOut}
                makeAction={makeAction}
            />
        </RegistrationProvider>
    );
}

export default RegistrationController;
