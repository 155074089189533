import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ButtonGr } from '../../../configs/Buttons';
import { icons } from '../../../configs/Icons';
import { useAuth } from '../../contexts/AuthProvider';
import PaymentProvider, { usePayment } from '../../contexts/PaymentProvider';
import { useTemplate } from '../../contexts/TemplateProvider';
import AxiosAuth from '../../utils/AxiosAuth';
import PageProperties from '../../utils/PageProperties';
import { GetDate } from '../../utils/Show';
import HeaderSection from '../headers/HeaderSection';
import FixedHeaderControl from '../websites/components/FixedHeaderControl';
import PaymentInvoiceProducts from './comps/invoice/PaymentInvoiceProducts';

const InvoiceInfo = ({ invoiceInfo }) => (
    // if (!invoiceInfo) {
    //     return null;
    // }

    <div className="invoice-info">
        <div className="line-align line-g1">
            <div className="cell font-14 fw-500">Invoice:</div>
            <div className="cell">
                <span className="font-14 fw-500"> #{invoiceInfo?.id || '------------'}</span>
            </div>
        </div>
        <div className="line line-g1 invoice-info-line">
            <div className="cell">Date of Issue:</div>
            <div className="cell">
                <span> {GetDate(invoiceInfo?.issueDate) || '-----------'}</span>
            </div>
        </div>
    </div>
);
const AddressFrom = ({ invoiceData }) => (
    <div className="address-view">
        <span className="font-18 clr">From</span>
        <div className="name">
            <span>BIKIRAN.COM</span>
        </div>
        <div className="address">
            <span>
                {' '}
                {invoiceData
                    ? 'House-02, Block-F, Shangbadik Abashik Elaka, Kalshi Road, Mirpur-11, Dhaka-1216,Bangladesh'
                    : '---------------------'}
            </span>
        </div>
        <div className="contact">
            Contact:{' '}
            <span>
                <b>880 1925 363333</b>
            </span>
        </div>
    </div>
);

const AddressTo = ({ addressBilling, invoiceInfo }) => {
    const { name, org, email, mobile, line1, line2, line3, country, state, city, zip } =
        addressBilling || {};

    // if (!addressBilling) {
    //     return null;
    // }

    return (
        <div className="address-view">
            <h4 className="line-align line-g1">
                <div className="cell">To</div>
            </h4>
            <div className="name">
                <span className="gap">
                    {name} {org ? `(${org})` : '----------------'}
                </span>
            </div>
            <div className="address">
                <span>
                    {[line1, line2, line3, city, state, zip, country]
                        .filter((x) => !!x)
                        .join(', ') || '------------------'}
                </span>
            </div>
            <div className="contact break-all">
                Contact:{' '}
                <span>
                    <b>{mobile || '---------'}</b>, <b>{email || '---------'}</b>
                </span>
            </div>
            <div className="line line-g1 contact">
                <div className="cell">
                    <b>Payment Due Date:</b>
                </div>
                <div className="cell">
                    <span>
                        <b>{GetDate(invoiceInfo?.dueToDate) || '-----------'}</b>
                    </span>
                </div>
            </div>
        </div>
    );
};

function InvoiceView() {
    const { invoiceData } = usePayment();

    const { setMessage } = useTemplate();
    const addressBilling = invoiceData?.addressBilling;

    const handelExecute = () => {
        setMessage('Loading...');
        AxiosAuth.setUrl(`https://demo.io.bikiran.com/invoice/create-contract/${invoiceData?.id}/`)
            .get()
            .then(({ data }) => {
                setMessage(data.message);
            });
    };

    return (
        <div className={`invoice-area print-area ${!invoiceData ? 'placeholder-mode' : ''}`}>
            <div className="invoice-header">
                <span className="print-none line justify-end mb-2">
                    <ButtonGr evOnClick={() => window.print()} title="Print" />
                </span>
                <div className="line-row-between mb-6">
                    <div className="print">
                        <img className="bik-logo " src={icons.iconBikLogoV2} alt="" />
                    </div>
                    <div>
                        <InvoiceInfo
                            invoiceInfo={invoiceData || {}}
                            items={invoiceData?.invoiceItems}
                        />
                    </div>
                </div>

                <div className="line line-g2 mb-6">
                    <div className="cell cell-6">
                        <AddressFrom invoiceData={invoiceData} />
                    </div>
                    <div className="cell cell-6">
                        <AddressTo
                            addressBilling={addressBilling}
                            invoiceInfo={invoiceData || {}}
                        />
                    </div>
                </div>
            </div>

            <div className="invoice-summery">
                <h4 className="order-summery-text">Order Summery</h4>
                <PaymentInvoiceProducts invoiceData={invoiceData || {}} />
            </div>

            {invoiceData?.itemBeExecute > 0 ? (
                <div>
                    <button type="button" onClick={handelExecute}>
                        Execute 1 Item
                    </button>
                </div>
            ) : null}
        </div>
    );
}

function InvoicePrintPage() {
    const { invoiceId } = useParams();
    const { currentUser } = useAuth();
    const location = useLocation();
    const hash = new URLSearchParams(location.search).get('hash');

    useEffect(() => {
        PageProperties('Invoice');
    }, []);

    return (
        <PaymentProvider invoiceId={invoiceId} currentUser={currentUser} hash={hash}>
            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>
            <div className="container text-center mb-6 mt-5 print-none">
                <span className="font-36 clr">Invoice</span>
            </div>
            <div className="container cart-payment-area">
                <InvoiceView />
            </div>
        </PaymentProvider>
    );
}

export default InvoicePrintPage;
