import { useEffect, useRef, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { icons } from '../../../../configs/Icons';
import { useLayout } from '../../../contexts/LayoutProvider';
import TabMenuPopup from '../_models/tab_menu_popup/TabMenuPopup';
import TabDns from '../dns/TabDns';
import TabFaq from '../faq/TabFaq';
import HostingInfoDotMenu from '../hostings_Info/hostings_info_dotmenu/HostingInfoDotMenu';
import TabsActivity from './tabs_activity/TabsActivity';
import TabBackup from './tabs_backup/TabBackup';
import TabBasicInfo from './tabs_basicinfo/TabsBasicInfo';
import TabsConversation from './tabs_conversation/TabsConversation';
import TabsCpLogin from './tabs_cplogin/TabsCpLogin';
import TabDatabase from './tabs_database/TabsDatabase';
import TabEmail from './tabs_email/TabsEmail';
import TabsStorage from './tabs_storage/TabsStorage';

const menuArr = [
    {
        title: 'Basic Info',
        url: '',
        cName: 'basic-info',
        img: icons.iconInfoInactive,
        imgActive: icons.iconInfoActive
    },
    {
        title: 'Login To Cpanel',
        url: '',
        cName: 'cpanel',
        img: icons.imgCpanelV2,
        imgActive: icons.imgCpanelV2
    },
    {
        title: 'Manage Email',
        url: 'emails',
        cName: 'email',
        img: icons.iconMailV1,
        imgActive: icons.iconMailActive
    },
    {
        title: 'Databases',
        url: 'databases',
        cName: 'database',
        img: icons.imgDatabase,
        imgActive: icons.iconDatabaseActive
    },
    {
        title: 'Storage',
        url: 'storage',
        cName: 'storage_backup',
        img: icons.iconStoragePink,
        imgActive: icons.iconStorageActive
    },
    {
        title: 'Manage DNS',
        url: 'dns',
        cName: 'dns',
        img: icons.iconDns,
        imgActive: icons.iconDnsActive
    },
    {
        title: 'Backup',
        url: 'backup',
        cName: 'backup',
        img: icons.iconBackup,
        imgActive: icons.iconBackupActive
    },
    {
        title: 'Conversation',
        url: 'conversation',
        cName: 'conversation-tab',
        img: icons.iconChat,
        imgActive: icons.iconChatActive
    },
    {
        title: 'Activity Log',
        url: 'activity',
        cName: 'activity',
        img: icons.iconActivity,
        imgActive: icons.iconActivityActive
    },

    {
        title: 'FAQ`s',
        url: 'faq',
        cName: 'faq',
        img: icons.iconFaq,
        imgActive: icons.iconFaqActive
    }
];

let lastScrollPosition = 0;

function TabsSection({
    info,
    basic,
    email,
    database,
    storage,
    activity,
    conversation,
    faq,
    dns,
    backup
}) {
    const [mode, setMode] = useState(false);
    const { contractId } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { windowSizeName } = useLayout();
    const isMobile = windowSizeName === 'xs' || windowSizeName === 'sm' || windowSizeName === 'md';

    const isStorageShow = pathname.includes('storage') || basic || email || database;
    const [, setScrollPosition] = useState(0);
    const containerRef = useRef(null);

    useEffect(() => {
        // set position to 0 if back
        const regex = /\/user\/services\/hosting\/\d+\/\w+\//;
        const isMatching = regex.test(pathname);

        if (!isMatching) {
            lastScrollPosition = 0;
        }

        const container = containerRef.current;

        if (container) {
            container.scrollLeft = lastScrollPosition;
            setScrollPosition(lastScrollPosition);

            const handleScroll = () => {
                lastScrollPosition = container.scrollLeft;
                setScrollPosition(lastScrollPosition);
            };

            container.addEventListener('scroll', handleScroll);
            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
        return undefined;
    }, [pathname, setScrollPosition]);

    return (
        <div className="hosting-tab mb-4 position-relative">
            <div ref={containerRef} className="line-row-between overflow-auto line-no-wrap line-g1">
                <TabBasicInfo
                    info={info}
                    basic={basic}
                    evOnClick={() => navigate(`/user/services/hosting/${contractId}/`)}
                />
                <TabsCpLogin info={info} contractId={contractId} />
                <TabEmail
                    info={info}
                    email={email}
                    evOnClick={() => navigate(`/user/services/hosting/${contractId}/emails/`)}
                />

                <TabDatabase
                    info={info}
                    database={database}
                    evOnClick={() => navigate(`/user/services/hosting/${contractId}/databases/`)}
                />
                <TabsStorage
                    info={info}
                    show={pathname.includes('storage') || basic || email || database || isMobile}
                    storage={storage}
                    evOnClick={() => navigate(`/user/services/hosting/${contractId}/storage/`)}
                />
                <TabsActivity
                    info={info}
                    show={pathname.includes('activity') || isMobile}
                    activity={activity}
                    evOnClick={() => navigate(`/user/services/hosting/${contractId}/activity/`)}
                />
                <TabsConversation
                    info={info}
                    show={pathname.includes('conversation') || isMobile}
                    conversation={conversation}
                    evOnClick={() => navigate(`/user/services/hosting/${contractId}/conversation/`)}
                />
                <TabFaq
                    info={info}
                    show={pathname.includes('faq') || isMobile}
                    faq={faq}
                    evOnClick={() => navigate(`/user/services/hosting/${contractId}/faq/`)}
                />
                <TabDns
                    info={info}
                    show={pathname.includes('dns') || isMobile}
                    dns={dns}
                    evOnClick={() => navigate(`/user/services/hosting/${contractId}/dns/`)}
                />
                <TabBackup
                    info={info}
                    show={pathname.includes('backup') || isMobile}
                    backup={backup}
                    evOnClick={() => navigate(`/user/services/hosting/${contractId}/backup/`)}
                />
                <HostingInfoDotMenu show={!isMobile} evOnClick={() => setMode(true)} mode={mode} />
            </div>
            <TabMenuPopup
                lastTabItem={isStorageShow}
                lastTabUrl="storage"
                show={mode}
                setMode={setMode}
                menuArr={menuArr}
                contractId={contractId}
            />
        </div>
    );
}

export default TabsSection;

// function TabsSection() {
//     const { contractId } = useParams();

//     return <TabRow menuArr={menuArr} type="hosting" id={contractId} />;
// }

// export default TabsSection;
