import { ButtonOutlinePink } from '../../../../configs/Buttons';
import Divider from '../../websites/models/SectionDivider';
import { useDomainInfo } from '../domains_info/DomainInfoBasicProvider';

function CommonRowView({ title, value }) {
    return (
        <div className="line mb-2">
            <div className="cell cell-5">
                <span className="subject ">{title}:</span>
            </div>
            <div className="cell cell-7">
                <span className="value">{value || '--'}</span>
            </div>
        </div>
    );
}

function DomainInfoContactsAdminSection() {
    const { cuContact, setModal } = useDomainInfo();
    const { name, email, telephone, org, address } = cuContact?.admin || {};
    const { line1, line2, line3, countryFull } = address || {};

    return (
        <div className="product-info fill mb-4 mt-md-4">
            <Divider
                title="Administrative"
                action={<ButtonOutlinePink evOnClick={() => setModal(1)} title="Edit" />}
            />
            <div className="">
                <div>
                    <CommonRowView title="Name" value={name || '--'} />
                    <CommonRowView title="Email" value={email || '--'} />
                    <CommonRowView title="Telephone" value={telephone || '--'} />
                    <CommonRowView title="Organization" value={org || '--'} />
                    <CommonRowView
                        title="Address"
                        value={`${line1 || line2 || line3}, ${countryFull}` || '--'}
                    />
                </div>
            </div>
        </div>
    );
}

export default DomainInfoContactsAdminSection;
