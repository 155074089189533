import { useState } from 'react';
import {
    arrowIcons,
    crossIcons,
    icons,
    notificationIcons,
    socialIcon
} from '../../../../configs/Icons';

const items = [
    {
        id: 1,
        title: 'Are you Interested in Mailbox/Email Services?',
        contents: [
            {
                id: 1,
                title: 'Web Mail',
                img: icons.iconWebmail,
                cName: 'web-mail'
            },
            {
                id: 2,
                title: 'G Suite',
                img: socialIcon.google,
                cName: 'g-suite'
            },
            {
                id: 3,
                title: 'MS Office',
                img: icons.iconOffice,
                cName: 'office'
            }
        ]
    },
    {
        id: 2,
        title: 'Are you Interested in Marketing Tools?',
        contents: [
            {
                id: 1,
                title: 'SMS Service',
                img: icons.iconSmsV1,
                cName: 'sms'
            },
            {
                id: 2,
                title: 'Email Services',
                img: icons.iconEmailV1,
                cName: 'email'
            },
            {
                id: 3,
                title: 'Push Notification',
                img: notificationIcons.iconNotificationV2,
                cName: 'notification'
            }
        ]
    },
    {
        id: 3,
        title: 'Are you Interested in Software Development?',
        contents: [
            {
                id: 1,
                title: 'E-Commerce',
                img: icons.iconSoftwareV1,
                cName: 'software'
            },
            {
                id: 2,
                title: 'Education',
                img: icons.iconBook,
                cName: 'book'
            },
            {
                id: 3,
                title: 'News Portal',
                img: icons.iconNews,
                cName: 'news'
            }
        ]
    },
    {
        id: 4,
        title: 'Are you Interested in IT Services?',
        contents: [
            {
                id: 1,
                title: 'Security',
                img: icons.iconSecurity,
                cName: 'security'
            },
            {
                id: 2,
                title: 'Consultation',
                img: icons.iconConsultation,
                cName: 'consultation'
            },
            {
                id: 3,
                title: 'Any Manage',
                img: icons.iconManageV1,
                cName: 'manage'
            }
        ]
    }
];

function ServiceHeader({ handleClick, data, index }) {
    return (
        <div
            role="button"
            tabIndex={-1}
            onClick={() => handleClick(data?.id)}
            className="service-menu line-row-between"
        >
            <div className="menu-title">{data?.title}</div>
            <div className="menu-icon line-row-center d-md-none">
                <img
                    className="d-block"
                    src={index !== data?.id ? arrowIcons.iconArrowDownV1 : crossIcons.iconCrossV1}
                    alt=""
                />
            </div>
        </div>
    );
}

function ServiceItem({ item }) {
    return (
        <div className="cell cell-fill">
            <div className={`service line-col-center ${item?.cName}`}>
                <div className="icon">
                    <img className="w-100 d-block" src={item?.img} alt="" />
                </div>
                <div>
                    <span>{item?.title}</span>
                </div>
            </div>
        </div>
    );
}

function OtherServiceSection() {
    const [index, setIndex] = useState(0);
    // console.log(index);

    const handleClick = (id) => {
        if (id !== index) {
            setIndex(id);
        } else {
            setIndex(0);
        }
    };
    return (
        <div className="container">
            <div className="other-service-container">
                {items?.map((data) => (
                    <div
                        key={data?.id}
                        className={`service-item ${index !== data?.id ? 'inactive' : 'active'}`}
                    >
                        <ServiceHeader handleClick={handleClick} data={data} index={index} />
                        <div className="service-body">
                            <div className="line-row-between line-g2 line-sm-g1 line-no-wrap overflow-auto">
                                {data?.contents?.map((item) => (
                                    <ServiceItem key={item?.id} item={item} />
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OtherServiceSection;
