import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonGr } from '../../../../configs/Buttons';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import PageProperties from '../../../utils/PageProperties';
import { mkToken } from '../../../utils/StringOperation';
import TableActionButton from '../_models/TableActionButton';
import DomainListHeader from './domains_list_header/DomainListHeader';
import DomainListTable from './domains_list_table/DomainListTable';

function ContentBodyArea({ domainData }) {
    return (
        <>
            <DomainListTable domainData={domainData} />

            <TableActionButton show={domainData}>
                <div className="line-row-center">
                    <Link to="/domain">
                        <ButtonGr title="Buy Domain" />
                    </Link>
                </div>
            </TableActionButton>
        </>
    );
}

function DomainListPage() {
    const [domainData, setDomainData] = useState(null);
    const [uniqueKey, setUniqueKey] = useState(mkToken(32));
    const { currentUser } = useAuth();

    const handelReload = () => {
        setUniqueKey(mkToken(32));
        setDomainData(null);
    };

    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .setUrl(`${ConfigApi.API_DOMAIN_LIST_DOMAINS}`)
            .get()
            .then(({ data }) => {
                setDomainData(null);
                if (data.error === 0) {
                    setDomainData(data?.data);
                } else {
                    setDomainData([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentUser, uniqueKey]);

    useEffect(() => {
        PageProperties(`Domain List`);
    }, []);

    return (
        <div className="cell cell-content h-100 w-100 domain-section">
            <div className="content-section">
                <div className="content-area">
                    <DomainListHeader
                        handelReload={handelReload}
                        listDetails={
                            <span className="font-14 primary-50">
                                Showing {domainData?.length || '--'} out of{' '}
                                {domainData?.length || '--'}{' '}
                            </span>
                        }
                    />

                    <div className="content-body-scroll" style={{ paddingBottom: '180px' }}>
                        <ContentBodyArea domainData={domainData} />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default DomainListPage;
