import ListActionButton from './ListActionButton';

function TableBody({ profileList, showOptions, setShowOptions }) {
    return (
        <tbody>
            {profileList?.map((data) => (
                <tr key={data?.id}>
                    <td>{data?.id}</td>
                    <td>{data?.domain}</td>
                    <td>{data?.email}</td>
                    <td>{data?.phone}</td>
                    <td>
                        <ListActionButton
                            showOptions={showOptions}
                            setShowOptions={setShowOptions}
                            data={data}
                        />
                    </td>
                </tr>
            ))}
        </tbody>
    );
}

function ProfileListWeb({ showOptions, setShowOptions, profileList }) {
    return (
        <table className="table-list" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th>SL</th>
                    <th>Domain Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th width="11%">Action</th>
                </tr>
            </thead>
            <TableBody
                setShowOptions={setShowOptions}
                showOptions={showOptions}
                profileList={profileList}
            />
        </table>
    );
}

export default ProfileListWeb;
