import React from 'react';
import { ButtonOutlinePink } from '../../../../../configs/Buttons';
import { UpperCase } from '../../../../utils/Show';
import { ListBody, ListItem, TableHeader, TableMobile } from '../../../../utils/TableMobileView';
import InfoStatusHandler from '../../../users/models/InfoStatusHandler';

export function InvoiceListMobile1({ invoiceList, navigate }) {
    return (
        <TableMobile>
            <TableHeader>
                <span>Status</span>
                <span>Action</span>
            </TableHeader>
            {invoiceList?.map((item) => (
                <ListItem key={item?.id} cName={item?.isDemo ? 'placeholder-mode' : ''}>
                    <div className="line-row-between">
                        <span className="title">{item?.status}</span>
                        <span>
                            <ButtonOutlinePink
                                evOnClick={() => {
                                    navigate(`/user/billing/invoice/${item?.id}/`);
                                }}
                                title="View"
                            />
                        </span>
                    </div>
                    <ListBody>
                        <div className="line-row-between w-100 line-no-wrap">
                            <span className="title">{item?.dateDue}</span>
                            <span className="title">{item?.items}</span>
                            <span className="title">{item?.priceOffer?.toFixed(2)}</span>
                            <span className="title">{item?.paidAmount?.toFixed(2)}</span>
                        </div>
                    </ListBody>
                </ListItem>
            ))}
        </TableMobile>
    );
}

function RowCommonView({ title, value }) {
    return (
        <div className="line-row-between mb-1">
            <div className="cell">
                <span className="value primary-70">{title || '--'}:</span>
            </div>
            <div className="cell">
                <span className="value primary">{value || '--'}</span>
            </div>
        </div>
    );
}

function InvoiceListMobile({ invoiceList, navigate }) {
    return invoiceList?.map((item) => (
        <div
            role="button"
            tabIndex={-1}
            onClick={() => navigate(`/user/billing/invoice/${item?.id}/`)}
            key={item?.id}
            className={` list-item-sm ${item?.isDemo ? 'placeholder-mode' : ''}`}
        >
            <div className="">
                <span className="title" style={{ color: InfoStatusHandler(item) }}>
                    {UpperCase(item?.status)}
                </span>
            </div>
            <RowCommonView title="Due Date" value={item?.dateDue} />
            <RowCommonView title="Items" value={item?.items} />
            <RowCommonView title="Paid" value={item?.paidAmount?.toFixed(2)} />
        </div>
    ));
}

export default InvoiceListMobile;
