import { useState } from 'react';
import { ButtonClose } from '../../../../../configs/Buttons';
import ContactInfoPhoneAddModalBody from './ContactInfoPhoneAddModalBody';

const initialFormData = {
    phone: '',
    sentId: '',
    otp: '',
    otpKey: ''
};

const ModalHeader = ({ handelCloseClick }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title">Add Phone</div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={handelCloseClick} />
            </div>
        </div>
    </div>
);

function ModalBody({ formData, setFormData, handelPhoneInput, setModalData }) {
    return (
        <div className="modal-box-body">
            <div>
                <ContactInfoPhoneAddModalBody
                    formData={formData}
                    setFormData={setFormData}
                    handelPhoneInput={handelPhoneInput}
                    setModalData={setModalData}
                />
            </div>
        </div>
    );
}

function ContactInfoPhoneAddModal({ show, setModalData }) {
    const [formData, setFormData] = useState(initialFormData);

    const handelCloseClick = () => {
        setModalData((cuData) => ({ ...cuData, type: null }));
    };

    const handelPhoneInput = (ev) => {
        setFormData((cuData) => ({ ...cuData, phone: ev.target.value || '' }));
    };

    if (!show) {
        return null;
    }

    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <ModalHeader handelCloseClick={handelCloseClick} />

                <ModalBody
                    formData={formData}
                    setFormData={setFormData}
                    handelPhoneInput={handelPhoneInput}
                    setModalData={setModalData}
                />
            </div>
        </div>
    );
}

export default ContactInfoPhoneAddModal;
