import { icons } from '../../../../../configs/Icons';
import ProgressBar from '../../../../utils/ProgressBar';
import Divider from '../../hostings/_models/SectionDivider';
import { useHostingInfoBasic } from '../HostingInfoBasicProvider';

function ItemCommonView({ title, details, img, progress, cName }) {
    const isZero = progress === 0;
    return (
        <div className="cell cell-3 cell-xl-4 cell-md-6 cell-sm-12">
            <div className={`item ${cName}`}>
                <div className={`line-align line-no-wrap ${isZero ? 'mb-1' : 'mb-2'}`}>
                    <span className="mr-2">
                        <img className="d-block" src={img} alt="" />
                    </span>
                    <span className="item-title">{title}</span>
                </div>
                <div className="line-align">
                    {isZero ? (
                        <span className="item-space">Unlimited</span>
                    ) : (
                        <ProgressBar progress={progress || 0} height={6} counter={false} />
                    )}
                </div>
                {!isZero ? (
                    <div>
                        <span className="item-space">{details || null}</span>
                    </div>
                ) : (
                    <span className="item-space">--</span>
                )}
            </div>
        </div>
    );
}

function BasicInfoResourceSection() {
    const { basicInfo } = useHostingInfoBasic();
    const { mysql, websites, cpu, ep, io, disk, bandwidth, ram } = basicInfo || {};

    // const percentage = (9 / 500) * 100;
    // console.log((500000 / 1024 / (500000 / 1024)) * 100);

    return (
        <div className="product-info mb-4 mb-sm-0">
            <Divider title="Resources" />
            <div className="resource-section">
                <div className="line-align line-g1">
                    <ItemCommonView
                        title="Disk"
                        img={icons.iconDiskFill}
                        progress={disk / 1024}
                        cName="disk"
                        details={`${disk / 1024} GB`}
                    />
                    <ItemCommonView
                        title="Bandwidth"
                        img={icons.iconDiskFillV2}
                        progress={(bandwidth / 1024 / ((disk / 1024) * 10)) * 100}
                        cName="bandwidth"
                        details={`${bandwidth / 1024} GB`}
                    />
                    <ItemCommonView
                        title="MySQL"
                        img={icons.iconMysql}
                        progress={mysql}
                        cName="mysql"
                    />
                    <ItemCommonView
                        title="Website"
                        img={icons.iconWebsite}
                        progress={websites}
                        cName="website"
                    />
                    <ItemCommonView
                        title="CPU Uses"
                        img={icons.iconCpu}
                        progress={cpu}
                        cName="cpu"
                        details={`${cpu} Core`}
                    />
                    <ItemCommonView
                        title="Memory"
                        img={icons.iconRamFill}
                        progress={ram / 1024}
                        cName="memory"
                        details={`${ram / 1024} GB`}
                    />
                    <ItemCommonView
                        title="EP"
                        img={icons.iconEp}
                        progress={ep}
                        cName="ep"
                        details={ep}
                    />
                    <ItemCommonView
                        title="I/O"
                        img={icons.iconIo}
                        progress={io}
                        cName="io"
                        details={`${io} Mb/s`}
                    />
                </div>
            </div>
            <span className="font-14">
                Need More Recourse?{' '}
                <span className="purple fw-500" role="button" tabIndex={-1}>
                    Upgrade Now
                </span>
            </span>
        </div>
    );
}

export default BasicInfoResourceSection;
