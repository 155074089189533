import React from 'react';
import { GetDate, getDayDiff } from '../../../../utils/Show';
import roleIconPicker from '../../_models/RoleIconPicker';
import TableActionButton from './TableActionButton';

/* 
 <tr>
                <th colSpan={1}>
                    <span className="text-dotted">#</span>
                </th>
                <th colSpan={2} width="21%">
                    <span className="text-dotted">Domain Name</span>
                </th>
                <th colSpan={2}>
                    <span className="text-dotted">Expired on</span>
                </th>
                <th colSpan={2}>
                    <div className="table-th-date break-all">
                        <span className="text-dotted">Registered on</span>
                    </div>
                </th>
                <th colSpan={2}>
                    <div className="table-th-date break-all">
                        <span className="text-dotted">Renew Price</span>
                    </div>
                </th>
                <th colSpan={1}>
                    <span className="text-dotted">Action</span>
                </th>
            </tr>
 */

function TableHead() {
    return (
        <thead>
            <tr>
                <th width="10%">ID</th>
                <th width="25%">Domain Name</th>

                <th width="10%">Renew</th>
                {/* <th width="27.5%">Domain</th> */}
                <th width="13%">Expired on</th>
                <th width="10%">Action</th>
            </tr>
        </thead>
    );
}

function TableBody({ arr, setModal, modal }) {
    if (arr?.length === 0) {
        return (
            <tbody>
                <tr>
                    <td colSpan={100}>
                        <div className="font-16 opacity-medium">No Domain found !</div>
                    </td>
                </tr>
            </tbody>
        );
    }

    return (
        <tbody>
            {arr?.map((item) => {
                const days = getDayDiff(item?.timeExpire);

                const daysRemain = item?.isExpired ? 'Expired' : `${days} Days Left`;
                return (
                    <tr
                        key={item.id}
                        className={`${item?.demo ? 'placeholder-mode' : ''} ${
                            item?.isExpired ? 'td-expired' : ''
                        }`}
                    >
                        <td className="opacity-medium">
                            <span className="">{item?.id}</span>
                        </td>
                        <td>
                            <div className="line-align line-no-wrap">
                                {roleIconPicker(item)}
                                <span className="fw-500 d-block gap word-break">
                                    {item?.domain}
                                </span>
                            </div>
                            <span className="value">{`Since ${GetDate(item?.timeIssue)}`}</span>
                        </td>

                        <td>
                            <span className="fw-500 d-block gap">
                                USD {(item?.price * 12).toFixed(2)}
                            </span>

                            {/* <span className="value">/{showUnitDuration(item?.duration)}</span> */}
                        </td>
                        <td>
                            <span className="fw-500 d-block gap"> {GetDate(item?.timeExpire)}</span>
                            <span className="value">{daysRemain}</span>
                        </td>
                        <td>
                            <TableActionButton item={item} setModal={setModal} modal={modal} />
                        </td>
                    </tr>
                );
            })}
        </tbody>
    );
}

function DomainListTableWebView({ arr, setModal, modal }) {
    return (
        <div className="domain-list-table line w-100 mb-3">
            <table className="table-list" cellPadding={0} cellSpacing={0}>
                <TableHead />
                <TableBody arr={arr} setModal={setModal} modal={modal} />
            </table>
        </div>
    );
}

export default DomainListTableWebView;
