import { useState } from 'react';
import LayoutSelector from '../../../../utils/LayoutSelector';
import MessageModal from '../../../system/modals/MessageModal';
import DomainListTableMobileView from './DomainListTableMobileView';
import DomainListTableWebView from './DomainListTableWebView';

const demoArr = [
    {
        id: '------',
        domain: '---------',
        priceOffer: 101,
        createdOn: 1682088291,
        expiredOn: 1682088291,
        demo: true
    }
];

function DomainListTable({ domainData }) {
    const [modal, setModal] = useState({ type: '', data: {} });

    const arr = domainData || demoArr;

    return (
        <>
            <LayoutSelector
                sm
                md
                webComp={<DomainListTableWebView arr={arr} modal={modal} setModal={setModal} />}
                mobileComp={<DomainListTableMobileView arr={arr} setModal={setModal} />}
            />
            <MessageModal show={modal?.type === 'message'} setModal={setModal} />
        </>
    );
}

export default DomainListTable;
