import React, { useEffect, useState } from 'react';

import { ButtonActionReload, ButtonOutlinePink } from '../../../../configs/Buttons';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import { DashboardArrowHeader } from '../../headers/DashboardHeaderSection';
import MessageModal from '../../system/modals/MessageModal';
import HeaderMenuPopup from '../_models/header_menu_popup/HeaderMenuPopup';

function HeaderTitleArea({ info, path, evReloadClick }) {
    const [showOption, setShowOption] = useState(false);
    const [domainData, setDomainData] = useState(null);
    const { currentUser } = useAuth();

    useEffect(() => {
        if (showOption) {
            AxiosAuth.currentUserAuth(currentUser)
                .setUrl(`${ConfigApi.API_DOMAIN_LIST_DOMAINS}`)
                .get()
                .then(({ data }) => {
                    if (data.error === 0) {
                        setDomainData(data?.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setDomainData(null);
        }
    }, [currentUser, showOption]);

    if (!info) {
        return (
            <div>
                <span className="title gap d-block overflow-dotted">-----------------</span>
                <span className="title gap d-block overflow-dotted">---------</span>
            </div>
        );
    }
    return (
        <HeaderMenuPopup
            info={info}
            title="domain"
            domain={null}
            data={domainData}
            evOnClick={() => setShowOption(!showOption)}
            evReloadClick={evReloadClick}
            showOption={showOption}
            setShowOption={setShowOption}
            path={path}
        />
    );
}

function HeaderContent({ evReloadClick }) {
    const [modal, setModal] = useState(false);

    return (
        <div className="line justify-end">
            <span className="ml-2 mr-2" style={{ width: '30px' }}>
                <ButtonActionReload evOnClick={evReloadClick} />
            </span>
            <span>
                <ButtonOutlinePink
                    // evOnClick={() => navigate(`/user/contracts/hosting/renew/${info?.id}/`)}
                    evOnClick={() => setModal(true)}
                    title="Renew"
                />
                <MessageModal show={modal} setModal={setModal} />
            </span>
        </div>
    );
}

function DomainInfoHeader({ info, evReloadClick, path }) {
    return (
        <DashboardArrowHeader
            title={info?.domain}
            titleArea={<HeaderTitleArea path={path} info={info} evReloadClick={evReloadClick} />}
            placeholder={!info}
            path="/user/services/domain/"
            content={<HeaderContent evReloadClick={evReloadClick} info={info} />}
            mobileContent={
                <div>
                    <HeaderContent evReloadClick={evReloadClick} info={info} />
                </div>
            }
        />
    );
}

export default DomainInfoHeader;
