import { useInit } from '../../../contexts/InitProvider';

export function showCurrency() {
    return 'USD';
}

export function ShowCurrencySign() {
    const { locale } = useInit();

    if (locale?.currency === 'USD') {
        return '$';
    }
    if (locale?.currency === 'BDT') {
        return '৳';
    }
    return '';
}

export function CurrencyFormatter(value) {
    return value?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function ShowFormattedPrice(value) {
    return `${ShowCurrencySign()}${CurrencyFormatter(value) || 0}`;
}

export function ShowPriceWithDuration(price) {
    const { isYearly } = useInit();

    if (isYearly) {
        return `${ShowCurrencySign()} ${CurrencyFormatter((price || 0) * 12)}/Year`;
    }
    return `${ShowCurrencySign()} ${CurrencyFormatter(price || 0)}/Month`;
}

export function showUnitDuration(item) {
    if (item >= 12) {
        return `${item / 12} Year`;
    }
    return `${item} Month`;
}

export function showPricePerDuration(productData) {
    if (productData.minDuration === 12) {
        return `${ShowCurrencySign()} ${productData.price * 12}/year`;
    }
    if (productData.minDuration === 1) {
        return `${ShowCurrencySign()} ${productData.price}/Month`;
    }

    return `${ShowCurrencySign()} ${productData.price * productData.minDuration} each ${
        productData.minDuration
    } Month`;
}

export function showOfferPrice(productData, duration) {
    const price =
        (productData.type === 'domain_registration'
            ? productData?.price?.registration
            : productData.price) * duration;
    const durationFloat = parseInt(duration, 10);

    // --Implement Duration Offer
    const offerPercent =
        productData.durations.find((item) => item.duration === durationFloat)?.offer_percent || 0;

    return (price * (100 - offerPercent)) / 100;
}

export function showPrice(productData, duration) {
    return (
        (productData.type === 'domain_registration' ? productData.price.renew : productData.price) *
        parseInt(duration, 10)
    );
}

export function showOfferPercent(productData, duration) {
    const offerPrice = showOfferPrice(productData, duration);
    const price = showPrice(productData, duration);

    return Math.round(((price - offerPrice) * 100) / price, 4);
}

// for domain
export function showPriceDomain(productData, duration) {
    return productData.price?.renew * parseInt(duration, 10);
}

// for domain
export function nextRenewalMonth(duration) {
    const date = new Date();
    const newDate = new Date(date.setMonth(date.getMonth() + parseInt(duration, 10)));

    return newDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
}

export const getDuration = (months) => {
    if (months < 12) {
        return `${months} Month`;
    }

    return `${months / 12} Year`;
};

export function DurationOptions({
    productInfo,
    className,
    handleYearChange,
    duration,
    style = {}
}) {
    return (
        <select className={className} onChange={handleYearChange} value={duration} style={style}>
            {productInfo?.durations?.map((item) => (
                <option value={item?.duration} key={item?.duration}>
                    {`${getDuration(item.duration)}`}
                    {item?.offer_percent ? ` (-${ShowCurrencySign()}${item.save.toFixed(2)})` : ''}
                </option>
            ))}
        </select>
    );
}

const Pricing = null;

export default Pricing;
