import { icons } from '../../../../../configs/Icons';
import { useHostingInfoBasic } from '../HostingInfoBasicProvider';

function BasicInfoDzoneUnSuspend({ disable }) {
    const { openModal } = useHostingInfoBasic();

    if (disable) {
        return null;
    }

    return (
        <>
            <div className="cell cell-4 cell-md-fill cursor-pointer mb-md-3">
                <div
                    className="line line-g1 line-no-wrap"
                    role="button"
                    tabIndex={-1}
                    onClick={() => openModal('cpUnsuspend')}
                >
                    <div className="cell ">
                        <div className="danger-zone-icon">
                            <span className="w-100 d-block">
                                <img className="d-block" src={icons.iconPlayRed} alt="" />
                            </span>
                        </div>
                    </div>
                    <div className="cell">
                        <div className="">
                            <span className="fs-14 fw-500 red">Unsuspend cPanel</span>
                            <span className="value">
                                To active your cPanel you must unsuspend your cPanel
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BasicInfoDzoneUnSuspend;
