import { ButtonOutlineGr, ButtonOutlineRed } from '../../../../../configs/Buttons';
import SecurityTwoFacAuthVerifyPassPopup from './SecurityTwoFacAuthPassPopup';

function ButtonView({ setMode, isActive }) {
    if (isActive) {
        return (
            <ButtonOutlineGr evOnClick={() => setMode({ type: 'off', data: {} })} title="Turn On" />
        );
    }
    return (
        <ButtonOutlineRed evOnClick={() => setMode({ type: 'on', data: {} })} title="Turn Off" />
    );
}

function CommonView({ value, cName }) {
    return <span className={`font-14 ${cName}`}>{value}</span>;
}

function SecurityTwoFacAuthMobile({ setMode, isActive, currentUser, mode }) {
    return (
        <div className="mb-6">
            <div className="info-list-header opacity-medium">
                <div className="line-row-between line-no-wrap w-100">
                    <span className="fs-16 fw-500 no-wrap">Two-Factor authentication</span>
                    <span>
                        <ButtonView setMode={setMode} isActive={isActive} />
                    </span>
                </div>
            </div>
            <div className="info-list-view">
                <div className="line">
                    <div className="line-row-between w-100 mb-2">
                        <span className="font-16 clr no-wrap">Use two-factor authentication</span>
                        <CommonView value="Activated" cName="green" />
                    </div>

                    <div className="">
                        <div className="content-text">
                            <CommonView
                                value="We`ll ask for a login code for an unrecognized device or browser."
                                cName="opacity-medium d-block break-word"
                            />
                        </div>
                    </div>
                    <SecurityTwoFacAuthVerifyPassPopup
                        show={isActive}
                        setMode={setMode}
                        mode={mode}
                        currentUser={currentUser}
                    />
                </div>
            </div>
        </div>
    );
}

export default SecurityTwoFacAuthMobile;
