import { useNavigate } from 'react-router-dom';
import SearchInputExitBtn from '../../websites/components/SearchInputExitBtn';
import SearchInputSection from '../../websites/components/SearchInputSection';

function SearchBarArea({ searchPage, setIsSearch, handleSearchIcon }) {
    const navigate = useNavigate();
    const exitBtn = (
        <SearchInputExitBtn
            clickHandler={() => {
                if (searchPage) {
                    navigate('/');
                } else {
                    setIsSearch(false);
                }
            }}
        />
    );
    return (
        <div className="header-search">
            <SearchInputSection handleSearchIcon={handleSearchIcon} exitBtn={exitBtn} />
        </div>
    );
}

export default SearchBarArea;
