import SmsListActionButton from './SmsListActionButton';

function ContentTop({ data }) {
    return (
        <div className="line-row-between mb-2">
            <div className="line">
                <img className="d-block" src={data?.image} alt="user dp" />
                <div className="ml-1">
                    <span className="title primary">{data?.name}</span>
                    <span className="sub-title purple d-block">{data?.email}</span>
                </div>
            </div>
            <div>
                <SmsListActionButton />
            </div>
        </div>
    );
}

function CommonRowArea({ title, value }) {
    return (
        <div className="line-row-between align-unset mb-1">
            <div className="cell">
                <span className="value primary-70">{title || '--'}:</span>
            </div>
            <div className="cell">
                <span className="value">{value || '--'}</span>
            </div>
        </div>
    );
}

function SmsListMobile({ arr }) {
    if (!arr?.length) {
        return (
            <div className="list-item-sm">
                <span className="title primary">No Hosting Found !</span>
            </div>
        );
    }
    return arr?.map((data) => (
        <div key={data?.uId} className={`list-item-sm ${data?.demo ? 'placeholder-mode' : ''}`}>
            <ContentTop data={data} />
            <CommonRowArea
                title="Types"
                value={
                    <div className="text-right">
                        <span className="value primary">{data.quantity}</span>
                        <span className="sub-title primary-70 d-block">{data.type}</span>
                    </div>
                }
            />

            <CommonRowArea
                title="Price"
                value={
                    <div>
                        <span className="value primary">${data?.price}</span>
                        <span
                            className="sub-title primary-70 d-block"
                            style={{ textDecoration: 'line-through' }}
                        >
                            {data?.prevPrice}
                        </span>{' '}
                    </div>
                }
            />
            <CommonRowArea title="Issued Date" value={data.issueDate} />
        </div>
    ));
}

export default SmsListMobile;
