function CommonAreaHead({ title, value }) {
    return (
        <div className="line-col align-item-center text-center mb-7 clr">
            <span className="font-18">{title}</span>
            <span className="font-36 text-center">{value}</span>
        </div>
    );
}

export default CommonAreaHead;
