import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { icons } from '../../../../configs/Icons';
import { useLayout } from '../../../contexts/LayoutProvider';
import TabMenuPopup from '../_models/tab_menu_popup/TabMenuPopup';
import TabDns from '../dns/TabDns';
import TabFaq from '../faq/TabFaq';
import TabConversation from '../tab-conversation/TabConversation';
import TabContacts from './TabContacts';
import TabsActivity from './TabsActivity';
import TabsBasicInfo from './TabsBasicInfo';
import TabsCns from './TabsCns';
import TabsDotMenu from './TabsDotMenu';
import TabsNs from './TabsNs';
import TabsTransferOut from './TabsTransferOut';

const menuArr = [
    {
        title: 'Manage DNS',
        url: 'dns',
        cName: 'dns',
        img: icons.iconDns,
        imgActive: icons.iconDnsActive
    },
    {
        title: 'Transfer Out',
        url: 'transfer-out',
        cName: 'transfer-out',
        img: icons.iconDtransfer,
        imgActive: icons.iconDtransferActive
    },
    {
        title: 'Conversation',
        url: 'conversation',
        cName: 'conversation-tab',
        img: icons.iconChat,
        imgActive: icons.iconChatActive
    },
    {
        title: 'Activity Log',
        url: 'activity',
        cName: 'activity',
        img: icons.iconActivity,
        imgActive: icons.iconActivityActive
    },

    {
        title: 'FAQ`s',
        url: 'faq',
        cName: 'faq',
        img: icons.iconFaq,
        imgActive: icons.iconFaqActive
    }
];

let lastScrollPosition = 0;

const TabsRowSection = ({
    isActive,
    basicInfo,
    activity,
    ns,
    cns,
    contacts,
    transfer,
    conversation,
    faq,
    dns
}) => {
    const [, setScrollPosition] = useState(0);
    const [mode, setMode] = useState();
    const { contractId } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const containerRef = useRef(null);

    const { windowSizeName } = useLayout();
    const isMobile = windowSizeName === 'xs' || windowSizeName === 'sm';

    const isDnsShow = pathname.includes('dns') || basicInfo || contacts || ns || cns;

    // set scroll position

    useEffect(() => {
        // set position to 0 if back
        // const regex = /\/user\/services\/domain\/\d+\/\w+\//;

        const regex = /\/user\/services\/domain\/\d+\/[\w-]+\//;
        const isMatching = regex.test(pathname);

        if (!isMatching) {
            lastScrollPosition = 0;
        }

        const container = containerRef.current;

        if (container) {
            container.scrollLeft = lastScrollPosition;
            setScrollPosition(lastScrollPosition);

            const handleScroll = () => {
                lastScrollPosition = container.scrollLeft;
                setScrollPosition(lastScrollPosition);
            };

            container.addEventListener('scroll', handleScroll);
            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
        return undefined;
    }, [pathname, setScrollPosition]);

    return (
        <div className="domain-tab mb-4 position-relative">
            <div
                ref={containerRef}
                className="line-align overflow-auto line-no-wrap line-g1"
                // style={{ marginBottom: '-10px' }}
            >
                <TabsBasicInfo
                    disabled={!isActive && basicInfo}
                    basicInfo={basicInfo}
                    evOnClick={() => navigate(`/user/services/domain/${contractId}/`)}
                />
                <TabContacts
                    disabled={!isActive && basicInfo}
                    contacts={contacts}
                    evOnClick={() => navigate(`/user/services/domain/${contractId}/contacts/`)}
                />
                <TabsNs
                    disabled={!isActive && basicInfo}
                    ns={ns}
                    evOnClick={() => navigate(`/user/services/domain/${contractId}/nameservers/`)}
                />

                <TabsCns
                    disabled={!isActive && basicInfo}
                    cns={cns}
                    evOnClick={() =>
                        navigate(`/user/services/domain/${contractId}/child-nameserver/`)
                    }
                />
                <TabDns
                    disabled={!isActive && basicInfo}
                    show={isDnsShow}
                    dns={dns}
                    evOnClick={() => navigate(`/user/services/domain/${contractId}/dns/`)}
                />
                <TabsActivity
                    show={pathname.includes('activity') || isMobile}
                    activity={activity}
                    evOnClick={() => navigate(`/user/services/domain/${contractId}/activity/`)}
                />
                <TabConversation
                    show={pathname.includes('conversation') || isMobile}
                    conversation={conversation}
                    evOnClick={() => navigate(`/user/services/domain/${contractId}/conversation/`)}
                />
                <TabFaq
                    show={pathname.includes('faq') || isMobile}
                    faq={faq}
                    evOnClick={() => navigate(`/user/services/domain/${contractId}/faq/`)}
                />
                <TabsTransferOut
                    show={pathname.includes('transfer-out') || isMobile}
                    transfer={transfer}
                    evOnClick={() => navigate(`/user/services/domain/${contractId}/transfer-out/`)}
                />

                <TabsDotMenu
                    disabled={!isActive && basicInfo}
                    show={!isMobile}
                    evOnClick={() => setMode(true)}
                    mode={mode}
                />
            </div>
            <TabMenuPopup
                show={mode}
                setMode={setMode}
                menuArr={menuArr}
                lastTabItem={isDnsShow}
                lastTabUrl="dns"
            />
        </div>
    );
};

export default TabsRowSection;
