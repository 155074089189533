import { useState } from 'react';
import { ButtonOutlinePink } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import { fileSizeDisplay } from '../../../../utils/Show';
import MessageModal from '../../../system/modals/MessageModal';
import Divider from '../../hostings/_models/SectionDivider';
import { useHostingStorageInfo } from '../HostingInfoStorageProvider';
import CollapseMenu from './CollapseMenu';
import InfoRowView from './InfoRowView';

// const menuItems = [
//     {
//         title: '.cpanel',
//         subMenu: [
//             {
//                 title: '.cphorde',
//                 subMenu: [{ title: 'Sub-Sub Item 1' }, { title: 'Sub-Sub Item 2' }]
//             },
//             { title: '.htpasswds' },
//             { title: '.spamassassin' },
//             {
//                 title: 'My SQL:',
//                 subMenu: [{ title: 'Sub-Sub Item 1' }, { title: 'Sub-Sub Item 2' }]
//             },
//             { title: 'My SQL:' }
//         ]
//     }
// ];

const subMenu = [
    {
        contained_usage: 1605632,
        contents: 2,
        name: '.cphorde',
        owner: 'yeasina2303',
        traversible: 1,
        type: 'dir',
        usage: 4096,
        user_contained_usage: 1605632
    }
];

const percentageUsed = (totalAmount, usedAmount) => ((usedAmount / totalAmount) * 100).toFixed(2);

function StorageInfoSection() {
    const [modal, setModal] = useState(false);
    const { storageInfo } = useHostingStorageInfo();
    const { contents, contained_usage: containedUsage } = storageInfo?.data?.homedir || {};
    const { quotalimit: totalAmount } = storageInfo?.data || {};

    return (
        <div className="product-info mb-4">
            <Divider title="Storage Info" />
            <InfoRowView
                // cName="cell-self-middle"
                title={
                    <div className="line-align">
                        <span className="d-block">
                            <img className="d-block mr-1" src={icons.iconHomePurple} alt="" />
                        </span>
                        <span>Home</span>
                    </div>
                }
                // space={`${(containedUsage / (1024 * 1024))?.toFixed(2)} Used`}
                space={fileSizeDisplay(containedUsage)}
                btnTitle="Backup"
                evOnClick={() => setModal(true)}
                color="purple"
                progress={percentageUsed(totalAmount, containedUsage)}
                cell="3"
                info={storageInfo}
            />

            {contents
                ?.filter((el) => el.name !== '.')
                ?.map((data) => (
                    <InfoRowView
                        key={Math.random()}
                        title={<CollapseMenu title={data?.name || '--'} subMenu={subMenu} />}
                        // space={`${(data?.contained_usage / (1024 * 1024)).toFixed(2)} MB Used`}
                        space={fileSizeDisplay(data?.contained_usage)}
                        btnTitle="Backup"
                        evOnClick={() => setModal(true)}
                        color="green"
                        progress={percentageUsed(totalAmount, data?.contained_usage)}
                        cell="3"
                        info={storageInfo}
                    />
                ))}

            {/* <OptionList /> */}

            <div className="mt-4">
                <ButtonOutlinePink evOnClick={() => setModal(true)} title="Add custom Backup" />
                <MessageModal show={modal} setModal={setModal} />
            </div>
        </div>
    );
}

export default StorageInfoSection;
