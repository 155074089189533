import React, { useRef, useState } from 'react';
import { ButtonActionDetails, ButtonActionDotMenu } from '../../../../../configs/Buttons';
import LayoutSelector from '../../../../utils/LayoutSelector';
import ProfileListOptions from './ProfileListOptions';

import ProfileDetailsModal from './ProfileDetailsModal';

function ButtonWeb({ setMode, setShowOptions, showOptions, data, myRef }) {
    return (
        <div className="position-relative">
            <div className="line line-no-wrap">
                <div className="common-table-btn">
                    <ButtonActionDetails evOnClick={() => setMode(data?.id)} />
                </div>
                <div className="ml-2 common-table-btn">
                    <ButtonActionDotMenu evOnClick={() => setShowOptions(data?.id)} />
                </div>
            </div>
            <div className="option-popup">
                <ProfileListOptions
                    myRef={myRef}
                    showOptions={showOptions === data?.id}
                    setShowOptions={setShowOptions}
                    data={data}
                />
            </div>
        </div>
    );
}

function ButtonMobile({ setMode, setShowOptions, showOptions, data, myRef }) {
    return (
        <div className="btn-action">
            <div className="position-relative">
                <div className="line line-no-wrap">
                    <span className="">
                        <ButtonActionDetails evOnClick={() => setMode(data?.id)} />
                    </span>
                    <span className="ml-2 ">
                        <ButtonActionDotMenu evOnClick={() => setShowOptions(data?.id)} />
                    </span>
                </div>
                <div className="option-popup">
                    <ProfileListOptions
                        myRef={myRef}
                        showOptions={showOptions === data?.id}
                        setShowOptions={setShowOptions}
                        data={data}
                    />
                </div>
            </div>
        </div>
    );
}

function ListActionButton({ setShowOptions, showOptions, data }) {
    const [mode, setMode] = useState();
    const myRef = useRef();

    return (
        <>
            <LayoutSelector
                sm
                md
                webComp={
                    <ButtonWeb
                        setMode={setMode}
                        setShowOptions={setShowOptions}
                        showOptions={showOptions}
                        data={data}
                        myRef={myRef}
                    />
                }
                mobileComp={
                    <ButtonMobile
                        setMode={setMode}
                        setShowOptions={setShowOptions}
                        showOptions={showOptions}
                        data={data}
                        myRef={myRef}
                    />
                }
            />
            <ProfileDetailsModal show={mode === data?.id} setMode={setMode} data={data} />
        </>
    );
}

export default ListActionButton;
