import LayoutSelector from '../../../../../utils/LayoutSelector';
import TicketListMobile from './TicketListMobile';
import TicketListWeb from './TicketListWeb';

const demoArr = [
    {
        id: 10000000,
        date: '-------',
        issuedBy: '-------',
        subject: '---------------------',
        subId: '-------',
        status: '------',
        isDone: false,
        demo: true
    }
];

const tickets = [
    {
        id: 10000000,
        date: '25-Sep-2022',
        issuedBy: 'cris_77',
        subject: 'This is Issue Subject',
        subId: 1000001,
        status: 'Solved',
        isDone: false
    },
    {
        id: 20000000,
        date: '25-Sep-2022',
        issuedBy: 'cris_78',
        subject: 'This is Issue Subject',
        subId: 1000001,
        status: 'Pending',
        isDone: true
    }
];

function TicketListSection() {
    const arr = tickets || demoArr;

    return (
        <LayoutSelector
            sm
            md
            webComp={<TicketListWeb arr={arr} />}
            mobileComp={<TicketListMobile arr={arr} />}
        />
    );
}

export default TicketListSection;
