import { ButtonClose } from '../../../../configs/Buttons';

const ModalHeader = ({ setOpenModal }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title" />
            <div className="modal-box-close">
                <ButtonClose evOnClick={() => setOpenModal(null)} />
            </div>
        </div>
    </div>
);

function ModalBody() {
    return (
        <div className="container h-100 text-center">
            <div style={{ padding: '16px 20px' }}>
                <h2>Coming Soon.....</h2>
            </div>
        </div>
    );
}

function CommonModal({ setOpenModal }) {
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <ModalHeader setOpenModal={setOpenModal} />

                <ModalBody />
            </div>
        </div>
    );
}

export default CommonModal;
