import { Link } from 'react-router-dom';

import { footerIcons, socialIcon } from '../../../../configs/Icons';
import { useInit } from '../../../contexts/InitProvider';
import CurrencyDropdownMenu from '../../../utils/CurrencyDropdownMenu';

function AddressInfo() {
    return (
        <>
            <p className="footer2-details mb-2">
                <span className="fw-500" style={{ color: '#130F40' }}>
                    Dhaka:
                </span>{' '}
                House-02, Block-F, Shangbadik Abashik Elaka, Kalshi Road, Mirpur-11, Dhaka-1216,
                Bangladesh
            </p>
            <p className="footer2-details mt-0">
                <span className="fw-500" style={{ color: '#130F40' }}>
                    Khulna:
                </span>{' '}
                Office No. 401, Sheikh Kamal IT Incubation & Training Center KUET, KUET Road, Khulna
                9203
            </p>
        </>
    );
}

function DropdownView() {
    // const [selectedOption, setSelectedOption] = useState(locale?.language || null);

    // const options = ['en_US', 'bn_BD'];
    return (
        <div className="cell footer-currency">
            {/* <DropdownMenu
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                options={options}
                width="100px"
            /> */}
            <CurrencyDropdownMenu />
        </div>
    );
}

function FooterTitleArea({ currencies, locale }) {
    return (
        <div className="cell-12 cell-xl-6 cell-sm-12">
            <div className="footer2-title">
                <div className="cell cell-profile brand">
                    <Link to="/">
                        <img
                            className="bik-banner-image"
                            src={footerIcons.iconBikiranBannerV2}
                            alt="Bikiran Banner"
                        />
                    </Link>
                </div>
                <DropdownView currencies={currencies} locale={locale} />
            </div>
            <AddressInfo />
        </div>
    );
}

function ContactInfo() {
    return (
        <div className="cell-12 cell-xl-6 cell-sm-12 footer-mb" style={{ paddingBottom: '10px' }}>
            <div className="footer2-title">Contact</div>
            <div className="footer2-contacts line">
                <div className="cell cell-6 cell-sm-6 cell-xxl-12 contacts">
                    <a href="mailto:info@bikiran.com">
                        <img src={footerIcons.iconContactMail} alt="Contact Mail" />
                        <span>info@bikiran.com</span>
                    </a>
                </div>

                <div className="cell cell-6 cell-sm-6 cell-xxl-12 contacts">
                    <a href="mailto:bishojit@gmail.com" target="_blank" rel="noreferrer">
                        <img src={footerIcons.iconContactMail} alt="Contact Mail" />
                        <span>bishojit@gmail.com</span>
                    </a>
                </div>

                <div className="cell cell-6 cell-sm-6 cell-xxl-12 contacts">
                    <a href="tel:+8801717416667">
                        <img src={footerIcons?.iconContactPhone} alt="Contact Phone" />
                        <span>+880 1717 416667</span>
                    </a>
                </div>

                <div className="cell cell-6 cell-sm-6 cell-xxl-12 contacts">
                    <a href="tel:+880 1925 363333" rel="noreferrer">
                        <img src={footerIcons?.iconContactPhone} alt="Contact Phone" />
                        <span>+880 1925 363333</span>
                    </a>
                </div>
            </div>
        </div>
    );
}

function BikiranInfoArea({ currencies, locale }) {
    return (
        <div className="cell cell-5 cell-xl-12 footer2-separator">
            <div className="line">
                <FooterTitleArea currencies={currencies} locale={locale} />
                <ContactInfo />
            </div>
        </div>
    );
}

function CompanyLinks() {
    return (
        <div className="cell cell-4 cell-sm-6 mb-3 footer-mb">
            <div className="footer2-title">Company</div>
            <div className="footer2-details">
                <ul>
                    <li>
                        <Link to="/about-us/">
                            <span>About Us</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/services">
                            <span>Services</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/">
                            <span>Our Team</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/about-us">
                            <span>Contact Us</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

function ImportantLinks() {
    return (
        <div className="cell cell-4 cell-sm-6 mb-3 footer-mb">
            <div className="footer2-title">Important Links</div>
            <div className="footer2-details">
                <ul>
                    <li>
                        <Link to="/legal/terms-of-service/">
                            <span>Terms & Condition</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/">
                            <span>Affiliate</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/">
                            <span>User Sign Up</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/services/hosting/web/">
                            <span>Our Pricing Plans</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

function SocialMediaLinks() {
    return (
        <div className="cell cell-4 cell-sm-12 mb-3">
            <div className="footer2-title">Find us on Social</div>
            <div className="footer2-details">
                <ul>
                    <li>
                        <a
                            href="https://www.facebook.com/bikiran12"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={socialIcon.facebookV2} alt="" />
                            <span>Facebook</span>
                        </a>
                    </li>
                    <li>
                        <a href="/">
                            <img src={socialIcon.skype} alt="" />
                            <span>Skype</span>
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.youtube.com/channel/UCLggQm2_2a7T42TBmzlb5wQ"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={socialIcon.youtube} alt="" />
                            <span>Youtube</span>
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.linkedin.com/company/bikiran-com/about/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={socialIcon.linkedin} alt="" />
                            <span>Linkedin</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://github.com/bikiran-team">
                            <img src={socialIcon.githubV2} alt="" />
                            <span>Github</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

function BikiranLinksArea() {
    return (
        <div className="cell cell-7 cell-xl-12">
            <div className="line">
                <CompanyLinks />
                <ImportantLinks />
                <SocialMediaLinks />
            </div>
        </div>
    );
}

function CountriesDropdown({ countries, locale }) {
    return (
        <div className="cell footer-currency" style={{ left: 15 }}>
            <select
                name="country"
                className="form-input"
                defaultValue={locale?.country}
                style={{ width: 135 }}
            >
                {countries.map((item) => (
                    <option
                        key={item?.code}
                        value={item?.code || ''}
                        defaultValue={locale?.country === item?.code ? item?.name : ''}
                    >
                        {item?.name}
                    </option>
                ))}
            </select>
        </div>
    );
}

function FooterSection2() {
    const { currencies, countries, locale } = useInit();

    return (
        <div className="footer2-section d-lg-none">
            <div>
                <div className="footer2-area footer-p">
                    <div className="container mb-7">
                        <div className="line">
                            <BikiranInfoArea currencies={currencies} locale={locale} />
                            <BikiranLinksArea />
                        </div>
                    </div>
                    <div className="container footer2-mini">
                        <hr
                            className="mb-0"
                            style={{ backgroundColor: 'rgba(19, 15, 64, 1)', opacity: '20%' }}
                        />
                        <div className="line">
                            <div className="cell cell-lg-12 footer2-mini-text">
                                © All Rights Reserved By &nbsp;
                                <Link to="/" className="purple">
                                    Bikiran.com
                                </Link>
                                <CountriesDropdown countries={countries} locale={locale} />
                            </div>
                            <div className="cell cell-fill cell-lg-12">
                                <div className="footer2-mini-image">
                                    <img
                                        src={footerIcons?.iconPaymentGateway}
                                        alt="Payment Gateway"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterSection2;
