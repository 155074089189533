import { createContext, useContext, useEffect, useState } from 'react';

import AxiosAuth from '../../../utils/AxiosAuth';
import InfoActivityErrorPopup from './InfoActivityErrorPopup';

const InfoActivityLogContext = createContext();

export function useActivityInfo() {
    return useContext(InfoActivityLogContext);
}

function InfoActivityLogProvider({ children, apiUrl, currentUser, contractId }) {
    // console.log(apiUrl);
    const [listKey, setListKey] = useState(null);
    const [activityInfo, setActivityInfo] = useState(null);
    const [error, setError] = useState(null);
    const reloadList = () => {
        setListKey(Math.random());
    };

    const handelReloadButton = () => {
        setListKey(Math.random());
        setActivityInfo(null);
    };

    // get info
    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .setApiUrl(apiUrl, `/${contractId}/activity-log/`)
            .get()
            .then(({ data }) => {
                // console.log(data);
                // setActivityInfo(null);
                if (data.error === 0) {
                    setActivityInfo(data);
                    // reloadList();
                } else {
                    setError(data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [apiUrl, contractId, currentUser, listKey]);

    const value = {
        reloadList,
        activityInfo,
        handelReloadButton
    };

    return (
        <InfoActivityLogContext.Provider value={value}>
            {children}
            {error?.error && error?.error !== 0 ? (
                <InfoActivityErrorPopup errorMessage={error?.message} />
            ) : null}
        </InfoActivityLogContext.Provider>
    );
}

export default InfoActivityLogProvider;
