import React from 'react';
import { icons } from '../../../../../configs/Icons';

function TwoFacAuthBackupCodes() {
    return (
        <div className="info-list-view">
            <div className="line line-g2 line-no-wrap">
                <div className="cell cell-fill">
                    <div className="line-align line-no-wrap">
                        <div className="list-logo d-md-none">
                            <img
                                className="d-block w-100"
                                src={icons.iconSecurityKey}
                                alt="icon device"
                            />
                        </div>
                        <div className="ml-3 ml-sm-0">
                            <span className="font-16 clr">Security key</span>
                            <span className="font-14 opacity-medium d-block break-word">
                                You`ll be asked to use your key for verification.
                            </span>
                        </div>
                    </div>
                </div>

                <div className="cell cell-2 cell-md-fill text-right cell-self-middle">
                    <div role="button" tabIndex={-1} onClick={null} className="setup-btn">
                        Setup
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TwoFacAuthBackupCodes;
