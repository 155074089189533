import { useState } from 'react';
import BasicInfoShellAccessPopup from './BasicInfoShellAccessPopup';

function ToggleView({ basicInfo, shellAccess, evOnClick, cpShellChange }) {
    const divStyle = {
        backgroundColor: shellAccess && cpShellChange ? '#38D600' : 'rgba(146, 156, 191, 0.5)',
        justifyContent: shellAccess && cpShellChange ? 'flex-end' : 'flex-start'
    };
    if (!basicInfo) {
        return <span>--------</span>;
    }
    return (
        <div
            style={divStyle}
            className="access-view p-none"
            role="button"
            tabIndex={-1}
            onClick={evOnClick}
        >
            <span />
        </div>
    );
}

function BasicInfoShellAccess({ basicInfo }) {
    const [modal, setModal] = useState(false);
    const { cpShellChange } = basicInfo?.permissions || {};
    return (
        <>
            <div className="line-align mb-2">
                <div className="cell cell-5">
                    <span className="subject">Shell Access::</span>
                </div>
                <div className="cell cell-7">
                    <ToggleView
                        evOnClick={() => setModal(!modal)}
                        shellAccess={basicInfo?.shellAccess}
                        cpShellChange={cpShellChange}
                        basicInfo={basicInfo}
                    />
                </div>
            </div>
            <BasicInfoShellAccessPopup show={modal} data={basicInfo} setModal={setModal} />
        </>
    );
}

export default BasicInfoShellAccess;
