import { useState } from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../../../../../configs/Icons';
import LayoutSelector from '../../../../utils/LayoutSelector';

const arr = [
    {
        id: 1,
        logo: icons.iconDbBg,
        title: 'How to Setup SSL Certificate for new domain?',
        tagItem: ['SSL', 'HOSTING', 'DOMAIN']
    },
    {
        id: 2,
        logo: icons.iconDbBg,
        title: 'How to Setup SSL Certificate for new domain?',
        tagItem: ['SSL', 'HOSTING', 'DOMAIN']
    },
    {
        id: 3,
        logo: icons.iconDbBg,
        title: 'How to Setup SSL Certificate for new domain?',
        tagItem: ['SSL', 'HOSTING', 'DOMAIN']
    }
];

function Interaction({ isActive, isLike, isDislike, handelClick }) {
    return (
        <div className="line-align line-no-wrap">
            <span role="button" tabIndex={-1} onClick={() => handelClick('like')}>
                <img
                    className="d-block"
                    src={isActive && isLike ? icons.iconLikePurple : icons.iconLikeWhite}
                    alt="like"
                />
            </span>
            <span className="count">00</span>
            <span role="button" tabIndex={-1} onClick={() => handelClick('dislike')}>
                <img
                    className="d-block"
                    src={isActive && isDislike ? icons.iconDislikePurple : icons.iconDislikeWhite}
                    alt="like"
                />
            </span>
            <span className="count">00</span>
        </div>
    );
}

function ListItemWeb({ data, isClick, setIsClick, isActive, isLike, isDislike, handelClick }) {
    return (
        <div className="line line-no-wrap line-g1">
            <div className="cell">
                <img className="d-block" src={data?.logo} alt="" />
            </div>
            <div className="cell cell-fill">
                <div className="mb-1">
                    <span className="title">{data?.title}</span>
                </div>
                <div className="line-align line-no-wrap mb-2">
                    {data?.tagItem?.map((item) => (
                        <Link to="/" key={item} className="tag-item">
                            {item}
                        </Link>
                    ))}
                </div>
                <div className="interaction-area">
                    <div className="line-align line-no-wrap">
                        <Interaction
                            setIsClick={setIsClick}
                            isClick={isClick}
                            isActive={isActive}
                            isLike={isLike}
                            isDislike={isDislike}
                            handelClick={handelClick}
                        />
                        <div className="ml-3">
                            <span className="int-count">00 People found this article Helpful</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
function ListItemMobile({ data, isClick, setIsClick, isActive, isLike, isDislike, handelClick }) {
    return (
        <div>
            <div className="line line-g1 mb-2">
                <div className="cell mb-2">
                    <img className="d-block" src={data?.logo} alt="" />
                </div>
                <div className="cell cell-fill">
                    <div className="mb-1">
                        <span className="title">{data?.title}</span>
                    </div>
                    <div className="line-align line-no-wrap mb-2">
                        {data?.tagItem?.map((item) => (
                            <Link to="/" key={item} className="tag-item">
                                {item}
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            <div className="interaction-area">
                <Interaction
                    setIsClick={setIsClick}
                    isClick={isClick}
                    isActive={isActive}
                    isLike={isLike}
                    isDislike={isDislike}
                    handelClick={handelClick}
                />
                <div className="mt-1">
                    <span className="int-count">00 People found this article Helpful</span>
                </div>
            </div>
        </div>
    );
}

function ListItem({ data }) {
    const [isClick, setIsClick] = useState({ id: null, action: '' });

    const handelClick = (action) => {
        setIsClick({ id: data?.id, action });
    };

    const isActive = isClick?.id === data?.id;
    const isLike = isClick?.action === 'like';
    const isDislike = isClick?.action === 'dislike';

    return (
        <LayoutSelector
            sm
            webComp={
                <ListItemWeb
                    data={data}
                    isClick={isClick}
                    handelClick={handelClick}
                    isActive={isActive}
                    isLike={isLike}
                    isDislike={isDislike}
                />
            }
            mobileComp={
                <ListItemMobile
                    data={data}
                    isClick={isClick}
                    handelClick={handelClick}
                    isActive={isActive}
                    isDislike={isDislike}
                    isLike={isLike}
                />
            }
        />
    );
}

function FaqList() {
    return (
        <div className="faq-list">
            {arr?.map((data) => (
                <ListItem key={data?.id} data={data} />
            ))}
        </div>
    );
}

export default FaqList;
