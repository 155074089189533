import { icons, socialIcon } from '../../../../configs/Icons';
import { InpTC } from '../../../utils/inputs/CheckInputs';
import OtpInputs from '../../../utils/inputs/OtpInputs';
import { InpPassword, InpPasswordSBS } from '../../../utils/inputs/PasswordInputs';
import { InpEmail, InpFullName } from '../../../utils/inputs/TextInputs';
import { BackToLogin } from '../components/AuthLinks';
import RegistrationButtons from './RegistrationButtons';
import { useRegistration } from './RegistrationProvider';
import SocialLoginButtons from './SocialLoginButtons';

const getProviderIcon = (providerId) => {
    if (providerId === 'google.com') {
        return socialIcon.google;
    }

    if (providerId === 'facebook.com') {
        return socialIcon.facebook;
    }

    if (providerId === 'github.com') {
        return socialIcon.github;
    }

    return icons.iconEmailV1;
};

const RegistrationForm = ({ handleCloseClick }) => {
    const {
        formData,
        setFormData,
        handelOtpSend,
        handelVerifyOtp,
        handleCreateAccount,
        handleCreateAccProvider,
        setInitialState
    } = useRegistration();

    const handelUpdateUsername = (ev) => {
        setFormData((cVal) => ({
            ...cVal,
            username: ev.target.value,
            sentId: ''
        }));
    };

    const handelUpdatePassword = (ev) => {
        const { name, value } = ev.target;
        setFormData((cVal) => ({
            ...cVal,
            [name]: value
        }));
    };

    const handelUpdateName = (ev) => {
        setFormData((cVal) => ({
            ...cVal,
            name: ev.target.value
        }));
    };

    const handelUpdateTc = (ev) => {
        setFormData((cVal) => ({
            ...cVal,
            acceptTc: ev.target.checked
        }));
    };

    return (
        <>
            <div className="line line-g2">
                <div className="cell cell-12 input-grp mb-2">
                    <InpEmail
                        value={formData.username}
                        setValue={handelUpdateUsername}
                        title="Email Address"
                        placeholder="ex: username@email.com"
                        vendorIcon={getProviderIcon(formData.providerId)}
                        isChecked={formData.otpIsVerified}
                        disabled={formData.sentId || formData.providerId}
                    />
                </div>

                {!formData.otpIsVerified ? (
                    <div className="cell cell-12 mb-2">
                        <OtpInputs
                            formData={formData}
                            setFormData={setFormData}
                            handelOtpSend={handelOtpSend}
                        />
                    </div>
                ) : null}

                {formData.otpIsVerified ? (
                    <div className="cell cell-12 input-grp login-pass mb-2">
                        <InpPasswordSBS>
                            <InpPassword
                                value={formData.password1}
                                setValue={handelUpdatePassword}
                                title="Password"
                                name="password1"
                            />
                            <InpPassword
                                value={formData.password2}
                                setValue={handelUpdatePassword}
                                title="Verify Password"
                                name="password2"
                            />
                        </InpPasswordSBS>
                    </div>
                ) : null}

                {formData.otpIsVerified && !formData.providerId ? (
                    <div className="cell cell-12 mb-2">
                        <InpFullName
                            value={formData.name}
                            setValue={handelUpdateName}
                            title="Full Name"
                            placeholder="ex: Nile Armstrong"
                            // vendorIcon={icons.iconEmailV1}
                        />
                    </div>
                ) : null}

                {formData.otpIsVerified ? (
                    <div className="cell forget-pass mb-3">
                        <InpTC
                            url="/legal/privacy-policy/"
                            value={formData.acceptTc}
                            setValue={handelUpdateTc}
                        />
                    </div>
                ) : null}
            </div>

            <div className="mb-3">
                <RegistrationButtons
                    formData={formData}
                    handelVerifyOtp={handelVerifyOtp}
                    handelOtpSend={handelOtpSend}
                    handleCreateAccount={handleCreateAccount}
                    handleCreateAccProvider={handleCreateAccProvider}
                    setInitialState={setInitialState}
                    handleCloseClick={handleCloseClick}
                />
            </div>
        </>
    );
};

const RegistrationModalArea0 = ({
    modalProperty,
    setCurrentUser,
    setMessage,
    setExitModal,
    signOut,
    makeAction
}) => {
    const { formData, signInWithGoogle, signInWithFacebook, signInWithGithub } = useRegistration();

    return (
        <div className="login-modal-area">
            <RegistrationForm
                modalProperty={modalProperty}
                setCurrentUser={setCurrentUser}
                setMessage={setMessage}
                setExitModal={setExitModal}
                signOut={signOut}
            />

            <div className="text-center text-g mb-4 line">
                <div className="cell-5 login-divider">
                    <hr />
                </div>
                <div className="cell-2">or</div>
                <div className="cell-5 login-divider">
                    <hr />
                </div>
            </div>

            <SocialLoginButtons
                formData={formData}
                signOut={signOut}
                signInWithGoogle={signInWithGoogle}
                signInWithFacebook={signInWithFacebook}
                signInWithGithub={signInWithGithub}
                setMessage={setMessage}
                setExitModal={setExitModal}
            />

            <BackToLogin makeAction={makeAction} />
        </div>
    );
};

export default RegistrationModalArea0;
