import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BtnProfileManage } from '../../../../../configs/Buttons';
import { useBasicInfo } from '../UserBasicInfoProvider';

function ProfileEmail({ currentUser }) {
    const navigate = useNavigate();
    const { mode } = useBasicInfo();

    const handelOnClick = () => {
        navigate('/user/contacts/');
    };

    return (
        <div className="line line-no-wrap default-view-area">
            <div className="cell cell-3 cell-md-4 od-opacity">
                <span className="profile-subject">Email:</span>
            </div>
            <div className="cell-fill od-opacity">
                <span className="value break-all">{currentUser.email || '--'}</span>
            </div>
            <div className="cell cell-sm-2 od-opacity">
                <BtnProfileManage evOnClick={handelOnClick} isDisabled={!!mode} />
            </div>
        </div>
    );
}

export default ProfileEmail;
