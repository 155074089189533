import { ButtonPurple } from '../../../../../../configs/Buttons';
import { useDnsInfo } from '../../DnsProvider';

function TabTxtContent() {
    const { formData, setFormData } = useDnsInfo();

    const handelCreate = () => null;

    const isDisabled = !formData?.value || !formData?.hostname || !formData?.ttl;

    return (
        <div>
            <div className="mb-1">
                <span className="content-text">
                    <b>TXT</b> records are used to associate a string of text with a hostname. These
                    are primarily used for verification.
                </span>
            </div>
            <div className="record-input-area">
                <div className="line align-end line-g1">
                    <div className="cell cell-fill">
                        <div className="label">
                            Hostname<span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, hostname: e.target.value }))
                            }
                            placeholder="Enter @ or hostname"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>

                    <div className="cell cell-fill">
                        <div className="label">
                            TTL (SECONDS)<span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, ttl: e.target.value }))
                            }
                            placeholder="36000"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>
                    <div className="cell cell-12">
                        <div className="label">
                            Value<span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, value: e.target.value }))
                            }
                            placeholder="Paste TXT string here"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>
                    <div className="cell cell-12">
                        <div className="label">Comment</div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, comment: e.target.value }))
                            }
                            placeholder="Here is the Comment"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>
                    <div className="cell cell-12">
                        <div className="line justify-end mt-2">
                            <ButtonPurple
                                disabled={isDisabled}
                                evOnClick={handelCreate}
                                title="Create Record"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TabTxtContent;
