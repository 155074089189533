import { useState } from 'react';
import { useAuth } from '../../../../contexts/AuthProvider';
import LayoutSelector from '../../../../utils/LayoutSelector';
import SecurityTwoFacAuthMobile from './SecurityTwoFacAuthMobile';
import TwoFacAuthWeb from './SecurityTwoFacAuthWeb';

function SecurityTwoFacAuthSection() {
    const [mode, setMode] = useState({ type: '', data: {} });
    // console.log(mode);
    const { currentUser } = useAuth();
    const isActive = mode?.type === 'on';
    return (
        <LayoutSelector
            sm
            md
            webComp={
                <TwoFacAuthWeb
                    setMode={setMode}
                    mode={mode}
                    isActive={isActive}
                    currentUser={currentUser}
                />
            }
            mobileComp={
                <SecurityTwoFacAuthMobile
                    setMode={setMode}
                    mode={mode}
                    isActive={isActive}
                    currentUser={currentUser}
                />
            }
        />
    );
}

export default SecurityTwoFacAuthSection;
