import dayjs from 'dayjs';
import { createContext, useContext, useEffect, useState } from 'react';
import { useTemplate } from '../../../contexts/TemplateProvider';

const UserProfileContext = createContext();

export function usePoints() {
    return useContext(UserProfileContext);
}

function UsersPointsProvider({ children }) {
    const [activity, setActivity] = useState(JSON.parse(localStorage.getItem('points')) || []);
    const [pointsValue, setPointsValue] = useState({
        cash: null,
        val: 0,
        tk: 0,
        date: null
    });

    const { setMessage } = useTemplate();

    const taka = pointsValue.val / 10;
    const getPointsValue = (ev) => {
        const { value } = ev.target;

        setPointsValue((cuState) => ({
            ...cuState,
            cash: true,
            val: value,
            tk: taka,
            date: dayjs(new Date()).format('DD/MM/YYYY')
        }));
        // setPointsValue(value);
    };

    useEffect(() => {
        localStorage.setItem('points', JSON.stringify(activity || []));
    }, [activity, pointsValue]);

    const handlePointsTransfer = () => {
        if (pointsValue) {
            setActivity((cuState) => [...cuState, pointsValue]);
            setPointsValue({
                cash: '',
                val: '',
                tk: '',
                date: ''
            });
            setMessage('Added');
        }
    };

    const value = {
        pointsValue,
        setPointsValue,
        getPointsValue,
        handlePointsTransfer,
        taka,
        activity
    };

    return <UserProfileContext.Provider value={value}>{children}</UserProfileContext.Provider>;
}

export default UsersPointsProvider;
