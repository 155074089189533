import { MD5 } from 'crypto-js';
import { useState } from 'react';
import { ButtonClose, ButtonGr } from '../../../../configs/Buttons';
import { addIcons } from '../../../../configs/Icons';
import { useTemplate } from '../../../contexts/TemplateProvider';
import CopyText from '../../websites/models/CopyText';
import { useDomainInfo } from '../domains_info/DomainInfoBasicProvider';

const ModalHeader = ({ handleCloseClick, domain }) => (
    <div className="modal-box-header">
        <div>
            <div>
                <div className="modal-box-title">
                    <span>Name Servers</span>
                    <div className="font-14 opacity-medium">
                        <CopyText
                            value={domain?.toUpperCase() || '--'}
                            copyValue={domain || '--'}
                        />
                    </div>
                </div>
            </div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={handleCloseClick} />
            </div>
        </div>
    </div>
);

const DnsInput = ({ index, row, setNameServer }) => {
    const handelChange = (ev) => {
        let val = ev.target.value.replace(/[^a-zA-Z0-9.-]/g, '');
        val = val.replace(/[.]+/gm, '.');
        val = val.replace(/[-]+/gm, '-');

        setNameServer((cuState) => {
            const state = [...cuState];
            state[index] = val;
            return state;
        });
    };

    const handelFilter = (ev) => {
        const val = ev.target?.value
            .split('.')
            .filter((v) => !!v)
            .map((item) =>
                item
                    .split('-')
                    .filter((v) => !!v)
                    .join('-')
            )
            .join('.');

        setNameServer((cuState) => {
            const state = [...cuState];
            state[index] = val;
            return state;
        });
    };

    return (
        <input
            style={{ width: '100%' }}
            required
            type="text"
            name="server"
            value={row}
            onChange={handelChange}
            onBlur={handelFilter}
            placeholder={index > 1 ? `Name Server ${index + 1}` : 'EX: ns11.hosting-server.tld'}
            id={MD5(index)}
            className="form-input"
        />
    );
};

const BtnPlus = ({ setNameServer }) => {
    const handelNewRow = () => {
        setNameServer((cuState) => {
            const state = [...cuState];
            state[state.length] = '';

            return state;
        });
    };

    return (
        <button type="button" onClick={handelNewRow}>
            <img src={addIcons.iconAddV2} alt="icon" />
        </button>
    );
};

const BtnMinus = ({ index, setNameServer }) => {
    const handelRemoveRow = () => {
        setNameServer((cuState) => {
            const state = [...cuState];
            state.splice(index, 1);

            return state;
        });
    };

    return (
        <button type="button" onClick={handelRemoveRow}>
            -
        </button>
    );
};

function WarningText() {
    return (
        <div>
            <div className="font-18 clr mb-1">Quotation:</div>
            <ul className="list-disc font-14 mt-0">
                <li className="mb-1">
                    Changing name servers can cause the domain to become disconnected from your
                    current server/hosting/DNS.
                </li>
                <li className="mb-1">
                    Name server changes may take 0 to 72 hours to take effect (cache updates) on
                    different networks.
                </li>
                <li>Unless you are a technical person, avoid changing name servers.</li>
                <li>Only update the name servers provided by your particular hosting provider</li>
            </ul>
        </div>
    );
}

function ModalBody({ handleCloseClick }) {
    const { nsAr, updateNameServers } = useDomainInfo();
    const [nameServer, setNameServer] = useState(nsAr);

    const { setMessage } = useTemplate();

    function compareArrays() {
        // Check if the arrays have the same length
        if (nsAr.length !== nameServer.length) {
            return false;
        }

        // Check if each element in the arrays is the same
        for (let i = 0; i < nsAr.length; i += 1) {
            if (nsAr[i] !== nameServer[i]) {
                return false;
            }
        }

        // If we haven't returned false yet, the arrays are the same
        return true;
    }

    const isDisable = compareArrays();

    const handelSave = (ns) => {
        setMessage('Adding...');
        updateNameServers(ns)
            .then(({ error, message }) => {
                if (error === 0) {
                    handleCloseClick();
                }
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    if (!nameServer) {
        return (
            <div className="modal-body-area">
                <span className="fs-16 fw-400 ">No Name Server Found!</span>
            </div>
        );
    }

    return (
        <div className="modal-body-area overflow-auto">
            <WarningText />
            <div className="line-align">
                {nameServer?.slice(0, 5)?.map((row, index) => (
                    <div key={MD5(`index_${index}`)} className=" w-100 mb-3">
                        <div className="line-align line-no-wrap">
                            <DnsInput index={index} row={row} setNameServer={setNameServer} />
                            {index === nameServer.length - 1 && nameServer?.length < 5 ? (
                                <span className="server-input">
                                    <BtnPlus setNameServer={setNameServer} />
                                </span>
                            ) : null}
                            {index > 1 ? (
                                <span className="server-input">
                                    <BtnMinus setNameServer={setNameServer} index={index} />
                                </span>
                            ) : null}
                        </div>
                    </div>
                ))}

                <div className="line justify-end w-100">
                    <ButtonGr
                        title="Save"
                        evOnClick={() => handelSave(nameServer?.filter((item) => !!item))}
                        disabled={isDisable}
                        modal
                    />
                </div>
            </div>
        </div>
    );
}

function DomainInfoNsModal({ show, setModal }) {
    const { domain } = useDomainInfo();

    const handleCloseClick = () => {
        setModal(null);
    };

    if (!show) {
        return null;
    }

    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <ModalHeader domain={domain} handleCloseClick={handleCloseClick} />

                <ModalBody handleCloseClick={handleCloseClick} />
            </div>
        </div>
    );
}

export default DomainInfoNsModal;
