import { useAuth } from '../../../../contexts/AuthProvider';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';
import { GetDate } from '../../../../utils/Show';
import { showUnitDuration } from '../../../websites/models/Pricing';

const DomainRowMobile = ({ item, createContract }) => (
    <div className="invoice-products-sm mb-3">
        <div className="line mb-4">
            <div className="cell cell-4">
                <span className="title">Item</span>
            </div>
            <div className="cell cell-8">
                <div className="value">
                    <b className="product-title">{item?.title}</b>
                    <p
                        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
                            __html: item?.description
                        }}
                    />
                    Validity:
                    <span>
                        {GetDate(item?.subscriptionStart)} to {GetDate(item?.subscriptionEnd)}
                    </span>
                    <br />
                    {item?.contract_status === 'not-created' ? (
                        <span
                            className="button-create-contract"
                            onClick={createContract}
                            role="button"
                            tabIndex={-1}
                        >
                            Click here to create contract
                        </span>
                    ) : null}
                </div>
            </div>
        </div>
        <div className="line mb-4">
            <div className="cell cell-4">
                {' '}
                <span className="title">Price (BDT)</span>
            </div>
            <div className="cell cell-8">
                <span className="value">{(item?.price * 12).toFixed(2)}/Year</span>
            </div>
        </div>
        <div className="line mb-4">
            <div className="cell cell-4">
                {' '}
                <span className="title">Unit</span>
            </div>
            <div className="cell cell-8">
                <span className="value">{item?.duration / 12} Year</span>
            </div>
        </div>
        <div className="line">
            <div className="cell cell-4">
                <span className="title">Total (BDT)</span>
            </div>
            <div className="cell cell-8">
                <span className="value">{(item?.price * item?.duration).toFixed(2)}</span>
            </div>
        </div>
    </div>
);

const HostingRowMobile = ({ item, createContract }) => (
    <div className="invoice-products-sm mb-3">
        <div className="line mb-4">
            <div className="cell cell-4">
                <span className="title">Item</span>
            </div>
            <div className="cell cell-8">
                <div className="value">
                    <b className="product-title">{item?.title}</b>
                    <p
                        // eslint-disable-next-line
                dangerouslySetInnerHTML={{
                            __html: item?.description
                        }}
                    />
                    Validity:
                    <span>
                        {GetDate(item?.subscriptionStart)} to {GetDate(item?.subscriptionEnd)}
                    </span>
                    <br />
                    {item?.contract_status === 'not-created' ? (
                        <span
                            className="button-create-contract"
                            onClick={createContract}
                            role="button"
                            tabIndex={-1}
                        >
                            Click here to create contract
                        </span>
                    ) : null}
                </div>
            </div>
        </div>
        <div className="line mb-4">
            <div className="cell cell-4">
                {' '}
                <span className="title">Price (BDT)</span>
            </div>
            <div className="cell cell-8">
                <span className="value">{(item?.price * 12).toFixed(2)}/Year</span>
            </div>
        </div>
        <div className="line mb-4">
            <div className="cell cell-4">
                {' '}
                <span className="title">Unit</span>
            </div>
            <div className="cell cell-8">
                <span className="value">{showUnitDuration(item?.duration)}</span>
            </div>
        </div>
        <div className="line">
            <div className="cell cell-4">
                <span className="title">Total (BDT)</span>
            </div>
            <div className="cell cell-8">
                <span className="value">{(item?.price * item?.duration).toFixed(2)}</span>
            </div>
        </div>
    </div>
);

function ItemRowMobileView({ item, total, discount, paid, payable, invoiceId, setInvoiceData }) {
    const { currentUser } = useAuth();
    const { setMessage } = useTemplate();

    const createContract = () => {
        setMessage('Loading...');
        AxiosAuth.currentUserAuth(currentUser)
            .setApiUrl('API_BILLING_INVOICE', `create-contract/${invoiceId}/`)
            .post()
            .then(({ data }) => {
                setMessage(data.message);

                if (data.error === 0) {
                    setInvoiceData(data.invoiceData);
                }
            });
    };

    if (item?.title?.split(' ')[item?.title?.split(' ')?.length - 1] === 'Domain') {
        return (
            <DomainRowMobile
                item={item}
                total={total}
                paid={paid}
                discount={discount}
                payable={payable}
                createContract={createContract}
            />
        );
    }
    return (
        <HostingRowMobile
            item={item}
            total={total}
            paid={paid}
            discount={discount}
            payable={payable}
            createContract={createContract}
        />
    );
}

function InvoiceInfoTableMobile({
    invoiceData,
    setInvoiceData,
    items,
    total,
    discount,
    paid,
    payable
}) {
    return items?.map((item) => (
        <ItemRowMobileView
            key={item?.id}
            item={item}
            total={total}
            paid={paid}
            discount={discount}
            payable={payable}
            invoiceId={invoiceData?.data?.id}
            setInvoiceData={setInvoiceData}
        />
    ));
}

export default InvoiceInfoTableMobile;
