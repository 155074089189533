/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { ButtonClose, ButtonGr } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import { useOtp } from '../../../verification_otp/VerificationOtpProvider';
import CopyText from '../../../websites/models/CopyText';
import { useHostingInfoBasic } from '../HostingInfoBasicProvider';

const ModalHeader = ({ handleCloseClick, domain }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title sub-title">
                <span>Change cPanel Domain</span>
                <span>
                    <small>
                        <CopyText copyValue={domain} value={domain?.toUpperCase()} />
                    </small>
                </span>
            </div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={handleCloseClick} />
            </div>
        </div>
    </div>
);

function InputField({ handelDomainInput, formData, domain }) {
    return (
        <div className="mb-2">
            <label htmlFor="password" className="label">
                Type New Domain:
            </label>
            <input
                onChange={handelDomainInput}
                defaultValue={domain || formData?.domain}
                placeholder="Type Your Domain..."
                type="text"
                className="form-input w-100"
                autoComplete="off"
            />
        </div>
    );
}

function Checkbox({ setFormData, formData, email }) {
    const handleAgreement1 = () => {
        setFormData((cuData) => ({
            ...cuData,
            checkBox1: !formData?.checkBox1
        }));
    };
    const handleAgreement2 = () => {
        setFormData((cuData) => ({
            ...cuData,
            checkBox2: !formData?.checkBox2
        }));
    };

    return (
        <div className="mb-3">
            <div
                className="line-inline line-no-wrap mb-1"
                role="button"
                tabIndex={-1}
                onClick={handleAgreement1}
            >
                <span>
                    <input
                        checked={formData?.checkBox1 || false}
                        readOnly
                        type="checkbox"
                        name="backup_data"
                        id=""
                    />
                </span>{' '}
                <span className="font-14 ml-1">Current domain will be disconnected !</span>
            </div>
            <div
                className="line-inline line-no-wrap"
                role="button"
                tabIndex={-1}
                onClick={handleAgreement2}
            >
                <span>
                    <input
                        checked={formData?.checkBox2 || false}
                        readOnly
                        type="checkbox"
                        name="cp_data"
                        id=""
                    />
                </span>{' '}
                <span className="font-14 ml-1">New domain nameservers should be update !</span>
            </div>
            <div className="line line-no-wrap" role="button" tabIndex={-1}>
                <span>
                    <input checked readOnly disabled type="checkbox" name="cp_data" id="" />
                </span>{' '}
                <span className="font-14 ml-1">
                    To update your domain, you must verify the email address associated with your
                    logged-in account. <span className="purple">({email || ''})</span>.
                </span>
            </div>
        </div>
    );
}

function ModalBody({ handleCloseClick, data, setMode }) {
    const { formData, setFormData, updatePrimaryDomain } = useHostingInfoBasic();

    const { currentUser } = useAuth();

    const { setMessage } = useTemplate();

    const { sendOtp, closeOtpWindow } = useOtp();

    const handelDomainInput = (ev) => {
        setFormData((cuData) => ({
            ...cuData,
            domain: ev.target.value
        }));
    };

    const handelUpdateBtn = () => {
        setMessage('Updating domain...');
        setMode(1);

        sendOtp(currentUser?.email, ({ sentId, otp }) => {
            // domain, vSentId, vOTP
            updatePrimaryDomain(formData.domain?.toLowerCase(), sentId, otp)
                .then(({ error, message }) => {
                    setMode(0);

                    if (error === 0) {
                        closeOtpWindow();
                        handleCloseClick();
                    }

                    setMessage(message);
                })
                .catch((err) => {
                    setMessage(err);
                });
        });
    };

    const isSubmitDisabled = !(
        formData?.domain &&
        formData?.domain?.toLowerCase() !== data.domain?.toLowerCase() &&
        formData?.checkBox1 &&
        formData?.checkBox2
    );

    return (
        <div className="modal-body-area">
            <div className="line-align" />
            <InputField
                handelDomainInput={handelDomainInput}
                formData={formData}
                domain={data?.domain || '--'}
            />
            <Checkbox
                setFormData={setFormData}
                formData={formData}
                email={currentUser?.email || '--'}
            />
            <div className="line justify-end">
                <ButtonGr
                    evOnClick={handelUpdateBtn}
                    disabled={isSubmitDisabled}
                    title="Update"
                    modal
                />
            </div>
        </div>
    );
}

function BasicInfoPrimaryDomainPopup({ show, setModal, data }) {
    const [mode, setMode] = useState(0);

    const { setFormData } = useHostingInfoBasic();

    const handleCloseClick = () => setFormData({}) + setModal(null);

    if (!show) {
        return null;
    }

    return (
        <div className="modal-box backdrop">
            <div
                className={`modal-box-content cp-wizard ${mode ? 'loading-block' : ''}`}
                style={{ width: '450px' }}
            >
                <ModalHeader handleCloseClick={handleCloseClick} domain={data?.domain} />
                <ModalBody handleCloseClick={handleCloseClick} data={data} setMode={setMode} />
            </div>
        </div>
    );
}

export default BasicInfoPrimaryDomainPopup;
