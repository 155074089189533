import React, { useState } from 'react';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AddEmailModal from '../contactinfo_email_table/ContactInfoEmailAddModal';
import ContactInfoEmailTableSection from '../contactinfo_email_table/ContactInfoEmailTableSection';
import { useContact } from '../ContactManageProvider';

function ButtonCommonView({ name, evOnClick }) {
    return (
        <div className="cell cell-sm-6">
            <div
                role="button"
                tabIndex={-1}
                onClick={evOnClick}
                className={`line-align add-btn-common mb-2 add-${name}`}
            >
                <span className="ml-4">Add</span>
            </div>
        </div>
    );
}

function AddButtonsArea({
    handelAddEmail,
    addGoogleContact,
    addFacebookContact,
    addGithubContact
}) {
    const { setMessage } = useTemplate();

    const handelGoogleClick = () => {
        setMessage('Loading google authorization...');
        addGoogleContact().then(({ message }) => {
            setMessage(message);
        });
    };

    const handelFacebookClick = () => {
        setMessage('Loading facebook authorization...');
        addFacebookContact().then(({ message }) => {
            setMessage(message);
        });
    };

    const handelGithubClick = () => {
        setMessage('Loading github authorization...');
        addGithubContact().then(({ message }) => {
            setMessage(message);
        });
    };

    return (
        <div className="line-align justify-end mt-4 mb-6">
            <ButtonCommonView evOnClick={handelAddEmail} name="mail" />
            <ButtonCommonView evOnClick={handelGoogleClick} name="google" />
            <ButtonCommonView evOnClick={handelFacebookClick} name="facebook" />
            <ButtonCommonView evOnClick={handelGithubClick} name="github" />
        </div>
    );
}

function ContactInfoEmailSection({ currentUser }) {
    const { contactList, addGoogleContact, addFacebookContact, addGithubContact } = useContact();
    const [modalData, setModalData] = useState({
        type: null
    });

    const handelAddEmail = () => {
        setModalData((cuData) => ({ ...cuData, type: 'add-email' }));
    };
    // if (contactList?.length === 0) {
    //     return <ConactPlaceholder setModalData={setModalData} modalData={modalData} text="Email" />;
    // }

    return (
        <div>
            <ContactInfoEmailTableSection contactList={contactList} currentUser={currentUser} />

            <AddButtonsArea
                handelAddEmail={handelAddEmail}
                addGoogleContact={addGoogleContact}
                addFacebookContact={addFacebookContact}
                addGithubContact={addGithubContact}
            />

            <AddEmailModal
                show={modalData.type === 'add-email'}
                modalData={modalData}
                setModalData={setModalData}
                currentUser={currentUser}
            />
        </div>
    );
}

export default ContactInfoEmailSection;
