import { useParams } from 'react-router-dom';
import { DashboardArrowHeader } from '../../headers/DashboardHeaderSection';
import CalculationSection from './calculation/CalculationSection';
import RenewItemSection from './renew_item/RenewItemSection';
import SmsItemSection from './sms/SmsItemSection';
import UsersRenewProvider from './UsersRenewProvider';

function ContentBodyArea() {
    // if (!data?.length) {
    //     return <div className="font-20 opacity-medium">No Data Found !</div>;
    // }

    return (
        <div className="line line-g2 mb-120">
            <div className="cell cell-9 cell-xl-12">
                <div className="item-area ">
                    <RenewItemSection />
                </div>
                <div className="item-area ">
                    <SmsItemSection />
                </div>
            </div>
            <div className="cell cell-3 cell-xl-12">
                <CalculationSection />
            </div>
        </div>
    );
}

function UsersRenewPage() {
    const { id } = useParams();

    return (
        <UsersRenewProvider contractId={id}>
            <div className="cell cell-content h-100">
                <div className="content-section">
                    <div className="content-area">
                        <DashboardArrowHeader title="Renew" path={-1} />
                        <div className="content-body-scroll">
                            <div className="renew-page-container">
                                <ContentBodyArea />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UsersRenewProvider>
    );
}

export default UsersRenewPage;
