function ToggleMenu({ disabled, width, height, bg, isActive, evOnClick }) {
    const bgColor = bg || 'rgb(56, 214, 0)';

    return (
        <div
            className={`toggle-menu ${disabled ? 'disabled-view' : ''}`}
            role="button"
            tabIndex={-1}
            onClick={evOnClick}
            style={{
                width: width || '36px',
                height: height || '16px',
                backgroundColor: isActive ? bgColor : 'rgba(146, 156, 191, 0.5)',
                justifyContent: isActive ? 'flex-end' : 'flex-start'
            }}
        >
            <span />
        </div>
    );
}

export default ToggleMenu;
