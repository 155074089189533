import { useEffect } from 'react';

import { ButtonGr } from '../../../../configs/Buttons';
import PageProperties from '../../../utils/PageProperties';
import { DashboardArrowHeader } from '../../headers/DashboardHeaderSection';
import NotificationList from './NotificationList';

const demoArr = [
    {
        id: 11111111,
        profileId: 1,
        profilePhoto: 'https://demo.io.bikiran.com/profiles/profile-photo/10000115/photo.png',
        title: '--------------------------------',
        profileName: '----------',
        timeLogged: 1679554764
    }
];

const arr = [
    // {
    //     id: 1,
    //     profileId: 1,
    //     profilePhoto: 'https://demo.io.bikiran.com/profiles/profile-photo/10000115/photo.png',
    //     title: 'has added new phone number (+880 1234 5678 90)',
    //     profileName: 'cristopher-1',
    //     timeLogged: 1679554764
    // },
    // {
    //     id: 2,
    //     profileId: 2,
    //     profilePhoto: 'https://demo.io.bikiran.com/profiles/profile-photo/10000115/photo.png',
    //     title: 'has added new phone number (+880 1234 5678 88)',
    //     profileName: 'cristopher-2',
    //     timeLogged: 1679554764
    // },
    // {
    //     id: 3,
    //     profileId: 3,
    //     profilePhoto: 'https://demo.io.bikiran.com/profiles/profile-photo/10000115/photo.png',
    //     title: 'has added new phone number (+880 1234 5678 12)',
    //     profileName: 'cristopher-3',
    //     timeLogged: 1679554764
    // },
    // {
    //     id: 4,
    //     profileId: 4,
    //     profilePhoto: 'https://demo.io.bikiran.com/profiles/profile-photo/10000115/photo.png',
    //     title: 'has added new phone number (+880 1234 5678 56)',
    //     profileName: 'cristopher-4',
    //     timeLogged: 1679554764
    // }
];

function UserNotificationPage() {
    useEffect(() => {
        PageProperties(`Notification \u2022 Profile`);
    }, []);

    return (
        <div className="cell cell-content h-100">
            <div className="content-section">
                <div className="content-area">
                    <DashboardArrowHeader
                        title="Notification"
                        path="/user/basic-info/"
                        content={
                            <div className="ml-6">
                                <ButtonGr title="Mark all as Read" />
                            </div>
                        }
                        mobileContent={
                            <span className="">
                                <ButtonGr title="Mark all as Read" />
                            </span>
                        }
                    />

                    <div className="content-body-scroll">
                        <div className="user-activity-log-area">
                            <div className={`product-info mb-6 w-100 `}>
                                <NotificationList arr={arr || demoArr} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default UserNotificationPage;
