import { domainIcons, icons, notificationIcons, userIcons } from '../../../../configs/Icons';

// sub-menu list array - for profile menu
export const profileMenuListDemo = [
    {
        id: 'profile_overview',
        title: 'Profile Overview',
        path: '/user/profile-overview/',
        icon: userIcons.iconUserV6
    },
    {
        id: 'basicInfo',
        title: 'Basic Info',
        path: '/user/basic-info/',
        icon: icons.iconOverview
    },
    {
        id: 'contact',
        title: 'Contact',
        path: '/user/contacts/',
        icon: icons.iconContact
    },
    {
        id: 'address',
        title: 'Address',
        path: '/user/addresses/',
        icon: icons.iconAddress
    },
    {
        id: 'domain_profile',
        title: 'Domain Profile',
        path: '/user/domain-profile/',
        icon: domainIcons.iconDomainV3
    },

    {
        id: 'social_urls',
        title: 'Social Media',
        path: '/user/social-url/',
        icon: icons.iconSocial
    },
    {
        id: 'security',
        title: 'Security and login',
        path: '/user/security-and-login/',
        icon: icons.iconSecurityV2
    },
    {
        id: 'activity_log',
        title: 'Activity Log',
        path: '/user/activity-log/',
        icon: icons.iconActivityLog
    },
    {
        id: 'notification',
        title: 'Notification',
        path: '/user/notifications/',
        icon: notificationIcons.iconNotificationFill
    },
    {
        id: 'org_role',
        title: 'Organizational Role',
        path: '/user/organizational-role/',
        icon: icons.iconDeveloperOption
    }
];

export const profileMenuListLive = [
    {
        id: 'profile_overview',
        title: 'Profile Overview',
        path: '/user/profile-overview/',
        icon: userIcons.iconUserV6
    },
    {
        id: 'basicInfo',
        title: 'Basic Info',
        path: '/user/basic-info/',
        icon: icons.iconOverview
    },
    {
        id: 'contact',
        title: 'Contact',
        path: '/user/contacts/',
        icon: icons.iconContact
    },
    {
        id: 'address',
        title: 'Address',
        path: '/user/addresses/',
        icon: icons.iconAddress
    },
    {
        id: 'domain_profile',
        title: 'Domain Profile',
        path: '/user/domain-profile/',
        icon: domainIcons.iconDomainV3
    },

    {
        id: 'social_urls',
        title: 'Social Media',
        path: '/user/social-url/',
        icon: icons.iconSocial
    },
    {
        id: 'security',
        title: 'Security and login',
        path: '/user/security-and-login/',
        icon: icons.iconSecurityV2
    },
    {
        id: 'activity_log',
        title: 'Activity Log',
        path: '/user/activity-log/',
        icon: icons.iconActivityLog
    },
    {
        id: 'notification',
        title: 'Notification',
        path: '/user/notifications/',
        icon: notificationIcons.iconNotificationFill
    }
];

// sidebar menu list array - web
export const listDemo = [
    {
        id: 'myProfile',
        title: 'My Profile',
        path: '/user/profile/',
        icon: userIcons.iconUserV1,
        subMenu: [
            {
                id: 'basicInfo',
                title: 'Basic Info',
                path: '/user/basic-info/',
                icon: icons.iconOverview
            },
            {
                id: 'contact',
                title: 'Contact',
                path: '/user/contacts/',
                icon: icons.iconContact
            },
            {
                id: 'address',
                title: 'Address',
                path: '/user/addresses/',
                icon: icons.iconAddress
            },
            {
                id: 'domain_profile',
                title: 'Domain Profile',
                path: '/user/domain-profile/',
                icon: domainIcons.iconDomainV3
            },

            {
                id: 'social_urls',
                title: 'Social Media',
                path: '/user/social-url/',
                icon: icons.iconSocial
            },
            {
                id: 'security',
                title: 'Security and login',
                path: '/user/security-and-login/',
                icon: icons.iconSecurityV2
            },
            {
                id: 'activity_log',
                title: 'Activity Log',
                path: '/user/activity-log/',
                icon: icons.iconActivityLog
            },
            {
                id: 'notification',
                title: 'Notification',
                path: '/user/notifications/',
                icon: notificationIcons.iconNotificationFill
            },
            {
                id: 'org_role',
                title: 'Organizational Role',
                path: '/user/organizational-role/',
                icon: icons.iconDeveloperOption
            }
        ]
    },
    {
        id: 'domain',
        title: 'Domain',
        path: '/user/services/domain/',
        icon: domainIcons.iconDomainV1
    },
    {
        id: 'hosting',
        title: 'Hosting',
        path: '/user/services/hosting/',
        icon: icons.iconHosting
    },
    {
        id: 'dns',
        title: 'DNS',
        path: '/user/services/dns/',
        icon: icons.iconDnsFill
    },
    {
        id: 'backup',
        title: 'Backup',
        path: '/user/services/backup/',
        icon: icons.iconBackupBg
    },
    {
        id: 'server_cloud',
        title: 'Cloud Server',
        path: '/user/services/server/cloud/',
        icon: icons.iconCloudBg
    },
    {
        id: 'server_vps',
        title: 'VPS',
        path: '/user/services/server/vps/',
        icon: icons.iconVpsBg
    },
    {
        id: 'server_dedicated',
        title: 'Dedicated Server',
        path: '/user/services/server/dedicated/',
        icon: icons.iconDedicatedBg
    },
    {
        id: 'sms-package',
        title: 'SMS Package',
        path: '/user/services/sms-package/',
        icon: icons.iconSmsV2
    },
    {
        id: 'mailbox',
        title: 'Email',
        path: '/user/services/email/',
        icon: icons.iconEmailV2
    },
    {
        id: 'software',
        title: 'Software',
        path: '/user/services/software/',
        icon: icons.iconSoftwareV2
    },
    {
        id: 'license',
        title: 'License',
        path: '/user/services/license/',
        icon: icons.iconSoftwareV2
    },
    {
        id: 'billing',
        title: 'Billing',
        path: '/user/billing/',
        icon: icons.iconServerV2,
        subMenu: [
            {
                id: 'billing_invoice',
                title: 'Invoice',
                path: '/user/billing/invoice/',
                icon: icons.iconCloudServer
            },
            {
                id: 'billing_transactions',
                title: 'Transactions',
                path: '/user/billing/transactions/',
                icon: icons.iconVps
            },
            {
                id: 'billing_payment_methods',
                title: 'Payment methods',
                path: '/user/billing/payment-methods/',
                icon: icons.iconDedicatedServer
            },
            {
                id: 'bikiran_balance',
                title: 'Bikiran Balance',
                path: '/user/balance/',
                icon: icons.iconWalletV2
            },
            {
                id: 'renew',
                title: 'Renew',
                path: '/user/services/renew/',
                icon: icons.iconDedicatedServer
            }
        ]
    },
    {
        id: 'sms',
        title: 'Sms',
        icon: icons.iconSmsV2,
        path: '',
        urlNewWindow: '/sms-dashboard/messaging/campaign/'
    },
    {
        id: 'smanager',
        title: 'Support Manager',
        icon: icons.iconSoftwareV2,
        path: '',
        urlNewWindow: '/smanager/ticket/'
    }
];

export const listLive = [
    {
        id: 'myProfile',
        title: 'My Profile',
        path: '/user/profile/',
        icon: userIcons.iconUserV1,
        subMenu: [
            {
                id: 'basicInfo',
                title: 'Basic Info',
                path: '/user/basic-info/',
                icon: icons.iconOverview
            },
            {
                id: 'contact',
                title: 'Contact',
                path: '/user/contacts/',
                icon: icons.iconContact
            },
            {
                id: 'address',
                title: 'Address',
                path: '/user/addresses/',
                icon: icons.iconAddress
            },
            {
                id: 'domain_profile',
                title: 'Domain Profile',
                path: '/user/domain-profile/',
                icon: domainIcons.iconDomainV3
            },

            {
                id: 'social_urls',
                title: 'Social Media',
                path: '/user/social-url/',
                icon: icons.iconSocial
            },
            {
                id: 'security',
                title: 'Security and login',
                path: '/user/security-and-login/',
                icon: icons.iconSecurityV2
            },
            {
                id: 'activity_log',
                title: 'Activity Log',
                path: '/user/activity-log/',
                icon: icons.iconActivityLog
            },
            {
                id: 'notification',
                title: 'Notification',
                path: '/user/notifications/',
                icon: notificationIcons.iconNotificationFill
            },
            {
                id: 'org_role',
                title: 'Organizational Role',
                path: '/user/organizational-role/',
                icon: icons.iconDeveloperOption
            }
        ]
    },
    {
        id: 'domain',
        title: 'Domain',
        path: '/user/services/domain/',
        icon: domainIcons.iconDomainV1
    },
    {
        id: 'hosting',
        title: 'Hosting',
        path: '/user/services/hosting/',
        icon: icons.iconHosting
    },
    {
        id: 'billing',
        title: 'Billing',
        path: '/user/billing/',
        icon: icons.iconServerV2,
        subMenu: [
            {
                id: 'billing_invoice',
                title: 'Invoice',
                path: '/user/billing/invoice/',
                icon: icons.iconCloudServer
            },
            {
                id: 'billing_transactions',
                title: 'Transactions',
                path: '/user/billing/transactions/',
                icon: icons.iconVps
            },

            {
                id: 'renew',
                title: 'Renew',
                path: '/user/services/renew/',
                icon: icons.iconDedicatedServer
            }
        ]
    }
];

// sidebar menu list array - mobile
export const listMobileDemo = [
    {
        id: 'domain',
        title: 'Domain',
        path: '/user/services/domain/',
        icon: domainIcons.iconDomainV1
    },
    {
        id: 'hosting',
        title: 'Hosting',
        path: '/user/services/hosting/',
        icon: icons.iconHosting
    },
    {
        id: 'dns',
        title: 'DNS',
        path: '/user/services/dns/',
        icon: icons.iconDnsFill
    },
    {
        id: 'backup',
        title: 'Backup',
        path: '/user/services/backup/',
        icon: icons.iconBackupBg
    },
    {
        id: 'server_cloud',
        title: 'Cloud Server',
        path: '/user/services/server/cloud/',
        icon: icons.iconCloudBg
    },
    {
        id: 'server_vps',
        title: 'VPS',
        path: '/user/services/server/vps/',
        icon: icons.iconVpsBg
    },
    {
        id: 'server_dedicated',
        title: 'Dedicated Server',
        path: '/user/services/server/dedicated/',
        icon: icons.iconDedicatedBg
    },
    {
        id: 'sms-package',
        title: 'SMS Package',
        path: '/user/services/sms-package/',
        icon: icons.iconSmsV2
    },
    {
        id: 'mailbox',
        title: 'Email',
        path: '/user/services/email/',
        icon: icons.iconEmailV2
    },
    {
        id: 'software',
        title: 'Software',
        path: '/user/services/software/',
        icon: icons.iconSoftwareV2
    },
    {
        id: 'license',
        title: 'License',
        path: '/user/services/license/',
        icon: icons.iconSoftwareV2
    },
    {
        id: 'billing',
        title: 'Billing',
        path: '/user/billing/',
        icon: icons.iconServerV2,
        subMenu: [
            {
                id: 'billing_invoice',
                title: 'Invoice',
                path: '/user/billing/invoice/',
                icon: icons.iconCloudServer
            },
            {
                id: 'billing_transactions',
                title: 'Transactions',
                path: '/user/billing/transactions/',
                icon: icons.iconVps
            },
            {
                id: 'billing_payment_methods',
                title: 'Payment methods',
                path: '/user/billing/payment-methods/',
                icon: icons.iconDedicatedServer
            }
        ]
    },
    {
        id: 'sms',
        title: 'Sms',
        icon: icons.iconSmsV2,
        path: '',
        urlNewWindow: '/sms-dashboard/campaign'
    },
    {
        id: 'smanager',
        title: 'Support Manager',
        icon: icons.iconSoftwareV2,
        path: '',
        urlNewWindow: '/smanager/ticket/'
    }
];

export const listMobileLive = [
    {
        id: 'domain',
        title: 'Domain',
        path: '/user/services/domain/',
        icon: domainIcons.iconDomainV1
    },
    {
        id: 'hosting',
        title: 'Hosting',
        path: '/user/services/hosting/',
        icon: icons.iconHosting
    },
    {
        id: 'billing',
        title: 'Billing',
        path: '/user/billing/',
        icon: icons.iconServerV2,
        subMenu: [
            {
                id: 'billing_invoice',
                title: 'Invoice',
                path: '/user/billing/invoice/',
                icon: icons.iconCloudServer
            },
            {
                id: 'billing_transactions',
                title: 'Transactions',
                path: '/user/billing/transactions/',
                icon: icons.iconVps
            }
        ]
    }
];

// sidebar menu list array - support-manager page
export const SupportManageMenulist = [
    {
        id: 'support-ticket',
        title: 'Support Ticket',
        path: '/smanager/ticket/',
        icon: icons.iconSupportV4
    },
    {
        id: 'contracts',
        title: 'Contracts',
        path: '/smanager/contracts/',
        icon: icons.iconContract,
        subMenu: [
            {
                id: 'hosting/',
                title: 'Hosting',
                path: '/smanager/contracts/hosting/',
                icon: icons.iconWebHV4
            },
            {
                id: 'domain',
                title: 'Domain',
                path: '/smanager/contracts/domain/',
                icon: domainIcons.iconDomainV3
            },
            {
                id: 'server',
                title: 'Server',
                path: '/smanager/contracts/server/',
                icon: icons.iconDedicatedServer
            },
            {
                id: 'software',
                title: 'Software',
                path: '/smanager/contracts/software/',
                icon: icons.iconSocial
            },
            {
                id: 'sms',
                title: 'Sms',
                path: '/smanager/contracts/sms/',
                icon: icons.iconMessagingInactive
            },
            {
                id: 'email',
                title: 'Email',
                path: '/smanager/contracts/email/',
                icon: icons.iconEmailV3
            }
        ]
    },
    {
        id: 'billings',
        title: 'Billings',
        path: '/smanager/billings/',
        icon: icons.iconInvoiceV1
    },
    {
        id: 'cart',
        title: 'Cart',
        path: '/smanager/cart/',
        icon: icons.iconCartV2
    },
    {
        id: 'users',
        title: 'Users',
        path: '/smanager/users/',
        icon: userIcons.iconUserV8
    },
    {
        id: 'activity-log',
        title: 'Activity Log',
        path: '/smanager/activity-log/',
        icon: icons.iconBillingV1
    }
];

function SidebarMenuListArr() {
    return null;
}

export default SidebarMenuListArr;
