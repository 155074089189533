import { Fragment, useEffect, useState } from 'react';
import { api } from '../../../../configs/ConfigApi';
import { arrowIcons, domainIcons, icons } from '../../../../configs/Icons';
import { useTemplate } from '../../../contexts/TemplateProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import ContributeLocationModal from '../modals/ContributeLocationModal';
import ContributePhoneModal from '../modals/ContributePhoneModal';

export function ExistingDomainBtn({ domain }) {
    const { suggestRef } = useTemplate();

    const handleScroll = () => {
        suggestRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="domain-found-btn">
            <button
                onClick={handleScroll}
                style={{ cursor: 'pointer' }}
                type="button"
                className="btn btn-similar domain-found-btn-similar"
            >
                <span>Similar Domain</span>
            </button>
            <a
                target="_blank"
                href={`https://www.whois.com/whois/${domain}`}
                className="btn btn-whois domain-found-btn-whois"
                role="button"
                rel="noreferrer"
            >
                <span>WhoIs Info</span>
            </a>
            <a
                target="_blank"
                href={`https://www.whatsmydns.net/#A/${domain}`}
                className="btn btn-dns domain-found-btn-dns"
                role="button"
                rel="noreferrer"
            >
                <span>DNS Zone</span>
            </a>
            <a
                target="_blank"
                href={`https://dnschecker.org/domain-health-checker.php?query=${domain}`}
                className="btn btn-health domain-found-btn-dns-health"
                role="button"
                rel="noreferrer"
            >
                <span>DNS Health</span>
            </a>
            <a
                target="_blank"
                href={`https://dnschecker.org/mx-lookup.php?query=${domain}&dns=google`}
                className="btn btn-mx domain-found-btn-mx"
                role="button"
                rel="noreferrer"
            >
                <span>MX Lookup</span>
            </a>
            <a target="_blank" href="/" className="btn domain-found-btn-manage" role="button">
                <span>Manage Domain</span>
            </a>
        </div>
    );
}

function SearchDomainWebRunning({ webInfo, domain }) {
    const [screenshotUrl, setScreenshotUrl] = useState();
    const [modalType, setModalType] = useState(null);

    const handleTitleClick = () => {
        window.open(webInfo?.url, '_blank');
    };

    useEffect(() => {
        AxiosAuth.setApiUrl('SEARCH', '/get-website-screenshot/')
            .get({ params: { domain } })
            .then(({ data }) => {
                if (data.error === 0) {
                    setScreenshotUrl(data.screenshotUrl);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [domain]);

    // console.log(webInfo);

    return (
        <div className={`container mb-4 ${!webInfo ? 'placeholder-mode' : ''}`}>
            <div className="domain-web-running-area mb-4">
                <div className="area-header">
                    <div
                        onClick={handleTitleClick}
                        role="button"
                        tabIndex={-1}
                        className="line-align line-no-wrap overflow-hidden"
                    >
                        <div className="cell">
                            <div className="domain-web-logo-area line-row-center">
                                <span className="d-block w-100">
                                    <img
                                        className="w-100 d-block"
                                        src={webInfo?.favicon || domainIcons.iconDomainDemo}
                                        alt="logo icon"
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="cell-fill overflow-hidden">
                            <div className="header-text ml-4">
                                <h3 className="line-align mt-0 mb-0 domain-title gap">
                                    <span>
                                        <>{domain?.toUpperCase() || '-----------------'} &bull; </>
                                        {webInfo?.title || '[Title not found]'}
                                    </span>
                                </h3>
                                <p className="font-14 mt-0 mb-0">
                                    <span>This domain has already Registered</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <span className="domain-title ml-4 mt-1 d-none d-sm-block">{webInfo?.title}</span>{' '}
                <div className="area-body">
                    <div className="line line-g2">
                        <div className="cell cell-3 cell-lg-fill cell-sm-12">
                            <div className="domain-info-area">
                                <a
                                    href={`https://ipinfo.io/${webInfo?.ip}`}
                                    target="_blank"
                                    className="line-align line-no-wrap domain-ip font-14 mt-0 mb-2"
                                    rel="noreferrer"
                                >
                                    <span className="line">
                                        <img src={icons.iconIp} alt="" />
                                    </span>{' '}
                                    <div className="line ml-2 ip">
                                        <span>{webInfo?.ip}</span>
                                    </div>
                                    <div className="line ml-1 new-tab-arrow">
                                        <img
                                            className="w-100"
                                            src={arrowIcons.iconArrowNewTabV2}
                                            alt=""
                                        />
                                    </div>
                                </a>
                                <div className="line line-no-wrap font-14 mt-0 mb-2">
                                    <span className="">
                                        <img src={icons.iconNsV1} alt="" />
                                    </span>{' '}
                                    <div className="ml-2">
                                        {webInfo?.nameservers.map((item) => (
                                            <Fragment key={item}>
                                                <span>{item}</span> <br />
                                            </Fragment>
                                        ))}
                                    </div>
                                </div>
                                <div
                                    onClick={() => setModalType('phone')}
                                    role="button"
                                    tabIndex={-1}
                                    className="line-align line-no-wrap font-14 mt-0 mb-2"
                                >
                                    <span className="line">
                                        <img src={icons.iconDomainNum} alt="" />
                                    </span>{' '}
                                    <span className="blue-solid ml-2">Contribute Phone Number</span>
                                </div>
                                <ContributePhoneModal
                                    setModalType={setModalType}
                                    show={modalType === 'phone'}
                                />
                                <div
                                    onClick={() => setModalType('location')}
                                    role="button"
                                    tabIndex={-1}
                                    className="line-align line-no-wrap font-14 mt-0 mb-0"
                                >
                                    <span className="line">
                                        <img src={icons.iconDomainLocation} alt="" />
                                    </span>{' '}
                                    <span className="line ml-2">
                                        <span className="blue-solid">Contribute Location</span>{' '}
                                    </span>
                                </div>
                                <ContributeLocationModal
                                    setModalType={setModalType}
                                    show={modalType === 'location'}
                                />
                            </div>
                        </div>
                        <div className="cell cell-fill d-md-none">
                            <div className="domain-question-area">
                                <div className="line-align">
                                    <div className="cell-8">
                                        <div className="title">
                                            <span>Are you Owner of this Website?</span>
                                        </div>
                                    </div>
                                    <div className="cell">
                                        <span>
                                            <button type="button" className="noBtn">
                                                No
                                            </button>
                                        </span>
                                        <span className="ml-2">
                                            <button type="button" className="yesBtn ">
                                                Yes
                                            </button>
                                        </span>
                                    </div>
                                </div>
                                <div className="line-align">
                                    <div className="cell-8">
                                        <div className="title">
                                            <span>Transfer the Domain to Bikiran.com</span>
                                        </div>
                                    </div>
                                    <div className="cell">
                                        <span>
                                            <button type="button" className="noBtn">
                                                No
                                            </button>
                                        </span>
                                        <span className="ml-2">
                                            <button type="button" className="yesBtn">
                                                Yes
                                            </button>
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <div className="title">
                                        <span>Is your website slow?</span>
                                    </div>
                                </div>
                                <div>
                                    <p className="mt-0 mb-0 hosting-text">
                                        <span>We provide faster hosting service.</span>{' '}
                                        <span role="button" tabIndex={-1} className="purple">
                                            <span className="opacity-100">Transfer Now</span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="cell cell-sm-12">
                            <div className="web-screenshot-area mt-sm-4 line">
                                <span className="">
                                    {screenshotUrl ? (
                                        <img
                                            className="w-100 d-block"
                                            alt="webImage"
                                            src={`${api}${screenshotUrl}`}
                                        />
                                    ) : null}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ExistingDomainBtn domain={domain} />
        </div>
    );
}

export default SearchDomainWebRunning;
