import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    domainIcons,
    hostingIcons,
    icons,
    solvedQIcons,
    userIcons
} from '../../../../../configs/Icons';

const questions = [
    {
        id: 1,
        title: 'How to create an account on Bikiran.com',
        img: solvedQIcons.iconCreateAccount,
        time: '3 min Read',
        type: 'account'
    },
    {
        id: 2,
        title: 'How to purchse domain from Bikiran.com',
        img: solvedQIcons.iconPurchaseDomain,
        time: '2 min Read',
        type: 'domain'
    },
    {
        id: 3,
        title: 'How to purchese hosting from Bikiran.com',
        img: solvedQIcons.iconPurchaseHosting,
        time: '3 min Read',
        type: 'hosting'
    },
    {
        id: 4,
        title: 'How to activate Bikiran SMS service by my own?',
        img: solvedQIcons.iconActiveSms,
        time: '4 min Read',
        type: 'sms-email'
    },
    {
        id: 5,
        title: 'How to send multiple email from my dashboard?',
        img: solvedQIcons.iconSendEmail,
        time: '4 min Read',
        type: 'sms-email'
    },
    {
        id: 6,
        title: 'How to manage my software From my PC?',
        img: solvedQIcons.iconManageSoftware,
        time: '2 min Read',
        type: 'software'
    }
];

const menuItem = [
    {
        id: 1,
        title: 'Hosting',
        img: hostingIcons.iconHostingV2,
        questions: [
            {
                id: 1,
                question: 'How To Buy Hosting Package?',
                answer: 'There is lot of option to buy our hosting package, You can buy it directly from our website. Also you can contact me before you buy.'
            },
            {
                id: 2,
                question: 'Is BIKIRAN keep my website data somehow?',
                answer: '---'
            },
            {
                id: 3,
                question: 'What if I change my mind and want refund?',
                answer: `---`
            },
            {
                id: 4,
                question: 'Is BIKIRAN save my card?',
                answer: `---`
            },
            {
                id: 5,
                question: 'How To Buy Hosting Package?',
                answer: `---`
            }
        ]
    },
    {
        id: 2,
        title: 'Domain',
        img: domainIcons.iconDomainV2,
        questions: [
            {
                id: 1,
                question: 'How To Buy Domain Package?',
                answer: 'There is lot of option to buy our Domain package, You can buy it directly from our website. Also you can contact me before you buy.'
            },
            {
                id: 2,
                question: 'Is BIKIRAN keep my website data somehow?',
                answer: '---'
            },
            {
                id: 3,
                question: 'What if I change my mind and want refund?',
                answer: `---`
            },
            {
                id: 4,
                question: 'Is BIKIRAN save my card?',
                answer: `---`
            },
            {
                id: 5,
                question: 'How To Buy Hosting Package?',
                answer: `---`
            }
        ]
    },
    {
        id: 3,
        title: 'Payment & Refund',
        img: icons.iconPay,
        questions: [
            {
                id: 1,
                question: 'Payment & Refund?',
                answer: 'Coming Soon....'
            },
            {
                id: 2,
                question: 'Is BIKIRAN keep my website data somehow?',
                answer: '---'
            },
            {
                id: 3,
                question: 'What if I change my mind and want refund?',
                answer: `---`
            },
            {
                id: 4,
                question: 'Is BIKIRAN save my card?',
                answer: `---`
            },
            {
                id: 5,
                question: 'How To Buy Hosting Package?',
                answer: `---`
            }
        ]
    },
    {
        id: 4,
        title: 'Account',
        img: userIcons.iconUserV2,
        questions: [
            {
                id: 1,
                question: 'Account',
                answer: 'Comming Soon.......'
            },
            {
                id: 2,
                question: 'Is BIKIRAN keep my website data somehow?',
                answer: '---'
            },
            {
                id: 3,
                question: 'What if I change my mind and want refund?',
                answer: `---`
            },
            {
                id: 4,
                question: 'Is BIKIRAN save my card?',
                answer: `---`
            },
            {
                id: 5,
                question: 'How To Buy Hosting Package?',
                answer: `---`
            }
        ]
    },
    {
        id: 5,
        title: 'Software Service',
        img: icons.iconSoftware,
        questions: [
            {
                id: 1,
                question: 'Software Service',
                answer: 'Comming Soon.......'
            },
            {
                id: 2,
                question: 'Is BIKIRAN keep my website data somehow?',
                answer: '---'
            },
            {
                id: 3,
                question: 'What if I change my mind and want refund?',
                answer: `---`
            },
            {
                id: 4,
                question: 'Is BIKIRAN save my card?',
                answer: `---`
            },
            {
                id: 5,
                question: 'How To Buy Hosting Package?',
                answer: `---`
            }
        ]
    },
    {
        id: 6,
        title: 'SMS & Email',
        img: icons.iconSms,
        questions: [
            {
                id: 1,
                question: 'SMS & Email',
                answer: 'Comming Soon.......'
            },
            {
                id: 2,
                question: 'Is BIKIRAN keep my website data somehow?',
                answer: '---'
            },
            {
                id: 3,
                question: 'What if I change my mind and want refund?',
                answer: `---`
            },
            {
                id: 4,
                question: 'Is BIKIRAN save my card?',
                answer: `---`
            },
            {
                id: 5,
                question: 'How To Buy Hosting Package?',
                answer: `---`
            }
        ]
    }
];

function QuestionSidebarMenu() {
    const [menu, setMenu] = useState(null);
    return (
        <div className="question-menu w-100 d-sm-none">
            <div className="line-col align-center">
                <div className="line-arow-between w-100 title">Bikiran Knowledge</div>
                {menuItem.map((item) => (
                    <div key={item.id} className="line-arow-between w-100 default">
                        <div
                            role="button"
                            tabIndex={-1}
                            onClick={() => setMenu(menu === null ? item.title : null)}
                        >
                            <span className="mr-3">
                                <img src={item.img} alt="icon" />
                            </span>
                            <span>{item.title}</span>
                        </div>
                        <div className={`submenu-list ${item.title === menu ? 'show' : 'hide'}`}>
                            {item?.questions?.map((i) => (
                                <ul key={i.id}>
                                    <li className="open">{i.question}</li>
                                </ul>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

function AnswerArea() {
    const { id } = useParams();
    return (
        <div className="answer-area">
            {questions
                ?.filter((el) => parseInt(el?.id, 10) === parseInt(id, 10))
                ?.map((item) => (
                    <h3 key={item?.id} className="section-title fw-500 mb-1 mt-0">
                        {item?.title}
                    </h3>
                ))}
            <div className="line-align w-100">
                <div className="cell-4 line-arow-between author-text">
                    <div className="line fw-400 ">
                        <span>Author: BIKIRAN.COM</span>
                    </div>
                    <div>174 Views</div>
                </div>
                <div className="cell-8" />
            </div>
            <p className="value fw-400">
                The European languages are members of the same family. Their separate existence is a
                myth. For science, music, sport, etc, Europe uses the same vocabulary. The languages
                only differ in their grammar,{' '}
            </p>
            <h5 className="title mt-1 mb-1 fw-400">#Step 1: How to setup</h5>
            <p className="value fw-400">
                The European languages are members of the same family. Their separate existence is a
                myth. For science, music, sport, etc, Europe uses the same vocabulary. The languages
                only differ in their grammar, their pronunciation and their most common words,
                Everyone realizes why a new common language would be desirable
            </p>
            <img className="w-100" src={icons.iconDmAns} alt="domain-icon" />
            <h5 className="title mt-1 mb-1 fw-400">#Step 2: How to Active Domain</h5>
            <p className="value fw-400">
                The languages only differ in their grammar, their pronunciation and their most
                common words, Everyone realizes why a new common language would be desirable
            </p>
            <p className="value fw-400">
                The European languages are members of the same family. Their separate existence is a
                myth. For science, music, sport, etc, Europe uses the same vocabulary. The languages
                only differ in their grammar, their pronunciation and their most common words,
                Everyone realizes why a new common language would be desirable
            </p>
            <hr style={{ opacity: '60%' }} />
            <p>
                <span className="value">Still no luck?</span>{' '}
                <Link to="/" className="title" style={{ fontSize: '16px' }}>
                    We can help!
                </Link>
            </p>
        </div>
    );
}

function QuestionDetailsSection() {
    return (
        <div className="line line-g3 w-sm-100 question-details-container mt-6">
            <div
                className="cell cell-3 cell-lg-4 cell-md-4 cell-sm-12 w-100"
                style={{ position: 'relative' }}
            >
                <QuestionSidebarMenu />
            </div>
            <div className="cell cell-9 cell-lg-8 cell-md-8 cell-sm-12">
                <AnswerArea />
            </div>
        </div>
    );
}

export default QuestionDetailsSection;
