import { ButtonOutlinePurple } from '../../../../../configs/Buttons';
import { basic } from '../../../../../configs/Images';

function SmsMobileAppSection() {
    return (
        <div className="container sms-page-area mb-6">
            <div className="line-align line-sm-reverse line-no-wrap line-sm-wrap service-area">
                <div className="cell-fill">
                    <div className="line-row-center text-sm-center">
                        <div>
                            <span className="title d-block mb-1">Mobile APP</span>
                            <span className="value clr">
                                Messaging is in Your Pocket, Any <br /> time Any where!
                            </span>
                            <div className="line-align line-no-wrap line-sm-justify-center mt-5">
                                <div>
                                    <ButtonOutlinePurple title="Install Now" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cell">
                    <img className="w-100" src={basic.mobileAppIcon} alt="" />
                </div>
            </div>
        </div>
    );
}

export default SmsMobileAppSection;
