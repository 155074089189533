import { ButtonPurple } from '../../../../../../configs/Buttons';
import { useDnsInfo } from '../../DnsProvider';

function TabSrvContent() {
    const { formData, setFormData } = useDnsInfo();

    const handelCreate = () => null;

    const isDisabled =
        !formData?.hostname ||
        !formData?.directTo ||
        !formData?.port ||
        !formData?.priority ||
        !formData?.weight ||
        !formData?.ttl;
    return (
        <div>
            <div className="mb-1">
                <span className="content-text">
                    <b>SRV</b> records specify the location (hostname and port number) of servers
                    for specific services. You can use service records to direct certain types of
                    traffic to particular servers.
                </span>
            </div>
            <div className="record-input-area">
                <div className="line align-end line-g1">
                    <div className="cell cell-3 cell-xl-4 cell-lg-fill">
                        <div className="label">
                            Hostname<span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, hostname: e.target.value }))
                            }
                            placeholder="e.g. _service._protocol"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>
                    <div className="cell cell-3 cell-xl-4 cell-lg-fill">
                        <div className="label">
                            Will Direct to<span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, directTo: e.target.value }))
                            }
                            placeholder="Enter @ or hostname (e.g. www or domain.com.)"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>
                    <div className="cell cell-3 cell-xl-4 cell-lg-fill">
                        <div className="label">
                            Port<span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, port: e.target.value }))
                            }
                            placeholder="e.g. 5060"
                            type="text"
                            className=" form-input"
                        />
                    </div>
                    <div className="cell cell-3 cell-xl-4 cell-lg-fill">
                        <div className="label">
                            Priority<span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, priority: e.target.value }))
                            }
                            placeholder="e.g. 10"
                            type="text"
                            className=" form-input"
                        />
                    </div>
                    <div className="cell cell-3 cell-xl-4 cell-lg-fill">
                        <div className="label">
                            Weight<span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, weight: e.target.value }))
                            }
                            placeholder="e.g. 100"
                            type="text"
                            className=" form-input"
                        />
                    </div>
                    <div className="cell cell-3 cell-xl-4 cell-lg-fill">
                        <div className="label">
                            TTL (SECONDS)<span className="red">*</span>
                        </div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, ttl: e.target.value }))
                            }
                            placeholder="36000"
                            type="text"
                            className=" form-input"
                        />
                    </div>
                    <div className="cell cell-12">
                        <div className="label">Comment</div>
                        <input
                            onChange={(e) =>
                                setFormData((cuData) => ({ ...cuData, comment: e.target.value }))
                            }
                            placeholder="Here is the Comment"
                            type="text"
                            className="w-100 form-input"
                        />
                    </div>
                    <div className="cell cell-12">
                        <div className="line justify-end mt-2">
                            <ButtonPurple
                                disabled={isDisabled}
                                evOnClick={handelCreate}
                                title="Create Record"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TabSrvContent;
