import dayjs from 'dayjs';
import { useState } from 'react';
import { BtnProfileEdit, ButtonCancel } from '../../../../../configs/Buttons';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import { DateInput } from '../../../../utils/inputs/DateInputs';
import { GetDate } from '../../../../utils/Show';
import { useBasicInfo } from '../UserBasicInfoProvider';

const compId = 'editDob';

const EditView = ({
    currentUser,
    formData,
    setFormData,
    handelExitEdit,
    handelSubmitForm,
    mode
}) => {
    const handleUpdate = (ev) => {
        setFormData((cuData) => ({
            ...cuData,
            dob: ev.target.value
        }));
    };

    return (
        <div className={`edit-view-area ${mode === compId ? 'show' : ''}`}>
            <div>
                <div>
                    <div className="mb-2">
                        <span className="profile-subject">Date Of Birth:</span>
                    </div>
                    <div className="mb-1">
                        <DateInput
                            handleChange={handleUpdate}
                            val={formData.dob}
                            height="50px"
                            width="100%"
                        />
                    </div>
                    <div className="line line-sm-no-wrap text-start note-text">
                        {/* <span className="value fw-500">Note:</span>
                    &nbsp;
                    <span className="value fw-400">Change your DOB</span> */}
                    </div>
                </div>
                <div className="line justify-end ">
                    <ButtonCancel evOnClick={handelExitEdit} title="Cancel" />

                    <button
                        onClick={handelSubmitForm}
                        type="button"
                        className="default-none btn-gr btn-gr-web ml-2"
                        disabled={
                            dayjs.unix(currentUser?.dob || 0).format('YYYY-MM-DD') === formData.dob
                        }
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

function ProfileDob({ currentUser }) {
    const { setMessage } = useTemplate();
    const { updateDob, mode, setMode } = useBasicInfo();

    const [formData, setFormData] = useState({
        dob: currentUser?.dob ? dayjs.unix(currentUser?.dob).format('YYYY-MM-DD') : ''
    });

    const handelClickEdit = () => {
        setMode(compId);
    };

    const handelExitEdit = () => {
        setMode(null);
    };

    const handelSubmitForm = () => {
        setMessage('Updating date of birth ...');
        updateDob(formData)
            .then(({ message }) => {
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    return (
        <>
            <div className={`line default-view-area ${mode === compId ? 'border-none' : ''}`}>
                <div className="cell cell-3 cell-md-4  od-opacity">
                    <span className="profile-subject">Date Of Birth:</span>
                </div>
                <div className="cell-fill  od-opacity">
                    <span className="value">{GetDate(currentUser?.dob)}</span>
                </div>
                <div className="cell  od-opacity">
                    <BtnProfileEdit evOnClick={handelClickEdit} isDisabled={!!mode} />
                </div>
            </div>
            <EditView
                currentUser={currentUser}
                formData={formData}
                setFormData={setFormData}
                handelExitEdit={handelExitEdit}
                handelSubmitForm={handelSubmitForm}
                mode={mode}
            />
        </>
    );
}

export default ProfileDob;
