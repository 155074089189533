import CopyText from '../../../websites/models/CopyText';
import BasicInfoEmail from '../basicinfo_info_email/BasicInfoEmail';
import BasicInfoPassword from '../basicinfo_info_password/BasicInfoPassword';
import BasicInfoPrimaryDomain from '../basicinfo_info_primarydomain/BasicInfoPrimaryDomain';
import BasicInfoShellAccess from '../basicinfo_info_shell/BasicInfoShellAccess';

import Divider from '../../hostings/_models/SectionDivider';
import BasicInfoShareSection from '../basicinfo_share/BasicInfoShareSection';
import { useHostingInfoBasic } from '../HostingInfoBasicProvider';

function CommonAreaView({ title, value, copy }) {
    return (
        <div className="info-edit-area">
            <div className="line-align line-no-wrap mb-2 ">
                <div className="cell cell-5">
                    <span className="subject">{title}:</span>
                </div>
                <div className="cell cell-7">
                    {copy ? (
                        <CopyText value={value || '--------------------------'} cName="value" />
                    ) : (
                        <span>{value || '--------------------------'}</span>
                    )}
                </div>
            </div>
        </div>
    );
}

function InfoSection() {
    const { basicInfo } = useHostingInfoBasic();
    const { username, host, ip, nameservers } = basicInfo || {};

    return (
        <div className="product-info mb-4 mb-sm-0">
            <Divider title="Information & Settings" />
            <div className="line mb-3">
                <div className="cell cell-6 cell-md-12">
                    <CommonAreaView title="Username" value={username} copy />
                    <CommonAreaView title="Host" value={host} copy />
                    <CommonAreaView title="IP Address" value={ip} copy />
                    {nameservers?.map((data, index) => (
                        <CommonAreaView
                            key={data}
                            title={`Nameservers ${index + 1}`}
                            value={data}
                            copy
                        />
                    ))}
                </div>

                <div className="cell cell-6 cell-md-12">
                    <BasicInfoPrimaryDomain basicInfo={basicInfo} />

                    <BasicInfoPassword basicInfo={basicInfo} />
                    <BasicInfoShellAccess basicInfo={basicInfo} />
                    <BasicInfoEmail basicInfo={basicInfo} />
                </div>
            </div>
            <BasicInfoShareSection />
        </div>
    );
}

export default InfoSection;
