import { Link } from 'react-router-dom';
import { useDomainInfo } from '../../domains_info/DomainInfoBasicProvider';

function CommonBtnView({ title, value, hrefLink, cName }) {
    return (
        <a target="_blank" href={hrefLink} className={cName} role="button" rel="noreferrer">
            <div className="cell-10">
                <div className="domain-info-title mb-1">{title}</div>
                <div className="domain-info-value">{value}</div>
            </div>
            <div className="cell-2">
                <div>{/* <img src={iconSimilar} alt="" /> */}</div>
            </div>
        </a>
    );
}

function BasicInfoDomainInfoSection() {
    const { domain } = useDomainInfo();
    return (
        <div className="domain-info-btn line">
            <Link
                to={`/search/?q=${domain}`}
                className="btn domain-details-info-btn btn-similar btn-similar-domain line"
            >
                <div className="cell-10">
                    <div className="domain-info-title mb-1">Find Similar Domain</div>
                    <div className="domain-info-value">
                        If you looking for similar domain name with deferent extension...
                    </div>
                </div>
                <div className="cell-2">
                    <div>{/* <img src={iconSimilar} alt="" /> */}</div>
                </div>
            </Link>

            <CommonBtnView
                title="See Whois Info"
                value="Whois info provided by whois.com visit what you looking."
                hrefLink={`https://www.whois.com/whois/${domain}`}
                cName="btn domain-details-info-btn btn-whois btn-whois-domain line"
            />

            <CommonBtnView
                title="Check DNS Zone"
                value="To get DNS zone debugging tools, click here."
                hrefLink={`https://www.whatsmydns.net/#A/${domain}`}
                cName="btn domain-details-info-btn btn-dns btn-dnsZone-domain line"
            />

            <CommonBtnView
                title="Check DNS Health"
                value="To get DNS Health debugging tools, click here."
                hrefLink={`https://dnschecker.org/domain-health-checker.php?query=${domain}`}
                cName="btn domain-details-info-btn btn-health btn-dnsHealth-domain line"
            />

            <CommonBtnView
                title="MX Information"
                value="To get MX information debugging tools, click here."
                hrefLink={`https://dnschecker.org/mx-lookup.php?query=${domain}&dns=google`}
                cName="btn domain-details-info-btn btn-mx btn-mxLookup-domain line"
            />
        </div>
    );
}

export default BasicInfoDomainInfoSection;
