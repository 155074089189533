import { useState } from 'react';
import HeaderSection from '../headers/HeaderSection';
import FooterSection2 from '../system/section/Footer2Section';
import FixedHeaderControl from '../websites/components/FixedHeaderControl';
import PostmanSection from './components/PostmanSection';

function PostmanPage() {
    const [historyText, setHistoryText] = useState('');
    return (
        <>
            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>
            <PostmanSection />
            <FooterSection2 historyText={historyText} setHistoryText={setHistoryText} />
        </>
    );
}

export default PostmanPage;
