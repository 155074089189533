import { validateEmail } from '../../../../utils/ValidatePatterns';

// name
// org
// email
// mobile
// telephone
// fax
// line1
// line2
// line3
// country
// state
// city
// zip
// residentType

function FormatAddress(name, org, email, mobile, telephone, fax, line1, country, state, city, zip) {
    if (!name) {
        return {
            error: 1,
            message: 'Please enter name'
        };
    }

    if (!validateEmail(email)) {
        return {
            error: 2,
            message: 'Please enter valid email'
        };
    }

    if (!mobile && !telephone) {
        return {
            error: 3,
            message: 'Please enter mobile or telephone'
        };
    }

    if (line1.length < 6) {
        return {
            error: 3,
            message: 'Please enter minimum 6 character of Address Line-1'
        };
    }

    if (!country) {
        return {
            error: 3,
            message: 'Please select country'
        };
    }

    if (!state) {
        return {
            error: 3,
            message: 'Please enter state'
        };
    }
    if (!city) {
        return {
            error: 3,
            message: 'Please enter city'
        };
    }
    if (!zip) {
        return {
            error: 3,
            message: 'Please enter Zip/Postal Code'
        };
    }

    return {
        error: 0,
        message: 'OK',
        address: {
            name,
            org,
            email,
            mobile,
            telephone,
            fax,
            line1,
            country,
            state,
            city,
            zip
        }
    };
}

export default FormatAddress;
