import { basic } from '../../../../configs/Images';
import Iopt from '../../../utils/Iopt';
import LayoutSelector from '../../../utils/LayoutSelector';
import MobileSidebarMenu from '../../users/components/MobileSidebarMenu';
import UserOption from './UserOption';

function UserAvatar({ setShowOptions, currentUser, myRef, showOptions, signOut }) {
    const img = currentUser?.firebase?.photoURL
        ? Iopt(currentUser?.firebase?.photoURL)
        : Iopt(currentUser?.photoUrl);

    return (
        <div>
            <div
                className="line-align line-no-wrap"
                onClick={() => {
                    setShowOptions(true);
                }}
                role="button"
                tabIndex="-1"
            >
                <div className="cell cell-self-middle">
                    <span className="d-block">
                        <img
                            style={{
                                border:
                                    currentUser?.firebase?.photoURL || currentUser?.photoUrl
                                        ? '1px solid rgba(174, 0, 185, 0.3)'
                                        : '0px solid rgba(174, 0, 185, 0.3)'
                            }}
                            className="avatar-image"
                            src={img || basic.imgAvatar}
                            alt="Avatar"
                        />
                    </span>
                </div>
                <UserOption
                    myRef={myRef}
                    showOptions={showOptions}
                    setShowOptions={setShowOptions}
                    signOut={signOut}
                />
            </div>
        </div>
    );
}

function MenuButtonArea({
    setShowOptions,
    currentUser,
    myRef,
    showOptions,
    signOut,
    handleLoginButtonClick
}) {
    if (!currentUser?.loginStatus) {
        return (
            <LayoutSelector
                sm
                md
                lg
                webComp={
                    <button
                        type="button"
                        className="button ml-1"
                        style={{
                            background: 'rgba(174, 0, 185, 0.10)',
                            border: 'none',
                            color: '#AE00B9',
                            width: '125px'
                        }}
                        onClick={handleLoginButtonClick}
                    >
                        Login
                    </button>
                }
                mobileComp={<MobileSidebarMenu />}
            />
        );
    }
    return (
        <LayoutSelector
            sm
            md
            webComp={
                <UserAvatar
                    setShowOptions={setShowOptions}
                    currentUser={currentUser}
                    myRef={myRef}
                    showOptions={showOptions}
                    signOut={signOut}
                />
            }
            mobileComp={<MobileSidebarMenu />}
        />
    );
}

export default MenuButtonArea;
