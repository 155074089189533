import { basic } from '../../../../configs/Images';

function CommonBelieveAreaView({ title, value, margin }) {
    return (
        <div className="cell-12 line mb-4" style={{ marginBottom: margin }}>
            <span className="title cell-3 cell-sm-12">{title}</span>
            <span className="value cell-9 cell-sm-12">{value}</span>
        </div>
    );
}
function CommonServiceAreaView({ title, value, margin }) {
    return (
        <div className="cell-12 line mb-4" style={{ marginBottom: margin }}>
            <span className="title cell-4 cell-sm-12">{title}</span>
            <span className="value cell-8 cell-sm-12">{value}</span>
        </div>
    );
}

function ContentRow1() {
    return (
        <div className="content-row1">
            <div className="line-align line-lg-wrap line-no-wrap line-g2">
                <div className="cell">
                    <span className="content-head-text">Who We Are</span>
                    <div className="left-col-container">
                        <span>
                            We are a Web & Mobile application development company building React,
                            PHP, and WordPress-based products. We also provide Domain, Hosting &
                            Bulk SMS service for your client.
                        </span>
                        <span>
                            We have more than 15K clients connected with us. We have 5 years of
                            experience in this field. Our server monitoring team observes all the
                            servers at every moment. Our development team continuously developed
                            programs & fixup all issues & bugs.
                        </span>
                        <span>
                            BIKIRAN was founded in 2012. We started planning in 2008. Five
                            developers decide to establish a firm to serve good hosting service. So,
                            we start planning and taking our first stapes in April 2012. We found
                            our first server in 2012 & day by day it is growing.
                        </span>
                    </div>
                </div>
                <div className="cell cell-5 cell-lg-12">
                    <img className="w-100" src={basic.iconFly} alt="" />
                </div>
            </div>
        </div>
    );
}
function ContentRow2() {
    return (
        <div className="content-row2">
            <div className="line align-end line-lg-wrap line-no-wrap line-g2">
                <div className="cell cell-5 cell-lg-12">
                    <img className="d-block" src={basic.iconPc} alt="icon" />
                </div>
                <div className="cell">
                    <span className="content-head-text">What We Value</span>
                    <div className="left-col-container">
                        <span className="">
                            We are trying to be an industry-leading company that values honesty,
                            integrity, and efficiency. Building quality products and caring for the
                            users is what made us stand out since the beginning.
                        </span>
                        <span>
                            We create premium web and mobile Applications that are stunning,
                            functional, and ready to use, and we also insured security for all our
                            products.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ContentRow3() {
    return (
        <div className="content-row3">
            <div className=" line-align line-g3 line-lg-wrap line-no-wrap">
                <div className="cell">
                    <div className="text-area line-col align-center mr-6">
                        <span className="cell-12 content-head-text">What We Believe</span>
                        <CommonBelieveAreaView
                            title="Service"
                            value="We would like to value customer's time, have a pleasant attitude, and provide knowledgeable and resourceful resources."
                        />
                        <CommonBelieveAreaView
                            title="Security"
                            value="Security is always the main part that we believe in IT Sector. And our team is always ready to maintain it."
                        />
                        <CommonBelieveAreaView
                            title="Support"
                            value="We have an expert IT team and are always ready to support you. Our 24/7 support team has a huge knowledge of IT support"
                        />
                        <CommonBelieveAreaView
                            title="Learn & Grow"
                            value="Proactive, self-directed mentality with a hunger to learn. Willing to take on responsibilities, sometimes outside of one's own “lane”"
                        />
                        <CommonBelieveAreaView
                            title="World Of Fun"
                            value="We take our task as it is our passion. We make everything super smooth and enjoy every moment of our life at the office."
                            margin={0}
                        />
                    </div>
                </div>
                <div className="cell cell-5 cell-lg-12">
                    <img className="d-block w-100" src={basic.iconStairs} alt="iconDrawing" />
                </div>
            </div>
        </div>
    );
}

function ContentRow4() {
    return (
        <div className="content-row4">
            <div className=" line-align line-g3 line-lg-wrap line-no-wrap line-lg-wrap line-no-wrap">
                <div className="cell cell-5 cell-lg-12">
                    <img className="d-block w-100" src={basic.iconDrawing} alt="iconStairs" />
                </div>

                <div className="cell">
                    <div className="text-area line-col align-center ml-6">
                        <span className="cell-12 content-head-text">Our Services</span>
                        <CommonServiceAreaView
                            title="Domain & Hosting"
                            value="Domain and hosting is one of our main businesses, We provide web hosting, App Hosting & Email Hosting"
                        />
                        <CommonServiceAreaView
                            title="Servers"
                            value="We also provide such as amazing server solution services like CloudVM, VPS & Dedicated Server"
                        />
                        <CommonServiceAreaView
                            title="Email & Push"
                            value="Email & Push Notification is another amazing service that we provide for our customers at the lowest price."
                        />
                        <CommonServiceAreaView
                            title="SMS Service"
                            value="We also provide a Bulk SMS service. It's an important thing that customers want most at this time."
                        />
                        <CommonServiceAreaView
                            title="Software Development"
                            value="One of our most valuable services is Software development service. We delivered many Web, Android & iOS applications for our clients."
                            margin={0}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function AboutSection() {
    return (
        <div className="container text-center ">
            <div className="about-section">
                <div className="text-center font-36 clr mt-6 mb-7">About Us</div>
                <div>
                    <ContentRow1 />
                    <ContentRow2 />
                    <ContentRow3 />
                    <ContentRow4 />
                </div>
            </div>
        </div>
    );
}

export default AboutSection;
