function CommonActivityView({ title, value, cName }) {
    return (
        <div className="cell cell-4 cell-xl-6 cell-lg-4 cell-md-6 cell-sm-12 mb-2">
            <div className="list-item count-box">
                <div className="line-align">
                    <div className="cell-fill">
                        <span className="count-title">{title}</span>
                    </div>
                    <div className="cell ">
                        <span className={`count-value invoice ${cName}`}>{value}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ProfileActivitySection({ value }) {
    return (
        <div className="total-activity-area">
            <div className="line-align line-g1 mb-2">
                <CommonActivityView title="Total Invoice" value={value || 0} cName="invoice" />
                <CommonActivityView title="Total Billed" value={value || 0} cName="billed" />
                <CommonActivityView title="Total Paid" value={value || 0} cName="paid" />
                <CommonActivityView title="Total Due" value={value || 0} cName="due" />
                <CommonActivityView title="Support Ticket" value={value || 0} cName="s-ticket" />
                <CommonActivityView title="Reward Point" value={value || 0} cName="r-point" />
            </div>
        </div>
    );
}

export default ProfileActivitySection;
