import { useEffect } from 'react';
import PageProperties from '../../../utils/PageProperties';
import DnsSection from '../dns/DnsSection';
import HostingInfoHeader from '../hostings_info_header/HostingInfoHeader';
import TabsSection from '../hostings_info_tabs/TabSection';

function ContentHead() {
    return (
        <HostingInfoHeader
            evReloadClick={null}
            info={{ title: 'Email Hosting - 2 GB SSD', domain: 'Domain.com' }}
            path="dns/"
        />
    );
}

function ContentBody() {
    return (
        <div className="content-body-scroll" style={{ paddingBottom: '180px' }}>
            <TabsSection dns info />
            <DnsSection tbl="domain" />
        </div>
    );
}

function HostingInfoDnsPage() {
    useEffect(() => {
        PageProperties(`DNS \u2022 Hosting`);
    }, []);

    return (
        <div className="cell cell-content h-100">
            <div className="content-section">
                <div className="content-area">
                    <ContentHead />
                    <div className="content-body">
                        <ContentBody />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HostingInfoDnsPage;
