import React, { useState } from 'react';
import { ButtonOutlineRed } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import TwoFacAuthSecurityUpdatePopup from './TwoFacAuthSecurityUpdatePopup';

function TwoFacAuthSecurityList() {
    const [mode, setMode] = useState(false);
    return (
        <>
            <div className="info-list-view">
                <div className="line line-g2 line-no-wrap">
                    <div className="cell cell-fill">
                        <div className="line-align line-no-wrap">
                            <div className="list-logo">
                                <img
                                    className="d-block w-100"
                                    src={icons.iconShieldV2}
                                    alt="icon device"
                                />
                            </div>
                            <div className="ml-3">
                                <span className="font-16 clr">*** **** **** 75</span>
                                <span className="font-14 opacity-medium d-block no-wrap">
                                    Text message (SMS)
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="cell cell-2 cell-md-fill text-right cell-self-middle">
                        <ButtonOutlineRed evOnClick={() => setMode(!mode)} title="Turn Off" />
                    </div>
                </div>
            </div>

            <TwoFacAuthSecurityUpdatePopup show={mode} setMode={setMode} />
        </>
    );
}

export default TwoFacAuthSecurityList;
