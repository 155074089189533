import { useState } from 'react';
import { ButtonActionDelete, ButtonActionEdit } from '../../../../../configs/Buttons';
import SocialListButtonArea from './SocialListButtonArea';

function UrlView({ viewType, data }) {
    if (viewType === data?.id) {
        return (
            <span className="w-md-100 h-100">
                <input
                    className="form-input w-100 table-input-field w-md-100"
                    type="text"
                    defaultValue={data?.url || ''}
                />
            </span>
        );
    }
    return (
        <span className="">
            <a href={data?.url} className="" target="_blank" rel="noreferrer">
                {data?.url || ''}
            </a>
        </span>
    );
}

function TableDisableView({ data, setViewType, viewType }) {
    return (
        <tbody className="disabled-view">
            <tr>
                <td colSpan={2}>
                    <div className="line-align">
                        <div className="logo-img ">
                            <img className="d-block w-100" src={data?.img} alt={data?.name} />
                        </div>
                        <div className="ml-3">{data?.name}</div>
                    </div>
                </td>
                <td colSpan={5} width="50%">
                    <UrlView viewType={viewType} data={data} />
                </td>
                <td colSpan={2}>
                    <div className="line-align line-no-wrap btn-action-web">
                        <span className="">
                            <ButtonActionEdit evOnClick={() => setViewType(data?.id)} />
                        </span>
                        <span className="ml-2 ">
                            <ButtonActionDelete />
                        </span>
                    </div>
                </td>
            </tr>
        </tbody>
    );
}

function TableBody({ setViewType, viewType, data }) {
    if (viewType !== null && viewType !== data?.id) {
        return <TableDisableView setViewType={setViewType} viewType={viewType} data={data} />;
    }
    return (
        <tbody>
            <tr>
                <td colSpan={2}>
                    <div className="line-align line-no-wrap">
                        <div className="logo-img ">
                            <img className="d-block w-100" src={data?.img} alt={data?.name} />
                        </div>
                        <div className="ml-3">{data?.name}</div>
                    </div>
                </td>
                <td colSpan={5} width="50%">
                    <UrlView viewType={viewType} data={data} />
                </td>
                <td colSpan={2}>
                    <SocialListButtonArea
                        setViewType={setViewType}
                        viewType={viewType}
                        data={data}
                    />
                </td>
            </tr>
        </tbody>
    );
}

function SocialListWeb({ info }) {
    const [viewType, setViewType] = useState(null);

    if (info === null) {
        return (
            <table className="table-list cell-sm-12 " cellPadding={0} cellSpacing={0}>
                <thead>
                    <tr>
                        <th colSpan={2}>Vendor</th>
                        <th colSpan={5}>Profile</th>
                        <th colSpan={2} width="13%">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody className="placeholder-mode">
                    <tr>
                        <td colSpan={1}>
                            <div className="logo-img line">
                                <span className="d-block">this</span>
                            </div>
                        </td>
                        <td colSpan={2}>
                            <span>Facebook</span>
                        </td>
                        <td colSpan={5} width="50%">
                            <span>https://www.facebook.com</span>
                        </td>
                        <td colSpan={2}>
                            <span>
                                <SocialListButtonArea
                                    setViewType={setViewType}
                                    viewType={viewType}
                                />
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
    return (
        <table className="table-list cell-sm-12" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th colSpan={2}>Vendor</th>
                    <th colSpan={5}>Profile</th>
                    <th colSpan={2} width="13%">
                        Action
                    </th>
                </tr>
            </thead>
            {info?.map((data) => (
                <TableBody
                    key={data?.id}
                    data={data}
                    viewType={viewType}
                    setViewType={setViewType}
                />
            ))}
        </table>
    );
}

export default SocialListWeb;
