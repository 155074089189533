import React from 'react';

function RoleTitle({ title }) {
    return (
        <div className="line align-end line-g1 mb-4">
            <div className="cell">
                <span className="divider-title">{title}</span>
            </div>
            <div className="cell cell-fill">
                <hr className="hr" />
            </div>
        </div>
    );
}

export default RoleTitle;
