import React from 'react';
import { useParams } from 'react-router-dom';
import UsersCLoudServerPage from './UsersCLoudServerPage';
import UsersDedicatedServerPage from './UsersDedicatedServerPage';
import UsersVspPage from './UsersVspServerPage';

function ServerType() {
    const data = [];
    const { serverType } = useParams();
    if (serverType === 'cloud') {
        return <UsersCLoudServerPage data={data} />;
    }
    if (serverType === 'vps') {
        return <UsersVspPage data={data} />;
    }
    if (serverType === 'dedicated') {
        return <UsersDedicatedServerPage data={data} />;
    }

    return null;
}

function UsersServerPage() {
    return <ServerType />;
}

export default UsersServerPage;
