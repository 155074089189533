import { useEffect } from 'react';
import HeaderSection from '../../headers/HeaderSection';
import MobileFooter from '../../users/components/MobileFooter';
import FixedHeaderControl from '../../websites/components/FixedHeaderControl';
import DomainFeatureSection from '../section/DomainFeatureSection';
import FooterSection2 from '../section/Footer2Section';
import TabsSection from '../section/TabsSection';

function HostingService({ page }) {
    useEffect(() => {
        if (page === 'hosting-service') {
            document.getElementById('hosting-service').scrollIntoView();
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [page]);

    return (
        <>
            <div id="hosting-service" />

            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>

            <TabsSection />

            <DomainFeatureSection />

            <FooterSection2 />

            <MobileFooter />
        </>
    );
}
export default HostingService;
