import { useDomainInfo } from '../../domains_info/DomainInfoBasicProvider';
import BasicInfoDzoneDdelete from '../basicinfo_dzone_Ddelete/BasicInfoDzoneDdelete';
import BasicInfoDzoneTransfer from '../basicinfo_dzone_transfer/BasicInfoDzoneTransfer';

function Divider() {
    return (
        <div className="line align-end line-g1 mb-4">
            <div className="cell">
                <span className="divider-title red">Danger Zone</span>
            </div>
            <div className="cell cell-fill">
                <hr
                    style={{
                        backgroundColor: '#EC1C24',
                        height: '1px',
                        border: 'none'
                    }}
                    className="hr mb-1"
                />
            </div>
        </div>
    );
}

function BasicInfoDzoneSection({ disable }) {
    const { domainInfo } = useDomainInfo();
    // const { cpSuspend, cpRemove, contractDelete } = domainInfo?.permissions || {};
    // const isSuspend = domainInfo && cpSuspend;
    // const isRemove = domainInfo && cpRemove;
    // const isContractDelete = domainInfo && contractDelete;

    // if (domainInfo && !isSuspend && !isRemove && !isContractDelete) {
    //     return null;
    // }
    return (
        <div className="product-info dzone-section fill mb-6">
            <Divider />
            <div className="line line-g2 line-sm-wrap">
                <BasicInfoDzoneTransfer
                    info={domainInfo}
                    disable={disable}
                    // disable={disable || !isSuspend || domainInfo?.isSuspended}
                />
                <BasicInfoDzoneDdelete
                    info={domainInfo}
                    disable={disable}
                    // disable={disable || !isSuspend || domainInfo?.isSuspended}
                />
            </div>
        </div>
    );
}

export default BasicInfoDzoneSection;
