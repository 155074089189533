import { ButtonActionDotMenu } from '../../../../../configs/Buttons';
import { useLayout } from '../../../../contexts/LayoutProvider';
import RoleListTableMenuOption from './RoleListTableMenuOption';

function RoleListTableActionBtn({ setModal, setShowOptions, showOptions, data, myRef, isShow }) {
    const { windowSizeName } = useLayout();
    const isMobile = windowSizeName === 'xs' || windowSizeName === 'sm' || windowSizeName === 'md';
    if (!isShow) {
        return null;
    }
    return (
        <div className="position-relative">
            <div className={isMobile ? 'btn-action' : 'btn-action-web'}>
                <span>
                    <ButtonActionDotMenu evOnClick={() => setShowOptions(data?.roleId)} />
                </span>
            </div>
            <div className="option-popup ">
                <RoleListTableMenuOption
                    setModal={setModal}
                    setShowOptions={setShowOptions}
                    showOptions={showOptions}
                    data={data}
                    myRef={myRef}
                />
            </div>
        </div>
    );
}

export default RoleListTableActionBtn;
