import { createContext, useContext, useState } from 'react';
import AxiosAuth from '../../utils/AxiosAuth';
import VerificationOtpForm from './VerificationOtpForm';
import VerificationOtpLoading from './VerificationOtpLoading';

const OtpContext = createContext();

export function useOtp() {
    return useContext(OtpContext);
}

const initialState = {
    email: null,
    onValidate: null,
    sentId: null,
    otp: '',
    error: 0,
    message: '',
    verified: false
};

function VerificationOtpProvider({ children, otpKey }) {
    const [stateData, setStateData] = useState(initialState);

    const sendOtp = (email, onValidate) =>
        new Promise((resolve, reject) => {
            setStateData((cuData) => ({ ...cuData, email, onValidate }));

            AxiosAuth.setApiUrl('VERIFICATIONS', 'email/send-otp')
                .post({ email, otpKey })
                .then(({ data }) => {
                    if (data.error === 0) {
                        setStateData((cuData) => ({ ...cuData, sentId: data?.sentId || null }));
                    } else {
                        setStateData((cuData) => ({
                            ...cuData,
                            error: data?.error,
                            message: data?.message
                        }));
                    }

                    resolve(data);
                })
                .catch((err) => {
                    setStateData((cuData) => ({
                        ...cuData,
                        error: 1,
                        message: err
                    }));

                    reject(err);
                });
        });

    const resendOtp = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.setApiUrl('VERIFICATIONS', 'email/send-otp')
                .post({ email: stateData.email, otpKey })
                .then(({ data }) => {
                    if (data.error === 0) {
                        setStateData((cuData) => ({ ...cuData, sentId: data?.sentId || null }));
                    } else {
                        setStateData((cuData) => ({
                            ...cuData,
                            error: data?.error,
                            message: data?.message
                        }));
                    }

                    resolve(data);
                })
                .catch((err) => {
                    setStateData((cuData) => ({
                        ...cuData,
                        error: 1,
                        message: err
                    }));

                    reject(err);
                });
        });

    const verifyOtp = () =>
        new Promise((resolve, reject) => {
            AxiosAuth.setApiUrl('VERIFICATIONS', 'email/verify-otp')
                .post({
                    email: stateData.email,
                    sentId: stateData.sentId,
                    otp: stateData.otp,
                    otpKey
                })
                .then(({ data }) => {
                    setStateData((cuData) => ({
                        ...cuData,
                        verified: data?.error === 0
                    }));

                    if (data.error === 0 && typeof stateData.onValidate === 'function') {
                        const obj = stateData.onValidate(stateData);

                        if (Promise.resolve(obj) === obj) {
                            obj.then((params) => {
                                resolve(params);
                            }).catch((err) => {
                                reject(err);
                            });
                        } else {
                            resolve(data);
                        }
                    } else {
                        resolve(data);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });

    const closeWindow = () => {
        setStateData(initialState);
    };

    const value = {
        stateData,
        setStateData,
        sendOtp,
        closeOtpWindow: closeWindow
    };

    return (
        <OtpContext.Provider value={value}>
            {children}
            {/* Loading window on sending email */}
            {stateData.email && stateData.sentId === null ? (
                <VerificationOtpLoading
                    error={stateData.error}
                    message={stateData.message}
                    closeWindow={closeWindow}
                />
            ) : null}

            {/* Form Window when sent email */}
            {stateData.email && stateData.sentId ? (
                <VerificationOtpForm
                    resendOtp={resendOtp}
                    verifyOtp={verifyOtp}
                    closeWindow={closeWindow}
                    formData={stateData}
                    setFormData={setStateData}
                    stateData={stateData}
                />
            ) : null}
        </OtpContext.Provider>
    );
}

export default VerificationOtpProvider;
