import React, { useEffect } from 'react';
import HeaderSection from '../headers/HeaderSection';
import FooterSection2 from '../system/section/Footer2Section';
import MobileFooter from '../users/components/MobileFooter';
import FixedHeaderControl from '../websites/components/FixedHeaderControl';
import CartDetailsSection from './comps/CartDetailsSection';

function CartPage({ page }) {
    useEffect(() => {
        if (page === 'cart') {
            document.getElementById('cart').scrollIntoView();
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [page]);

    return (
        <>
            <div id="cart" />
            <FixedHeaderControl>
                <HeaderSection cart />
            </FixedHeaderControl>
            <div className="container text-center mb-6 mt-5">
                <span className="font-36 clr">Your Cart</span>
            </div>
            <CartDetailsSection />
            <MobileFooter />
            <FooterSection2 />
        </>
    );
}

export default CartPage;
