import React from 'react';
import ListActionButton from './ListActionButton';

function Body({ arr }) {
    if (arr?.length === 0) {
        return (
            <tbody>
                <tr>
                    <td colSpan={100}>
                        <span className="font-16 clr">No Payment Method Found !</span>
                    </td>
                </tr>
            </tbody>
        );
    }
    return arr?.map((item) => (
        <tbody key={item?.id} className={item?.isDemo ? 'placeholder-mode' : ''}>
            <tr key={item.id}>
                <td colSpan={1}>
                    <span>#{item.id}</span>
                </td>
                <td colSpan={2}>
                    <span>{item.acType}</span>
                </td>
                <td colSpan={2}>
                    <span>{item.acNum}</span>
                </td>
                <td colSpan={1}>
                    <span>{item.expDate}</span>
                </td>
                <td>
                    <span>
                        <ListActionButton />
                    </span>
                </td>
            </tr>
        </tbody>
    ));
}

function MethodListWeb({ arr }) {
    return (
        <table className="table-list" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th colSpan={1}>#</th>
                    <th colSpan={2}>AC/Card Type</th>
                    <th colSpan={2}>AC/Card Number</th>
                    <th colSpan={1}>Expire Date</th>
                    <th colSpan={1}>Action</th>
                </tr>
            </thead>
            <Body arr={arr} />
        </table>
    );
}

export default MethodListWeb;
