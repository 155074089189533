import { useState } from 'react';
import { arrowIcons } from '../../../../../configs/Icons';
import CommonAreaHead from '../CommonAreaHead';

const menuItem = [
    {
        id: 1,
        title: 'Hosting',
        questions: [
            {
                id: 1,
                question: 'How To Buy Hosting Package?',
                answer: 'There is lot of option to buy our hosting package, You can buy it directly from our website. Also you can contact me before you buy.'
            },
            {
                id: 2,
                question: 'Is BIKIRAN keep my website data somehow?',
                answer: '---'
            },
            {
                id: 3,
                question: 'What if I change my mind and want refund?',
                answer: `---`
            },
            {
                id: 4,
                question: 'Is BIKIRAN save my card?',
                answer: `---`
            },
            {
                id: 5,
                question: 'How To Buy Hosting Package?',
                answer: `---`
            }
        ]
    },
    {
        id: 2,
        title: 'Domain',
        questions: [
            {
                id: 1,
                question: 'How To Buy Domain Package?',
                answer: 'There is lot of option to buy our Domain package, You can buy it directly from our website. Also you can contact me before you buy.'
            },
            {
                id: 2,
                question: 'Is BIKIRAN keep my website data somehow?',
                answer: '---'
            },
            {
                id: 3,
                question: 'What if I change my mind and want refund?',
                answer: `---`
            },
            {
                id: 4,
                question: 'Is BIKIRAN save my card?',
                answer: `---`
            },
            {
                id: 5,
                question: 'How To Buy Hosting Package?',
                answer: `---`
            }
        ]
    },
    {
        id: 3,
        title: 'Payment & Refund',
        questions: [
            {
                id: 1,
                question: 'Payment & Refund?',
                answer: 'Coming Soon....'
            },
            {
                id: 2,
                question: 'Is BIKIRAN keep my website data somehow?',
                answer: '---'
            },
            {
                id: 3,
                question: 'What if I change my mind and want refund?',
                answer: `---`
            },
            {
                id: 4,
                question: 'Is BIKIRAN save my card?',
                answer: `---`
            },
            {
                id: 5,
                question: 'How To Buy Hosting Package?',
                answer: `---`
            }
        ]
    },
    {
        id: 4,
        title: 'Account',
        questions: [
            {
                id: 1,
                question: 'Account',
                answer: 'Comming Soon.......'
            },
            {
                id: 2,
                question: 'Is BIKIRAN keep my website data somehow?',
                answer: '---'
            },
            {
                id: 3,
                question: 'What if I change my mind and want refund?',
                answer: `---`
            },
            {
                id: 4,
                question: 'Is BIKIRAN save my card?',
                answer: `---`
            },
            {
                id: 5,
                question: 'How To Buy Hosting Package?',
                answer: `---`
            }
        ]
    },
    {
        id: 5,
        title: 'Software Service',
        questions: [
            {
                id: 1,
                question: 'Software Service',
                answer: 'Comming Soon.......'
            },
            {
                id: 2,
                question: 'Is BIKIRAN keep my website data somehow?',
                answer: '---'
            },
            {
                id: 3,
                question: 'What if I change my mind and want refund?',
                answer: `---`
            },
            {
                id: 4,
                question: 'Is BIKIRAN save my card?',
                answer: `---`
            },
            {
                id: 5,
                question: 'How To Buy Hosting Package?',
                answer: `---`
            }
        ]
    },
    {
        id: 6,
        title: 'SMS & Email',
        questions: [
            {
                id: 1,
                question: 'SMS & Email',
                answer: 'Comming Soon.......'
            },
            {
                id: 2,
                question: 'Is BIKIRAN keep my website data somehow?',
                answer: '---'
            },
            {
                id: 3,
                question: 'What if I change my mind and want refund?',
                answer: `---`
            },
            {
                id: 4,
                question: 'Is BIKIRAN save my card?',
                answer: `---`
            },
            {
                id: 5,
                question: 'How To Buy Hosting Package?',
                answer: `---`
            }
        ]
    }
];
function FaqSidebarMenu({ setMenu, menu }) {
    return (
        <div className="question-menu w-100 d-sm-none">
            <div className="line-col align-center">
                {menuItem.map((item) => (
                    <div
                        role="button"
                        tabIndex={-1}
                        onClick={() => setMenu(item?.id)}
                        key={item.id}
                        className={`line-row-between w-100 default ${
                            menu === item?.id && 'active'
                        }`}
                    >
                        <span>{item.title}</span>
                        <span>
                            <img src={arrowIcons.iconArrowRightV1} alt="iconRight" srcSet="" />
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}

function FaqMobileMenu() {
    return (
        <select name="status" className="form-input w-100 d-none d-sm-block w-100 mb-6">
            <option value="status">Status</option>
            <option value="active">Active</option>
            <option value="notActive">Not Active</option>
            <option value="expired">Expired</option>
            <option value="expiredSoon">Expired soon</option>
            <option value="recoveryMode">Recovery Mode</option>
        </select>
    );
}

function QuestionItem({ item, index, activeIndex, setActiveIndex }) {
    return (
        <div
            role="button"
            tabIndex={-1}
            className={`question-item  ${activeIndex === index && 'bg-img'}`}
            onClick={() => setActiveIndex(activeIndex !== index ? index : null)}
        >
            <div className="line-row-between mb-1">
                <span className="title cell-sm-10">{item.question}</span>
                <span className="cell-sm-2 line justify-end ">
                    <img
                        src={
                            index === activeIndex
                                ? arrowIcons.iconArrowTopS
                                : arrowIcons.iconArrowBottomS
                        }
                        alt="icon"
                    />
                </span>
            </div>
            <div className={`line-align answer-view  ${index === activeIndex ? 'show' : 'hide'}`}>
                <div className="cell-11">{item.answer}</div> <div className="cell-1" />
            </div>
        </div>
    );
}

function FaqQuestions({ menu }) {
    const [activeIndex, setActiveIndex] = useState(null);

    return (
        <div className="faq-questions-area">
            <div className="w-100">
                {menuItem
                    ?.filter((el) => menu === el?.id)
                    .map((item) =>
                        item?.questions?.map((question, index) => (
                            <QuestionItem
                                key={question.id}
                                item={question}
                                index={index}
                                activeIndex={activeIndex}
                                setActiveIndex={setActiveIndex}
                            />
                        ))
                    )}
            </div>
        </div>
    );
}

function SupportFaqArea() {
    const [menu, setMenu] = useState(1);
    return (
        <div className="container support-page-area">
            <div className="faq-area">
                <CommonAreaHead title="FAQ" value="Quick Question" />
                <div className="line line-g3 w-sm-100 area-content">
                    <div
                        className="cell cell-3 cell-lg-4 cell-md-4 cell-sm-12 w-100"
                        style={{ position: 'relative' }}
                    >
                        <FaqSidebarMenu setMenu={setMenu} menu={menu} />
                        <FaqMobileMenu />
                    </div>
                    <div className="cell cell-9 cell-lg-8 cell-md-8 cell-sm-12">
                        <FaqQuestions menu={menu} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SupportFaqArea;
