import React, { useState } from 'react';
import LayoutSelector from '../../../../utils/LayoutSelector';
import TwoFacAuthMobile from './TwoFacAuthUpdateMobile';
import TwoFacAuthUpdatePopup from './TwoFacAuthUpdatePopup';
import TwoFacAuthWeb from './TwoFacAuthUpdateWeb';

function AuthUpdateView() {
    const [mode, setMode] = useState(false);
    return (
        <>
            <LayoutSelector
                sm
                md
                webComp={<TwoFacAuthWeb setMode={setMode} mode={mode} />}
                mobileComp={<TwoFacAuthMobile setMode={setMode} mode={mode} />}
            />

            <TwoFacAuthUpdatePopup show={mode} setMode={setMode} />
        </>
    );
}

function TwoFacAuthUpdateSection() {
    return (
        <div className="mb-6">
            <div className="info-list-header opacity-medium">
                <span className="fs-16 fw-500">Two-Factor authentication</span>
            </div>

            <AuthUpdateView />
        </div>
    );
}

export default TwoFacAuthUpdateSection;
