import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonGr } from '../../../../configs/Buttons';
import { useAuth } from '../../../contexts/AuthProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import PageProperties from '../../../utils/PageProperties';
import { mkToken } from '../../../utils/StringOperation';
import TableActionButton from '../_models/TableActionButton';
import HostingListHeader from './hostings_list_header/HostingListHeader';
import HostingListTable from './hostings_list_table/HostingListTable';

// function DataDetails({ hostingData }) {
//     return (
//         <div className={`mb-3 ${!hostingData ? 'placeholder-mode' : ''}`}>
//             <span className="font-14 fw-500 opacity-medium">
//                 Showing {hostingData?.length || '--'} out of {hostingData?.length || '--'}{' '}
//             </span>
//         </div>
//     );
// }

function UsersHostingListPage() {
    const { currentUser } = useAuth();
    const [uniqueKey, setUniqueKey] = useState(mkToken(32));
    const [hostingData, setHostingData] = useState(null);
    const handelReload = () => {
        setUniqueKey(mkToken(32));
        setHostingData(null);
    };

    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .setApiUrl('CONTRACT_HOSTING', `/list/`)
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    setHostingData(data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentUser, uniqueKey]);

    useEffect(() => {
        PageProperties('Hosting List');
    }, []);

    return (
        <div className="content-area">
            <HostingListHeader
                handelReload={handelReload}
                listDetails={
                    <span className="font-14 primary-50">
                        Showing {hostingData?.length || '--'} out of {hostingData?.length || '--'}{' '}
                    </span>
                }
            />

            <div className="content-body">
                <div className="content-body-scroll" style={{ paddingBottom: '120px' }}>
                    <HostingListTable hostingData={hostingData} setUniqueKey={setUniqueKey} />

                    <TableActionButton show={hostingData}>
                        <div className="line-row-center mb-4">
                            <Link to="/services/hosting/web/">
                                <ButtonGr title="Buy More" />
                            </Link>
                        </div>
                    </TableActionButton>
                </div>
            </div>
        </div>
    );
}

export default UsersHostingListPage;
