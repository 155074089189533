import { useNavigate } from 'react-router-dom';
import roleIconPicker from '../../_models/RoleIconPicker';
import TableActionBtn from './TableActionBtn';

function DomainView({ item, setModal }) {
    const handelCreateButton = (ev) => {
        ev.stopPropagation();
        setModal({ type: 'cp-wizard', data: item });
    };

    if (item?.domain?.length === 0) {
        return (
            <div
                className="sub-title mb-1"
                role="button"
                tabIndex={-1}
                onClick={(ev) => handelCreateButton(ev)}
            >
                <span className=" primary-50">[Domain Not Set]</span>
                <span className="purple d-block">Create cPanel</span>
            </div>
        );
    }
    return (
        <div className="sub-title primary-50 mb-1">
            <span>{item?.domain}</span>
        </div>
    );
}

function InfoCommonView({ title, value }) {
    return (
        <div className="line-row-between">
            <div className="cell">
                <span className="value primary-70">{title}:</span>
            </div>
            <div className="cell">
                <span className="value primary">{value}</span>
            </div>
        </div>
    );
}

const HostingListTableMobile = ({ arr, setModal }) => {
    const navigate = useNavigate();

    if (!arr?.length) {
        return <div>No data found!</div>;
    }
    return arr?.map((item) => (
        <div
            role="button"
            tabIndex={-1}
            onClick={() => navigate(`/user/services/hosting/${item?.id}/`)}
            key={item?.id}
            className={`list-item-sm ${item?.isDemo ? 'placeholder-mode' : ''}`}
        >
            <div className="line-row-between">
                <div className="cell">
                    <div className="line-align line-no-wrap">
                        {roleIconPicker(item)}
                        <span className="title">{item?.title}</span>
                    </div>
                    <DomainView setModal={setModal} item={item} />
                </div>
                <div className="cell">
                    <TableActionBtn item={item} setModal={setModal} />
                </div>
            </div>
            <InfoCommonView title="Renew Price" value={`USD ${item?.priceOffer}`} />
            <InfoCommonView title="Expire On" value={item?.expiredOn} />
        </div>
    ));
};

export default HostingListTableMobile;
