import { useNavigate, useParams } from 'react-router-dom';
import { ButtonOutlinePurple } from '../../../../../../configs/Buttons';
import { GetDate } from '../../../../../utils/Show';
import TeamAvatarRow from './TeamAvatarRow';

function TableBody({ arr }) {
    const { contractId } = useParams();
    const navigate = useNavigate();
    const handelManage = () => {
        navigate(`/user/services/hosting/${contractId}/conversation/team/`);
    };

    return (
        <tbody>
            {arr?.map((data) => (
                <tr key={data?.id} className={data?.demo ? 'placeholder-mode' : ''}>
                    <td>{data?.id}</td>
                    <td>{data?.topics}</td>
                    <td>
                        <TeamAvatarRow arr={data?.team} />
                    </td>
                    <td>{GetDate(data?.activity)}</td>
                    <td>
                        <ButtonOutlinePurple evOnClick={handelManage} title="Manage" />
                    </td>
                </tr>
            ))}
        </tbody>
    );
}

function ConversationListWeb({ arr }) {
    return (
        <table className="table-list table-v2 cell-sm-12" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th>SL</th>
                    <th width="35%">Topics</th>
                    <th width="25%">Team</th>
                    <th>Last Activity</th>
                    <th width="10%">Action</th>
                </tr>
            </thead>
            <TableBody arr={arr} />
        </table>
    );
}

export default ConversationListWeb;
