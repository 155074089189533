/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import { ButtonOutlinePurple } from '../../../../../configs/Buttons';
import { GetDate } from '../../../../utils/Show';
import MessageModal from '../../../system/modals/MessageModal';
import InfoStatusHandler from '../../../users/models/InfoStatusHandler';

function TableBody({ arr }) {
    const [modal, setModal] = useState(false);
    const tdStyle = { maxWidth: '100px' };

    if (!arr?.length) {
        return (
            <tbody>
                <tr>
                    <td colSpan={100}>No Email Found !</td>
                </tr>
            </tbody>
        );
    }
    return (
        <tbody>
            {arr?.map((data, index) => (
                <tr key={index} className={data?.demo ? 'placeholder-mode' : ''}>
                    <td>{index + 1}</td>
                    <td style={tdStyle}>
                        {' '}
                        <p className="overflow-dotted no-wrap m-0">
                            <span>{data?.email}</span>
                        </p>{' '}
                    </td>
                    <td>
                        {' '}
                        <span>{GetDate(data?.timeUpdate)}</span>{' '}
                    </td>
                    <td className="fw-500">
                        {' '}
                        <span className="red">0%</span>{' '}
                    </td>
                    <td className="fw-500">
                        {' '}
                        <span style={{ color: InfoStatusHandler(data) }}>
                            {data?.status || 0}
                        </span>{' '}
                    </td>
                    <td>
                        <span className="d-block">
                            <ButtonOutlinePurple evOnClick={() => setModal(true)} title="Backup" />
                            <MessageModal show={modal} setModal={setModal} />
                        </span>
                    </td>
                </tr>
            ))}
        </tbody>
    );
}

function EmailListTableWeb({ arr }) {
    return (
        <table className="table-list cell-sm-12" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th>SL</th>
                    <th width="35%">Email</th>
                    <th>Updated on</th>
                    <th>B. Rate</th>
                    <th>Status</th>
                    <th width="10%">Action</th>
                </tr>
            </thead>
            <TableBody arr={arr} />
        </table>
    );
}

export default EmailListTableWeb;
