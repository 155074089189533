import { useEffect } from 'react';
import PageProperties from '../../../utils/PageProperties';
import HeaderSection from '../../headers/HeaderSection';
import FooterSection2 from '../../system/section/Footer2Section';
import MobileFooter from '../../users/components/MobileFooter';
import FixedHeaderControl from '../../websites/components/FixedHeaderControl';
import SoftwareServiceTabSection from './componenets/SoftwareServiceTabSection';

function SoftwareServicePage() {
    useEffect(() => {
        PageProperties('Software \u2022 Services');
    }, []);

    return (
        <>
            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>
            <SoftwareServiceTabSection />
            <MobileFooter />
            <FooterSection2 />
        </>
    );
}

export default SoftwareServicePage;
