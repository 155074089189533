import { ButtonClose } from '../../../../configs/Buttons';

const ModalHeader = ({ setShowOptions, title }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title">Delete {title}</div>
            <div className="modal-box-close">
                <ButtonClose evOnClick={() => setShowOptions(false)} />
            </div>
        </div>
    </div>
);

function ModalBody({ title }) {
    return (
        <div className="modal-body-area text-center mb-2 mt-2">
            <div className="line-row-center align-unset mb-4">
                <svg
                    width="49"
                    height="49"
                    viewBox="0 0 49 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="24.5" cy="24.5" r="24" stroke="#FFA41D" />
                    <path
                        d="M26.9659 18.275H26.9676L25.5534 27.7142H25.5516C25.4673 28.3578 25.2351 28.846 24.4998 28.846C23.7645 28.846 23.5324 28.3578 23.448 27.7142H23.4462L22.0321 18.275H22.0337C22.0123 18.1109 22 17.9421 22 17.7692C22 16.2398 22.8414 15 24.5 15C26.1584 15 27 16.2398 27 17.7692C26.9996 17.9423 26.9874 18.1109 26.9659 18.275ZM24.4996 30.6923C23.2097 30.6923 22.5552 31.6565 22.5552 32.8462C22.5552 34.0358 23.2097 35 24.4996 35C25.7896 35 26.4441 34.0358 26.4441 32.8462C26.4441 31.6565 25.7896 30.6923 24.4996 30.6923Z"
                        fill="#FFA41D"
                    />
                </svg>
            </div>
            <div className="clr fw-400">
                <span>Primary {title} can`t be deleted !!</span>
            </div>
        </div>
    );
}

function DeletePrimaryPopup({ setShowOptions, show, title }) {
    if (!show) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <ModalHeader setShowOptions={setShowOptions} title={title} />

                <ModalBody show={show} title={title} />
            </div>
        </div>
    );
}

export default DeletePrimaryPopup;
