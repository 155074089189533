import { ButtonGr, ButtonOutlineRed } from '../../../../../configs/Buttons';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import { useOtp } from '../../../verification_otp/VerificationOtpProvider';
import CopyText from '../../../websites/models/CopyText';
import { useDomainInfo } from '../../domains_info/DomainInfoBasicProvider';

function Domain() {
    const { domain } = useDomainInfo();

    return (
        <div className="line-row-center font-14 mt-2">
            <span>Domain:</span>
            &nbsp;
            <CopyText
                arrow={false}
                cName="fw-500"
                copyValue={domain}
                value={domain?.toUpperCase()}
            />
        </div>
    );
}

function WarningText({ isAllow, isActive }) {
    if (!isAllow) {
        return (
            <>
                <div className="line-row-center align-unset mb-4">
                    <svg
                        width="49"
                        height="49"
                        viewBox="0 0 49 49"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="24.5" cy="24.5" r="24" stroke="#FFA41D" />
                        <path
                            d="M26.9659 18.275H26.9676L25.5534 27.7142H25.5516C25.4673 28.3578 25.2351 28.846 24.4998 28.846C23.7645 28.846 23.5324 28.3578 23.448 27.7142H23.4462L22.0321 18.275H22.0337C22.0123 18.1109 22 17.9421 22 17.7692C22 16.2398 22.8414 15 24.5 15C26.1584 15 27 16.2398 27 17.7692C26.9996 17.9423 26.9874 18.1109 26.9659 18.275ZM24.4996 30.6923C23.2097 30.6923 22.5552 31.6565 22.5552 32.8462C22.5552 34.0358 23.2097 35 24.4996 35C25.7896 35 26.4441 34.0358 26.4441 32.8462C26.4441 31.6565 25.7896 30.6923 24.4996 30.6923Z"
                            fill="#FFA41D"
                        />
                    </svg>
                </div>
                <div className="clr">
                    <span className="font-16">
                        You Don`t have permission to {isActive ? 'Disable' : 'Enable'} privacy
                        protection!
                    </span>
                </div>
            </>
        );
    }
    if (isActive) {
        return (
            <>
                <div className="line-row-center align-unset mb-4">
                    <svg
                        width="49"
                        height="49"
                        viewBox="0 0 49 49"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="24.5" cy="24.5" r="24" stroke="#FFA41D" />
                        <path
                            d="M26.9659 18.275H26.9676L25.5534 27.7142H25.5516C25.4673 28.3578 25.2351 28.846 24.4998 28.846C23.7645 28.846 23.5324 28.3578 23.448 27.7142H23.4462L22.0321 18.275H22.0337C22.0123 18.1109 22 17.9421 22 17.7692C22 16.2398 22.8414 15 24.5 15C26.1584 15 27 16.2398 27 17.7692C26.9996 17.9423 26.9874 18.1109 26.9659 18.275ZM24.4996 30.6923C23.2097 30.6923 22.5552 31.6565 22.5552 32.8462C22.5552 34.0358 23.2097 35 24.4996 35C25.7896 35 26.4441 34.0358 26.4441 32.8462C26.4441 31.6565 25.7896 30.6923 24.4996 30.6923Z"
                            fill="#FFA41D"
                        />
                    </svg>
                </div>
                <div className="clr">
                    <span className="font-16">Are you sure you want to disable domain lock?</span>
                </div>
            </>
        );
    }
    return (
        <>
            <div className="line-row-center align-unset mb-4">
                <svg
                    width="49"
                    height="49"
                    viewBox="0 0 49 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="24.5" cy="24.5" r="24" stroke="#FFA41D" />
                    <path
                        d="M26.9659 18.275H26.9676L25.5534 27.7142H25.5516C25.4673 28.3578 25.2351 28.846 24.4998 28.846C23.7645 28.846 23.5324 28.3578 23.448 27.7142H23.4462L22.0321 18.275H22.0337C22.0123 18.1109 22 17.9421 22 17.7692C22 16.2398 22.8414 15 24.5 15C26.1584 15 27 16.2398 27 17.7692C26.9996 17.9423 26.9874 18.1109 26.9659 18.275ZM24.4996 30.6923C23.2097 30.6923 22.5552 31.6565 22.5552 32.8462C22.5552 34.0358 23.2097 35 24.4996 35C25.7896 35 26.4441 34.0358 26.4441 32.8462C26.4441 31.6565 25.7896 30.6923 24.4996 30.6923Z"
                        fill="#FFA41D"
                    />
                </svg>
            </div>
            <div className="clr">
                <span className="font-16">
                    Locking the domain will stop your domain transfer. And your domain will be safe.
                </span>
            </div>
        </>
    );
}

function ModalBody({ setModal, isActive, isAllow }) {
    const { handelDomainUnlock, handelDomainLock, domain, cuContact } = useDomainInfo();
    const { setMessage } = useTemplate();
    const { sendOtp, closeOtpWindow } = useOtp();

    const email = cuContact?.admin?.email || '--';

    const handelDomainUnlockBtn = () => {
        setMessage('Domain unlocking...');

        sendOtp(email, ({ sentId, otp }) => {
            // domain, vSentId, vOTP
            handelDomainUnlock(domain, sentId, otp)
                .then(({ error, message }) => {
                    if (error === 0) {
                        closeOtpWindow();
                        setModal(null);
                    }

                    setMessage(message);
                })
                .catch((err) => {
                    setMessage(err);
                });
        });
    };

    const handelDomainLockButton = () => {
        setMessage('Domain locking...');

        handelDomainLock()
            .then(({ error, message }) => {
                if (error === 0) {
                    closeOtpWindow();
                    setModal(null);
                }
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err);
            });
    };

    if (!isAllow) {
        return (
            <div className="modal-body-area text-center mb-2 mt-2">
                <WarningText isActive={isActive} isAllow={isAllow} />
                <Domain />
                <div className="line-row-center mt-4">
                    <span>
                        <ButtonOutlineRed evOnClick={() => setModal(null)} title="Ok" modal />
                    </span>
                </div>
            </div>
        );
    }

    return (
        <div className="modal-body-area text-center mb-2 mt-2">
            <WarningText isActive={isActive} isAllow={isAllow} />
            <Domain />
            <div className="line-row-center mt-4">
                <span>
                    <ButtonOutlineRed evOnClick={() => setModal(null)} title="Close" modal />
                </span>
                <span className="ml-2">
                    {isActive ? (
                        <ButtonGr evOnClick={() => handelDomainUnlockBtn()} title="Unlock" modal />
                    ) : (
                        <ButtonGr evOnClick={() => handelDomainLockButton()} title="Lock" modal />
                    )}
                </span>
            </div>
        </div>
    );
}

function BasicInfoDomainLockPopup({ show, setModal }) {
    const { isTransferLock } = useDomainInfo();
    const isDomainLockAllowed = true;

    if (!show) {
        return null;
    }
    return (
        <div className="modal-box backdrop">
            <div
                className="modal-box-content"
                style={{
                    backgroundColor: isTransferLock ? 'rgba(254, 244, 244, 1)' : '#F9FFFB'
                }}
            >
                <ModalBody
                    setModal={setModal}
                    isActive={isTransferLock}
                    isAllow={isDomainLockAllowed}
                />
            </div>
        </div>
    );
}

export default BasicInfoDomainLockPopup;
