import React, { useRef, useState } from 'react';
import { basic } from '../../../../../configs/Images';
import { GetDate } from '../../../../utils/Show';
import { useRole } from '../RoleProvider';
import { status } from './RoleListStatus';
import ListActionButton from './RoleListTableActionBtn';

const demoArr = [
    { id: '--', name: '--------', date: '-----------', role: '---------', isDemo: true }
];

// web
function TableBody({ setModal, arr }) {
    const [showOptions, setShowOptions] = useState(false);
    const ref = useRef();
    if (!arr?.length) {
        return (
            <tr>
                <td colSpan={100}>
                    <span className=" font-16 no-wrap">No User found!</span>
                </td>
            </tr>
        );
    }
    return arr?.map((data) => (
        <tr key={data?.roleId + 1} className={data?.isDemo ? 'placeholder-mode' : ''}>
            <td>
                <div className="user-img">
                    <span className="d-block">
                        <img className="w-100 d-block" src={basic.imgAvatar} alt="" />
                    </span>
                </div>
            </td>
            <td className="break-all">
                <span className="">{data?.name || '------------'}</span>
                {status(data)}
            </td>
            <td>
                <span className="">{GetDate(data?.timeAccepted) || '------------'}</span>
            </td>
            <td>
                <span className="">{data?.roleTitle || '------------'}</span>
            </td>
            <td>
                <ListActionButton
                    setModal={setModal}
                    setShowOptions={setShowOptions}
                    showOptions={data?.roleId === showOptions}
                    isShow={data?.buttons?.length > 0}
                    data={data}
                    myRef={ref}
                />
            </td>
        </tr>
    ));
}

function RoleListTableWeb({ setModal }) {
    const { roles } = useRole();
    // console.log(roles);
    const arr = roles || demoArr;
    return (
        <table className="table-list md-fon" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th className="no-wrap" width="10%">
                        <span>Image</span>
                    </th>
                    <th className="no-wrap" width="42%">
                        <span>Name</span>
                    </th>
                    <th className="no-wrap" width="20%">
                        <span>Assign Date</span>
                    </th>
                    <th className="no-wrap" width="20%">
                        <span>Role</span>
                    </th>
                    <th className="no-wrap" width="8%">
                        <span>Action</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <TableBody setModal={setModal} arr={arr} />
            </tbody>
        </table>
    );
}

export default RoleListTableWeb;
