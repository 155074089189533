import React from 'react';
import { Link } from 'react-router-dom';

function FooterSectionHome() {
    return (
        <div className="footer-section-home d-sm-none">
            <div className="container">
                <div className="line">
                    <div className="cell cell-6">
                        <p className="footer-section-home-text">
                            We are not Search Engine <Link to=".">How it’s Work?</Link>
                        </p>
                    </div>
                    <div className="cell cell-6 text-right">
                        <p className="footer-section-home-text">
                            <Link to="/legal/privacy-policy/">Privacy & Policy</Link> .{' '}
                            <Link to=".">Terms & Condition</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterSectionHome;
