export function TableMobile({ children }) {
    return <div className="table-list-m w-100">{children}</div>;
}

export function TableHeader({ children }) {
    return (
        <div className="table-list-header">
            <div className="line-row-between line-no-wrap">{children}</div>
        </div>
    );
}

export function ListItem({ evOnClick, cName, onMouseEnter, onMouseLeave, style, children }) {
    return (
        <div
            role={evOnClick && 'button'}
            tabIndex={-1}
            onClick={evOnClick}
            className={`body ${cName}`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={style}
        >
            {children}
        </div>
    );
}

export function ListBody({ children }) {
    return (
        <div className="content-body">
            <div>{children}</div>
        </div>
    );
}

// usage
// function DemoTable() {
//     return (
//         <TableMobile>
//             <TableHeader>
//                 <span>left btn</span>
//                 <span>right btn</span>
//             </TableHeader>
//             <TableBody>
//                 <BodyDetails>
//                     <span>This is details</span>
//                 </BodyDetails>
//             </TableBody>
//         </TableMobile>
//     );
// }

function TableMobileView() {
    return null;
}

export default TableMobileView;
