import { useTemplate } from '../../../../contexts/TemplateProvider';
import InstOption from '../../../../utils/InstOption';
import { useAddress } from '../ProfileAddressProvider';

function AddressListTableMenuOption({ showOptions, setShowOptions, myRef, data }) {
    const { removeAddress, setAddressAsPrimary } = useAddress();
    const { setConfirm, setMessage } = useTemplate();

    const handlePrimary = (id, hash) => {
        setAddressAsPrimary(id, hash).then(({ error, message }) => {
            setMessage(error);
            if (error === 0) {
                setMessage(message);
                setShowOptions(false);
            }
        });
    };

    const handleDelete = () => {
        if (data?.isDefault) {
            setShowOptions('default');
        } else {
            setConfirm({
                text: 'Are you sure you want delete this Address?',
                clickAction: (confirm, setNull) => {
                    if (!data.isDefault) {
                        removeAddress(data?.id, data?.hash).then(({ error, message }) => {
                            setMessage(error);
                            setNull(null);

                            if (error === 0) {
                                setMessage(message);
                            }
                        });
                        setMessage('Address Deleting...');
                    } else {
                        setMessage('Primary address can`t be deleted....');
                    }
                }
            });
        }
    };

    return (
        <InstOption show={showOptions} setShow={setShowOptions} myRef={myRef}>
            <button
                onClick={() => handlePrimary(data?.id, data?.hash)}
                type="button"
                className="default-none w-100 text-left no-wrap"
            >
                <span>Make Primary</span>
            </button>
            <button
                onClick={handleDelete}
                type="button"
                className="default-none w-100 text-left no-wrap"
            >
                <span>Delete Address</span>
            </button>
        </InstOption>
    );
}

export default AddressListTableMenuOption;
