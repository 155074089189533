import { useActivityInfo } from './InfoActivityLogProvider';
import InfoActivityList from './info_activity_list/InfoActivityList';

function InfoActivityLogArea() {
    const { activityInfo } = useActivityInfo();

    if (activityInfo && activityInfo?.length === 0) {
        return <div className="ml-3 font-16">No Activity Log Found !</div>;
    }
    return (
        <div className="user-activity-log-area">
            <div className={`product-info mb-6 w-100 ${!activityInfo ? 'placeholder-mode' : ''}`}>
                <InfoActivityList />
            </div>
        </div>
    );
}
export default InfoActivityLogArea;
