import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

function AcceptCookiePopup() {
    const [mode, setMode] = useState(false);
    const [isAccept, setIsAccept] = useState(JSON.parse(localStorage.getItem('cookie')) || null);

    useEffect(() => {
        if (mode) {
            localStorage.setItem('cookie', JSON.stringify('Bikiran.com' || ' '));
            setIsAccept(JSON.parse(localStorage.getItem('cookie')));
        }
    }, [mode]);

    if (isAccept !== null) {
        return null;
    }

    return (
        <div className="cookie-popup-container">
            <p>
                This site uses cookies. By continuing to use this website, you agree to their use.
                For details, please check our{' '}
                <Link className="purple" to="/legal/privacy-policy/">
                    Privacy Policy
                </Link>
                .
            </p>
            <button onClick={() => setMode(true)} type="button" className="accept-btn">
                Accept Cookie
            </button>
        </div>
    );
}

export default AcceptCookiePopup;
